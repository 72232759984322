export const CREATIVE_FIELD_OPTIONS = {
  architecture: 'Architecture',
  brand_graphic_design: 'Brand/graphic design',
  creative_direction: 'Creative direction',
  fashion_styling: 'Fashion styling',
  floral_design: 'Floral design',
  interior_design: 'Interior design',
  personal_styling: 'Personal styling',
  ui_ux_design: 'UX/UI design',
  wedding_event_planning: 'Wedding/event planning',
  other: 'Other',
};

export const TEAM_SIZE_OPTIONS = {
  solo: 'Solo',
  _5: '2–5',
  '6_10': '6-10',
  '11_25': '11-25',
  '26_50': '26-50',
  '50_': 'More than 50',
};

export const AVERAGE_FEE_OPTIONS = {
  _1500: 'Under $1,500',
  '1500_4999': '$1,500 to $4,999',
  '5000_9999': '$5,000 to $9,999',
  '10000_49999': '$10,000 to $49,999',
  '50000_99999': '$50,000 to $99,999',
  '100000_': '$100,000 and above',
};

export const USE_CASE_OPTIONS = {
  work: 'Work',
  personal: 'Personal',
  mix: 'A mix of both',
};

export const ACQUISITION_CHANNEL_OPTIONS = {
  search_engine: 'Search engine',
  social_media: 'Social media',
  recommendation: 'Friend or colleague',
  customer_reviews: 'Customer reviews',
  event_webinar_workshop: 'Event, webinar or workshop',
  blog_publication: 'Blog or publication',
  ad: 'Ad',
  other: 'Other',
};
