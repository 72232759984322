import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { flushSync } from 'react-dom';
import { useLocation } from 'react-router';

import { VaiColouredPathIcon } from '@visualist/design-system/src/components/v2';

import { getHubs } from '@api/hubs';
import { ALL_HUBS_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

import { openVaiModal } from '../../open-vai';

import styles from './styles.module.css';

export const VaiButton = () => {
  const [mouseState, setMouseState] = useState<'none' | 'hover'>('none');
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { pathname } = useLocation();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const loopAnimation = () => {
    return setTimeout(() => {
      // Check if still hovered
      if (mouseState === 'hover') {
        loopAnimation();
      } else {
        setShouldAnimate(false);
      }
    }, 1500);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const { data: hubs = [] } = useQuery({
    queryKey: [ALL_HUBS_QUERY],
    queryFn: () => getHubs({ ordering: '-created_at' }),
  });

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.hoverCheck, {
          [styles.animated]: shouldAnimate,
        })}
        onMouseEnter={() => {
          flushSync(() => {
            setMouseState('hover');
            setShouldAnimate(true);
          });
          timeoutRef.current = loopAnimation();
        }}
        onMouseLeave={() => {
          setMouseState('none');
        }}
        onClick={() => openVaiModal({ pathname, hubs })}
        aria-hidden="true"
      >
        <VaiColouredPathIcon isPurple height={22} width={22} />
      </div>
    </div>
  );
};
