import React from 'react';

import { ImageBlock } from '@pages/StudioPage/api';
import { useImages } from '@pages/StudioPage/hooks/useImages';
import { useTextBoxes } from '@pages/StudioPage/hooks/useTextBoxes';
import { calculateCenter } from '@pages/StudioPage/utils';
import {
  chooseHeroImage,
  performLayoutCalculation,
} from '@pages/StudioPage/utils/layoutAlgorithms';
import { isTransparent } from '@pages/StudioPage/utils/transparency';

export const useShuffler = ({ designId }: { designId: string }) => {
  const { imageQuery, imageUpdateMutation } = useImages({ designId });
  const { textBoxQuery } = useTextBoxes({ designId });

  const [currentHeroImage, setCurrentHeroImage] = React.useState<string>('');
  const [hasUserSelectedHero, setHasUserSelectedHero] = React.useState(false);

  const [shuffledMap, setShuffledMap] = React.useState<
    Map<number, { images: ImageBlock[] }>
  >(new Map());
  const [currentMapKey, setCurrentMapKey] = React.useState<
    number | undefined
  >();

  const [hasLoadedTransparentImages, setHasLoadedTransparentImages] =
    React.useState(false);
  const [transparentImages, setTransparentImages] = React.useState<Set<string>>(
    new Set(),
  );
  const [paletteImages, setPaletteImages] = React.useState<Set<string>>(
    new Set(),
  );

  const selectHero = (id: string) => {
    // Toggle hero image selection
    if (id === currentHeroImage) {
      setCurrentHeroImage('');
      setHasUserSelectedHero(false);
      return;
    }
    setCurrentHeroImage(id);
    setHasUserSelectedHero(true);
  };

  const centerPoint = React.useMemo(() => {
    if (!imageQuery.data || !textBoxQuery.data)
      return {
        x: 0,
        y: 0,
      };

    const blocks = [
      ...imageQuery.data.data.results.map((b) => ({
        x: b.studio.position_x,
        y: b.studio.position_y,
        width: b.studio.position_width ?? b.width,
        height: b.studio.position_height ?? b.height,
      })),
      ...textBoxQuery.data.map((t) => ({
        x: t.position_x,
        y: t.position_y,
        width: t.width,
        height: 20,
      })),
    ];

    return calculateCenter({ blocks });
  }, [imageQuery.data?.data.results.length, textBoxQuery.data?.length]);

  React.useEffect(() => {
    const checkTransparency = async () => {
      if (!imageQuery.data) return;
      const images = imageQuery.data.data.results;
      const tempTransparentImages = new Set<string>();

      const promises = images.map((image) => {
        return isTransparent({ url: image.file.full_size }).then(
          (isTransparent) => {
            if (isTransparent) {
              tempTransparentImages.add(image.id);
            }
          },
        );
      });

      await Promise.all(promises);
      setTransparentImages(tempTransparentImages);
      setHasLoadedTransparentImages(true);
    };

    checkTransparency();
  }, [imageQuery.data]);

  React.useEffect(() => {
    const checkIfPalette = () => {
      if (!imageQuery.data) return;

      const paletteSet = new Set<string>();

      for (const image of imageQuery.data.data.results) {
        // Naively assume all images 1000 x 1000 are colour palettes
        if (image.height === 1000 && image.width === 1000) {
          paletteSet.add(image.id);
        }
      }

      setPaletteImages(paletteSet);
    };

    checkIfPalette();
  }, [imageQuery.data]);

  const handleShuffle = async () => {
    if (!imageQuery.data || !textBoxQuery.data) return;

    const images: ImageBlock[] = [
      ...imageQuery.data.data.results.map((image) => {
        return { ...image, studio: { ...image.studio } };
      }),
    ];

    const nextHeroImageBlock = await chooseHeroImage(images, transparentImages);
    let currentHeroImageBlock = images.find(
      (image) => image.id === currentHeroImage,
    );
    currentHeroImageBlock = currentHeroImageBlock
      ? currentHeroImageBlock
      : nextHeroImageBlock;

    const heroImage = hasUserSelectedHero
      ? currentHeroImageBlock
      : nextHeroImageBlock;

    const shuffledImages = await performLayoutCalculation({
      heroImage,
      images: images.filter((image) => image.id !== heroImage.id),
      center: centerPoint,
      transparentImages,
      paletteImages,
    });

    const newMap = new Map<number, { images: ImageBlock[] }>(shuffledMap);
    const newKey = newMap.size + 1;
    newMap.set(newKey, { images: shuffledImages });

    setCurrentMapKey(newKey);
    setShuffledMap(newMap);
  };

  return {
    imageQuery,
    imageUpdateMutation,
    textBoxQuery,
    selectHero,
    centerPoint,
    shuffledMap,
    currentHeroImage,
    currentMapKey,
    handleShuffle,
    hasLoadedTransparentImages,
    setCurrentMapKey,
  };
};
