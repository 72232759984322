import { Icon, IconProps } from './Icon';

export const VaiStyleGradientIcon = (props: IconProps) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M7.62801 9.61695C7.62801 9.61695 4.72824 10.8735 3.75482 12.5138C2.7814 14.1541 4.718 14.9694 7.50505 13.1277C10.2306 11.3148 13.2739 8.97427 17.3007 7.60257C19.3808 6.89274 21.2457 6.78723 20.2005 9.62654C19.6984 11.0078 16.3273 14.1541 16.3273 14.1541M10.03 17.3697C10.03 17.8888 9.60914 18.3097 9.08999 18.3097C8.57085 18.3097 8.14999 17.8888 8.14999 17.3697C8.14999 16.8505 8.57085 16.4297 9.08999 16.4297C9.60914 16.4297 10.03 16.8505 10.03 17.3697ZM7.23001 5.17969H10.06V8.00969H7.23001V5.17969ZM12.5 15.3696L14.28 14.6796L15.17 11.2496L16.06 14.6796L17.84 15.3696L16.06 16.0596L15.17 18.8096L14.28 16.0596L12.5 15.3696Z"
        stroke="url(#paint0_linear_2123_2435)"
        strokeLinecap="square"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2123_2435"
          x1="20.5"
          y1="5.17969"
          x2="3.51343"
          y2="18.7306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#630682" />
          <stop offset="0.5" stopColor="#8B39A8" />
          <stop offset="1" stopColor="#FAD7FF" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
