import { useHistory } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { CreateDoc, createDoc, ResponseCreateDoc } from '@api/docs';
import { ALL_DOCS_QUERY } from '@src/shared/constants/query-names';
import { DOC_TABS } from '@src/shared/constants/variables-local-storage';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { Tab } from '../../ui/tabs';

export const useCreateDoc = (
  options?: MutateOptions<ResponseCreateDoc, any, CreateDoc>,
) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [, setTabs] = useLocalStorage<Tab[]>(DOC_TABS, []);

  const makeDocMutation = useMutation({
    mutationFn: createDoc,
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [ALL_DOCS_QUERY],
      });

      setTabs((tabs) => [
        ...tabs,
        {
          id: data.id,
          label: data.title,
        },
      ]);

      startedSnack({
        label: 'Created doc',
        close: true,
      });

      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }

      history.push(`/d/${data.id}`);
    },
    onError: (err, variables, context) => {
      startedSnack({
        label: "Couldn't create doc",
        action: {
          label: 'Try again',
          action: () => makeDocMutation.mutate({}),
        },
        close: true,
      });

      if (options?.onError) options?.onError(err, variables, context);
    },
  });

  return { makeDoc: makeDocMutation.mutate, makeDocMutation };
};
