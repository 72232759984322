import { createEvent, createStore, sample } from 'effector';

import { emailsCleared, queryCleared } from './inviting';

export const docShareSheetOpened = createEvent();
export const docShareSheetClosed = createEvent();

export const idSelected = createEvent<string>();
export const idCleared = createEvent();

export const hubVerified = createEvent<boolean>();
export const hubCleared = createEvent();

export const $isDocShareSheetOpen = createStore(false);
export const $id = createStore<string | null>(null);
export const $isDocInHub = createStore(false);

$isDocShareSheetOpen
  .on(docShareSheetOpened, () => true)
  .reset(docShareSheetClosed);

$id.on(idSelected, (_, id) => id).reset(idCleared);

$isDocInHub.on(hubVerified, (_, isHubExist) => isHubExist).reset(hubCleared);

sample({
  clock: docShareSheetClosed,
  target: [idCleared, queryCleared, emailsCleared, hubCleared],
});
