import {
  AssistChip,
  Tooltip,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const ExtraSeats = () => (
  <div className={styles.extraSeats}>
    <Tooltip
      style={{
        minWidth: 'max-content',
        left: '37px',
      }}
      parameter={{
        type: 'plain',
        description: 'Coming soon: Pay for extra seats',
        position: 'top',
      }}
    >
      <AssistChip style="outlined" onClick={() => {}} disabled>
        Get extra seats
      </AssistChip>
    </Tooltip>
  </div>
);
