import { createContext, useContext, useRef } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';

import { Icon } from '@visualist/icons';

import { Divider } from '../Divider';
import { IconButton } from '../IconButtons';
import TypographyPoppins from '../Styles/Typography/TypographyPoppins';
import { Tooltip } from '../Tooltip';
import {
  $background,
  $isCustomColor,
  backgroundSelected,
  colorMenuClosed,
} from './model/select-background';

import styles from './styles.module.css';

type Swatch = {
  name: string;
  color: string;
};

export type Swatches = {
  title?: string;
  swatches?: Swatch[];
};

type Props = {
  colors: Swatches;
  gradients?: Swatches;
  customColor?: boolean;
  background: string;
};

const Context = createContext({ background: '' });

export const ColorMenu = ({
  colors,
  gradients,
  background,
  customColor = true,
}: Props) => {
  return (
    <Context.Provider value={{ background }}>
      <div className={styles.colorMenu}>
        <Swatches title={colors.title} swatches={colors.swatches} />
        <Swatches title={gradients?.title} swatches={gradients?.swatches} />
        {customColor && <CustomColor />}
      </div>
    </Context.Provider>
  );
};

const Swatches = ({ title, swatches }: Swatches) => {
  if (!swatches) return null;

  return (
    <>
      {title && (
        <TypographyPoppins type="label" labelSize="S" className={styles.label}>
          {title}
        </TypographyPoppins>
      )}
      <ul className={styles.swatches}>
        {swatches.map((swatch, i) => {
          return (
            <li key={i}>
              <ColorSwatch name={swatch.name} color={swatch.color} />
            </li>
          );
        })}
      </ul>
      <Divider type="long-line" className={styles.divider} />
    </>
  );
};

const ColorSwatch = ({ name, color }: { name: string; color: string }) => {
  if (!color) return null;

  const selectedBackground = useUnit($background);

  const { background } = useContext(Context);

  const isSelectedColor = () => {
    if (selectedBackground && selectedBackground === color) {
      return true;
    } else if (!selectedBackground && background === color) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Tooltip
      parameter={{
        description: name,
        type: 'plain',
        position: 'top',
        centerText: true,
      }}
      style={{
        minWidth: 'max-content',
      }}
    >
      <div
        style={{ background: color }}
        className={cn(styles.swatch, {
          [styles.light]: color.includes('#') && isLightColor(hexToRgb(color)),
          [styles.dark]: color.includes('#') && !isLightColor(hexToRgb(color)),
        })}
        onClick={() => {
          backgroundSelected(color);
          colorMenuClosed();
        }}
      >
        {isSelectedColor() && (
          <Icon
            size={16}
            name="sprite/tick"
            color="var(--color-neutral-variant-30)"
          />
        )}
      </div>
    </Tooltip>
  );
};

const CustomColor = () => {
  const selectedBackground = useUnit($background);
  const isCustomColor = useUnit($isCustomColor);
  const ref = useRef<HTMLInputElement>(null);

  const { background } = useContext(Context);

  const getLightColor = () => {
    if (selectedBackground && selectedBackground.includes('#')) {
      return isLightColor(hexToRgb(selectedBackground));
    } else if (!selectedBackground && background.includes('#')) {
      return isLightColor(hexToRgb(background));
    }
  };

  const isGradient =
    (selectedBackground && !selectedBackground.includes('#')) ||
    !background.includes('#');

  return (
    <>
      <TypographyPoppins type="label" labelSize="S" className={styles.label}>
        Custom
      </TypographyPoppins>
      <div style={{ display: 'flex', gap: '4px' }}>
        {isCustomColor && (
          <Tooltip
            parameter={{
              description: selectedBackground
                ? selectedBackground.toUpperCase()
                : background.toUpperCase(),
              type: 'plain',
              position: 'top',
            }}
            style={{
              minWidth: 'max-content',
            }}
          >
            <div
              style={{
                background: selectedBackground
                  ? selectedBackground
                  : background,
              }}
              className={cn(styles.swatch, {
                [styles.light]: !isGradient && getLightColor(),
                [styles.dark]: !isGradient && !getLightColor(),
              })}
            >
              <Icon
                size={16}
                name="sprite/tick"
                color="var(--color-neutral-variant-30)"
              />
            </div>
          </Tooltip>
        )}
        <IconButton
          className={styles.customColor}
          iconStyles={styles.customColorIcon}
          type="unfilled"
          icon={
            <>
              <Icon
                name="sprite/plus"
                color="var(--color-neutral-variant-30)"
              />
              <div className={styles.colorWheelMenu}>
                <input
                  ref={ref}
                  type="color"
                  onChange={(e) => backgroundSelected(e.target.value)}
                />
              </div>
            </>
          }
          onClick={() => ref.current?.click()}
        />
      </div>
    </>
  );
};

// TODO: copying variables and functions from app/shared as they cannot be accessed from DS
const RED_Y = 0.2126;
const GREEN_Y = 0.7152;
const BLUE_Y = 0.0722;

export const hexToRgb = (hex: string | null) => {
  if (!hex) return null;

  if (hex.includes('#')) {
    hex = hex.replace(/^#/, '');
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
};

export const isLightColor = (rgb: string | null) => {
  if (!rgb) return null;

  const rgbColors = rgb.split(',');
  const r = parseInt(rgbColors[0]);
  const g = parseInt(rgbColors[1]);
  const b = parseInt(rgbColors[2]);
  const brightness = RED_Y * r + GREEN_Y * g + BLUE_Y * b;

  const isLight = brightness > 128;

  return isLight;
};
