import { Icon, IconProps } from './Icon';

export const CheckboxIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <rect
        data-item={props.itemID}
        x="5"
        y="5"
        width="13.5"
        height="13.5"
        rx="2"
        fill={props.fill}
      />
      <path
        d="M8 12.362L10.9643 14.9L16.5 9"
        stroke="currentColor"
        strokeLinecap="round"
        data-item={props.itemID}
      />
    </Icon>
  );
};
