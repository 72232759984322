import { deleteColorTheme } from '@api/blocks';
import { IMAGE_QUERY } from '@src/shared/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useRemoveColorTheme = (fileId: string) => {
  const queryClient = useQueryClient();

  const { mutate: removeColorTheme } = useMutation({
    mutationFn: (theme: any) => deleteColorTheme(theme),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [IMAGE_QUERY, { id: fileId }],
      });
    },
  });

  return { removeColorTheme };
};
