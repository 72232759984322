import { ChangeEvent, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router';

import {
  AssistChip,
  Avatar,
  Divider,
  Team,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { getInviteesHub } from '@api/hubs';
import { shareSideSheetOpened } from '@pages/HubPage/model';
import { useHubs } from '@pages/HubPage/model/queries/useHubs';
import { MEMBERS_INVITATION_HUB_QUERY } from '@src/shared/constants';
import { Autocomplete } from '@src/widgets/Autocomplete';
import { useQuery } from '@tanstack/react-query';

import styles from './styles.module.css';

export const Details = ({
  description,
  location,
}: {
  description: string;
  location?: string;
}) => {
  const [isEditBody, setEditBody] = useState(false);
  const [localDescription, setLocalDescription] = useState('');

  const descriptionRef = useRef<HTMLDivElement>(null);

  const { hub_id } = useParams<{ hub_id: string }>();

  const { data: invitees } = useQuery({
    queryKey: [MEMBERS_INVITATION_HUB_QUERY, { hub_id }],
    queryFn: () => getInviteesHub(hub_id),
  });

  useEffect(() => {
    if (description) {
      setLocalDescription(description);
    }
  }, [description]);

  const onDoubleClick = () => {
    setEditBody(true);

    setTimeout(() => {
      if (descriptionRef.current) {
        descriptionRef.current.focus();

        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(descriptionRef.current);
        range.collapse(false);
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }, 0);
  };

  const { editHub } = useHubs({});

  const onInput = (e: ChangeEvent<HTMLDivElement>) => {
    setLocalDescription(e.target.innerText);
  };

  const editDescription = () => {
    if (localDescription !== description) {
      editHub({ description: localDescription, hubId: hub_id });
    }
    setEditBody(false);
  };

  const onBlur = () => {
    editDescription();
  };

  return (
    <li className={styles.item}>
      <div className={styles.card}>
        <div className={styles.header}>
          <TypographyPoppins
            className={styles.color}
            type="title"
            titleSize="M"
          >
            Details
          </TypographyPoppins>
        </div>
        <div className={styles.horizontal}>
          <Divider className={styles.divider} type="long-line" />
        </div>

        <div className={styles.details}>
          <div className={styles.content}>
            <div className={styles.people}>
              <div className={styles.team}>
                <Team className={styles.icon} fill="none" />
              </div>
              <div className={styles.ownerAndClients}>
                {invitees?.members.map((member) => (
                  <Avatar
                    key={member.id}
                    image={member.user?.photo.full_size}
                    initials={
                      member.user
                        ? `${member.user.first_name} ${member.user.last_name}`
                        : member.email
                    }
                    size={20}
                  />
                ))}
              </div>
            </div>
            <AssistChip
              className={styles.invite}
              style="outlined"
              onClick={() => shareSideSheetOpened()}
            >
              Invite
            </AssistChip>
          </div>
          <div className={styles.description}>
            <Icon className={styles.icon} name="sprite/information" />
            <div
              ref={descriptionRef}
              className={cn(styles.text, {
                [styles.editMode]: isEditBody,
              })}
              contentEditable={isEditBody}
              onDoubleClick={onDoubleClick}
              onInput={onInput}
              onBlur={onBlur}
              placeholder={
                description ? '' : 'write a short description of this project'
              }
            >
              {description}
            </div>
          </div>
          <Autocomplete hubId={hub_id} location={location} />
        </div>
      </div>
    </li>
  );
};
