import { UserPersonalisationType, UserType } from '@visualist/design-system';

export const getFormCompletionPercentage = (user: UserType) => {
  const includedFields: Array<keyof UserPersonalisationType> = [
    'active_since',
    'location',
    'creative_field',
    'team_size',
    'average_fee',
    'project_number',
    'use_case',
    'acquisition_channel',
  ];
  const totalFields = includedFields.length;
  const filledFields = includedFields.filter((key) => {
    return Boolean(user[key]);
  }).length;
  return Math.round((filledFields / totalFields) * 100);
};
