import {
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import { motion } from 'framer-motion';
import { isDesktop, isMobile, isMobileOnly } from 'react-device-detect';
import { useLongPress } from 'use-long-press';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { useKeyboardShortcut } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { useFloating } from '@floating-ui/react';

import {
  Checkbox,
  CheckboxIcon,
  DesignColored,
  Dropdown,
  IconButton,
  ImageColored,
  Item,
  PaletteColored,
  StudioIcon,
  TeamIcon,
  TypographyPoppins,
} from '../v2';

import styles from './style.module.css';

export enum MENU_OPTIONS {
  OPEN,
  DELETE,
  DOWNLOAD,
  TAG,
  CLIP,
  CREATE_BOARD,
  COPY,
  DOTS,
  STUDIO,
}

export type FileType = 'Doc' | 'Image' | 'Design' | 'Palette';
export type FileSize = 'Large';

export type ImageProps = {
  setAnyImageActionOpen: Dispatch<boolean>;
  item: {
    title?: string;
    block_type: string;
    id: string;
    file: {
      thumbnail_640: string;
      thumbnail_400: string;
      thumbnail_330: string;
      thumbnail_100: string;
      full_size: string;
    };
    height: number;
    width: number;
    name: string;
    copies_number: number;
    board?: { id: string; name: string };
    hub?: { id: string; name: string };
  };
  selected: boolean;
  selectImage: (
    e: React.MouseEvent<HTMLElement>,
    id: string,
    imageRef: React.RefObject<HTMLElement>,
  ) => void;
  anyImageActionOpen: boolean;
  imageAction: () => void;
  actions: boolean;
  copyFile: (url: string) => void;
  createBoard?: boolean;
  shareOnly?: boolean;
  hasPermissionEditing?: boolean;
  size: FileSize;
  type: FileType;
  history: any;
  selectAll?: boolean;
  currentCardId?: string;
  animationDelay?: number;
  openNewTab?: (id: string, shareOnly?: boolean) => void;
  downloadSelectedBlocksSingle?: (id: string) => Promise<any>;
  removeDoc: (name: string, id: string) => void;
  removeFile: (id: string[]) => void;
  fileTypeSelected: (type: string) => string;
  renameFile: ({ id, name }: { id: string; name: string }) => void;
  renameDoc: ({ id, name }: { id: string; name: string }) => void;
  downloadAsPDF: ({ id, name }: { id: string; name: string }) => void;
  docShareSheetOpened?: () => void;
  idSelected?: (id: string) => string;
  hubVerified?: (isHubExist: boolean) => boolean;
  baseURL: string;
};

export const ImageCard = ({
  setAnyImageActionOpen,
  item,
  selected,
  selectImage,
  imageAction,
  copyFile,
  shareOnly,
  type,
  history,
  selectAll,
  currentCardId,
  animationDelay = 0,
  openNewTab,
  downloadSelectedBlocksSingle,
  removeDoc,
  removeFile,
  fileTypeSelected,
  renameFile,
  renameDoc,
  downloadAsPDF,
  docShareSheetOpened,
  idSelected,
  hubVerified,
  baseURL,
}: ImageProps) => {
  const [isShowCheckbox, setShowCheckbox] = useState(false);
  const [isSelectedCheckbox, setSelectedCheckbox] = useState(false);
  const [isShowTooltip, setShowTooltip] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isOpenDropdown, setOpenDropdown] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isEditName, setEditName] = useState(false);

  const imageRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { refs, floatingStyles } = useFloating({
    open: isShowTooltip,
    onOpenChange: setShowTooltip,
    placement: 'top',
  });

  const longPressBind = useLongPress((e) => {
    e.preventDefault();

    if (isMobileOnly) {
      setOpenDropdown(!isOpenDropdown);
    }
  });

  const handleHover = () => {
    if (!isMobileOnly) {
      setShowCheckbox(true);
    }
    setShowTooltip(true);
  };

  const handleHoverOut = () => {
    if (!isSelectedCheckbox && !isMobileOnly) {
      setShowCheckbox(false);
    }
    setShowTooltip(false);
  };

  const handleSelectImage = (e: React.MouseEvent<HTMLElement>) => {
    selectImage(e, item.id, imageRef);
  };

  const getFileType = () => {
    if (item?.block_type === 'Image') {
      return 'image';
    } else if (item?.block_type === 'Set') {
      return 'design';
    } else {
      return 'palette';
    }
  };

  const copyAsPNG = () => {
    if (item.file.full_size) {
      copyFile(item.file.full_size);

      startedSnack({
        label: `Copied ${getFileType()}`,
        close: true,
      });
    } else {
      startedSnack({
        label: `Couldn't copy ${getFileType()}`,
        action: {
          label: 'Try again',
          action: () => {
            copyAsPNG();
          },
        },
        close: true,
      });
    }
  };

  const goToStudio = async () => {
    if (item.id) {
      history.push(`/studio/${item.id}`);
    } else {
      startedSnack({
        label: "Couldn't open Studio to edit",
        action: {
          label: 'Try again',
          action: () => {
            history.push(`/studio/${item.id}`);
          },
        },
        close: true,
      });
    }
  };

  const download = async () => {
    if (downloadSelectedBlocksSingle) {
      try {
        startedSnack({
          label: `Downloading image...`,
          close: true,
        });

        downloadSelectedBlocksSingle(item.id).then((response) => {
          startedSnack({
            label: `Downloaded ${getFileType()}`,
            close: true,
          });

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${item.id}.jpg`;
          link.click();
        });
      } catch (error) {
        startedSnack({
          label: `Couldn't download ${getFileType()}`,
          action: {
            label: 'Try again',
            action: () => {
              download();
            },
          },
          close: true,
        });
      }
    }
  };

  const handleClose = () => {
    setAnyImageActionOpen(false);
  };

  useEffect(() => {
    if (isMobile) {
      setShowCheckbox(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (selectAll) {
      setShowCheckbox(true);
      setSelectedCheckbox(true);
    } else if (isDesktop) {
      setShowCheckbox(false);
      setSelectedCheckbox(false);
    } else {
      setSelectedCheckbox(false);
    }
  }, [selectAll]);

  useEffect(() => {
    if (currentCardId !== item.id && isSelectedCheckbox && isDesktop) {
      setShowCheckbox(false);
      setSelectedCheckbox(false);
    } else if (currentCardId !== item.id && isSelectedCheckbox && isMobile) {
      setSelectedCheckbox(false);
    }
  }, [currentCardId, item.id, isSelectedCheckbox, isDesktop, isMobile]);

  useEffect(() => {
    if (currentCardId) {
      setShowCheckbox(true);
      setSelectedCheckbox(true);
    }
  }, [currentCardId]);

  useEffect(() => {
    if (item.name) {
      setFileName(item.name);
    } else if (item.title) {
      setFileName(item.title);
    } else {
      setFileName('');
    }
  }, [item]);

  useEffect(() => {
    if (inputRef.current && isEditName && !shareOnly) {
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length,
      );
      inputRef.current.scrollLeft = inputRef.current.scrollWidth;
    }
  }, [isEditName, shareOnly]);

  useKeyboardShortcut(['Escape'], handleClose);

  const handleCheckbox = (
    e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
  ) => {
    e.stopPropagation();
    setSelectedCheckbox(!isSelectedCheckbox);
    if ('button' in e) {
      handleSelectImage(e as MouseEvent<HTMLElement>);
    }
  };

  const openFileCard = () => {
    if (type === 'Doc') {
      history.push(`/d/${item.id}`);
    } else {
      imageAction();
    }
  };
  const handleRightClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isDesktop && type !== 'Doc') {
      setOpenDropdown(!isOpenDropdown);
    }
  };

  const handleFileCardClick = (e: MouseEvent<HTMLDivElement>, id: string) => {
    e.preventDefault();

    if (!e.ctrlKey && !e.metaKey) {
      openFileCard();
    } else if (openNewTab && (e.ctrlKey || e.metaKey)) {
      openNewTab(id, shareOnly);
    } else {
      handleCheckbox(e);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && type !== 'Doc') {
      if (fileName !== item.name) {
        renameFile({ id: item.id, name: fileName });
      }
      setEditName(false);
    } else if (e.key === 'Enter' && type === 'Doc') {
      if (fileName !== item.name && fileName !== item.title) {
        renameDoc({ id: item.id, name: fileName });
      }
      setEditName(false);
    }
  };

  const rename = () => {
    setEditName(true);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.select();
      }
    }, 50);
  };

  const onBlur = () => {
    if (type === 'Doc' && fileName !== item.name && fileName !== item.title) {
      renameDoc({ id: item.id, name: fileName });
    } else if (fileName !== item.name) {
      renameFile({ id: item.id, name: fileName });
    }
    setEditName(false);
  };

  const preloadImage = () => {
    const img = new Image();
    img.src = item.file.full_size;
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${baseURL}/library#/block/${item.id}`,
      );

      startedSnack({
        label: `Copied link to ${getFileType()}`,
      });
    } catch (error) {
      startedSnack({
        label: `Couldn't copy link to ${getFileType()}`,
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const menuItems: Item[] =
    item.block_type === 'Set'
      ? [
          {
            leadingIcon: <Icon name="sprite/pen" />,
            content: 'Rename',
            onClick: rename,
          },
          {
            leadingIcon: <StudioIcon fill="none" />,
            content: 'Edit in Studio',
            onClick: goToStudio,
          },
          {
            leadingIcon: <Icon name="sprite/link" size={21} />,
            content: 'Copy link',
            onClick: copyLink,
          },
          {
            leadingIcon: <Icon name="sprite/copy" />,
            content: 'Copy as PNG',
            onClick: copyAsPNG,
          },
          {
            leadingIcon: <Icon name="sprite/download" />,
            content: 'Download',
            isDivider: true,
            onClick: download,
          },
          {
            leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
            content: 'Delete',
            classNameContent: styles.delete,
            onClick: () => {
              removeFile([item.id]);
              fileTypeSelected(item.block_type);
            },
          },
        ]
      : [
          {
            leadingIcon: <Icon name="sprite/pen" />,
            content: 'Rename',
            onClick: rename,
          },
          {
            leadingIcon: <Icon name="sprite/link" size={21} />,
            content: 'Copy link',
            onClick: copyLink,
          },
          {
            leadingIcon: <Icon name="sprite/copy" />,
            content: 'Copy as PNG',
            onClick: copyAsPNG,
          },
          {
            leadingIcon: <Icon name="sprite/download" />,
            content: 'Download',
            isDivider: true,
            onClick: download,
          },
          {
            leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
            content: 'Delete',
            classNameContent: styles.delete,
            onClick: () => {
              removeFile([item.id]);
              fileTypeSelected(item.block_type);
            },
          },
        ];

  const copyLinkDoc = async () => {
    try {
      await navigator.clipboard.writeText(`${baseURL}/d/${item.id}`);

      startedSnack({
        label: 'Copied link to doc',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to doc",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const menuItemsDocs: Item[] = [
    {
      leadingIcon: <Icon name="sprite/pen" />,
      content: 'Rename',
      onClick: rename,
    },
    {
      leadingIcon: <Icon name="sprite/link" size={21} />,
      content: 'Copy link',
      onClick: copyLinkDoc,
    },
    {
      leadingIcon: <TeamIcon />,
      content: 'Invite to doc',
      onClick: () => {
        if (docShareSheetOpened && idSelected && hubVerified) {
          docShareSheetOpened();
          idSelected(item.id);
          hubVerified(Boolean(item.hub));
        }
      },
    },
    {
      leadingIcon: <Icon name="sprite/download" />,
      content: 'Download as PDF',
      isDivider: true,
      onClick: () => downloadAsPDF({ id: item.id, name: item.name }),
    },
    {
      leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
      content: 'Delete',
      classNameContent: styles.delete,
      onClick: () => removeDoc(item.name, item.id),
    },
  ];

  return (
    <motion.div
      animate={{ scale: 1 }}
      transition={{
        delay: animationDelay,
        layout: {
          type: 'tween',
        },
      }}
      {...longPressBind()}
      ref={imageRef}
      key={item.id}
      data-item={item.id}
      onClick={(e) => handleFileCardClick(e, item.id)}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setTimeout(() => {
          handleRightClick(e);
        }, 200);
      }}
      className={styles.container}
      // layoutId={`file-card-${item.id}`}
      onMouseOver={preloadImage}
      style={{
        zIndex: isAnimating ? 110 : 100,
      }}
      onLayoutAnimationStart={() => setIsAnimating(true)}
      onLayoutAnimationComplete={() => setIsAnimating(false)}
    >
      <div className={styles.thumbnail}>
        {item.copies_number > 1 && (
          <>
            <div
              className={cn(styles.thirdCard, {
                [styles.activeCard]: isOpenDropdown || isShowCheckbox,
              })}
            />
            <div
              className={cn(styles.secondCard, {
                [styles.activeCard]: isOpenDropdown || isShowCheckbox,
              })}
            />
          </>
        )}
        <div
          ref={refs.setReference}
          className={cn(styles.mediaContainer, {
            [styles.doc]: type === 'Doc',
            [styles.design]: type === 'Design',
            [styles.palette]: type === 'Palette',
            [styles.activeCard]: isOpenDropdown,
            [styles.stackedCopies]:
              item.copies_number > 1 && item.file && item.file.thumbnail_640,
          })}
          onMouseEnter={handleHover}
          onMouseLeave={handleHoverOut}
        >
          {type === 'Doc' ? (
            <Icon
              name="sprite/doc-colored"
              className={styles.docIcon}
              size={80}
            />
          ) : (
            <img
              // layout
              // layoutId={`file-image-${item.id}`}
              // transition={{
              //   type: 'tween',
              // }}
              src={
                item.file.thumbnail_640 ??
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8f2fPfwAJEQOYV82bGAAAAABJRU5ErkJggg=='
              }
              style={{
                objectFit: item.file.thumbnail_640 ? undefined : 'fill',
              }}
              className={cn(styles.media, {
                [styles.design]: type === 'Design',
                [styles.palette]: type === 'Palette',
              })}
              id={item.id}
            />
          )}

          {!shareOnly && type !== 'Doc' && isShowCheckbox && (
            <Checkbox
              style={{ position: 'absolute' }}
              className={styles.checkbox}
              icon={<CheckboxIcon itemID={item.id} fill="#99461C" size={30} />}
              itemID={item.id}
              onClick={handleCheckbox}
              isSelected={isSelectedCheckbox}
            />
          )}

          <div
            className={cn(styles.header, {
              [styles.design]: type === 'Design',
              [styles.palette]: type === 'Palette',
            })}
          >
            <div className={styles.content}>
              <div style={{ width: '16px', height: '16px' }}>
                {type === 'Doc' && <Icon name="sprite/doc-colored" size={16} />}
                {item.block_type === 'Image' && (
                  <ImageColored fill="none" size={16} />
                )}
                {item.block_type === 'Set' && (
                  <DesignColored fill="none" size={16} />
                )}
                {item.block_type === 'Colour palette' && (
                  <PaletteColored fill="none" size={16} />
                )}
              </div>
              <input
                ref={inputRef}
                className={cn(styles.input, {
                  [styles.editName]: isEditName,
                })}
                value={fileName}
                onClick={(e) => {
                  if (isEditName) {
                    e.stopPropagation();
                  }
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  setFileName(e.target.value);
                }}
                placeholder={isEditName ? fileName : 'Untitled'}
                readOnly={!isEditName}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
              />
            </div>

            <div className={styles.actionsInner}>
              <span
                data-item={item.id}
                onClick={handleSelectImage}
                className={cn(styles['select-check'], {
                  [styles.selected]: selected,
                })}
              />
              {!shareOnly && (
                <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
                  <Dropdown.Menu
                    trigger={
                      <IconButton
                        style={{ width: '16px', height: '16px' }}
                        type="unfilled"
                        icon={<Icon name="sprite/3-dot-menu" size={16} />}
                        onClick={() => {}}
                        isSelected={isOpenDropdown}
                      />
                    }
                    side="top"
                    density="-2"
                  >
                    {type === 'Doc'
                      ? menuItemsDocs.map((item, index) => (
                          <Dropdown.MenuItem key={index} item={item} />
                        ))
                      : menuItems.map((item, index) => (
                          <Dropdown.MenuItem key={index} item={item} />
                        ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>

          {isShowTooltip && item.copies_number > 1 && (
            <div ref={refs.setFloating} style={floatingStyles}>
              <span className={styles.tooltip}>
                <TypographyPoppins type="body" size="S">
                  Multiple copies
                </TypographyPoppins>
              </span>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};
