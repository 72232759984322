import { getHub } from '@api/hubs';
import { DocumentHead } from '@components/DocumentHead';
import { useHubMessages } from '@pages/HubPage/model/queries/useHubMessages';
import { HUB_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

export const MessagesDocumentHead = ({ hubId }: { hubId?: string }) => {
  if (hubId) return <MessagesTitle hubId={hubId} />;
  else return <DocumentHead name={`Messages`} />;
};

const MessagesTitle = ({ hubId }: { hubId: string }) => {
  const { messagesQuery } = useHubMessages({ id: hubId });
  const { data: hub } = useQuery({
    queryKey: [HUB_QUERY, { hubId }],
    queryFn: () => getHub({ hubId }),
    enabled: !!hubId,
  });

  if (
    messagesQuery &&
    messagesQuery.data &&
    messagesQuery.data.unread_count > 0 &&
    hub
  )
    return (
      <DocumentHead
        name={`Messages (${messagesQuery.data.unread_count}) | ${hub.name}`}
      />
    );
  else if (hub) return <DocumentHead name={`Messages | ${hub.name}`} />;
  else return <DocumentHead name={`Messages`} />;
};
