import { Icon, IconProps } from './Icon';

export const VaiTidyUpGradient = (props: IconProps) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M16.3439 12C17.4659 12.6 19.5418 14.16 18.8685 15.6C18.1953 17.04 11.7153 16.5 10.4529 18.3C9.61137 19.2 9.3589 21 15.0815 21M7 11L8 2L9 11L11 12L9 13L8 22L7 13L5 12L7 11ZM11 7.90909L13.6667 7.09091L15 3L16.3333 7.09091L19 7.90909L16.3333 8.72727L15 12L13.6667 8.72727L11 7.90909Z"
        stroke="url(#paint0_linear_1963_98)"
        strokeLinecap="square"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1963_98"
          x1="19"
          y1="2"
          x2="-0.0689775"
          y2="10.5375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#630682" />
          <stop offset="0.5" stopColor="#8B39A8" />
          <stop offset="1" stopColor="#FAD7FF" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
