import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { useSearchItem } from '@visualist/hooks';

import { useInfiniteQuery } from '@tanstack/react-query';

import { Block, getBlocks } from '../../../../api/services';
import { LIBRARY_QUERY } from '../../../../shared/constants';

interface Props {
  ordering: 'created_at' | '-created_at' | 'use_count' | '-use_count';
  filters?: string | null;
  queryKey?: Array<string | Record<string, any>>;
  limit?: number;
  typeOfBlock?: keyof typeof BlockChoiceMapping;
}

const BlockChoiceMapping = {
  image: 0,
  set: 1,
  show: 2,
  color_palette: 3,
  // background removed blocks are 4 and sets from lite are 5 but we do not need them here
} as const;

export const useAllBlocks = ({
  ordering,
  filters,
  queryKey,
  limit,
  typeOfBlock,
}: Props) => {
  const { getReadyHexColors } = useSearchItem();
  const location = useLocation();
  const { hex_colors } = queryString.parse(location.search, {
    arrayFormat: 'comma',
  });
  const rgb_color = new URLSearchParams(location.search).get('rgb_color');

  const queryKeyDefault = queryKey ?? [
    LIBRARY_QUERY,
    { ordering, filters, rgb_color, typeOfBlock },
  ];

  const query = useInfiniteQuery({
    queryKey: queryKeyDefault,
    queryFn: ({ pageParam }) => {
      const offset = parseInt(pageParam) ?? 0;
      return getBlocks({
        ...(filters && { filters }),
        ...(hex_colors && {
          hex_colors: getReadyHexColors(),
        }),
        ...(rgb_color && { rgb_color }),
        ...(limit && { limit }),
        offset,
        ordering,
        block_type: typeOfBlock ? BlockChoiceMapping[typeOfBlock] : undefined,
      });
    },
    initialPageParam: '0',
    getNextPageParam: (lastGroup) => {
      return (
        lastGroup.next && new URL(lastGroup.next).searchParams.get('offset')
      );
    },
  });

  if (!query.data)
    return {
      blocks: [],
      query,
    };

  const blocks = query.data.pages.reduce(
    (acc, item) => [...acc, ...item.results],
    [] as Block[],
  );

  return { blocks, query };
};
