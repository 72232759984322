import { api, File } from '@api/index';

type BlockTag = {
  id: string;
  tag: string;
  tags: string[];
  file: File;
  height: number;
  width: number;
};

export type BlockTags = Array<BlockTag>;

export const fetchTags = async (): Promise<BlockTag[]> => {
  const { data } = await api.get('/blocks/tags/label_next');
  return data;
};
