import { format } from 'date-fns';

type DateStyle = 'comprehensive';

export const getDate = (d: string, type: DateStyle) => {
  switch (type) {
    case 'comprehensive':
    default:
      return format(new Date(d), `MMM d yyyy 'at' h:mm aaa`);
  }
};

export const getDateCommaSeparatedYear = (d: string, type: DateStyle) => {
  switch (type) {
    case 'comprehensive':
    default:
      return format(new Date(d), `MMM d, yyyy 'at' h:mm aaa`);
  }
};
