import { TwitterPickerStylesProps } from 'react-color/lib/components/twitter/Twitter';

interface Classes<T> {
  default: Partial<T>;
  [scope: string]: Partial<T>;
}

export const style: Partial<Classes<TwitterPickerStylesProps>> | undefined = {
  default: {
    input: {
      display: 'none',
    },
    hash: {
      display: 'none',
    },
  },
};

export const colors: string[] | undefined = [
  '#000000',
  '#666666',
  '#999999',
  '#B7B7B7',
  '#CCCCCC',
  '#D9D9D9',
  '#F3F3F3',
  '#FFFFFF',
  '#F4CCCC',
  '#FCE5CD',
  '#FFF9C7',
  '#D9EAD3',
  '#D0E0E3',
  '#C9DAF8',
  '#D9D2E9',
  '#EAD1DC',
  '#CC4125',
  '#F6B26B',
  '#FFF066',
  '#93C47D',
  '#76A5AF',
  '#6D9EEB',
  '#8E7CC3',
  '#C27BA0',
  '#5B0F00',
  '#783F04',
  '#7F6B00',
  '#274E13',
  '#0C343D',
  '#274E13',
  '#20124D',
  '#4C1130',
];
