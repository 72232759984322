import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useUnit } from 'effector-react';
import { motion } from 'framer-motion';

import { Tooltip } from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { generateId } from '@src/shared/utils/id';

import {
  $hideColoursOnPicker,
  $searchFullScreen,
  $selectedColours,
  movedColour,
  removedColour,
  revealColoursOnPicker,
  SearchColour,
  selectedColour,
  setSearchFullScreen,
  updatedColours,
} from '../../model';
import { rgbToHex } from '../../util/colour';
import { ExpandButton } from '../expand-button';
import {
  generateColouredCanvas,
  getCoordinatesForHex,
  recalculateColourPositions,
} from './utils';

import styles from './styles.module.css';

export const ColourSelector = () => {
  const [selectedColours, searchFullScreen, hideColoursOnPicker] = useUnit([
    $selectedColours,
    $searchFullScreen,
    $hideColoursOnPicker,
  ]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const colourContainerRef = useRef<HTMLDivElement>(null);
  const [canvasSize, setCanvasSize] = useState<
    { width: number; height: number } | undefined
  >(undefined);
  const [draggedColour, setDraggedColour] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartPos = useRef<{ x: number; y: number } | null>(null);

  const { width } = useWindowSize();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || canvasSize) return;

    setCanvasSize({
      width: canvas.getBoundingClientRect().width,
      height: canvas.getBoundingClientRect().height - 4,
    });
  }, [width, searchFullScreen, setCanvasSize]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;

        // Update state
        setCanvasSize({ width, height });

        // Update canvas internal dimensions
        canvas.width = width;
        canvas.height = height;

        // Redraw canvas content if necessary
        generateColouredCanvas({
          canvas,
          canvasSize: { width, height },
        });
      }
    });

    resizeObserver.observe(canvas);

    return () => resizeObserver.disconnect();
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (selectedColours.length >= 5) return;

    const canvas = canvasRef.current;
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();
    const ctx = canvas.getContext('2d', { willReadFrequently: true });
    if (!ctx) return;

    const x = Math.floor(e.clientX - rect.left);
    const y = Math.floor(e.clientY - rect.top);

    const pixel = ctx.getImageData(x, y, 1, 1).data;
    // const rgbColor = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
    const hexColor = rgbToHex(pixel[0], pixel[1], pixel[2]);

    const colorId = generateId();

    selectedColour({ hex: hexColor, x, y, id: colorId });
  };

  const calculateHexAtPosition = useCallback((x: number, y: number) => {
    const canvas = canvasRef.current;
    if (!canvas) return '#000';
    const ctx = canvas.getContext('2d');
    if (!ctx) return '#000';

    const pixel = ctx.getImageData(x, y, 1, 1).data;
    return rgbToHex(pixel[0], pixel[1], pixel[2]);
  }, []);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, id: string) => {
      setDraggedColour(id);
      setIsDragging(true);
      dragStartPos.current = { x: e.clientX, y: e.clientY };
    },
    [],
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        !isDragging ||
        !canvasRef.current ||
        !draggedColour ||
        !dragStartPos.current ||
        !canvasSize
      )
        return;

      const deltaX = e.clientX - dragStartPos.current.x;
      const deltaY = e.clientY - dragStartPos.current.y;

      const colour = selectedColours.find((c) => c.id === draggedColour);

      if (!colour) {
        console.error('colour not found');
        return;
      }

      if (!colour.x || !colour.y) {
        const { x, y } = getCoordinatesForHex(colour.hex, canvasSize);

        movedColour({ x, y, id: draggedColour, hex: colour.hex });

        return;
      }

      const newX = Math.max(0, Math.min(colour.x + deltaX, canvasSize.width));
      const newY = Math.max(0, Math.min(colour.y + deltaY, canvasSize.height));

      const hex = calculateHexAtPosition(newX, newY);

      movedColour({ x: newX, y: newY, id: draggedColour, hex });
      dragStartPos.current = { x: e.clientX, y: e.clientY };
    },
    [
      isDragging,
      canvasRef,
      draggedColour,
      selectedColours,
      canvasSize,
      movedColour,
    ],
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setDraggedColour(null);
    dragStartPos.current = null;
  }, []);

  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>, id: string) => {
      e.preventDefault(); // Prevent scrolling while dragging
      const touch = e.touches[0];
      setDraggedColour(id);
      setIsDragging(true);
      dragStartPos.current = { x: touch.clientX, y: touch.clientY };
    },
    [],
  );

  const handleTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      e.preventDefault(); // Prevent scrolling while dragging
      if (
        !isDragging ||
        !canvasRef.current ||
        !draggedColour ||
        !dragStartPos.current ||
        !canvasSize
      )
        return;

      const touch = e.touches[0];
      const deltaX = touch.clientX - dragStartPos.current.x;
      const deltaY = touch.clientY - dragStartPos.current.y;

      const colour = selectedColours.find((c) => c.id === draggedColour);

      if (!colour) {
        console.error('colour not found');
        return;
      }

      if (!colour.x || !colour.y) {
        const { x, y } = getCoordinatesForHex(colour.hex, canvasSize);
        movedColour({ x, y, id: draggedColour, hex: colour.hex });
        return;
      }

      const newX = Math.max(0, Math.min(colour.x + deltaX, canvasSize.width));
      const newY = Math.max(0, Math.min(colour.y + deltaY, canvasSize.height));

      const hex = calculateHexAtPosition(newX, newY);

      movedColour({ x: newX, y: newY, id: draggedColour, hex });
      dragStartPos.current = { x: touch.clientX, y: touch.clientY };
    },
    [
      isDragging,
      canvasRef,
      draggedColour,
      selectedColours,
      canvasSize,
      movedColour,
      calculateHexAtPosition,
    ],
  );
  const selectedMemoedColours = useMemo(() => {
    if (!canvasSize) return [];

    return selectedColours
      .map((colour) => {
        if (!colour.x || !colour.y) {
          // Find colour in the canvas
          const hex = colour.hex;

          const { x, y } = getCoordinatesForHex(hex, canvasSize);

          const updatedColour = {
            ...colour,
            x,
            y,
          };

          return updatedColour;
        }

        return colour;
      })
      .filter((colour) => {
        if (colour === undefined) {
          console.error('undefined colour');
        }
        return colour !== undefined;
      });
  }, [selectedColours, getCoordinatesForHex, canvasSize]);

  return (
    <div
      ref={colourContainerRef}
      className={styles.container}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleMouseUp}
    >
      <ExpandButton
        className={styles.expandButton}
        onClick={() => {
          setSearchFullScreen(!searchFullScreen);

          setTimeout(() => {
            if (!canvasRef.current) return;

            const _updatedColours = recalculateColourPositions(
              selectedColours,
              canvasRef.current,
            );

            if (!_updatedColours) {
              revealColoursOnPicker();
              return;
            }

            updatedColours(_updatedColours);

            revealColoursOnPicker();
          }, 100);
        }}
      >
        {searchFullScreen ? (
          <Icon name="sprite/arrow-shrink" color="black" />
        ) : (
          <Icon name="sprite/arrow-expand" color="black" />
        )}
      </ExpandButton>
      <motion.canvas
        initial={{
          // y: 40,
          opacity: 0,
        }}
        animate={{
          // y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
          type: 'tween',
          opacity: {
            delay: 0.3,
          },
        }}
        className={styles.canvas}
        ref={canvasRef}
        onClick={handleClick}
      ></motion.canvas>
      {hideColoursOnPicker
        ? null
        : selectedMemoedColours.map((colour) => (
            <PickerSwatch
              key={colour.id}
              colour={colour}
              isDragging={isDragging}
              draggedColour={draggedColour}
              handleMouseDown={handleMouseDown}
              handleTouchStart={handleTouchStart}
              // handleTouchMove={handleTouchMove}
              canvasSize={canvasSize}
              calculateHexAtPosition={calculateHexAtPosition}
            />
          ))}
    </div>
  );
};

const PickerSwatch = ({
  canvasSize,
  colour,
  isDragging,
  draggedColour,
  handleMouseDown,
  handleTouchStart,
  // handleTouchMove,
  calculateHexAtPosition,
}: {
  canvasSize: { width: number; height: number } | undefined;
  colour: SearchColour;
  isDragging: boolean;
  draggedColour: string | null;
  calculateHexAtPosition: (x: number, y: number) => string;
  handleMouseDown: (e: React.MouseEvent<HTMLDivElement>, id: string) => void;
  handleTouchStart: (e: React.TouchEvent<HTMLDivElement>, id: string) => void;
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (!isFocused || !colour.x || !colour.y) return;

      const step = 2;
      let newX = colour.x;
      let newY = colour.y;

      switch (e.key) {
        case 'ArrowUp':
          newY = Math.max(0, colour.y - step);
          break;
        case 'ArrowDown':
          newY = Math.min((canvasSize?.height || 0) - 40, colour.y + step);
          break;
        case 'ArrowLeft':
          newX = Math.max(0, colour.x - step);
          break;
        case 'ArrowRight':
          newX = Math.min((canvasSize?.width || 0) - 40, colour.x + step);
          break;
        default:
          return;
      }

      e.preventDefault();

      // Get colour from canvas
      const newColour = calculateHexAtPosition(newX, newY);

      movedColour({
        id: colour.id,
        x: newX,
        y: newY,
        hex: newColour,
      });
    },
    [isFocused, colour, canvasSize],
  );

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
      role="button"
      tabIndex={0}
      aria-label={`Selected color ${colour.hex}. Click to drag or remove.`}
      key={colour.id}
      style={
        {
          backgroundColor: colour.hex,
          '--x': `${colour.x}px`,
          '--y': `${colour.y}px`,
          borderColor:
            colour?.y && colour.y < (canvasSize ? canvasSize.height : 0) / 4
              ? '#85736C'
              : '#D8C2BA',
          cursor:
            isDragging && draggedColour === colour.id ? 'grabbing' : 'grab',
        } as React.CSSProperties
      }
      className={styles.colour}
      onMouseDown={(e) => {
        if (e.target !== e.currentTarget) {
          // Button clicked, so return early to prevent dragging state from starting
          return;
        }
        handleMouseDown(e, colour.id);
      }}
      onTouchStart={(e) => {
        if (e.target !== e.currentTarget) return;
        handleTouchStart(e, colour.id);
      }}
    >
      <Tooltip
        parameter={{
          description: 'Remove color',
          type: 'plain',
          position: 'top',
          hasVisualBoundary: true,
        }}
        className={styles.removeColourButtonTooltip}
      >
        <button
          onClick={() => {
            removedColour(colour.id);
          }}
          data-dragging={isDragging}
          className={styles.removeColourButton}
        >
          <Icon name="sprite/x" className={styles.removeColourIcon} />
        </button>
      </Tooltip>
    </div>
  );
};
