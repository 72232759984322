import { useUnit } from 'effector-react';

import { SideSheet } from '@visualist/design-system/src/components/v2';

import { generateLookupKey } from '@api/billing';
import {
  $isAnnualToggle,
  $planModal,
  closedPlanModal,
  PlanModalState,
} from '@pages/AccountPage/models';
import { formatAnnualPrice } from '@pages/AccountPage/utils';
import { useBilling } from '@src/shared/queries/useBilling';

import { Plan, PLAN_SHEET_DATA } from '../types';

import styles from './styles.module.css';

export const PlanSheet = () => {
  const [planModalState, isAnnualToggle] = useUnit([
    $planModal,
    $isAnnualToggle,
  ]);
  const showSheet = planModalState.isPlanModalOpen;

  return (
    <>
      <UpgradeDowngradeSheet
        isAnnualToggle={isAnnualToggle}
        showSheet={showSheet && planModalState.mode === 'upgrade-downgrade'}
        closeModal={closedPlanModal}
        planModalState={planModalState}
      />
      {/* <BillingFrequencySheet
        showSheet={showSheet && planModalState.mode === 'billing-frequency'}
        closeModal={closedPlanModal}
      /> */}
    </>
  );
};

// type BillingFrequencyProps = {
//   showSheet: boolean;
//   closeModal: () => void;
// };

// const BillingFrequencySheet = ({
//   showSheet,
//   closeModal,
// }: BillingFrequencyProps) => {
//   const {
//     query: { data },
//     membershipQuery,
//     checkoutURLMutation: { mutate, isLoading },
//   } = useBilling(closeModal);
//   if (!data?.membershipTierInfo || !membershipQuery.data) return null;
//   const isUserAnnual = data.membershipTierInfo.isAnnual;

//   // Key for the monthly or yearly plan version of the current plan. For pro_yearly this gives us pro_monthly
//   const lookupKey = generateLookupKey(
//     data.membershipTierInfo.plan,
//     !isUserAnnual,
//   );

//   const stripePriceId =
//     membershipQuery.data.availablePlans[lookupKey].stripePriceId;

//   const handleCheckout = () => {
//     mutate({
//       stripePriceId,
//       currentPlan: data.membershipTierInfo.plan,
//       planToChangeTo: data.membershipTierInfo.plan,
//       isAnnual: !isUserAnnual,
//     });
//   };

//   return (
//     <SideSheet showSheet={showSheet} handleBackdropClick={closeModal}>
//       <SideSheet.Heading closeSideSheet={closeModal}>
//         Update billing
//       </SideSheet.Heading>
//       <SideSheet.Body closeSideSheet={closeModal}>
//         <SideSheet.SubHeading>
//           {isUserAnnual
//             ? 'Switch to monthly billing'
//             : 'Switch to yearly billing'}
//         </SideSheet.SubHeading>
//         <SideSheet.Content>
//           {isUserAnnual
//             ? 'Your current plan is still active.'
//             : 'Great choice.'}
//           <br />
//           <br />
//           {isUserAnnual
//             ? 'We’ll start billing you on a monthly basis when this annual plan ends.'
//             : 'We’ll switch you to yearly billing today so you can start saving 15%.'}
//         </SideSheet.Content>
//       </SideSheet.Body>
//       <SideSheet.BottomControls
//         isDisabled={isLoading}
//         primaryLabel="Switch"
//         closeSideSheet={closeModal}
//         mainClick={handleCheckout}
//       />
//     </SideSheet>
//   );
// };

type UpgradeDowngradeProps = {
  isAnnualToggle: boolean;
  showSheet: boolean;
  closeModal: () => void;
  planModalState: PlanModalState;
};

export const UpgradeDowngradeSheet = ({
  isAnnualToggle,
  showSheet,
  closeModal,
  planModalState,
}: UpgradeDowngradeProps) => {
  return (
    <SideSheet
      className={styles.sideSheetContainer}
      showSheet={showSheet}
      handleBackdropClick={closeModal}
      closeSideSheet={closeModal}
    >
      <UpgradeDowngradeSheetContent
        closeModal={closeModal}
        planModalState={planModalState}
        isAnnualToggle={isAnnualToggle}
      />
    </SideSheet>
  );
};

const UpgradeDowngradeSheetContent = ({
  closeModal,
  planModalState,
  isAnnualToggle,
}: {
  closeModal: () => void;
  planModalState: PlanModalState;
  isAnnualToggle: boolean;
}) => {
  const {
    query: { data },
    membershipQuery,
    checkoutURLMutation: { mutate, isPending },
  } = useBilling(closeModal);

  if (
    planModalState.mode !== 'upgrade-downgrade' ||
    !data ||
    !membershipQuery.data
  )
    return null;

  const lookupKey = generateLookupKey(
    planModalState.planToChange,
    isAnnualToggle,
  );

  const stripePriceId =
    membershipQuery.data.availablePlans[lookupKey].stripePriceId;

  const handleCheckout = () => {
    // When plan to change to is free, we just send back 'free' vs a strippriceid as there is no free product.
    mutate({
      stripePriceId:
        planModalState.planToChange === 'free'
          ? planModalState.planToChange
          : stripePriceId,
      currentPlan: data.membershipTierInfo.plan,
      planToChangeTo: planModalState.planToChange,
      isAnnual: isAnnualToggle,
    });
  };

  const currentPlan = planModalState.planModalMode.split('-')[0] as Plan;
  const mode = planModalState.planModalMode;

  const hubCredits = 0;

  const numberOfHubs = data.hubs_billing ? data.hubs_billing.hubs_number : 0;

  return (
    <>
      <SideSheet.Heading closeSideSheet={closeModal}>
        Update your plan
      </SideSheet.Heading>
      <SideSheet.Body>
        <div>
          <SideSheet.SubHeading>
            {SHEET_COPY[mode].heading}
          </SideSheet.SubHeading>
          <SideSheet.SubSubHeading>
            $
            {formatAnnualPrice(
              membershipQuery.data.availablePlans[lookupKey].unitPrice,
              isAnnualToggle,
            )}{' '}
            per month
          </SideSheet.SubSubHeading>
        </div>
        <SideSheet.ContentContainer>
          <SideSheet.ContentHeading>
            {getBilledTimeCopy(currentPlan, data.membershipTierInfo.isAnnual)}
          </SideSheet.ContentHeading>
          <SideSheet.Content>
            {getContentCopy(mode, isAnnualToggle, numberOfHubs)}
          </SideSheet.Content>
          <SideSheet.InfoText>
            {infoTextCopy({
              hubCredits,
              currentPlan,
              planToChangeTo: planModalState.planToChange,
              numberOfHubs: numberOfHubs,
            })}
          </SideSheet.InfoText>
        </SideSheet.ContentContainer>
      </SideSheet.Body>
      <SideSheet.BottomControls
        isDisabled={isPending}
        primaryLabel={SHEET_COPY[mode].buttonLabel}
        closeSideSheet={closeModal}
        mainClick={handleCheckout}
      />
    </>
  );
};

const infoTextCopy = ({
  hubCredits,
  currentPlan,
  planToChangeTo,
  numberOfHubs,
}: {
  hubCredits: number;
  currentPlan: Plan;
  planToChangeTo: Plan;
  numberOfHubs: number;
}) => {
  if (hubCredits && currentPlan === 'pro') {
    return (
      <>
        {`You still have ${hubCredits} hub credits.`}
        <br />
        <br />
        Don’t worry. Your hub credits never expire. You can use them when you
        use Pro again.
      </>
    );
  }

  if (
    currentPlan === 'pro' &&
    (planToChangeTo === 'free' || planToChangeTo === 'starter') &&
    numberOfHubs >= 1
  ) {
    return (
      <>
        Your hubs will then be locked. Remember to notify any guests as they’ll
        also lose access.
        <br />
        <br />
        Don’t worry. You can switch back to Pro anytime.
      </>
    );
  }

  return null;
};

const getContentCopy = (
  key: keyof PLAN_SHEET_DATA,
  isAnnualToggle: boolean,
  numberOfHubs: number,
) => {
  switch (key) {
    case 'pro-free':
    case 'starter-free':
      return (
        <>
          You can make updates at anytime and continue to enjoy your current
          plan until the last day of your billing cycle.
          <br />
          <br />
          Your changes will take effect on your next billing date.
        </>
      );
    case 'pro-starter':
      return (
        <>
          You can make updates at anytime and continue to enjoy your current
          plan.
          <br />
          <br />
          Your changes will take effect on your next billing date.
        </>
      );
    case 'free-starter':
      return (
        <>
          Your plan changes will take effect immediately.
          <br />
          <br />
          {isAnnualToggle
            ? "You'll be charged today, and then yearly on this date."
            : "You'll be charged today, and then monthly on this date."}
        </>
      );
    case 'free-pro':
    case 'starter-pro':
    default:
      return (
        <>
          Your plan changes will take effect immediately.
          <br />
          <br />
          {isAnnualToggle
            ? "You'll be charged today, and then yearly on this date."
            : "You'll be charged today, and then monthly on this date."}
          {numberOfHubs > 0 ? (
            <>
              <br />
              <br />
              We'll unlock your hubs right away.
            </>
          ) : null}
        </>
      );
  }
};

const proAndHobbytoFree = {
  heading: 'Switch to Free',
  subHeading: 'per month',
  buttonLabel: 'Switch',
};

const SHEET_COPY: PLAN_SHEET_DATA = {
  'pro-starter': {
    heading: 'Switch to Starter',
    subHeading: 'per month',
    buttonLabel: 'Switch',
  },
  'pro-free': proAndHobbytoFree,
  'starter-free': proAndHobbytoFree,
  'free-starter': {
    heading: 'Upgrade to Starter',
    subHeading: 'per month',
    buttonLabel: 'Upgrade',
  },
  'free-pro': {
    heading: 'Upgrade to Pro',
    subHeading: 'per month',
    buttonLabel: 'Upgrade',
  },
  'starter-pro': {
    heading: 'Upgrade to Pro',
    subHeading: 'per month',
    buttonLabel: 'Upgrade',
  },
};

export type SheetCopy = keyof typeof SHEET_COPY;

const getBilledTimeCopy = (currentPlan: Plan, isAnnual: boolean) => {
  if (currentPlan === 'pro' || currentPlan === 'starter') {
    return `You’re currently billed ${isAnnual ? 'yearly' : 'monthly'}.`;
  }

  return `You'll be billed ${isAnnual ? 'yearly' : 'monthly'}.`;
};
