import { createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';
import { createGate } from 'effector-react';

export const hubDialogOpened = createEvent();
export const hubDialogClosed = createEvent();

export const hubRenamingDialogOpened = createEvent();
export const hubRenamingDialogClosed = createEvent();

export const hubIdSelected = createEvent<string>();
export const hubIdCleared = createEvent();

export const hubNameEntered = createEvent<string>();
export const hubNameCleared = createEvent();

export const hubDescriptionEntered = createEvent<string>();
export const hubDescriptionCleared = createEvent();

export const locationEntered = createEvent<string>();
export const locationCleared = createEvent();
export const locationSelected = createEvent<string>();

// Creation dialog
export const $isShowHubDialog = createStore(false);

$isShowHubDialog.on(hubDialogOpened, () => true).reset(hubDialogClosed);

// Renaming dialog
export const $isShowHubRenamingDialog = createStore(false);

$isShowHubRenamingDialog
  .on(hubRenamingDialogOpened, () => true)
  .reset(hubRenamingDialogClosed);

sample({
  clock: [hubDialogClosed, hubRenamingDialogClosed],
  target: [
    hubIdCleared,
    hubNameCleared,
    hubDescriptionCleared,
    locationCleared,
  ],
});

// Id
export const $hubId = createStore<string | null>(null);

$hubId.on(hubIdSelected, (_, id) => id).reset(hubIdCleared);

// Name
export const $hubName = createStore('');

$hubName.on(hubNameEntered, (_, hubName) => hubName).reset(hubNameCleared);

// Description
export const $hubDescription = createStore('');

$hubDescription
  .on(hubDescriptionEntered, (_, hubDescription) => hubDescription)
  .reset(hubDescriptionCleared);

// Location
export const $location = createStore('');
export const $selectedLocation = createStore('');

$location.on(locationEntered, (_, location) => location).reset(locationCleared);

$selectedLocation
  .on(locationSelected, (_, location) => location)
  .reset(locationCleared);

export const Gate = createGate<{ placePredictions: any[] }>();

export const autocompleteClosed = createEvent();
export const autocompleteOpened = createEvent();

// Shortcut: hub creation and renaming dialogs
hotkey({
  key: 'Escape',
  filter:
    ($isShowHubDialog || $isShowHubRenamingDialog) &&
    Gate.state.map(({ placePredictions }) => placePredictions?.length === 0),
  target: [hubDialogClosed, hubRenamingDialogClosed],
});

export const $isAutocompleteOpen = createStore(true);

$isAutocompleteOpen
  .on(autocompleteClosed, () => false)
  .reset(autocompleteOpened);

// Shortcut: google autocomplete
hotkey({
  key: 'Escape',
  filter: $isAutocompleteOpen,
  target: autocompleteClosed,
});
