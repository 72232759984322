import { Icon, IconProps } from './Icon';

export const VaiIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M8.5 14.5C7.39543 14.5 6.5 13.6046 6.5 12.5C6.5 11.3954 7.39543 10.5 8.5 10.5C9.60457 10.5 10.5 11.3954 10.5 12.5C10.5 13.6046 9.60457 14.5 8.5 14.5Z"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M14.1111 8.2296L11 9L14.1111 9.86732L14.9704 13L15.8889 9.86732L19 9L15.8889 8.2296L15 5L14.1111 8.2296Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="square"
        fill="none"
      />
      <rect
        x="15.4"
        y="15.4"
        width="2.7"
        height="2.7"
        stroke="currentColor"
        strokeWidth="0.8"
        fill="none"
      />
      <path
        d="M18.5 16C19.0483 15.9292 19.9507 15.5 20 14.5C20.0493 13.5 19.5 13 19 12.5C18.5 12 17.8244 11.4331 17.1104 10.8348"
        stroke="currentColor"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M10.3693 13.9846C10.6167 14.0836 10.8432 14.292 11.0656 14.4329C11.8272 14.9155 12.6814 15.3023 13.5318 15.5978C14.2079 15.8327 14.5 16 15 16"
        stroke="currentColor"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M7.09933 10.5951C7.00615 10.557 6.92134 10.4962 6.83939 10.4395C6.44624 10.1676 6.06961 9.8607 5.77003 9.48532C5.50945 9.15881 5.30895 8.78823 5.17854 8.39107C5.07201 8.06662 4.9496 7.57237 5.09449 7.2361C5.26324 6.84446 5.7124 6.66174 6.08601 6.54655C7.02198 6.25798 8.02358 6.2887 8.9812 6.43292C10.1443 6.60809 11.2728 6.94892 12.3667 7.37308"
        stroke="currentColor"
        strokeLinecap="round"
        fill="none"
      />
    </Icon>
  );
};

export const VaiVerticalIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M8.5 2L7.5 11L5.5 12L7.5 13L8.5 22L9.5 13L11.5 12L9.5 11L8.5 2Z"
        stroke="currentColor"
        strokeLinecap="square"
        fill="none"
      />
      <path
        d="M16.8439 12C17.9659 12.6 20.0418 14.16 19.3685 15.6C18.6953 17.04 12.2153 16.5 10.9529 18.3C10.1114 19.2 9.8589 21 15.5815 21"
        stroke="currentColor"
        strokeLinecap="square"
        fill="none"
      />
      <path
        d="M14.1667 7.09091L11.5 7.90909L14.1667 8.72727L15.5 12L16.8333 8.72727L19.5 7.90909L16.8333 7.09091L15.5 3L14.1667 7.09091Z"
        stroke="currentColor"
        strokeLinecap="square"
        fill="none"
      />
    </Icon>
  );
};

export const VaiStarIcon = (props: IconProps) => {
  return (
    <Icon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M6.84026 6.9407L2.56248 8L6.84026 9.19256L8.02174 13.5L9.28471 9.19256L13.5625 8L9.28471 6.9407L8.06248 2.5L6.84026 6.9407Z"
        fill="url(#paint0_linear_15968_239)"
        stroke="#6B1888"
        strokeLinecap="square"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15968_239"
          x1="8.06248"
          y1="2.5"
          x2="8.06248"
          y2="13.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE2B5" />
          <stop offset="0.5" stopColor="#E2C9EB" />
          <stop offset="1" stopColor="#B3D4C1" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export const VaiColouredPathIcon = ({
  isPurple,
  ...props
}: IconProps & { isPurple?: boolean }) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      {/* Path */}
      <path
        id="path"
        d="M10.5 7C4.90063 4.93087 2.34658 6.46956 2.01908 7.54076C1.64514 8.47749 1.61552 10.9159 4.48856 13.1755C6.49237 14.9503 11.5 18.8 15.5 20C19.5 21.2 21.1667 20.1667 21.5 19.5C22.3333 18.5 22.6 15.5 17 11.5"
        stroke="url(#paint1_linear_0_5)"
        strokeLinecap="round"
      />

      {/* Star */}
      <path
        id="star"
        d="M12.8403 7.9407L8.56248 9L12.8403 10.1926L14.0217 14.5L15.2847 10.1926L19.5625 9L15.2847 7.9407L14.0625 3.5L12.8403 7.9407Z"
        fill={isPurple ? '#E2C9EB' : 'url(#paint0_linear_15968_481)'}
        stroke="#6B1888"
        strokeLinecap="square"
      />
      {/* Rectangle */}
      <rect
        id="square"
        x="14.0625"
        y="18"
        width="4"
        height="4"
        fill={isPurple ? '#E2C9EB' : 'url(#paint4_linear_15968_481)'}
        stroke={'#6B1888'}
      />
      {/* Circle */}
      <path
        id="circle"
        d="M6.06248 19C4.40563 19 3.06248 17.6569 3.06248 16C3.06248 14.3431 4.40563 13 6.06248 13C7.71934 13 9.06248 14.3431 9.06248 16C9.06248 17.6569 7.71934 19 6.06248 19Z"
        fill={isPurple ? '#E2C9EB' : 'url(#paint5_linear_15968_481)'}
        stroke="#6B1888"
      />
      <defs>
        <linearGradient
          id="paint1_linear_0_5"
          x1="11.8979"
          y1="5.97792"
          x2="11.8979"
          y2="20.5519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isPurple ? '#E2C9EB' : '#FFE2B5'} />
          <stop
            offset={isPurple ? '0.479167' : '0.5'}
            stopColor={isPurple ? '#9E4BBB' : '#E2C9EB'}
          />
          <stop offset="1" stopColor={isPurple ? '#6B1888' : '#B3D4C1'} />
        </linearGradient>
        <linearGradient
          id="paint0_linear_15968_481"
          x1={isPurple ? '14.4252' : '14.0625'}
          y1={isPurple ? '1.21029e-07' : '3.5'}
          x2={isPurple ? '13.4252' : '14.0625'}
          y2={isPurple ? '12' : '14.5'}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isPurple ? '#E2C9EB' : '#FFE2B5'} />
          <stop
            offset={isPurple ? '0.479167' : '0.5'}
            stopColor={isPurple ? '#9E4BBB' : '#E2C9EB'}
          />
          <stop offset="1" stopColor={isPurple ? '#6B1888' : '#B3D4C1'} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_15968_481"
          x1={isPurple ? '14.9252' : '16.0625'}
          y1={isPurple ? '15' : '17.5'}
          x2={isPurple ? '15.0625' : '16.0625'}
          y2={isPurple ? '21.5' : '22.5'}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isPurple ? '#E2C9EB' : '#FFE2B5'} />
          <stop
            offset={isPurple ? '0.479167' : '0.5'}
            stopColor={isPurple ? '#9E4BBB' : '#E2C9EB'}
          />
          <stop offset="1" stopColor={isPurple ? '#6B1888' : '#B3D4C1'} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_15968_481"
          x1={isPurple ? '8.92524' : '9.56248'}
          y1={isPurple ? '12' : '16'}
          x2={isPurple ? '1.92524' : '2.56248'}
          y2={isPurple ? '15' : '16'}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isPurple ? '#E2C9EB' : '#FFE2B5'} />
          <stop
            offset={isPurple ? '0.479167' : '0.5'}
            stopColor={isPurple ? '#9E4BBB' : '#E2C9EB'}
          />
          <stop offset="1" stopColor={isPurple ? '#6B1888' : '#B3D4C1'} />
        </linearGradient>
      </defs>
    </Icon>
  );
};
