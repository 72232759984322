import React from 'react';

import { useUnit } from 'effector-react';
import {
  animate,
  AnimationPlaybackControls,
  motion,
  useMotionValue,
  useTransform,
} from 'framer-motion';

import { VaiColored } from '@visualist/design-system/src/components/v2';

import {
  $showVaiTidyupModal,
  closedVaiTidyupModal,
  openedShuffler,
} from '@pages/StudioPage/model';
import {
  VaiButton,
  VaiButtonIcon,
  VaiButtonText,
} from '@src/entities/vai/ui/button';
import {
  Card,
  CardButtons,
  CardContent,
  CardHeader,
  CardHeaderText,
  CardText,
} from '@src/entities/vai/ui/card';
import { VaiPopup } from '@src/entities/vai/ui/popup';

import styles from './styles.module.css';

export const VaiTidyPopupModal = () => {
  const [showVaiTidyupModal] = useUnit([$showVaiTidyupModal]);
  const text = `You’ve got a nice selection of\nimages in Studio. Let Vai help you\ntidy them up?`;

  const textLength = useMotionValue(0);

  React.useEffect(() => {
    let controls: AnimationPlaybackControls;
    if (showVaiTidyupModal) {
      const timeout = setTimeout(() => {
        controls = animate(textLength, text.length, {
          duration: 4,
        });
      }, 500);
      return () => {
        controls.stop();
        clearTimeout(timeout);
      };
    }
  }, [showVaiTidyupModal]);

  const roundedTextLength = useTransform(textLength, (latest) =>
    Math.round(latest),
  );
  const displayedText = useTransform(roundedTextLength, (latest) =>
    text.slice(0, latest),
  );

  return (
    <VaiPopup showPopup={showVaiTidyupModal} width={300}>
      <Card>
        <CardHeader onClose={() => closedVaiTidyupModal()}>
          <VaiColored />
          <CardHeaderText>Vai, your AI assistant</CardHeaderText>
        </CardHeader>
        <CardContent>
          <CardText>
            <motion.span className={styles.text}>{displayedText}</motion.span>
          </CardText>
        </CardContent>
        <CardButtons>
          <VaiButton onClick={() => openedShuffler()}>
            <VaiButtonIcon />
            <VaiButtonText>Tidy up now</VaiButtonText>
          </VaiButton>
        </CardButtons>
      </Card>
    </VaiPopup>
  );
};
