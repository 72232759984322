import { createEvent, createStore } from 'effector';

export type SnackState = {
  type?: 'longer action';
  label: string;
  action?: {
    label: string;
    action: () => void;
  };
  close?: boolean;
  delayTime?: number;
};

type StoredSnackState = SnackState | null;

export const closedSnack = createEvent();
export const startedSnack = createEvent<SnackState>();

let timeout: NodeJS.Timeout;

export const $snack = createStore<StoredSnackState>(null)
  .on(startedSnack, (state, payload) => {
    if (state) {
      clearTimeout(timeout);
    }
    // Set a timeout with a default delayTime of 5000ms if it's not provided
    timeout = setTimeout(() => {
      closedSnack();
    }, payload.delayTime ?? 5000);
    return payload;
  })
  .on(closedSnack, () => clearTimeout(timeout))
  .reset(closedSnack);
