import React, { ReactNode } from 'react';

import PhotoAvatar from './Photo';

import styles from './Badge.module.css';

export interface BadgeProps {
  node: ReactNode;
}

const Badge = ({ node }: BadgeProps): JSX.Element => {
  return (
    <div className={styles.badge}>
      {typeof node === 'string' ? <PhotoAvatar photo={node} size={16} /> : node}
    </div>
  );
};

export default Badge;
