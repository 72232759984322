import { isSameDay, isThisMonth } from 'date-fns';

import { Thread } from '@api/messaging';

export const convertEmailDate = (date: string) => {
  // Return the time as 10:12am if within the last week otherwise show the month and day as Aug 1
  const dateObj = new Date(date);
  const now = new Date();
  const startOfWeek = new Date();
  startOfWeek.setDate(now.getDate() - ((now.getDay() + 6) % 7));
  startOfWeek.setHours(0, 0, 0, 0);

  if (dateObj.getTime() >= startOfWeek.getTime()) {
    return formatTime(dateObj);
  } else {
    return dateObj.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  }
};

const formatTime = (date: Date) => {
  return date
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    .toLowerCase();
};

export const formatDateTodayYesterday = (sentAt: string) => {
  const sentAtDate = new Date(sentAt);
  const compareDate = new Date(sentAt);
  compareDate.setHours(0, 0, 0, 0);
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const diff = now.getTime() - compareDate.getTime();
  const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) {
    return `Today at ${formatTime(sentAtDate)}`;
  }

  if (diffInDays === 1) {
    return `Yesterday at ${formatTime(sentAtDate)}`;
  }

  return `${sentAtDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })} at ${formatTime(sentAtDate)}`;
};

export const formatSenderToReturnNameOrEmail = (sender: Thread['sender']) => {
  const { first_name, last_name, email } = sender;

  if (first_name || last_name) {
    return `${first_name ?? ''} ${last_name ?? ''}`;
  }

  return email;
};

export const generateInitials = (sender: Thread['sender']) => {
  const { first_name, last_name, email } = sender;

  if (!first_name && !last_name) {
    return email[0];
  }

  return `${first_name?.[0] ?? ''}${last_name?.[0] ?? ''}`;
};

export const formatName = (sender: Thread['sender']) => {
  const { first_name, last_name, email } = sender;

  if (first_name || last_name) {
    let name = '';
    if (first_name) name += first_name;
    if (last_name) name += ` ${last_name}`;
    return name;
  }

  return email;
};

export function sortMessagesByDate(a: Thread, b: Thread) {
  return new Date(b.sent_at).getTime() - new Date(a.sent_at).getTime();
}

const DaysOfWeekIndex = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
} as const;

export const SortedMessageLabels = {
  today: 'Today',
  yesterday: 'Yesterday',
  remainingDaysInWeek: 'This week',
  thisMonth: 'This month',
  lastMonth: 'Last month',
  earlier: 'Earlier',
} as const;

/**
 * Creates an array of objects for the type of dates we want to display
 * Show in the following order:
 * Today, Yesterday, and then the remaining days in the week.
 * This month
 * Last month
 * Earlier
 *
 * @param messages - The array of messages to sort.
 * @returns
 */

// TODO Change these types to use a map https://stackoverflow.com/questions/46233280/preserving-type-when-using-object-keys

const InitialSortedMessagesEmpty: {
  today: Thread[];
  yesterday: Thread[];
  remainingDaysInWeek: Record<
    (typeof DaysOfWeekIndex)[keyof typeof DaysOfWeekIndex],
    Thread[]
  >;
  thisMonth: Thread[];
  lastMonth: Thread[];
  earlier: Thread[];
} = {
  today: [],
  yesterday: [],
  remainingDaysInWeek: {
    Sunday: [],
    Saturday: [],
    Friday: [],
    Thursday: [],
    Wednesday: [],
    Tuesday: [],
    Monday: [],
  },
  thisMonth: [],
  lastMonth: [],
  earlier: [],
};

export function constructMessagesByDate(
  messages: Thread[] | undefined,
  today: Date,
) {
  if (!messages || messages.length === 0)
    return { ...InitialSortedMessagesEmpty };

  const messagesMut = [...messages];

  const result = structuredClone(InitialSortedMessagesEmpty);

  // Messages from today
  for (let i = 0; i < messagesMut.length; i++) {
    const message = messagesMut[i];
    const sentAt = new Date(message.sent_at);
    if (isSameDay(sentAt, today)) {
      messagesMut.splice(i, 1);
      i--;

      result.today.push(message);
    }
  }

  // Messages from yesterday
  for (let i = 0; i < messagesMut.length; i++) {
    const message = messagesMut[i];
    const sentAt = new Date(message.sent_at);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    if (isSameDay(sentAt, yesterday)) {
      messagesMut.splice(i, 1);
      i--;

      result.yesterday.push(message);
    }
  }

  // Messages from this week
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - ((today.getDay() + 6) % 7));
  startOfWeek.setHours(0, 0, 0, 0);

  const endOfWeek = new Date(today);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(0, 0, 0, 0);

  for (let i = 0; i < messagesMut.length; i++) {
    const message = messagesMut[i];
    const sentAt = new Date(message.sent_at);

    if (
      sentAt.getDate() >= startOfWeek.getDate() &&
      sentAt.getDate() <= endOfWeek.getDate() &&
      sentAt.getMonth() === today.getMonth() &&
      sentAt.getFullYear() === today.getFullYear()
    ) {
      messagesMut.splice(i, 1);
      i--;

      const dayIndex = getAdjustedDay(sentAt);
      const dayOfWeek =
        DaysOfWeekIndex[dayIndex as keyof typeof DaysOfWeekIndex];
      result.remainingDaysInWeek[dayOfWeek].push(message);
    }
  }

  // Messages from this month
  for (let i = 0; i < messagesMut.length; i++) {
    const message = messagesMut[i];
    const sentAt = new Date(message.sent_at);
    if (isThisMonth(sentAt)) {
      messagesMut.splice(i, 1);
      i--;

      result.thisMonth.push(message);
    }
  }

  // Messages from last month
  for (let i = 0; i < messagesMut.length; i++) {
    const message = messagesMut[i];
    const sentAt = new Date(message.sent_at);
    if (isLastMonth(sentAt)) {
      messagesMut.splice(i, 1);
      i--;

      result.lastMonth.push(message);
    }
  }

  // Messages from earlier
  for (let i = 0; i < messagesMut.length; i++) {
    const message = messagesMut[i];
    const sentAt = new Date(message.sent_at);
    if (sentAt < new Date(today.getFullYear(), today.getMonth() - 1, 5)) {
      messagesMut.splice(i, 1);
      i--;

      result.earlier.push(message);
    }
  }

  return result;
}

export function isLastMonth(date: Date): boolean {
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  lastMonth.setDate(1); // Set to the first day of the last month
  lastMonth.setHours(0, 0, 0, 0);

  return (
    date.getMonth() === lastMonth.getMonth() &&
    date.getFullYear() === lastMonth.getFullYear()
  );
}

function getAdjustedDay(date: Date): number {
  const day = date.getDay();
  return day === 0 ? 6 : day - 1; // Sunday becomes 6, Monday becomes 0
}

const ICON_NAMES_TO_ICON_PATH = {
  pdf: 'sprite/file-pdf-colored',
  ai: 'sprite/file-ai-colored',
  psd: 'sprite/file-psd-colored',
  txt: 'sprite/file-txt-colored',
  xls: 'sprite/file-xls-colored',
  mp3: 'sprite/file-mp3-colored',
  png: 'sprite/file-png-colored',
  mp4: 'sprite/file-mp4-colored',
  indd: 'sprite/file-indd-colored',
  ppt: 'sprite/file-ppt-colored',
  html: 'sprite/file-html-colored',
  eml: 'sprite/file-eml-colored',
  cal: 'sprite/file-cal-colored',
  sql: 'sprite/file-sql-colored',
  zip: 'sprite/file-zip-colored',
  sketch: 'sprite/file-sketch-colored',
  '3d': 'sprite/file-3d-colored',
  fig: 'sprite/file-fig-colored',
  ae: 'sprite/file-ae-colored',
} as const;

const AVAILABLE_ICONS_NAMES = Object.keys(
  ICON_NAMES_TO_ICON_PATH,
) as (keyof typeof ICON_NAMES_TO_ICON_PATH)[];

// Content types mapping to icon names
// .ai files have the content type of application/pdf
// Following content types are not supported with built in mime types
// any adobe? .ai, .ae, .sketch, .3d, .obj, .mtl, .fig
const CONTENT_TYPE_TO_ICON_TYPE = {
  // .pdf
  'application/pdf': 'pdf',
  // .ai DOUBLE CHECK
  'application/illustrator': 'ai',
  // .psd
  'image/vnd.adobe.photoshop': 'psd',
  'application/x-photoshop': 'psd',
  'application/photoshop': 'psd',
  'application/psd': 'psd',
  'image/psd': 'psd',
  'image/x-photoshop': 'psd',
  // .txt
  'text/plain': 'txt',
  'application/rtf': 'txt',
  'application/txt': 'txt',
  'application/msword': 'txt',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'txt',
  // .xls
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
  // .mp3
  'audio/mpeg': 'mp3',
  // .png
  'image/jpeg': 'png',
  'image/png': 'png',
  'image/apng': 'png',
  'image/avif': 'png',
  'image/heic': 'png',
  'image/vnd.microsoft.icon': 'png',
  'image/svg+xml': 'png',
  'image/tif': 'png',
  'image/tiff': 'png',
  'image/gif': 'png',
  'image/bmp': 'png',
  'image/webp': 'png',
  // .mp4
  'video/mp4': 'mp4',
  'video/mpeg': 'mp4',
  'video/quicktime': 'mp4',
  'video/webm': 'mp4',
  'video/ogg': 'mp4',
  'video/avi': 'mp4',
  'video/wmv': 'mp4',
  'video/x-msvideo': 'mp4',
  'video/mp2t': 'mp4',
  // .indd DOUBLE CHECK
  'application/x-indesign': 'indd',
  // .ppt
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'ppt',
  'text/csv': 'ppt',
  // .html
  'text/html': 'html',
  'text/javascript': 'html',
  'text/css': 'html',
  'text/xml': 'html',
  // .eml
  'message/rfc822': 'eml',
  // .cal
  'text/calendar': 'cal',
  // .sql
  'application/sql': 'sql',
  // .zip
  'application/zip': 'zip',
  'application/x-zip-compressed': 'zip',
} satisfies Record<string, keyof typeof ICON_NAMES_TO_ICON_PATH>;

const iconNameAssert = (
  name: string,
): (typeof AVAILABLE_ICONS_NAMES)[number] | null => {
  if (AVAILABLE_ICONS_NAMES.includes(name as any)) {
    return name as (typeof AVAILABLE_ICONS_NAMES)[number];
  }

  return null;
};

const mimeTypeAssert = (
  mimeType: string,
): keyof typeof CONTENT_TYPE_TO_ICON_TYPE | null => {
  const keys = Object.keys(CONTENT_TYPE_TO_ICON_TYPE) as any[];

  if (keys.includes(mimeType)) {
    return mimeType as keyof typeof CONTENT_TYPE_TO_ICON_TYPE;
  }

  return null;
};

export function getFileIcon(fileName: string, mimeType: string) {
  // Check file extension
  const fileExtension = fileName.split('.');

  if (fileExtension.length === 1) {
    // No extension
    return null;
  }

  const extension = fileExtension[1];

  const iconName = iconNameAssert(extension);

  if (iconName) {
    return ICON_NAMES_TO_ICON_PATH[iconName];
  }

  // Fallback to mime type
  const mimeName = mimeTypeAssert(mimeType);

  if (mimeName) {
    return ICON_NAMES_TO_ICON_PATH[CONTENT_TYPE_TO_ICON_TYPE[mimeName]];
  }

  return 'sprite/file-txt-colored';
}

// File names
const FILE_LENGTH_LIMIT = 12;
export const formatFileName = (
  name: string,
  optionalFileLengthLimit?: number,
) => {
  const fileLengthLimit = optionalFileLengthLimit || FILE_LENGTH_LIMIT;

  const nameParts = name.split('.');

  const hasExtension = nameParts.length > 1;

  let fileName = '';
  let extension = '';
  if (hasExtension) {
    extension = nameParts[nameParts.length - 1];
    nameParts.splice(nameParts.length - 1, 1);
    fileName = nameParts.join('.');
  } else {
    fileName = nameParts[0];
  }

  if (fileName.length > fileLengthLimit) {
    const truncatedName = fileName.slice(0, fileLengthLimit);
    return `${truncatedName}....${extension}`;
  }
  return name;
};
