import cn from 'classnames';
import { isMobileOnly } from 'react-device-detect';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import { DocumentHead } from '@components/DocumentHead';
import { PageLoader } from '@components/PageLoader';
import { useBilling } from '@src/shared/queries/useBilling';
import { useQuery } from '@tanstack/react-query';

import { getHubs } from '../../api/hubs';
import { LibraryActions } from '../../components/LibraryActions';
import { ALL_HUBS_QUERY } from '../../shared/constants/query-names';
import { FileCard } from '..';
import { HomeBanner } from './HomeBanner';
import { Hubs } from './Hubs';
import { useUserActions } from './model/queries/useUserActions';
import { useUserMessages } from './model/queries/useUserMessages';
import { Overview } from './Overview';
import { VaiCard } from './vai-card';
import { WelcomeMessage } from './WelcomeMessage';

import styles from './styles.module.css';

export const HomePage = () => {
  const {
    query: { isLoading, isError, isSuccess, data },
  } = useBilling();

  const { data: hubs = [] } = useQuery({
    queryKey: [ALL_HUBS_QUERY],
    queryFn: () => getHubs({ ordering: '-created_at' }),
  });

  const { items } = useUserActions();
  const { messagesQuery } = useUserMessages();

  // To be replaced with snackbars
  if (isLoading) return <PageLoader />;

  if (isError) return <p>Error</p>;

  if (isSuccess && data) {
    const plan = data.membershipTierInfo.plan;

    return (
      <div className={styles.homePage}>
        <FileCard />
        <div
          className={cn(styles.container, {
            [styles.mobile]: isMobileOnly,
          })}
        >
          <DocumentHead name="Home" />
          <WelcomeMessage />
          <TypographyPoppins
            type="headline"
            headlineSize="M"
            className={styles.header}
          >
            Dashboard
          </TypographyPoppins>
          <ul className={styles.cardsList}>
            <VaiCard />
            <Overview
              actions={items.data?.count}
              messages={messagesQuery.data}
            />
          </ul>
          <div className={styles.bannerContainer}>
            <HomeBanner plan={plan} />
          </div>
          <Hubs hubs={hubs} plan={plan} />
          <LibraryActions />
        </div>
      </div>
    );
  }
  return <p>Something went wrong?</p>;
};
