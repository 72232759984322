import { useUnit } from 'effector-react';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { $fileType } from '@pages/LibraryPage/model';
import { MutateOptions, useMutation } from '@tanstack/react-query';

import { bulkDeleteBlock } from '../../../../../api/blocks';
import { queryClient } from '../../../../../queryClient';
import {
  ALL_BOARD_IMAGES_QUERY,
  BOARD_IMAGES_QUERY,
  LIBRARY_QUERY,
} from '../../../../../shared/constants/query-names';

export const useDeleteBDC = (options?: MutateOptions<any, any, any>) => {
  const fileType = useUnit($fileType);

  const getSuccessLabel = () => {
    if (fileType === 'Image') {
      return 'Deleted image';
    } else if (fileType === 'Set') {
      return 'Deleted design';
    } else {
      return 'Deleted palette';
    }
  };

  const getErrorLabel = () => {
    if (fileType === 'Image') {
      return "Couldn't delete image";
    } else if (fileType === 'Set') {
      return "Couldn't delete design";
    } else {
      return "Couldn't delete palette";
    }
  };

  const { mutate: removeBlock } = useMutation({
    mutationFn: (blockId: string[] | null) =>
      bulkDeleteBlock({ block_ids: blockId }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_IMAGES_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARD_IMAGES_QUERY],
      });

      if (variables?.length === 1) {
        startedSnack({
          label: getSuccessLabel(),
          close: true,
        });
      } else {
        startedSnack({
          label: 'Deleted files',
          close: true,
        });
      }

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      startedSnack({
        label: getErrorLabel(),
        action: {
          label: 'Try again',
          action: () => removeBlock(variables),
        },
        close: true,
      });
      if (options?.onError) options?.onError(error, variables, context);
    },
  });

  return { removeBlock };
};
