import React from 'react';

import { useUnit } from 'effector-react';
import { Stage } from 'konva/lib/Stage';

import { useTextBoxes } from '@pages/StudioPage/hooks/useTextBoxes';
import {
  $currentTool,
  $stageState,
  changedTool,
  selectedTextBlockIdsAction,
} from '@pages/StudioPage/model';

// import { TextBox } from '../Textbox';
import { Textbox } from '../Textbox';

type Props = {
  designId: string;
  stageRef: React.MutableRefObject<Stage | null>;
  updateThumbnail: () => void;
};

const DEFAULT_TEXT_FONT_SIZE = 80;

export const TextBoxes = ({ designId, stageRef, updateThumbnail }: Props) => {
  const [currentTool, stageState] = useUnit([$currentTool, $stageState]);
  const { textBoxQuery, addNewTextbox } = useTextBoxes({ designId });
  const [hoveredSelection, setHoveredSelection] = React.useState('');
  const averageFontSize =
    (textBoxQuery.data?.reduce(
      (acc, textbox) =>
        acc + (textbox.text_format.additionalProp1 || DEFAULT_TEXT_FONT_SIZE),
      0,
    ) || DEFAULT_TEXT_FONT_SIZE) / (textBoxQuery.data?.length || 1) || 0;

  const addTextComponent = (e: MouseEvent) => {
    if (currentTool !== 'add-text' || !stageRef.current || hoveredSelection)
      return;
    const s = stageRef.current;

    // Get x and y of click
    const pointerX = e.offsetX;
    const pointerY = e.offsetY;

    const x = (pointerX - s.x()) / s.scaleX();
    const y = (pointerY - s.y()) / s.scaleY();

    const id = `temp-text-box-${Date.now()}`;

    addNewTextbox({
      id,
      layer: 0,
      position_x: x,
      position_y: y,
      set_block: designId,
      text: '',
      text_format: {
        additionalProp1: Math.round(averageFontSize),
        alignment: 'left',
        bold: false,
        italic: false,
        underline: false,
      },
      width: averageFontSize / 0.8,
    });
    selectedTextBlockIdsAction(new Set([id]));

    changedTool('select');

    updateThumbnail();
  };

  React.useEffect(() => {
    if (!stageRef.current) return;

    stageRef.current.container().addEventListener('click', addTextComponent);

    return () => {
      if (!stageRef.current) return;
      // Remove event listener
      stageRef.current
        .container()
        .removeEventListener('click', addTextComponent);
    };
  }, [currentTool, stageState, hoveredSelection]);

  React.useEffect(() => {
    return () => {
      // Refetch on unmount so data is fresh when navigating back
      textBoxQuery.refetch();
    };
  }, []);

  if (textBoxQuery.isLoading) return null;

  if (!textBoxQuery.data) return null;

  return (
    <>
      {textBoxQuery.data.map((textbox) => (
        <Textbox
          id={textbox.id}
          defaultFontSize={textbox.text_format.additionalProp1 || 80}
          defaultAlignment={textbox.text_format.alignment}
          defaultBold={textbox.text_format.bold}
          defaultItalic={textbox.text_format.italic}
          defaultUnderline={textbox.text_format.underline}
          defaultRotation={textbox.text_format.rotation}
          key={textbox.id}
          defaultText={textbox.text}
          defaultX={textbox.position_x}
          defaultY={textbox.position_y}
          defaultWidth={textbox.width}
          defaultColour={textbox.text_format.colour}
          designId={designId}
          hoveredSelection={hoveredSelection}
          setHoveredSelection={setHoveredSelection}
          updateThumbnail={updateThumbnail}
        />
      ))}
    </>
  );
};
