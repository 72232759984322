import { ElementRef, useRef } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { motion } from 'framer-motion';
import { isMobileOnly } from 'react-device-detect';

import {
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { BlockResponse } from '@api/services';

import { $openPanel, toggledPanel } from '../model';
import { themeSelected } from './model/active-theme';
import {
  $isThemeCreation,
  $themePlaceholder,
  themeCreationEnabled,
} from './model/create-theme';
import {
  $isExistingThemeUpdating,
  existingThemePlaceholderCleared,
  existingThemeUpdatingCompleted,
} from './model/edit-theme';
import { Theme } from './theme';

import styles from './styles.module.css';

type Props = {
  block: BlockResponse;
};

export const ColourThemes = ({ block }: Props) => {
  const openPanel = useUnit($openPanel);

  const themePlaceholder = useUnit($themePlaceholder);
  const isThemeCreation = useUnit($isThemeCreation);
  const isExistingThemeUpdating = useUnit($isExistingThemeUpdating);

  const isExtended = openPanel === 'colour';
  const containerRef = useRef<ElementRef<'div'>>(null);

  const togglePanel = () => {
    toggledPanel('colour');
  };

  const placeholder = isThemeCreation ? themePlaceholder : [];

  const sortedDefaultPalettes = block.default_palettes.sort((a, b) => {
    const indexA = desiredOrder.indexOf(a.theme);
    const indexB = desiredOrder.indexOf(b.theme);

    return indexA - indexB;
  });

  // Hide panel when block type is colour
  if (block.block_type === 'Colour palette') return null;

  return (
    <div
      ref={containerRef}
      id="color-theme-panel"
      className={cn(styles.cardsColorTheme, {
        [styles.cardsColorThemeMobile]: isMobileOnly,
      })}
    >
      <header className={styles.titleColourThemes} onClick={togglePanel}>
        <TypographyPoppins type="title" titleSize="M" className={styles.label}>
          Color themes
        </TypographyPoppins>
        <div className={styles.icons}>
          <IconButton
            className={styles.colorDropper}
            iconStyles={styles.colorDropper}
            type="unfilled"
            icon={
              <Icon
                name="sprite/color-dropper"
                className={styles.icon}
                size={34}
              />
            }
            onClick={(e) => {
              e.stopPropagation();
              if (isExistingThemeUpdating) {
                existingThemeUpdatingCompleted();
                existingThemePlaceholderCleared();
              }
              themeCreationEnabled();
              themeSelected(0);
            }}
          />
          <IconButton
            className={styles.themesExpands}
            type="unfilled"
            icon={
              <Icon
                name="sprite/chevron-down"
                className={styles.icon}
                style={{
                  transform: `rotate(${isExtended ? '180deg' : '0deg'})`,
                }}
              />
            }
            onClick={() => {}}
          />
        </div>
      </header>

      <ul className={styles.themes}>
        {[
          ...placeholder,
          ...block.custom_palettes,
          ...sortedDefaultPalettes,
        ].map((palette, index) => (
          <>
            {!isExtended ? (
              index === 0 && (
                <motion.li className={styles.theme}>
                  <Theme
                    palette={palette}
                    fileId={block.id}
                    themeIndex={index}
                  />
                </motion.li>
              )
            ) : (
              <motion.li className={styles.theme}>
                <Theme palette={palette} fileId={block.id} themeIndex={index} />
              </motion.li>
            )}
          </>
        ))}
      </ul>
    </div>
  );
};

export const ColourPanelSkeleton = () => {
  return <div className={styles.skeleton} />;
};

const desiredOrder = [
  'Colourful',
  'Scientific',
  'Light',
  'Dark',
  'Warm',
  'Cool',
  'Neutral',
  'Monochromatic',
];
