import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import {
  CHECKOUT_SUCCESS,
  saveCheckoutFailureToLocalStorage,
  saveHubCreditCheckoutBundleId,
} from '@visualist/design-system/src/components/v2/SnackBar/utils';

import {
  getHubCreditsBalance,
  getHubProducts,
  postHubCreditBundle,
  PurchasePurpose,
} from '@api/billing';
import {
  generateCurrentUrl,
  saveValueTolocalStorage,
} from '@pages/AccountPage/utils';
import {
  HUB_CREDIT_QUERY,
  HUB_PRODUCTS_QUERY,
} from '@src/shared/constants/query-names';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useHubCredits = () => {
  const queryClient = useQueryClient();

  const hubCreditQuery = useQuery({
    queryKey: [HUB_CREDIT_QUERY],
    queryFn: getHubCreditsBalance,
  });

  const hubProductsQuery = useQuery({
    queryKey: [HUB_PRODUCTS_QUERY],
    queryFn: getHubProducts,
  });

  const mutation = useMutation({
    mutationFn: ({
      stripePriceId,
      // Disabled as used in on success and not in main call
      // eslint-disable-next-line
      lookupKey,
      quantity,
      purchaseCharge,
      redirectURL,
      purchasePurpose,
      purchaseName,
    }: {
      quantity: number;
      purchaseCharge: number;
      stripePriceId: string;
      redirectURL?: string;
      purchasePurpose: PurchasePurpose;
      purchaseName?: string;
      lookupKey: string;
    }) => {
      return postHubCreditBundle({
        stripePriceId,
        redirectURL: redirectURL ? redirectURL : generateCurrentUrl(),
        quantity,
        purchasePurpose,
        purchaseCharge,
        purchaseName,
      });
    },
    onMutate: () => {
      startedSnack({
        label: 'Checkout in progress...',
        close: true,
      });
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [HUB_CREDIT_QUERY] });
      if (data.data.data.checkout_url) {
        saveValueTolocalStorage(
          CHECKOUT_SUCCESS,
          `We've added ${data.data.data.credits_added} credits`,
        );
        saveCheckoutFailureToLocalStorage('hub-checkout-failure');
        saveHubCreditCheckoutBundleId(variables.lookupKey);
        window.location.href = data.data.data.checkout_url;
      }
    },
    onError: (_, variables) => {
      startedSnack({
        label: 'Oops, something went wrong',
        action: {
          label: 'Try again',
          action: () => {
            mutation.mutate({
              stripePriceId: variables.stripePriceId,
              redirectURL: variables.redirectURL,
              purchasePurpose: variables.purchasePurpose,
              purchaseCharge: variables.purchaseCharge,
              quantity: variables.quantity,
              lookupKey: variables.lookupKey,
            });
          },
        },
        close: true,
      });
    },
  });

  return {
    hubCreditQuery,
    hubProductsQuery,
    buyHubCredits: mutation,
  };
};
