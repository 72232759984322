import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

type HeaderProps = {
  title: string;
  description?: string;
};

export const Header = ({ title, description }: HeaderProps) => (
  <header className={styles.header}>
    <TypographyPoppins className={styles.title} type="title" titleSize="M">
      {title}
    </TypographyPoppins>
    <TypographyPoppins className={styles.description} type="body" bodySize="S">
      {description}
    </TypographyPoppins>
  </header>
);
