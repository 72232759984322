import React from 'react';

import { useUnit } from 'effector-react';
import { AnimatePresence } from 'framer-motion';
import { Group as TGroup } from 'konva/lib/Group';
import { Stage } from 'konva/lib/Stage';
import { debounce } from 'lodash';
import { Group, Rect } from 'react-konva';
import { Html } from 'react-konva-utils';

import {
  EDITING_CARD_SIZE,
  EXPANDED_CARD_SIZE,
  LARGE_CARD_SIZE,
  PADDING_SIZE,
} from '@pages/FileCard/Stickies/constants';
import { useImages } from '@pages/StudioPage/hooks/useImages';
import { useTextBoxes } from '@pages/StudioPage/hooks/useTextBoxes';
import { $currentTool } from '@pages/StudioPage/model';
import { calculateTextAndImageBounds } from '@pages/StudioPage/utils';
import { BaseSticky } from '@src/entities/Stickies/BaseSticky';
import {
  $editingStickyId,
  $hoveredStickyId,
  editedSticky,
  hoveredSticky,
  selectedSticky,
} from '@src/entities/Stickies/model';
import { StickyType } from '@src/entities/Stickies/types';
import {
  calculateTextHeight,
  checkIfAttached,
  computeStickPositionFromRatio,
  computeStickyPositionToRatio,
  sortByUpdatedAt,
} from '@src/entities/Stickies/utils';
import useStickies from '@src/shared/queries/useStickies';
import { Timeout } from '@src/shared/types';
import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from '@tanstack/react-query';

export const Stickies = (props: {
  stageRef: React.RefObject<Stage>;
  designId: string;
  dimensions: {
    height: number;
    width: number;
  };
}) => {
  const queryClient = useQueryClient();
  const [currentTool] = useUnit([$currentTool]);
  const showStickies = currentTool === 'sticky';

  const { stickiesQuery } = useStickies(props.designId, null);
  const { imageQuery } = useImages({ designId: props.designId });
  const { textBoxQuery } = useTextBoxes({ designId: props.designId });

  const { minY, maxY, minX, maxX } = React.useMemo(() => {
    return calculateTextAndImageBounds({
      images: imageQuery.data?.data.results,
      textboxes: textBoxQuery.data,
    });
  }, [imageQuery.data, textBoxQuery.data]);

  return (
    <AnimatePresence>
      {stickiesQuery.data
        ? stickiesQuery.data.stickies.sort(sortByUpdatedAt).map((s, i) => {
            if (!props.stageRef.current) return null;

            return (
              <Sticky
                showStickies={showStickies}
                stage={{
                  scale: {
                    x: props.stageRef.current.scaleX(),
                    y: props.stageRef.current.scaleY(),
                  },
                  x: props.stageRef.current.x(),
                  y: props.stageRef.current.y(),
                }}
                stageRef={props.stageRef}
                dimensions={props.dimensions}
                designId={props.designId}
                index={i}
                sticky={s}
                key={s.id}
                queryClient={queryClient}
                computeStickyPositionToRatio={(x, y) => {
                  return computeStickyPositionToRatio({
                    x,
                    y,
                    minX,
                    minY,
                    maxX,
                    maxY,
                  });
                }}
                computeStickPositionFromRatio={(ratioX, ratioY) => {
                  return computeStickPositionFromRatio({
                    ratioX,
                    ratioY,
                    minX,
                    minY,
                    maxX,
                    maxY,
                  });
                }}
              />
            );
          })
        : null}
    </AnimatePresence>
  );
};

type Props = {
  showStickies: boolean;
  queryClient: QueryClient;
  sticky: StickyType;
  index: number;
  designId: string;
  dimensions: {
    height: number;
    width: number;
  };
  stage: {
    scale: {
      x: number;
      y: number;
    };
    x: number;
    y: number;
  };
  computeStickyPositionToRatio: (
    x: number,
    y: number,
  ) => { ratioX: number; ratioY: number };
  computeStickPositionFromRatio: (
    ratioX: number,
    ratioY: number,
  ) => { x: number; y: number };
  stageRef: React.RefObject<Stage>;
};

export const Sticky = (props: Props) => {
  const [hoveredStickyId, editingStickyId] = useUnit([
    $hoveredStickyId,
    $editingStickyId,
  ]);

  // x: initialX, y: initialY
  const { x, y } = props.computeStickPositionFromRatio(
    props.sticky.left_pixel,
    props.sticky.top_pixel,
  );

  // Local Stage
  const [text, setText] = React.useState(props.sticky.text);
  // const [x, setX] = React.useState(initialX);
  // const [y, setY] = React.useState(initialY);
  const [isDragging, setIsDragging] = React.useState(false);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [initialAnimationComplete, setInitialAnimationComplete] =
    React.useState(props.sticky.id === 'new-sticky');

  // Refs
  const divRef = React.useRef<React.ElementRef<'div'>>(null);
  const groupRef = React.useRef<TGroup>(null);
  const stickySaveTimeout = React.useRef<Timeout | null>(null);
  const clickTimeout = React.useRef<Timeout | null>(null);

  // Derived properties
  const shouldAnimate =
    new Date(props.sticky.created_at).getTime() <
      new Date().getTime() - 10000 || props.sticky.id === 'new-sticky';
  const isEditing = editingStickyId === props.sticky.id;

  const {
    addStickyMutation,
    mutateSticky,
    removeNewStickies,
    optimisticallyUpdateStickyUpdateAt,
  } = useStickies(props.designId, null);

  // React.useEffect(() => {
  //   const { x: initialX, y: initialY } = props.computeStickPositionFromRatio(
  //     props.sticky.left_pixel,
  //     props.sticky.top_pixel,
  //   );

  //   setX(initialX);
  //   setY(initialY);
  // }, [props.sticky]);

  const enterEditMode = () => {
    if (isDragging) return;

    editedSticky(isEditing ? null : props.sticky.id);
  };

  const selectSticky = () => {
    if (isDragging) return;
    selectedSticky(props.sticky.id);
  };

  const handleClick = () => {
    // Do nothing if dropdown is open
    if (showDropdown) return;
    // If double click in progress, cancel single click
    if (clickTimeout.current) return;

    clickTimeout.current = setTimeout(() => {
      if (props.sticky.id === 'new-sticky') {
        enterEditMode();
      } else {
        editedSticky(null);
        selectSticky();
      }

      clickTimeout.current = null;
    }, 200);
  };

  const handleDoubleClick = () => {
    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }
    enterEditMode();
  };

  const saveSticky = ({
    text,
    attachedId,
    attachedType,
  }: {
    text: string;
    attachedType?: string;
    attachedId?: string;
  }) => {
    if (props.sticky.id === 'new-sticky') {
      // Create new sticky with data
      // Set sticky to go back to normal mode

      // Convert coordinates back to ratios

      editedSticky(null);
      stickySaveTimeout.current = setTimeout(() => {
        if (!groupRef.current) return;
        const { ratioX, ratioY } = props.computeStickyPositionToRatio(
          groupRef.current.x(),
          groupRef.current.y(),
        );

        // Set timeout to have stickies start saving after animation ends
        addStickyMutation.mutate({
          data: {
            left_pixel: ratioX,
            top_pixel: ratioY,
            text,
            background_hex: props.sticky.background_hex,
            updated_at: new Date().toISOString(),
            attached_to_id: attachedId,
            attached_to_type: attachedType,
            action_sticky: null,
          },
        });
      }, 800);
    } else {
      if (!groupRef.current) return;
      const { ratioX, ratioY } = props.computeStickyPositionToRatio(
        groupRef.current.x(),
        groupRef.current.y(),
      );

      // Save sticky data
      mutateSticky.mutate({
        ...props.sticky,
        stickyId: props.sticky.id,
        data: {
          left_pixel: ratioX,
          top_pixel: ratioY,
          text,
          background_hex: props.sticky.background_hex,
          updated_at: new Date().toISOString(),
          attached_to_id: attachedId,
          attached_to_type: attachedType,
        },
      });
    }
  };

  const saveStickyDebounced = React.useCallback(
    debounce((text: string) => saveSticky({ text }), 500),
    [props.sticky],
  );

  const onDrag = () => {
    setIsDragging(true);
  };

  const saveDragStateEnd = () => {
    setIsDragging(false);

    if (!groupRef.current?.getAbsolutePosition()) return;

    const point = {
      x: groupRef.current?.getAbsolutePosition().x,
      y: groupRef.current?.getAbsolutePosition().y,
    };

    const attachment = checkIfAttached({
      point,
      stageRef: props.stageRef,
    });

    saveSticky({
      text,
      attachedId: attachment?.id,
      attachedType: attachment?.type,
    });
  };

  const { x: divX, y: divY } = transformPosition({
    relativeX: groupRef.current?.getAbsolutePosition().x,
    relativeY: groupRef.current?.getAbsolutePosition().y,
    cardScale: groupRef.current?.getAbsoluteScale().x,
    isEditing,
  });

  const computedHeight = React.useMemo(() => calculateTextHeight(text), [text]);

  return (
    <Group
      id={props.sticky.id}
      visible={props.showStickies}
      ref={groupRef}
      x={x}
      y={y}
      scale={{
        x: Math.min(1 / props.stage.scale.x, 4),
        y: Math.min(1 / props.stage.scale.y, 4),
      }}
      onContextMenu={(e) => {
        e.evt.preventDefault();
        setShowDropdown((v) => !v);
      }}
      width={LARGE_CARD_SIZE}
      height={LARGE_CARD_SIZE}
      // Prevent sticky dragging when save mutation running
      draggable={!addStickyMutation.isPending}
      onClick={handleClick}
      onDblClick={handleDoubleClick}
      onTap={handleClick}
      onDblTap={handleDoubleClick}
      onDragMove={onDrag}
      onDragEnd={saveDragStateEnd}
      onMouseEnter={() => {
        hoveredSticky(props.sticky.id);
        optimisticallyUpdateStickyUpdateAt(props.sticky.id);
      }}
      onMouseLeave={() => hoveredSticky(null)}
    >
      <Rect
        height={LARGE_CARD_SIZE}
        width={LARGE_CARD_SIZE}
        fill="transparent"
      />
      {props.showStickies ? (
        <Html
          divProps={{
            style: {
              pointerEvents: isEditing || showDropdown ? 'all' : 'none',
              scale: 1,
              zIndex: props.index,
            },
          }}
        >
          <QueryClientProvider client={props.queryClient}>
            <BaseSticky
              layout
              ref={divRef}
              type="medium"
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              initial={{
                width: LARGE_CARD_SIZE,
                height: LARGE_CARD_SIZE,
                scale: shouldAnimate ? 0.8 : 1,
                opacity: shouldAnimate ? 0 : 1,
              }}
              animate={{
                width: isEditing ? EXPANDED_CARD_SIZE : LARGE_CARD_SIZE,
                height: isEditing ? computedHeight : LARGE_CARD_SIZE,
                scale: hoveredStickyId === props.sticky.id ? 1.05 : 1,
                opacity: 1,
                translateX: divX,
                translateY: divY,
              }}
              onAnimationComplete={() => setInitialAnimationComplete(true)}
              transition={{
                type: 'tween',
                duration: 0.5,
                ease: [0.2, 0, 0, 1],
                // Delay causes lag with lots of items
                delay: initialAnimationComplete ? 0 : props.index * 0.05,
              }}
              exit={shouldAnimate ? { scale: 0.8, opacity: 0 } : undefined}
              // whileHover={isEditing ? undefined : { scale: 1.05 }}
              // whileTap={isEditing ? undefined : { scale: 0.95 }}
              blockId={props.designId}
              enableDragControls
              text={text}
              setText={setText}
              removeNewStickies={removeNewStickies}
              saveStickyText={saveStickyDebounced}
              sticky={props.sticky}
              textSize="M"
            />
          </QueryClientProvider>
        </Html>
      ) : null}
    </Group>
  );
};

/**
 * @description
 * This function is used to transform the position of the sticky so that when a sticky is being edited it is always in view. Done by translate the underlying div when in edit mode
 *
 * @param relativeX - The x position of the sticky related to the window
 * @param relativeY - The y position of the sticky related to the window
 * @param cardScale - Scale of the card (Scale changes based on canvas zoom)
 * @param isEditing - Whether or not the sticky is being edited
 *
 */

const transformPosition = ({
  relativeX,
  relativeY,
  isEditing,
  cardScale,
}: {
  isEditing: boolean;
  relativeX?: number;
  relativeY?: number;
  cardScale?: number;
}) => {
  if (!isEditing || !relativeX || !relativeY || !cardScale)
    return { x: 0, y: 0 };

  // Because of the left navbar covering the canvas we need to take that amount in account
  const LEFT_NAV = 80;
  // Value for Top nav bar
  const TOP_NAV = 56;

  // Get the window width and height
  const { innerWidth, innerHeight } = window;

  const expandedCardSize = EXPANDED_CARD_SIZE * cardScale;
  const editingCardHeight = EDITING_CARD_SIZE * cardScale;
  const paddingOnEdges = PADDING_SIZE / 2;

  let newX = 0;
  let newY = 0;

  // Checks right side edge
  if (relativeX + expandedCardSize > innerWidth) {
    // Scale used to convert from normal px vs the canvas px
    const amountOverflowing = Math.abs(
      relativeX + expandedCardSize - innerWidth,
    );

    const amountScaled = (amountOverflowing + paddingOnEdges) / cardScale;

    newX = -amountScaled;
  }

  // Checks left side edge
  if (relativeX < LEFT_NAV) {
    newX = (Math.abs(relativeX) + LEFT_NAV + paddingOnEdges) / cardScale;
  }

  // Check top edge
  if (relativeY < 0) {
    newY = (Math.abs(relativeY) + paddingOnEdges) / cardScale;
  }

  // Checks bottom edge
  if (relativeY + editingCardHeight + TOP_NAV > innerHeight) {
    const amountOverflowing = Math.abs(
      relativeY + editingCardHeight + TOP_NAV - innerHeight,
    );

    const amountScaled = (amountOverflowing + paddingOnEdges) / cardScale;

    newY = -amountScaled;
  }

  return { x: newX, y: newY };
};
