import { MouseEvent, MouseEventHandler, ReactNode } from 'react';

import cn from 'classnames';

type Density = '0' | '-2';

import TypographyPoppins, {
  BodySize,
  HeadlineSize,
  LabelSize,
  Size,
  Variant,
} from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

export interface MenuItemProps {
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  density?: Density;
  supportingText?: string;
  isDisabled?: boolean;
  children: ReactNode;
  className?: string;
  onClickContent?: MouseEventHandler<HTMLLIElement>;
  onClickLeadingIcon?: MouseEventHandler<HTMLSpanElement>;
  isBlur?: boolean;
  onMouseEnter?: MouseEventHandler<HTMLLIElement>;

  type?: Variant;
  size?: Size;
  labelSize?: LabelSize;
  headlineSize?: HeadlineSize;
  titleSize?: Size;
  bodySize?: BodySize;
  classNameLeadingIcon?: string;
  classNameContent?: string;
  classNameLabel?: string;
  classNameTrailingIcon?: string;
}

export const MenuItem = ({
  leadingIcon,
  trailingIcon,
  density,
  supportingText,
  isDisabled,
  children,
  className,
  onClickContent,
  onClickLeadingIcon,
  isBlur,
  onMouseEnter,

  type,
  size,
  labelSize,
  headlineSize,
  titleSize,
  bodySize,
  classNameLeadingIcon,
  classNameContent,
  classNameLabel,
  classNameTrailingIcon,
}: MenuItemProps) => {
  const handleOnClickContent = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    if (onClickContent && !isDisabled) onClickContent(e);
  };

  const handleOnClickLeadingIcon = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (onClickLeadingIcon && !isDisabled) onClickLeadingIcon(e);
  };

  return (
    <li
      className={cn(styles.item, className, {
        [styles.disabled]: isDisabled,
      })}
      onClick={handleOnClickContent}
      onMouseEnter={onMouseEnter}
    >
      {leadingIcon && (
        <span
          className={cn(styles.leadingIcon, classNameLeadingIcon, {
            [styles.density]: density === '-2',
          })}
          onClick={handleOnClickLeadingIcon}
        >
          {leadingIcon}
        </span>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '14px',
          width: '100%',
        }}
        className={cn({
          [styles.leftColumn]: isBlur,
        })}
      >
        <div
          className={cn(styles.content, classNameContent, {
            [styles.withSupportingText]: supportingText,
          })}
        >
          <TypographyPoppins
            className={cn(styles.text, classNameLabel)}
            type={type ? type : 'body'}
            labelSize={labelSize}
            bodySize={bodySize ? bodySize : 'L'}
            headlineSize={headlineSize}
            size={size}
            titleSize={titleSize}
          >
            {children}
          </TypographyPoppins>

          {supportingText && (
            <TypographyPoppins
              className={styles.supportingText}
              type="body"
              bodySize="M"
            >
              {supportingText}
            </TypographyPoppins>
          )}
        </div>
      </div>
      {isBlur && <div className={styles.blur} />}
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        className={cn({
          [styles.rightColumn]: isBlur,
        })}
      >
        {trailingIcon && (
          <span
            className={cn(styles.trailingIcon, classNameTrailingIcon, {
              [styles.density]: density === '-2',
            })}
          >
            {trailingIcon}
          </span>
        )}
      </div>
    </li>
  );
};
