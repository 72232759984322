import React from 'react';

import Icon, { IconProps } from './Icon';

const CheckIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <Icon {...props} fill="currentColor">
      <path
        d="M6.35355 11.6464C6.15829 11.4512 5.84171 11.4512 5.64645 11.6464C5.45118 11.8417 5.45118 12.1583 5.64645 12.3536L6.35355 11.6464ZM10 16L9.64645 16.3536L10 16.7071L10.3536 16.3536L10 16ZM18.3536 8.35355C18.5488 8.15829 18.5488 7.84171 18.3536 7.64645C18.1583 7.45118 17.8417 7.45118 17.6464 7.64645L18.3536 8.35355ZM5.64645 12.3536L9.64645 16.3536L10.3536 15.6464L6.35355 11.6464L5.64645 12.3536ZM10.3536 16.3536L18.3536 8.35355L17.6464 7.64645L9.64645 15.6464L10.3536 16.3536Z"
        fill="currentColor"
      />
    </Icon>
  );
};
export default CheckIcon;
