import { PropsWithChildren } from 'react';

import { motion } from 'framer-motion';
import useMeasure from 'react-use-measure';

import styles from './styles.module.css';

export const AuthCard = (
  props: PropsWithChildren<{
    animated?: boolean;
  }>,
) => {
  const { animated = false } = props;
  const [ref, bounds] = useMeasure();

  if (animated) {
    return (
      <motion.div
        initial={false}
        animate={{
          height: bounds.height > 0 ? bounds.height : undefined,
        }}
        transition={{
          duration: 0.8,
          bounce: 0,
          type: 'spring',
        }}
      >
        <div ref={ref} className={styles.unfixedCardContainer}>
          {props.children}
        </div>
      </motion.div>
    );
  }

  return <div className={styles.cardContainer}>{props.children}</div>;
};

AuthCard.Container = (props: PropsWithChildren) => {
  return <div className={styles.container}>{props.children}</div>;
};
