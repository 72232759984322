import cn from 'classnames';
import { useHistory } from 'react-router';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { BoardLvl1, BoardLvl2, BoardLvl3, Hub } from '@api/designs';

import styles from './styles.module.css';

interface Props {
  board: BoardLvl1 | BoardLvl2 | BoardLvl3 | Hub | null;
  className?: string;
  isShowHub?: boolean;
  isClickable?: boolean;
}

export const Breadcrumbs = ({
  board,
  className,
  isShowHub = true,
  isClickable,
}: Props) => {
  if (!board) return null;

  const history = useHistory();

  return (
    <div className={cn(styles.breadcrumbs, className)}>
      {board && 'type' in board && board.type === 'hub' && (
        <div className={styles.breadcrumb}>
          <div
            className={cn(styles.filterChip, {
              [styles.clickable]: isClickable,
            })}
            onClick={() => {
              if (isClickable) {
                if (board.type === 'hub') {
                  history.push(`/h/${board.id}`);
                } else {
                  history.push(`/board/${board.id}`);
                }
              }
            }}
          >
            <div style={{ display: 'flex' }}>
              <Icon name="sprite/hub" size={12} />
            </div>
            <TypographyPoppins
              type="label"
              labelSize="S"
              className={styles.name}
            >
              {board.name}
            </TypographyPoppins>
          </div>
        </div>
      )}

      {board && 'level' in board && board.level === 0 && (
        <>
          {board.parent !== null && isShowHub && (
            <div className={styles.breadcrumb}>
              <div
                className={cn(styles.filterChip, {
                  [styles.clickable]: isClickable,
                })}
                onClick={() => {
                  if (isClickable) {
                    if (board.parent?.type === 'hub') {
                      history.push(`/h/${board.parent?.id}`);
                    } else {
                      history.push(`/board/${board.parent?.id}`);
                    }
                  }
                }}
              >
                <div style={{ display: 'flex' }}>
                  <Icon name="sprite/hub" size={12} />
                </div>
                <TypographyPoppins
                  type="label"
                  labelSize="S"
                  className={styles.name}
                >
                  {board.parent.name}
                </TypographyPoppins>
              </div>
            </div>
          )}

          <div className={styles.breadcrumb}>
            {board.parent !== null && isShowHub && (
              <div style={{ display: 'flex' }}>
                <Icon name="sprite/dot" size={12} />
              </div>
            )}
            <div
              className={cn(styles.filterChip, {
                [styles.clickable]: isClickable,
              })}
              onClick={() => {
                if (isClickable) {
                  history.push(`/board/${board.id}`);
                }
              }}
            >
              <div style={{ display: 'flex' }}>
                <Icon name="sprite/board" size={12} />
              </div>
              <TypographyPoppins
                type="label"
                labelSize="S"
                className={styles.name}
              >
                {board.name}
              </TypographyPoppins>
            </div>
          </div>
        </>
      )}

      {board && 'level' in board && board.level === 1 && (
        <>
          {board.parent.parent !== null && isShowHub && (
            <div className={styles.breadcrumb}>
              <div
                className={cn(styles.filterChip, {
                  [styles.clickable]: isClickable,
                })}
                onClick={() => {
                  if (isClickable) {
                    if (board.parent.parent?.type === 'hub') {
                      history.push(`/h/${board.parent.parent?.id}`);
                    } else {
                      history.push(`/board/${board.parent.parent?.id}`);
                    }
                  }
                }}
              >
                <div style={{ display: 'flex' }}>
                  <Icon name="sprite/hub" size={12} />
                </div>
                <TypographyPoppins
                  type="label"
                  labelSize="S"
                  className={styles.name}
                >
                  {board.parent.parent.name}
                </TypographyPoppins>
              </div>
            </div>
          )}
          <div className={styles.breadcrumb}>
            {board.parent.parent !== null && isShowHub && (
              <div style={{ display: 'flex' }}>
                <Icon name="sprite/dot" size={12} />
              </div>
            )}
            <div
              className={cn(styles.filterChip, {
                [styles.clickable]: isClickable,
              })}
              onClick={() => {
                if (isClickable) {
                  history.push(`/board/${board.parent.id}`);
                }
              }}
            >
              <div style={{ display: 'flex' }}>
                <Icon name="sprite/board" size={12} />
              </div>
              <TypographyPoppins
                type="label"
                labelSize="S"
                className={styles.name}
              >
                {board.parent.name}
              </TypographyPoppins>
            </div>
          </div>

          <div className={styles.breadcrumb}>
            <div style={{ display: 'flex' }}>
              <Icon name="sprite/dot" size={12} />
            </div>
            <div
              className={cn(styles.filterChip, {
                [styles.clickable]: isClickable,
              })}
              onClick={() => {
                if (isClickable) {
                  history.push(`/board/${board.id}`);
                }
              }}
            >
              <div style={{ display: 'flex' }}>
                <Icon name="sprite/board" size={12} />
              </div>
              <TypographyPoppins
                type="label"
                labelSize="S"
                className={styles.name}
              >
                {board.name}
              </TypographyPoppins>
            </div>
          </div>
        </>
      )}

      {board && 'level' in board && board.level === 2 && (
        <>
          {board.parent.parent.parent !== null && isShowHub && (
            <div className={styles.breadcrumb}>
              <div
                className={cn(styles.filterChip, {
                  [styles.clickable]: isClickable,
                })}
                onClick={() => {
                  if (isClickable) {
                    history.push(`/board/${board.parent.parent.id}`);
                  }
                }}
              >
                <div style={{ display: 'flex' }}>
                  <Icon name="sprite/hub" size={12} />
                </div>
                <TypographyPoppins
                  type="label"
                  labelSize="S"
                  className={styles.name}
                >
                  {board.parent.parent.parent.name}
                </TypographyPoppins>
              </div>
            </div>
          )}

          <div className={styles.breadcrumb}>
            {board.parent.parent.parent !== null && isShowHub && (
              <div style={{ display: 'flex' }}>
                <Icon name="sprite/dot" size={12} />
              </div>
            )}
            <div
              className={cn(styles.filterChip, {
                [styles.clickable]: isClickable,
              })}
              onClick={() => {
                if (isClickable) {
                  history.push(`/board/${board.parent.parent.id}`);
                }
              }}
            >
              <div style={{ display: 'flex' }}>
                <Icon name="sprite/board" size={12} />
              </div>
              <TypographyPoppins
                type="label"
                labelSize="S"
                className={styles.name}
              >
                {board.parent.parent.name}
              </TypographyPoppins>
            </div>
          </div>

          <div className={styles.breadcrumb}>
            <div style={{ display: 'flex' }}>
              <Icon name="sprite/dot" size={12} />
            </div>
            <div
              className={cn(styles.filterChip, {
                [styles.clickable]: isClickable,
              })}
              onClick={() => {
                if (isClickable) {
                  history.push(`/board/${board.parent.id}`);
                }
              }}
            >
              <div style={{ display: 'flex' }}>
                <Icon name="sprite/board" size={12} />
              </div>
              <TypographyPoppins
                type="label"
                labelSize="S"
                className={styles.name}
              >
                {board.parent.name}
              </TypographyPoppins>
            </div>
          </div>

          {board.level === 2 && (
            <div className={styles.breadcrumb}>
              <div style={{ display: 'flex' }}>
                <Icon name="sprite/dot" size={12} />
              </div>
              <div
                className={cn(styles.filterChip, {
                  [styles.clickable]: isClickable,
                })}
                onClick={() => {
                  if (isClickable) {
                    history.push(`/board/${board.id}`);
                  }
                }}
              >
                <div style={{ display: 'flex' }}>
                  <Icon name="sprite/board" size={12} />
                </div>
                <TypographyPoppins
                  type="label"
                  labelSize="S"
                  className={styles.name}
                >
                  {board.name}
                </TypographyPoppins>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
