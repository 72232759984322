import { getInviteListDoc } from '@api/users';
import { MEMBERS_SUGGESTIONS_DOC_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

import { Email } from '../inviting';

type Props = {
  query: string;
  emails: Email[];
  id: string | null;
};

export const useSuggestions = ({ query, emails, id }: Props) => {
  const suggestionsQuery = useQuery({
    queryKey: [MEMBERS_SUGGESTIONS_DOC_QUERY, id, query],
    queryFn: () => getInviteListDoc({ query, id }),
    enabled: query.length > 0 && emails.length === 0 && Boolean(id),
  });

  return {
    suggestionsQuery,
  };
};
