import { Helmet } from 'react-helmet-async';

import { AuthCard } from '@src/entities/auth/auth-card';
import { AuthHeader } from '@src/entities/auth/header';
import { AuthPageContainer } from '@src/entities/auth/page-container';
import { LoginForm } from '@src/widgets/auth/login-form';

import styles from './styles.module.css';

export const Login = () => {
  return (
    <>
      <Helmet>
        <title>Log in to Visualist</title>
      </Helmet>
      <AuthPageContainer>
        <div className={styles.container}>
          <AuthHeader />
          <AuthCard.Container>
            <AuthCard>
              <LoginForm />
            </AuthCard>
          </AuthCard.Container>
        </div>
      </AuthPageContainer>
    </>
  );
};
