import { getActions, SimpleAction } from '@api/actions';
import { actions as actionsKey } from '@src/shared/constants';
import { useInfiniteQuery } from '@tanstack/react-query';

import { ActionFilter } from '../modal';

export const useActions = ({ filters }: { filters: ActionFilter }) => {
  const query = useInfiniteQuery({
    queryKey: actionsKey.filtered(filters),
    queryFn: ({ pageParam }) => {
      const offset = parseInt(pageParam) ?? 0;
      return getActions({ offset, filters });
    },
    initialPageParam: '0',
    getNextPageParam: (lastGroup) => {
      return (
        lastGroup.next && new URL(lastGroup.next).searchParams.get('offset')
      );
    },
  });

  const isLoading = query.isLoading;

  if (!query.data) {
    return {
      actions: [],
      query,
      isLoading,
    };
  }

  const actions = query.data.pages.reduce(
    (acc, item) => [...acc, ...item.results],
    [] as SimpleAction[],
  );

  return {
    actions,
    query,
    isLoading,
  };
};
