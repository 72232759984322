import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { fetchMessages, markThreadAsRead, Thread } from '@api/messaging';
import { messagesKeys } from '@src/shared/constants/query-keys';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

export const useMessages = ({ hubId }: { hubId: string }) => {
  const qc = useQueryClient();

  // Fetch messages
  const query = useInfiniteQuery({
    queryKey: messagesKeys.messages(hubId),
    queryFn: ({ pageParam = '0' }) => fetchMessages(hubId, pageParam, '40'),
    initialPageParam: '0',
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const messages = query.data?.pages.reduce(
    (acc, item) => [...acc, ...item.results],
    [] as Thread[],
  );

  // Mark message as read mutation
  const mutateMessageReadStatus = useMutation({
    mutationFn: ({
      threadId,
      readStatus,
    }: {
      threadId: string;
      readStatus: boolean;
    }) => markThreadAsRead(hubId, threadId, readStatus),
    onSettled: () => {
      qc.invalidateQueries({ queryKey: messagesKeys.messages(hubId) });
    },
    onError: (e, variables) => {
      console.error(e);
      startedSnack({
        label: "Couldn't mark message as read",
        action: {
          label: 'Try again',
          action: () => {
            return mutateMessageReadStatus.mutate({
              threadId: variables.threadId,
              readStatus: variables.readStatus,
            });
          },
        },
      });
    },
  });

  return { messages, query, mutateMessageReadStatus };
};
