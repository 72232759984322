import cn from 'classnames';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { getHubs } from '@api/hubs';
import { useAllBoards } from '@pages/BoardPage/model/queries/useAllBoards';
import { ALL_HUBS_QUERY } from '@src/shared/constants';
import {
  RECENT_BOARDS_KEY,
  RECENT_HUBS_KEY,
} from '@src/shared/constants/variables-local-storage';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';
import { useQuery } from '@tanstack/react-query';

import { RecentSearches } from '../recentSearches';

import styles from './styles.module.css';

export const Suggestions = (props: {
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <div className={styles.container}>
      {/* <div className={styles.suggestionContainer}>
        <SuggestedHubRow />
        <SuggestedBoardRow />
      </div> */}
      <RecentSearches setSearchText={props.setSearchText} />
    </div>
  );
};

export type Suggested = {
  id: string;
  name: string;
};

export const SuggestedHubRow = () => {
  const [lastAccessedHubs] = useLocalStorage<Array<Suggested>>(
    RECENT_HUBS_KEY,
    [],
  );

  const hasLastAccessedHubs = lastAccessedHubs && lastAccessedHubs.length !== 0;

  const { data: hubs = [], isLoading } = useQuery({
    queryKey: [ALL_HUBS_QUERY],
    queryFn: () => getHubs({ ordering: '-created_at' }),
    enabled: !hasLastAccessedHubs,
  });

  const hubData = hasLastAccessedHubs
    ? lastAccessedHubs
    : hubs
        ?.map((h) => ({
          id: h.id,
          name: h.name,
        }))
        .slice(0, 3);

  if (!hasLastAccessedHubs && isLoading) return null;

  if (!hubData) return null;

  return (
    <div className={styles.suggestedContainer}>
      <TypographyPoppins type="body" bodySize="M" className={styles.title}>
        Hubs
      </TypographyPoppins>
      <div className={styles.row}>
        {hubData.map((s) => {
          return (
            <SuggestionChip
              key={s.id}
              name={s.name}
              icon={<Icon name="sprite/hub" height={24} width={24} />}
              onClick={() => {}}
            />
          );
        })}
      </div>
    </div>
  );
};

export const SuggestedBoardRow = () => {
  const [lastAccessedBoards] = useLocalStorage<Array<Suggested>>(
    RECENT_BOARDS_KEY,
    [],
  );

  const hasLastAccessedBoards = lastAccessedBoards.length !== 0;

  const { data, isLoading } = useAllBoards({
    enabled: !hasLastAccessedBoards,
  });

  const boardData = hasLastAccessedBoards
    ? lastAccessedBoards
    : data
        ?.map((b) => ({
          id: b.id,
          name: b.name,
        }))
        .slice(0, 3);

  if (!hasLastAccessedBoards && isLoading) return null;

  if (!boardData) return null;

  return (
    <div className={styles.suggestedContainer}>
      <TypographyPoppins type="body" bodySize="M" className={styles.title}>
        Boards
      </TypographyPoppins>
      <div className={styles.row}>
        {boardData.map((s) => {
          return (
            <SuggestionChip
              key={s.id}
              name={s.name}
              icon={<Icon name="sprite/board" height={24} width={24} />}
              onClick={() => {}}
            />
          );
        })}
      </div>
    </div>
  );
};

export const SuggestionChip = (props: {
  name: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  className?: string;
  titleClassName?: string;
}) => {
  const { name, icon, onClick, className, titleClassName } = props;
  return (
    <button
      onClick={onClick}
      className={cn(styles.suggestionChipContainer, className)}
    >
      <TypographyPoppins
        type="label"
        labelSize="S"
        className={cn(styles.suggestionTitle, titleClassName)}
      >
        {name}
      </TypographyPoppins>
      {icon && <div className={styles.icon}>{icon}</div>}
    </button>
  );
};
