import { useUnit } from 'effector-react';

import {
  VaiModal,
  VaiModalContent,
  VaiModalHeader,
  VaiModalProvider,
} from '@src/entities/vai/ui/modal';

import { $showVaiModal, closedVaiModal } from './model';
import { VaiTabRenderer } from './ui/tab-renderer';
import { VaiTabs } from './ui/Tabs';

export const VaiMain = () => {
  const [showVaiModal] = useUnit([$showVaiModal]);

  const closeModal = () => closedVaiModal();

  return (
    <VaiModalProvider showModal={showVaiModal} closeModal={closeModal}>
      <VaiModal>
        <VaiModalHeader />
        <VaiModalContent>
          <VaiTabs />
          <VaiTabRenderer />
        </VaiModalContent>
      </VaiModal>
    </VaiModalProvider>
  );
};
