import { api } from './services';

type CheckKeyPayload =
  | {
      referral_code: string;
    }
  | { invitation_code: string }
  | { campaign_code: string };

type User = {
  first_name: string | null;
  id: number;
  last_name: string | null;
  photo: any;
};

interface Suggestion {
  email: string;
  boardId: string;
}

export interface ResponseSuggestion {
  created_at: string;
  email: string;
  id: number;
  user: User | null;
}

interface SuggestionHub {
  email: string;
  hubId: string;
}

export interface ResponseSuggestionHub {
  created_at: string;
  email: string;
  id: number;
  user: User | null;
  inviter: string;
}

type SuggestionDoc = {
  query: string;
  id: string | null;
};

// @ts-ignore
export const inviteUser = async (payload) => {
  const { data } = await api.post(
    '/users/invitation/create-and-send/',
    payload,
  );
  return data;
};

export const getReferralUsers = async () => {
  const { data } = await api.get('/users/referral/');
  return data;
};

export const getInviteList = async ({
  email,
  boardId,
}: Suggestion): Promise<ResponseSuggestion[]> => {
  const { data } = await api.get(
    `/users/invitation/?name_email=${email}&exclude_for_board=${boardId}`,
  );
  return data;
};

export const getInviteListHub = async ({
  email,
  hubId,
}: SuggestionHub): Promise<ResponseSuggestionHub[]> => {
  const { data } = await api.get(
    `/users/invitation/?name_email=${email}&exclude_for_hub=${hubId}`,
  );
  return data;
};

export const getInviteListDoc = async ({
  query,
  id,
}: SuggestionDoc): Promise<ResponseSuggestion[]> => {
  const { data } = await api.get(
    `/users/invitation/?name_email=${query}&exclude_for_document=${id}`,
  );
  return data;
};

export const checkKey = async (payload: CheckKeyPayload) => {
  const { data } = await api.post('/auth/check_code/', payload);
  return data;
};
