import { useEffect, useState } from 'react';

import { useUnit } from 'effector-react';
import { motion, Variants } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Icon } from '@visualist/icons';

import { Spinner } from '@components/Spinner';

import { useBlocksForPaletteSearch } from '../../hooks/useSearchPaletteForBlocks';
import {
  $searchFullScreen,
  $selectedSearchedColours,
  setSearchFullScreen,
} from '../../model';
import { ColourResult } from '../colour-result';
import { ExpandButton } from '../expand-button';

import styles from './styles.module.css';

export const ColourResults = () => {
  const [selectedSearchedColours, searchFullScreen] = useUnit([
    $selectedSearchedColours,
    $searchFullScreen,
  ]);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const {
    blocks,
    blockForPaletteQuery: { isLoading, hasNextPage, fetchNextPage },
  } = useBlocksForPaletteSearch({
    colours: selectedSearchedColours.map((colour) => colour.hex),
    mutationOptions: {
      enabled: selectedSearchedColours.length > 0,
    },
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const columnCount = useColumnCount(searchFullScreen);

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    );
  }

  if (!blocks || blocks.length === 0) {
    return null;
  }
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.01,
        type: 'tween',
        ease: 'easeOut',
        duration: 0.2,
      },
    },
  } satisfies Variants;

  const item = {
    hidden: { opacity: 0, scale: 0.8 },
    show: { opacity: 1, scale: 1 },
  };

  return (
    <div className={styles.results}>
      <ExpandButton
        className={styles.expandButton}
        onClick={() => {
          setSearchFullScreen(!searchFullScreen);
        }}
      >
        {searchFullScreen ? (
          <Icon name="sprite/arrow-shrink" />
        ) : (
          <Icon name="sprite/arrow-expand" />
        )}
      </ExpandButton>
      <motion.div
        className={styles.masonry}
        variants={container}
        initial="hidden"
        animate="show"
      >
        {Array.from({ length: columnCount }, (_, columnIndex) => (
          <div key={columnIndex} className={styles.column}>
            {blocks
              .filter((_, index) => index % columnCount === columnIndex)
              .map((block) => (
                <ColourResult key={block.id} block={block} variants={item} />
              ))}
          </div>
        ))}
      </motion.div>
      {hasNextPage && (
        <div ref={ref} className={styles.nextPageLoader}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

// TODO Make columns dependant on the container size and watch for the dialog container resizing to change the number of columns

// Custom hook to determine the number of columns based on screen width
function useColumnCount(isFullScreen: boolean) {
  const [columnCount, setColumnCount] = useState(getColumnCount(isFullScreen));

  useEffect(() => {
    setColumnCount(getColumnCount(isFullScreen));
  }, [isFullScreen]);

  useEffect(() => {
    function handleResize() {
      setColumnCount(getColumnCount(isFullScreen));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isFullScreen, setColumnCount]);

  return columnCount;
}

function getColumnCount(isFullScreen: boolean) {
  if (typeof window === 'undefined') return 4;
  // Check if modal view always have 4 columns
  if (!isFullScreen && window.innerWidth >= 768) return 4;

  // Assume either mobile or full screen
  if (window.innerWidth < 768) return 2;
  // Medium
  if (window.innerWidth < 1279) return 4;
  // Large
  // X Large
  return 6;
}
