import { Icon, IconProps } from './Icon';

export const ChatSpeechRight = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M12.6328 12.5312C12.6328 12.3776 12.6797 12.2461 12.7734 12.1367C12.8672 12.0273 13.0091 11.9727 13.1992 11.9727C13.3867 11.9727 13.5286 12.0273 13.625 12.1367C13.724 12.2435 13.7734 12.375 13.7734 12.5312C13.7734 12.6849 13.724 12.8138 13.625 12.918C13.526 13.0221 13.3841 13.0742 13.1992 13.0742C13.0117 13.0742 12.8698 13.0221 12.7734 12.918C12.6797 12.8112 12.6328 12.6823 12.6328 12.5312ZM17.4375 12.5312C17.4375 12.3776 17.4844 12.2461 17.5781 12.1367C17.6719 12.0273 17.8138 11.9727 18.0039 11.9727C18.1914 11.9727 18.3333 12.0273 18.4297 12.1367C18.5286 12.2435 18.5781 12.375 18.5781 12.5312C18.5781 12.6849 18.5286 12.8138 18.4297 12.918C18.3307 13.0221 18.1888 13.0742 18.0039 13.0742C17.8164 13.0742 17.6745 13.0221 17.5781 12.918C17.4844 12.8112 17.4375 12.6823 17.4375 12.5312ZM15.0352 12.5312C15.0352 12.3776 15.082 12.2461 15.1758 12.1367C15.2695 12.0273 15.4115 11.9727 15.6016 11.9727C15.7891 11.9727 15.931 12.0273 16.0273 12.1367C16.1263 12.2435 16.1758 12.375 16.1758 12.5312C16.1758 12.6849 16.1263 12.8138 16.0273 12.918C15.9284 13.0221 15.7865 13.0742 15.6016 13.0742C15.4141 13.0742 15.2721 13.0221 15.1758 12.918C15.082 12.8112 15.0352 12.6823 15.0352 12.5312Z"
        fill="black"
      />
      <path
        d="M20 17H19.5V17.5V19.5H19.2071L16.8536 17.1464L16.7071 17H16.5H9.5V8.5H21C21.2761 8.5 21.5 8.72386 21.5 9V17H20Z"
        stroke="currentColor"
      />
      <mask
        id="mask0_15943_591"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="4"
        width="17"
        height="16"
      >
        <path d="M2 20V4H18.5V8H9V20H2Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_15943_591)">
        <path
          d="M7.56375 14.7557L4.90694 19.5H4.8076L4.99954 15.0214L5.02189 14.5H4.5H2.5V4.5H17C17.2761 4.5 17.5 4.72386 17.5 5V14.5H8H7.70694L7.56375 14.7557Z"
          stroke="currentColor"
        />
      </g>
    </Icon>
  );
};
