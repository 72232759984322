//If an invitation is sent to an existing user, the BE sends the page id to which the redirection should occur after clicking the link received via email.
// Since redirection may not always happen immediately, for example, if the user is not logged in, the redirection should take place right after their authorization
export const REDIRECT_TO_HUB_OR_BOARD_EXISTING_USER =
  'redirectToHubOrBoardExistingUser';

// If an invitation is sent to a new user who does not yet have an account in Visualist,
// the BE adds a next parameter containing the page id to which the redirection should occur after clicking the link received via email.
// Therefore, in this case, there is a check for the exist of next and the link is saved in localStorage while the user is creating an account.
// After the account is created, the redirection will take place using this link
export const REDIRECT_TO_HUB_OR_BOARD_NEW_USER = 'redirectToHubNewUser';

export const RECENT_BOARDS_KEY = 'board-search-suggestions';
export const RECENT_HUBS_KEY = 'hub-search-suggestions';

export const DOC_TABS = 'doc-tabs';
export const STUDIO_TABS = 'design-tabs';
