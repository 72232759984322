import { memo } from 'react';

import { VaiTagPopup } from './ui/VaiTagPopup';

export const Popups = memo(() => {
  return (
    <>
      <VaiTagPopup />
    </>
  );
});
