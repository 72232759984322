import { useHistory } from 'react-router';

import {
  Button,
  FilterChip,
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { HubResponse } from '@api/hubs';
import { LAST_OPENED_MESSAGE_HUB } from '@pages/messages/constants';
import { HubSearch } from '@src/entities/search-modal/hub-search';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';

import styles from './styles.module.css';

export const ContainingHub = ({ hub }: { hub: HubResponse }) => {
  const history = useHistory();

  const [, setLastOpenedMessageHub] = useLocalStorage(
    LAST_OPENED_MESSAGE_HUB,
    hub.id,
  );

  const { isMobile } = useWindowSize();

  const goToHub = () => {
    setLastOpenedMessageHub(hub.id);
    history.push(`/h/${hub.id}`);
  };

  const switchHub = (hubId: string) => {
    setLastOpenedMessageHub(hubId);
    history.push(`/messages/${hubId}`);
  };

  return (
    <>
      <div className={styles.container}>
        {isMobile ? (
          <IconButton
            type="unfilled"
            icon={<Icon size={24} name="sprite/chevron-left" />}
            onClick={goToHub}
            className={styles.backButton}
          />
        ) : (
          <Button
            type="ghost"
            label="Back to hub"
            onClick={goToHub}
            className={styles.backButton}
            icon={<Icon size={24} name="sprite/chevron-left" />}
          />
        )}
        <div className={styles.innerContainer}>
          <TypographyPoppins type="body" bodySize="M" className={styles.text}>
            Viewing messages in:
          </TypographyPoppins>
          <HubSearch onResultClick={(h) => switchHub(h.id)} selectedHub={hub}>
            <FilterChip type="label" isSelected={true}>
              {hub.name}
            </FilterChip>
          </HubSearch>
        </div>
      </div>
    </>
  );
};
