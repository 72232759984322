import {
  AssistChip,
  EmptyState,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Board } from '@api/boards';
import { boardDialogOpened } from '@src/entities/dialogs/board/model';
import { Thumbnail } from '@src/entities/thumbnails/board';

import styles from './styles.module.css';

type Props = {
  boardsThumbnails: Array<Board>;
  parent: string;
  level: number;
};

export const BoardsStripe = ({ boardsThumbnails, parent, level }: Props) => {
  return level === 2 ? null : (
    <>
      <div className={styles.header}>
        <TypographyPoppins
          type="headline"
          headlineSize="S"
          className={styles.title}
        >
          Sub-boards
        </TypographyPoppins>
        <AssistChip
          leadingIcon
          icon={<Icon name="sprite/plus" />}
          onClick={() => boardDialogOpened()}
          style="outlined"
        >
          New board
        </AssistChip>
      </div>
      {boardsThumbnails?.length === 0 ? (
        <EmptyState
          icon={<Icon name="sprite/sticky-note-soft-colored" size={80} />}
          content="Want to stay organized?"
          label="Create a board"
          action={() => boardDialogOpened()}
        />
      ) : (
        <ul className={styles.boards}>
          {boardsThumbnails?.length > 0 &&
            boardsThumbnails.map(({ id, name, description, recent_images }) => {
              return (
                <li style={{ listStyleType: 'none' }} key={id}>
                  <Thumbnail
                    id={id}
                    name={name}
                    parent={parent}
                    description={description ?? ''}
                    images={recent_images}
                  />
                </li>
              );
            })}
        </ul>
      )}
    </>
  );
};
