import { getBlocksForPalette } from '@api/blocks';
import { searchPaletteKeys } from '@src/shared/constants/query-keys';
import {
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';

type SearchProps = {
  colours: Array<string>;
  tolerance?: number;
  mutationOptions?: Omit<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getBlocksForPalette>>
    >,
    'queryKey' | 'queryFn' | 'initialPageParam' | 'getNextPageParam'
  >;
};

export const useBlocksForPaletteSearch = (props: SearchProps) => {
  const { colours, tolerance = 25 } = props;

  const blockForPaletteQuery = useInfiniteQuery({
    queryKey: searchPaletteKeys.palette(colours),
    queryFn: ({ pageParam = 0 }) =>
      getBlocksForPalette({
        colours,
        tolerance,
        offset: pageParam as number,
      }),
    getNextPageParam: (lastPage) => {
      return (
        lastPage.data.next &&
        new URL(lastPage.data.next).searchParams.get('offset')
      );
    },
    initialPageParam: '0',
    ...props.mutationOptions,
  });

  if (!blockForPaletteQuery.data)
    return {
      blocks: [],
      blockForPaletteQuery,
    };

  const blocks =
    blockForPaletteQuery.data.pages.reduce(
      (acc, page) => [...acc, ...page.data.results],
      [] as Awaited<ReturnType<typeof getBlocksForPalette>>['data']['results'],
    ) ?? [];

  return {
    blocks,
    blockForPaletteQuery,
  };
};
