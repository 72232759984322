import cn from 'classnames';
import { useUnit } from 'effector-react';

import { Tooltip } from '@visualist/design-system/src/components/v2';

import { $searchFullScreen } from '../../model';

import styles from './styles.module.css';

export const ExpandButton = ({
  children,
  ...props
}: React.ComponentProps<'button'>) => {
  const [searchFullScreen] = useUnit([$searchFullScreen]);

  return (
    <Tooltip
      parameter={{
        type: 'plain',
        description: searchFullScreen ? 'Exit full screen' : 'Go full screen',
        position: 'left',
      }}
      className={styles.expandButtonTooltip}
      wrapperClassName={props.className}
    >
      <button {...props} className={cn(styles.expandButton)}>
        {children}
      </button>
    </Tooltip>
  );
};
