import {
  SegmentedButton,
  Tooltip,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import styles from './styles.module.css';

interface ImageToolbarProps {
  onTextWrap: (
    textwrap:
      | 'inline'
      | 'square'
      | 'squareRight'
      | 'breakText'
      | 'front'
      | 'behind',
  ) => void;
  currentTextWrap:
    | 'inline'
    | 'square'
    | 'squareRight'
    | 'breakText'
    | 'front'
    | 'behind';
  lockWrapControls: boolean;
}

const ImageToolbar = ({
  onTextWrap,
  currentTextWrap,
  lockWrapControls,
}: ImageToolbarProps) => {
  return (
    <div className={styles.toolbar}>
      <Tooltip
        parameter={{
          description: 'Inline',
          type: 'plain',
          position: 'bottom',
          centerText: true,
        }}
        style={{
          minWidth: 32,
        }}
        className={styles.tooltip}
      >
        <SegmentedButton
          buttonStyle={`${
            currentTextWrap === 'inline' ? styles.firstSelectedButton : ''
          }`}
          isSelected={currentTextWrap === 'inline'}
          icon={
            <Icon
              name="sprite/text-wrap-inline-colored"
              height={20}
              width={20}
            />
          }
          onClick={() => {
            onTextWrap('inline');
          }}
        />
      </Tooltip>
      {!lockWrapControls ? (
        <>
          <Tooltip
            parameter={{
              description: 'Square left',
              type: 'plain',
              position: 'bottom',
              centerText: true,
            }}
            style={{
              minWidth: 70,
            }}
            className={styles.tooltip}
          >
            <SegmentedButton
              buttonStyle={styles.button}
              isSelected={currentTextWrap === 'square'}
              icon={
                <Icon
                  name="sprite/text-wrap-square-colored"
                  height={20}
                  width={20}
                />
              }
              onClick={() => {
                onTextWrap('square');
              }}
            />
          </Tooltip>
          <Tooltip
            parameter={{
              description: 'Square right',
              type: 'plain',
              position: 'bottom',
              centerText: true,
            }}
            style={{
              minWidth: 80,
            }}
            className={styles.tooltip}
          >
            <SegmentedButton
              buttonStyle={styles.button}
              isSelected={currentTextWrap === 'squareRight'}
              icon={
                <Icon
                  name="sprite/text-wrap-square-right-colored"
                  height={20}
                  width={20}
                />
              }
              onClick={() => {
                onTextWrap('squareRight');
              }}
            />
          </Tooltip>
          <Tooltip
            parameter={{
              description: 'Break text',
              type: 'plain',
              position: 'bottom',
              centerText: true,
            }}
            style={{
              minWidth: 60,
            }}
            className={styles.tooltip}
          >
            <SegmentedButton
              buttonStyle={styles.button}
              isSelected={currentTextWrap === 'breakText'}
              icon={
                <Icon
                  name="sprite/text-wrap-break-colored"
                  height={20}
                  width={20}
                />
              }
              onClick={() => {
                onTextWrap('breakText');
              }}
            />
          </Tooltip>
        </>
      ) : null}
      <Tooltip
        parameter={{
          description: 'In front of text',
          type: 'plain',
          position: 'bottom',
          centerText: true,
        }}
        style={{
          minWidth: 90,
        }}
        className={styles.tooltip}
      >
        <SegmentedButton
          buttonStyle={styles.button}
          isSelected={currentTextWrap === 'front'}
          icon={
            <Icon
              name="sprite/text-wrap-front-colored"
              height={20}
              width={20}
            />
          }
          onClick={() => {
            onTextWrap('front');
          }}
        />
      </Tooltip>
      <Tooltip
        parameter={{
          description: 'Behind text',
          type: 'plain',
          position: 'bottom',
          centerText: true,
        }}
        style={{
          minWidth: 70,
        }}
        className={styles.tooltip}
      >
        <SegmentedButton
          buttonStyle={`${styles.button} ${
            currentTextWrap === 'behind' ? styles.lastSelectedButton : ''
          }`}
          isSelected={currentTextWrap === 'behind'}
          icon={
            <Icon
              name="sprite/text-wrap-behind-colored"
              height={20}
              width={20}
            />
          }
          onClick={() => {
            onTextWrap('behind');
          }}
        />
      </Tooltip>
    </div>
  );
};

export default ImageToolbar;
