import { MouseEvent, useState } from 'react';

import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import {
  Dropdown,
  IconButton,
  Item,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { File } from '@api/blocks';
import { boardIdPassed } from '@pages/BoardPage/model';
import { deleteBoardModalOpened } from '@src/entities/delete-modals/modal';
import {
  boardDescriptionEntered,
  boardIdSelected,
  boardNameEntered,
  boardRenamingDialogOpened,
} from '@src/entities/dialogs/board/model';

import styles from './styles.module.css';

type Props = {
  id: string;
  name: string;
  images: { id: string; file: File }[];
  description?: string;
  parent?: string;
  onboardingId?: string;
};

export const Thumbnail = ({
  id,
  name,
  parent,
  description,
  images,
  onboardingId,
}: Props) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const history = useHistory();
  // const location = useLocation();

  // const boardPage = matchPath(location.pathname, {
  //   path: '/board/:id',
  //   exact: true,
  //   strict: false,
  // });

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (e.ctrlKey || e.metaKey) {
      window.open(`${import.meta.env.VITE_BASE_URL}/board/${id}`, '_blank');
    } else {
      history.push(`/board/${id}`);
    }
  };

  const onContextMenu = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOpenDropdown(true);
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/board/${id}`,
      );

      startedSnack({
        label: 'Copied link to board',
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to board",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const rename = () => {
    boardRenamingDialogOpened();
    boardNameEntered(name);
    boardIdSelected(id);

    if (description) {
      boardDescriptionEntered(description);
    }
  };

  const deleteBoard = () => {
    deleteBoardModalOpened({
      boardId: id,
      parentBoardId: parent,
    });
    boardIdPassed(id);
  };

  const menuItems: Item[] = [
    {
      leadingIcon: <Icon name="sprite/link" size={21} />,
      content: 'Copy link',
      onClick: () => copyLink(),
    },
    {
      leadingIcon: <Icon name="sprite/pen" />,
      content: 'Rename',
      onClick: rename,
      // isDivider: !boardPage?.isExact,
      isDivider: true,
    },
    // boardPage?.isExact && {
    //   leadingIcon: <TeamIcon />,
    //   content: 'Invite',
    //   onClick: () => shareSideSheetOpened(),
    //   isDivider: true,
    // },
    {
      leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
      content: 'Delete',
      onClick: () => deleteBoard(),
      classNameContent: styles.delete,
    },
  ].filter(Boolean);

  return (
    <div
      id={onboardingId}
      className={styles.thumbnail}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      <div className={styles.media}>
        {images.length > 0 ? (
          <Images images={images} />
        ) : (
          <Icon name="sprite/folder-empty-colored" size={80} />
        )}
      </div>
      <header className={styles.header}>
        <div className={styles.content}>
          <Icon name="sprite/board" size={16} />
          <TypographyPoppins
            type="label"
            labelSize="S"
            className={styles.label}
          >
            {name}
          </TypographyPoppins>
        </div>
        <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
          <Dropdown.Menu
            trigger={
              <IconButton
                className={styles.iconButton}
                type="unfilled"
                icon={<Icon name="sprite/3-dot-menu" size={16} />}
                onClick={() => {}}
                stopPropagation
                isSelected={isOpenDropdown}
              />
            }
            side="top"
            density="-2"
          >
            {menuItems.map((item, index) => (
              <Dropdown.MenuItem key={index} item={item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </header>
    </div>
  );
};

type ImagesProps = {
  images: { id: string; file: File }[];
};

const Images = ({ images }: ImagesProps) => (
  <>
    {images.slice(0, 3).map(({ id, file }) => (
      <div
        className={cn(styles.image, {
          [styles.one]: images.length === 1,
          [styles.two]: images.length === 2,
          [styles.three]: images.length >= 3,
        })}
        key={id}
      >
        <img
          style={{ width: '100%', objectFit: 'cover' }}
          src={file.full_size}
          alt="image"
        />
      </div>
    ))}
  </>
);
