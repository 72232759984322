// import { StickyType } from '@visualist/design-system';
import React from 'react';

import { motion } from 'framer-motion';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import styles from './styles.module.css';

type Props = {
  numberOfReplies: number | undefined;
  isSelected: boolean;
  isEditing?: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  size?: React.ComponentProps<typeof TypographyPoppins>['labelSize'];
};

export const StickyFooter = ({
  isSelected,
  isEditing,
  numberOfReplies,
  setShowDropdown,
  size = 'XS',
}: Props) => {
  const ref = React.useRef(null);

  if (isSelected) {
    return (
      <motion.div ref={ref} className={styles.selectedFooter}>
        <div />
        <div className={styles.buttonContainer}>
          <button
            className={styles.dotsButton}
            onClick={() => {
              setShowDropdown((prev) => !prev);
            }}
          >
            <Icon name="sprite/3-dot-menu" />
          </button>
        </div>
      </motion.div>
    );
  }

  if (numberOfReplies) {
    return (
      <motion.div className={styles.footer}>
        <TypographyPoppins type="label" labelSize={size}>
          <span className={styles.repliesText}>
            {numberOfReplies} {numberOfReplies === 1 ? 'reply' : 'replies'}
          </span>
        </TypographyPoppins>
      </motion.div>
    );
  }

  // Render for space but hide it on the cards
  return (
    <motion.div
      className={styles.footer}
      style={{
        visibility: 'hidden',
        height: isEditing ? 0 : 10,
      }}
    >
      <TypographyPoppins type="label" labelSize="XS">
        <span className={styles.repliesText}>-</span>
      </TypographyPoppins>
    </motion.div>
  );
};
