import { IconButton } from '@visualist/design-system/src/components/v2';
import { useKeyboardShortcut } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import styles from './styles.module.css';

interface ArrowRightProps {
  goNextFile: () => void;
}

export const ArrowRight = ({ goNextFile }: ArrowRightProps) => {
  useKeyboardShortcut(['ArrowRight'], goNextFile);

  return (
    <nav className={styles.navigation}>
      <IconButton
        type="on secondary container"
        className={styles.arrowRight}
        icon={<Icon name="sprite/chevron-right" className={styles.icon} />}
        onClick={goNextFile}
      />
    </nav>
  );
};
