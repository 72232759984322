import { combine, createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';
import { previous } from 'patronum';

export const uploadPopupOpened = createEvent();
export const uploadPopupClosed = createEvent();
// colorPicked - an event for passing any type of data (not just hex-color) to change the background of the hub's header.
// Then, the value in $previousColor is compared with $defaultColor. If they match, it means the event 'Added cover' occurred, otherwise, it's 'Changed cover'
export const colorPicked = createEvent<any>();

export const $defaultColor = createStore('#FFF8F6');
export const $isUploadPopupOpen = createStore(false);

$isUploadPopupOpen.on(uploadPopupOpened, () => true).reset(uploadPopupClosed);

const $currentColor = createStore('');
export const $previousColor = previous($currentColor);

$currentColor.on(colorPicked, (_, color) => color);

export const currentHeaderNameSetted = createEvent<string>();

export const $currentHeaderName = createStore('');

$currentHeaderName.on(currentHeaderNameSetted, (_, name) => name);

export const headerNameChanged = createEvent<string>();
export const headerNameReset = createEvent();

export const $headerName = createStore('');

$headerName.on(headerNameChanged, (_, name) => name);

sample({
  clock: headerNameReset,
  source: $currentHeaderName,
  target: $headerName,
});

export const thumbnailSetted = createEvent<any>();
export const thumbnailHexSetted = createEvent<any>();

const $thumbnail = createStore(false);
$thumbnail.on(thumbnailSetted, (_, background) => background);

const $thumbnailHex = createStore(false);
$thumbnailHex.on(thumbnailHexSetted, (_, background) => background);

export const $isShowSnackbar = combine(
  $thumbnail,
  $thumbnailHex,
  (thumbnail, thumbnailHex) => {
    return !thumbnail && !thumbnailHex;
  },
);

hotkey({
  key: 'Escape',
  filter: $isUploadPopupOpen,
  target: uploadPopupClosed,
});
