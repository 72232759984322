import { Editor } from '@tiptap/react';

export const getNumberFromPx = (s: string, defaultValue: number) => {
  if (!s) return defaultValue;

  const splitSign = s.includes('px') ? 'px' : 'rem';

  const split = s.split(splitSign);

  if (split[0]) return parseInt(split[0]);

  return defaultValue;
};

// const P_SIZE = '12px';
const P_H1 = '32px';
const P_H2 = '28px';
const P_H3 = '24px';

export const checkIsHeading = (editor: Editor) => {
  const isHeading1 = editor.isActive('heading', { level: 1 });
  const isHeading2 = editor.isActive('heading', { level: 2 });
  const isHeading3 = editor.isActive('heading', { level: 3 });
  const isHeading = isHeading1 || isHeading2 || isHeading3;

  return {
    isHeading,
    isHeading1,
    isHeading2,
    isHeading3,
  };
};

export const getFontSize = (editor: Editor) => {
  const { isHeading, isHeading1, isHeading2, isHeading3 } =
    checkIsHeading(editor);

  if (isHeading) {
    if (isHeading1) {
      return P_H1;
    } else if (isHeading2) {
      return P_H2;
    } else if (isHeading3) {
      return P_H3;
    }
  }

  // Not a heading

  // TODO Check if normal style, if normal return P_SIZE

  return editor.getAttributes('textStyle').fontSize;
};

export const isURL = (s: string) => {
  return /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/.test(s);
};

export const canAppendHTTPS = (s: string) => {
  return /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z]{2,})?$/.test(s);
};
