import { combine, createEvent, createStore } from 'effector';
import { hotkey } from 'effector-hotkey';

import {
  $isShowBoardDialog,
  $step,
  boardDialogClosed,
  stepContinue,
} from '../../../model';

export const searchModalOpened = createEvent<boolean>();
export const searchModalClosed = createEvent();

export const queryEntered = createEvent<string>();
export const queryCleared = createEvent();

export const widthSetted = createEvent<number>();

// TODO: add types
export const locationSelected = createEvent<any>();
export const locationCleared = createEvent();

// Search modal
export const $isSearchModalOpen = createStore(false);

$isSearchModalOpen
  .on(searchModalOpened, (_, state) => state)
  .reset(searchModalClosed);

// Query
export const $query = createStore('');

$query
  .on(queryEntered, (_, query) => query)
  .reset([queryCleared, searchModalClosed]);

// Location
// TODO: add types
export const $location = createStore(null);

$location
  .on(locationSelected, (_, location) => location)
  .reset([locationCleared, boardDialogClosed]);

// The width of long names (location) to apply a blur effect
export const $width = createStore(0);

$width.on(widthSetted, (_, width) => width);

// Close the board creation dialog if the hub and board search modal is closed
hotkey({
  key: 'Escape',
  filter: $isShowBoardDialog && $isSearchModalOpen.map((open) => !open),
  target: boardDialogClosed,
});

// Close the hub and board search modal
hotkey({
  key: 'Escape',
  filter: $isSearchModalOpen,
  target: searchModalClosed,
});

// Board creation dialog: On Step 1 - Press Enter to go the next screen
const $goNext = combine(
  $step,
  $isSearchModalOpen,
  (step, isSearchModalOpen) => step === 1 && !isSearchModalOpen,
);

hotkey({
  key: 'Enter',
  filter: $goNext,
  target: stepContinue,
});
