import { Extension } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    background: {
      setBackground: (color: string) => ReturnType;
    };
  }
}

export const Background = Extension.create({
  name: 'background',

  addCommands() {
    return {
      setBackground:
        (color) =>
        ({ editor }) => {
          const element = editor.view.dom;
          if (element) {
            element.style.background = color;
          }
          return true;
        },
    };
  },
});
