import { Icon, IconProps } from './Icon';

export const BringToFrontIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <rect
        x="13.25"
        y="13.25"
        width="7.5"
        height="7.5"
        rx="0.75"
        fill="white"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeDasharray="1 1"
      />
      <rect
        x="3.25"
        y="3.25"
        width="7.5"
        height="7.5"
        rx="0.75"
        fill="white"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeDasharray="1 1"
      />
      <rect x="7" y="7" width="10" height="10" rx="1" fill="currentColor" />
    </Icon>
  );
};
