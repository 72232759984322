import { Icon, IconProps } from './Icon';

export const Team = (props: IconProps) => {
  return (
    <Icon {...props}>
      <mask
        id="mask0_15898_642"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="11"
        y="5"
        width="5"
        height="6"
      >
        <path
          d="M12.2858 6C11.7858 5.5 11.7858 5.5 11 5H16V11H11.2858L12.2858 10C12.7858 9.5 13.7001 7.41415 12.2858 6Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_15898_642)">
        <circle cx="12.7858" cy="8" r="2.5" stroke="currentColor" />
      </g>
      <mask
        id="mask1_15898_642"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="14"
        y="5"
        width="6"
        height="6"
      >
        <path
          d="M15.7858 6C15.2858 5.5 15.2858 5.5 14.5 5H15.5H19.2858L19.5 11H14.7858L15.7858 10C16.2858 9.5 17.2001 7.41415 15.7858 6Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask1_15898_642)">
        <circle cx="16.2858" cy="8" r="2.5" stroke="currentColor" />
      </g>
      <path
        d="M8.5 12H15.1628C15.6472 12 16.062 12.3473 16.1472 12.8242L17.25 19M11.25 12H17.9128C18.3972 12 18.812 12.3473 18.8972 12.8242L20 19M4.5 19L5.75 12H12.4128C12.8972 12 13.312 12.3473 13.3972 12.8242L14.5 19H4.5Z"
        stroke="currentColor"
      />
      <circle cx="9.5" cy="8" r="2.5" stroke="currentColor" />
    </Icon>
  );
};
