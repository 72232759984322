import { Icon, IconProps } from './Icon';

interface ThinArrowIconProps extends IconProps {
  direction: 'left' | 'right';
}

export const CurvedArrowIcon = (props: ThinArrowIconProps) => {
  return (
    <Icon
      {...props}
      style={{
        ...props.style,
        rotate: props.rotate?.toString(),
        transform: `scaleX(${props.direction === 'left' ? -1 : 1})`,
      }}
      viewBox="0 0 34 35"
    >
      <path
        d="M24.7488 13.9645C21.4489 13.4931 14.0008 14.5302 10.6066 22.4498"
        stroke="currentColor"
        fill="transparent"
      />
      <path
        d="M21.5667 10.7825L24.7487 13.9644L21.2132 16.7929"
        stroke="currentColor"
        fill="transparent"
      />
    </Icon>
  );
};
