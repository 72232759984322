import { ReactNode, useEffect, useRef, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useHistory } from 'react-router';

import {
  Design,
  Palette,
  TypographyItalic,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { BoardLvl1, BoardLvl2, BoardLvl3 } from '@api/designs';
import { PossibleResult } from '@api/search';
import { fileCardIdSet } from '@pages/FileCard/model';
import { Breadcrumbs } from '@src/entities/breadcrumbs';

import {
  getIndicesOfExactMatchingWord,
  getIndicesOfMatchingWord,
  splitSentence,
} from '../../util/splitSentence';
import { SearchFilters } from '../filters';

import styles from './styles.module.css';

export const SearchResults = (props: {
  isLoading: boolean;
  results?: PossibleResult[];
  searchText: string;
}) => {
  const { isLoading, results, searchText } = props;

  const [shouldShowLoading, setShouldShowLoading] = useState(false);
  const loadingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (searchText && isLoading) {
      loadingTimeoutRef.current = setTimeout(() => {
        setShouldShowLoading(true);
      }, 1000);

      return () => {
        if (!loadingTimeoutRef.current) return;
        clearTimeout(loadingTimeoutRef.current);
      };
    }

    if (searchText && !isLoading && loadingTimeoutRef.current) {
      clearTimeout(loadingTimeoutRef.current);
      setShouldShowLoading(false);
    }
  }, [isLoading, searchText]);

  if (isLoading && shouldShowLoading)
    return (
      <>
        <SearchFilters />
        <SkeletonRows rows={3} />
      </>
    );

  if (isLoading) return <SearchFilters />;

  if (searchText === '') return null;

  return (
    <>
      <SearchFilters />
      {!results || results.length === 0 ? (
        <div className={styles.noResultContainer}>
          <TypographyItalic
            type="body"
            formattingBody="large italic"
            customStyle={styles.noResultsLabel}
          >
            No matching results
          </TypographyItalic>
        </div>
      ) : (
        <div className={styles.resultsContainer}>
          <AnimatePresence mode="popLayout">
            {results.map((result) => (
              <SearchResultMapper
                searchText={searchText}
                key={result.id}
                result={result}
              />
            ))}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

const SearchResultMapper = (props: {
  result: PossibleResult;
  searchText: string;
}) => {
  const { result, searchText } = props;

  const matchedContent =
    result.search_type === 'content'
      ? {
          searchText: searchText,
          // TODO fix this typeing
          // @ts-expect-error
          matchedText: result[result.matched_field],
        }
      : undefined;

  switch (result.type) {
    case 'doc':
      return (
        <Result
          name={result.title}
          icon={<Icon name="sprite/doc" height={24} width={24} />}
          type="doc"
          id={result.id}
          parent={result.board}
          matchedContent={matchedContent}
        />
      );
    case 'hub':
      return (
        <Result
          name={result.name}
          icon={<Icon name="sprite/hub" height={24} width={24} />}
          type="hub"
          id={result.id}
          matchedContent={matchedContent}
        />
      );
    case 'board':
      return (
        <Result
          name={result.name}
          thumbnail={result.recent_images?.[0].file.full_size}
          icon={<Icon name="sprite/board" height={24} width={24} />}
          type="board"
          id={result.id}
          parent={result.parent}
          matchedContent={matchedContent}
        />
      );
    case 'image':
      return (
        <Result
          name={result.name}
          thumbnail={result.file.thumbnail_640}
          icon={<Icon name="sprite/image" height={24} width={24} />}
          type="image"
          id={result.id}
          parent={result.boards?.[0]}
          matchedContent={matchedContent}
        />
      );
    case 'design':
      return (
        <Result
          name={result.name ?? 'Untitled'}
          thumbnail={result.file.thumbnail_640}
          icon={<Design fill="transparent" height={24} width={24} />}
          type="design"
          id={result.id}
          parent={result.boards?.[0]}
          matchedContent={matchedContent}
        />
      );
    case 'palette':
      return (
        <Result
          name={result.name ?? 'Untitled'}
          thumbnail={result.file.thumbnail_640}
          icon={<Palette fill="transparent" height={24} width={24} />}
          type="palette"
          id={result.id}
          parent={result.boards?.[0]}
          matchedContent={matchedContent}
        />
      );
    case 'stickies':
      return (
        <Result
          name={result.block[0].name ?? 'Untitled'}
          thumbnail={result.block[0].file?.thumbnail_640 || ''}
          matchedContent={matchedContent}
          icon={
            <Icon
              fill="transparent"
              name="sprite/sticky"
              height={24}
              width={24}
            />
          }
          type="stickies"
          id={result.id}
          parent={result.parent?.[0]}
          attachedBlock={{
            id: result.block[0].id,
            board: result.block[0].boards?.[0],
          }}
        />
      );
    case 'commentSticker':
      return (
        <Result
          name={result.block[0].name ?? 'Untitled'}
          thumbnail={result.block[0].file?.thumbnail_640 || ''}
          matchedContent={matchedContent}
          icon={
            <Icon
              fill="transparent"
              name="sprite/sticky"
              height={24}
              width={24}
            />
          }
          type="stickies"
          id={result.id}
          parent={result.parent?.[0]}
          attachedBlock={{
            id: result.block[0].id,
            board: result.block[0].boards?.[0],
          }}
        />
      );
    default:
      return null;
  }
};

const Result = (props: {
  id: string;
  name: string;
  icon: ReactNode;
  thumbnail?: string;
  attachedBlock?: {
    id: string;
    board?: {
      id: string;
      name: string;
    };
  };
  type: PossibleResult['type'];
  matchedContent?: {
    searchText: string;
    matchedText: string | undefined;
  };
  parent?: BoardLvl1 | BoardLvl2 | BoardLvl3 | null;
}) => {
  const { id, name, icon, thumbnail, attachedBlock, matchedContent, parent } =
    props;
  const history = useHistory();

  const openResult = () => {
    switch (props.type) {
      case 'doc':
        history.push(`/d/${id}`);
        break;
      case 'hub':
        history.push(`/h/${id}`);
        break;
      case 'board':
        history.push(`/board/${id}`);
        break;
      case 'image':
      case 'palette':
        fileCardIdSet(id);
        history.push(`/library#/block/${id}`);
        break;
      case 'design':
        history.push(`/studio/${id}`);
        break;
      case 'stickies':
        fileCardIdSet(attachedBlock?.id ?? '');
        if (attachedBlock?.board) {
          history.push(
            `/board/${attachedBlock.board.id}/#/block/${attachedBlock.id}`,
          );
          return;
        }
        history.push(`/library#/block/${attachedBlock?.id}`);
        break;
      default:
        break;
    }
  };

  const matchedText = () => {
    if (!matchedContent || !matchedContent.matchedText) return null;

    try {
      let startIndex = -1;
      let endIndex = -1;

      const [maybeStartIndex, maybeEndIndex] = getIndicesOfExactMatchingWord(
        matchedContent.matchedText.trimStart().trimEnd(),
        matchedContent.searchText,
      );

      if (maybeStartIndex !== -1 && maybeEndIndex !== -1) {
        // If exact match assign indices
        startIndex = maybeStartIndex;
        endIndex = maybeEndIndex;
      }

      if (startIndex === -1 || endIndex === -1) {
        // If not exact match try a first occurence match
        const [startIndex2, endIndex2] = getIndicesOfMatchingWord(
          matchedContent.matchedText.trimStart().trimEnd(),
          matchedContent.searchText,
        );

        if (startIndex2 !== -1 && endIndex2 !== -1) {
          // If first occurence match use indices
          startIndex = startIndex2;
          endIndex = endIndex2;
        }
      }

      const text = splitSentence(
        matchedContent.matchedText,
        startIndex,
        endIndex,
      );
      const firstHalf = text[0];
      const secondHalf = text[2];

      const lastThreeWordsBeginnning = firstHalf.split(' ').slice(-4).join(' ');
      const lastThreeWordsEnding = secondHalf.split(' ').slice(0, 4).join(' ');

      return (
        <TypographyPoppins
          type="body"
          bodySize="M"
          className={styles.titleText}
        >
          {lastThreeWordsBeginnning}
          <span className={styles.matchedText}>
            {matchedContent.matchedText.slice(startIndex, endIndex)}
          </span>
          {lastThreeWordsEnding}
        </TypographyPoppins>
      );
    } catch (e) {
      // console.error(e);
    }

    return null;
  };

  return (
    <motion.button
      layout
      whileHover={{
        paddingLeft: '24px',
        paddingRight: '24px',
      }}
      className={styles.resultContainer}
      onClick={openResult}
    >
      <div className={styles.left}>
        {parent ? (
          <div className={styles.breadcrumbsContainer}>
            {parent && <Breadcrumbs board={parent} />}
          </div>
        ) : (
          <div className={styles.noBreadcrumbsContainer}></div>
        )}
        <div className={styles.nameInfo}>
          <div className={styles.iconContainer}>{icon}</div>
          <div>
            <TypographyPoppins
              type="body"
              bodySize="L"
              className={styles.titleText}
            >
              {name}
            </TypographyPoppins>
            {matchedText()}
          </div>
        </div>
      </div>
      <div className={styles.right}>
        {thumbnail ? (
          <img src={thumbnail} alt={name} className={styles.thumbnail} />
        ) : null}
      </div>
    </motion.button>
  );
};

const SkeletonRows = ({ rows }: { rows: number }) => {
  return (
    <div className={styles.resultsContainer}>
      <div className={styles.fadeIn}>
        {Array.from({ length: rows }).map((_, idx) => (
          <div
            key={idx}
            style={
              {
                '--order': idx,
              } as React.CSSProperties
            }
            className={styles.skeletonRow}
          ></div>
        ))}
      </div>
    </div>
  );
};
