import { useUnit } from 'effector-react';

import { PageLoader } from '@components/PageLoader';
import { $isTogglingDemoMode } from '@src/model';

import styles from './styles.module.css';

export const DemoLoading = () => {
  const [isTogglingDemoMode] = useUnit([$isTogglingDemoMode]);

  if (!isTogglingDemoMode) return null;

  return (
    <div className={styles.container}>
      <PageLoader description="Getting your space ready. This may take a minute or two..." />
    </div>
  );
};
