export const checkUrlPattern = (name: string) => {
  const urlPattern =
    /https?:\/\/(?:[-\w]+\.)?([-\w]+)\.\w+(?:\.\w+)?\/?.*/i.test(name);
  return urlPattern;
};

export const checkValidateEmail = (email: string) => {
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
    email.trim(),
  );
  return emailPattern;
};

export const validateJSON = <T extends string>(json: T | undefined) => {
  try {
    if (!json) return null;

    if (typeof json !== 'string') return null;

    const parsedJson = JSON.parse(json);
    return parsedJson as Record<PropertyKey, any>;
  } catch (e) {
    return null;
  }
};
