import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  InputChip,
  TextField,
  Tooltip,
} from '@visualist/design-system/src/components/v2';

import { UserInvitationHub } from '@api/hubs';
import { ResponseSuggestion } from '@api/users';
import { checkValidateEmail, KeyCodes } from '@src/shared/utils';

import styles from './styles.module.css';

type InivteField = {
  isNotEnoughSeats: boolean;
  suggestions: ResponseSuggestion[];
  invitation: string;
  setInvitation: (s: string) => void;
  users: UserInvitationHub[];
  setUsers: Dispatch<UserInvitationHub[]>;
  clear: () => void;
  isDocInHub: boolean;
};

export const InviteField = ({
  invitation,
  setInvitation,
  users,
  setUsers,
  suggestions,
  isNotEnoughSeats,
  clear,
  isDocInHub,
}: InivteField) => {
  const [error, setError] = useState('');
  const [supportingText, setSupportingText] = useState('');

  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (users && users.length > 0 && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [users]);

  useEffect(() => {
    if (invitation.length >= 0) {
      setError('');
    }
  }, [invitation]);

  useEffect(() => {
    if (
      users &&
      suggestions &&
      invitation.trim().length > 0 &&
      users.length === 0 &&
      suggestions.length === 0
    ) {
      setSupportingText('No contacts found. Enter email to invite');
    } else {
      setSupportingText('');
    }
  }, [suggestions, invitation, users]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInvitation(e.target.value.replace(/[,]/g, ''));
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // if (
    //   (e.key === KeyCodes.BACKSPACE || e.key === KeyCodes.DELETE) &&
    //   invitation.length === 0
    // ) {
    //   e.preventDefault();
    //   const guestUser = users.pop();
    //   setUsers(users.filter((user) => user.email !== guestUser?.email));
    // }

    if (
      (e.key === KeyCodes.COMMA ||
        e.key === KeyCodes.ENTER ||
        e.key === KeyCodes.SPACE) &&
      !e.shiftKey &&
      checkValidateEmail(invitation) &&
      invitation.trim().length !== 0
    ) {
      e.preventDefault();
      setUsers([...users, { email: invitation }]);
      setInvitation('');
    }

    if (
      (e.key === KeyCodes.COMMA ||
        e.key === KeyCodes.ENTER ||
        e.key === KeyCodes.SPACE) &&
      !e.shiftKey &&
      invitation.trim().length > 0 &&
      !checkValidateEmail(invitation)
    ) {
      e.preventDefault();
      setError('Invalid email');
    }
  };

  const removeChip = (index: number) =>
    setUsers(users.filter((_, idx) => idx !== index));

  const inputChips =
    users && users.length > 0
      ? users.map(({ email }, index) => (
          <li key={email} style={{ marginRight: '4px', marginTop: '4px' }}>
            <InputChip
              style="outlined"
              trailingIcon
              type="label"
              labelSize="L"
              onClick={() => removeChip(index)}
            >
              {email}
            </InputChip>
          </li>
        ))
      : null;

  return (
    <div className={styles.inviteField}>
      {isNotEnoughSeats && isDocInHub && (
        <Tooltip
          style={{
            minWidth: 'max-content',
          }}
          parameter={{
            type: 'plain',
            description: "You don't have enough seats",
            position: 'top',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <TextField
              ref={textFieldRef}
              placeholder="Enter name or email"
              value={invitation}
              onChange={onChange}
              onKeyDown={onKeyDown}
              errorAndSupportingText={error}
              error={isNotEnoughSeats}
              clear={clear}
              inputChips={inputChips}
            />
          </div>
        </Tooltip>
      )}

      {!isDocInHub && (
        <Tooltip
          style={{
            minWidth: 'max-content',
          }}
          parameter={{
            type: 'plain',
            description:
              'This doc is not in a hub. Add it to a hub to invite others.',
            position: 'top',
          }}
        >
          <TextField
            ref={textFieldRef}
            placeholder="Enter name or email"
            value={invitation}
            onChange={onChange}
            onKeyDown={onKeyDown}
            clear={clear}
            isDisabled={!isDocInHub}
          />
        </Tooltip>
      )}

      {!isNotEnoughSeats && isDocInHub && (
        <TextField
          ref={textFieldRef}
          placeholder="Enter name or email"
          value={invitation}
          onChange={onChange}
          onKeyDown={onKeyDown}
          supportingText={supportingText}
          errorAndSupportingText={error}
          clear={clear}
          inputChips={inputChips}
        />
      )}
    </div>
  );
};
