import { createEvent, createStore } from 'effector';

export type Email = {
  email: string;
};

export const queryEntered = createEvent<string>();
export const queryCleared = createEvent();

export const emailsEntered = createEvent<Email[]>();
export const emailsCleared = createEvent();

export const $query = createStore<string>('');
export const $emails = createStore<Email[]>([]);

$query.on(queryEntered, (_, query) => query).reset(queryCleared);

$emails.on(emailsEntered, (_, emails) => emails).reset(emailsCleared);
