import React, { ElementRef, forwardRef, ReactNode } from 'react';

import cn from 'classnames';

import styles from './styles.module.css';

export type IconButtonType =
  | 'unfilled'
  | 'outlined'
  | 'primary'
  | 'on secondary container'
  | 'surface round small';

export type IconButtonProps = {
  type: IconButtonType;
  icon: ReactNode;
  iconStyles?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  stopPropagation?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const IconButton = forwardRef<ElementRef<'button'>, IconButtonProps>(
  (
    {
      type,
      icon,
      iconStyles,
      onClick,
      onMouseEnter,
      onMouseLeave,
      stopPropagation,
      isDisabled,
      isSelected,
      className,
      style,
      ...props
    },
    ref,
  ) => {
    const onClickHandler = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      if (stopPropagation) {
        e.stopPropagation();
      }

      onClick(e);
    };

    return (
      <button
        {...props}
        ref={ref}
        className={cn(styles.container, className, {
          [styles.unfilled]: type === 'unfilled',
          [styles.unfilledDisabled]: type === 'unfilled' && isDisabled,
          [styles.outlined]: type === 'outlined',
          [styles.outlinedDisabled]: type === 'outlined' && isDisabled,
          [styles.primary]: type === 'primary',
          [styles.primaryDisabled]: type === 'primary' && isDisabled,
          [styles.onSecondaryContainer]:
            type === 'on secondary container' || isSelected,
          [styles.onSecondaryContainerDisabled]:
            type === 'on secondary container' && isDisabled,
          [styles.surfaceRoundSmall]: type === 'surface round small',
          [styles.surfaceRoundSmallDisabled]:
            type === 'surface round small' && isDisabled,
          [styles.selected]: isSelected,
        })}
        onClick={onClickHandler}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        disabled={isDisabled}
        style={style}
      >
        <span className={cn(styles.icon, iconStyles)}>{icon}</span>
      </button>
    );
  },
);
