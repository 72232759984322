import { PageLoader } from '@components/PageLoader';
import { useBilling } from '@src/shared/queries/useBilling';

import { Banner } from '../Banner';
import { CancelSheet } from '../CancelSheet';
import { HubCreditSheet } from '../HubCreditSheet';
import { HubCreditsSection } from '../HubCreditsSection';
import { HubInfoSheet } from '../HubInfoSheet';
import { PaymentMethod } from '../PaymentMethod';
import { PlanSection } from '../PlanSection';
import { PlanSheet } from '../PlanSheet';
import { TrialSheet } from '../TrialSheet';

import styles from './styles.module.css';

export const Billing = () => {
  const { query } = useBilling();

  if (query.isLoading)
    return (
      <div
        style={{
          height: '100dvh',
        }}
      >
        <PageLoader />
      </div>
    );

  return (
    <>
      <div className={styles.container}>
        <Banner />
        <PlanSection />
        <HubCreditsSection />
        <PaymentMethod />
      </div>
      <CancelSheet />
      <PlanSheet />
      <HubCreditSheet />
      <HubInfoSheet />
      <TrialSheet />
    </>
  );
};
