import { Member, ResponseInvitee } from '@api/docs';
import { useChangePermissions } from '@pages/BoardPage/model/queries/useChangePermissions';

import { useRemoveFromDoc } from '../model/queries/use-remove-from-doc';
import { InvitedUser } from '../user/invited-user';
import { Owner } from '../user/owner';

import styles from './styles.module.css';

type InviteesProps = {
  invitees: ResponseInvitee;
};

export const Invitees = ({ invitees }: InviteesProps) => {
  const { chahgePermissions } = useChangePermissions({});

  const { removeInvitee } = useRemoveFromDoc();

  const removeFromBoard = (member: Member) => {
    removeInvitee({
      id: member.doc,
      members: [member.id],
    });
  };

  return (
    <>
      <ul className={styles.guestList}>
        {invitees?.members?.map((invitee) => {
          if (invitee.is_owner) {
            return <Owner key={invitee.id} user={invitee} />;
          } else {
            return (
              <InvitedUser
                key={invitee.id}
                user={invitee}
                permissions={invitee.permissions}
                chahgePermissions={chahgePermissions}
                removeFromBoard={removeFromBoard}
              />
            );
          }
        })}
      </ul>
    </>
  );
};
