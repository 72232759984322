import React, { ReactNode, SVGProps } from 'react';

export interface IconProps extends SVGProps<SVGSVGElement> {
  size?: number;
  viewBoxSize?: number;
  className?: string;
  fill?: string;
}

type IconSVGWrapperProps = IconProps & {
  children: ReactNode;
};

const Icon = ({
  size = 24,
  viewBoxSize = 24,
  className,
  children,
  fill,
  ...props
}: IconSVGWrapperProps): JSX.Element => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill={fill}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      {...props}
    >
      {children}
    </svg>
  );
};

export default Icon;
