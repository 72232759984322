import { Member, ResponseInvitee } from '@api/boards';
import { useChangePermissions } from '@pages/BoardPage/model/queries/useChangePermissions';
import { useRemoveInvitee } from '@pages/BoardPage/model/queries/useRemoveInvitee';
import { useAppData } from '@src/AppContext';

import { InvitedUser } from '../User/InvitedUser';
import { Owner } from '../User/Owner';

import styles from './styles.module.css';

type InviteesProps = {
  invitees: ResponseInvitee;
  boardName: string;
};

export const Invitees = ({ invitees, boardName }: InviteesProps) => {
  const { user } = useAppData();

  const { chahgePermissions } = useChangePermissions({});

  const { removeInvitee } = useRemoveInvitee({});

  const removeFromBoard = (member: Member) => {
    const emailRemovedUser = invitees.members?.filter(
      (invitee) => invitee.email === member.email,
    )[0].email;

    const noBoardAccess = user.email === emailRemovedUser;

    removeInvitee({
      boardId: member.board,
      members: [member.id],
      email: member.email,
      boardName,
      noBoardAccess,
    });
  };

  return (
    <>
      <ul className={styles.guestList}>
        {invitees?.members?.map((invitee) => {
          if (invitee.is_owner) {
            return <Owner key={invitee.id} user={invitee} />;
          } else {
            return (
              <InvitedUser
                key={invitee.id}
                user={invitee}
                permissions={invitee.permissions}
                chahgePermissions={chahgePermissions}
                removeFromBoard={removeFromBoard}
              />
            );
          }
        })}
      </ul>
    </>
  );
};
