import { PropsWithChildren, ReactNode } from 'react';

import {
  Divider,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const Card = ({ children }: PropsWithChildren) => {
  return (
    <li className={styles.item}>
      <div className={styles.card}>{children}</div>
    </li>
  );
};

export const CardHeader = ({
  title,
  icon,
}: PropsWithChildren<{
  title: string;
  icon?: ReactNode;
}>) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.header}>
        {icon}
        <TypographyPoppins className={styles.color} type="title" titleSize="M">
          {title}
        </TypographyPoppins>
      </div>
      <div className={styles.horizontal}>
        <Divider className={styles.divider} type="long-line" />
      </div>
    </div>
  );
};

export const CardContent = ({ children }: PropsWithChildren) => {
  return <div className={styles.details}>{children}</div>;
};

export const CardEmptyContent = ({
  icon,
  description,
  button,
}: {
  icon?: ReactNode;
  description?: string;
  button?: ReactNode;
}) => {
  return (
    <div className={styles.empty}>
      {icon ? <span className={styles.icon}>{icon}</span> : null}
      {description ? (
        <TypographyPoppins
          type="body"
          bodySize="S"
          className={styles.emptyText}
        >
          {description}
        </TypographyPoppins>
      ) : null}
      {button ? <span className={styles.button}>{button}</span> : null}
    </div>
  );
};
