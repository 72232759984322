import { useUnit } from 'effector-react';

import { VaiTagModal } from '@src/widgets/VaiTagModal';

import { $selectedTab } from '../../model';
import { Personalise } from '../personalise';
import { VaiChat } from '../vai-chat';

import styles from './styles.module.css';

export const VaiTabRenderer = () => {
  const [selectedTab] = useUnit([$selectedTab]);

  return (
    <div className={styles.content}>
      {(() => {
        switch (selectedTab) {
          case 'chat':
            return <VaiChat />;
          case 'personalize':
            return <Personalise />;
          case 'aesthetiq':
            return <VaiTagModal />;
          default:
            return null;
        }
      })()}
    </div>
  );
};
