import { ANGLE_ONE_EIGHTY } from './constants';

// function to calculate the rotated deltaX based on a given angle
export const getRotatedDeltaX = (
  RotationAngle: number,
  deltaX: number,
  deltaY: number,
) => {
  const rotationInRadians = (RotationAngle * Math.PI) / ANGLE_ONE_EIGHTY;
  return (
    deltaX * Math.cos(rotationInRadians) + deltaY * Math.sin(rotationInRadians)
  );
};

// function to calculate the rotated deltaY based on a given angle
export const getRotatedDeltaY = (
  RotationAngle: number,
  deltaX: number,
  deltaY: number,
) => {
  const rotationInRadians = (RotationAngle * Math.PI) / ANGLE_ONE_EIGHTY;
  return (
    deltaY * Math.cos(rotationInRadians) - deltaX * Math.sin(rotationInRadians)
  );
};

// function to determine the direction of resizing based on angles and handle positions
export const getDirection = (
  LowerAngle1: number,
  HigherAngle1: number,
  handle1: string,
  LowerAngle2: number,
  HigherAngle2: number,
  handle2: string,
  rotation: number,
  activeHandle: string,
) => {
  return (rotation > LowerAngle1 &&
    rotation < HigherAngle1 &&
    activeHandle.includes(handle1)) ||
    (rotation > LowerAngle2 &&
      rotation < HigherAngle2 &&
      activeHandle.includes(handle2))
    ? 1
    : -1;
};
