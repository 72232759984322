import { useEffect, useRef } from 'react';

/**
 * @description useFocusTrap is a custom hook that allows you to trap focus within a specific element.
 *
 * @param isOpen - A boolean value that indicates whether the focus trap is open or not.
 * @param ref - A React ref object that references the element to be focused.
 * @param key - A string value that is used to identify the focus trap.
 */

export const useFocusTrap = ({
  isOpen,
  ref,
  key = '',
  setInitialFocus = true,
}: {
  isOpen: boolean;
  ref: React.RefObject<HTMLElement>;
  key?: string;
  setInitialFocus?: boolean;
}) => {
  const firstFocusableElementRef = useRef<HTMLElement | null>(null);
  const lastFocusableElementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (isOpen && ref.current) {
      const focusableElementsString =
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';
      const focusableElements = ref.current.querySelectorAll(
        focusableElementsString,
      );
      if (!focusableElements.length) return;

      firstFocusableElementRef.current = focusableElements[0] as HTMLElement;
      lastFocusableElementRef.current = focusableElements[
        focusableElements.length - 1
      ] as HTMLElement;

      const handleKeyDown = (e: KeyboardEvent) => {
        const isTabPressed = e.key === 'Tab';

        if (!isTabPressed) return;

        if (e.shiftKey) {
          // shift + tab
          if (document.activeElement === firstFocusableElementRef.current) {
            e.preventDefault();
            lastFocusableElementRef.current?.focus();
          }
        } else {
          // tab
          if (document.activeElement === lastFocusableElementRef.current) {
            e.preventDefault();
            firstFocusableElementRef.current?.focus();
          }
        }
      };

      ref.current?.addEventListener('keydown', handleKeyDown);

      ref.current.tabIndex = -1;
      if (setInitialFocus) {
        setTimeout(() => {
          ref.current?.focus();
        }, 200);
      }

      return () => {
        ref.current?.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen, key]);
};
