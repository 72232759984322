import { ResponseDocs } from '@api/docs';
import { useBoardBlocks } from '@pages/BoardPage/useBoardBlocks';

import { LayoutGrid } from '../../../../components';

type Props = {
  boardId: string;
  hasPermissionEditing: boolean;
  isOwner: boolean;
  docs?: ResponseDocs;
};

export const Grid = ({
  boardId,
  hasPermissionEditing,
  isOwner,
  docs,
}: Props) => {
  const imageBlockQuery = useBoardBlocks({ boardId, typeOfBlock: 'image' });
  const designBlockQuery = useBoardBlocks({ boardId, typeOfBlock: 'set' });
  const paletteBlockQuery = useBoardBlocks({
    boardId,
    typeOfBlock: 'color_palette',
  });

  const hasLoadedBlocks =
    imageBlockQuery.isFetched &&
    designBlockQuery.isFetched &&
    paletteBlockQuery.isFetched;

  const refetchQueries = () => {
    imageBlockQuery.refetch();
    designBlockQuery.refetch();
    paletteBlockQuery.refetch();
  };

  return (
    <LayoutGrid
      allDataLoaded={hasLoadedBlocks}
      designBlocks={designBlockQuery.blocks}
      designBlocksLoading={designBlockQuery.isLoading}
      imageBlocks={imageBlockQuery.blocks}
      imageBlocksLoading={imageBlockQuery.isLoading}
      paletteBlocks={paletteBlockQuery.blocks}
      paletteBlocksLoading={paletteBlockQuery.isLoading}
      refetchQueries={refetchQueries}
      hasPermissionEditing={hasPermissionEditing}
      isOwner={isOwner}
      page="board"
      imageBlockHasNext={false}
      designBlocksHasNext={false}
      paletteBlocksHasNext={false}
      imageBlockFetchNext={imageBlockQuery.fetchNextPage}
      designBlocksFetchNext={designBlockQuery.fetchNextPage}
      paletteBlocksFetchNext={paletteBlockQuery.fetchNextPage}
      boardId={boardId}
      docs={docs}
    />
  );
};
