import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { FormikErrors } from 'formik';

import { useCombinedRefs } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import CheckIcon from '../Icons/CheckIcon';

import styles from './Input.module.css';

type ReactInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
export interface InputProps extends ReactInputProps {
  className?: string;
  customStyleHintText?: string;
  customErrorMessage?: string;
  customStyleRightIcon?: string;
  value?: string;
  onChangeValue?: (value: string) => void;
  onKeyUpValue?: (value: string) => void;
  ref?: React.Ref<HTMLInputElement>;

  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;

  hintText?: string;
  placeholder?: string;
  isLabel?: boolean;
  customLabel?: string;

  errorMessage?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  successMessage?: string;
}

type Ref = HTMLInputElement;
const Input = React.forwardRef<Ref, InputProps>(
  (
    {
      value,
      leftIcon,
      rightIcon,
      hintText,
      placeholder,
      isLabel = true,
      customLabel,
      errorMessage,
      successMessage,
      className,
      customStyleHintText,
      customErrorMessage,
      customStyleRightIcon,
      onChangeValue,
      onKeyUpValue,
      ...inputProps
    },
    forwardRef,
  ) => {
    const ref = useCombinedRefs(useRef(null), forwardRef);
    const [focused, setFocused] = useState(false);
    const onChange: any = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeValue?.(event.currentTarget.value);
      inputProps.onChange?.(event);
    };
    const onKeyUp: any = (event: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyUpValue?.(event.currentTarget.value);
      inputProps.onKeyUp?.(event);
    };
    const onFocusIn: any = () => {
      setFocused(true);
    };
    const onFocusOut: any = () => {
      setFocused(false);
    };

    useEffect(() => {
      ref.current?.addEventListener('focusin', onFocusIn);
      ref.current?.addEventListener('focusout', onFocusOut);
      return () => {
        ref.current?.removeEventListener('focusin', onFocusIn);
        ref.current?.removeEventListener('focusout', onFocusOut);
      };
    }, []);

    return (
      <>
        <div
          className={cx(className, styles.inputWrapper, {
            [styles.disabled]: inputProps.disabled,
            [styles.readOnly]: inputProps.readOnly,
            [styles.focused]: focused,
            [styles.isError]: errorMessage !== undefined,
            [styles.isSuccess]: successMessage !== undefined,
          })}
        >
          {isLabel && (placeholder || customLabel) && (focused || value) && (
            <div className={cx(styles.labelWrapper)}>
              <span className={cx(styles.label)}>
                {customLabel || placeholder}
              </span>
            </div>
          )}
          {leftIcon && <div className={cx(styles.leftIcon)}>{leftIcon}</div>}
          <input
            {...inputProps}
            value={value}
            placeholder={placeholder}
            ref={ref}
            className={cx(className, styles.input)}
            onChange={onChange}
            onKeyUp={onKeyUp}
            autoComplete="off"
          />
          {rightIcon && (
            <div className={cx(styles.rightIcon, customStyleRightIcon)}>
              {rightIcon}
            </div>
          )}
          {hintText && (
            <>
              <div className={cx(styles.hintText, customStyleHintText)}>
                {hintText}
              </div>
              <span className={styles.tooltip}>
                We'll tell you when someone replies
              </span>
            </>
          )}
        </div>
        {errorMessage && (
          <div className={cx(styles.errorMessage, customErrorMessage)}>
            <>
              <Icon name="sprite/information" size={16} />
              {errorMessage}
            </>
          </div>
        )}
        {successMessage && (
          <div className={cx(styles.successMessage)}>
            <CheckIcon size={16} />
            {successMessage}
          </div>
        )}
      </>
    );
  },
);

export default Input;
