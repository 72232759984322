import { ReactNode } from 'react';

import cn from 'classnames';

import { TabsStyle } from '..';

import styles from './styles.module.css';

interface TrailingIconProps {
  type: TabsStyle;
  iconTrailing: ReactNode;
  onClickIconTrailingHandler: any;
  isDisabled?: boolean;
  isSelected?: boolean;
}

export const TrailingIcon = ({
  type,
  iconTrailing,
  isDisabled,
  isSelected,
  onClickIconTrailingHandler,
}: TrailingIconProps) => (
  <button
    className={cn(styles.icon, {
      [styles.selected]:
        isSelected && type == 'leading icon + trailing icon + label',
      [styles.disabled]: isDisabled,
    })}
    onClick={onClickIconTrailingHandler}
  >
    {iconTrailing}
  </button>
);
