import {
  docPathFinalStep,
  hubPathFinalStep,
  OptionKey,
  Step,
  studioColourPathFinalStep,
  User,
} from '@src/AppContext';

import { hidingTooltip, showingTooltip } from './model';

export const positionTooltip = ({
  currentStep,
  tooltipRef,
}: // from,
{
  tooltipRef: React.RefObject<HTMLDivElement>;
  currentStep: Step;
  from?: string;
}) => {
  // console.log(from);
  let targetElement = null;
  if (tooltipRef.current) {
    if (currentStep.type === 'target') {
      // Target positioning
      targetElement = document.getElementById(currentStep.position.targetId);
      if (!targetElement) {
        // hidingTooltip();
        return null;
      }

      const targetRect = targetElement.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      const position = {
        top: targetRect.top,
        left: targetRect.left,
      };

      const scrollX = document.documentElement.scrollLeft;
      const scrollY = document.documentElement.scrollTop;

      const leftOffset = currentStep.position.offset?.left ?? 0;
      const topOffset = currentStep.position.offset?.top ?? 0;

      if (leftOffset === 0 && topOffset === 0) {
        hidingTooltip();
      }

      let topPos = 0;
      switch (currentStep.position.alignment) {
        case 'start':
          topPos = position.top + topOffset + scrollY;
          break;
        case 'center':
          topPos =
            position.top +
            topOffset +
            scrollY +
            targetRect.height / 2 -
            tooltipRect.height / 2;
          break;
        case 'end':
          topPos =
            position.top +
            topOffset +
            scrollY +
            targetRect.height -
            tooltipRect.height;
          break;
      }

      let leftPos = 0;
      switch (currentStep.position.anchor) {
        case 'left':
          leftPos = position.left + scrollX - leftOffset - tooltipRect.width;
          break;
        case 'right':
          leftPos = position.left + targetRect.width + leftOffset + scrollX;
          break;
        case 'bottom':
          leftPos = position.left + leftOffset + scrollX - targetRect.width / 2;
          break;
        case 'top':
          leftPos = position.left + leftOffset + scrollX;
          break;
      }

      tooltipRef.current.style.left = `${leftPos}px`;
      tooltipRef.current.style.top = `${topPos}px`;
    }
  }
  showingTooltip();
  return targetElement;
};

export const setupWindowResizeObserver = ({
  currentStep,
  tooltipRef,
}: {
  currentStep: Step;
  tooltipRef: React.RefObject<HTMLDivElement>;
}) => {
  if (currentStep.type === 'page')
    throw Error('Should not be called on page positioning');

  const resizeObserver = new ResizeObserver(() => {
    positionTooltip({
      currentStep,
      tooltipRef,
      from: 'position from window resize',
    });
  });

  resizeObserver.observe(document.body);

  return resizeObserver;
};

export const setupResizeAndIntersectionObserver = ({
  currentStep,
  tooltipRef,
  targetElement,
}: {
  currentStep: Step;
  tooltipRef: React.RefObject<HTMLDivElement>;
  targetElement: HTMLElement;
}) => {
  if (currentStep.type === 'page')
    throw Error('Should not be called on page positioning');

  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        positionTooltip({
          currentStep,
          tooltipRef,
          from: 'position from intersection',
        });
      }
    },
    { threshold: 0.1 }, // Trigger when at least 10% of the target is visible
  );

  const resizeObserver = new ResizeObserver(() => {
    positionTooltip({
      currentStep,
      tooltipRef,
      from: 'position from resize',
    });
  });

  observer.observe(targetElement);
  resizeObserver.observe(targetElement);

  return [observer, resizeObserver] as const;
};

export const setupMutationObserver = ({
  currentStep,
  tooltipRef,
}: // targetElement,
{
  currentStep: Step;
  tooltipRef: React.RefObject<HTMLDivElement>;
  targetElement: HTMLElement;
}) => {
  if (currentStep.type === 'page')
    throw Error('Should not be called on page positioning');

  const targetDFlex = document.getElementById('d-flex');

  if (targetDFlex) {
    const observer = new MutationObserver((mutations) => {
      // if (!targetElement?.isConnected) {
      //   console.log('HIDING FROM WITHIN POSITION');
      //   hidingTooltip();
      //   return;
      // }

      // callback function to handle mutations
      for (const mutation of mutations) {
        if (mutation.type === 'childList') {
          for (const addedNode of mutation.addedNodes) {
            if (addedNode.nodeType === Node.ELEMENT_NODE) {
              // @ts-ignore
              const childNode = addedNode.querySelector(
                `#${currentStep.position.targetId}`,
              );
              if (childNode) {
                // Know for a fact node is added
                setTimeout(
                  () =>
                    positionTooltip({
                      currentStep,
                      tooltipRef,
                      from: 'position from mutation',
                    }),
                  500,
                );
              }
            }
          }
        }
      }
    });

    const options = {
      childList: true,
      subtree: true,
    } satisfies MutationObserverInit;

    observer.observe(document.body, options);

    return observer;
  }
  return null;
};

export const hasUserCompletedAllPaths = (user: User) => {
  if (!user.meta?.onboarding) return false;

  // return (
  //   user.meta.onboarding.completedPaths.createHubs &&
  //   user.meta.onboarding.completedPaths.manageBusinessAdmin
  // );
  return Object.values(user.meta.onboarding.completedPaths).every(
    (completed) => completed,
  );
};

/**
 * @description This function decides the next onboarding path after one is completed. Returns false if none remaining
 * @param user
 * @returns
 */

export const getNextPath = (user: User): false | OptionKey => {
  // returns any remaing paths that have not been completed
  if (hasUserCompletedAllPaths(user)) return false;

  const workCreatively = user.meta?.onboarding?.workCreatively;
  const manageBusinessAdmin = user.meta?.onboarding?.manageBusinessAdmin;
  const createHubs = user.meta?.onboarding?.createHubs;

  let remainingPaths: OptionKey[] = [
    'createHubs',
    'workCreatively',
    'manageBusinessAdmin',
  ];

  if (createHubs === hubPathFinalStep) {
    remainingPaths = remainingPaths.filter((path) => path !== 'createHubs');
  }
  if (workCreatively === studioColourPathFinalStep)
    remainingPaths = remainingPaths.filter((path) => path !== 'workCreatively');
  if (manageBusinessAdmin === docPathFinalStep)
    remainingPaths = remainingPaths.filter(
      (path) => path !== 'manageBusinessAdmin',
    );

  return remainingPaths[0] ? remainingPaths[0] : false;
};
