import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { AnimatePresence, motion, Reorder } from 'framer-motion';
import { jsPDF } from 'jspdf';
import { Stage } from 'konva/lib/Stage';
import { useHistory } from 'react-router-dom';

import {
  Dropdown,
  Item,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { useOnClickOutside } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { BoardLvl1, BoardLvl2, BoardLvl3 } from '@api/designs';
import { useDesign } from '@pages/StudioPage/hooks/useDesign';
import { useOpenedDesigns } from '@pages/StudioPage/hooks/useOpenedDesigns';
import { generatePreview } from '@pages/StudioPage/utils';
import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { IMAGE_PREVIEW_MUTATION } from '@src/shared/constants/query-names';
import { useIsMutating } from '@tanstack/react-query';

import styles from './styles.module.css';

type Props = {
  designId: string;
  stageRef: MutableRefObject<Stage | null>;
};

export const Tabs = ({ designId, stageRef }: Props) => {
  const { designQuery, createDesignMutation } = useDesign({ designId });

  const history = useHistory();
  const {
    openedDesigns,
    saveDesign,
    loadOpenedDesigns,
    removeDesign,
    reorderOpenedDesigns,
    updateDesignName,
    // validateOpenDesigns
  } = useOpenedDesigns();

  const name = designQuery.data?.name || 'Untitled';
  const board = designQuery.data?.board[0];

  const isUploadingPreview = useIsMutating({
    mutationKey: [IMAGE_PREVIEW_MUTATION],
  });

  React.useEffect(() => {
    if (!openedDesigns) {
      loadOpenedDesigns();
    }
  }, []);

  React.useEffect(() => {
    if (openedDesigns && designQuery.data && !openedDesigns.has(designId)) {
      saveDesign({
        designId: designQuery.data.id,
        name: designQuery.data.name,
      });
    }
  }, [designQuery.data]);

  // if (designQuery.isLoading) return <div className={styles.container} />;

  const downloadThumbnailJPG = () => {
    if (!stageRef.current) return;
    const { data } = generatePreview(stageRef.current, 'export', 'image/jpeg');

    if (data) {
      startedSnack({
        label: 'Exported design',
        close: true,
      });
    } else {
      startedSnack({
        label: "Couldn't export design",
        action: {
          label: 'Try again',
          action: () => {
            downloadThumbnailJPG();
          },
        },
        close: true,
      });

      return;
    }

    const link = document.createElement('a');
    const downloadName = `${name}.jpeg`;
    link.download = downloadName;
    link.href = data as string;
    link.click();
  };

  const downloadThumbnailPNG = () => {
    if (!stageRef.current) return;
    const { data } = generatePreview(stageRef.current, 'export', 'image/png');

    if (data) {
      startedSnack({
        label: 'Exported design',
        close: true,
      });
    } else {
      startedSnack({
        label: "Couldn't export design",
        action: {
          label: 'Try again',
          action: () => {
            downloadThumbnailPNG();
          },
        },
        close: true,
      });

      return;
    }

    const link = document.createElement('a');
    const downloadName = `${name}.png`;
    link.download = downloadName;
    link.href = data as string;
    link.click();
  };

  const downloadPDF = () => {
    if (!stageRef.current) return;
    const { data, maxX, minX, maxY, minY } = generatePreview(
      stageRef.current,
      'export',
      'image/png',
    );

    if (data) {
      startedSnack({
        label: 'Exported design',
      });
    } else {
      startedSnack({
        label: "Couldn't export design",
        action: {
          label: 'Try again',
          action: () => {
            downloadPDF();
          },
        },
        close: true,
      });

      return;
    }

    const pdf = new jsPDF('p', 'px', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const widthRatio = pageWidth / (maxX - minX);
    const heightRatio = pageHeight / (maxY - minY);
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
    const canvasWidth = (maxX - minX) * ratio;
    const canvasHeight = (maxY - minY) * ratio;

    const marginX = (pageWidth - canvasWidth) / 2;
    const marginY = (pageHeight - canvasHeight) / 2;
    pdf.addImage(data, 'PNG', marginX, marginY, canvasWidth, canvasHeight);

    const downloadName = `${name}.pdf`;

    pdf.save(downloadName);
  };

  if (!openedDesigns) return <div className={styles.container} />;

  // Order designs by index for dragging tabs
  const orderedDesigns = Array.from(openedDesigns.values()).sort((a, b) => {
    return a.index - b.index;
  });

  const closeDesign = (id: string) => {
    let shouldDelete = true;
    if (isUploadingPreview) {
      shouldDelete = confirm(
        'We’re saving your design. If you go to another page, you may lose some changes.',
      );
    }
    if (shouldDelete) {
      removeDesign(id);
      postCloseAction(id);
    }
  };

  const postCloseAction = (id: string) => {
    if (
      orderedDesigns.length > 0 &&
      orderedDesigns[orderedDesigns.length - 1].id !== id
    ) {
      history.push(`/studio/${orderedDesigns[orderedDesigns.length - 1].id}`);
      return;
    } else if (orderedDesigns[orderedDesigns.length - 1].id === id) {
      history.push(`/studio/${orderedDesigns[orderedDesigns.length - 2].id}`);
      return;
    } else {
      history.push(`/home`);
    }
  };

  return (
    <Tab.Container
      reorder={reorderOpenedDesigns}
      values={orderedDesigns.map((od) => od.index.toString())}
    >
      <AnimatePresence mode="popLayout">
        {orderedDesigns.map((d) => (
          <Tab
            key={d.id.toString()}
            id={d.id}
            isSelected={d.id === designId}
            onClick={() => {
              if (d.id === designId) return;
              history.push(`/studio/${d.id}`);
            }}
            removeDesign={closeDesign}
            orderIndex={d.index.toString()}
            updateDesignName={updateDesignName}
            downloadThumbnailPNG={downloadThumbnailPNG}
            downloadThumbnailJPG={downloadThumbnailJPG}
            downloadPDF={downloadPDF}
            board={board}
          >
            {openedDesigns.get(d.id)?.name || 'Untitled'}
          </Tab>
        ))}
        <motion.button
          onClick={() => createDesignMutation.mutate()}
          layout
          className={styles.addButton}
        >
          <Icon name="sprite/plus" />
        </motion.button>
      </AnimatePresence>
    </Tab.Container>
  );
};

type TabProps = {
  id: string;
  isSelected: boolean;
  onClick: () => void;
  children: React.ReactNode;
  orderIndex: string;
  removeDesign: (id: string) => void;
  updateDesignName: (name: string, designId: string) => void;
  downloadThumbnailPNG: () => void;
  downloadThumbnailJPG: () => void;
  downloadPDF: () => void;
  board: BoardLvl1 | BoardLvl2 | BoardLvl3 | undefined;
};

const Tab = ({
  id,
  isSelected,
  onClick,
  children,
  removeDesign,
  updateDesignName,
  downloadThumbnailPNG,
  downloadThumbnailJPG,
  downloadPDF,
  orderIndex,
  board,
}: TabProps) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);
  const [isShowTooltip, setShowTooltip] = useState(false);

  const labelRef = useRef<HTMLSpanElement>(null);
  const [isHovered, setIsHovered] = React.useState(false);
  const [isRenaming, setIsRenaming] = React.useState(false);
  const { updateDesignNameMutation } = useDesign({ designId: id });
  const ref = React.useRef<HTMLDivElement | null>(null);

  const closeDesign = () => {
    removeDesign(id);
  };

  const saveText = () => {
    if (!labelRef.current) {
      setIsRenaming(false);
      return;
    }

    updateDesignName(id, labelRef.current.innerText);

    updateDesignNameMutation.mutate({
      id,
      name: labelRef.current.innerText,
    });

    setIsRenaming(false);
  };

  const toggleRenaming = () => {
    setIsRenaming((s) => !s);
    setTimeout(() => {
      if (labelRef.current) {
        labelRef.current.focus();
      }
    }, 50);
  };

  const handleDownloadThumbnailPNG = () => {
    downloadThumbnailPNG();
  };

  const handleDownloadThumbnailJPG = () => {
    downloadThumbnailJPG();
  };

  const handleDownloadPDF = () => {
    downloadPDF();
  };

  // const handleCopy = (e: MouseEvent<HTMLDivElement>) => {
  //   e.stopPropagation();
  //   if (design) {
  //     copyFile(design);

  //     startedSnack({
  //       type: 'success',
  //       label: 'Copied as PNG.',
  //     });
  //   } else {
  //     startedSnack({
  //       type: 'error',
  //       label: "Couldn't copy that.",
  //       action: {
  //         label: 'Try again',
  //         action: () => {
  //           handleCopy(e);
  //         },
  //       },
  //     });
  //   }
  // };

  const copyLink = async () => {
    const url = new URL(document.location.href);
    try {
      await navigator.clipboard.writeText(url.href);

      startedSnack({
        label: 'Copied link to design',
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to design",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  // const menuItemsTop: Item[] = [
  //   {
  //     leadingIcon: <CopyIcon />,
  //     content: 'Copy as PNG',
  //     onClick: (e) => handleCopy(e),
  //   },
  // ];

  const menuItemsBottom: Item[] = [
    {
      leadingIcon: (
        <Icon name="sprite/link" size={21} className={styles.link} />
      ),
      content: 'Copy link',
      onClick: copyLink,
    },
    {
      leadingIcon: <Icon name="sprite/pen" />,
      content: 'Rename',
      isDivider: true,
      onClick: toggleRenaming,
    },
    {
      leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
      content: 'Delete',
      classNameContent: styles.delete,
      onClick: closeDesign,
    },
  ];

  const subMenuItems: Item[] = [
    {
      content: 'PNG',
      classNameContent: styles.padding,
      onClick: handleDownloadThumbnailPNG,
    },
    {
      content: 'JPG',
      classNameContent: styles.padding,
      onClick: handleDownloadThumbnailJPG,
    },
    {
      content: 'PDF',
      classNameContent: styles.padding,
      onClick: handleDownloadPDF,
    },
  ];

  React.useEffect(() => {
    if (!labelRef.current || !isRenaming) return;

    // Set the cursor at the end of the text
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(labelRef.current);

    selection?.removeAllRanges();
    selection?.addRange(range);
  }, [isRenaming]);

  useOnClickOutside(ref, () => {
    setIsRenaming(false);
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isSelected) {
      timer = setTimeout(() => {
        setShowTooltip(true);
      }, 300);
    } else {
      setShowTooltip(false);
    }

    return () => clearTimeout(timer);
  }, [isSelected]);

  return isSelected && board ? (
    <Tooltip
      parameter={{
        description: <Breadcrumbs board={board} />,
        type: 'plain',
        position: 'bottom',
      }}
      style={{
        maxWidth: '230px',
        width: 'max-content',
        minWidth: 'min-content',
        display: isShowTooltip ? 'flex' : 'none',
      }}
    >
      <Reorder.Item
        value={orderIndex}
        ref={ref}
        className={cn(styles.tabContainer, {
          [styles.tabContainerSelected]: isSelected,
        })}
        draggable={!isRenaming}
        drag="x"
        onDragEnd={(e) => e.stopPropagation()}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        layout="position"
      >
        <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
          <Dropdown.Menu
            trigger={
              isSelected && (
                <button
                  className={styles.dropdownButton}
                  onClick={() => setOpenDropdown(!isOpenDropdown)}
                >
                  <Icon name="sprite/caret-down" />
                </button>
              )
            }
            side="bottom"
            sideOffset={20}
            align="start"
            alignOffset={-13}
            density="-2"
          >
            <Dropdown.SubMenu
              icon={<Icon name="sprite/download" />}
              subTriggerByClick="Download"
            >
              {subMenuItems.map((item, index) => (
                <Dropdown.SubMenuItem key={index} item={item} />
              ))}
            </Dropdown.SubMenu>
            {menuItemsBottom.map((item, index) => (
              <Dropdown.MenuItem key={index} item={item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <TypographyPoppins type="label" size="L">
          <span
            tabIndex={1}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                saveText();
              }
              if (e.key === 'Escape') {
                e.preventDefault();
                setIsRenaming(false);
              }
            }}
            ref={labelRef}
            contentEditable={isRenaming}
            className={cn(styles.textarea, {
              [styles.editing]: isRenaming,
            })}
          >
            {children}
          </span>
        </TypographyPoppins>
        {(isSelected && isHovered && !isRenaming) || isOpenDropdown ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              closeDesign();
            }}
            className={styles.closeButton}
          >
            <Icon name="sprite/x" />
          </button>
        ) : null}
        {isRenaming && isSelected ? (
          <button onClick={saveText} className={styles.closeButton}>
            <Icon name="sprite/tick" />
          </button>
        ) : null}
      </Reorder.Item>
    </Tooltip>
  ) : (
    <Reorder.Item
      value={orderIndex}
      ref={ref}
      className={cn(styles.tabContainer, {
        [styles.tabContainerSelected]: isSelected,
      })}
      draggable={!isRenaming}
      drag="x"
      onDragEnd={(e) => e.stopPropagation()}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      layout="position"
    >
      <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
        <Dropdown.Menu
          trigger={
            isSelected && (
              <button
                className={styles.dropdownButton}
                onClick={() => setOpenDropdown(!isOpenDropdown)}
              >
                <Icon name="sprite/caret-down" />
              </button>
            )
          }
          side="bottom"
          sideOffset={20}
          align="start"
          alignOffset={-13}
          density="-2"
        >
          <Dropdown.SubMenu
            icon={<Icon name="sprite/download" />}
            subTriggerByClick="Download"
          >
            {subMenuItems.map((item, index) => (
              <Dropdown.SubMenuItem key={index} item={item} />
            ))}
          </Dropdown.SubMenu>
          {menuItemsBottom.map((item, index) => (
            <Dropdown.MenuItem key={index} item={item} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <TypographyPoppins type="label" size="L">
        <span
          tabIndex={1}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              saveText();
            }
            if (e.key === 'Escape') {
              e.preventDefault();
              setIsRenaming(false);
            }
          }}
          ref={labelRef}
          contentEditable={isRenaming}
          className={cn(styles.textarea, {
            [styles.editing]: isRenaming,
          })}
        >
          {children}
        </span>
      </TypographyPoppins>
      {(isSelected && isHovered && !isRenaming) || isOpenDropdown ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            closeDesign();
          }}
          className={styles.closeButton}
        >
          <Icon name="sprite/x" />
        </button>
      ) : null}
      {isRenaming && isSelected ? (
        <button onClick={saveText} className={styles.closeButton}>
          <Icon name="sprite/tick" />
        </button>
      ) : null}
    </Reorder.Item>
  );
};

type ContainerProps = {
  children: React.ReactNode;
  values: Array<string>;
  reorder: (values: Array<string>) => void;
};

Tab.Container = ({ children, values, reorder }: ContainerProps) => {
  return (
    <Reorder.Group
      axis="x"
      values={values}
      onReorder={reorder}
      as="div"
      className={styles.container}
    >
      {children}
    </Reorder.Group>
  );
};
