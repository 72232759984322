import { Button } from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

interface ProgressProps {
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  onSkip?: () => void;
  currentStep: number;
}

export const Progress = ({
  onNextClick,
  onPreviousClick,
  currentStep,
  onSkip,
}: ProgressProps) => {
  const onSkipClick = () => {
    if (onSkip) {
      onSkip();
    }
  };
  return (
    <div className={styles.progressWrapper}>
      <div className={styles.progressContainer}>
        {currentStep == 1 ? (
          <>
            <div className={styles.progressCurrent}></div>
            <div className={styles.progressNext} onClick={onNextClick}></div>
          </>
        ) : (
          <>
            <div
              className={styles.progressCompleted}
              onClick={onPreviousClick}
            ></div>
            <div className={styles.progressCurrent}></div>
          </>
        )}
      </div>
      {currentStep == 1 && (
        <Button
          type="ghost"
          label="Skip"
          onClick={onSkipClick}
          className={styles.skipBtn}
        />
      )}
    </div>
  );
};
