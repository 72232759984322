import { ChangeEvent, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router';

import { Divider } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { useHubs } from '@pages/HubPage/model/queries/useHubs';

import styles from './styles.module.css';

type WelcomeProps = {
  title: string;
  body: string;
};

export const Welcome = ({ title, body }: WelcomeProps) => {
  const [isEditHeader, setEditHeader] = useState(false);
  const [isEditBody, setEditBody] = useState(false);
  const [localHeader, setLocalHeader] = useState('');
  const [localBody, setLocalBody] = useState('');

  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const { hub_id } = useParams<{ hub_id: string }>();

  const { editHub } = useHubs({});

  useEffect(() => {
    if (title) {
      setLocalHeader(title);
    }
  }, [title]);

  useEffect(() => {
    if (body) {
      setLocalBody(body);
    }
  }, [body]);

  const onDoubleClickHeader = () => {
    setEditHeader(true);

    setTimeout(() => {
      if (headerRef.current) {
        headerRef.current.focus();

        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(headerRef.current);
        range.collapse(false);
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }, 0);
  };

  const onInputHeader = (e: ChangeEvent<HTMLDivElement>) => {
    setLocalHeader(e.target.innerText);
  };

  const editTitle = () => {
    if (localHeader !== title) {
      editHub({ welcome_title: localHeader, hubId: hub_id });
    }
    setEditHeader(false);
  };

  const onBlurHeader = () => {
    editTitle();
  };

  const onDoubleClickBody = () => {
    setEditBody(true);

    setTimeout(() => {
      if (bodyRef.current) {
        bodyRef.current.focus();

        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(bodyRef.current);
        range.collapse(false);
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }, 0);
  };

  const onInputBody = (e: ChangeEvent<HTMLDivElement>) => {
    setLocalBody(e.target.innerText);
  };

  const editBody = () => {
    if (localBody !== body) {
      editHub({ welcome_message: localBody, hubId: hub_id });
    }
    setEditBody(false);
  };

  const onBlurBody = () => {
    editBody();
  };

  return (
    <li className={styles.item}>
      <div className={styles.card}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <div className={styles.icon}>
              <Icon className={styles.color} name="sprite/hub" />
            </div>
            <div
              ref={headerRef}
              className={cn(styles.headerText, {
                [styles.editMode]: isEditHeader,
              })}
              contentEditable={isEditHeader}
              onDoubleClick={onDoubleClickHeader}
              onInput={onInputHeader}
              onBlur={onBlurHeader}
            >
              {title ? title : 'Welcome'}
            </div>
          </div>
        </div>
        <div className={styles.horizontal}>
          <Divider className={styles.divider} type="long-line" />
        </div>
        <div className={styles.body}>
          <div
            ref={bodyRef}
            className={cn(styles.bodyContent, {
              [styles.editMode]: isEditBody,
            })}
            contentEditable={isEditBody}
            onDoubleClick={onDoubleClickBody}
            onInput={onInputBody}
            onBlur={onBlurBody}
          >
            {body
              ? body
              : `Welcome to your client hub. 
                  
            Here, you’ll find everything you need to keep updated.`}
          </div>
        </div>
      </div>
    </li>
  );
};
