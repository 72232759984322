import { useEffect } from 'react';

import { useUnit } from 'effector-react';

import {
  TypographyItalic,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';

import { useAppData } from '@src/AppContext';
import { SMALL } from '@src/shared/constants/breakpoints';

import { $quote, getQuote } from './model';

import styles from './styles.module.css';

export const WelcomeMessage = () => {
  const quote = useUnit($quote);

  const { user } = useAppData();
  const { width } = useWindowSize();

  useEffect(() => {
    getQuote();
  }, []);

  if (!quote) return null;

  return (
    <div className={styles.welcome}>
      {width <= SMALL ? (
        <>
          <TypographyPoppins
            type="headline"
            headlineSize="M"
            className={styles.title}
          >
            {user.first_name ? `Hi ${user.first_name}` : 'Your daily reminder'}
          </TypographyPoppins>
          <TypographyPoppins
            type="body"
            bodySize="S"
            className={styles.message}
          >
            {quote.message}
          </TypographyPoppins>
          <TypographyItalic
            type="body"
            formattingBody="small italic"
            customStyle={styles.author}
          >
            {quote.author}
          </TypographyItalic>
        </>
      ) : (
        <>
          <TypographyPoppins
            type="headline"
            headlineSize="L"
            className={styles.title}
          >
            {user.first_name ? `Hi ${user.first_name}` : 'Your daily reminder'}
          </TypographyPoppins>
          <TypographyPoppins
            type="body"
            bodySize="M"
            className={styles.message}
          >
            {quote.message}
          </TypographyPoppins>
          <TypographyItalic
            type="body"
            formattingBody="small italic"
            customStyle={styles.author}
          >
            {quote.author}
          </TypographyItalic>
        </>
      )}
    </div>
  );
};
