import AutoJoiner from 'tiptap-extension-auto-joiner';

import TextColor from '@tiptap/extension-color';
import Focus from '@tiptap/extension-focus';
import FontFamily from '@tiptap/extension-font-family';
import Link from '@tiptap/extension-link';
// import Placeholder from '@tiptap/extension-placeholder';
import TableRow from '@tiptap/extension-table-row';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

import { File } from '../nodes';
import { Background } from './background-color';
import GlobalDragHandle from './dragHandle';
import { FilterDeleteExtension } from './filterDelete';
// import DragHandle from './dragHandle';
import { FontSize } from './fontSize';
import { Selection } from './selection';
import { TableExtension } from './table';
// import TableCell from '@tiptap/extension-table-cell';
import { TableCell } from './tableCell';
import { TableHeader } from './tableHeader';
import { TrailingNode } from './tailing-node';

export const extensions = [
  // Heading.configure({
  //   levels: [1, 2, 3],
  // }),
  Selection,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
    alignments: ['left', 'right', 'center'],
    defaultAlignment: 'left',
  }),
  TextStyle,
  FontSize,
  FontFamily,
  TextColor,
  Underline,
  Link,
  TrailingNode,
  TaskItem.configure({
    nested: true,
  }),
  TaskList,
  File,
  FilterDeleteExtension,
  GlobalDragHandle.configure({
    dragHandleWidth: 20,
    dragHandleSelector: '.drag-handle',
    // For example, setting this option to ['p', 'hr'] will hide the global drag handle for <p> and <hr> tags.
    excludedTags: [], // default
  }),
  // Placeholder.configure({
  //   includeChildren: true,
  //   showOnlyCurrent: false,
  //   placeholder: () => '',
  // }),
  AutoJoiner,
  StarterKit.configure({
    dropcursor: {
      class: 'drop-cursor',
    },
    gapcursor: false,
  }),
  Focus.configure({
    className: 'has-focus',
    mode: 'all',
  }),
  TableExtension.configure(),
  TableRow.extend({
    allowGapCursor: false,
    content: 'tableCell*',
  }),
  TableCell,
  TableHeader,
  Background,
];
