export function copyToClipboard(blob: Blob | null) {
  if (blob) {
    const clipboardItem = new ClipboardItem({ [blob.type]: blob });
    navigator.clipboard.write([clipboardItem]);
  }
}

function convertToPngAndCopyToClipboard(imgBlob: any) {
  const imageUrl = window.URL.createObjectURL(imgBlob);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (ctx) {
    const imageEl = document.createElement('img');
    imageEl.src = imageUrl;
    imageEl.crossOrigin = 'anonymous';

    imageEl.onload = ({ target }: any) => {
      const { width, height } = target;

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(target, 0, 0, width, height);
      canvas.toBlob(copyToClipboard, 'image/png', 1);
    };
  }
}

export const copyFile = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();

  try {
    if (url.endsWith('.jpg') || url.endsWith('.jpeg')) {
      convertToPngAndCopyToClipboard(blob);
    } else if (url.endsWith('.png')) {
      copyToClipboard(blob);
    }
  } catch (err) {
    const typedError = err as any;
    console.error(typedError.name, typedError.message);
  }
};
