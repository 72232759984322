export const routes = {
  home: '/',

  login: '/login',
  forgotPassword: '/forgot-password',
  signup: '/signup',
  registration: '/registration',

  library: '/library',

  account: '/account',
  emailNotFound: '/email-not-found',
  import: '/import',

  projects: '/workspace/:workspace_id',
  messages: '/messages/:hubId?/:threadId?',
  actionItems: '/action-items/:actionId?',
};

export const getRoute = (path: keyof typeof routes): string => routes[path];
