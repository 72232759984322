export const SnackErrorValue = [
  'hub-checkout-failure',
  'trial-checkout-failure',
  'pro-hobby-checkout-failure',
  'pro-free-checkout-failure',
  'hobby-free-checkout-failure',
  'hobby-pro-checkout-failure',
  'free-pro-checkout-failure',
  'free-hobby-checkout-failure',
  'cancel-failure',
] as const;

export type SnackError = (typeof SnackErrorValue)[number];

export const parseFailureReason = (s: string) => {
  if (SnackErrorValue.includes(s as any)) {
    return s as SnackError;
  }
  return undefined;
};
