import { Button } from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const AuthHeader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <a href="https://www.visualistapp.com" target="_blank">
          <img
            className={styles.logo}
            src="/assets-email/visualist@2x.png"
            alt="logo"
          />
          <img className={styles.logoMobile} src="/logo128.png" alt="logo" />
        </a>
      </div>
      <div className={styles.right}>
        <Button
          label="Help Center"
          onClick={() => {
            window.open(
              'https://www.visualistapp.com/help-center/all',
              '_blank',
            );
          }}
          type="ghost"
        />
        <Button
          label="Contact"
          onClick={() => {
            window.open('https://www.visualistapp.com/contact', '_blank');
          }}
          type="outlined"
        />
      </div>
    </div>
  );
};
