import {
  Button,
  TeamIcon,
  Tooltip,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

type Props = {
  isNotEnoughSeats: boolean;
  invite: () => void;
};

export const Invite = ({ isNotEnoughSeats, invite }: Props) => {
  return (
    <div className={styles.invite}>
      {isNotEnoughSeats ? (
        <div style={{ display: 'flex' }}>
          <Tooltip
            style={{
              minWidth: 'max-content',
              left: '41px',
            }}
            parameter={{
              type: 'plain',
              description: "You don't have enough seats",
              position: 'top',
            }}
          >
            <Button
              type="filled"
              label="Invite"
              icon={<TeamIcon />}
              isDisabled={isNotEnoughSeats}
              onClick={() => {}}
            />
          </Tooltip>
        </div>
      ) : (
        <Button
          type="filled"
          label="Invite"
          icon={<TeamIcon />}
          onClick={invite}
        />
      )}
    </div>
  );
};
