/**
 * @description Renders the initials of a user using their first and last name, fallbacking to first two letters of their email
 * @param user
 * @returns
 */

export const renderUserInitials = (user: {
  first_name: string | null;
  last_name: string | null;
  email: string;
}) => {
  if (user.first_name === null || user.last_name === null) {
    return user.email.slice(0, 2).toUpperCase();
  }
  return `${user.first_name[0]?.toUpperCase()}${user.last_name[0]?.toUpperCase()}`;
};

/**
 * @description Renders the name of a user using their first and last name, fallbacking to their email
 * @param user
 * @returns
 */

export const renderUserNameOrEmail = (user: {
  first_name: string | null;
  last_name: string | null;
  email: string;
}) => {
  if (!user.first_name || !user.last_name) {
    return user.email;
  }
  return `${user.first_name[0]?.toUpperCase() + user.first_name.slice(1)} ${
    user.last_name[0]?.toUpperCase() + user.last_name.slice(1)
  }`;
};
