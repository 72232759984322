import { ChangeEvent, useEffect, useState } from 'react';

import axios from 'axios';
import { useUnit } from 'effector-react';

import {
  Button,
  TextField,
  // Switch,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { useWindowSize } from '@visualist/hooks';

import {
  applyPromoCode,
  generateLookupKey,
  removePromoCode,
} from '@api/billing';
import {
  $isAnnualToggle,
  // changedAnnualBilling,
} from '@pages/AccountPage/models';
import { getDaySuffix } from '@pages/AccountPage/utils';
import { HUB_CREDIT_QUERY } from '@src/shared/constants/query-names';
import { useBilling } from '@src/shared/queries/useBilling';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PlanCard } from '../PlanCard';
import { Plan, PlanCardDetails } from '../types';

import styles from './styles.module.css';

const PLAN_DISPLAY_ORDER = ['pro', 'starter', 'free'] as const;

export const PlanSection = () => {
  const billingData = useBilling();
  const { query, membershipQuery } = billingData || {};
  const queryClient = useQueryClient();
  const data = query?.data;
  const membershipData = membershipQuery?.data;
  const { width } = useWindowSize();
  const isMid = width > 768 && width < 1280;
  const isLarge = width > 1280;
  const isAnnualToggle = useUnit($isAnnualToggle);
  const [promoCode, setPromoCode] = useState('');
  const [error, setError] = useState('');
  const [applyPromoBtnDisabled, setApplyPromoBtnDisabled] = useState(true);

  const { mutate: removePromoCodeMutation } = useMutation({
    mutationFn: (promoCode: string) => removePromoCode(promoCode),
    onSuccess: () => {},
    onError: () => {},
  });

  const { mutate: applyPromoCodeMutation } = useMutation({
    mutationFn: (promoCode: string) => applyPromoCode(promoCode),
    onSuccess: (response) => {
      const status = response.state?.status;
      if (status == 'promotion_applied_and_redeemed') {
        setPromoCode('');
        startedSnack({
          label: `You've now redeemed your promo code.`,
        });
        queryClient.invalidateQueries({ queryKey: [HUB_CREDIT_QUERY] });
      } else {
        startedSnack({
          label: 'Applying your code...',
        });
      }
      setError('');
      setApplyPromoBtnDisabled(true);
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.state.status == 'promo_no_longer_active') {
          setError('Sorry, the code has expired');
        } else if (error.response?.data.state.status == 'same_promo') {
          setError('');
          startedSnack({
            label: 'Applying your code...',
          });
          setApplyPromoBtnDisabled(true);
        } else {
          setError('That didn’t work. Please check your code');
        }
      } else {
        setError('Something went wrong');
      }
    },
  });

  useEffect(() => {
    if (
      data &&
      data.currently_applied_promotion.active &&
      data.currently_applied_promotion.promotion_code != 'visualist_pro_trial'
    ) {
      setPromoCode(data.currently_applied_promotion.promotion_code);
      setError(
        data.currently_applied_promotion.date_valid_until &&
          new Date(data.currently_applied_promotion.date_valid_until) <
            new Date()
          ? 'Sorry, the code has expired'
          : '',
      );
    }
  }, [data]);

  // const toggleAnnualBilling = () => changedAnnualBilling();
  if (!data || !membershipData) return null;

  const plan = data.membershipTierInfo.plan;

  const filterPlan = (p: Plan) => {
    if (plan === 'trial' && p === 'pro') return false;
    return !(p === plan);
  };

  const selectedPlanLookupKey = generateLookupKey(
    plan,
    data.membershipTierInfo.isAnnual,
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value);
    if (e.target.value.length > 0) {
      setApplyPromoBtnDisabled(false);
    } else {
      setApplyPromoBtnDisabled(true);
      setError('');
    }
  };

  const clear = () => {
    removePromoCodeMutation(promoCode);
    setPromoCode('');
  };

  const applyPromocodeSubmit = () => {
    applyPromoCodeMutation(promoCode);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.currentPlanContainer}>
          <TypographyPoppins type="headline" size="L">
            <span className={styles.planLabel}>My plan</span>
          </TypographyPoppins>
          {data.membershipTierInfo.plan !== 'free' ? (
            <TypographyPoppins type="body" size="M">
              <span className={styles.planSubLabel}>
                Cycle starts on the{' '}
                {data.membershipTierInfo.planEndDate.getDate() +
                  getDaySuffix(data.membershipTierInfo.planEndDate.getDate())}
              </span>
            </TypographyPoppins>
          ) : null}
          <PlanCard
            isCurrentPlan
            currentPlan={plan}
            isUserAnnual={data.membershipTierInfo.isAnnual}
            planCardDetails={PLANS[plan]}
            isAnnualToggle={data.membershipTierInfo.isAnnual}
            useUserAnnual
            isDisabled={data.membershipTierInfo.plan === 'trial'}
            fetchedPlanCardDetails={
              membershipQuery.data.availablePlans[selectedPlanLookupKey]
            }
          />
          <div className={styles.promoWrapper}>
            <TypographyPoppins
              type="body"
              bodySize="S"
              className={styles.promoLabeL}
            >
              Have a promo or referral code?
            </TypographyPoppins>
            <div className={styles.row}>
              <div>
                <TextField
                  placeholder="Offer code"
                  value={promoCode}
                  onChange={onChange}
                  errorAndSupportingText={error}
                  clear={clear}
                />
              </div>
              <div>
                <Button
                  label="Apply"
                  type="ghost"
                  isDisabled={applyPromoBtnDisabled}
                  onClick={applyPromocodeSubmit}
                  className={styles.applyPromoBtn}
                />
                {
                  /* This is added to keep the button alignment */ error && (
                    <TypographyPoppins
                      type="body"
                      bodySize="S"
                      style={{ paddingTop: '7px' }}
                    >
                      &nbsp;
                    </TypographyPoppins>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div className={styles.otherPlansContainer}>
          <div className={styles.otherPlansHeader}>
            <TypographyPoppins type="headline" size="L">
              <span className={styles.planLabel}>Other plans</span>
            </TypographyPoppins>
            {isMid ? <DetailedComparisonButton /> : null}
          </div>
          {
            /* This is to keep the card aligned */ data.membershipTierInfo
              .plan !== 'free' ? (
              <TypographyPoppins type="body" size="M">
                <span className={styles.planSubLabel}>&nbsp;</span>
              </TypographyPoppins>
            ) : null
          }
          <div className={styles.otherPlansList}>
            {PLAN_DISPLAY_ORDER.filter(filterPlan).map((p) => {
              const lookupKey = generateLookupKey(p, isAnnualToggle);
              return (
                <PlanCard
                  key={p}
                  isCurrentPlan={false}
                  currentPlan={plan}
                  fetchedPlanCardDetails={
                    membershipQuery.data.availablePlans[lookupKey]
                  }
                  planCardDetails={PLANS[p]}
                  isAnnualToggle={isAnnualToggle}
                  isDisabled={data.membershipTierInfo.plan === 'trial'}
                />
              );
            })}
          </div>
          <div className={styles.detailedComparisonContainerLarge}>
            {isLarge ? <DetailedComparisonButton /> : null}
          </div>
        </div>
      </div>
    </>
  );
};

const DetailedComparisonButton = () => {
  return (
    <Button
      type="ghost"
      label="See a detailed comparison"
      onClick={() => window.open('https://www.visualistapp.com/pricing')}
    />
  );
};

const PLANS: Record<Plan, PlanCardDetails> = {
  trial: {
    title: 'pro',
    subTitle: 'Best for working with clients',
    cost: {
      monthly: 30,
      annual: 26,
    },
    costDuration: 'per month',
    features: [
      'Create unlimited client hubs',
      'Set permissions for guests',
      'Get unlimited AI assistance',
    ],
    buttonAction: {
      title: 'Upgrade to work in hubs',
    },
  },
  free: {
    title: 'free',
    subTitle: 'Best for staying inspired',
    cost: {
      monthly: 0,
      annual: 0,
    },
    costDuration: 'per month',
    features: [
      'Store up to 1000 files',
      'Get AI assistance 30x a day',
      'Share files with others',
    ],
    buttonAction: {
      title: 'Switch to Free',
    },
  },
  starter: {
    title: 'starter',
    subTitle: 'Best for solopreneurs and passion projects',
    cost: {
      monthly: 4,
      annual: 3,
    },
    costDuration: 'per month',
    features: [
      'Store unlimited files',
      'Create docs in your workflow',
      'Get AI assistance 90x a day',
    ],
    buttonAction: {
      title: 'Switch to Starter',
    },
  },
  pro: {
    title: 'pro',
    subTitle: 'Best for working with clients',
    cost: {
      monthly: 30,
      annual: 26,
    },
    costDuration: 'per month',
    features: [
      'Create unlimited client hubs',
      'Set permissions for guests',
      'Get unlimited AI assistance',
    ],
    buttonAction: {
      title: 'Upgrade to work in hubs',
    },
  },
};
