import {
  BringToFrontIcon,
  SegmentedButton,
  SendToBackIcon,
  Tooltip,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { ImageType } from '@pages/StudioPage/api';
import { useImages } from '@pages/StudioPage/hooks/useImages';
import { useLayer } from '@pages/StudioPage/hooks/useLayer';
import { useAppData } from '@src/AppContext';

import styles from './styles.module.css';

type ImageToolbarProps = {
  designId: string;
  imageId: string;
  imageType: ImageType;
  canShowOnboardingTip: boolean;
};

export const ImageToolbar = ({
  designId,
  imageId,
  imageType,
  canShowOnboardingTip,
}: ImageToolbarProps) => {
  const { mutateLayer } = useLayer();
  const { imageDeleteMutation, imageBackgroundRemove } = useImages({
    designId,
  });
  const { user } = useAppData();
  const isDemoBannerVisible = user.meta?.onboarding?.demoMode;

  const removeBackground = () => {
    if (imageBackgroundRemove.isPending) return;

    imageBackgroundRemove.mutate(imageId);
  };

  const bringToFront = () =>
    mutateLayer.mutate({ designId, imageId, typeOfLayerAction: 'forward' });

  const sendToBack = () =>
    mutateLayer.mutate({ designId, imageId, typeOfLayerAction: 'backward' });

  const deleteImage = () => imageDeleteMutation.mutate([imageId]);

  // const lockImage = () =>
  //   imageUpdateMutation.mutate({
  //     imageId,
  //     positionX: x,
  //     positionY: y,
  //     positionHeight: height,
  //     positionWidth: width,
  //     positionOmega: rotation,
  //     positionLock: true,
  //     shouldInvalidate: true,
  //   });

  const showOnboardingDot =
    user.meta?.onboarding?.workCreatively === 'moodboards-that-pop' &&
    canShowOnboardingTip;

  return (
    <div className={styles.container} data-demo-banner={isDemoBannerVisible}>
      <div className={styles.imageToolbar}>
        {showOnboardingDot ? (
          <div
            className={styles.demoIndicator}
            onClick={removeBackground}
          ></div>
        ) : null}
        <Tooltip
          style={{
            minWidth: 'max-content',
          }}
          parameter={{
            type: 'plain',
            description:
              imageType === 'Background remove'
                ? 'Add background back'
                : 'Remove background',
            position: 'bottom',
          }}
        >
          <SegmentedButton
            start
            buttonStyle={styles.button}
            icon={
              imageType === 'Background remove' ? (
                <Icon name="sprite/add-background" />
              ) : (
                <Icon name="sprite/remove-background" />
              )
            }
            onClick={removeBackground}
          />
        </Tooltip>
        <div className={styles.divider}></div>
        <Tooltip
          style={{
            minWidth: 'max-content',
          }}
          parameter={{
            type: 'plain',
            description: 'Bring to front',
            position: 'bottom',
          }}
        >
          <SegmentedButton
            buttonStyle={styles.button}
            icon={
              <BringToFrontIcon
                className={styles.bringToFrontIcon}
                height={24}
                width={24}
              />
            }
            onClick={bringToFront}
          />
        </Tooltip>
        <Tooltip
          style={{
            minWidth: 'max-content',
          }}
          parameter={{
            type: 'plain',
            description: 'Send to back',
            position: 'bottom',
          }}
        >
          <SegmentedButton
            buttonStyle={styles.button}
            icon={<SendToBackIcon height={24} width={24} />}
            onClick={sendToBack}
          />
        </Tooltip>
        <div className={styles.divider}></div>
        <Tooltip
          style={{
            minWidth: 'auto',
          }}
          parameter={{
            type: 'plain',
            description: 'Delete',
            position: 'bottom',
          }}
        >
          <SegmentedButton
            end
            buttonStyle={styles.button}
            icon={<Icon name="sprite/bin" size={24} />}
            onClick={deleteImage}
          />
        </Tooltip>
      </div>
    </div>
  );
};
