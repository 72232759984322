/**
 * @description function to split sentence into three parts, returns first part, matched middle part and last part
 *
 * @param sentence
 * @param word
 */

export const splitSentence = (
  s: string,
  startIndex: number,
  endIndex: number,
) => {
  if (startIndex === -1 || endIndex === -1) {
    throw Error('Invalid start or end index');
  }

  if (startIndex > endIndex) {
    throw Error('Start index cannot be greater than end index');
  }

  if (startIndex < 0 || endIndex > s.length) {
    throw Error('Invalid start or end index');
  }

  const firstHalf = s.substring(0, startIndex);
  const secondHalf = s.substring(endIndex);

  const word = s.substring(startIndex, endIndex);

  return [firstHalf, word, secondHalf];
};

/**
 * @description function to find the index of the first occurrence of a word in a sentence
 *
 * @param sentence
 * @param word
 * @returns
 */

export const getIndicesOfMatchingWord = (sentence: string, word: string) => {
  const lowerW = word.toLowerCase();
  const lowerSentence = sentence.toLowerCase();

  const startIndex = lowerSentence.indexOf(lowerW);
  const endIndex = startIndex + word.length;

  return [startIndex, endIndex];
};

/**
 * @description function to find the index of the exact match of a word in a sentence
 *
 * @param sentence
 * @param word
 * @returns
 */

export const getIndicesOfExactMatchingWord = (
  sentence: string,
  word: string,
) => {
  const lowerW = word.toLowerCase();
  const lowerSentence = sentence.toLowerCase();

  // Splits into words
  const splitSentence = lowerSentence.split(' ');
  const wordIdx = splitSentence.findIndex((w) => w === lowerW);

  if (wordIdx === -1) {
    return [-1, -1];
  }

  const firstHalf = splitSentence.slice(0, wordIdx).join(' ');

  // Account for the space at the end of the first half
  const startIndex =
    firstHalf.length === 0 ? firstHalf.length : firstHalf.length + 1;
  const endIndex = startIndex + lowerW.length;

  return [startIndex, endIndex];
};
