import { useRef } from 'react';

import { Avatar, Dropdown } from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { saveProfile } from '@api/account';
import { useAppData } from '@src/AppContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import styles from './styles.module.css';

export const AccountAvatar = () => {
  const { user } = useAppData();
  const ref = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    // @ts-expect-error
    mutationFn: (payload: FormData) => saveProfile(payload),
    onSuccess: (response) => {
      queryClient.setQueryData(['my-user'], response);
      queryClient.invalidateQueries({ queryKey: ['my-user'] });
      localStorage.setItem('v_user', JSON.stringify(response));
    },
    onError: () => {
      startedSnack({
        label: "Couldn't update picture.",
        action: {
          label: 'Try again',
          action: () => handleUpload(),
        },
        close: true,
      });
    },
  });

  const handleUpload = () => {
    if (ref && ref.current)
      ref.current.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }),
      );
  };

  const handleFile = (e: any) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const image = e && e.target && e.target.result;
      if (image) {
        const formData = new FormData();
        formData.append(
          'photo',
          new Blob([image], { type: 'application/octet-stream' }),
          'avatar.jpg',
        );
        //@ts-ignore
        mutate(formData);
      }
    };
    //@ts-ignore
    reader.readAsArrayBuffer(e.target?.files[0]);
  };

  return (
    <Dropdown>
      <Dropdown.Menu
        side="bottom"
        density="-2"
        align="start"
        sideOffset={-8}
        trigger={
          <>
            <Avatar
              size={80}
              initials={`${user.first_name ?? ''} ${user.last_name ?? ''}`}
              email={user.email}
              image={user.photo?.medium_square_crop}
            />
            <input
              ref={ref}
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleFile}
              className={styles.inputFile}
            />
          </>
        }
      >
        <Dropdown.MenuItem
          item={{
            content: 'Remove avatar',
            onClick: () => {
              const formData = new FormData();
              formData.append('photo', '');
              mutate(formData);
            },
          }}
        />
        <Dropdown.MenuItem
          item={{
            content: 'Change avatar',
            onClick: handleUpload,
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};
