import { motion } from 'framer-motion';

import { UserIcon, UserType } from '@visualist/design-system';
import {
  Badge,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { getDateCommaSeparatedYear } from '@src/shared/utils/data';

import styles from './styles.module.css';

type Props = {
  createdBy: UserType;
  createdAt: string;
  expanded?: boolean;
  size?: React.ComponentProps<typeof TypographyPoppins>['labelSize'];
  isActionable?: boolean;
  showActionableBadge?: boolean;
};

export const StickyHeader = ({
  createdBy,
  createdAt,
  expanded = false,
  size = 'XS',
  isActionable = false,
  showActionableBadge = false,
}: Props) => {
  const date = getDateCommaSeparatedYear(createdAt, 'comprehensive');

  return (
    <div className={styles.header}>
      <div className={styles.avatarContainer}>
        {createdBy.photo && createdBy.photo.small_square_crop ? (
          <img className={styles.avatar} src={createdBy.photo.full_size} />
        ) : (
          <UserIcon user={createdBy} size={20} fontSize={8} />
        )}
        <TypographyPoppins
          type="label"
          labelSize={size}
          className={styles.name}
        >
          {createdBy.first_name}
        </TypographyPoppins>
      </div>
      <div className={styles.rightSide}>
        {expanded ? (
          <TypographyPoppins
            labelSize="XS"
            type="label"
            className={styles.date}
          >
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.5,
              }}
            >
              {date}
            </motion.span>
          </TypographyPoppins>
        ) : (
          isActionable &&
          showActionableBadge && (
            <Tooltip
              parameter={{
                type: 'plain',
                description: 'Marked for action',
                position: 'right',
                centerText: true,
              }}
            >
              {/* TODO when tooltips are fixed with portals this will need fixing */}
              <div
                style={{
                  position: 'absolute',
                  top: -26,
                  right: -16,
                }}
              >
                <Badge text="" threeSizes="large" style="red-single digit" />
              </div>
            </Tooltip>
          )
        )}
      </div>
    </div>
  );
};
