type DividerType =
  | 'long-line'
  | 'short-line'
  | 'or-line'
  | 'vertical-long'
  | 'vertical-short';

import cn from 'classnames';

import TypographyPoppins from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

type Props = {
  type: DividerType;
  className?: string;
};

export const Divider = ({ type, className }: Props) => {
  if (type === 'or-line') {
    return (
      <div className={styles.orLineContainer}>
        <div className={cn(styles.line, styles.shortLine)} />
        <TypographyPoppins type="body" size="S">
          <span className={styles.or}>or</span>
        </TypographyPoppins>
        <div className={cn(styles.line, styles.shortLine)} />
      </div>
    );
  }

  if (type === 'vertical-long' || type === 'vertical-short') {
    return (
      <div
        className={cn(styles.verticalLine, className, {
          [styles.longVerticalLine]: type === 'vertical-long',
          [styles.shortVerticalLine]: type === 'vertical-short',
        })}
      />
    );
  }

  return (
    <div
      className={cn(styles.line, className, {
        [styles.longLine]: type === 'long-line',
        [styles.shortLine]: type === 'short-line',
      })}
    />
  );
};
