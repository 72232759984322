import React from 'react';

import Icon, { IconProps } from './Icon';

const ShiftIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M9.54376 12.6667V12.1667H9.04376H5.56897L12.1541 4.75291L18.7392 12.1667H15.266H14.766V12.6667V18.6667C14.766 19.1269 14.3929 19.5 13.9327 19.5H10.3771C9.91686 19.5 9.54376 19.1269 9.54376 18.6667V12.6667Z"
        stroke="currentColor"
        fill="transparent"
      />
    </Icon>
  );
};

export default ShiftIcon;
