import { useUnit } from 'effector-react';

import { Modal } from '@visualist/design-system/src/components/v2';

import { DialogContainer } from '../../ui/dialog-container';
import { $isShowBoardDialog, $step, boardDialogClosed } from '../model';
import { Step1 } from './step-1';
import { Step2 } from './step-2';

import styles from './styles.module.css';

export const BoardCreationDialog = () => {
  const isShowBoardDialog = useUnit($isShowBoardDialog);
  const step = useUnit($step);

  return (
    <Modal showModal={isShowBoardDialog} handleClose={boardDialogClosed}>
      <DialogContainer className={styles.board}>
        {step === 1 && <Step1 />}
        {step === 2 && <Step2 />}
      </DialogContainer>
    </Modal>
  );
};
