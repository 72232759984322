export type RGB = {
  r: number;
  g: number;
  b: number;
};

/**
 * Converts RGB values to HEX.
 * @param r Red value (0 - 255)
 * @param g Green value (0 - 255)
 * @param b Blue value (0 - 255)
 * @returns HEX color string
 */
export const rgbToHex = (r: number, g: number, b: number): string => {
  return (
    '#' +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
};

/**
 * Converts HSL color values to RGB.
 * @param h Hue value (0 - 360)
 * @param s Saturation value (0 - 100)
 * @param l Lightness value (0 - 100)
 * @returns An RGB object with values ranging from 0 to 255
 */
export function hslToRgb(h: number, s: number, l: number): RGB {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const hh = h / 60;
  const x = c * (1 - Math.abs((hh % 2) - 1));
  let r1 = 0,
    g1 = 0,
    b1 = 0;

  if (0 <= hh && hh < 1) {
    r1 = c;
    g1 = x;
    b1 = 0;
  } else if (1 <= hh && hh < 2) {
    r1 = x;
    g1 = c;
    b1 = 0;
  } else if (2 <= hh && hh < 3) {
    r1 = 0;
    g1 = c;
    b1 = x;
  } else if (3 <= hh && hh < 4) {
    r1 = 0;
    g1 = x;
    b1 = c;
  } else if (4 <= hh && hh < 5) {
    r1 = x;
    g1 = 0;
    b1 = c;
  } else if (5 <= hh && hh < 6) {
    r1 = c;
    g1 = 0;
    b1 = x;
  }

  const m = l - c / 2;
  const r = Math.round((r1 + m) * 255);
  const g = Math.round((g1 + m) * 255);
  const b = Math.round((b1 + m) * 255);

  return { r, g, b };
}

export type HSL = {
  h: number; // Hue in degrees [0, 360)
  s: number; // Saturation percentage [0, 100]
  l: number; // Lightness percentage [0, 100]
};

/**
 * Converts RGB color values to HSL.
 * @param r - Red value (0 - 255)
 * @param g - Green value (0 - 255)
 * @param b - Blue value (0 - 255)
 * @returns An HSL object.
 */
export function rgbToHsl(r: number, g: number, b: number): HSL {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0,
    s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;

    // Saturation calculation
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    // Hue calculation
    switch (max) {
      case r:
        h = ((g - b) / d + (g < b ? 6 : 0)) * 60;
        break;
      case g:
        h = ((b - r) / d + 2) * 60;
        break;
      case b:
        h = ((r - g) / d + 4) * 60;
        break;
    }
  }

  return {
    h: Math.round(h) % 360,
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
}

/**
 * Converts a HEX color code to its RGB components.
 * @param hex - The HEX color code (e.g., "#FF5733" or "FF5733").
 * @returns An RGB object or null if the HEX code is invalid.
 */
export function hexToRgb(hex: string): RGB | null {
  // Remove the hash symbol if present
  hex = hex.replace(/^#/, '');

  // Handle shorthand HEX codes (e.g., "FFF")
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Validate HEX code length
  if (hex.length !== 6) {
    console.warn('Invalid HEX color code.');
    return null;
  }

  // Parse the HEX code to RGB
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}
