import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { uploadImage } from '../../../api';
import { queryClient } from '../../../queryClient';
import {
  ALL_BOARD_IMAGES_QUERY,
  ALL_BOARDS_QUERY,
  BOARD_IMAGES_QUERY,
  BOARDS_OF_HUB_QUERY,
  LIBRARY_QUERY,
} from '../../constants';

export const uploadFiles = (files = [], board) => {
  if (!files.length) return;

  const promises = [];

  for (let i = 0; i < files.length; i++) {
    const formData = new FormData();
    formData.append('file', files[i]);
    if (board) {
      formData.append('board', board.id);
    }
    promises.push(uploadImage(formData));
  }

  if (promises.length === 1) {
    startedSnack({
      label: 'Uploading image...',
      close: true,
    });
  } else {
    startedSnack({
      label: 'Uploading images...',
      close: true,
    });
  }

  Promise.all(promises)
    .then((files) => {
      const ids = [];
      files.forEach((file) => {
        ids.push(file.data.id);
      });

      queryClient.invalidateQueries([LIBRARY_QUERY]);

      if (board) {
        queryClient.invalidateQueries([BOARD_IMAGES_QUERY]);
        queryClient.invalidateQueries([ALL_BOARDS_QUERY]);
        queryClient.invalidateQueries([ALL_BOARD_IMAGES_QUERY]);
        queryClient.invalidateQueries([
          BOARDS_OF_HUB_QUERY,
          { hub: board.hub?.id },
        ]);
      }

      if (files.length === 1) {
        startedSnack({
          label: 'Uploaded image',
          close: true,
        });
      } else {
        startedSnack({
          label: 'Uploaded images',
          close: true,
        });
      }
    })
    .catch(() => {
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('file', files[i]);
        if (board) {
          formData.append('board', board.id);
        }

        if (files.length === 1) {
          startedSnack({
            label: "Couldn't upload image",
            action: {
              label: 'Try again',
              action: () => uploadImage(formData),
            },
            close: true,
          });
        } else {
          startedSnack({
            label: "Couldn't upload images",
            action: {
              label: 'Try again',
              action: () => uploadImage(formData),
            },
            close: true,
          });
        }
      }
    });
};
