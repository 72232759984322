import { createEvent, createStore } from 'effector';
import { hotkey } from 'effector-hotkey';

export const fileIdSelected = createEvent<string[]>();
export const fileIdCleared = createEvent();

export const $fileId = createStore<string[] | null>(null);

export const fileTypeSelected = createEvent<string>();
export const fileTypeCleared = createEvent();

export const $fileType = createStore<string>('');

export const fileDeleteDialogOpened = createEvent();
export const fileDeleteDialogClosed = createEvent();

export const $isFileDeleteDialogOpen = createStore(false);

$fileId.on(fileIdSelected, (_, id) => id).reset(fileIdCleared);

$fileType.on(fileTypeSelected, (_, type) => type).reset(fileTypeCleared);

$isFileDeleteDialogOpen
  .on(fileDeleteDialogOpened, () => true)
  .reset(fileDeleteDialogClosed);

hotkey({
  key: 'Escape',
  filter: $isFileDeleteDialogOpen,
  target: fileDeleteDialogClosed,
});
