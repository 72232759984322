import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

type Props = {
  input: string;
};

export const useGoogleAutocomplete = ({ input }: Props) => {
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: import.meta.env.DEV
      ? import.meta.env.VITE_GOOGLE_API_KEY_DEV ?? ''
      : import.meta.env.VITE_GOOGLE_API_KEY,
    options: {
      input,
      types: ['(cities)'],
    },
  });

  return { placePredictions, getPlacePredictions };
};
