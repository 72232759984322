import { Icon, IconProps } from './Icon';

export const CheckboxRound = ({ stroke = 'black', ...props }: IconProps) => {
  return (
    <Icon {...props}>
      <mask
        id="mask0_15898_536"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path
          d="M18 3.99999L15.5 6.49999L19 9.99999L21 7.5C24 13.5 19 22 12 22C3.59997 21.6 0.49999 12.5 3.49997 6.99998C8.29997 0.599977 15.1667 2.33333 18 3.99999Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_15898_536)">
        <circle cx="12" cy="12" r="8.5" stroke={stroke} />
      </g>
      <path d="M7 12.6667L10.6923 16L19 6" stroke={stroke} />
    </Icon>
  );
};
