import React from 'react';

import Icon, { IconProps } from './Icon';

const AddIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <Icon {...props} fill="none">
      <g filter="url(#filter0_d_1633_24742)">
        <path
          d="M4.625 2.7524L20 16.3923L12.875 17.0418L8.75 22.8875L4.625 2.7524Z"
          fill="#1B0F0F"
        />
        <path
          d="M8.87893 22.2712L5.02055 3.43751L19.4018 16.1958L12.8523 16.7929L12.7373 16.8033L12.6707 16.8977L8.87893 22.2712Z"
          stroke="white"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1633_24742"
          x="2.625"
          y="0.752441"
          width="19.375"
          height="24.135"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1633_24742"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1633_24742"
            result="shape"
          />
        </filter>
      </defs>
    </Icon>
  );
};
export default AddIcon;
