import cn from 'classnames';

import {
  Button,
  FilterChip,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { Title } from '@src/entities/dialogs/ui/title';

import { boardDialogClosed, stepContinue } from '../../model';
import { Search } from '../search';
import { useSearch } from '../search/useSearch';

import styles from './styles.module.css';

export const Step1 = () => {
  const {
    ref,
    location,
    width,
    getLeadingIcon,
    getTrailingIcon,
    clear,
    isShowTooltip,
  } = useSearch();

  return (
    <>
      <div className={styles.progress}>
        <button className={styles.current}></button>
        <button
          className={styles.continue}
          onClick={() => stepContinue()}
        ></button>
      </div>
      <Title
        className={styles.title}
        title="Create a board"
        description="Use boards to organize your documents, images, designs and palettes."
      />
      <div className={styles.location}>
        <div className={styles.selection}>
          {location && (
            <TypographyPoppins className={styles.nest} type="body" bodySize="S">
              Nest board in
            </TypographyPoppins>
          )}
          <Search>
            {isShowTooltip() ? (
              <Tooltip
                parameter={{
                  description: (
                    <Breadcrumbs board={location && location.parent} />
                  ),
                  type: 'plain',
                  position: 'top',
                }}
                style={{
                  maxWidth: '230px',
                  width: 'max-content',
                  minWidth: 'min-content',
                }}
              >
                <FilterChip
                  ref={ref}
                  className={cn({
                    [styles.filterChip]: location !== null,
                  })}
                  classNameLabel={cn({
                    [styles.blur]: width >= 302,
                  })}
                  type="label"
                  labelSize="L"
                  leadingIcon={getLeadingIcon()}
                  trailingIcon={getTrailingIcon()}
                  trailingAction={location ? clear : undefined}
                  isSelected={location !== null}
                >
                  {location ? location.name : 'Choose a location (optional)'}
                </FilterChip>
              </Tooltip>
            ) : (
              <FilterChip
                ref={ref}
                className={cn({
                  [styles.filterChip]: location !== null,
                })}
                classNameLabel={cn({
                  [styles.blur]: width >= 302,
                })}
                type="label"
                labelSize="L"
                leadingIcon={getLeadingIcon()}
                trailingIcon={getTrailingIcon()}
                trailingAction={location ? clear : undefined}
                isSelected={location !== null}
              >
                {location ? location.name : 'Choose a location (optional)'}
              </FilterChip>
            )}
          </Search>
        </div>
        <TypographyPoppins className={styles.tip} type="body" bodySize="S">
          Don’t worry, you can always move it later.
        </TypographyPoppins>
      </div>
      <div className={styles.actions}>
        <Button
          type="outlined"
          label="Cancel"
          onClick={() => boardDialogClosed()}
        />
        <Button type="filled" label="Continue" onClick={() => stepContinue()} />
      </div>
    </>
  );
};
