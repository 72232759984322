import React from 'react';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { UpgradeDowngradeSheet } from '@pages/AccountPage/components/PlanSheet';
import { Plan } from '@pages/AccountPage/components/types';

import styles from './styles.module.css';

type Props = {
  currentPlan: Plan;
  isLocked: boolean;
  isOwner: boolean;
};

export const LockedView = ({ currentPlan, isLocked, isOwner }: Props) => {
  const [showSheet, setShowSheet] = React.useState(false);

  const openSheet = () => {
    setShowSheet(true);
  };

  if (!isLocked) return null;

  if (!isOwner) {
    return (
      <div className={styles.lockedContainer}>
        <div className={styles.scrim} />
        <div className={styles.modal}>
          <TypographyPoppins
            type="headline"
            headlineSize="S"
            className={styles.title}
          >
            This hub is locked
          </TypographyPoppins>
          <TypographyPoppins type="body" bodySize="M" className={styles.body}>
            Contact the admin to unlock the hub.
          </TypographyPoppins>
        </div>
      </div>
    );
  }

  if (currentPlan === 'pro' || currentPlan === 'trial') {
    // Checks in place for TS else it complains since this is an impossible state.
    console.error(
      'User is in a state where they are pro or trial and their hub (i.e. they are the owner) is locked',
    );
    return null;
  }

  return (
    <>
      <UpgradeDowngradeSheet
        isAnnualToggle={false}
        showSheet={showSheet}
        closeModal={() => setShowSheet(false)}
        planModalState={{
          isPlanModalOpen: false,
          mode: 'upgrade-downgrade',
          planModalMode: `${currentPlan}-pro`,
          planToChange: 'pro',
        }}
      />
      <div className={styles.lockedContainer}>
        <div className={styles.scrim} />
        <div className={styles.modal}>
          <TypographyPoppins
            type="headline"
            headlineSize="S"
            className={styles.title}
          >
            This hub is locked
          </TypographyPoppins>
          <TypographyPoppins type="body" bodySize="M" className={styles.body}>
            Upgrade to Pro to unlock your hub <br /> and start collaborating
            again.
          </TypographyPoppins>
          <Button
            buttonStyle={{
              margin: '8px 24px 8px 24px',
            }}
            label="Switch to Pro"
            type="filled"
            onClick={openSheet}
          />
        </div>
      </div>
    </>
  );
};
