import { useEffect, useRef } from 'react';

// @ts-expect-error
import confetti from 'canvas-confetti';
import { useUnit } from 'effector-react';

import {
  Button,
  Modal,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { useAppData } from '@src/AppContext';

import {
  $showDemoCompletion,
  hidingDemoCompletion,
  showingProTrial,
} from '../../model';

import styles from './styles.module.css';

const colors = [
  '#E2C9EB',
  '#006E2E',
  '#FFB86A',
  '#FFE2B5',
  '#B3D4C1',
  '#53006E',
];

export const DemoCompletionScreen = () => {
  const { user } = useAppData();

  const [showDemoCompletion] = useUnit([$showDemoCompletion]);
  const modalRef = useRef<HTMLDialogElement>(null);

  const handleCloseModal = async () => {
    showingProTrial();
    hidingDemoCompletion();
  };

  useEffect(() => {
    if (!showDemoCompletion) return;
    const end = Date.now() + 3000;

    const frame = () => {
      if (Date.now() > end) return;

      confetti({
        particleCount: 4,
        angle: 60,
        spread: 55,
        startVelocity: 60,
        origin: { x: 0, y: 0.5 },
        colors: colors,
        zIndex: 1000000,
      });

      confetti({
        particleCount: 4,
        angle: 120,
        spread: 55,
        startVelocity: 60,
        origin: { x: 1, y: 0.5 },
        colors: colors,
        zIndex: 1000000,
      });

      return requestAnimationFrame(frame);
    };

    const frameNumber = frame();

    return () => {
      if (frameNumber) cancelAnimationFrame(frameNumber);
    };
  }, [showDemoCompletion]);

  return (
    <Modal
      ref={modalRef}
      showModal={showDemoCompletion}
      handleClose={handleCloseModal}
      className={styles.welcomeModal}
    >
      <TypographyPoppins
        type="headline"
        headlineSize="L"
        className={styles.headline}
      >
        {user.first_name === null ? 'Hey,' : `Hey ${user.first_name},`}
      </TypographyPoppins>
      <TypographyPoppins
        type="body"
        bodySize="M"
        className={styles.welcomeBody}
      >
        That’s all we have for now.
        <br />
        <br />
        All of this demo content is available to you, so keep going on your own!
      </TypographyPoppins>
      <Button
        type="filled"
        label="Continue exploring"
        onClick={handleCloseModal}
        className={styles.welcomeButton}
      />
    </Modal>
  );
};
