import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { BoardLvl1, BoardLvl2, BoardLvl3 } from '@api/designs';
import { api, GenericBlockResponse, ImageTypes, Studio } from '@api/services';

export type ImageType = 'Image' | 'Background remove' | 'Colour palette';

export type ImageBlock = {
  id: string;
  name: string;
  file: ImageTypes;
  created_at: Date;
  block_type: ImageType;
  height: number;
  width: number;
  studio: Studio;
  is_removed: boolean;
  boards: Array<BoardLvl1 | BoardLvl2 | BoardLvl3> | null;
};

export type ImageBlockResponse = GenericBlockResponse & {
  results: ImageBlock[];
};

export type ImageGenericBlockResponse = GenericBlockResponse & {
  results: ImageBlock[];
};

export const getBlocks = async ({
  ordering,
  offset,
}: {
  ordering?: 'created_at' | '-created_at' | 'use_count' | '-use_count';
  offset?: number;
}): Promise<AxiosResponse<ImageGenericBlockResponse>> => {
  return api.get('/blocks/', {
    params: {
      ordering,
      offset,
      // block_type: 0,
    },
  });
};

// Images
export const getImages = (
  designId: string,
): Promise<AxiosResponse<ImageGenericBlockResponse>> => {
  return api.get(`/blocks/set/${designId}/blocks/`);
};

type UpdateImageData = {
  imageId: string;
  designId: string;
  positionX: number;
  positionY: number;
  positionWidth: number;
  positionHeight: number;
  positionLock: boolean;
  positionOmega: number;
};

// Remove image id from UpdateImageData
export type UpdateImageDataWithoutDesignId = Omit<UpdateImageData, 'designId'>;

// Update Image
export const updateImage = ({
  imageId,
  designId,
  positionX,
  positionY,
  positionWidth,
  positionHeight,
  positionOmega,
  positionLock,
}: UpdateImageData) => {
  return api.post(`blocks/${imageId}/position/`, {
    set: designId,
    position_x: positionX,
    position_y: positionY,
    position_width: positionWidth,
    position_height: positionHeight,
    position_omega: positionOmega,
    position_lock: positionLock,
  });
};

export type ImageResponse = {
  id: string;
  file: ImageSizes;
  tags: any[];
  colors: any[];
  boards: any[];
  hubs: any[];
  created_at: Date;
  position_x: number;
  position_y: number;
};

export type ExistingImageResponse = {
  created_at: string;
  file: ImageSizes;
  height: number;
  width: number;
  id: string;
  name: string | null;
  studio: Position;
};

type Position = {
  position_x: number;
  position_y: number;
  position_omega: number;
  position_width: number;
  position_height: number;
  position_lock: boolean;
};

export type File = {
  file: string;
  position: Position;
};

type ImageSizes = {
  thumbnail_640: string;
  thumbnail_130: string;
  thumbnail_160: string;
  full_size: string;
  thumbnail_100: string;
  thumbnail_330: string;
  small_square_crop: string;
  thumbnail_400: string;
};

// Upload Image
export const uploadImage = (
  payload: any,
): Promise<AxiosResponse<ImageResponse>> => {
  // Pass is_hidden_from_library in the payload to hide from library
  const options: AxiosRequestConfig = {
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  return api.post(`/blocks/image/`, payload, options);
};

// Upload images from library
export const uploadExistingImages = ({
  id,
  files,
}: {
  id: string;
  files: File[];
}): Promise<AxiosResponse<ExistingImageResponse[]>> => {
  return api.post(`/blocks/set/${id}/add_blocks/`, { files });
};

// Crop Image
export const cropImage = ({
  id,
  payload,
}: {
  id: string;
  payload: Record<'top' | 'left' | 'right' | 'bottom' | 'set', number | string>;
}) => {
  return api.post(`/blocks/${id}/crop/`, payload);
};

// Delete Image
export const deleteImage = (
  designId: string,
  ids: string[],
): Promise<AxiosResponse<unknown>> => {
  return api.post(`/blocks/set/${designId}/delete_blocks/`, { blocks: ids });
};

// Image Layers
type Layers = { block: string; layer: number }[];

export const setLayer = ({
  setId,
  layers,
}: {
  setId: string;
  layers: Layers;
}): Promise<AxiosResponse<any>> => {
  return api.post(`/blocks/set/${setId}/layer/`, {
    layers,
  });
};

// Remove Image background
export const removeBackground = async (
  id: string,
): Promise<AxiosResponse<ImageResponse>> => {
  return api.post(`/blocks/remove_background/`, { block: id });
};

// Text Boxes
export type TextBox = {
  id: string;
  created_by: CreatedBy;
  text: string;
  text_format: TextFormat;
  width: number;
  position_x: number;
  position_y: number;
  layer: number;
  created_at: Date;
  set_block: string;
  block: null;
};

export type TempTextBox = Omit<TextBox, 'created_by' | 'created_at' | 'block'>;

export type CreatedBy = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  photo: Photo;
};

export type Photo = {
  thumbnail: string;
  medium_square_crop: string;
  small_square_crop: string;
  full_size: string;
};

export type Alignment = 'left' | 'center' | 'right';

export type TextFormat = {
  // 1 is for fontSize and 2 is for alignment. This is essentially metadata for text
  additionalProp1?: number;
  alignment?: Alignment;
  bold?: boolean;
  underline?: boolean;
  italic?: boolean;
  colour?: string;
  rotation?: number;
};

export type TextBoxResponse = GenericBlockResponse & {
  results: TextBox[];
};

export const getTextBoxes = (
  designId: string,
): Promise<AxiosResponse<TextBoxResponse>> => {
  return api.get(`/text-boxes/`, {
    params: {
      set_block: designId,
    },
  });
};

type UpdateTextBoxData = {
  textboxId: string;
  positionX: number;
  positionY: number;
  fontSize: number;
  text: string;
  width: number;
} & TextFormat;

export const updateTextBox = ({
  textboxId,
  positionX,
  positionY,
  fontSize,
  text,
  width = 0,
  alignment = 'left',
  bold = false,
  underline = false,
  italic = false,
  colour = '#000000',
  rotation = 0,
}: UpdateTextBoxData) => {
  return api.patch(`text-boxes/${textboxId}/`, {
    text: text,
    position_x: Math.round(positionX),
    position_y: Math.round(positionY),
    text_format: {
      additionalProp1: fontSize,
      alignment,
      bold,
      underline,
      italic,
      colour,
      rotation,
    },
    width: Math.round(width),
  });
};

export type NewTextBoxData = {
  text: string;
  positionX: number;
  positionY: number;
  width: number;
  layer: number;
  designId: string;
  fontSize: number;
  alignment?: Alignment;
  bold?: boolean;
  underline?: boolean;
  italic?: boolean;
  colour?: string;
  rotation?: number;
};

export const createTextbox = async ({
  text,
  positionX,
  positionY,
  layer,
  designId,
  width,
  fontSize,
  alignment = 'left',
  bold = false,
  italic = false,
  underline = false,
  rotation = 0,
  colour = '#000000',
}: NewTextBoxData): Promise<AxiosResponse<{ id: string }>> => {
  return api.post('/text-boxes/', {
    layer,
    text: text,
    position_x: Math.round(positionX),
    position_y: Math.round(positionY),
    text_format: {
      additionalProp1: fontSize,
      alignment,
      bold,
      underline,
      italic,
      colour,
      rotation,
    },
    width: Math.round(width),
    set_block: designId,
  });
};

export const deleteTextbox = async (
  id: string,
): Promise<AxiosResponse<{ id: string }>> => {
  return api.delete(`/text-boxes/${id}/`);
};
