import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { actions, NOTES_REPLIES_QUERY } from '@src/shared/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  createStickyAction as createStickyActionApi,
  deleteStickyAction as deleteStickyActionApi,
  undoDeleteStickyAction as undoDeleteStickyActionApi,
  updateStickyAction as updateStickyActionApi,
} from './api';

export const useStickyReplyAction = (stickyId: string, actionId?: string) => {
  const queryClient = useQueryClient();

  const createStickyReplyAction = useMutation({
    mutationFn: createStickyActionApi,
    onMutate: async () => {
      // Magically update the sticky to be the actionable one
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_REPLIES_QUERY, { stickyId }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
    },
    onError: () => {
      startedSnack({
        label: "Couldn't create sticky action",
        close: true,
      });
    },
  });

  const updateStickyReplyAction = useMutation({
    mutationFn: updateStickyActionApi,
    onMutate: async () => {
      // Magically update the sticky to be the actionable one
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_REPLIES_QUERY, { stickyId }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
    },
  });

  const deleteStickyReplyAction = useMutation({
    mutationFn: deleteStickyActionApi,
    onMutate: async () => {
      // Magically update the sticky to be the actionable one
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_REPLIES_QUERY, { stickyId }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
      if (actionId) {
        startedSnack({
          label: 'Unmarked action',
          action: {
            label: 'Undo',
            action: () => {
              undoDeleteStickyReplyAction.mutate(actionId);
            },
          },
          close: true,
        });
      }
    },
  });

  const undoDeleteStickyReplyAction = useMutation({
    mutationFn: undoDeleteStickyActionApi,
    onMutate: async () => {
      // Magically update the sticky to be the actionable one
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_REPLIES_QUERY, { stickyId }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
    },
  });

  return {
    createStickyReplyAction,
    updateStickyReplyAction,
    deleteStickyReplyAction,
    undoDeleteStickyReplyAction,
  };
};
