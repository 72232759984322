import React, { ReactNode, useCallback, useMemo } from 'react';

import { Icon } from '@visualist/icons';

import { EditorState } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import { Editor } from '@tiptap/react';

import { OptionsMenu } from './options';
import { isRowGripSelected } from './utils';

export const TableRowMenu = React.memo(
  ({ editor, appendTo }: { editor: Editor; appendTo: any }): JSX.Element => {
    const shouldShow = useCallback(
      ({
        view,
        state,
        from,
      }: {
        view: EditorView;
        state?: EditorState;
        from?: number;
      }) => {
        if (!state) {
          return false;
        }

        return isRowGripSelected({ editor, view, state, from: from || 0 });
      },
      [editor],
    );

    const onAddRowBefore = useCallback(() => {
      editor.chain().focus().addRowBefore().run();
    }, [editor]);

    const onAddRowAfter = useCallback(() => {
      editor.chain().focus().addRowAfter().run();
    }, [editor]);

    const onDeleteRow = useCallback(() => {
      editor.chain().focus().deleteRow().run();
    }, [editor]);

    const menuItems = useMemo(
      () => [
        {
          icon: (<Icon name="sprite/doc-table-add" />) as ReactNode,
          tooltipText: 'Add row above',
          onClick: onAddRowBefore,
        },
        {
          icon: (
            <Icon
              name="sprite/doc-table-add"
              style={{
                transform: 'rotate(180deg)',
              }}
            />
          ) as ReactNode,
          tooltipText: 'Add row below',
          onClick: onAddRowAfter,
        },
        {
          icon: (
            <Icon name="sprite/bin" color="var(--color-error-40)" />
          ) as ReactNode,
          tooltipText: 'Delete row',
          onClick: onDeleteRow,
        },
      ],
      [editor],
    );

    return (
      <BaseBubbleMenu
        editor={editor}
        pluginKey="tableRowMenu"
        updateDelay={0}
        tippyOptions={{
          appendTo: () => {
            return appendTo?.current;
          },
          placement: 'left',
          offset: [0, 15],
          popperOptions: {
            modifiers: [{ name: 'flip', enabled: false }],
          },
        }}
        shouldShow={shouldShow}
      >
        <OptionsMenu options={menuItems} />
      </BaseBubbleMenu>
    );
  },
);

TableRowMenu.displayName = 'TableRowMenu';
