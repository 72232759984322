import React from 'react';

import { Helmet } from 'react-helmet-async';

export const LibraryPageTitle = () => {
  return (
    <Helmet>
      <title>Library | Visualist </title>
    </Helmet>
  );
};
