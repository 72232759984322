import {
  ChatSpeechRight,
  Dropdown,
  VaiColored,
} from '@visualist/design-system/src/components/v2';

import { VaiBeacon } from '@src/entities/vai/ui/beacon';
import { VaiPinnedCard } from '@src/entities/vai/ui/pinned-card';
import { VaiSectionAccordion } from '@src/entities/vai/ui/vai-section-accordion';
import { usePinnedMessages } from '@src/widgets/vai-main/hooks/usePinnedMessages';
import { useVaiActions } from '@src/widgets/vai-main/hooks/useVaiActions';
import {
  openedVaiModalWithHub,
  openedVaiModalWithMessage,
} from '@src/widgets/vai-main/model';

import styles from './styles.module.css';

export const VaiSection = ({
  hubId,
  hubName,
  threadId,
}: {
  hubId: string;
  hubName: string;
  threadId: string;
}) => {
  const { data: pinnedMessages } = usePinnedMessages(hubId);
  const { toggleEmailPinMutation } = useVaiActions({});

  const unpinMessage = (conversationId: string) => {
    toggleEmailPinMutation.mutateAsync({
      hubId,
      conversationId: conversationId,
    });
  };

  if (pinnedMessages && pinnedMessages.length > 0) {
    return (
      <VaiSectionAccordion>
        <div className={styles.accordionChildContainer}>
          {pinnedMessages?.map((message) => (
            <VaiPinnedCard
              cardTitle={message.vai_object_id.output_type}
              cardContent={message.text.text}
              onCloseClick={() => {
                unpinMessage(message?.id);
              }}
              onSeeMoreClick={() => {
                openedVaiModalWithMessage(message.id);
                openedVaiModalWithHub({
                  id: hubId,
                  name: hubName,
                });
              }}
              key={message.id}
            />
          ))}
        </div>
      </VaiSectionAccordion>
    );
  }

  return <EmptySection hubId={hubId} hubName={hubName} threadId={threadId} />;
};

const EmptySection = ({
  hubId,
  hubName,
  threadId,
}: {
  hubId: string;
  hubName: string;
  threadId: string;
}) => {
  const { generateThreadSummaryMutation } = useVaiActions({
    optionalHubId: hubId,
  });

  const onClickSummarizeThread = () => {
    generateThreadSummaryMutation.mutate({
      hubId,
      threadId,
    });
    openedVaiModalWithHub({
      id: hubId,
      name: hubName,
    });
  };

  return (
    <VaiSectionAccordion>
      <Dropdown>
        <Dropdown.Menu
          trigger={<VaiBeacon />}
          side="right"
          align="start"
          density="-2"
          sideOffset={4}
        >
          <Dropdown.MenuItem
            item={{
              content: 'Ask Vai to',
              leadingIcon: <VaiColored fill="none" />,
              isDivider: true,
              isDisabled: true,
              classNameContent: styles.vaiDropdownHeader,
            }}
            style={{ cursor: 'default' }}
          />
          <Dropdown.MenuItem
            item={{
              leadingIcon: <ChatSpeechRight fill="none" />,
              content: 'Summarize this thread',
              onClick: onClickSummarizeThread,
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    </VaiSectionAccordion>
  );
};
