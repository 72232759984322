import { useUnit } from 'effector-react';

import { Dialog, Modal } from '@visualist/design-system/src/components/v2';

import { useRemoveBoardFromHub } from '@pages/BoardPage/model/queries/useRemoveBoardFromHub';

import {
  $boardIdForHub,
  $boardWarningName,
  $hubdIdForDeleting,
  $isShowWarningDialog,
  moveBoardDialogClosed,
  warningDialogClosed,
} from './model';

export const WarningDialog = () => {
  const isShowWarningDialog = useUnit($isShowWarningDialog);
  const hub = useUnit($hubdIdForDeleting);
  const boardId = useUnit($boardIdForHub);
  const boardName = useUnit($boardWarningName);

  const { deleteBoardFromHub } = useRemoveBoardFromHub();

  return (
    <Modal handleClose={warningDialogClosed} showModal={isShowWarningDialog}>
      <Dialog
        headline={`Remove ${boardName} from hub?`}
        description="Everyone invited to the board will lose access."
        actionLabel="Remove"
        action={() => {
          if (hub && boardId) {
            deleteBoardFromHub({ hub, boards: [boardId] });
            warningDialogClosed();
            moveBoardDialogClosed();
          }
        }}
        closeAction={warningDialogClosed}
      />
    </Modal>
  );
};
