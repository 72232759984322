import { createEvent, createStore, sample } from 'effector';

import { Block } from '@api/index';

export const allFilesFetched = createEvent<Block[]>();
export const imagesFetched = createEvent<Block[]>();
export const designsFetched = createEvent<Block[]>();
export const palettesFetched = createEvent<Block[]>();

export const allFilesSelected = createEvent();
export const allUnselected = createEvent();
export const imagesSelected = createEvent();
export const designsSelected = createEvent();
export const palettesSelected = createEvent();

export const $allFiles = createStore<Block[]>([]);
export const $images = createStore<Block[]>([]);
export const $designs = createStore<Block[]>([]);
export const $palettes = createStore<Block[]>([]);

$allFiles.on(allFilesFetched, (_, images) => images);
$images.on(imagesFetched, (_, images) => images);
$designs.on(designsFetched, (_, designs) => designs);
$palettes.on(palettesFetched, (_, palettes) => palettes);

export const $isAllSelected = createStore(true);
export const $isImagesSelected = createStore(false);
export const $isDesignSelected = createStore(false);
export const $isPaletteSelected = createStore(false);

$isAllSelected.on(allFilesSelected, () => true).on(allUnselected, () => false);
$isImagesSelected.on(imagesSelected, (state) => !state).reset(allFilesSelected);
$isDesignSelected
  .on(designsSelected, (state) => !state)
  .reset(allFilesSelected);
$isPaletteSelected
  .on(palettesSelected, (state) => !state)
  .reset(allFilesSelected);

// If all options other than 'All' are selected or no option is selected then - automatically switch to 'All' being selected
sample({
  clock: [$isImagesSelected, $isDesignSelected, $isPaletteSelected],
  source: {
    isImagesSelected: $isImagesSelected,
    isDesignSelected: $isDesignSelected,
    isPaletteSelected: $isPaletteSelected,
  },
  filter: ({ isImagesSelected, isDesignSelected, isPaletteSelected }) =>
    (isImagesSelected && isDesignSelected && isPaletteSelected) ||
    (!isImagesSelected && !isDesignSelected && !isPaletteSelected),
  target: allFilesSelected,
});

// 'All' becomes unselected if another option is selected
sample({
  clock: [$isImagesSelected, $isDesignSelected, $isPaletteSelected],
  source: {
    isImagesSelected: $isImagesSelected,
    isDesignSelected: $isDesignSelected,
    isPaletteSelected: $isPaletteSelected,
  },
  filter: ({ isImagesSelected, isDesignSelected, isPaletteSelected }) =>
    isImagesSelected || isDesignSelected || isPaletteSelected,
  target: allUnselected,
});
