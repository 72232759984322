// Canvas realted
export const GRID_SIZE = 10;

// Animate related
export const TAB_ANIMATION_START_DELAY = 0.15;
export const TOOLBAR_ANIMATION_START_DELAY = TAB_ANIMATION_START_DELAY + 0.15;

// Names of nodes
export const DRAG_SELECTION = 'drag-selection';
export const IMAGES_LAYER = 'Images';
export const IMAGE_GROUP = 'Image';
export const TEXT_LAYER = 'Text';
export const STICKY_LAYER = 'Sticky';
export const TRANSFORMER = 'Transformer';
