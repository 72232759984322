import { AxiosResponse } from 'axios';

import { Board } from './boards';
import { api } from './services';

export type PermissionsHub = 'edit' | 'comment' | 'invite';

type User = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  photo: {
    full_size: string;
    medium_square_crop: string;
    small_square_crop: string;
    thumbnail: string;
  };
};

export type ThumbnailBrightness = 'dark' | 'light' | null;

export interface Hub {
  hubId?: string;
  name?: string;
  description?: string;
  welcome_message?: string;
  welcome_title?: string;
  end_date?: string;
  location?: string;
  start_date?: string;
}

export interface Bookmark {
  url: string;
  hub: string;
}

export interface BookmarksOfHub {
  hub: string;
}

export interface EditBookmark {
  url: string;
  id: string;
}

export interface HubRequest {
  name?: string;
  ordering?: string;
}

export type PostHubResponse = {
  data: HubResponse;
  state: {
    status: 'create_success';
  };
};

export type PostHubResponseError =
  | {
      data: {
        cost_to_open: number;
        credit_balance: number;
        deficit: number;
        deficit_unit_price: number;
      };
      state: {
        status: 'insufficient_credits';
      };
    }
  | {
      data: null;
      state: {
        status: 'low_membership_tier';
      };
    };

export interface HubResponse {
  block_count: number;
  created_by: number;
  description: string;
  id: string;
  is_removed: boolean;
  is_locked: boolean;
  logo: string;
  name: string;
  share: { id: string; allow_comments: boolean };
  thumbnail: {
    full_size: string;
  };
  thumbnail_hex: string;
  billing_anchor: number | null;
  credit_arrears: number;
  welcome_message: string;
  welcome_title: string;
  project_details?: {
    end_date?: string;
    location?: string;
    start_date?: string;
  };
  thumbnail_brightness: ThumbnailBrightness;
  previous_state: { name: string; description: string; location: string };
}

export type BookmarkThumbnail = {
  full_size: string;
  small_square_crop: string;
  thumbnail_100: string;
  thumbnail_130: string;
  thumbnail_160: string;
  thumbnail_330: string;
  thumbnail_400: string;
  thumbnail_640: string;
};

export interface BookmarksResponse {
  count: number;
  next: null;
  previous: null;
  results: BookmarkResponse[];
}

export interface BookmarkResponse {
  id: string;
  name: string;
  url: string;
  hub: string;
  created_by: number;
  created_at: string;
  thumbnail: BookmarkThumbnail;
  netloc: string;
}

export interface UserInvitationHub {
  email: string;
  name?: string;
  avatar?: string;
}

export interface InvitationHub {
  hubId: string;
  hubName?: string;
  users: UserInvitationHub[];
  message?: string;
  permissions: PermissionsHub[];
  setOn?: (value: boolean) => void;
}

export interface InviteeHub {
  member: number;
  permissions: PermissionsHub[];
}

export interface RemoveInviteeFromHub {
  hubId: string;
  members: string[];
  hubName?: string;
  email?: string;
  permission?: string;
  noHubAccess?: boolean;
}

export interface Member {
  accepted_at: string;
  created_at: string;
  board: string;
  email: string;
  hub: string | null;
  id: string;
  // User id of inviter
  invited_by: string | null;
  is_joined: boolean;
  is_owner: boolean;
  permissions: PermissionsHub[];
  user: User | null;
}

export interface ResponseInvitee {
  members: Member[];
  member_seats_available: number;
}
export interface UpdatePermissionHub {
  hubId: string;
  member: string;
  permissions: PermissionsHub[];
  name?: string;
  email?: string;
}

export interface Cover {
  hubId: string;
  thumbnail: FormData;
}

export interface CoverResponse {
  id: string;
  thumbnail: string;
}

export interface RefundedCreditsResponse {
  credits_refunded: number;
}

export const getHubs = async ({
  name,
  ordering,
}: HubRequest): Promise<HubResponse[]> => {
  const { data } = await api.get('/hubs/', { params: { name, ordering } });
  return data;
};

export const getHub = async ({ hubId }: Hub): Promise<HubResponse> => {
  const { data } = await api.get(`/hubs/${hubId}/`);
  return data;
};

export const createHub = async ({
  name,
  description,
  location,
}: Hub): Promise<AxiosResponse<PostHubResponse>> => {
  return await api.post('/hubs/', { name, description, location });
};

export const updateHub = async ({
  name,
  hubId,
  description,
  welcome_message,
  welcome_title,
  location,
}: Hub): Promise<HubResponse> => {
  const { data } = await api.patch(`/hubs/${hubId}/`, {
    name,
    description,
    welcome_message,
    welcome_title,
    location,
  });
  return data;
};

export const deleteHub = async ({ hubId }: Hub) => {
  const { data } = await api.delete(`/hubs/${hubId}/`);
  return data;
};

export const restoreHub = async (hubId: string) => {
  const { data } = await api.post(`/hubs/restore_deleted/`, { hub: hubId });
  return data;
};

export const getCredits = async ({
  hubId,
}: Hub): Promise<RefundedCreditsResponse> => {
  const { data } = await api.post(`/hubs/${hubId}/credit_due/`);
  return data;
};

export const getInviteesHub = async (
  hubId: string,
): Promise<ResponseInvitee> => {
  const { data } = await api.get(`/hubs/${hubId}/members/`);
  return data;
};

export const inviteToHub = async ({
  hubId,
  users,
  message,
  permissions,
}: InvitationHub): Promise<InvitationHub> => {
  const { data } = await api.post(`/hubs/${hubId}/members/invite/`, {
    users,
    message,
    permissions,
  });
  return data;
};

export const deleteInviteeFromHub = async ({
  hubId,
  members,
}: RemoveInviteeFromHub) => {
  const { data } = await api.post(`/hubs/${hubId}/members/remove/`, {
    members,
  });
  return data;
};

export const updatePermissionHub = async ({
  hubId,
  member,
  permissions,
}: UpdatePermissionHub) => {
  const { data } = await api.post(`/hubs/${hubId}/members/permissions/`, {
    member,
    permissions,
  });
  return data;
};

export const getBookmarks = async (): Promise<BookmarksResponse> => {
  const { data } = await api.get('/hubs/bookmarks/');
  return data;
};

export const getBookmarksOfHub = async ({
  hub,
}: BookmarksOfHub): Promise<BookmarksResponse> => {
  const { data } = await api.get(`/hubs/bookmarks/?hub=${hub}`);
  return data;
};

export const getBoardsOfHub = async ({
  hubId,
  ordering,
}: {
  hubId: string;
  ordering?: string;
}): Promise<Board[]> => {
  const { data } = await api.get(`/boards/?hub=${hubId}`, {
    params: { ordering },
  });
  return data;
};

export const createBookmarks = async ({ url, hub }: Bookmark) => {
  const { data } = await api.post('/hubs/bookmarks/', { url, hub });
  return data;
};

export const editBookmark = async ({
  id,
  url,
}: EditBookmark): Promise<BookmarkResponse> => {
  const { data } = await api.patch(`/hubs/bookmarks/${id}/`, { url });
  return data;
};

export const deleteBookmark = async (id: string) => {
  const { data } = await api.delete(`/hubs/bookmarks/${id}`);
  return data;
};

export const uploadImage = ({
  hubId,
  thumbnail,
}: Cover): Promise<CoverResponse> =>
  api.post(`/hubs/${hubId}/thumbnail/`, thumbnail);

//share
export const createHubShare = async (
  hubId: string,
  allow_comments: boolean,
) => {
  const { data } = await api.post('/share/', {
    hub: hubId,
    allow_comments,
  });

  return data;
};

export const deleteHubShare = async (shareId: string) =>
  await api.delete(`/share/${shareId}/`);
