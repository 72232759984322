import React from 'react';

export interface Options {
  header: boolean;
}

const defaultValue: Options = {
  header: true,
};

export const OptionContext = React.createContext(defaultValue);
