export function isInteractableElement(element: Element) {
  // List of interactable element tags
  const interactableTags = ['INPUT', 'TEXTAREA', 'SELECT', 'BUTTON'];

  // Check if the element's tag is in the list of interactable tags
  if (interactableTags.includes(element.tagName)) {
    return true;
  }

  // Check for elements with contenteditable attribute
  if (element.getAttribute('contenteditable') === 'true') {
    return true;
  }

  // Check for elements with tabindex
  if (
    element.hasAttribute('tabindex') &&
    element.getAttribute('tabindex') !== '-1'
  ) {
    return true;
  }

  return false;
}
