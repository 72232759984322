import axios from 'axios';
import { useFormik } from 'formik';

import {
  Button,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { changePassword } from '@api/account';
import { ChangePasswordFormValues } from '@src/widgets/auth/types';
import {
  resolveResetPasswordErrorMessages,
  validateResetConfirmedPasswordLogin,
} from '@src/widgets/auth/utils';
import { useMutation } from '@tanstack/react-query';

import styles from './styles.module.css';

const initialValues: ChangePasswordFormValues = {
  password: '',
  confirmPassword: '',
  oldPassword: '',
};

export const ChangePasswordForm = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const { mutate } = useMutation({
    mutationFn: changePassword,
    onSuccess,
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        if (
          error.response?.data?.old_password &&
          error.response?.data?.old_password.length !== 0
        ) {
          formik.errors.oldPassword = 'Current password is incorrect';
        }

        if (
          error.response?.data?.new_password2 &&
          error.response?.data?.new_password2.length !== 0
        ) {
          formik.errors.password = resolveResetPasswordErrorMessages(
            error.response.data.new_password2[0],
          );
        }
      } else {
        formik.errors.password = 'Something went wrong';
      }
    },
  });

  const formik = useFormik({
    initialValues,
    validate: (v) =>
      validateResetConfirmedPasswordLogin(
        v,
        {} as Record<keyof ChangePasswordFormValues, string>,
      ),
    onSubmit: (values) => {
      return mutate({
        old_password: values.oldPassword,
        new_password1: values.password,
        new_password2: values.confirmPassword,
      });
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.mainForm}>
        <form
          noValidate
          onSubmit={(e) => {
            formik.handleSubmit(e);
          }}
          className={styles.form}
        >
          <div className={styles.formInput}>
            <TypographyPoppins
              type="label"
              labelSize="M"
              className={styles.label}
            >
              <label htmlFor="oldPassword">Current password</label>
            </TypographyPoppins>
            <TextField
              isFocused
              formNoValidate
              id="oldPassword"
              name="oldPassword"
              type="password"
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              errorAndSupportingText={formik.errors.oldPassword}
              clear={(e) => {
                e.preventDefault();
                formik.resetForm();
              }}
            />
          </div>
          <div className={styles.formInput}>
            <TypographyPoppins
              type="label"
              labelSize="M"
              className={styles.label}
            >
              <label htmlFor="email">New password</label>
            </TypographyPoppins>
            <TextField
              formNoValidate
              id="password"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              errorAndSupportingText={formik.errors.password}
              supportingText={
                !formik.errors.password
                  ? 'Use at least 12 characters, including a symbol or number'
                  : undefined
              }
              clear={(e) => {
                e.preventDefault();
                formik.resetForm();
              }}
            />
          </div>
          <div className={styles.formInput}>
            <TypographyPoppins
              type="label"
              labelSize="M"
              className={styles.label}
            >
              <label htmlFor="email">Confirm password</label>
            </TypographyPoppins>
            <TextField
              formNoValidate
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              errorAndSupportingText={formik.errors.confirmPassword}
              clear={(e) => {
                e.preventDefault();
                formik.resetForm();
              }}
            />
          </div>
          <div className={styles.formButtons}>
            <Button
              type="filled"
              label="Change password"
              // Empty onclick as it's handled by formik
              onClick={() => {}}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
