import { combine, createEvent, createStore, sample } from 'effector';

export type SelectedImage = {
  id: string;
  ref: HTMLImageElement;
};

export const singleImageSelected = createEvent<SelectedImage>();
export const singleImageUnselected = createEvent();
export const multipleImagesSelected = createEvent<SelectedImage>();
export const allImagesUnselected = createEvent();
export const imageDragStart = createEvent<SelectedImage>();
export const imageDragEnd = createEvent();

export const $singleSelectedImage = createStore<SelectedImage[] | null>(null);
export const $selectedImages = createStore<SelectedImage[] | null>(null);
export const $draggableImages = createStore<SelectedImage[] | null>(null);

// Select single image
sample({
  clock: singleImageSelected,
  fn: ({ id, ref }) => [{ id, ref }],
  target: $singleSelectedImage,
});

sample({
  clock: singleImageUnselected,
  fn: () => null,
  target: $singleSelectedImage,
});

// Select/unselect miltiple images
sample({
  clock: multipleImagesSelected,
  source: {
    selectedImages: $selectedImages,
  },
  fn: ({ selectedImages }, { ref, id }) => {
    if (!selectedImages) {
      return [{ id, ref }];
    }

    const isImagesExists =
      selectedImages.filter((image) => image.id === id).length > 0;

    if (isImagesExists) {
      const images = selectedImages.filter((image) => image.id !== id);
      return images.length > 0 ? images : null;
    } else {
      return [...selectedImages, { id, ref }];
    }
  },
  target: $selectedImages,
});

sample({
  clock: imageDragStart,
  fn: ({ id, ref }) => [{ id, ref }],
  target: $draggableImages,
});

sample({
  clock: imageDragEnd,
  fn: () => null,
  target: $draggableImages,
});

// Display FAB when at least 1 image is selected
export const $isUploadAvailable = combine(
  { selectedImages: $selectedImages },
  ({ selectedImages }) => Array.isArray(selectedImages),
);

// Deselect all images
sample({
  clock: allImagesUnselected,
  fn: () => null,
  target: [$selectedImages, $draggableImages],
});
