import { Icon, IconProps } from './Icon';

export const EmptyMailboxColored = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        opacity="0.5"
        d="M20.9124 6.89688V12.2353C20.9124 12.3457 20.8228 12.4353 20.7124 12.4353H19.8904H16.1772H12.2885H11.442V6.89688C11.442 4.24954 9.97762 3.47152 9.03082 3.24515C9.00419 3.23879 9.01162 3.15477 9.039 3.15477L10.9075 3.1548L13.4578 3.15477H14.4418L17.9216 3.15476L18.8161 3.1548C18.8307 3.1548 18.8447 3.15624 18.8588 3.16006C19.075 3.21879 20.9124 3.80873 20.9124 6.89688Z"
        fill="#E2C9EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9124 13.3428V6.89688C20.778 4.62692 20.2363 4.11193 20.0952 4.01533C20.0736 4.00058 20.0479 3.99558 20.0218 3.99558H18.9739L11.6073 4.01973H10.4568C11.0064 4.56526 11.442 5.45306 11.442 6.89688V13.5418H16.263H19.8926H20.7121C20.8227 13.5418 20.9124 13.4527 20.9124 13.3428ZM13.1385 7.50377C13.028 7.50377 12.9385 7.41422 12.9385 7.30377V7.09688C12.9385 6.98643 13.028 6.89688 13.1385 6.89688H18.3779C18.4883 6.89688 18.5779 6.98643 18.5779 7.09688V10.2772V7.70377C18.5779 7.59331 18.4883 7.50377 18.3779 7.50377H13.1385Z"
        fill="#E2C9EB"
      />
      <path
        opacity="0.5"
        d="M11.442 12.4353H5.10352V6.89688C5.10352 3.22366 8.12978 3.15604 8.24121 3.15479L8.24746 3.15463C8.35888 3.14996 11.442 3.05559 11.442 6.89688V12.4353Z"
        fill="#E2C9EB"
      />
      <path
        opacity="0.75"
        d="M11.4407 12.4353H5.8301L5.83008 6.25577C5.83005 3.30205 8.23204 3.15543 8.24304 3.15477C8.24338 3.15475 8.24277 3.15479 8.24311 3.15477C8.25415 3.1542 11.4407 2.99168 11.4407 6.89688V12.4353Z"
        fill="#DCD5CB"
      />
      <path
        d="M20.9124 12.4353V6.89687C20.9124 3.15474 18.4902 3.15476 18.4902 3.15476C18.4902 3.15476 18.4902 3.15476 18.4902 3.15476H16.9213M20.9124 12.4353H19.8904M20.9124 12.4353V13.2946C20.9124 13.4051 20.8228 13.4946 20.7124 13.4946H11.642C11.5316 13.4946 11.442 13.4051 11.442 13.2946V12.4353M8.24436 3.15476C8.24436 3.15476 5.10352 3.15476 5.10352 6.89687C5.10352 10.2498 5.10352 11.9862 5.10352 12.4353H5.82511M8.24436 3.15476C8.24436 3.15476 11.442 2.98489 11.442 6.89687C11.442 12.4353 11.442 6.89687 11.442 12.4353M8.24436 3.15476H10.403M8.24436 3.15476C8.24436 3.15476 5.82511 3.15477 5.82511 6.89687C5.82511 8.47538 5.82511 10.2208 5.82511 12.4353M11.442 12.4353H12.2885M11.442 12.4353H5.82511M10.403 3.15476C10.403 3.15476 12.2885 3.15476 12.2885 6.89687C12.2885 9.08163 12.3184 11.4989 12.2885 12.4353M10.403 3.15476H13.4578H14.4904H16.9213M12.2885 12.4353H16.1772H19.8904M19.8904 12.4353C19.8904 12.4353 19.8904 12.4353 19.8904 6.89687C19.8904 3.15433 16.9213 3.15476 16.9213 3.15476"
        stroke="#43424A"
        strokeWidth="0.2"
      />
      <path
        opacity="0.5"
        d="M3.6342 15.8957C2.14792 19.3832 4.44166 20.5552 4.44166 20.5552C4.44166 20.5552 4.77606 20.7602 5.2869 20.8416C6.30414 21.0602 8.00919 21.0078 9.084 18.7249C10.0252 16.7258 11.139 14.4107 11.5188 13.5415L11.4485 12.4534L5.1013 12.4533C4.79393 13.1254 5.1013 12.4533 3.6342 15.8957Z"
        fill="#E2C9EB"
      />
      <path
        d="M4.4753 20.4603C4.48342 20.4655 4.48777 20.4681 4.48777 20.4681C4.48777 20.4681 4.82218 20.6731 5.33301 20.7545C6.35025 20.9731 8.05531 20.9207 9.13011 18.6378C10.0713 16.6386 11.8306 13.3399 11.4299 12.7494L11.0552 13.4543L5.14772 13.4543C4.80447 14.2338 4.42239 15.0586 4.1106 15.8665C4.11009 15.8675 4.10958 15.8686 4.10908 15.8697C3.61394 16.9213 3.45866 17.7607 3.48118 18.4191C3.45754 19.2674 3.72203 19.986 4.4753 20.4603Z"
        fill="#E2C9EB"
      />
      <path
        d="M4.451 20.4992C4.451 20.4992 2.13715 19.1985 3.66077 15.7393C5.14873 12.361 4.90714 12.8614 5.10016 12.4515L11.443 12.4515M4.451 20.4992C4.451 20.4992 6.90891 22.0058 8.67265 18.4789C10.6328 14.5594 9.08065 17.7425 10.6628 14.3821L11.0406 13.506M4.451 20.4992C4.451 20.4992 7.48128 22.0932 9.08963 18.6771C10.0286 16.6827 11.0642 14.3732 11.4431 13.5061L11.443 12.4515M4.451 20.4992C2.37808 19.2113 3.95426 16.1085 5.10019 13.5059L11.0406 13.506M11.443 12.4515L11.0406 13.506"
        stroke="#43424A"
        strokeWidth="0.2"
      />
      <path
        d="M5.10352 13.4866V12.4354L8.24305 12.4355H11.3804L11.0059 13.4866H5.10352Z"
        stroke="#43424A"
        strokeWidth="0.2"
      />
      <path
        d="M14.7754 7.47453C14.7754 7.61188 14.6635 7.72322 14.5254 7.72322C14.3873 7.72322 14.2754 7.61188 14.2754 7.47453C14.2754 7.33717 14.3873 7.22583 14.5254 7.22583C14.6635 7.22583 14.7754 7.33717 14.7754 7.47453Z"
        stroke="#43424A"
        strokeWidth="0.2"
      />
      <path
        d="M13.0079 8.08333V6.87671L17.3393 6.87671H18.5156V10.2383H17.3393V8.08333H13.0079Z"
        stroke="#43424A"
        strokeWidth="0.2"
      />
    </Icon>
  );
};
