import styles from './styles.module.css';

export const Spinner = ({ variant = 50 }: { variant?: 25 | 50 | 75 | 100 }) => {
  const circumference = 2 * Math.PI * 12; // 2πr, where r = 12
  const dashOffset = variant === 100 ? 0 : circumference * (1 - variant / 100);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.spinner}
    >
      <circle
        cx="18"
        cy="18"
        r="12"
        stroke="#DBB9C9"
        strokeWidth="3"
        fill="none"
        strokeDasharray={circumference}
        strokeDashoffset={dashOffset}
        strokeLinecap="round"
      ></circle>
    </svg>
  );
};
