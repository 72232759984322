import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * @description useMouseEnterLeave hook
 * @description This hook is used to handle mouse enter and mouse leave events, ensures that one onEnter matches one onExit even if user scrolls the page
 * @param onEnter
 * @param onExit
 * @returns
 */

export function useMouseEnterLeave(onEnter: () => void, onExit: () => void) {
  const [isInside, setIsInside] = useState(false);
  const [hasExited, setHasExited] = useState(false);
  const elementRef = useRef(null);

  const handleMouseEnter = useCallback(() => {
    if (!isInside) {
      setIsInside(true);
      setHasExited(false);
      onEnter();
    }
  }, [isInside, onEnter]);

  const handleExit = useCallback(() => {
    if (isInside && !hasExited) {
      setIsInside(false);
      setHasExited(true);
      onExit();
    }
  }, [isInside, hasExited, onExit]);

  const handleMouseLeave = useCallback(() => {
    handleExit();
  }, [handleExit]);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting && isInside) {
          handleExit();
        }
      },
      { threshold: 0 },
    );

    observer.observe(element);

    return () => {
      observer.unobserve(element);
      observer.disconnect();
    };
  }, [isInside, handleExit]);

  return {
    elementRef,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };
}
