export enum KeyCodes {
  ENTER = 'Enter',
  ESC = 'Escape',
  BACKSPACE = 'Backspace',
  SPACE = ' ',
  DELETE = 'Delete',
  // DOWN = 'ArrowDown',
  // UP = 'ArrowUp',
  COMMA = ',',
  LEFT = 37,
  RIGHT = 39,
  DOWN = 40,
  UP = 38,
}

export const KEY_DELETE = ['Delete'];
export const KEY_BACKSPACE = ['Backspace'];
export const KEY_ESCAPE = ['Escape'];
export const KEY_LEFT = ['ArrowLeft'];
export const KEY_RIGHT = ['ArrowRight'];
export const KEY_UP = ['ArrowUp'];
export const KEY_DOWN = ['ArrowDown'];
