import { createEvent, createStore, sample } from 'effector';

export const toggleMobileSidebar = createEvent();
export const openedMobileSidebar = createEvent();

export const expandedSidebar = createEvent();
export const shrunkSidebar = createEvent();

export const $showMobileSidebar = createStore(false)
  .on(toggleMobileSidebar, (s) => !s)
  .on(openedMobileSidebar, () => true)
  .reset(shrunkSidebar);

export const $isExpanded = createStore(false)
  .on(expandedSidebar, () => true)
  .on(shrunkSidebar, () => false);

// Makes sure isExpanded is true when toggleMobileSidebar or openedMobileSidebar is called
sample({
  clock: [toggleMobileSidebar, openedMobileSidebar],
  source: $showMobileSidebar,
  filter: (showMobileSidebar) => showMobileSidebar,
  target: expandedSidebar,
});
