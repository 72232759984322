import { createEvent, createStore } from 'effector';

export const hexToggled = createEvent();
export const rgbToggled = createEvent();
export const hslToggled = createEvent();
export const hsvToggled = createEvent();

export const hexUpdated = createEvent<boolean>();
export const rgbUpdated = createEvent<boolean>();
export const hslUpdated = createEvent<boolean>();
export const hsvUpdated = createEvent<boolean>();

export const $hex = createStore(true);
export const $rgb = createStore(true);
export const $hsl = createStore(true);
export const $hsv = createStore(true);

$hex.on(hexUpdated, (_, hex) => hex).on(hexToggled, (isToggled) => !isToggled);

$rgb.on(rgbUpdated, (_, rgb) => rgb).on(rgbToggled, (isToggled) => !isToggled);

$hsl.on(hslUpdated, (_, hsl) => hsl).on(hslToggled, (isToggled) => !isToggled);

$hsv.on(hsvUpdated, (_, hsv) => hsv).on(hsvToggled, (isToggled) => !isToggled);
