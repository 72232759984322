import { UpdateColorPalette, updateColorPalette } from '@api/blocks';
import { imagesKeys } from '@src/shared/constants/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpdateColorPalette = (fileId: string) => {
  const queryClient = useQueryClient();

  const { mutate: updatePalette } = useMutation({
    mutationFn: (payload: UpdateColorPalette) =>
      updateColorPalette({ codes: payload.codes, id: fileId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: imagesKeys.image(fileId),
      });
    },
  });

  return { updatePalette };
};
