import { PropsWithChildren } from 'react';

import { motion } from 'framer-motion';

import styles from './styles.module.css';

type Variants = 'fullScreen' | 'default';

const containerVariants = (
  variant: Variants,
): React.ComponentProps<typeof motion.div> => {
  if (variant === 'fullScreen')
    return {
      initial: {
        transform: `scale(0.98)`,
        opacity: 0,
      },
      animate: {
        transform: `scale(1)`,
        opacity: 1,
      },
      exit: {
        transform: `scale(0.98)`,
        opacity: 0,
      },
      transition: {
        duration: 0.2,
      },
    };

  return {
    initial: {
      transform: `scale(0.98)`,
      opacity: 0,
    },
    animate: {
      transform: `scale(1)`,
      opacity: 1,
    },
    exit: {
      transform: `scale(0.98)`,
      opacity: 0,
    },
    transition: {
      duration: 0.2,
    },
  };
};

export const SearchContainer = ({
  children,
  shouldAnimate = false,
  variant = 'default',
}: PropsWithChildren<{
  shouldAnimate?: boolean;
  variant?: Variants;
}>) => {
  const animationProps = shouldAnimate ? containerVariants(variant) : undefined;

  return (
    <motion.div
      data-fullscreen={variant === 'fullScreen'}
      {...animationProps}
      className={styles.container}
    >
      {children}
    </motion.div>
  );
};

export const SearchContainerMobile = ({ children }: PropsWithChildren) => {
  return <div className={styles.containerMobile}>{children}</div>;
};
