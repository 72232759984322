import {
  SegmentedButton,
  Tooltip,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const TextStyleButton = (props: {
  textIcon: React.ReactNode;
  textIconStyle: React.CSSProperties;
  isActive: boolean;
  toggle: () => void;
  tooltipText: string;
}) => {
  const { textIcon, isActive, textIconStyle, toggle, tooltipText } = props;

  const icon =
    typeof textIcon === 'string' ? (
      <span style={textIconStyle}>{textIcon}</span>
    ) : (
      textIcon
    );

  return (
    <Tooltip
      parameter={{
        description: tooltipText,
        type: 'plain',
        position: 'bottom',
        centerText: true,
      }}
      style={{
        minWidth: 32,
        whiteSpace: 'nowrap',
      }}
    >
      <SegmentedButton
        buttonStyle={styles.button}
        isSelected={isActive}
        icon={icon}
        onClick={toggle}
      />
    </Tooltip>
  );
};
