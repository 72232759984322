import { MutateOptions, useMutation } from '@tanstack/react-query';

import {
  UpdatePermissionHub,
  updatePermissionHub,
} from '../../../../../api/hubs';
import { queryClient } from '../../../../../queryClient';
import { MEMBERS_INVITATION_HUB_QUERY } from '../../../../../shared/constants/query-names';

export const useChangePermissionsHub = (
  options?: MutateOptions<UpdatePermissionHub, any, UpdatePermissionHub>,
) => {
  const { mutate: changePermissionHub } = useMutation({
    mutationFn: ({
      hubId,
      member,
      permissions,
      email,
      name,
    }: UpdatePermissionHub) =>
      updatePermissionHub({ hubId, member, permissions, email, name }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_INVITATION_HUB_QUERY],
      });
      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
  });

  return { changePermissionHub };
};
