import { useRef } from 'react';

import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';

import {
  Dropdown,
  Fab,
  Item,
  Palette,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { Board } from '@api/designs';
import { boardDialogOpened } from '@src/entities/dialogs/board/model';
import { hubDialogOpened } from '@src/entities/dialogs/hub/model';
import { paletteDialogOpened } from '@src/entities/dialogs/palette/model';
import { useBilling } from '@src/shared/queries/useBilling';
import { useMutation } from '@tanstack/react-query';

import { createNewSet } from '../../api';
import { useCreateDoc } from '../../entities/doc/queries/useCreateDoc';
import { Plan } from '../../pages/AccountPage/components/types';
import { useBoardContext } from '../../pages/BoardPage';
import { Page } from '../LibraryActions/LibraryActions';

import styles from './ActionButton.module.css';

export type Props = {
  className?: string;
  mobile?: boolean;
  uploadAction: any;
  showFAB?: boolean;
  handleOpen?: any;
  handleClose?: any;
  currentBoard?: Board;
  page?: Page;
  plan?: Plan;
};

const ActionButton = ({ className, uploadAction, page, plan }: Props) => {
  const linkRef = useRef<any>(null);

  const { board_id } = useParams<{ board_id: string }>();

  const history = useHistory();
  const { hasPermissionEditing } = useBoardContext();
  const { isWithinThreeDays } = useBilling();

  const createDoc = () => {
    board_id ? makeDoc({ board: board_id }) : makeDoc({});
  };

  const createDesign = () => {
    createNewSetMutation();
  };

  const uploadImages = () => {
    uploadAction();
  };

  const menuItems: Item[] = hasPermissionEditing
    ? [
        {
          leadingIcon: <Icon name="sprite/hub" />,
          content: 'Create hub',
          trailingIcon:
            plan === 'free' || plan === 'starter' ? (
              <TypographyPoppins
                className={styles.badge}
                type="label"
                labelSize="XS"
              >
                Pro users only
              </TypographyPoppins>
            ) : null,
          isDisabled:
            plan === 'free' || plan === 'starter' || isWithinThreeDays,
          onClick: () => hubDialogOpened(),
        },
        {
          leadingIcon: <Icon name="sprite/board" />,
          content: 'Create board',
          onClick: () => boardDialogOpened(),
        },
        {
          leadingIcon: <Icon name="sprite/doc" />,
          content: 'Create doc',
          onClick: createDoc,
        },
        {
          leadingIcon: <Icon name="sprite/studio" />,
          content: 'Create design',
          onClick: createDesign,
        },
        {
          leadingIcon: <Icon name="sprite/upload" />,
          content: 'Upload images',
          onClick: uploadImages,
        },
        {
          leadingIcon: <Palette fill="none" />,
          content: 'Create palette',
          onClick: () => paletteDialogOpened(),
        },
      ]
    : [
        {
          leadingIcon: <Icon name="sprite/hub" />,
          content: 'Create hub',
          onClick: () => hubDialogOpened(),
        },
        {
          leadingIcon: <Icon name="sprite/studio" />,
          content: 'Create design',
          onClick: createDesign,
        },
      ];

  if (isMobile) {
    menuItems.splice(2, 1);
  }

  const { mutate: createNewSetMutation } = useMutation({
    mutationFn: () => {
      if (board_id) {
        return createNewSet({ name: 'Untitled', boardId: board_id });
      } else {
        return createNewSet({ name: 'Untitled' });
      }
    },
    onSuccess: async ({ data }) => {
      history.push(`/studio/${data.id}`);
    },
    onError: () => {
      startedSnack({
        label: "Couldn't create design",
        action: {
          label: 'Try again',
          action: () => {
            if (board_id) {
              return createNewSet({ name: 'Untitled', boardId: board_id });
            } else {
              return createNewSet({ name: 'Untitled' });
            }
          },
        },
        close: true,
      });
    },
  });

  const { makeDoc } = useCreateDoc();

  return (
    <>
      <div
        className={cn(styles.container, className, styles.desktop, {
          [styles.mobile]: isMobile,
          [styles.docPage]: page === 'docPage',
        })}
      >
        <AnimatePresence>
          <Dropdown>
            <Dropdown.Menu
              trigger={<Fab style="small" icon={<Icon name="sprite/plus" />} />}
              side="top"
              sideOffset={15}
              collisionPadding={20}
              density="-2"
            >
              {menuItems.map((item, index) => (
                <Dropdown.MenuItem key={index} item={item} />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </AnimatePresence>
      </div>
      <a
        hidden
        ref={linkRef}
        href="https://chrome.google.com/webstore/detail/visualist-chrome-extensio/kfkkhoppmbfmfmacgfagijkmphpiibjd"
        rel="noopener noreferrer"
        target="_blank"
      >
        Visualist Chrome Extension
      </a>
    </>
  );
};

export default ActionButton;
