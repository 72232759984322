import { useState } from 'react';

import cn from 'classnames';
import { motion } from 'framer-motion';

import {
  Button,
  TypographyItalic,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { MappedMembershipProduct } from '@api/billing';
import { openedPlanModal } from '@pages/AccountPage/models';
import { formatAnnualPrice } from '@pages/AccountPage/utils';

import { Credit } from '../Credit';
import {
  checkIfAction,
  checkIfUpgradeAction,
  Plan,
  PlanCardDetails,
} from '../types';

import styles from './styles.module.css';

type PlanCardProps = {
  isCurrentPlan: boolean;
  currentPlan: Plan;
  planCardDetails: PlanCardDetails;
  fetchedPlanCardDetails: MappedMembershipProduct;
  isAnnualToggle: boolean;
  useUserAnnual?: boolean;
  isUserAnnual?: boolean;
  isDisabled?: boolean;
  blockPlanChange?: boolean;
};

export const PlanCard = ({
  isCurrentPlan,
  currentPlan,
  planCardDetails,
  isAnnualToggle,
  useUserAnnual,
  // isUserAnnual,
  fetchedPlanCardDetails,
  isDisabled,
  blockPlanChange = false,
}: PlanCardProps) => {
  const { isMobile } = useWindowSize();
  const hideBottomButton = isCurrentPlan; //&& planCardDetails.title === 'free';
  const [expand, setExpand] = useState(
    isCurrentPlan || !(isMobile && !isCurrentPlan),
  );
  const title =
    planCardDetails.title.charAt(0).toUpperCase() +
    planCardDetails.title.slice(1);

  const isUserAnnual = false;

  const openBasedOnPlan = (planToChangeTo: Plan) => {
    const planMode = `${currentPlan}-${planToChangeTo}`;

    if (checkIfAction(planMode)) {
      if (
        planMode === 'free-free' ||
        planMode === 'pro-pro' ||
        planMode === 'starter-starter'
      ) {
        // User wants to change billing frequency
        openedPlanModal({
          mode: 'billing-frequency',
          isPlanModalOpen: true,
          planModalMode: planMode,
          planToChange: null,
        });
      } else {
        // Upgrade downgrade
        openedPlanModal({
          mode: 'upgrade-downgrade',
          isPlanModalOpen: true,
          planModalMode: checkIfUpgradeAction(planMode),
          planToChange: planToChangeTo,
        });
      }
    }
  };

  const toggleExpandCard = () => {
    if (isMobile && !isCurrentPlan) {
      setExpand((prev) => !prev);
    }
  };

  const userPlanPrice = formatAnnualPrice(
    fetchedPlanCardDetails.unitPrice,
    Boolean(isUserAnnual),
  );

  const toggledPlanPrice = formatAnnualPrice(
    fetchedPlanCardDetails.unitPrice,
    Boolean(isAnnualToggle),
  );

  return (
    <div
      onClick={toggleExpandCard}
      tabIndex={0}
      className={cn(styles.container, {
        [styles.containerCurrentPlan]: isCurrentPlan,
      })}
    >
      <div className={styles.titleContainer}>
        <TypographyPoppins type="headline" size="L">
          {/* Capitalise first letter */}
          <span className={styles.title}>{title}</span>
        </TypographyPoppins>
        <TypographyItalic formattingBody="medium light italic">
          <span className={styles.subTitle}>{planCardDetails.subTitle}</span>
        </TypographyItalic>
      </div>
      <div className={styles.costContainer}>
        <div
          className={cn(styles.priceContainer, {
            [styles.priceContainerHorizontal]:
              isMobile && !isCurrentPlan && expand,
          })}
        >
          <div className={styles.creditContainer}>
            {useUserAnnual ? (
              <TypographyPoppins type="display" size="M">
                <span
                  className={cn(styles.cost, {
                    [styles.costCurrentPlan]: !isCurrentPlan,
                  })}
                >
                  ${userPlanPrice}
                </span>
              </TypographyPoppins>
            ) : (
              <>
                <TypographyPoppins type="display" size="M">
                  <span className={styles.otherPlanCost}>$</span>
                </TypographyPoppins>
                {toggledPlanPrice > 10 ? (
                  <Credit
                    credit={toggledPlanPrice}
                    isCurrentPlan={isCurrentPlan}
                    place={10}
                  />
                ) : null}
                <Credit
                  credit={toggledPlanPrice}
                  isCurrentPlan={isCurrentPlan}
                  place={1}
                />
              </>
            )}
          </div>
          <TypographyPoppins type="body" size="M">
            <span className={styles.costDuration}>
              {planCardDetails.costDuration}
              {getCostLabel(isCurrentPlan, currentPlan, Boolean(isUserAnnual))}
            </span>
          </TypographyPoppins>
        </div>
      </div>
      <div
        className={cn(styles.infoContainer, {
          [styles.infoContainerOpen]: isMobile ? expand : true,
        })}
      >
        {planCardDetails.features.map((feature, i) => {
          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: i * 0.1,
                ease: [0.2, 0, 0, 1],
                duration: 0.5,
              }}
              key={feature + expand}
              className={styles.featureContainer}
            >
              <Icon name="sprite/tick" />
              <TypographyPoppins type="body" size="M">
                <span className={styles.feature}>{feature}</span>
              </TypographyPoppins>
            </motion.div>
          );
        })}
      </div>
      <BottomButton
        hideBottomButton={hideBottomButton}
        isCurrentPlan={isCurrentPlan}
        planCardDetails={planCardDetails}
        // This pay annual not to be confused with payAnnualToggle. This should be the users billing option
        isUserAnnual={isUserAnnual}
        openPlan={openBasedOnPlan}
        isDisabled={isDisabled || blockPlanChange}
      />
    </div>
  );
};

const getCostLabel = (
  isCurrentPlan: boolean,
  plan: Plan,
  isUserAnnual: boolean,
) => {
  if (isCurrentPlan && plan !== 'free') {
    return isUserAnnual ? ', billed monthly' : ', billed monthly';
  }
  return null;
};

const getCopy = (
  isCurrentPlan: boolean,
  planCardDetails: PlanCardDetails,
  isUserAnnual?: boolean | undefined,
) => {
  if (isCurrentPlan) {
    if (typeof isUserAnnual === 'undefined') return 'Error';
    const percent = planCardDetails.title === 'pro' ? '13' : '25';
    return isUserAnnual
      ? 'Switch to monthly billing'
      : `Switch to yearly (save ${percent}%)`;
  }

  return planCardDetails.buttonAction?.title;
};

const getButtonType = (
  isCurrentPlan: boolean,
  plan: Plan,
  isUserAnnual: boolean | undefined,
) => {
  if (isCurrentPlan) {
    return typeof isUserAnnual === 'undefined' || !isUserAnnual
      ? 'filled'
      : 'outlined';
  }

  switch (plan) {
    case 'pro':
      return 'filled';
    case 'free':
      return 'ghost';
    default:
      return 'outlined';
  }
};

type BottomButtonProps = {
  hideBottomButton: boolean;
  isCurrentPlan: boolean;
  planCardDetails: PlanCardDetails;
  isUserAnnual?: boolean | undefined;
  openPlan: (p: Plan) => void;
  isDisabled?: boolean;
};

const BottomButton = ({
  hideBottomButton,
  isCurrentPlan,
  planCardDetails,
  isUserAnnual,
  openPlan,
  isDisabled,
}: BottomButtonProps) => {
  const copy = getCopy(isCurrentPlan, planCardDetails, isUserAnnual);

  // TODO replace ghost button with actual ghost button once added to ds
  const buttonType = getButtonType(
    isCurrentPlan,
    planCardDetails.title,
    isUserAnnual,
  );

  const open = () => {
    openPlan(planCardDetails.title);
  };

  return (
    <div className={styles.switchContainer}>
      {!isCurrentPlan && planCardDetails.title === 'free' ? (
        <span
          className={cn({
            [styles.hide]: hideBottomButton,
          })}
        >
          <Button
            type="ghost"
            label={copy || ''}
            isDisabled={isDisabled}
            onClick={open}
            style={{
              color: '#FFB596',
            }}
          />
        </span>
      ) : null}
      {!hideBottomButton && planCardDetails.title !== 'free' ? (
        <Button
          onClick={open}
          type={buttonType}
          label={copy ?? ''}
          isDisabled={isDisabled}
        />
      ) : null}
    </div>
  );
};
