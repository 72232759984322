import { useHistory } from 'react-router-dom';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { createColorPalette } from '@api/blocks';
import { ColorSwatch } from '@api/index';
import { fileCardIdSet } from '@pages/FileCard/model';
import {
  allBoardImagesKeys,
  libraryKeys,
} from '@src/shared/constants/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useCreatePalette = () => {
  const history = useHistory();

  const queryClient = useQueryClient();

  const { mutate: createPalette } = useMutation({
    mutationFn: (payload: {
      swatches: ColorSwatch[];
      board?: string;
      name?: string;
    }) => {
      return createColorPalette(payload);
    },
    onMutate: () => {
      startedSnack({
        label: 'Creating palette...',
        close: true,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: libraryKeys.all,
      });

      if (data.data.boards.length > 0) {
        queryClient.invalidateQueries({
          queryKey: allBoardImagesKeys.all,
        });
      }

      startedSnack({
        label: 'Created new palette',
        action: {
          label: 'Go to file',
          action: () => {
            history.push(`#/block/${data.data.id}`);
            // TODO
            fileCardIdSet(data.data.id);
          },
        },
        close: true,
      });
    },
    onError: (_, payload) => {
      startedSnack({
        label: "Couldn't create palette",
        action: {
          label: 'Try again',
          action: () => {
            createPalette(payload);
          },
        },
        close: true,
      });
    },
  });

  return { createPalette };
};
