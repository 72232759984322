import { useState } from 'react';

import cn from 'classnames';
import { motion } from 'framer-motion';

import UserIconBase from '@visualist/design-system/src/components/Avatar/UserIconBase';
import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import { StickyType } from '@entities/Stickies/types';
import { enabledStickyMode } from '@pages/FileCard/model';
import { useAppData } from '@src/AppContext';
import { hoveredSticky, selectedSticky } from '@src/entities/Stickies/model';
import { StickyDropDown } from '@src/entities/Stickies/StickyDropDown';
import { StickyFooter } from '@src/entities/Stickies/StickyFooter';
import { StickyHeader } from '@src/entities/Stickies/StickyHeader';
import { useStickyAction } from '@src/entities/Stickies/useStickyAction';

import styles from './styles.module.css';

type Thumbnail = {
  type: 'thumbnail';
  sticky: StickyType;
  index: number;
  blockId: string;
};

type Placeholder = {
  type: 'placeholder';
  blockId: string;
  addPlaceholderSticky: () => void;
};

type StickyCardProps = Thumbnail | Placeholder;

export const StickyCard = (props: StickyCardProps) => {
  const { user } = useAppData();

  if (props.type === 'placeholder') {
    return (
      <motion.button
        // TODO make layout animatoin work
        // layoutId="new-sticky"
        className={cn(styles.container, styles.placeholderContainer)}
        onClick={props.addPlaceholderSticky}
        initial={{
          scale: 0.8,
          opacity: 0,
        }}
        animate={{
          scale: 1,
          opacity: 1,
        }}
      >
        <div className={styles.placeholderHeader}>
          <UserIconBase
            photo={user.photo}
            firstName={user.first_name}
            lastName={user.last_name}
            size={20}
            fontSize={8}
          />
          <TypographyPoppins type="label" labelSize="XS">
            <span className={styles.name}>{user.first_name}</span>
          </TypographyPoppins>
        </div>
        <div className={styles.content}>
          <TypographyPoppins type="body" bodySize="S">
            <span className={styles.placeholderText}>Add a sticky note</span>
          </TypographyPoppins>
        </div>
        <div style={{ height: 10 }} />
      </motion.button>
    );
  }

  // Render nothing if it's a placeholder and we are trying to add a new sticky
  if (props.sticky.id === 'new-sticky')
    return <motion.div className={styles.emptyPlaceholder} />;

  // Disable framer motion for first two cards
  return <MainCard {...props} />;
};

const MainCard = (props: Thumbnail) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { createStickyAction, deleteStickyAction, undoDeleteStickyAction } =
    useStickyAction({ imageId: props.blockId });

  const { action_sticky } = props.sticky;
  const isActionable = !!action_sticky && !action_sticky.is_removed;

  const toggleActionable = () => {
    if (isActionable) {
      deleteStickyAction.mutate(action_sticky.id);
    } else {
      selectedSticky(props.sticky.id);
      // If sticky is new, we need to create it first else undo the previous delete
      if (action_sticky) {
        undoDeleteStickyAction.mutate(action_sticky.id);
        return;
      }
      createStickyAction.mutate({
        stickyId: props.sticky.id,
        status: 'open',
      });
    }
  };

  const firstTwo = props.index === 0 || props.index === 1;

  return (
    <motion.button
      initial={
        firstTwo
          ? undefined
          : {
              scale: 0.8,
              opacity: 0,
            }
      }
      animate={{
        scale: 1,
        opacity: 1,
        transition: {
          type: 'spring',
          bounce: 0.5,
          delay: props.index * 0.05,
        },
      }}
      onHoverStart={() => {
        hoveredSticky(props.sticky.id);
      }}
      onHoverEnd={() => {
        hoveredSticky(null);
      }}
      transition={{
        type: 'tween',
        ease: [0.2, 0, 0, 1],
        delay: props.index * 0.05,
      }}
      style={{
        backgroundColor: props.sticky.background_hex,
      }}
      onClick={() => {
        selectedSticky(props.sticky.id);
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        setShowDropdown((v) => !v);
        enabledStickyMode();
      }}
      className={styles.container}
    >
      <StickyHeader
        createdBy={props.sticky.created_by}
        createdAt={props.sticky.created_at}
        isActionable={isActionable}
      />
      <div className={styles.content}>
        <TypographyPoppins type="body" bodySize="S" className={styles.text}>
          {props.sticky.text}
        </TypographyPoppins>
      </div>
      <StickyFooter
        isSelected={false}
        numberOfReplies={props.sticky.comment_count}
        setShowDropdown={() => {}}
      />
      <StickyDropDown
        sticky={props.sticky}
        blockId={props.blockId}
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        isActionable={isActionable}
        toggleActionable={toggleActionable}
      />
    </motion.button>
  );
};
