import { useHistory, useLocation } from 'react-router';

import {
  IconButton,
  Tooltip,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { SMALL } from '@src/shared/constants/breakpoints';

import { themeUnselected } from '../color-themes/model/active-theme';
import {
  themeCreationCompleted,
  themePlaceholderCleared,
} from '../color-themes/model/create-theme';
import { fileCardIdUnset } from '../model';

import styles from './styles.module.css';

export const Close = () => {
  const history = useHistory();
  const location = useLocation();

  const { width } = useWindowSize();

  const close = () => {
    fileCardIdUnset();
    themeUnselected();
    themePlaceholderCleared();
    themeCreationCompleted();
    history.replace(location.pathname);
  };

  return (
    <Tooltip
      style={{
        minWidth: 'max-content',
        marginTop: '5px',
        right: width <= SMALL ? '5px' : 'unset',
      }}
      parameter={{
        type: 'plain',
        description: 'Close',
        position: width <= SMALL ? 'bottom' : 'left',
      }}
    >
      <IconButton
        type="unfilled"
        className={styles.iconButton}
        icon={<Icon name="sprite/x" className={styles.icon} />}
        onClick={close}
      />
    </Tooltip>
  );
};
