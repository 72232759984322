import { fetchUserMessages } from '@api/messaging';
import { messagesKeys } from '@src/shared/constants/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useUserMessages = () => {
  const messagesQuery = useQuery({
    queryKey: messagesKeys.all,
    queryFn: () => fetchUserMessages('0', '1'),
  });

  return {
    messagesQuery,
  };
};
