import cn from 'classnames';
import { AnimatePresence, motion, Variants } from 'framer-motion';

import {
  Checkbox,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { StickyAction, StickyType } from '@entities/Stickies/types';
import { capitalise } from '@pages/AccountPage/utils';
import { useAction } from '@pages/action-items/hooks/useAction';
import { AssigneeSearch } from '@pages/action-items/ui/Assignee';
import { getDateCommaSeparatedYear } from '@src/shared/utils/data';
import { UseMutationResult } from '@tanstack/react-query';

import styles from './styles.module.css';

export const ActionContainer = (props: {
  isEditing: boolean;
  action: StickyType['action_sticky'];
  updateAction: UseMutationResult<
    any,
    Error,
    {
      actionId: string;
      payload: Partial<Pick<StickyAction, 'status' | 'assigned_to'>>;
    },
    void
  >;
}) => {
  const { isEditing, action } = props;
  const { data: actionItem } = useAction(action?.id);
  const checked = actionItem?.status !== 'open';
  const isActionable = !!actionItem && !actionItem.is_removed;

  const ContainerVariants = {
    show: {
      height: 40,
      transition: {
        delay: 0.4,
        duration: 0.2,
      },
    },
    hide: {
      height: 0,
    },
  } as Variants;

  const ActionVariants = {
    show: {
      rotateX: 0,
      transition: {
        delay: 0.4,
        duration: 0.2,
      },
    },
    hide: {
      rotateX: 90,
    },
  } as Variants;

  return (
    <AnimatePresence>
      {isActionable && isEditing ? (
        // Container used to create spacing
        <motion.div
          variants={ContainerVariants}
          initial="hide"
          animate="show"
          exit="hide"
          className={styles.container}
        >
          {/* Actual action animated into the space create by container */}
          <motion.div
            variants={ActionVariants}
            initial="hide"
            animate="show"
            exit="hide"
            transition={{ type: 'spring', duration: 0.4, bounce: 0.2 }}
            style={
              {
                '--position': 'absolute',
              } as React.CSSProperties
            }
            data-checked={checked}
            className={styles.action}
          >
            <div className={styles.checkboxContainer}>
              <Tooltip
                parameter={{
                  type: 'plain',
                  description: `On ${getDateCommaSeparatedYear(
                    actionItem.completed_at,
                    'comprehensive',
                  )}`,
                  position: 'right',
                }}
                style={{
                  display: checked ? 'block' : 'none',
                  whiteSpace: 'nowrap',
                  top: '20px',
                }}
              >
                <Checkbox
                  onClick={(e) => {
                    e.stopPropagation();
                    props.updateAction.mutate({
                      actionId: actionItem.id,
                      payload: {
                        status: checked ? 'open' : 'done',
                      },
                    });
                  }}
                  icon={
                    <Icon
                      name="sprite/checkbox-filled"
                      size={30}
                      color="#99461C"
                    />
                  }
                  isSelected={checked}
                />
              </Tooltip>
              {checked ? (
                <TypographyPoppins
                  labelSize="S"
                  type="label"
                  className={styles.mainText}
                >
                  Marked done by{' '}
                  <span className={styles.userName}>
                    {capitalise(actionItem.marked_as_done_by?.first_name ?? '')}
                  </span>
                </TypographyPoppins>
              ) : (
                <TypographyPoppins
                  labelSize="S"
                  type="label"
                  className={styles.mainText}
                >
                  Mark as done
                </TypographyPoppins>
              )}
            </div>
            {checked ? null : (
              <div
                className={cn(styles.assignedContainer, {
                  [styles.hidden]: checked,
                })}
              >
                <TypographyPoppins
                  labelSize="S"
                  type="label"
                  className={styles.mainText}
                >
                  Assigned to
                </TypographyPoppins>
                <AssigneeSearch action={actionItem}>
                  <TypographyPoppins
                    labelSize="S"
                    type="label"
                    className={styles.secondaryText}
                  >
                    {actionItem.assigned_to &&
                    actionItem.assigned_to?.length > 1
                      ? `${actionItem.assigned_to[0].first_name} + ${
                          actionItem.assigned_to.length - 1
                        } more`
                      : actionItem.assigned_to &&
                        actionItem.assigned_to?.length > 0
                      ? actionItem.assigned_to[0].first_name
                      : 'anyone'}
                  </TypographyPoppins>
                </AssigneeSearch>
              </div>
            )}
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
