import { useUnit } from 'effector-react';

import { Dialog, Modal } from '@visualist/design-system/src/components/v2';

import {
  $stickyToDeleteId,
  selectedStickyToDelete,
} from '@src/entities/Stickies/model';
import useStickies from '@src/shared/queries/useStickies';

type Props = {
  blockId: string;
};

export const DeleteStickyModal = ({ blockId }: Props) => {
  const [stickyToDeleteId] = useUnit([$stickyToDeleteId]);

  const { mutateStickyDelete } = useStickies(blockId, stickyToDeleteId);

  const remove = () => {
    if (!stickyToDeleteId) return;
    mutateStickyDelete.mutate(
      { id: stickyToDeleteId, replies: 0 },
      {
        onSuccess: () => {
          selectedStickyToDelete(null);
        },
      },
    );
  };

  return (
    <Modal
      showModal={!!stickyToDeleteId}
      handleClose={() => selectedStickyToDelete(null)}
    >
      <Dialog
        headline="Delete sticky?"
        description="You're about to delete this sticky note and all replies in this
        thread."
        action={remove}
        actionLabel="Confirm"
        closeAction={() => selectedStickyToDelete(null)}
      />
    </Modal>
  );
};
