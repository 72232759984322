import { ReactNode } from 'react';

import cn from 'classnames';
import { motion } from 'framer-motion';

import styles from './styles.module.css';

const animationProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    duration: 0.2,
  },
};

type Props = {
  children: ReactNode;
  className?: string;
};

export const DialogContainer = ({ children, className }: Props) => {
  return (
    <motion.div {...animationProps} className={cn(styles.container, className)}>
      {children}
    </motion.div>
  );
};
