import {
  IconButton,
  Tooltip,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { downloadSelectedBlocksSingle } from '@api/blocks';
import { SMALL } from '@src/shared/constants/breakpoints';

import styles from './styles.module.css';

export const Download = ({ block, getFileType }: any) => {
  const { width } = useWindowSize();

  const download = async () => {
    if (block.file.full_size) {
      try {
        startedSnack({
          label: `Downloading image...`,
          close: true,
        });

        const blob = await downloadSelectedBlocksSingle(block.id);
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${block.id}.jpg`;
        link.click();

        startedSnack({
          label: `Downloaded ${getFileType()}`,
          close: true,
        });
      } catch (error) {
        startedSnack({
          label: `Couldn't download ${getFileType()}`,
          action: {
            label: 'Try again',
            action: () => {
              download();
            },
          },
          close: true,
        });
      }
    }
  };

  return (
    <Tooltip
      style={{
        minWidth: 'max-content',
        marginTop: width <= SMALL ? '5px' : '0px',
      }}
      parameter={{
        type: 'plain',
        description: 'Download',
        position: width <= SMALL ? 'bottom' : 'right',
      }}
    >
      <IconButton
        type="unfilled"
        className={styles.iconButton}
        icon={<Icon name="sprite/download" className={styles.icon} />}
        onClick={download}
      />
    </Tooltip>
  );
};
