import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { MutateOptions, useMutation } from '@tanstack/react-query';

import { InvitationHub, inviteToHub } from '../../../../../api/hubs';
import { queryClient } from '../../../../../queryClient';
import {
  MEMBERS_INVITATION_HUB_QUERY,
  MEMBERS_SUGGESTIONS_HUB_QUERY,
} from '../../../../../shared/constants/query-names';

export const useInviteToHub = (
  options?: MutateOptions<InvitationHub, any, InvitationHub>,
) => {
  const { mutate: sendInvitationHub } = useMutation({
    mutationFn: ({ hubId, users, message, permissions }: InvitationHub) =>
      inviteToHub({ hubId, users, message, permissions }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_INVITATION_HUB_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_SUGGESTIONS_HUB_QUERY],
      });

      startedSnack({
        label: `Invited them to ${variables.hubName}`,
        close: true,
      });

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      if (error.message.includes('406') && variables.setOn) {
        startedSnack({
          label: "You don't have guest seats left",
          close: true,
        });
        variables.setOn(false);
      } else {
        startedSnack({
          label: `Couldn't invite them to ${variables.hubName}`,
          action: {
            label: 'Try again',
            action: () => {
              sendInvitationHub({
                hubId: variables.hubId,
                users: variables.users,
                permissions: variables.permissions,
              });
            },
          },
          close: true,
        });
      }

      if (options?.onError) options?.onError(error, variables, context);
    },
  });

  return { sendInvitationHub };
};
