import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { ColorTheme, createColorSwatches } from '@api/blocks';
import { IMAGE_QUERY } from '@src/shared/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { themePlaceholderCleared } from '../../create-theme';

export const useCreateColor = (fileId: string) => {
  const queryClient = useQueryClient();

  const { mutate: createColor } = useMutation({
    mutationFn: (color: ColorTheme) => createColorSwatches(color),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [IMAGE_QUERY, { id: fileId }],
      });

      themePlaceholderCleared();
    },
    onError: (err, variables) => {
      startedSnack({
        label: "Couldn't add color",
        action: {
          label: 'Try again',
          action: () => {
            createColor({
              block: variables.block,
              theme: variables.theme,
              swatches: variables.swatches,
            });
          },
        },
        close: true,
      });
    },
  });

  return { createColor };
};
