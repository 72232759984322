import React, { ChangeEvent } from 'react';

import cn from 'classnames';

import styles from './styles.module.css';

export type SwitchProps = {
  onValueChange: (newValue: boolean) => void;
  value: boolean;
  disable?: boolean;
  icon?: React.ReactNode;
  sameColor?: boolean;
};

export const Switch = ({
  onValueChange,
  value,
  disable,
  sameColor,
  icon,
}: SwitchProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (disable) return;
    if (e.target) {
      onValueChange(e.target.checked);
    }
  };

  const toggle = () => {
    if (disable) return;
    onValueChange(!value);
  };

  return (
    <label className={styles.switch}>
      <input
        type="checkbox"
        className={cn(styles.input, {
          [styles.disabled]: disable,
          [styles.alwaysOnColor]: sameColor,
        })}
        checked={value}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            toggle();
          }
        }}
      />
      <span
        className={cn(styles.slider, {
          [styles.icon]: icon,
          [styles.off]: !value,
        })}
      >
        {/* TODO add icon variants here */}
      </span>
    </label>
  );
};
