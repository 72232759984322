import React from 'react';

import { AnimationSequence, motion, useAnimate } from 'framer-motion';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const PageLoader = ({
  isVai = false,
  description,
}: {
  isVai?: boolean;
  description?: string;
}) => {
  if (isVai) return <VaiLogo />;

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            initial={{ pathLength: 0, fill: 'rgb(255,255,255)', opacity: 1 }}
            animate={{ pathLength: 1, fill: 'rgba(0,0,0)', opacity: 1 }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.356.046c-.893.039-1.195.126-1.754.508-1.057.72-1.5 2.067-1.09 3.318.243.743 1.315 2.722 2.458 4.538l.266.423-.837 2.373c-2.158 6.12-3.586 9.937-4.052 10.834l-.1.193-.287-.607c-.55-1.168-1.127-2.63-4.753-12.056L4.554.073 2.427.059.3.045.611.28c.76.572 1.47 1.453 2.097 2.606.63 1.157.55.955 5.086 12.757.637 1.656 1.202 3.176 1.256 3.377.077.283.098.541.095 1.156-.002.706-.018.842-.147 1.266-.262.86-.72 1.577-1.415 2.211L7.202 24h7.195l.034-.145c.626-2.69 2.55-8.54 5.376-16.343.368-1.015.894-2.472 1.17-3.238.589-1.637.829-2.207 1.106-2.626.305-.462.883-1.044 1.334-1.345.22-.147.41-.276.422-.288.034-.033-4.624-.007-5.483.03zm.706.756c.635.29 1.037.846 1.074 1.483.018.313-.018.53-.221 1.35-.219.876-1.05 3.477-1.213 3.79-.061.119-.083.093-.452-.527-.494-.834-1.414-2.625-1.656-3.227-.267-.665-.33-1.009-.255-1.41.146-.783.654-1.36 1.38-1.565.366-.104.992-.055 1.343.106z"
            stroke="black"
          ></motion.path>
        </svg>
        {description && (
          <TypographyPoppins
            type="body"
            bodySize="M"
            className={styles.textNeutral}
          >
            {description}
          </TypographyPoppins>
        )}
      </div>
    </div>
  );
};

const VaiLogo = () => {
  const pathRef = React.useRef<SVGPathElement | null>(null);
  const squareRef = React.useRef<SVGRectElement | null>(null);
  const circleRef = React.useRef<SVGCircleElement | null>(null);
  const starRef = React.useRef<SVGPathElement | null>(null);

  const [, animate] = useAnimate();

  React.useEffect(() => {
    if (!pathRef.current) return;

    const sequence = [
      [
        pathRef.current,
        {
          pathLength: [0, 1],
        },
        {
          duration: 2,
        },
      ],
      [
        pathRef.current,
        {
          opacity: [0, 1],
        },
        {
          duration: 0.3,
          at: 0,
        },
      ],
      [
        circleRef.current,
        {
          scale: [0.2, 1],
          opacity: [0, 1],
        },
        {
          at: 0.5,
          type: 'spring',
        },
      ],
      [
        squareRef.current,
        {
          scale: [0.2, 1],
          opacity: [0, 1],
        },
        {
          at: 0.7,
          type: 'spring',
        },
      ],
      [
        starRef.current,
        {
          scale: [0.2, 1],
          opacity: [0, 1],
        },
        {
          at: 1.7,
          type: 'spring',
        },
      ],
    ] as AnimationSequence;

    animate(sequence, {
      repeat: Infinity,
      repeatType: 'reverse',
    });
  }, []);

  return (
    <div className={styles.vaiContainer}>
      <svg
        width="64"
        height="64"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Ring */}
        <path
          ref={pathRef}
          d="M10.5 7C4.90063 4.93087 2.34658 6.46956 2.01908 7.54076C1.64514 8.47749 1.61552 10.9159 4.48856 13.1755C6.49237 14.9503 11.5 18.8 15.5 20C19.5 21.2 21.1667 20.1667 21.5 19.5C22.3333 18.5 22.6 15.5 17 11.5"
          stroke="url(#paint1_linear_0_5)"
          strokeLinecap="round"
          fill="none"
        />
        {/* Star */}
        <motion.path
          ref={starRef}
          d="M12.8403 7.9407L8.56248 9L12.8403 10.1926L14.0217 14.5L15.2847 10.1926L19.5625 9L15.2847 7.9407L14.0625 3.5L12.8403 7.9407Z"
          fill="url(#paint0_linear_0_5)"
          stroke="#6B1888"
          strokeLinecap="square"
        />
        {/* Rectangle */}
        <motion.rect
          ref={squareRef}
          x="14.0625"
          y="18"
          width="4"
          height="4"
          fill="url(#paint2_linear_0_5)"
          stroke="#6B1888"
        />
        {/* Circle */}
        <motion.path
          ref={circleRef}
          d="M6.5 17.5C4.84315 17.5 3.5 16.1569 3.5 14.5C3.5 12.8431 4.84315 11.5 6.5 11.5C8.15685 11.5 9.5 12.8431 9.5 14.5C9.5 16.1569 8.15685 17.5 6.5 17.5Z"
          fill="url(#paint3_linear_0_5)"
          stroke="#6B1888"
        />
        <defs>
          <linearGradient
            id="paint0_linear_0_5"
            x1="14.0625"
            y1="3.5"
            x2="14.0625"
            y2="14.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE2B5" />
            <stop offset="0.5" stopColor="#E2C9EB" />
            <stop offset="1" stopColor="#B3D4C1" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_0_5"
            x1="11.8979"
            y1="5.97792"
            x2="11.8979"
            y2="20.5519"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE2B5" />
            <stop offset="0.5" stopColor="#E2C9EB" />
            <stop offset="1" stopColor="#B3D4C1" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_0_5"
            x1="16.0625"
            y1="17.5"
            x2="16.0625"
            y2="22.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE2B5" />
            <stop offset="0.5" stopColor="#E2C9EB" />
            <stop offset="1" stopColor="#B3D4C1" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_0_5"
            x1="9.56248"
            y1="16"
            x2="2.56248"
            y2="16"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE2B5" />
            <stop offset="0.5" stopColor="#E2C9EB" />
            <stop offset="1" stopColor="#B3D4C1" />
          </linearGradient>
        </defs>
      </svg>
      <TypographyPoppins type="body" bodySize="S" className={styles.text}>
        Performing magic...
      </TypographyPoppins>
    </div>
  );
};
