import { Icon, IconProps } from './Icon';

export const PaletteColored = (props: IconProps) => {
  return (
    <Icon {...props}>
      <rect x="4" y="15" width="3" height="5" fill="#B3D4C1" />
      <mask
        id="mask0_16826_7307"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="4"
        width="13"
        height="17"
      >
        <path d="M16.5 4L8 12.5V21H21V16H12L20 8L16.5 4Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_16826_7307)">
        <path
          d="M3.70709 17.7279L15.7279 5.70711L18.2028 8.18198C18.398 8.37724 18.398 8.69383 18.2028 8.88909L6.53552 20.5563L3.70709 17.7279Z"
          stroke="black"
        />
      </g>
      <mask
        id="mask1_16826_7307"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="15"
        width="13"
        height="6"
      >
        <path
          d="M21 16H12V15.5L8 19.5V21H20C20.5523 21 21 20.5523 21 20V16Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask1_16826_7307)">
        <path d="M3.5 16.5H20.5V20.5H3.5V16.5Z" stroke="black" />
      </g>
      <path
        d="M3.5 20.5V3.5H7C7.27614 3.5 7.5 3.72386 7.5 4V20.5H3.5Z"
        stroke="black"
      />
      <path
        d="M5 19V18C5.8 18 6 18.6667 6 19H5Z"
        fill="black"
        stroke="black"
        strokeWidth="0.8"
      />
      <path d="M7.5 14.833H3.5" stroke="black" strokeWidth="0.8" />
      <path d="M7.5 9.16699H3" stroke="black" strokeWidth="0.8" />
      <rect x="4" y="4" width="3" height="5" fill="#FFE2B5" />
      <rect x="4" y="9.5" width="3" height="5" fill="#E2C9EB" />
    </Icon>
  );
};
