import { createContext, useContext } from 'react';

import { Centrifuge } from 'centrifuge';

export const CentrifugeContext = createContext<Centrifuge | undefined>(
  undefined,
);

/**
 * @returns socket - Centrifuge socket instance to send messages
 */

export const useSocket = () => {
  const socket = useContext(CentrifugeContext);

  if (!socket) {
    throw Error('Need centrifuge instance');
  }

  return socket;
};
