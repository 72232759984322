import { format } from 'date-fns';

import { HubCreditProduct } from '@api/billing';

import { Plan } from './components/types';

export const generateCurrentUrl = () => {
  const url = new URL(window.location.href);

  return `${url.origin}${url.pathname}/`;
};

export const generateCurrentPathURL = () => {
  const url = new URL(window.location.href);

  return removeTrailingSlash(`${url.pathname}`);
};

export const removeStatusFromURL = (url: string) => {
  const urlParts = removeTrailingSlash(url).split('/');

  // Check if the last part is "success" or "failed"
  const lastPart = urlParts[urlParts.length - 1];
  if (lastPart === 'success' || lastPart === 'failed') {
    // Remove the last part ("success" or "failed")
    urlParts.pop();
  }

  const modifiedURL = urlParts.join('/');

  return removeTrailingSlash(`${modifiedURL}`);
};

const removeTrailingSlash = (url: string) =>
  url.endsWith('/') ? url.slice(0, -1) : url;

export const formatAnnualPrice = (n: number, isAnnual: boolean) => {
  return isAnnual ? n / 100 / 12 : n / 100;
};

export const capitalise = (s: string | undefined | null) => {
  if (!s) return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }

  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const generateNextBillingCopy = ({
  billingDate,
  currentPlan,
  selectedPlan,
  isAnnual,
  amount,
}: {
  billingDate: Date;
  currentPlan: Plan;
  selectedPlan: Plan;
  isAnnual: boolean;
  amount: {
    monthly: number;
    annually: number;
  };
}) => {
  const date = format(billingDate, 'MMMM d, yyyy');

  if (
    (currentPlan === 'pro' || currentPlan === 'starter') &&
    selectedPlan === 'free'
  ) {
    // Switching to free from pro or hobby
    return `From ${date}, you’ll be switched to the Free plan and no longer be charged.`;
  }

  if (currentPlan === 'pro' && selectedPlan === 'starter') {
    return `Your changes will take effect on your next billing date of ${date}`;
  }

  // Changes amount whether free -> pro or hobby -> pro by adding hobby cost
  const annualAmount =
    currentPlan === 'free' ? amount.annually * 12 : (amount.annually - 4) * 12;

  return `You’ll be charged $${
    isAnnual ? annualAmount : amount.monthly
  } today, ${
    isAnnual
      ? `and your next bill will be on ${date}.`
      : `and then monthly on the ${billingDate.getDay()}${getDaySuffix(
          billingDate.getDay(),
        )}.`
  }`;
};

// Filter from hub credit bundle the 1 credit bundle
export const sortHubBundles = (a: HubCreditProduct, b: HubCreditProduct) => {
  return a.unit_price - b.unit_price;
};

export const filterOneCreditPack = (product: HubCreditProduct) => {
  if (
    product.lookup_key === 'credit_pack_1' ||
    product.lookup_key === 'credit_pack_10'
  ) {
    return false;
  }
  return true;
};

export const saveValueTolocalStorage = (key: string, value: string) => {
  // TODO error handling for if local storage cannot be set.
  localStorage.setItem(key, JSON.stringify(value));
};

export const readValueFromLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? value : null;
};

export const readValueFromLocalStorageAndDelete = (key: string) => {
  const value = localStorage.getItem(key);
  localStorage.removeItem(key);
  return value ? value : null;
};
