import { AnimatePresence, motion } from 'framer-motion';

import {
  AssistChip,
  Switch,
  TeamIcon,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { BoardShare } from '@pages/BoardPage';

import styles from './styles.module.css';

interface PublicLinkProps {
  handlePublishChange: (
    published: boolean,
    allow_comments?: boolean,
  ) => Promise<void>;
  boardShare: BoardShare | null;
  isBoardInHub: boolean;
}

export const Links = ({
  handlePublishChange,
  boardShare,
  isBoardInHub,
}: PublicLinkProps) => {
  const copyPublicLink = async () => {
    try {
      if (boardShare) {
        await navigator.clipboard.writeText(boardShare.link);
        startedSnack({
          label: 'Copied link',
          close: true,
        });
      }
    } catch (error) {
      startedSnack({
        label: "Oops, could'nt copy this",
        action: {
          label: 'Try again',
          action: () => {
            copyPublicLink();
          },
        },
        close: true,
      });
    }
  };

  const copyPrivateLink = async () => {
    const url = new URL(document.location.href);

    try {
      await navigator.clipboard.writeText(url.href);
      startedSnack({
        label: 'Copied link',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link",
        action: {
          label: 'Try again',
          action: () => {
            copyPrivateLink();
          },
        },
        close: true,
      });
    }
  };

  return (
    <div className={styles.links}>
      <div className={styles.copyPublicLinkContainer}>
        <div className={styles.row}>
          <div className={styles.publicTitleContainer}>
            <Icon name="sprite/public" className={styles.urlIcon} />
            <TypographyPoppins type="body" size="L">
              <span className={styles.publicLabel}>Share link with anyone</span>
            </TypographyPoppins>
            <Tooltip
              style={{ minWidth: '222px', left: '-38px' }}
              parameter={{
                type: 'plain',
                description: 'Anyone on the internet with this link can view',
                hasVisualBoundary: false,
                position: 'bottom',
              }}
            >
              <Icon
                name="sprite/information"
                size={16}
                className={styles.infoIcon}
              />
            </Tooltip>
          </div>
          <Switch
            onValueChange={handlePublishChange}
            value={boardShare?.link ? true : false}
          />
        </div>
        <motion.div
          className={styles.row}
          animate={{ height: boardShare?.link ? 'auto' : 0 }}
          exit={{ height: 0 }}
        >
          <AnimatePresence>
            {boardShare?.link ? (
              <AssistChip onClick={copyPublicLink}>Copy public link</AssistChip>
            ) : null}
          </AnimatePresence>
        </motion.div>

        <div className={styles.row}>
          <div className={styles.publicTitleContainer}>
            <TeamIcon className={styles.teamIcon} />
            <TypographyPoppins type="body" size="L">
              <span className={styles.publicLabel}>
                Share link with invited guests
              </span>
            </TypographyPoppins>
            <Tooltip
              style={{ minWidth: '246px', left: '-102px' }}
              parameter={{
                type: 'plain',
                description:
                  'Only those invited can see this page when they log in',
                hasVisualBoundary: false,
                position: 'bottom',
              }}
            >
              <Icon
                name="sprite/information"
                size={16}
                className={styles.infoIcon}
              />
            </Tooltip>
          </div>
        </div>
        <div className={styles.row}>
          {isBoardInHub ? (
            <AssistChip style="outlined" onClick={copyPrivateLink}>
              Copy private link
            </AssistChip>
          ) : (
            <Tooltip
              style={{ minWidth: 'max-content', left: '49px' }}
              parameter={{
                type: 'plain',
                description: 'Add the board to a hub to invite others',
                hasVisualBoundary: false,
                position: 'top',
              }}
            >
              <AssistChip style="outlined" disabled={!isBoardInHub}>
                Copy private link
              </AssistChip>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
