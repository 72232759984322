import { createEvent, createStore, sample } from 'effector';

import { $currentState, $palette } from '../../palette/model';

// Trigger that the palette has been changed
export const paletteSaved = createEvent();

export const $isPaletteModified = createStore(false);

$isPaletteModified.reset(paletteSaved);

sample({
  clock: $palette,
  source: { currentState: $currentState },
  fn: ({ currentState }, palette) => {
    return (
      JSON.stringify(currentState.swatches) !== JSON.stringify(palette.swatches)
    );
  },
  target: $isPaletteModified,
});

// Prompt dialog
export const promptDialogOpened = createEvent();
export const promptDialogClosed = createEvent();

export const $isOpenPromptDialog = createStore(false);

$isOpenPromptDialog
  .on(promptDialogOpened, () => true)
  .reset(promptDialogClosed);

// Palette options
export const adjustPaletteSelected = createEvent();
export const colorCodesSelected = createEvent();
export const resetAllOptions = createEvent();

export const $isAdjustPalette = createStore(true);
export const $isColorCodes = createStore(false);

// Select adjust palette
sample({
  clock: adjustPaletteSelected,
  fn: () => true,
  target: $isAdjustPalette,
});

// Select adjust palette and deselect the color codes
sample({
  clock: adjustPaletteSelected,
  fn: () => false,
  target: $isColorCodes,
});

// Select color codes
sample({
  clock: colorCodesSelected,
  fn: () => true,
  target: $isColorCodes,
});

// Select color codes and deselect adjust palette
sample({
  clock: colorCodesSelected,
  fn: () => false,
  target: $isAdjustPalette,
});

sample({
  clock: resetAllOptions,
  fn: () => false,
  target: [$isAdjustPalette, $isColorCodes],
});
