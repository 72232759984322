import { useUnit } from 'effector-react';

import { SegmentedButton } from '@visualist/design-system/src/components/v2';

import { $filters, resetFilters, toggleFilterType } from '../../model';

import styles from './styles.module.css';

export const SearchFilters = () => {
  const [filters] = useUnit([$filters]);

  return (
    <div className={styles.container}>
      <SegmentedButton
        isSelected={filters.allSelected()}
        start
        onClick={() => resetFilters()}
        label="All"
      />
      <SegmentedButton
        isSelected={filters.files.has('hub')}
        onClick={() => toggleFilterType('hub')}
        label="Hubs"
      />
      <SegmentedButton
        isSelected={filters.files.has('board')}
        onClick={() => toggleFilterType('board')}
        label="Boards"
      />
      <SegmentedButton
        isSelected={filters.files.has('doc')}
        onClick={() => toggleFilterType('doc')}
        label="Docs"
      />
      <SegmentedButton
        isSelected={filters.files.has('image')}
        onClick={() => toggleFilterType('image')}
        label="Images"
      />
      <SegmentedButton
        isSelected={filters.files.has('design')}
        onClick={() => toggleFilterType('design')}
        label="Designs"
      />
      <SegmentedButton
        end
        isSelected={filters.files.has('palette')}
        onClick={() => toggleFilterType('palette')}
        label="Palettes"
      />
    </div>
  );
};
