import { ReactNode } from 'react';

import cn from 'classnames';

import { Icon } from '@visualist/icons';

import { Avatar } from '../Avatar';
import TypographyPoppins, {
  BodySize,
  HeadlineSize,
  LabelSize,
  Size,
  Variant,
} from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

type Style = 'outlined' | 'filled';

export interface InputChipProps {
  style: Style;
  leadingIcon?: ReactNode;
  trailingIcon?: boolean;
  avatar?: string;
  onClick?: () => void;
  children: ReactNode;
  className?: string;
  classNameLabel?: string;

  type: Variant;
  size?: Size;
  labelSize?: LabelSize;
  headlineSize?: HeadlineSize;
  titleSize?: Size;
  bodySize?: BodySize;
}

export const InputChip = ({
  style,
  leadingIcon,
  trailingIcon,
  avatar,
  onClick,
  children,
  className,
  classNameLabel,

  type,
  size,
  labelSize,
  headlineSize,
  titleSize,
  bodySize,
}: InputChipProps) => {
  return (
    <button
      className={cn(styles.chip, className, {
        [styles.outlined]: style === 'outlined',
        [styles.filled]: style === 'filled',
        [styles.withLeadingIcon]: leadingIcon || avatar,
        [styles.withTrailingIcon]: trailingIcon,
      })}
    >
      {leadingIcon && !avatar && (
        <span className={styles.leadingIcon}>{leadingIcon}</span>
      )}

      {avatar && (
        <span className={styles.avatar}>
          <Avatar image={avatar} size={24} />
        </span>
      )}

      <TypographyPoppins
        className={cn(styles.label, classNameLabel, {
          [styles.labelFilled]: style === 'filled',
        })}
        type={type}
        labelSize={labelSize}
        bodySize={bodySize}
        headlineSize={headlineSize}
        size={size}
        titleSize={titleSize}
      >
        {children}
      </TypographyPoppins>

      {trailingIcon && (
        <button
          className={cn(styles.trailingIcon, {
            [styles.trailingIconFilled]: style === 'filled',
          })}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <Icon
            name="sprite/x"
            size={16}
            className={cn(styles.closeIcon, {
              [styles.closeIconFilled]: style === 'filled',
            })}
          />
        </button>
      )}
    </button>
  );
};
