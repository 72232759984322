import { useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { NodeSelection } from 'prosemirror-state';
import { usePopper } from 'react-popper';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { Node } from '@tiptap/core';
import { TextSelection } from '@tiptap/pm/state';
import {
  mergeAttributes,
  NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from '@tiptap/react';

import ImageToolbar from '../../ui/imageToolbar';
import {
  ANGLE_FOURTY_FIVE,
  ANGLE_FOURTY_FOUR,
  ANGLE_NINETY,
  ANGLE_ONE_EIGHTY,
  ANGLE_ONE_FIFTY_SEVEN_DOT_SIX,
  ANGLE_ONE_ONE_TWO_DOT_FIVE,
  ANGLE_ONE_THIRTY_FIVE,
  ANGLE_ONE_THIRTY_FOUR,
  ANGLE_SIXTY_SEVEN_DOT_SIX,
  ANGLE_THREE_ONE_FIVE,
  ANGLE_THREE_ONE_FOUR,
  ANGLE_THREE_THIRTY_SEVEN_DOT_SIX,
  ANGLE_TWENTY_TWO_DOT_FIVE,
  ANGLE_TWO_FOURTY_SEVEN_DOT_SIX,
  ANGLE_TWO_NINETY_TWO_DOT_FIVE,
  ANGLE_TWO_SEVENTY,
  ANGLE_TWO_TWENTY_FIVE,
  ANGLE_TWO_ZERO_TWO_DOT_FIVE,
  ANGLE_ZERO,
  HANDLE_BOTTOM,
  HANDLE_BOTTOM_LEFT,
  HANDLE_BOTTOM_RIGHT,
  HANDLE_LEFT,
  HANDLE_RIGHT,
  HANDLE_TOP,
  HANDLE_TOP_LEFT,
  HANDLE_TOP_RIGHT,
  MIN_WIDTH,
  ROTATION_ANGLE_LIMIT,
} from './constants';
import { getDirection, getRotatedDeltaX, getRotatedDeltaY } from './utils';

import fileStyles from './styles.module.css';

export interface FileOptions {
  /**
   * Controls if the image node should be inline or not.
   * @default false
   * @example true
   */
  inline: boolean;

  /**
   * Controls if base64 images are allowed. Enable this if you want to allow
   * base64 image urls in the `src` attribute.
   * @default false
   * @example true
   */
  allowBase64: boolean;

  /**
   * HTML attributes to add to the image element.
   * @default {}
   * @example { class: 'foo' }
   */
  HTMLAttributes: Attributes;
}

type Attributes = {
  src: string;
  alt?: string;
  fileId: string;
  fileType: string;
  height: number;
  width: number;
  aspectRatio: number;
  actualHeight: number;
  actualWidth: number;
  textWrap?: string;
  top?: number;
  left?: number;
  zIndex?: number;
  rotation?: number;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    file: {
      /**
       * Add an image
       * @param options The image attributes
       * @example
       * editor
       *   .commands
       *   .setImage({ src: 'https://tiptap.dev/logo.png', alt: 'tiptap', title: 'tiptap logo' })
       */
      setFile: (options: {
        src: string;
        alt?: string;
        fileId: string;
        fileType: string;
        height: number;
        width: number;
        aspectRatio: number;
        actualHeight: number;
        actualWidth: number;
        textWrap?: string;
        top?: number;
        left?: number;
        zIndex?: number;
        rotation?: number;
      }) => ReturnType;
    };
  }
}

export default Node.create<FileOptions>({
  name: 'FileComponent',

  addOptions() {
    return {
      inline: true,
      allowBase64: false,
      HTMLAttributes: {
        fileId: '',
        fileType: '',
        height: 0,
        width: 0,
        aspectRatio: 0,
        actualHeight: 0,
        actualWidth: 0,
        src: '',
        top: 0,
        left: 0,
        zIndex: 0,
        rotation: 0,
      },
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? 'inline' : 'block';
  },

  draggable: true,

  addAttributes() {
    return {
      height: {
        default: 0,
      },
      width: {
        default: 0,
      },
      aspectRatio: {
        default: 0,
      },
      actualHeight: {
        default: 0,
      },
      actualWidth: {
        default: 0,
      },
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      fileId: {
        default: '',
      },
      fileType: {
        default: 'image',
      },
      textWrap: {
        default: 'inline',
      },
      top: {
        default: 0,
      },
      left: {
        default: 0,
      },
      zIndex: {
        default: 0,
      },
      rotation: {
        default: 0,
      },
    };
  },

  // To add content at head of selection: .insertContentAt(this.editor.state.selection.head

  parseHTML() {
    return [
      {
        tag: 'img',
        getAttrs: (node) => node.hasAttribute('fileid') && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { textWrap, top, left, rotation, zIndex } = HTMLAttributes;
    const spanStyle = `
    display: ${textWrap === 'inline' ? 'inline' : ''};
    position: ${
      ['front', 'behind'].includes(textWrap) ? 'absolute' : 'relative'
    };
    top: ${top}px;
    left: ${left}px;
    float: ${
      textWrap === 'square'
        ? 'left'
        : textWrap === 'squareRight'
        ? 'right'
        : 'none'
    };
    margin-right: ${textWrap === 'square' ? '8px' : '0px'};
    margin-left: ${textWrap === 'squareRight' ? '8px' : '0px'};
    padding: ${textWrap === 'inline' ? '0 8px' : '0px'};
    z-index: ${zIndex};
    transform: rotate(${rotation}deg);`;
    const imgStyle = `transform: rotate(${rotation}deg);`;

    return [
      'span',
      { style: spanStyle },
      [
        'img',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
          class: textWrap,
          style: imgStyle,
        }),
      ],
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});

const Component = (props: NodeViewProps) => {
  const { node, updateAttributes, getPos, editor, selected } = props;
  const [width, setWidth] = useState(node.attrs.width);
  const [height, setHeight] = useState(node.attrs.height);
  const [rotation, setRotation] = useState(node.attrs.rotation || 0);
  const imgRef = useRef<HTMLImageElement>(null);
  const editorRef = editor.view.dom as HTMLElement | null;
  const [isResizing, setIsResizing] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [initialRotation, setInitialRotation] = useState(0);
  const [initialAngle, setInitialAngle] = useState(0);
  const [initialMousePosition, setInitialMousePosition] = useState({
    x: 0,
    y: 0,
  });
  const [initialSize, setInitialSize] = useState({ width: 0, height: 0 });
  const [activeHandle, setActiveHandle] = useState<string>('');
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  });
  const dataAspectRatio =
    node.attrs.aspectRatio && node.attrs.aspectRatio > 0
      ? node.attrs.aspectRatio
      : node.attrs.width > 0 && node.attrs.height > 0
      ? parseFloat((node.attrs.height / node.attrs.width).toFixed(2))
      : 1;
  const MAX_WIDTH = editor.view.dom.clientWidth - 192; // set allowed maximum width (192 is margin 1in on both sides)

  const isAppleOS =
    typeof navigator !== 'undefined' &&
    (/Mac/.test(navigator.platform) ||
      (/AppleWebKit/.test(navigator.userAgent) &&
        /Mobile\/\w+/.test(navigator.userAgent)));

  const isOnlyImageSelected = () => {
    const { $from, $to } = editor.view.state.selection;
    return (
      editor.view.state.doc.nodeAt($from.pos) ===
        editor.view.state.doc.nodeAt($to.pos - 1) && selected
    );
  };

  const isSelected = isOnlyImageSelected();

  const handleTextWrap = (
    textWrap:
      | 'inline'
      | 'square'
      | 'squareRight'
      | 'breakText'
      | 'front'
      | 'behind',
  ) => {
    let newTop = 0;
    let newLeft = 0;
    let zIndex = 0;
    if (textWrap === 'front' || textWrap === 'behind') {
      const imgElement = imgRef.current;
      if (imgElement) {
        const rect = imgElement.getBoundingClientRect();
        const editorRect = editor.view.dom.getBoundingClientRect();
        newTop = rect.top - editorRect.top + editor.view.dom.scrollTop;
        newLeft = rect.left - editorRect.left + editor.view.dom.scrollLeft;
      }
    }
    if (textWrap === 'front') {
      zIndex = 3;
    }
    if (textWrap === 'behind') {
      zIndex = -1;
      startedSnack({
        label: `The object is now moved behind text. To select it again, hold the ${
          isAppleOS ? 'Option' : 'Alt'
        } key.`,
        close: true,
        delayTime: 8500,
      });
    }
    updateAttributes({
      textWrap,
      top: newTop,
      left: newLeft,
      zIndex: zIndex,
    });
    if (textWrap === 'breakText') {
      editor.view.focus();
    }
  };

  useEffect(() => {
    if (isSelected && imgRef.current) {
      setReferenceElement(imgRef.current);
      setWidth(node.attrs.width);
      setHeight(node.attrs.height);
    }
  }, [isSelected, node.attrs.width, node.attrs.height]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Alt' && node.attrs.textWrap === 'behind') {
        updateAttributes({
          zIndex: 0, // Allow interaction with image
        });
        if (isSelected) {
          const pos = getPos();
          const tr = editor.view.state.tr.setSelection(
            NodeSelection.create(editor.view.state.doc, pos),
          );
          editor.view.dispatch(tr);
        }
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Alt' && node.attrs.textWrap === 'behind') {
        updateAttributes({
          zIndex: -1, // Prevent interaction with image
        });
        if (isSelected) {
          const pos = getPos();
          const tr = editor.view.state.tr.setSelection(
            NodeSelection.create(editor.view.state.doc, pos),
          );
          editor.view.dispatch(tr);
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [node.attrs.textWrap, updateAttributes, isSelected]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const editorRect = editor.view.dom.getBoundingClientRect();
      const images = editor.view.dom.querySelectorAll('img'); // Get all images in the editor

      images.forEach((imgElement) => {
        const rect = imgElement.getBoundingClientRect();

        // Calculate the new top and left if the image is outside the editor's boundaries
        let newTop = rect.top - editorRect.top + editor.view.dom.scrollTop;
        let newLeft = rect.left - editorRect.left + editor.view.dom.scrollLeft;
        let updateRequired = false;

        // Check if the image is beyond the editor's boundaries
        if (rect.right > editorRect.right) {
          newLeft = editorRect.width - rect.width;
          updateRequired = true;
        }

        if (rect.bottom > editorRect.bottom) {
          newTop = editorRect.height - rect.height;
          updateRequired = true;
        }

        if (updateRequired) {
          const pos = editor.view.posAtDOM(imgElement, 0);
          editor.view.dispatch(
            editor.view.state.tr.setNodeMarkup(pos, undefined, {
              ...editor?.view?.state?.doc?.nodeAt(pos)?.attrs,
              top: newTop,
              left: newLeft,
            }),
          );
        }
      });
    });

    if (editorRef) {
      resizeObserver.observe(editorRef);
    }

    return () => {
      if (editorRef) {
        resizeObserver.unobserve(editorRef);
      }
    };
  }, [editorRef, editor]);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isResizing && imgRef.current) {
        // If image is being resized
        // Calculate mouse movement deltas
        const deltaX = e.clientX - initialMousePosition.x;
        const deltaY = e.clientY - initialMousePosition.y;
        let newWidth = initialSize.width;
        let newHeight = initialSize.height;
        const isAspectRatioBelowOne = dataAspectRatio < 1; // Determine if aspect ratio of image is less than one
        const isRotationInverted =
          rotation > ANGLE_FOURTY_FIVE && rotation < ANGLE_THREE_ONE_FIVE; // Determine if the image is rotated between 45 degree and 315 degree for resizing calculations
        // Group rotation angles into 0, 90, 180, 270-degree blocks
        const rotationAngleGroup =
          rotation > ANGLE_THREE_ONE_FOUR || rotation < ANGLE_FOURTY_FIVE
            ? ANGLE_ZERO
            : rotation > ANGLE_FOURTY_FOUR && rotation < ANGLE_ONE_THIRTY_FIVE
            ? ANGLE_NINETY
            : rotation > ANGLE_ONE_THIRTY_FOUR &&
              rotation < ANGLE_TWO_TWENTY_FIVE
            ? ANGLE_ONE_EIGHTY
            : ANGLE_TWO_SEVENTY;

        // function to set width and height when resizing from corner handles
        const setWidthAndHeightCornerHandles = (
          directionX: number,
          directionY: number,
          cornerHandleDeltaX: number,
          cornerHandleDeltaY: number,
        ) => {
          newWidth = Math.min(
            Math.max(
              MIN_WIDTH,
              initialSize.width + directionX * cornerHandleDeltaX,
            ),
            MAX_WIDTH,
          );
          if (
            (e.shiftKey && isAspectRatioBelowOne) ||
            (e.shiftKey && node.attrs.actualHeight > node.attrs.actualWidth)
          ) {
            newHeight = parseFloat((newWidth * dataAspectRatio).toFixed(2));
          } else if (e.shiftKey && !isAspectRatioBelowOne) {
            newHeight = parseFloat((newWidth / dataAspectRatio).toFixed(2));
          } else {
            newHeight = initialSize.height + directionY * cornerHandleDeltaY;
          }
        };

        // function to set width and height when resizing horizontally (Right/Left)
        const setWidthAndHeight = (direction: number, delta: number) => {
          newWidth = Math.min(
            Math.max(
              MIN_WIDTH,
              initialSize.width +
                (isRotationInverted ? -direction * delta : direction * delta),
            ),
            MAX_WIDTH,
          );
          if (
            (e.shiftKey && isAspectRatioBelowOne) ||
            (e.shiftKey && node.attrs.actualHeight > node.attrs.actualWidth)
          ) {
            newHeight = parseFloat((newWidth * dataAspectRatio).toFixed(2));
          } else if (e.shiftKey && !isAspectRatioBelowOne) {
            newHeight = parseFloat((newWidth / dataAspectRatio).toFixed(2));
          }
        };

        // function to set height and width when resizing vertically (Top/Bottom)
        const setHeightAndWidth = (direction: number, delta: number) => {
          newHeight =
            initialSize.height +
            (isRotationInverted ? -direction * delta : direction * delta);
          if (
            (e.shiftKey && isAspectRatioBelowOne) ||
            (e.shiftKey && node.attrs.actualHeight > node.attrs.actualWidth)
          ) {
            newWidth = Math.min(
              Math.max(
                MIN_WIDTH,
                parseFloat((newHeight / dataAspectRatio).toFixed(2)),
              ),
              MAX_WIDTH,
            );
          } else if (e.shiftKey && !isAspectRatioBelowOne) {
            newWidth = Math.min(
              Math.max(
                MIN_WIDTH,
                parseFloat((newHeight * dataAspectRatio).toFixed(2)),
              ),
              MAX_WIDTH,
            );
          }
        };

        if (
          ((rotation > ANGLE_TWENTY_TWO_DOT_FIVE &&
            rotation < ANGLE_SIXTY_SEVEN_DOT_SIX) ||
            (rotation > ANGLE_TWO_ZERO_TWO_DOT_FIVE &&
              rotation < ANGLE_TWO_FOURTY_SEVEN_DOT_SIX)) &&
          (activeHandle === HANDLE_TOP_RIGHT ||
            activeHandle === HANDLE_BOTTOM_LEFT)
        ) {
          // Resizing at 45-degree and 225-degree corners (TopRight, BottomLeft handles)
          const rotatedDeltaX = getRotatedDeltaX(
            ANGLE_FOURTY_FIVE,
            deltaX,
            deltaY,
          );
          const rotatedDeltaY = getRotatedDeltaY(
            ANGLE_FOURTY_FIVE,
            deltaX,
            deltaY,
          );
          const directionX = getDirection(
            ANGLE_TWENTY_TWO_DOT_FIVE,
            ANGLE_SIXTY_SEVEN_DOT_SIX,
            HANDLE_TOP,
            ANGLE_TWO_ZERO_TWO_DOT_FIVE,
            ANGLE_TWO_FOURTY_SEVEN_DOT_SIX,
            HANDLE_BOTTOM,
            rotation,
            activeHandle,
          );
          const directionY = getDirection(
            ANGLE_TWENTY_TWO_DOT_FIVE,
            ANGLE_SIXTY_SEVEN_DOT_SIX,
            HANDLE_BOTTOM,
            ANGLE_TWO_ZERO_TWO_DOT_FIVE,
            ANGLE_TWO_FOURTY_SEVEN_DOT_SIX,
            HANDLE_TOP,
            rotation,
            activeHandle,
          );
          setWidthAndHeightCornerHandles(
            directionX,
            directionY,
            rotatedDeltaX,
            rotatedDeltaY,
          );
        } else if (
          ((rotation > ANGLE_ONE_ONE_TWO_DOT_FIVE &&
            rotation < ANGLE_ONE_FIFTY_SEVEN_DOT_SIX) ||
            (rotation > ANGLE_TWO_NINETY_TWO_DOT_FIVE &&
              rotation < ANGLE_THREE_THIRTY_SEVEN_DOT_SIX)) &&
          (activeHandle === HANDLE_TOP_RIGHT ||
            activeHandle === HANDLE_BOTTOM_LEFT)
        ) {
          // Resizing at 135-degree and 315-degree corners (TopRight, BottomLeft handles)
          const rotatedDeltaX = getRotatedDeltaX(
            ANGLE_ONE_THIRTY_FIVE,
            deltaX,
            deltaY,
          );
          const rotatedDeltaY = getRotatedDeltaY(
            ANGLE_ONE_THIRTY_FIVE,
            deltaX,
            deltaY,
          );
          const directionX = getDirection(
            ANGLE_ONE_ONE_TWO_DOT_FIVE,
            ANGLE_ONE_FIFTY_SEVEN_DOT_SIX,
            HANDLE_TOP,
            ANGLE_TWO_NINETY_TWO_DOT_FIVE,
            ANGLE_THREE_THIRTY_SEVEN_DOT_SIX,
            HANDLE_BOTTOM,
            rotation,
            activeHandle,
          );
          const directionY = getDirection(
            ANGLE_ONE_ONE_TWO_DOT_FIVE,
            ANGLE_ONE_FIFTY_SEVEN_DOT_SIX,
            HANDLE_BOTTOM,
            ANGLE_TWO_NINETY_TWO_DOT_FIVE,
            ANGLE_THREE_THIRTY_SEVEN_DOT_SIX,
            HANDLE_TOP,
            rotation,
            activeHandle,
          );
          setWidthAndHeightCornerHandles(
            directionX,
            directionY,
            rotatedDeltaX,
            rotatedDeltaY,
          );
        } else if (
          ((rotation > ANGLE_TWENTY_TWO_DOT_FIVE &&
            rotation < ANGLE_SIXTY_SEVEN_DOT_SIX) ||
            (rotation > ANGLE_TWO_ZERO_TWO_DOT_FIVE &&
              rotation < ANGLE_TWO_FOURTY_SEVEN_DOT_SIX)) &&
          (activeHandle === HANDLE_TOP_LEFT ||
            activeHandle === HANDLE_BOTTOM_RIGHT)
        ) {
          // Resizing at 45-degree and 225-degree corners (TopLeft, BottomRight handles)
          const rotatedDeltaX = getRotatedDeltaX(
            ANGLE_ONE_THIRTY_FIVE,
            deltaX,
            deltaY,
          );
          const rotatedDeltaY = getRotatedDeltaY(
            ANGLE_ONE_THIRTY_FIVE,
            deltaX,
            deltaY,
          );
          const directionX = getDirection(
            ANGLE_TWENTY_TWO_DOT_FIVE,
            ANGLE_SIXTY_SEVEN_DOT_SIX,
            HANDLE_BOTTOM,
            ANGLE_TWO_ZERO_TWO_DOT_FIVE,
            ANGLE_TWO_FOURTY_SEVEN_DOT_SIX,
            HANDLE_TOP,
            rotation,
            activeHandle,
          );
          const directionY = getDirection(
            ANGLE_TWENTY_TWO_DOT_FIVE,
            ANGLE_SIXTY_SEVEN_DOT_SIX,
            HANDLE_TOP,
            ANGLE_TWO_ZERO_TWO_DOT_FIVE,
            ANGLE_TWO_FOURTY_SEVEN_DOT_SIX,
            HANDLE_BOTTOM,
            rotation,
            activeHandle,
          );
          setWidthAndHeightCornerHandles(
            directionY,
            directionX,
            rotatedDeltaY,
            rotatedDeltaX,
          );
        } else if (
          ((rotation > ANGLE_ONE_ONE_TWO_DOT_FIVE &&
            rotation < ANGLE_ONE_FIFTY_SEVEN_DOT_SIX) ||
            (rotation > ANGLE_TWO_NINETY_TWO_DOT_FIVE &&
              rotation < ANGLE_THREE_THIRTY_SEVEN_DOT_SIX)) &&
          (activeHandle === HANDLE_TOP_LEFT ||
            activeHandle === HANDLE_BOTTOM_RIGHT)
        ) {
          // Resizing at 135-degree and 315-degree corners (TopLeft, BottomRight handles)
          const rotatedDeltaX = getRotatedDeltaX(
            ANGLE_FOURTY_FIVE,
            deltaX,
            deltaY,
          );
          const rotatedDeltaY = getRotatedDeltaY(
            ANGLE_FOURTY_FIVE,
            deltaX,
            deltaY,
          );
          const directionX = getDirection(
            ANGLE_ONE_ONE_TWO_DOT_FIVE,
            ANGLE_ONE_FIFTY_SEVEN_DOT_SIX,
            HANDLE_TOP,
            ANGLE_TWO_NINETY_TWO_DOT_FIVE,
            ANGLE_THREE_THIRTY_SEVEN_DOT_SIX,
            HANDLE_BOTTOM,
            rotation,
            activeHandle,
          );
          const directionY = getDirection(
            ANGLE_ONE_ONE_TWO_DOT_FIVE,
            ANGLE_ONE_FIFTY_SEVEN_DOT_SIX,
            HANDLE_BOTTOM,
            ANGLE_TWO_NINETY_TWO_DOT_FIVE,
            ANGLE_THREE_THIRTY_SEVEN_DOT_SIX,
            HANDLE_TOP,
            rotation,
            activeHandle,
          );
          setWidthAndHeightCornerHandles(
            directionY,
            directionX,
            rotatedDeltaY,
            rotatedDeltaX,
          );
        } else if (
          (rotationAngleGroup == ANGLE_ZERO ||
            rotationAngleGroup == ANGLE_ONE_EIGHTY) &&
          (activeHandle === HANDLE_RIGHT || activeHandle === HANDLE_LEFT)
        ) {
          // Resizing from right and left handles
          const directionX = activeHandle === HANDLE_RIGHT ? 1 : -1;
          setWidthAndHeight(directionX, deltaX);
        } else if (
          (rotationAngleGroup == ANGLE_NINETY ||
            rotationAngleGroup == ANGLE_TWO_SEVENTY) &&
          (activeHandle === HANDLE_RIGHT || activeHandle === HANDLE_LEFT)
        ) {
          // Resizing from right and left handles when image roated at 90 and 270 degree
          const directionY =
            (activeHandle === HANDLE_LEFT &&
              rotationAngleGroup == ANGLE_NINETY) ||
            (activeHandle === HANDLE_RIGHT &&
              rotationAngleGroup == ANGLE_TWO_SEVENTY)
              ? 1
              : -1;
          setWidthAndHeight(directionY, deltaY);
        } else if (
          (rotationAngleGroup == 0 || rotationAngleGroup == ANGLE_ONE_EIGHTY) &&
          (activeHandle === HANDLE_TOP || activeHandle === HANDLE_BOTTOM)
        ) {
          // Resizing from top and bottom handles
          const directionY = activeHandle === HANDLE_BOTTOM ? 1 : -1;
          setHeightAndWidth(directionY, deltaY);
        } else if (
          (rotationAngleGroup == ANGLE_NINETY ||
            rotationAngleGroup == ANGLE_TWO_SEVENTY) &&
          (activeHandle === HANDLE_TOP || activeHandle === HANDLE_BOTTOM)
        ) {
          // Resizing from top and bottom handles when image roated at 90 and 270 degree
          const directionX =
            (activeHandle === HANDLE_BOTTOM &&
              rotationAngleGroup === ANGLE_NINETY) ||
            (activeHandle === HANDLE_TOP &&
              rotationAngleGroup === ANGLE_TWO_SEVENTY)
              ? 1
              : -1;
          setHeightAndWidth(directionX, deltaX);
        } else if (
          rotationAngleGroup == 0 ||
          rotationAngleGroup == ANGLE_ONE_EIGHTY
        ) {
          // Resizing from corner handles
          const directionX = activeHandle.includes(HANDLE_RIGHT) ? 1 : -1;
          const directionY = activeHandle.includes(HANDLE_BOTTOM) ? 1 : -1;
          setWidthAndHeightCornerHandles(
            isRotationInverted ? -directionX : directionX,
            isRotationInverted ? -directionY : directionY,
            deltaX,
            deltaY,
          );
        } else if (
          rotationAngleGroup == ANGLE_NINETY ||
          rotationAngleGroup == ANGLE_TWO_SEVENTY
        ) {
          // Resizing from corner handles when image roated at 90 and 270 degree
          const directionX =
            (activeHandle.includes(HANDLE_BOTTOM) &&
              rotationAngleGroup === ANGLE_NINETY) ||
            (activeHandle.includes(HANDLE_TOP) &&
              rotationAngleGroup === ANGLE_TWO_SEVENTY)
              ? 1
              : -1;
          const directionY =
            (activeHandle.includes(HANDLE_LEFT) &&
              rotationAngleGroup == ANGLE_NINETY) ||
            (activeHandle.includes(HANDLE_RIGHT) &&
              rotationAngleGroup == ANGLE_TWO_SEVENTY)
              ? 1
              : -1;
          setWidthAndHeightCornerHandles(
            isRotationInverted ? -directionY : directionY,
            isRotationInverted ? -directionX : directionX,
            deltaY,
            deltaX,
          );
        }

        setWidth(newWidth);
        setHeight(newHeight);

        updateAttributes({
          width: newWidth,
          height: newHeight,
        });
        const pos = getPos();
        const tr = editor.view.state.tr.setSelection(
          NodeSelection.create(editor.view.state.doc, pos),
        );
        editor.view.dispatch(tr);
      } else if (isRotating && imgRef.current) {
        // Image roatation when rotation handles is invoked
        const rect = imgRef.current.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const angle =
          (Math.atan2(e.clientY - centerY, e.clientX - centerX) *
            ANGLE_ONE_EIGHTY) /
          Math.PI;
        setRotation(
          (initialRotation + angle - initialAngle + ROTATION_ANGLE_LIMIT) %
            ROTATION_ANGLE_LIMIT,
        );
        updateAttributes({
          rotation:
            (initialRotation + angle - initialAngle) % ROTATION_ANGLE_LIMIT,
        });
        const pos = getPos();
        const tr = editor.view.state.tr.setSelection(
          TextSelection.create(editor.view.state.doc, pos + 1),
        );
        editor.view.dispatch(tr);
      }
    },
    [
      isResizing,
      isRotating,
      initialMousePosition,
      initialSize,
      dataAspectRatio,
      activeHandle,
      updateAttributes,
      node.attrs.aspectRatio,
      initialRotation,
      initialAngle,
    ],
  );

  const handleMouseUp = useCallback(() => {
    if (isResizing) {
      setIsResizing(false);
      const pos = getPos();
      const tr = editor.view.state.tr.setSelection(
        NodeSelection.create(editor.view.state.doc, pos),
      );
      editor.view.dispatch(tr);
    }
    if (isRotating) {
      setIsRotating(false);
      const pos = getPos();
      const tr = editor.view.state.tr.setSelection(
        NodeSelection.create(editor.view.state.doc, pos),
      );
      editor.view.dispatch(tr);
    }
    if (isDragging) setIsDragging(false);
  }, [isResizing, isRotating, isDragging]);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const startDragging = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (event.target != imgRef.current) return;
      if (!editorRef || !referenceElement || isResizing || isRotating) return;
      setIsDragging(true);
      const initialX = event.clientX;
      const initialY = event.clientY;

      const startTop = node.attrs.top;
      const startLeft = node.attrs.left;
      const editorWidth = editorRef.clientWidth;
      const editorHeight = editorRef.clientHeight;
      const maxLeft = editorWidth - width;
      const maxTop = editorHeight - height;

      const handleDragMove = (moveEvent: MouseEvent) => {
        if (isResizing || isRotating) return;
        const deltaX = moveEvent.clientX - initialX;
        const deltaY = moveEvent.clientY - initialY;
        const newTop = Math.min(Math.max(startTop + deltaY, 0), maxTop);
        const newLeft = Math.min(Math.max(startLeft + deltaX, 0), maxLeft);
        updateAttributes({ top: newTop, left: newLeft });
      };

      const handleDragEnd = () => {
        setIsDragging(false);
        document.removeEventListener('mousemove', handleDragMove);
        document.removeEventListener('mouseup', handleDragEnd);
        const pos = getPos();
        const tr = editor.view.state.tr.setSelection(
          NodeSelection.create(editor.view.state.doc, pos),
        );
        editor.view.dispatch(tr);
      };

      document.addEventListener('mousemove', handleDragMove);
      document.addEventListener('mouseup', handleDragEnd);
    },
    [
      editorRef,
      referenceElement,
      isResizing,
      isRotating,
      width,
      height,
      updateAttributes,
      getPos,
      editor.view.state,
    ],
  );

  const startResizing = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, position: string) => {
      if (!imgRef.current) return;
      setIsResizing(true);
      setInitialMousePosition({ x: event.clientX, y: event.clientY });
      setInitialSize({ width, height });
      setActiveHandle(position);
    },
    [width, height],
  );

  const startRotating = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      if (!imgRef.current) return;
      const rect = imgRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const angle =
        (Math.atan2(event.clientY - centerY, event.clientX - centerX) * 180) /
        Math.PI;
      setInitialAngle(angle);
      setInitialRotation(rotation);
      setIsRotating(true);
    },
    [rotation],
  );

  const displayStyle = node.attrs.textWrap === 'inline' ? 'inline' : '';
  const positionStyle =
    node.attrs.textWrap === 'inline'
      ? 'relative'
      : node.attrs.textWrap === 'front' || node.attrs.textWrap === 'behind'
      ? 'absolute'
      : undefined;
  const floatStyle =
    node.attrs.textWrap === 'square'
      ? 'left'
      : node.attrs.textWrap === 'squareRight'
      ? 'right'
      : 'none';
  const draggable =
    node.attrs.textWrap === 'front' || node.attrs.textWrap === 'behind';
  const nodeViewStyles = {
    display: displayStyle,
    position: positionStyle,
    top: positionStyle == 'absolute' ? node.attrs.top : '0',
    left: positionStyle == 'absolute' ? node.attrs.left : '0',
    float: floatStyle,
    marginRight: floatStyle === 'left' ? '8px' : '0px',
    marginLeft: floatStyle === 'right' ? '8px' : '0px',
    padding: displayStyle === 'inline' ? '0 8px' : '0px',
    zIndex: node.attrs.zIndex,
    // Border width to give spacing to content when in square mode for the image
    borderWidth: '20px',
    borderTopWidth: '0px',
    borderBottomWidth: '0px',
    borderRightWidth: floatStyle === 'right' ? '0px' : '12px',
    borderLeftWidth: floatStyle === 'left' ? '0px' : '12px',
    borderColor: 'transparent',
    borderStyle: 'solid',
  } satisfies React.CSSProperties;

  const isInsideListItem = () => {
    let depth = editor.state.doc.resolve(getPos()).depth;
    while (depth > 0) {
      const node = editor.state.doc.resolve(getPos()).node(depth);
      if (node.type.name === 'listItem') {
        return true;
      }
      depth--;
    }
    return false;
  };

  const isInsideTableCell = () => {
    let depth = editor.state.doc.resolve(getPos()).depth;
    while (depth > 0) {
      const node = editor.state.doc.resolve(getPos()).node(depth);
      if (node.type.name === 'tableCell') {
        return true;
      }
      depth--;
    }
    return false;
  };

  const lockWrapControls = isInsideListItem() || isInsideTableCell();

  return (
    <>
      <NodeViewWrapper
        className={`file-component ${isSelected ? 'selected' : ''}`}
        style={{ ...nodeViewStyles }}
        ref={setReferenceElement}
        onMouseDown={
          draggable && !isResizing && !isRotating ? startDragging : undefined
        }
      >
        <div
          className={fileStyles.selectWrapper}
          style={{
            height: node.attrs.height,
            width: node.attrs.width,
            transform: `rotate(${rotation}deg)`,
          }}
        >
          <img
            draggable="true"
            data-textwrap={node.attrs.textWrap}
            id="image"
            height="100%"
            width="100%"
            data-aspectratio={dataAspectRatio}
            src={node.attrs.src}
            style={{ display: displayStyle }}
            ref={imgRef}
            className={node.attrs.textWrap}
          />
          {isSelected && (
            <>
              <RotateHandleComponent onMouseDown={startRotating} />
              <ResizeHandleGroup
                startResizing={startResizing}
                rotation={rotation}
              />
            </>
          )}
        </div>
        &#8203; {/*zero width space for cursor to be of text size */}
      </NodeViewWrapper>
      {isSelected && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            zIndex: 1,
            marginTop: rotation > 115 && rotation < 245 ? '35px' : '10px',
          }}
          {...attributes.popper}
        >
          <ImageToolbar
            onTextWrap={handleTextWrap}
            currentTextWrap={node.attrs.textWrap}
            lockWrapControls={lockWrapControls}
          />
        </div>
      )}
    </>
  );
};

// Rotation handle
interface RotateHandleComponentProps {
  onMouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
const RotateHandleComponent = ({ onMouseDown }: RotateHandleComponentProps) => (
  <div className={fileStyles.rotateHandleWrap}>
    <div className={fileStyles.rotateHandle} onMouseDown={onMouseDown}></div>
  </div>
);

// ResizeHandleGroup is for the eight resize handles around an element (corners and sides)
const ResizeHandleGroup = ({
  startResizing,
  rotation,
}: {
  startResizing: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    position: string,
  ) => void;
  rotation: number;
}) => {
  const positions = [
    'TopLeft',
    'TopRight',
    'BottomRight',
    'BottomLeft',
    'Top',
    'Right',
    'Bottom',
    'Left',
  ];

  return (
    <div>
      {positions.map((position) => (
        <div
          key={position}
          className={fileStyles[`resize${position}HandleWrap`]}
          onMouseDown={(e) => startResizing(e, position)}
          data-rotate={
            rotation > 337.5 || rotation < 22.6
              ? '0deg'
              : rotation > 22.5 && rotation < 67.6
              ? '45deg'
              : rotation > 67.5 && rotation < 112.6
              ? '90deg'
              : rotation > 112.5 && rotation < 157.6
              ? '135deg'
              : rotation > 157.5 && rotation < 202.6
              ? '180deg'
              : rotation > 202.5 && rotation < 247.6
              ? '225deg'
              : rotation > 247.5 && rotation < 292.6
              ? '270deg'
              : rotation > 292.5 && rotation < 337.6
              ? '315deg'
              : '0deg' // Set the data-rotate attribute based on the element's current rotation to adjust cursor appearance
          }
        >
          <div
            className={cn(
              fileStyles.resizeHandle,
              fileStyles[`resize${position}Handle`],
            )}
          ></div>
        </div>
      ))}
    </div>
  );
};
