import { useRef, useState } from 'react';

import { useOnClickOutside } from '@visualist/hooks';

import { InvitationHub, PermissionsHub } from '@api/hubs';
import { ResponseSuggestionHub } from '@api/users';
import { UseMutateFunction } from '@tanstack/react-query';

import { User } from '..';

type ExistingUserProps = {
  user: ResponseSuggestionHub;
  hubId: string;
  hubName: string;
  sendInvitationHub: UseMutateFunction<
    InvitationHub,
    any,
    InvitationHub,
    unknown
  >;
  permissions: PermissionsHub[];
};

// Component loaded when user is being suggested

export const ExistingUser = ({
  user,
  hubId,
  hubName,
  sendInvitationHub,
  permissions,
}: ExistingUserProps) => {
  const [on, setOn] = useState(false);
  const [isShowMenu, setShowMenu] = useState(false);

  const permissionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(permissionsRef, () => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  });

  const changePermissions = (permissions: PermissionsHub[]) => {
    sendInvitationHub({
      hubId,
      hubName,
      users: [{ email: user.email }],
      permissions,
      setOn,
    });
  };

  const invite = () => {
    if (!on) {
      sendInvitationHub({
        hubId,
        hubName,
        users: [{ email: user.email }],
        permissions,
        setOn,
      });
    }
    setOn(true);
  };

  return (
    <User
      // @ts-expect-error Error here because the user expected is meant to be a more complete user object. And this component is only shown to render the switch from this component. To fix the type will need to create a new component to render this.
      user={user}
      isShowMenu={isShowMenu}
      setShowMenu={setShowMenu}
      invite={invite}
      on={on}
      permissions={permissions}
      permissionsRef={permissionsRef}
      changePermissions={changePermissions}
    />
  );
};
