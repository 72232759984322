import { createEvent, createStore } from 'effector';

export const showingTooltip = createEvent();
export const hidingTooltip = createEvent();

export const $showTooltip = createStore(false)
  .on(hidingTooltip, () => false)
  .on(showingTooltip, () => true);

export const hidingTooltipForSession = createEvent<string>();
export const resetHiddenTooltipForSession = createEvent();

// As tooltip cna be hiddena and shown by other means, this is for if the tooltp is hidden by the user for the entire session
export const $hideTooltipForSession = createStore('')
  .on(hidingTooltipForSession, (_, s: string) => s)
  .reset(resetHiddenTooltipForSession);

export const showingDemoCompletion = createEvent();
export const hidingDemoCompletion = createEvent();

export const $showDemoCompletion = createStore(false)
  .on(hidingDemoCompletion, () => false)
  .on(showingDemoCompletion, () => true);

export const showingProTrial = createEvent();
export const hidingProTrial = createEvent();

export const $showProTrialUpgrade = createStore(false)
  .on(showingProTrial, () => true)
  .on(hidingProTrial, () => false);

export const showingDemoWarning = createEvent();
export const hideDemoWarning = createEvent();

export const $showDemoWarning = createStore(false)
  .on(hideDemoWarning, () => false)
  .on(showingDemoWarning, () => true);

export const hideMobileDemoMessage = createEvent();

export const $showMobileDemoMessage = createStore(true).on(
  hideMobileDemoMessage,
  () => false,
);
