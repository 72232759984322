import React from 'react';

import { Icon, IconProps } from './Icon';

export const PaperPlaneIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        stroke="currentColor"
        d="M4.695 12.281 1.28 9.831a.593.593 0 0 1 .071-1.045l17.483-7.775a.118.118 0 0 1 .159.147l-.006.019L15.03 18.6a.353.353 0 0 1-.033.09.59.59 0 0 1-.957.114l-.003-.004c-.046-.051-.092-.103-.148-.141l-5.985-4.072a.5.5 0 0 1-.104-.733l7.247-8.747c.153-.184-.089-.433-.277-.285l-9.475 7.445a.5.5 0 0 1-.6.013Z"
      />
    </Icon>
  );
};
