import { useRef, useState } from 'react';

import { useOnClickOutside } from '@visualist/hooks';

import { Invitation } from '@api/boards';
import { PermissionsHub } from '@api/hubs';
import { ResponseSuggestion } from '@api/users';
import { UseMutateFunction } from '@tanstack/react-query';

import { User } from '..';

type ExistingUserProps = {
  user: ResponseSuggestion;
  boardId: string;
  boardName: string;
  sendInvitation: UseMutateFunction<Invitation, any, Invitation, unknown>;
  permissions: PermissionsHub[];
  nestedBoards: string[];
};
export const ExistingUser = ({
  user,
  boardId,
  boardName,
  sendInvitation,
  permissions,
  nestedBoards,
}: ExistingUserProps) => {
  const [on, setOn] = useState(false);
  const [isShowMenu, setShowMenu] = useState(false);

  const permissionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(permissionsRef, () => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  });

  const changePermissions = (permissions: PermissionsHub[]) => {
    sendInvitation({
      boardId,
      users: [{ email: user.email }],
      boardName,
      nested_board: nestedBoards,
      permissions,
      setOn,
    });
  };

  const invite = () => {
    if (!on) {
      sendInvitation({
        boardId,
        users: [{ email: user.email }],
        boardName,
        nested_board: nestedBoards,
        permissions,
        setOn,
      });
    }
    setOn(true);
  };

  return (
    <User
      // @ts-expect-error Error here because the user expected is meant to be a more complete user object. And this component is only shown to render the switch from this component. To fix the type will need to create a new component to render this. Similiar issue in other matching components copied in uhbs share sheet.
      user={user}
      isShowMenu={isShowMenu}
      setShowMenu={setShowMenu}
      invite={invite}
      on={on}
      permissions={permissions}
      permissionsRef={permissionsRef}
      changePermissions={changePermissions}
    />
  );
};
