import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router';

import { Carousel } from '@visualist/design-system';

import { useAppData } from '../../AppContext';

import styles from './styles.module.css';

export const NotFound = () => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAppData();

  useEffect(() => {
    if (user && location.pathname === '/login') {
      history.push('/home');
    }
  }, [user, history, location]);

  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>Error</title>
      </Helmet>
      <div className={styles.errorContainer}>
        <span className={styles.header}>Oops. Can't find this page.</span>
        <span className={styles.message}>
          It may no longer be available for viewing.
        </span>
      </div>
      <div className={styles.carouselBox}>
        <Carousel />
      </div>
    </div>
  );
};
