import { AxiosResponse } from 'axios';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { saveCheckoutFailureToLocalStorage } from '@visualist/design-system/src/components/v2/SnackBar/utils';

import {
  getProductInfo,
  getUserBillingInfo,
  manageBillingInfo,
  MappedMembershipProduct,
  parseMembershipTier,
  postCheckoutPlan,
  ProductsInfoResponse,
  UserTierInfo,
} from '@api/billing';
import { Plan } from '@pages/AccountPage/components/types';
import {
  capitalise,
  generateCurrentUrl,
  saveValueTolocalStorage,
} from '@pages/AccountPage/utils';
import {
  BILLING_INFO_QUERY,
  MANAGE_BILLING_QUERY,
  MEMBERSHIP_PRODUCT_INFO_QUERY,
} from '@src/shared/constants/query-names';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useBilling = (syncSuccessHandler?: () => void) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: [BILLING_INFO_QUERY],
    queryFn: getUserBillingInfo,
    select: transformBillingInfo,
  });

  const membershipQuery = useQuery({
    queryKey: [MEMBERSHIP_PRODUCT_INFO_QUERY],
    queryFn: getProductInfo,
    select: transformMembershipProductInfo,
  });

  const cancelMutation = useMutation({
    mutationFn: () => postCheckoutPlan('free', generateCurrentUrl()),
    onMutate: () => {
      startedSnack({
        label: 'Cancelling your subscription...',
        close: true,
      });
    },
    onSuccess: () => {
      startedSnack({
        label: 'You’ve now cancelled your subscription',
        close: true,
      });

      if (syncSuccessHandler) syncSuccessHandler();

      queryClient.invalidateQueries({
        queryKey: [BILLING_INFO_QUERY],
      });
    },
    onError: () => {
      startedSnack({
        label: 'Oops, something went wrong',
        action: {
          label: 'Try again',
          action: () => {
            cancelMutation.mutate();
          },
        },
        close: true,
      });
    },
  });

  // Does the same thing as cancel plan currently. In future will be seperated.
  const manageBillingInfoMutation = useMutation({
    mutationKey: [MANAGE_BILLING_QUERY],
    mutationFn: manageBillingInfo,
    onMutate: () => {
      startedSnack({
        label: 'Getting you there...',
        close: true,
      });
    },
    onSuccess: (data) => {
      if (data.data.customer_session_url) {
        window.open(data.data.customer_session_url, '_blank');
        return;
      }

      if (syncSuccessHandler) syncSuccessHandler();

      startedSnack({
        label: 'Oops, something went wrong',
        action: {
          label: 'Try again',
          action: () => manageBillingInfoMutation.mutate(),
        },
        close: true,
      });
    },
    onError: () => {
      startedSnack({
        label: 'Oops, something went wrong',
        action: {
          label: 'Try again',
          action: () => manageBillingInfoMutation.mutate(),
        },
        close: true,
      });
    },
  });

  // Es lint line disabled as these values are needed for when a mutation is retried on error. Passing them here gives acces to them in the onError callback.
  const mutation = useMutation({
    mutationFn: ({
      stripePriceId,
      // eslint-disable-next-line
      planToChangeTo,
      // eslint-disable-next-line
      currentPlan,
      // eslint-disable-next-line
      isAnnual,
    }: {
      stripePriceId: string;
      planToChangeTo: Plan;
      currentPlan: Plan;
      isAnnual: boolean;
    }) => postCheckoutPlan(stripePriceId, generateCurrentUrl()),
    onMutate: () => {
      startedSnack({
        label: 'Checkout in progress...',
        close: true,
      });
    },
    onSuccess: (data, variables) => {
      // Two things can happen here:
      // If user billing data is in stripe, it updates subscription and query needs updating.
      // If user billing data is not in stripe, it returns a checkout URL and we need to redirect to it.
      if (data.data.data.checkout_url) {
        saveValueTolocalStorage(
          'checkout_success',
          generatePlanSnackText({
            planToChangeTo: variables.planToChangeTo,
            currentPlan: variables.currentPlan,
            isAnnual: variables.isAnnual,
          }),
        );
        generateCheckoutFailureAction(
          variables.currentPlan,
          variables.planToChangeTo,
        );

        window.location.href = data.data.data.checkout_url;
      } else if (data.data.state.status === 'modify_success') {
        // Backend issue where null is returned on next plan. Assume if null it is free
        const snackText = generatePlanSnackText({
          planToChangeTo: variables.planToChangeTo,
          currentPlan: variables.currentPlan,
          isAnnual: variables.isAnnual,
        });

        startedSnack({
          label: snackText,
          close: true,
        });
      }

      if (syncSuccessHandler) syncSuccessHandler();

      queryClient.invalidateQueries({
        queryKey: [BILLING_INFO_QUERY],
      });
    },
    onError: (_, variables) => {
      startedSnack({
        label: 'Oops, something went wrong',
        action: {
          label: 'Try again',
          action: () => {
            mutation.mutate({
              stripePriceId: variables.stripePriceId,
              planToChangeTo: variables.planToChangeTo,
              currentPlan: variables.currentPlan,
              isAnnual: variables.isAnnual,
            });
          },
        },
      });
    },
  });

  return {
    isWithinThreeDays: query.data?.membershipTierInfo.planEndDate
      ? new Date().getTime() <
          new Date(query.data?.membershipTierInfo.planEndDate).getTime() &&
        new Date().getTime() >
          new Date(query.data?.membershipTierInfo.planEndDate).getTime() -
            268000000
      : false,
    query,
    membershipQuery,
    checkoutURLMutation: mutation,
    cancelMutation,
    manageBillingInfoMutation,
  };
};

const generateCheckoutFailureAction = (
  currentPlan: Plan,
  planToChangeTo: Plan,
) => {
  if (currentPlan === 'pro') {
    if (planToChangeTo === ('hobby' as Plan)) {
      saveCheckoutFailureToLocalStorage('pro-hobby-checkout-failure');
    } else if (planToChangeTo === 'free') {
      saveCheckoutFailureToLocalStorage('pro-free-checkout-failure');
    }
  } else if (currentPlan === ('hobby' as Plan)) {
    if (planToChangeTo === 'free') {
      saveCheckoutFailureToLocalStorage('hobby-free-checkout-failure');
    } else if (planToChangeTo === 'pro') {
      saveCheckoutFailureToLocalStorage('hobby-pro-checkout-failure');
    }
  } else if (currentPlan === 'free') {
    if (planToChangeTo === 'pro') {
      saveCheckoutFailureToLocalStorage('free-pro-checkout-failure');
    } else if (planToChangeTo === 'starter') {
      saveCheckoutFailureToLocalStorage('free-hobby-checkout-failure');
    } else if (planToChangeTo === 'trial') {
      saveCheckoutFailureToLocalStorage('trial-checkout-failure');
    }
  }
};

const generatePlanSnackText = ({
  currentPlan,
  planToChangeTo,
  isAnnual,
}: {
  currentPlan: Plan;
  planToChangeTo: Plan;
  isAnnual: boolean;
}) => {
  if (currentPlan !== planToChangeTo) {
    if (currentPlan === 'free' && planToChangeTo === 'trial') {
      // Free -> Trial
      return `You're now on Pro Trial`;
    }

    if (
      (currentPlan === 'free' || currentPlan === 'starter') &&
      planToChangeTo === 'pro'
    ) {
      // Upgrade free or hobby -> prp
      return `You're now on Pro`;
    }

    if (
      (currentPlan === 'pro' &&
        (planToChangeTo === 'free' || planToChangeTo === 'starter')) ||
      (currentPlan === 'starter' && planToChangeTo === 'free')
    ) {
      //  Downgrade Pro -> Hobby or Free
      return `You'll be switched to ${capitalise(
        planToChangeTo,
      )} when this cycle ends`;
    }

    return `You're now on ${capitalise(planToChangeTo)}`;
  } else {
    if (currentPlan === 'free') return `You're now on the free plan`;
    return `You'll be billed ${isAnnual ? 'yearly' : 'monthly'}`;
  }
};

const transformBillingInfo = (response: AxiosResponse<UserTierInfo>) => {
  const data = response.data;
  const parsedMemberShipInfo = parseMembershipTier(
    data.current_membership_tier,
  );
  const nextPlan = data.next_membership_tier
    ? data.next_membership_tier.split('_')[0]
    : null;

  // To get time in ms
  const planEndDate = new Date(data.current_membership_period_end * 1000);

  return {
    ...data,
    membershipTierInfo: {
      ...parsedMemberShipInfo,
      planEndDate,
      trialInfo: {
        ...data.trial,
      },
      nextPlan: nextPlan,
    },
    currently_applied_promotion: {
      ...data.currently_applied_promotion,
    },
  };
};

const transformMembershipProductInfo = (
  response: AxiosResponse<ProductsInfoResponse>,
) => {
  const availablePlans = Object.fromEntries(
    response.data.map((product) => {
      const newProduct: MappedMembershipProduct = {
        id: product.id,
        stripeProductId: product.stripe_product_id,
        stripePriceId: product.stripe_price_id,
        productName: product.product_name,
        description: product.description,
        unitPrice: product.unit_price,
        created: product.created,
        type: product.type,
        lookupKey: product.lookup_key,
      };

      return [product.lookup_key, newProduct];
    }),
  );
  return {
    availablePlans,
  };
};
