import axios, { AxiosError, AxiosResponse } from 'axios';
import { useHistory, useLocation } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  Bookmark,
  createBookmarks,
  createHub,
  deleteHub,
  Hub,
  PostHubResponse,
  PostHubResponseError,
  restoreHub,
  updateHub,
} from '@api/hubs';
import { openedHubCreditSheet } from '@pages/AccountPage/models';
import { generateCurrentUrl } from '@pages/AccountPage/utils';
import { headerNameReset } from '@pages/HubPage/components/Header/model';
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  actions,
  ALL_HUBS_QUERY,
  BOARDS_TREE_QUERY,
  BOOKMARKS_OF_HUB_QUERY,
  HUB_QUERY,
} from '../../../../../shared/constants';

export const useHubs = (options?: MutateOptions<any, any, any>) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { pathname } = useLocation();

  const { mutate: makeHub } = useMutation<
    AxiosResponse<PostHubResponse>,
    AxiosError<PostHubResponseError>,
    { name: string; description?: string; location?: string }
  >({
    mutationFn: ({ name, description, location }: Hub) =>
      createHub({ name, description, location }),
    ...(options ?? {}),
    onSuccess: (data, { name }, context) => {
      queryClient.invalidateQueries({
        queryKey: [ALL_HUBS_QUERY],
      });

      startedSnack({
        label: 'Created hub',
        action: {
          label: 'Go to hub',
          action: () => {
            history.push(`/h/${data.data.data.id}`);
          },
        },
        close: true,
      });

      if (options?.onSuccess) options?.onSuccess(data, { name }, context);
    },
    onError: (err, { name, description, location }) => {
      if (axios.isAxiosError(err)) {
        if (
          err.response &&
          err.response.data.data &&
          err.response.data.state.status === 'insufficient_credits'
        ) {
          startedSnack({
            label: "Oops, you don't have enough hub credits",
            close: true,
          });
          openedHubCreditSheet({
            bundleId: 'credit_pack_1',
            redirectURL: generateCurrentUrl(),
            creditShortfallPurchaseAmount: {
              deficit: err.response?.data.data?.deficit,
              cost: err.response?.data.data?.deficit_unit_price,
              totalAmount: err.response?.data.data?.cost_to_open,
              hubName: name,
            },
          });
        } else {
          startedSnack({
            label: "Couldn't create hub",
            action: {
              label: 'Try again',
              action: () => makeHub({ name, description, location }),
            },
            close: true,
          });
        }
      }

      if (err.message.includes('400')) {
        startedSnack({
          label: "Couldn't create hub. Character limit exceeded.",
          close: true,
        });
      }
    },
  });

  const { mutate: editHub } = useMutation({
    mutationFn: ({
      hubId,
      name,
      description,
      welcome_message,
      welcome_title,
      location,
    }: Hub) =>
      updateHub({
        hubId,
        name,
        description,
        welcome_message,
        welcome_title,
        location,
      }),
    onSuccess: (data, { hubId }, context) => {
      queryClient.invalidateQueries({
        queryKey: [HUB_QUERY, { hubId }],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_HUBS_QUERY],
      });

      startedSnack({
        label: 'Updated hub',
        action: {
          label: 'Undo',
          action: () => {
            editHub({
              hubId: data.id,
              name: data.previous_state.name,
              description: data.previous_state.description,
              location: data.previous_state.location,
            });
          },
        },
        close: true,
      });
      if (options?.onSuccess) options?.onSuccess(data, { hubId }, context);
    },
    onError: (err, { name, hubId, description }, context) => {
      if (name) {
        startedSnack({
          label: "Couldn't update hub",
          action: {
            label: 'Try again',
            action: () => {
              editHub({ name, hubId, description });
            },
          },
          close: true,
        });
      }

      if (description) {
        startedSnack({
          label: "Couldn't edit hub description",
          action: {
            label: 'Try again',
            action: () => {
              editHub({ description, hubId });
            },
          },
          close: true,
        });
      }

      if (err.message.includes('400') && name && name.length > 0) {
        headerNameReset();
        startedSnack({
          label: " Couldn't rename hub. Character limit exceeded.",
        });
      }
      if (name?.length === 0) {
        headerNameReset();
        startedSnack({
          label: " Couldn't rename hub",
          close: true,
        });
      }

      if (options?.onError) {
        options?.onError(err, { name, hubId, description }, context);
      }
    },
  });

  const { mutate: addBookmarks } = useMutation({
    mutationFn: ({ url, hub }: Bookmark) => createBookmarks({ url, hub }),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [BOOKMARKS_OF_HUB_QUERY],
      });
      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
  });

  const { mutate: removeHub } = useMutation({
    mutationFn: ({ hubId, name }: { hubId: string; name: string }) =>
      deleteHub({ hubId, name }),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [ALL_HUBS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });

      if (pathname.includes(variables.hubId)) {
        history.push(`/home`);
      }

      startedSnack({
        label: 'Deleted hub',
        action: {
          label: 'Undo',
          action: () => {
            if (variables.hubId) {
              putHubBack(variables.hubId);
            }
          },
        },
        close: true,
      });

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (err, variables) => {
      startedSnack({
        label: "Couldn't delete hub",
        action: {
          label: 'Try again',
          action: () => {
            removeHub({ hubId: variables.hubId, name: variables.name });
          },
        },
        close: true,
      });
    },
  });

  const { mutate: putHubBack } = useMutation({
    mutationFn: (hubId: string) => restoreHub(hubId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ALL_HUBS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
    },
  });

  return { makeHub, editHub, addBookmarks, removeHub };
};
