import { Icon, IconProps } from './Icon';

export const SpinnerIcon = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="m456.4 371.7-27.8-16c-7.2-4.2-10.1-13.1-6.5-20.6 25.8-54.3 23.6-118.6-6.8-171-30.3-52.5-84.8-86.6-144.8-91.4-8.1-.8-14.5-7.7-14.5-16.1v-32c0-9.3 7.9-16.6 17.2-16C355 14.3 429.6 60.5 470.8 132c41.3 71.4 44 159.1 8 232.8-4.1 8.4-14.4 11.6-22.4 6.9z"
      />
    </Icon>
  );
};
