import React, { useRef } from 'react';

import Slider from 'react-slick';

import { Slide } from './Slide';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './overwrite-react-slick.css';
import styles from './Carousel.module.css';

export const Carousel = () => {
  const sliderRef = useRef<Slider | null>(null);

  const slidesInfo = [
    {
      id: '1',
      tipTitles: 'Colour Search',
      downText: 'Search for a colour without typing a single word',
      gif: '/assets/gifs/colour-search.gif',
    },
    {
      id: '2',
      tipTitles: 'Stickies',
      downText:
        'Drop a pin to make notes for yourself or communicate with others',
      gif: '/assets/gifs/stickies.gif',
    },
    {
      id: '3',
      tipTitles: 'Colour Blocks',
      downText: 'Create, save and tweak colour palettes',
      gif: '/assets/gifs/colour-blocks.gif',
    },
    {
      id: '4',
      tipTitles: 'Remove Background',
      downText: '1 click to remove distracting backgrounds',
      gif: '/assets/gifs/remove-background.gif',
    },
    {
      id: '5',
      tipTitles: 'Tidy Up',
      downText: 'Auto-tidy with 3 layouts: clusters, rows and columns',
      gif: '/assets/gifs/tidy-up.gif',
    },
    {
      id: '6',
      tipTitles: 'Go To Source',
      downText: 'Keep track of a block’s original source and go back anytime',
      gif: '/assets/gifs/source.gif',
    },
    {
      id: '7',
      tipTitles: 'Sets',
      downText:
        'Make and share moodboards, collages or concept boards in minutes',
      gif: '/assets/gifs/sets.png',
    },
    {
      id: '8',
      tipTitles: 'View Your Selection',
      downText: 'Select multiple blocks to clip, tag or work them into a set',
      gif: '/assets/gifs/selection-view.gif',
    },
    {
      id: '9',
      tipTitles: 'Browser Extension',
      downText: 'Save things from all over the web straight into Visualist',
      gif: '/assets/gifs/browser-extention.gif',
    },
  ];

  const linkExtension =
    'https://chrome.google.com/webstore/detail/visualist-chrome-extensio/kfkkhoppmbfmfmacgfagijkmphpiibjd?hl=en';

  const shouldDispalyTopTip =
    window.location.pathname === '/registration' ? true : false;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    initialSlide: Math.floor(Math.random() * slidesInfo.length),
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    cssEase: 'linear',
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carouselWrapper}>
        <Slider {...settings} ref={sliderRef}>
          {slidesInfo.map((slide, index) => (
            <div key={index}>
              <Slide
                key={index}
                {...slide}
                link={linkExtension}
                shouldDispalyTopTip={shouldDispalyTopTip}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
