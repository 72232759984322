import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

type Seats = 'seat' | 'seats';
type Guests = 'guest' | 'guests';

type DescriptionProps = {
  numberGuests: number;
  isNotEnoughSeats: boolean;
  maxRemainingNumberSeats: number;
  seats: Seats;
  numberRemainingSeats: number;
  guests: Guests;
  remainingSeats: Seats;
};

export const Description = ({
  numberGuests,
  isNotEnoughSeats,
  maxRemainingNumberSeats,
  seats,
  numberRemainingSeats,
  guests,
  remainingSeats,
}: DescriptionProps) => {
  return numberGuests > 0 ? (
    <div style={{ marginBottom: '8px' }}>
      <TypographyPoppins
        className={styles.description}
        type="body"
        bodySize="S"
      >
        {isNotEnoughSeats
          ? `You’re trying to add ${numberGuests} ${guests}, but you've only got ${maxRemainingNumberSeats} ${seats} left in this hub`
          : `You’re adding ${numberGuests} ${guests}, and you’ll have ${
              numberRemainingSeats > 0 ? numberRemainingSeats : 'no'
            } ${remainingSeats} left in this
      hub after this`}
      </TypographyPoppins>
    </div>
  ) : (
    <div style={{ marginBottom: '8px', marginTop: '20px' }}>
      <TypographyPoppins
        className={styles.description}
        type="body"
        bodySize="S"
      >
        {maxRemainingNumberSeats === 0
          ? "You've got no seats left in this hub"
          : `You’ve got ${maxRemainingNumberSeats} ${seats} left in this hub`}
      </TypographyPoppins>
    </div>
  );
};
