import { Dispatch, SetStateAction } from 'react';

import { Icon } from '@visualist/icons';

import { AllFileCopiesResponse } from '@api/blocks';

import { BoardIdsToCopy, BoardIdsToRemove } from '..';
import { ListItem } from '../ListItem';

import styles from './styles.module.css';

interface AllBoardsListProps {
  allFileCopies: AllFileCopiesResponse;
  allBoardsIds: string[];
  idsSelectedBoards: string[];
  setIdsSelectedBoards: Dispatch<SetStateAction<Array<string>>>;
  boardIdsToRemove: BoardIdsToRemove;
  setBoardIdsToRemove: Dispatch<SetStateAction<BoardIdsToRemove>>;
  boardIdsToCopy: BoardIdsToCopy;
  setBoardIdsToCopy: Dispatch<SetStateAction<BoardIdsToCopy>>;
}

export const AllBoardsList = ({
  allFileCopies,
  allBoardsIds,
  idsSelectedBoards,
  setIdsSelectedBoards,
  boardIdsToRemove,
  boardIdsToCopy,
  setBoardIdsToRemove,
  setBoardIdsToCopy,
}: AllBoardsListProps) => {
  if (!allFileCopies?.boards?.length) return null;

  return (
    <>
      {allFileCopies.boards.map((board) => (
        <ListItem
          key={board.board_id}
          name={board.board_name}
          boardId={board.board_id}
          icon={<Icon name="sprite/board" className={styles.icon} />}
          allBoardsIds={allBoardsIds}
          blockId={board.block_id}
          boardIdsToRemove={boardIdsToRemove}
          setBoardIdsToRemove={setBoardIdsToRemove}
          boardIdsToCopy={boardIdsToCopy}
          setBoardIdsToCopy={setBoardIdsToCopy}
          idsSelectedBoards={idsSelectedBoards}
          setIdsSelectedBoards={setIdsSelectedBoards}
          board={board.parent}
          checkbox
        />
      ))}
    </>
  );
};
