export interface ImageType {
  id: string;
  colors: ColorType[];
  created_at: Date;
  block_source: string;
  block_type: BlockType;
  source_data: {
    page_domain: string;
    page_url: string;
    image_url?: string;
    saved_date?: string;
    instagram_media_id: string;
  };
  projects: ProjectOrBoardType[];
  boards: ProjectOrBoardType[];
  tags: string[];
  name?: string;
  file: PhotoType;
}

export type ColorType = {
  id: string;
  hex_color: string;
  rgb_color: string;
  main_color: boolean;
  source?: number;
  order: number;
  delete?: boolean;
};

export enum BlockType {
  palette = 'Colour palette',
  set = 'Set',
  image = 'Image',
  background = 'Background remove',
}

export type ProjectOrBoardType = {
  id: string;
  name: string;
};

export enum TabType {
  Properties,
  Stickies,
  Editing,
}

export type RGBType = {
  r: number;
  g: number;
  b: number;
};

export type AddColorType = {
  rgb: RGBType;
} & Pick<ColorType, 'main_color'>;

export type UpdateColorType = {
  hex: string | undefined;
} & Pick<ColorType, 'id' | 'order' | 'delete'>;

// TODO: combine the types with Image
export interface EditingImage extends ImageType {
  use_count: number;
  width: number;
  height: number;
  project_list: string[];
  board_list: string[];
  is_removed: boolean;
}

export type PhotoType = {
  medium_square_crop: string;
  full_size: string;
  small_square_crop: string;
  thumbnail: string;
  thumbnail_640: string;
  thumbnail_330: string;
  thumbnail_400: string;
  thumbnail_160: string;
  thumbnail_130: string;
  thumbnail_100: string;
};

export type UserType = {
  id: number;
  first_name: string | null;
  last_name: string | null;
  photo?: PhotoType;
  referral_code?: string;
  meta?: Record<string, unknown>;
  is_google_linked?: boolean;
  email?: string;
  name?: string; // this `name` appears in Studio when we don't have a user logged in
} & UserPersonalisationType;

export type UserPersonalisationType = {
  active_since?: string | null;
  location?: string | null;
  creative_field?: string | null;
  creative_field_other?: string | null;
  team_size?: string | null;
  average_fee?: string | null;
  project_number?: string | null;
  use_case?: string | null;
  acquisition_channel?: string | null;
  acquisition_channel_other?: string | null;
};
