import { api } from '.';
import { HubResponse } from './hubs';

export type Sender = {
  id: number;
  email: string;
  first_name?: string;
  last_name?: string;
  photo:
    | {
        full_size: string;
        medium_square_crop: string;
        thumbnail: string;
        small_square_crop: string;
      }
    | Partial<{
        full_size: string;
        medium_square_crop: string;
        thumbnail: string;
        small_square_crop: string;
      }>;
};

export type Thread = {
  id: string;
  hub?: HubResponse;
  text: string;
  html: string;
  summary: string | null;
  response: string | null;
  sender: Sender;
  recipients_to: Sender[];
  recipients_cc: Sender[];
  subject: string;
  message_id: string;
  references: string | null;
  sent_at: string;
  thread_count: number;
  is_read: boolean;
  attachments: {
    size: number;
    filename: string;
    content_id: string;
    content_type: string;
    content_disposition: string;
    content_transfer_encoding: string;
  }[];
};

export type MessagesResponse = {
  count: number;
  unread_count: number;
  next: string | null;
  previous: string | null;
  results: Thread[];
};

export type Threads = Omit<Thread, 'thread_count'> & {
  attachments: any[];
};

export type MessageThreadResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Thread[];
};

export const fetchMessages = async (
  hubId: string,
  offset: string,
  limit: string = '40',
): Promise<MessagesResponse> => {
  const response = await api.get(`/messaging/${hubId}`, {
    params: {
      offset,
      limit,
    },
  });
  return response.data;
};

export const fetchUserMessages = async (
  offset: string,
  limit: string = '40',
): Promise<MessagesResponse> => {
  const response = await api.get(`/messaging`, {
    params: {
      offset,
      limit,
    },
  });
  return response.data;
};

export const fetchMessage = async (
  hubId: string,
  threadId: string,
): Promise<Thread> => {
  const response = await api.get(`/messaging/${hubId}/message/${threadId}`);
  return response.data;
};

export const fetchMessageThread = async (
  hubId: string,
  threadId: string,
  offset: string,
  limit: string = '40',
): Promise<MessageThreadResponse> => {
  const response = await api.get(`/messaging/${hubId}/thread/${threadId}`, {
    params: {
      offset,
      limit,
    },
  });
  return response.data;
};

export const markThreadAsRead = async (
  hubId: string,
  threadId: string,
  readStatus: boolean,
): Promise<void> => {
  const response = await api.patch(`/messaging/${hubId}/message/${threadId}/`, {
    is_read: readStatus,
  });
  return response.data;
};

export const downloadAttachment = async (
  hubId: string,
  messageId: string,
  attachmentId: string,
): Promise<void> => {
  const response = await api.get(
    `/messaging/${hubId}/message/${messageId}/attachment/${attachmentId}/`,
  );
  return response.data;
};

export const saveAttachmentToLibraryReq = async (
  hubId: string,
  messageId: string,
  attachmentId: string,
): Promise<{ id: string }> => {
  const response = await api.post(
    `/messaging/${hubId}/message/${messageId}/attachment/${attachmentId}/`,
    {
      page_domain: document.location.origin,
      page_url: `${
        import.meta.env.BASE_URL
      }/messaging/${hubId}/${messageId}?threadId=${messageId}`,
      saved_date: new Date().toISOString(),
    },
  );
  return response.data;
};
