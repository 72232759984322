export type TextState = {
  text: string;
  width: number;
  height?: number;
  x: number;
  y: number;
  fontSize: number;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  rotation: number;
  colour: string;
  alignment: 'left' | 'center' | 'right';
};

export type TextAction =
  | {
      type: 'SET_TEXT';
      payload: {
        text: string;
      };
    }
  | {
      type: 'SET_WIDTH';
      payload: {
        width: number;
      };
    }
  | {
      type: 'SET_HEIGHT';
      payload: {
        height: number;
      };
    }
  | {
      type: 'SET_X';
      payload: {
        x: number;
      };
    }
  | {
      type: 'SET_Y';
      payload: {
        y: number;
      };
    }
  | {
      type: 'SET_FONTSIZE';
      payload: {
        fontSize: number;
      };
    }
  | {
      type: 'SET_BOLD';
      payload: {
        bold: boolean;
      };
    }
  | {
      type: 'SET_ITALIC';
      payload: {
        italic: boolean;
      };
    }
  | {
      type: 'SET_UNDERLINE';
      payload: {
        underline: boolean;
      };
    }
  | {
      type: 'SET_ROTATION';
      payload: {
        rotation: number;
      };
    }
  | {
      type: 'SET_COLOUR';
      payload: {
        colour: string;
      };
    }
  | {
      type: 'SET_ALIGNMENT';
      payload: {
        alignment: 'left' | 'center' | 'right';
      };
    };

export const textReducer = (
  state: TextState,
  action: TextAction,
): TextState => {
  switch (action.type) {
    case 'SET_TEXT':
      return { ...state, text: action.payload.text };
    case 'SET_WIDTH':
      return { ...state, width: action.payload.width };
    case 'SET_HEIGHT':
      return { ...state, height: action.payload.height };
    case 'SET_X':
      return { ...state, x: action.payload.x };
    case 'SET_Y':
      return { ...state, y: action.payload.y };
    case 'SET_FONTSIZE':
      return { ...state, fontSize: action.payload.fontSize };
    case 'SET_BOLD':
      return { ...state, bold: action.payload.bold };
    case 'SET_ITALIC':
      return { ...state, italic: action.payload.italic };
    case 'SET_UNDERLINE':
      return { ...state, underline: action.payload.underline };
    case 'SET_ROTATION':
      return { ...state, rotation: action.payload.rotation };
    case 'SET_COLOUR':
      return { ...state, colour: action.payload.colour };
    case 'SET_ALIGNMENT':
      return { ...state, alignment: action.payload.alignment };
    default:
      return state;
  }
};
