import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
  SyntheticEvent,
} from 'react';

import cn from 'classnames';

import { UserType } from '../../types';
import Badge from './Badge';
import UserIconBase from './UserIconBase';

import styles from './UserBadge.module.css';

export interface UserBadgeProps {
  badge?: ReactNode;
  onUpload: (e: SyntheticEvent) => void;
  size?: number;
  user: UserType;
  handleUpload: () => void;
}

const INNER_DIFF = 0;

const UserBadge = forwardRef(
  (
    { badge, onUpload, size = 44, user, handleUpload }: UserBadgeProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): ReactElement => {
    const { photo, first_name, last_name } = user;

    return (
      <div className={styles.container} style={{ width: size, height: size }}>
        <div
          className={cn(styles.roundFlex, styles.userBadge)}
          style={{ width: size, height: size }}
        >
          <div
            className={styles.roundFlex}
            style={{ width: size - INNER_DIFF, height: size - INNER_DIFF }}
          >
            <UserIconBase
              photo={photo}
              firstName={first_name ?? ''}
              lastName={last_name ?? ''}
              size={size - INNER_DIFF}
            />
            <button onClick={handleUpload} className={styles.uploadButton} />
            <input
              ref={ref}
              type="file"
              accept="image/png, image/jpeg"
              onChange={onUpload}
              className={styles.inputFile}
            />
          </div>
        </div>
        {badge && <Badge node={badge} />}
      </div>
    );
  },
);

export default UserBadge;
