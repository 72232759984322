import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { uploadImage } from '../../../../../api/hubs';
import {
  ALL_HUBS_QUERY,
  HUB_QUERY,
} from '../../../../../shared/constants/query-names';

interface Cover {
  thumbnail_hex: string;
  hubId: string;
}

export const useRemoveCover = (options?: MutateOptions<any, any, Cover>) => {
  const queryClient = useQueryClient();

  const { mutate: removeCover } = useMutation({
    mutationFn: ({ hubId, thumbnail_hex }) => {
      const formData = new FormData();

      formData.append('thumbnail_hex', thumbnail_hex);
      formData.append('thumbnail_brightness', 'light');

      return uploadImage({
        hubId,
        thumbnail: formData,
      });
    },
    ...(options ?? {}),
    onSuccess: (data, { hubId, thumbnail_hex }, context) => {
      queryClient.invalidateQueries({
        queryKey: [ALL_HUBS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [HUB_QUERY, { hubId }],
      });

      startedSnack({
        label: 'Removed cover',
        close: true,
      });

      if (options?.onSuccess)
        options?.onSuccess(data, { hubId, thumbnail_hex }, context);
    },
    onError: (error, { hubId, thumbnail_hex }, context) => {
      startedSnack({
        label: "Couldn't remove cover",
        action: {
          label: 'Try again',
          action: () => {
            removeCover({
              hubId,
              thumbnail_hex,
            });
          },
        },
        close: true,
      });

      if (options?.onError)
        options?.onError(error, { hubId, thumbnail_hex }, context);
    },
  });

  return { removeCover };
};
