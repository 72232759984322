import React, { useState } from 'react';

import { useUnit } from 'effector-react';
import { Stage } from 'konva/lib/Stage';
import { debounce } from 'lodash';
import useOnclickOutsideMultiple, { Return } from 'react-cool-onclickoutside';
import { useLocation, useParams } from 'react-router';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { DocumentHead } from '@components/DocumentHead';
import { useDesign } from '@pages/StudioPage/hooks/useDesign';
import { ErrorBoundary } from '@sentry/react';
import { SearchDialog } from '@src/entities/search/ui/searchDialog';
import { SelectedSticky } from '@src/entities/Stickies/SelectedSticky';
import { queryClient } from '@src/queryClient';

import { Canvas } from './components/Canvas';
import { Library } from './components/Library';
import { Shuffler } from './components/Shuffler';
import { Tabs } from './components/Tabs';
import { useImages } from './hooks/useImages';
import { $selectedImageBlocks, selectedImageBlockIdsAction } from './model';
import { generatePreview } from './utils';

import styles from './styles.module.css';

export const StudioPage = () => {
  const selectedImages = useUnit($selectedImageBlocks);

  const { design_id } = useParams<{ design_id?: string }>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const generateFirstThumbnail = Boolean(
    searchParams.get('generateFirstThumbnail'),
  );

  const stageRef = React.useRef<Stage | null>(null);

  const selectImageBlockRef = useOnclickOutsideMultiple(() => {
    if (selectedImages.size === 1) {
      selectedImageBlockIdsAction(new Set());
    }
  });

  if (!design_id) return <p> No design id </p>;

  return (
    <div className={styles.container}>
      <DocumentHead name="Studio" />
      <Tabs designId={design_id} stageRef={stageRef} />
      <ErrorBoundary
        fallback={
          <div className={styles.fallback}>
            <TypographyPoppins type="headline">
              Oops, something went wrong.
            </TypographyPoppins>
            <Button
              onClick={async () => {
                queryClient.clear();
                // Clearing cache required 1s to persist the empty cache in localStorage
                await new Promise((r) => setTimeout(r, 1100));
                window.location.reload();
              }}
              type="filled"
              label="Refresh"
            />
          </div>
        }
      >
        <MainCanvas
          key={design_id}
          designId={design_id}
          stageRef={stageRef}
          selectImageBlockRef={selectImageBlockRef}
          generateFirstThumbnail={generateFirstThumbnail}
        />
      </ErrorBoundary>
    </div>
  );
};

type Props = {
  designId: string;
  stageRef: React.MutableRefObject<Stage | null>;
  selectImageBlockRef: Return;
  generateFirstThumbnail: boolean;
};

const MainCanvas = ({
  designId,
  stageRef,
  selectImageBlockRef,
  generateFirstThumbnail,
}: Props) => {
  const { designQuery } = useDesign({ designId });
  const { imageQuery } = useImages({ designId });
  const { previewMutation } = useImages({ designId });
  const [shouldGenerateFirstThumbnail, setShouldGenerateFirstThumbnail] =
    useState(generateFirstThumbnail);

  const generateThumbnailsSet = () => {
    const stage = stageRef?.current;

    if (!stage) return;

    const { data } = generatePreview(stage, 'thumbnail');

    previewMutation.mutate({ file: data });
  };

  const debouncedGenerateThumbnail = React.useMemo(
    () =>
      debounce(
        generateThumbnailsSet,
        // If there are no images, we want to generate the thumbnail immeidately and subsequent images are generated after 3s
        imageQuery.data?.data.count && imageQuery.data?.data.count < 2
          ? 0
          : 3000,
      ),
    [imageQuery.data?.data.count],
  );

  if (shouldGenerateFirstThumbnail) {
    debouncedGenerateThumbnail();
    setShouldGenerateFirstThumbnail(false);
  }

  if (designQuery.isError)
    return <div className={styles.error}> Something went wrong. </div>;

  return (
    <div ref={selectImageBlockRef} className={styles.bottomContainer}>
      <SearchDialog hideMobileSearch />
      <Library
        stageRef={stageRef}
        designId={designId}
        debouncedGenerateThumbnail={debouncedGenerateThumbnail}
      />
      <Canvas
        ref={stageRef}
        key={designId}
        stageRef={stageRef}
        designId={designId}
        debouncedGenerateThumbnail={debouncedGenerateThumbnail}
      />
      <Shuffler
        stageRef={stageRef}
        designId={designId}
        debouncedGenerateThumbnail={debouncedGenerateThumbnail}
      />
      <SelectedSticky blockId={designId} />
    </div>
  );
};
