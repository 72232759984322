import React, { forwardRef, ReactNode } from 'react';

import cn from 'classnames';

import styles from './styles.module.css';

export type Variant = 'display' | 'headline' | 'title' | 'body' | 'label';
export type Size = 'S' | 'M' | 'L';
export type LabelSize = 'S' | 'M' | 'L' | 'XS';
export type BodySize = 'S' | 'M' | 'L' | 'Small strikethrough';
export type HeadlineSize = 'S' | 'M' | 'L' | 'Small semibold';

export interface TypographyPoppinsProps
  extends React.HTMLAttributes<HTMLHeadingElement & HTMLParagraphElement> {
  type: Variant;
  size?: Size;
  labelSize?: LabelSize;
  headlineSize?: HeadlineSize;
  titleSize?: Size;
  bodySize?: BodySize;
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const TypographyPoppins = forwardRef<HTMLDivElement, TypographyPoppinsProps>(
  (
    {
      type,
      size,
      labelSize,
      headlineSize,
      titleSize,
      bodySize,
      children,
      className,
      style,
      ...rest
    },
    ref,
  ) => {
    if (type === 'display' && size !== 'L') {
      return (
        <h1
          ref={ref}
          className={cn(styles.display, styles.poppins, className, {
            [styles.displayMedium]: size === 'M',
            [styles.displaySmall]: size === 'S',
          })}
          style={style}
          {...rest}
        >
          {children}
        </h1>
      );
    }

    if (type === 'headline') {
      return (
        <h2
          ref={ref}
          className={cn(styles.headline, styles.poppins, className, {
            [styles.headlineMedium]: headlineSize === 'M',
            [styles.headlineSmall]: headlineSize === 'S',
            [styles.headlineSmallsemibold]: headlineSize === 'Small semibold',
          })}
          style={style}
          {...rest}
        >
          {children}
        </h2>
      );
    }

    if (type === 'title') {
      return (
        <p
          ref={ref}
          className={cn(styles.title, styles.poppins, className, {
            [styles.titleMedium]: titleSize === 'M',
            [styles.titleSmall]: titleSize === 'S',
          })}
          style={style}
          {...rest}
        >
          {children}
        </p>
      );
    }

    if (type === 'body') {
      return (
        <p
          ref={ref}
          className={cn(styles.body, styles.poppins, className, {
            [styles.bodyMedium]: bodySize === 'M',
            [styles.bodySmall]: bodySize === 'S',
            [styles.bodySmallStrikethrough]: bodySize === 'Small strikethrough',
          })}
          style={style}
          {...rest}
        >
          {children}
        </p>
      );
    }

    if (type === 'label') {
      return (
        <span
          ref={ref}
          className={cn(styles.label, styles.poppins, className, {
            [styles.labelMedium]: labelSize === 'M',
            [styles.labelSmall]: labelSize === 'S',
            [styles.labelXSmall]: labelSize === 'XS',
          })}
          style={style}
          {...rest}
        >
          {children}
        </span>
      );
    }

    return (
      <p
        ref={ref}
        style={style}
        className={cn(styles.display, styles.poppins, className)}
        {...rest}
      >
        {children}
      </p>
    );
  },
);

export default TypographyPoppins;
