import { Group } from 'konva/lib/Group';
import { Image as TImage } from 'konva/lib/shapes/Image';
import { Text as TText } from 'konva/lib/shapes/Text';
import { Stage } from 'konva/lib/Stage';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { ImageBlock, TextBox } from '../api';
import {
  DRAG_SELECTION,
  IMAGE_GROUP,
  IMAGES_LAYER,
  TEXT_LAYER,
  TRANSFORMER,
} from '../constants';
import {
  selectedImageBlockIdsAction,
  selectedTextBlockIdsAction,
  updatedStage,
} from '../model';

export const calculateNewStagePosition = ({
  x,
  y,
  previousScale,
  newScale,
  origin,
}: {
  x: number;
  y: number;
  previousScale: number;
  newScale: number;
  origin: { x: number; y: number };
}) => {
  const mousePointTo = {
    x: origin.x / previousScale - x / previousScale,
    y: origin.y / previousScale - y / previousScale,
  };

  return {
    scale: newScale,
    x: (origin.x / newScale - mousePointTo.x) * newScale,
    y: (origin.y / newScale - mousePointTo.y) * newScale,
  };
};

export const asyncLoadImage = (imgSrc: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve(img);
    };

    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    img.src = imgSrc;
  });
};

export async function loadImageFromFile(file: File): Promise<HTMLImageElement> {
  return new Promise((res, rej) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;
      img.onload = () => {
        res(img);
      };
    };

    reader.onerror = (e) => {
      rej(e);
    };

    reader.readAsDataURL(file);
  });
}

export async function imageUrlToFile(
  url: string,
  fileName: string,
): Promise<File | null> {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    return new File([blob], fileName, { type: blob.type });
  } catch (error) {
    console.error('Error converting image URL to File:', error);
    return null;
  }
}

// Function to calculate the lower fence
export const calculateLowerFence = (values: number[]) => {
  const q1 = percentile(values, 0);
  const q3 = percentile(values, 1);
  const iqr = q3 - q1;
  return q1 - 1.5 * iqr;
};

// Function to calculate the upper fence
export const calculateUpperFence = (values: number[]) => {
  const q1 = percentile(values, 0);
  const q3 = percentile(values, 1);
  const iqr = q3 - q1;
  return q3 + 1.5 * iqr;
};

// Function to calculate the percentile
const percentile = (arr: number[], p: number) => {
  arr.sort((a, b) => a - b);
  const index = Math.floor(p * (arr.length - 1));
  return arr[index];
};

export const generatePreview = (
  stage: Stage,
  quality?: 'export' | 'high' | 'low' | 'medium' | 'thumbnail',
  mimeType?: 'image/jpeg' | 'image/png',
) => {
  const clonedStage = stage.clone();

  clonedStage.scale({ x: 1, y: 1 });
  clonedStage.x(0);
  clonedStage.y(0);

  let minX = Number.MAX_VALUE;
  let minY = Number.MAX_VALUE;
  let maxX = Number.MIN_VALUE;
  let maxY = Number.MIN_VALUE;

  const nodes: { x: number; y: number; height: number; width: number }[] = [];

  // Delete drag selection layer so it is not in the export
  clonedStage
    .getLayers()
    .find((l) => l.name() === DRAG_SELECTION)
    ?.destroy();

  clonedStage.getLayers().forEach((layer) => {
    if (layer.attrs.name === IMAGES_LAYER) {
      layer.children.forEach((g) => {
        try {
          // Check image layer
          if (g.getType() === 'Group') {
            // @ts-ignore TODO why is children not type correctly because it does exist?
            g.children.forEach((c: TImage) => {
              if (c.attrs.name === IMAGE_GROUP) {
                const height = c.height();
                const width = c.width();
                const x = c.x();
                const y = c.y();
                nodes.push({ x, y, height, width });
              }

              // Destory any transformer attached
              if (c.attrs.name === TRANSFORMER) c.destroy();
            });
          }

          if (quality === 'export') {
            startedSnack({
              label: 'Exported design',
              close: true,
            });
          }
        } catch (e) {
          console.error(e);
          return;
        }
      });
    } else if (layer.attrs.name === TEXT_LAYER) {
      // Check text layer
      layer.children.forEach((g) => {
        try {
          // @ts-ignore TODO why is children not type correctly because it does exist?
          g.children.forEach((c: TText | Group) => {
            if (c.attrs.name === TEXT_LAYER) {
              const height = c.height();
              const width = c.width();
              const x = c.x();
              const y = c.y();
              nodes.push({ x, y, height, width });
            } else c.destroy();
          });
        } catch (e) {
          console.error(e);
          return;
        }
      });
    }
  });

  if (nodes.length === 0 && quality === 'thumbnail')
    throw Error('No nodes to export');

  const xValues = nodes.map((node) => node.x);
  const yValues = nodes.map((node) => node.y);

  const { xLowerFence, xUpperFence, yLowerFence, yUpperFence } = calculateFence(
    xValues,
    yValues,
  );

  const filteredPoints = nodes.filter((node) => {
    const point = {
      x: node.x,
      y: node.y,
    };

    return (
      point.x >= xLowerFence &&
      point.x <= xUpperFence &&
      point.y >= yLowerFence &&
      point.y <= yUpperFence
    );
  });

  filteredPoints.forEach((image) => {
    minX = Math.min(minX, image.x);
    minY = Math.min(minY, image.y);
    maxX = Math.max(maxX, image.x + image.width);
    maxY = Math.max(maxY, image.y + image.height);
  });

  let pixelRatio = 1 / 2;
  let imageQuality = 0;

  switch (quality) {
    case 'export':
      pixelRatio = calculatePixelRatio({
        width: maxX - minX,
        desiredWidth: 5200,
      });
      imageQuality = 1;
      break;
    case 'high':
      pixelRatio = 1;
      imageQuality = 0.8;
      break;
    case 'medium':
      pixelRatio = 1 / 2;
      imageQuality = 0.5;
      break;
    case 'low':
      pixelRatio = 1 / 3;
      imageQuality = 0.2;
      break;
    case 'thumbnail':
      pixelRatio = calculatePixelRatio({
        width: maxX - minX,
        desiredWidth: 1200,
      });
      imageQuality = 0.4;
      break;
    default:
      pixelRatio = 1 / 4;
      imageQuality = 0.2;
  }

  const data = clonedStage.toDataURL({
    pixelRatio,
    quality: imageQuality,
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
    mimeType: mimeType,
  });

  return { data, minX, minY, maxX, maxY };
};

/**
 * Function generates a pixel rato to
 */

const calculatePixelRatio = (props: {
  width: number;
  desiredWidth: number;
}) => {
  return props.desiredWidth / props.width;
};

export async function copyImageToClipboard(base64Image: string): Promise<void> {
  // Create a temporary canvas and context
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Create a new image
  const img = new Image();
  img.src = base64Image;

  // Once the image loads, draw it onto the canvas
  img.onload = () => {
    if (context) {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);

      // Convert the canvas to a Blob
      canvas.toBlob(async (blob) => {
        if (blob) {
          // Try to copy the Blob to the clipboard
          try {
            await navigator.clipboard.write([
              new ClipboardItem({
                'image/png': blob,
              }),
            ]);
          } catch (err) {
            console.error('Failed to copy image to clipboard', err);
          }
        }
      });
    }
  };
}

export const mapValueInRange = (
  value: number,
  fromRange: [number, number],
  toRange: [number, number],
) => {
  // Maps value in range but skews to one side of the range
  const [fromLow, fromHigh] = fromRange;
  const [toLow, toHigh] = toRange;

  const fraction = (value - fromLow) / (fromHigh - fromLow);
  const nonLinearFraction = 1 - Math.pow(fraction, 50);

  return nonLinearFraction * (toHigh - toLow) + toLow;
};

/**
 * Function to generate form data for image upload
 * @param props.width
 * @param props.height
 * @param props.file
 * @param props.positionX
 * @param props.positionY
 * @param props.id
 * @param props.set
 * @param props.isHiddenFromLibrary
 *
 * @returns FormData
 * @example
 * const formData = generateFormDataForUpload({
 *  width: 100,
 * height: 100,
 * file: new File([''], 'image.jpeg'),
 * positionX: 100,
 * positionY: 100,
 * id: '123',
 * set: '123',
 * isHiddenFromLibrary: true,
 * });
 */

export const generateFormDataForUpload = (props: {
  width: number;
  height: number;
  file: File;
  positionX: number;
  positionY: number;
  id: string;
  set: string;
  isHiddenFromLibrary: boolean;
}) => {
  const formData = new FormData();

  formData.append('width', props.width.toString());
  formData.append('height', props.height.toString());
  formData.append('file', props.file);
  formData.append('position_x', Math.trunc(props.positionX).toString());
  formData.append('position_y', Math.trunc(props.positionY).toString());
  formData.append('id', props.id);
  formData.append('set', props.set);
  formData.append(
    'is_hidden_from_library',
    props.isHiddenFromLibrary.toString(),
  );

  return formData;
};

/**
 * Function to calculate the centered width and height for an image
 *
 * @param props.x
 * @param props.y
 * @param props.width
 * @param props.height
 *
 * @returns {x: number, y: number}
 */

export const centeredWidthHeightForImage = (props: {
  x: number;
  y: number;
  width: number;
  height: number;
}) => {
  return {
    x: props.x - props.width / 2,
    y: props.y - props.height / 2,
  };
};

export const getAverage = (arr: number[]) => {
  return arr.reduce((a, b) => a + b, 0) / arr.length;
};

export const getSelectedNodes = (stage: Stage, nodeIds: string[]) => {
  try {
    const nodes: (TImage | TText)[] = [];

    stage.children.forEach((layer) => {
      if (layer.name() === 'Images') {
        layer.children.forEach((g) => {
          if (g.getType() === 'Group') {
            // @ts-ignore TODO why is children not type correctly because it does exist?
            g.children.forEach((c: TImage) => {
              if (c.attrs.name === 'Image' && nodeIds.includes(c.attrs.id)) {
                nodes.push(c);
              }
            });
          }
        });
      } else if (layer.name() === 'Text') {
        layer.children.forEach((g) => {
          // @ts-ignore
          g.children.forEach((t) => {
            if (t.name() === 'Text' && nodeIds.includes(t.attrs.id)) {
              nodes.push(t);
            }
          });
        });
      }
    });

    return nodes;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const selectBlocksInSelection = ({
  stage,
  x1,
  y1,
  x2,
  y2,
}: {
  stage: Stage;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}) => {
  try {
    const ids: string[] = [];
    stage.children.forEach((layer) => {
      if (layer.name() === 'Images') {
        layer.children.forEach((g) => {
          try {
            // @ts-ignore
            g.children.forEach((c: TImage) => {
              if (c.attrs.name === 'Image') {
                if (
                  doesOverlap({
                    l1: { x: x1, y: y1 },
                    r1: { x: x2, y: y2 },
                    l2: { x: c.x(), y: c.y() },
                    r2: { x: c.x() + c.width(), y: c.y() + c.height() },
                  })
                ) {
                  ids.push(c.attrs.id);
                }
              }
            });

            selectedImageBlockIdsAction(new Set(ids));
          } catch (e) {
            console.error(e);
            return;
          }
        });
      } else if (layer.name() === 'Text') {
        layer.children.forEach((g) => {
          // @ts-ignore
          g.children.forEach((t) => {
            if (t.name() === 'Text') {
              if (
                doesOverlap({
                  l1: { x: x1, y: y1 },
                  r1: { x: x2, y: y2 },
                  l2: { x: t.x(), y: t.y() },
                  r2: { x: t.x() + t.width(), y: t.y() + t.height() },
                })
              ) {
                ids.push(t.attrs.id);
              }
            }
          });

          selectedTextBlockIdsAction(new Set(ids));
        });
      }
    });
  } catch (e) {
    console.error(e);
    selectedImageBlockIdsAction(new Set());
    selectedTextBlockIdsAction(new Set());
    return;
  }
};

type Point = {
  x: number;
  y: number;
};

/**
 * @description l is for the bounding box as this function check if r is within l or r intersects l
 */
export function doesOverlap({
  l1,
  r1,
  l2,
  r2,
}: {
  l1: Point;
  r1: Point;
  l2: Point;
  r2: Point;
}) {
  // if rectangle has area 0, no overlap
  if (l1.x === r1.x || l1.y === r1.y || r2.x === l2.x || l2.y === r2.y)
    return false;

  if (
    contains({
      a: {
        x1: l1.x,
        y1: l1.y,
        x2: r1.x,
        y2: r1.y,
      },
      b: {
        x1: l2.x,
        y1: l2.y,
        x2: r2.x,
        y2: r2.y,
      },
    })
  ) {
    return true;
  }

  if (
    overlaps({
      a: {
        x1: l1.x,
        y1: l1.y,
        x2: r1.x,
        y2: r1.y,
      },
      b: {
        x1: l2.x,
        y1: l2.y,
        x2: r2.x,
        y2: r2.y,
      },
    })
  )
    return true;

  return false;
}

/**
 *
 * @description
 * Check if a contains b
 * @param a
 * @param b
 *
 */

function contains({
  a,
  b,
}: {
  a: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  b: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
}) {
  // Ensure a is always the bounding box with the top-left corner and b with the bottom-right corner
  const [topLeft, bottomRight] = [
    { x: Math.min(a.x1, a.x2), y: Math.min(a.y1, a.y2) },
    { x: Math.max(a.x1, a.x2), y: Math.max(a.y1, a.y2) },
  ];

  return (
    b.x1 >= topLeft.x &&
    b.y1 >= topLeft.y &&
    b.x2 <= bottomRight.x &&
    b.y2 <= bottomRight.y
  );
}

/**
 * @description check if a overlaps b
 * @param a
 * @param b
 */
function overlaps({
  a,
  b,
}: {
  a: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  b: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
}) {
  // Ensure a is always the bounding box with the top-left corner and b with the bottom-right corner
  const [topLeftA, bottomRightA] = [
    { x: Math.min(a.x1, a.x2), y: Math.min(a.y1, a.y2) },
    { x: Math.max(a.x1, a.x2), y: Math.max(a.y1, a.y2) },
  ];

  const [topLeftB, bottomRightB] = [
    { x: Math.min(b.x1, b.x2), y: Math.min(b.y1, b.y2) },
    { x: Math.max(b.x1, b.x2), y: Math.max(b.y1, b.y2) },
  ];

  // no horizontal overlap
  if (topLeftA.x >= bottomRightB.x || topLeftB.x >= bottomRightA.x)
    return false;

  // no vertical overlap
  if (topLeftA.y >= bottomRightB.y || topLeftB.y >= bottomRightA.y)
    return false;

  return true;
}

export const centerStage = (props: {
  stageRef: React.MutableRefObject<Stage | null>;
  imageData: ImageBlock[];
  textData: TextBox[];
  setHasCentered: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // Calculates the center point of the stage given the images and text blocks and centers the view on that position
  if (
    typeof props.stageRef === 'function' ||
    !props.stageRef ||
    !props.stageRef.current
  )
    return;

  const stage = props.stageRef.current;
  const stageScale = 0.2;

  const blocks: {
    x: number;
    y: number;
    width: number;
    height: number;
  }[] = [
    ...props.imageData.map((b) => ({
      x: b.studio.position_x,
      y: b.studio.position_y,
      width: b.studio.position_width ?? b.width,
      height: b.studio.position_height ?? b.height,
    })),
    ...props.textData.map((t) => ({
      x: t.position_x,
      y: t.position_y,
      width: t.width,
      height: 20,
    })),
  ];

  const center = calculateCenter({ blocks });

  const x = -center.x * stageScale + stage.width() / 2;
  const y = -center.y * stageScale + stage.height() / 2;

  updatedStage({
    x: x || 0,
    y: y || 0,
    scale: stageScale,
  });

  props.setHasCentered(true);
};

type Block = {
  x: number;
  y: number;
  width: number;
  height: number;
};

/**
 * @name CalculateCenter
 * @description Calculates center of a given set of blocks. In this case a block has a height, width, x and y.
 * @param blocks
 * @returns center {x: number, y: number}
 */

export const calculateCenter = ({ blocks }: { blocks: Block[] }) => {
  const xValues = blocks.map((b) => b.x);
  const yValues = blocks.map((b) => b.y);

  const xLowerFence = calculateLowerFence(xValues);
  const xUpperFence = calculateUpperFence(xValues);

  const yLowerFence = calculateLowerFence(yValues);
  const yUpperFence = calculateUpperFence(yValues);

  const filteredPoints = blocks.filter((b) => {
    const point = {
      x: b.x,
      y: b.y,
    };

    return (
      point.x >= xLowerFence &&
      point.x <= xUpperFence &&
      point.y >= yLowerFence &&
      point.y <= yUpperFence
    );
  });

  const center = {
    x: 0,
    y: 0,
  };

  filteredPoints.forEach((b) => {
    center.x += b.x + b.width / 2;
    center.y += b.y + b.height / 2;
  });
  center.x /= filteredPoints.length;
  center.y /= filteredPoints.length;

  return center;
};

/**
 * @name Calculate Canvas
 * @description Takes the image and text data and calculates the max bounds of these elements
 * @param {ImageBlock[]} images
 * @param {TextBox[]} textboxes
 *
 * @returns {maxX: number, maxY: number, minX: number, minY: number}
 */

export const calculateTextAndImageBounds = ({
  images,
  textboxes,
}: {
  images?: ImageBlock[];
  textboxes?: TextBox[];
}) => {
  // Compute max bounds
  let minY = Infinity;
  let maxY = -Infinity;
  let maxX = -Infinity;
  let minX = Infinity;

  if (!images || !textboxes) return { maxX, maxY, minX, minY };

  for (const image of images) {
    const {
      position_x: x,
      position_y: y,
      position_width: width = 0,
      position_height: height = 0,
    } = image.studio;
    minY = Math.min(minY, y);
    minX = Math.min(minX, x);
    maxY = Math.max(maxY, y + (height ?? 0));
    maxX = Math.max(maxX, x + (width ?? 0));
  }
  for (const textbox of textboxes) {
    const { position_x: x, position_y: y, width } = textbox;
    // Height is calculated on demand so its impossible to get here without some trickery. Assume its 64 (height of 1 line)
    const HEIGHT = 64;
    minY = Math.min(minY, y);
    minX = Math.min(minX, x);
    maxY = Math.max(maxY, y + (HEIGHT ?? 0));
    maxX = Math.max(maxX, x + (width ?? 0));
  }

  return { maxX, maxY, minX, minY };
};

export const calculateBoundsFromStage = (stage: Stage) => {
  let minX = Number.MAX_VALUE;
  let minY = Number.MAX_VALUE;
  let maxX = Number.MIN_VALUE;
  let maxY = Number.MIN_VALUE;

  const nodes: { x: number; y: number; height: number; width: number }[] = [];

  stage.getLayers().forEach((layer) => {
    if (layer.attrs.name === IMAGES_LAYER) {
      layer.children.forEach((g) => {
        try {
          // Check image layer
          if (g.getType() === 'Group') {
            // @ts-ignore TODO why is children not type correctly because it does exist?
            g.children.forEach((c: TImage) => {
              if (c.attrs.name === IMAGE_GROUP) {
                const height = c.height();
                const width = c.width();
                const x = c.x();
                const y = c.y();
                nodes.push({ x, y, height, width });
              }
            });
          }
        } catch (e) {
          console.error(e);
          return;
        }
      });
    } else if (layer.attrs.name === TEXT_LAYER) {
      // Check text layer
      layer.children.forEach((g) => {
        try {
          // @ts-ignore TODO why is children not type correctly because it does exist?
          g.children.forEach((c: TText | Group) => {
            if (c.attrs.name === TEXT_LAYER) {
              const height = c.height();
              const width = c.width();
              const x = c.x();
              const y = c.y();
              nodes.push({ x, y, height, width });
            }
          });
        } catch (e) {
          console.error(e);
          return;
        }
      });
    }
  });

  const xValues = nodes.map((node) => node.x);
  const yValues = nodes.map((node) => node.y);

  const { xLowerFence, xUpperFence, yLowerFence, yUpperFence } = calculateFence(
    xValues,
    yValues,
  );

  const filteredPoints = nodes.filter((node) => {
    const point = {
      x: node.x,
      y: node.y,
    };

    return (
      point.x >= xLowerFence &&
      point.x <= xUpperFence &&
      point.y >= yLowerFence &&
      point.y <= yUpperFence
    );
  });

  filteredPoints.forEach((image) => {
    minX = Math.min(minX, image.x);
    minY = Math.min(minY, image.y);
    maxX = Math.max(maxX, image.x + image.width);
    maxY = Math.max(maxY, image.y + image.height);
  });

  return { minX, minY, maxX, maxY };
};

/**
 * @description Given a list of x and y numbers get the upper and lower fence
 * @param xValues
 * @param yValues
 * @returns
 */

const calculateFence = (xValues: number[], yValues: number[]) => {
  const xLowerFence = calculateLowerFence(xValues);
  const xUpperFence = calculateUpperFence(xValues);

  const yLowerFence = calculateLowerFence(yValues);
  const yUpperFence = calculateUpperFence(yValues);

  return { xLowerFence, xUpperFence, yLowerFence, yUpperFence };
};
