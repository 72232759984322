import { useUnit } from 'effector-react';

import {
  SideSheet,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';

import { $isTrialSheetOpen, closedTrialSheet } from '@pages/AccountPage/models';
import { useBilling } from '@src/shared/queries/useBilling';

import styles from './styles.module.css';

export const TrialSheet = () => {
  const isTrialSheetOpen = useUnit($isTrialSheetOpen);
  const { isMobile } = useWindowSize();

  const { query, membershipQuery, checkoutURLMutation } =
    useBilling(closedTrialSheet);

  const activateTrial = () => {
    if (!query.data || !membershipQuery.data) return;
    if (
      query.data.membershipTierInfo.plan === 'pro' ||
      query.data.membershipTierInfo.plan === 'trial'
    ) {
      // Failed case
      console.error(
        `Couldn't activate trial, user is pro or trial: ${query.data.membershipTierInfo.plan}`,
      );
      return;
    }

    const plan = membershipQuery.data.availablePlans['pro_monthly'];

    const stripePriceId = plan.stripePriceId;

    checkoutURLMutation.mutate({
      stripePriceId,
      currentPlan: 'free',
      planToChangeTo: 'trial',
      isAnnual: false,
    });

    return;
  };

  const closeSheet = () => closedTrialSheet();

  return (
    <SideSheet
      className={styles.sideSheetContainer}
      handleBackdropClick={closeSheet}
      showSheet={isTrialSheetOpen}
      closeSideSheet={closeSheet}
    >
      <SideSheet.Heading closeSideSheet={closeSheet}>
        Trial Pro today
      </SideSheet.Heading>
      <SideSheet.Body>
        <SideSheet.ContentContainer>
          {isMobile ? (
            <TypographyPoppins type="title" size="L">
              <span className={styles.headingLabel}>Trial Pro today</span>
            </TypographyPoppins>
          ) : null}
          <SideSheet.Content>
            <div className={styles.content}>
              Trial Pro free for 15 days, and get $120 worth of free hub credits
              (equivalent to 100 credits).
              <br />
              <br />
              The Pro plan allows you to
              <ul className={styles.list}>
                <li>create hubs</li>
                <li>create unlimited docs</li>
                <li>invite guests</li>
              </ul>
            </div>
          </SideSheet.Content>
        </SideSheet.ContentContainer>
      </SideSheet.Body>
      <SideSheet.BottomControls
        primaryLabel="Continue"
        hideCancel
        mainClick={activateTrial}
        closeSideSheet={closeSheet}
      />
    </SideSheet>
  );
};
