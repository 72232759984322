import { ButtonHTMLAttributes, forwardRef } from 'react';

import cn from 'classnames';

import styles from './styles.module.css';

export const VaiBeacon = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ ...props }, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className={cn(styles.beacon, props.className)}
    ></button>
  );
});

VaiBeacon.displayName = 'VaiBeacon';
