import { useParams } from 'react-router';

import { Member, ResponseInvitee } from '@api/hubs';
import { useChangePermissionsHub } from '@pages/HubPage/model/queries/useChangePermissionsHub';
import { useRemoveInviteeFromHub } from '@pages/HubPage/model/queries/useRemoveInviteeFromHub';
import { useAppData } from '@src/AppContext';

import { InvitedUser } from '../User/InvitedUser';
import { Owner } from '../User/Owner';

import styles from './styles.module.css';

type InviteesProps = {
  invitees: ResponseInvitee;
  hubName: string;
};

export const Invitees = ({ invitees, hubName }: InviteesProps) => {
  const { hub_id } = useParams<{ hub_id: string }>();

  const { user } = useAppData();

  const { changePermissionHub } = useChangePermissionsHub();

  const { removeInviteeFromHub } = useRemoveInviteeFromHub();

  const handleRemoveInviteeFromHub = (member: Member) => {
    const emailRemovedUser = invitees.members?.filter(
      (invitee) => invitee.email === member.email,
    )[0].email;

    const noHubAccess = user.email === emailRemovedUser;

    removeInviteeFromHub({
      hubId: hub_id,
      hubName,
      members: [member.id],
      noHubAccess,
    });
  };

  return (
    <>
      <ul className={styles.guestList}>
        {invitees?.members?.map((invitee) => {
          if (invitee.is_owner) {
            return <Owner key={invitee.id} user={invitee} />;
          } else {
            return (
              <InvitedUser
                key={invitee.id}
                user={invitee}
                permissions={invitee.permissions}
                changePermissionHub={changePermissionHub}
                handleRemoveInviteeFromHub={handleRemoveInviteeFromHub}
              />
            );
          }
        })}
      </ul>
    </>
  );
};
