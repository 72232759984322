import { Dispatch, forwardRef, SetStateAction, useState } from 'react';

import { useUnit } from 'effector-react';
import { AnimatePresence, motion } from 'framer-motion';

import { Button, Tooltip } from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { generateId } from '@src/shared/utils/id';

import {
  $selectedColours,
  $showColourPaletteSearch,
  removedColour,
  searchedColours,
  selectedColour,
} from '../../model';
import { hexToRgb, rgbToHsl } from '../../util/colour';

import styles from './styles.module.css';

export const SearchBar = forwardRef<
  HTMLInputElement,
  {
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
    searchText: string;
    setSearchText: Dispatch<SetStateAction<string>>;
  } & React.InputHTMLAttributes<HTMLInputElement>
>(({ onClick, searchText, setSearchText, ...props }, inputRef) => {
  const [showColourPaletteSearch] = useUnit([$showColourPaletteSearch]);
  if (showColourPaletteSearch) {
    return <ColourSearchBar ref={inputRef} {...props} onClick={onClick} />;
  }

  return (
    <ImageSearchBar
      ref={inputRef}
      {...props}
      onClick={onClick}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );
});

const ImageSearchBar = forwardRef<
  HTMLInputElement,
  {
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
    searchText: string;
    setSearchText: Dispatch<SetStateAction<string>>;
  } & React.InputHTMLAttributes<HTMLInputElement>
>(({ onClick, searchText, setSearchText, ...props }, inputRef) => {
  const { width } = useWindowSize();

  return (
    <div className={styles.container}>
      <input
        {...props}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        type="text"
        ref={inputRef}
        className={styles.input}
        placeholder={`Type to find something ${
          width > 767 ? "(it's faster than scrolling)" : ''
        }`}
        onClick={onClick}
      />
      <div className={styles.iconContainer} onClick={onClick}>
        <Icon name="sprite/magnifier" className={styles.icon} />
      </div>
      <div className={styles.iconXContainer}>
        <button
          className={styles.closeButton}
          onClick={() => {
            setSearchText('');
          }}
        >
          <Icon name="sprite/x" className={styles.icon} />
        </button>
      </div>
    </div>
  );
});

const isValidHex = (hex: string) => {
  return /^#([0-9a-f]{3}){1,2}$/i.test(hex);
};

const ColourSearchBar = forwardRef<
  HTMLInputElement,
  {
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
    // searchText: string;
    // setSearchText: Dispatch<SetStateAction<string>>;
  } & React.InputHTMLAttributes<HTMLInputElement>
>(({ onClick, ...props }, inputRef) => {
  const [selectedColours] = useUnit([$selectedColours]);
  const [searchText, setSearchText] = useState('');
  const [isValidating, setIsValidating] = useState(false);

  const isValidColour = isValidHex(searchText);

  const validateColourHex = () => {
    if (isValidColour) {
      selectedColour({
        hex: searchText,
        id: generateId(),
      });

      setSearchText('');
      setIsValidating(false);

      if (selectedColours.length >= 4) {
        // Perform auto search
        handleSearch();
      }

      return;
    } else {
      setIsValidating(true);
    }

    // Early return if the user hasn't typed anything
    if (searchText.length === 0) return;

    // Otherwise, show an error
    startedSnack({
      label: 'Try again with a valid hex code',
    });
  };

  const handleSearch = () => searchedColours();

  return (
    <div className={styles.container}>
      {selectedColours.length === 5 ? (
        <Tooltip
          parameter={{
            type: 'plain',
            description: 'You can only select 5 colours',
            position: 'bottom',
          }}
          wrapperClassName={styles.tooltipWrapper}
        >
          <input
            {...props}
            disabled={selectedColours.length >= 5}
            value={searchText}
            onChange={(e) => {
              if (selectedColours.length >= 5) {
                // TODO add error snack
                return;
              }

              if (searchText.length === 0 && e.target.value !== '#') {
                setSearchText(`#${e.target.value}`);
                return;
              }

              setSearchText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                if (searchText.length === 0 && selectedColours.length > 0) {
                  handleSearch();
                  return;
                }
                if (searchText.length > 0 && selectedColours.length <= 4) {
                  validateColourHex();
                  return;
                }

                handleSearch();
              }
              if (e.key === 'Backspace') {
                if (searchText.length === 0 && selectedColours.length > 0) {
                  removedColour(selectedColours[selectedColours.length - 1].id);
                }
              }
            }}
            // onBlur={validateColourHex}
            type="text"
            ref={inputRef}
            className={styles.input}
            placeholder="#646464"
            onClick={onClick}
            style={
              {
                '--selected-colours-width': `${48 * selectedColours.length}px`,
                '--text-colour':
                  isValidating && !isValidColour
                    ? 'var(--color-error-40'
                    : 'var(--color-neutral-variant-30)',
              } as React.CSSProperties
            }
          />
        </Tooltip>
      ) : (
        <input
          disabled={selectedColours.length >= 5}
          {...props}
          value={searchText}
          onChange={(e) => {
            if (selectedColours.length >= 5) {
              // TODO add error snack
              return;
            }

            if (searchText.length === 0 && e.target.value !== '#') {
              setSearchText(`#${e.target.value}`);
              return;
            }

            setSearchText(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              if (searchText.length === 0 && selectedColours.length > 0) {
                handleSearch();
                return;
              }
              if (searchText.length > 0 && selectedColours.length <= 4) {
                validateColourHex();
                return;
              }

              handleSearch();
            }
            if (e.key === 'Backspace') {
              if (searchText.length === 0 && selectedColours.length > 0) {
                removedColour(selectedColours[selectedColours.length - 1].id);
              }
            }
          }}
          // onBlur={validateColourHex}
          type="text"
          ref={inputRef}
          className={styles.input}
          placeholder={
            selectedColours.length === 0
              ? 'Type a hex code or pick a swatch below'
              : '#646464'
          }
          onClick={onClick}
          style={
            {
              '--selected-colours-width': `${48 * selectedColours.length}px`,
              '--text-colour':
                isValidating && !isValidColour
                  ? 'var(--color-error-40'
                  : 'var(--color-neutral-variant-30)',
            } as React.CSSProperties
          }
        />
      )}
      {selectedColours.length !== 0 ? (
        <div className={styles.selectedColoursContainer}>
          {selectedColours.map((colour) => {
            const rgb = hexToRgb(colour.hex);
            if (!rgb) return null;
            const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
            const isLight = hsl.l > 90;

            return (
              <div
                key={colour.id}
                className={styles.selectedColour}
                style={
                  {
                    backgroundColor: colour.hex,
                    '--border-width': isLight ? '1px' : '0px',
                  } as React.CSSProperties
                }
              >
                {/* <Tooltip
                  parameter={{
                    description: 'Remove color',
                    type: 'plain',
                    position: 'right',
                    hasVisualBoundary: true,
                  }}
                  className={styles.selectedColourRemoveButtonTooltip}
                > */}
                <button
                  onClick={() => {
                    removedColour(colour.id);
                  }}
                  className={styles.selectedColourRemoveButton}
                >
                  <Icon name="sprite/x" className={styles.removeColourIcon} />
                </button>
                {/* </Tooltip> */}
              </div>
            );
          })}
        </div>
      ) : null}
      <div className={styles.trailingContainer}>
        <AnimatePresence mode="wait">
          {searchText.length === 0 ? (
            <>
              {selectedColours.length > 0 ? (
                <motion.span
                  key="2"
                  initial={{
                    opacity: 0,
                    x: 0,
                  }}
                  animate={{
                    opacity: 1,
                    x: -30,
                  }}
                  exit={{
                    opacity: 0,
                    x: 40,
                  }}
                  transition={{
                    duration: 0.15,
                  }}
                >
                  <Button label="Search" onClick={handleSearch} type="ghost" />
                </motion.span>
              ) : null}
            </>
          ) : (
            <motion.button
              key="1"
              initial={{
                opacity: 0,
                x: -16,
              }}
              animate={{
                opacity: 1,
                x: 0,
              }}
              exit={{
                opacity: 0,
                x: 16,
              }}
              transition={{
                duration: 0.15,
              }}
              className={styles.closeButton}
              onClick={() => {
                setSearchText('');
              }}
            >
              <Icon name="sprite/x" className={styles.icon} />
            </motion.button>
          )}
        </AnimatePresence>
      </div>
      <div className={styles.iconContainer} onClick={onClick}>
        <Icon name="sprite/magnifier" className={styles.icon} />
      </div>
    </div>
  );
});
