import { SearchColour } from '../../model';
import { hexToRgb, hslToRgb, rgbToHsl } from '../../util/colour';

/**
 * Generates a coloured canvas with a gradient of hues and lightness.
 *
 * @param {Object} params - The parameters for generating the canvas.
 * @param {HTMLCanvasElement} params.canvas - The canvas element to draw on.
 * @param {Object} params.canvasSize - The dimensions of the canvas.
 * @param {number} params.canvasSize.width - The width of the canvas.
 * @param {number} params.canvasSize.height - The height of the canvas.
 *
 * This function:
 * 1. Creates an image data object based on the canvas size.
 * 2. Iterates through each pixel, calculating its color based on position:
 *    - x-axis determines hue (0 to 360)
 *    - y-axis determines lightness (100 at top to 0 at bottom)
 *    - saturation is set to 100% for vibrant colors
 * 3. Sets the calculated color for each pixel in the image data.
 * 4. Applies the image data to the canvas.
 * 5. Applies a blur effect to the entire canvas.
 */

export const generateColouredCanvas = ({
  canvas,
  canvasSize,
}: {
  canvas: HTMLCanvasElement;
  canvasSize: {
    width: number;
    height: number;
  };
}) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  const width = canvasSize.width;
  const height = canvasSize.height;
  const imageData = ctx.createImageData(width, height);
  const data = imageData.data;

  for (let y = 0; y < height; y++) {
    // Calculate lightness based on y position (0 at top, 100 at bottom)
    const lightness = 100 - (y / height) * 100;

    for (let x = 0; x < width; x++) {
      // Calculate hue based on x position (0 to 360)
      const hue = (x / width) * 360;

      // Saturation set to 100% for vibrant colors
      const saturation = 100;

      // Get RGB values from HSL
      const color = hslToRgb(hue, saturation, lightness);

      const index = (y * width + x) * 4;
      data[index] = color.r; // Red
      data[index + 1] = color.g; // Green
      data[index + 2] = color.b; // Blue
      data[index + 3] = 255; // Alpha
    }
  }

  ctx.putImageData(imageData, 0, 0);

  ctx.filter = 'blur(12px)';
  ctx.drawImage(canvas, 0, 0);
  ctx.filter = 'none'; // Reset filter
};

/**
 * Recalculates the positions of colours on the canvas.
 *
 * @param colours - An array of SearchColour objects to recalculate positions for.
 * @param canvas - The HTMLCanvasElement on which the colours are displayed.
 * @returns An array of updated SearchColour objects with new x and y coordinates, or undefined if the canvas context is not available.
 *
 * This function:
 * 1. Gets the canvas dimensions.
 * 2. Maps over each colour in the input array.
 * 3. Calculates new x and y coordinates for each colour based on its hex value.
 * 4. Returns a new array of colours with updated positions.
 */

export const recalculateColourPositions = (
  colours: Array<SearchColour>,
  canvas: HTMLCanvasElement,
) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  const width = canvas.getBoundingClientRect().width;
  const height = canvas.getBoundingClientRect().height;

  const updatedColours = colours.map((colour) => {
    const { x, y } = getCoordinatesForHex(colour.hex, { width, height });

    return { ...colour, x, y };
  });

  return updatedColours;
};

/**
 * Calculates the coordinates on the canvas for a given hex color.
 *
 * @param hex - The hex color string to find coordinates for.
 * @param canvasSize - An object containing the width and height of the canvas.
 * @returns An object with x and y coordinates corresponding to the color's position on the canvas.
 *
 * The function works as follows:
 * 1. Converts the hex color to RGB.
 * 2. Converts RGB to HSL.
 * 3. Maps the hue (0-360) directly to the x-coordinate.
 * 4. Maps the lightness (0-100) to the y-coordinate, inverting it so that
 *    black (0) is at the bottom and white (100) is at the top.
 *
 * This allows for intuitive color selection where:
 * - Moving left to right changes the hue.
 * - Moving top to bottom changes from light to dark.
 */

export const getCoordinatesForHex = (
  hex: string,
  canvasSize: { width: number; height: number },
) => {
  const rgb = hexToRgb(hex);
  if (!rgb) return { x: 0, y: 0 };

  const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);

  // Map hue (0-360) directly to x-coordinate
  const x = Math.round((hsl.h / 360) * canvasSize.width);

  // Map lightness (0-100) to y-coordinate, inverting it
  // so that 0 (black) is at the bottom and 100 (white) is at the top
  const y = Math.round((1 - hsl.l / 100) * canvasSize.height);

  return { x, y };
};
