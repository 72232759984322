import { useCallback, useEffect, useMemo, useRef } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { debounce } from 'lodash';

import { backgroundSelected } from '@visualist/design-system/src/components/v2/color-menu/model/select-background';
import { useWindowSize } from '@visualist/hooks';

import { ResponseDoc } from '@api/docs';
import { useAppData } from '@src/AppContext';
import { DocSidebar } from '@src/entities/sidebar';
import { hidingTooltip } from '@src/widgets/onboarding/model';
import { EditorContent, JSONContent, useEditor } from '@tiptap/react';

import {
  $isShowElementsPanel,
  $isShowFilesPanel,
} from '../../../../pages/DocPage/model/sidebar-opening';
import { extensions } from '../../extensions';
import { plugins } from '../../plugins';
import { useGetDoc } from '../../queries/useGetDoc';
import { useUpdateDoc } from '../../queries/useUpdateDoc';
import { uploadImage } from '../../upload';
import { TableColumnMenu } from '../menus/table/column-options';
import { TableRowMenu } from '../menus/table/row-options';
import { Toolbar } from '../toolbar';
import { UsedDocumentBanner } from '../used-document-banner';

import styles from './styles.module.css';

export const Editor = ({ docId }: { docId: string }) => {
  const { user } = useAppData();
  const isShowElementsPanel = useUnit($isShowElementsPanel);
  const isShowFilesPanel = useUnit($isShowFilesPanel);

  const {
    data: doc,
    isError,
    isFetching,
  } = useGetDoc({
    doc_id: docId,
    options: {
      refetchInterval: (query) => {
        const response = query.state.data;

        if (!response) return false;

        if (response.is_being_edited && response.edited_by.id !== user.id) {
          return 10000;
        }
        return false;
      },
    },
  });

  if (isError) {
    hidingTooltip();
  }

  // Commented code used for DPI measuring to scale document down
  // const inchRef = useRef<HTMLDivElement>(null);
  // const widthMM = useSyncExternalStore(
  //   (onStoreChange) => {
  //     window.addEventListener('resize', onStoreChange);
  //     return () => window.removeEventListener('resize', onStoreChange);
  //   },
  //   () => {
  //     if (!parentRef.current || !inchRef.current) return 0;
  //     const inchWidth = inchRef.current.offsetWidth;
  //     const dpi = window.devicePixelRatio * inchWidth;
  //     return (parentRef.current.getBoundingClientRect().width * 25.4) / dpi;
  //   },
  // );

  if (!doc) {
    return (
      <div className={styles.pageContainer}>
        <div
          className={cn(styles.container, styles.loading, {
            // TODO: Add DPI measurement to scale down document based on width
            [styles.reduceScale]: isShowElementsPanel || isShowFilesPanel,
          })}
          style={
            {
              // Height and Width in mm
              '--width': 8.5,
              '--min-height': 11,
              position: 'relative',
            } as React.CSSProperties
          }
        >
          <div>
            <div className="ProseMirror"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <MainEditor initialContent={doc.json} doc={doc} isFetching={isFetching} />
  );
};

const MainEditor = ({
  initialContent,
  doc,
  isFetching,
}: {
  initialContent: string | JSONContent;
  doc: ResponseDoc;
  isFetching: boolean;
}) => {
  const { user } = useAppData();
  const isShowElementsPanel = useUnit($isShowElementsPanel);
  const isShowFilesPanel = useUnit($isShowFilesPanel);

  const parentRef = useRef<HTMLDivElement>(null);

  const pageContainerRef = useRef<HTMLDivElement>(null);

  const editorRef = useRef<HTMLDivElement>(null);

  const { editDoc } = useUpdateDoc();

  const isDocEditedByOtherUser = useMemo(() => {
    return doc.is_being_edited && doc.edited_by.id !== user.id;
  }, [doc.is_being_edited, doc.edited_by, user.id]);

  const handleEditDoc = (json: JSONContent, html: string) => {
    editDoc({
      docId: doc.id,
      json,
      html,
    });
  };

  const updateDoc = useCallback(debounce(handleEditDoc, 1000), []);

  const editor = useEditor({
    //@ts-ignore
    extensions: [...extensions, ...plugins()],
    content: initialContent,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      const json = editor.getJSON();
      updateDoc(json, html);
    },
    // autofocus: 'end',
    editorProps: {
      handleDrop: (view, event, _, moved) => {
        if (
          !moved &&
          event.dataTransfer &&
          event.dataTransfer.files &&
          event.dataTransfer.files[0] &&
          !event.dataTransfer?.getData('imageData')
        ) {
          uploadImage({
            files: event.dataTransfer.files,
            view,
          });
          return true;
        }
        // Use default behaviour (plugin for dropping files)
        return false;
      },
    },
    editable: !isDocEditedByOtherUser,
  });

  useEffect(() => {
    if (doc && editor) {
      backgroundSelected(doc.background_color);
      editor.commands.setBackground(doc.background_color);
    }
  }, [doc, editor]);

  useEffect(() => {
    if (isDocEditedByOtherUser && doc && editor) {
      // If another user is editing the document, update the content
      if (Array.isArray(doc.json)) {
        const updatedContent = doc.json;
        editor.commands.setContent(updatedContent);
      } else {
        const updatedContent = doc.json ?? {
          type: 'doc',
          content: [
            {
              type: 'paragraph',
              attrs: {
                textAlign: 'left',
              },
            },
          ],
        };
        editor.commands.setContent(updatedContent);
      }
    }
  }, [isDocEditedByOtherUser, doc, editor]);

  if (!editor)
    return (
      <div
        className={cn(styles.container, styles.loading, {
          // TODO: Add DPI measurement to scale down document based on width
          [styles.reduceScale]: isShowElementsPanel || isShowFilesPanel,
        })}
        style={
          {
            // Height and Width in mm
            '--width': 8.5,
            '--min-height': 11,
            position: 'relative',
          } as React.CSSProperties
        }
      >
        <div>
          <div className="ProseMirror"></div>
        </div>
      </div>
    );

  const documentHeight =
    editorRef.current?.children[0]?.getBoundingClientRect().height;

  return (
    <div className={styles.pageContainer} ref={pageContainerRef}>
      <Toolbar editor={editor} showToolbar={!isDocEditedByOtherUser} />
      <DocSidebar editor={editor} />
      <div className={styles.container}>
        {isDocEditedByOtherUser && (
          <UsedDocumentBanner isFetching={isFetching} />
        )}
        <div
          ref={parentRef}
          className={cn(styles.editorContainer, {
            // TODO: Add DPI measurement to scale down document based on width
            [styles.reduceScale]: isShowElementsPanel || isShowFilesPanel,
          })}
          style={
            {
              // Height and Width in mm
              '--width': doc.width,
              '--min-height': doc.height,
              '--margin-top': doc.margin_top,
              '--margin-left': doc.margin_left,
              '--margin-right': doc.margin_right,
              '--margin-bottom': doc.margin_bottom,
              position: 'relative',
              pointerEvents: isDocEditedByOtherUser ? 'none' : 'auto',
            } as React.CSSProperties
          }
        >
          <EditorContent ref={editorRef} autoFocus editor={editor} />
          {/* <DragItemMenu editor={editor} /> */}
          <TableColumnMenu editor={editor} appendTo={pageContainerRef} />
          <TableRowMenu editor={editor} appendTo={pageContainerRef} />
          {/* <GeneralOptionsMenu editor={editor} appendTo={pageContainerRef} /> */}
          <DividerLines
            documentHeight={documentHeight}
            isShowElementsPanel={isShowElementsPanel}
            isShowFilesPanel={isShowFilesPanel}
            pageSizeInInch={{
              height: doc.height,
              width: doc.width,
            }}
            pageMarginsInInch={{
              top: doc.margin_top,
              left: doc.margin_left,
              right: doc.margin_right,
              bottom: doc.margin_bottom,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const DividerLines = ({
  documentHeight,
  isShowElementsPanel,
  isShowFilesPanel,
  pageSizeInInch,
}: {
  documentHeight: number | undefined;
  isShowElementsPanel: boolean;
  isShowFilesPanel: boolean;
  pageSizeInInch: {
    height: number;
    width: number;
  };
  pageMarginsInInch: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
}) => {
  const pixelPerInch = useMemo(calculatePixelPerInch, []);
  const { width } = useWindowSize();

  if (!documentHeight || width < 767) return null;

  const totalHeightInInch = documentHeight / pixelPerInch;

  const numberOfLines = Math.floor(totalHeightInInch / pageSizeInInch.height);

  const arr = Array.from({ length: numberOfLines }, (_, i) => i);

  if (totalHeightInInch < pageSizeInInch.height) return null;

  return (
    <div
      className={cn(styles.lineContainer, {
        [styles.reduceScale]: isShowElementsPanel || isShowFilesPanel,
      })}
    >
      <div className={styles.relative}>
        {arr.map((_, idx) => {
          return (
            <div
              key={idx}
              className={styles.line}
              style={{
                position: 'absolute',
                top: (idx + 1) * (pageSizeInInch.height * pixelPerInch),
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

const calculatePixelPerInch = () => {
  const tempDiv = document.createElement('div');
  tempDiv.style.width = '1in';
  document.body.appendChild(tempDiv);
  const pixelsPerInch = tempDiv.getBoundingClientRect().width;
  document.body.removeChild(tempDiv);

  return pixelsPerInch;
};
