export const vaiMutationKeys = {
  all: ['all'],
  generateHubSummary: (hubId: string) => [
    ...vaiMutationKeys.all,
    'generateHubSummary',
    hubId,
  ],
  generateThreadSummary: (hubId: string) => [
    ...vaiMutationKeys.all,
    'generateThreadSummary',
    hubId,
  ],
  generateEmailSummary: (hubId: string) => [
    ...vaiMutationKeys.all,
    'generateEmailSummary',
    hubId,
  ],
  generateEmailActionItems: (hubId: string) => [
    ...vaiMutationKeys.all,
    'generateEmailActionItems',
    hubId,
  ],
  generateDraftEmailResponse: (hubId: string) => [
    ...vaiMutationKeys.all,
    'generateDraftEmailResponse',
    hubId,
  ],
  toggleEmailPin: (hubId: string) => [
    ...vaiMutationKeys.all,
    'toggleEmailPin',
    hubId,
  ],
  sendMessageToConversation: (hubId: string) => [
    ...vaiMutationKeys.all,
    'sendMessageToConversation',
    hubId,
  ],
};

export const mutateActionKey = {
  all: ['mutatingAction'] as const,
  action: (id: string) => [...mutateActionKey.all, id],
};
