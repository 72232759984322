import { createEvent, createStore } from 'effector';

import { closedPanel, fileCardIdUnset } from '@pages/FileCard/model';

export const hoveredSticky = createEvent<string | null>();
export const selectedSticky = createEvent<string | null>();
export const selectedStickyToDelete = createEvent<string | null>();
export const selectedThreadToDelete = createEvent<string | null>();
export const editedSticky = createEvent<string | null>();

export const $hoveredStickyId = createStore<string | null>(null);
export const $selectedStickyId = createStore<string | null>(null);
export const $stickyToDeleteId = createStore<string | null>(null);
export const $threadToDeleteId = createStore<string | null>(null);
export const $editingStickyId = createStore<string | null>(null);

$hoveredStickyId
  .on(hoveredSticky, (_, id) => id)
  .reset([closedPanel, fileCardIdUnset]);

$selectedStickyId
  .on(selectedSticky, (_, id) => id)
  .reset([closedPanel, fileCardIdUnset]);

$stickyToDeleteId
  .on(selectedStickyToDelete, (_, p) => p)
  .reset([closedPanel, fileCardIdUnset]);

$threadToDeleteId
  .on(selectedThreadToDelete, (_, p) => p)
  .reset([closedPanel, fileCardIdUnset]);

$editingStickyId
  .on(editedSticky, (_, p) => p)
  .reset([closedPanel, fileCardIdUnset]);
