import { Dispatch, useState } from 'react';

import { useUnit } from 'effector-react';
import { useParams } from 'react-router';

import { Divider, SideSheet } from '@visualist/design-system/src/components/v2';

import {
  getInviteesHub,
  PermissionsHub,
  ResponseInvitee,
  UserInvitationHub,
} from '@api/hubs';
import { getInviteListHub } from '@api/users';
import { $isShareSheetOpen, shareSideSheetClosed } from '@pages/HubPage/model';
import { useInviteToHub } from '@pages/HubPage/model/queries/useInviteToHub';
import {
  MEMBERS_INVITATION_HUB_QUERY,
  MEMBERS_SUGGESTIONS_HUB_QUERY,
} from '@src/shared/constants/query-names';
import { useQuery } from '@tanstack/react-query';

import { Description } from './Description';
import { ExtraSeats } from './ExtraSeats';
import { GuestSeat } from './GuestSeat';
import { Header } from './Header';
import { Invite } from './Invite';
import { Invitees } from './Invitees';
import { InviteField } from './InviteField';
import { ExistingUser } from './User/ExistingUser';

import styles from './styles.module.css';

type Props = {
  hubId: string;
  users: UserInvitationHub[];
  setUsers: Dispatch<UserInvitationHub[]>;
  invitation: UserInvitationHub;
  setInvitation: Dispatch<UserInvitationHub>;
  handleSendInviteHub: () => void;
  message: string;
  setMessage: Dispatch<string>;
  permissions: PermissionsHub[];
  setPermissions: Dispatch<PermissionsHub[]>;
  hubName: string;
};

export const HubShareSheet = ({
  hubId,
  setInvitation,
  invitation,
  users,
  setUsers,
  permissions,
  setPermissions,
  hubName,
}: Props) => {
  const isShareSheetOpen = useUnit($isShareSheetOpen);

  const { hub_id } = useParams<{ hub_id: string }>();

  const { data: invitees } = useQuery({
    queryKey: [MEMBERS_INVITATION_HUB_QUERY, { hub_id }],
    queryFn: () => getInviteesHub(hub_id),
  });

  const closeSheet = () => shareSideSheetClosed();

  return (
    <SideSheet
      className={styles.sideSheetContainer}
      type="large"
      showSheet={isShareSheetOpen}
      handleBackdropClick={closeSheet}
      closeSideSheet={closeSheet}
    >
      <SideSheet.Heading
        className={styles.headingContainer}
        closeSideSheet={closeSheet}
      >
        Team
      </SideSheet.Heading>
      <SideSheet.Body className={styles.bodyContainer}>
        <SideSheet.ContentContainer>
          <div className={styles.inviteByEmail}>
            <InviteOthers
              hubId={hubId}
              invitation={invitation}
              setInvitation={setInvitation}
              users={users}
              setUsers={setUsers}
              permissions={permissions}
              setPermissions={setPermissions}
              hubName={hubName}
              invitees={invitees}
            />
            <Divider className={styles.divider} type="short-line" />
            <Header
              title="Already invited"
              description="Those invited will have permissions to all items nested in this space"
            />
            {invitees ? (
              <Invitees invitees={invitees} hubName={hubName} />
            ) : null}
          </div>
        </SideSheet.ContentContainer>
      </SideSheet.Body>
    </SideSheet>
  );
};

export const InviteOthers = ({
  hubId,
  invitation,
  setInvitation,
  users,
  setUsers,
  permissions,
  setPermissions,
  hubName,
  invitees,
}: {
  hubId: string;
  users: UserInvitationHub[];
  setUsers: Dispatch<UserInvitationHub[]>;
  invitation: UserInvitationHub;
  setInvitation: Dispatch<UserInvitationHub>;
  permissions: PermissionsHub[];
  setPermissions: Dispatch<PermissionsHub[]>;
  hubName: string;
  invitees: ResponseInvitee | undefined;
}) => {
  const [isInvite, setInvite] = useState(true);

  const { data: suggestions = [] } = useQuery({
    queryKey: [MEMBERS_SUGGESTIONS_HUB_QUERY, hubId, invitation.email],
    queryFn: () => getInviteListHub({ email: invitation.email, hubId }),
    enabled: invitation.email.length > 0 && users.length === 0,
  });

  const onSuccess = () => {
    if (users.length > 0) {
      clear();
    }

    setInvite(true);
  };

  const onError = () => {
    setInvite(true);
  };

  const { sendInvitationHub } = useInviteToHub({ onSuccess, onError });

  const clear = () => {
    if (users.length > 0) {
      setUsers([]);
    }

    setInvitation({
      email: '',
    });
  };

  const invite = () => {
    if (isInvite) {
      sendInvitationHub({
        hubId,
        hubName,
        users,
        permissions,
      });

      setInvite(false);
    }
  };

  const maxRemainingNumberSeats = invitees
    ? invitees?.member_seats_available
    : 0;

  const seats = maxRemainingNumberSeats === 1 ? 'seat' : 'seats';
  const guests = users.length === 1 ? 'guest' : 'guests';

  const numberRemainingSeats = maxRemainingNumberSeats - users.length;
  const remainingSeats = numberRemainingSeats === 1 ? 'seat' : 'seats';

  const isNotEnoughSeats = maxRemainingNumberSeats < users.length;

  return (
    <>
      <Header
        title="Invite others"
        description="Invite clients and others as collaborators"
      />
      {users.length > 0 && (
        <GuestSeat permissions={permissions} setPermissions={setPermissions} />
      )}
      <InviteField
        isNotEnoughSeats={isNotEnoughSeats}
        suggestions={suggestions}
        invitation={invitation}
        setInvitation={setInvitation}
        users={users}
        setUsers={setUsers}
        clear={clear}
      />
      {suggestions.length > 0 && users.length === 0 && (
        <ul className={styles.suggestions}>
          {suggestions.map((user) => (
            <ExistingUser
              key={user.id}
              user={user}
              hubId={hubId}
              hubName={hubName}
              sendInvitationHub={sendInvitationHub}
              permissions={permissions}
            />
          ))}
        </ul>
      )}
      {users.length > 0 && (
        <Invite isNotEnoughSeats={isNotEnoughSeats} invite={invite} />
      )}
      <Description
        numberGuests={users.length}
        maxRemainingNumberSeats={maxRemainingNumberSeats}
        seats={seats}
        guests={guests}
        numberRemainingSeats={numberRemainingSeats}
        remainingSeats={remainingSeats}
        isNotEnoughSeats={isNotEnoughSeats}
      />
      <ExtraSeats />
    </>
  );
};
