import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import cn from 'classnames';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { createPortal } from 'react-dom';

import { useFocusTrap } from '@visualist/hooks';

import styles from './styles.module.css';

// May add this to storybook later. Currently only used on signup screen so not generic for time reasons. If you need this component for anything else please refactor to be generic and make sure it still works in the login flow.

type Scrim = 'Surface container low, 32%';

type Props = {
  showModal: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  className?: string;
  scrim?: Scrim;
  hasBackdrop?: boolean;
  isVai?: boolean;
  isInitialFocused?: boolean;
  preventBackdropClick?: boolean;
} & React.ComponentProps<typeof motion.dialog>;

const backdropVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
};

export const Modal = forwardRef<HTMLDialogElement, Props>(
  (
    {
      showModal,
      handleClose,
      children,
      className,
      scrim,
      hasBackdrop = true,
      isVai = false,
      isInitialFocused = true,
      preventBackdropClick = false,
      ...rest
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLDialogElement>(null);
    const scrimRef = useRef<HTMLDivElement>(null);
    const [animateShadow, setAnimateShadow] = React.useState(false);
    const onHandleClose = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      event.stopPropagation();
      if (preventBackdropClick) return;
      if (event.target === event.currentTarget) {
        handleClose();
      }
    };

    if (showModal && isVai && !animateShadow) {
      setTimeout(() => {
        setAnimateShadow(true);
      }, 250);
    }

    if (!showModal && animateShadow) {
      setAnimateShadow(false);
    }

    useImperativeHandle(ref, () => {
      return internalRef.current as HTMLDialogElement;
    });

    useFocusTrap({
      isOpen: showModal,
      ref: internalRef,
      setInitialFocus: isInitialFocused,
    });

    const variants: Variants = {
      hidden: {
        opacity: 0,
        transition: {
          type: 'tween',
          ease: 'easeOut',
          duration: 0.2,
        },
        ...(isVai
          ? {
              transformPerspective: 1000,
              rotateX: 20,
              translateY: 50,
              scale: 0.9,
            }
          : undefined),
      },
      show: {
        opacity: 1,
        transition: {
          type: 'tween',
          ease: 'easeOut',
          duration: 0.15,
        },
        ...(isVai
          ? {
              rotateX: 0,
              translateY: 0,
              scale: 1,
              transition: {
                type: 'tween',
                ease: 'easeInOut',
                duration: 0.4,
              },
            }
          : undefined),
      },
    };

    return createPortal(
      <AnimatePresence>
        {showModal ? (
          <motion.div
            className={cn(styles.backdrop, {
              [styles.hideBackdrop]: !hasBackdrop,
            })}
          >
            <motion.div
              ref={scrimRef}
              initial="hidden"
              animate={showModal ? 'show' : 'hidden'}
              exit="hidden"
              variants={backdropVariants}
              onClick={onHandleClose}
              className={cn(styles.scrim, {
                [styles.scrim32]: scrim === 'Surface container low, 32%',
              })}
            />
            <motion.dialog
              open={showModal}
              ref={internalRef}
              tabIndex={-1}
              initial="hidden"
              animate={showModal ? 'show' : 'hidden'}
              onClick={(e) => e.stopPropagation()}
              exit="hidden"
              transition={{
                type: '',
              }}
              variants={variants}
              className={cn(styles.modal, className, {
                [styles.vaiShadow]: animateShadow,
                [styles.vai]: isVai,
              })}
              style={
                {
                  '--progress': animateShadow ? 1 : 0,
                } as React.CSSProperties
              }
              dragConstraints={rest.drag ? scrimRef : undefined}
              {...rest}
            >
              {children}
            </motion.dialog>
          </motion.div>
        ) : null}
      </AnimatePresence>,
      document.body,
    );
  },
);
