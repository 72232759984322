import { useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import {
  DragDropContext,
  Draggable,
  DragStart,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { isMobile } from 'react-device-detect';

import { IconButton } from '@visualist/design-system/src/components/v2';
import { useOnClickOutside } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { hexToRgb, isLightColor } from '@src/shared/constants/colours';

import { $isAdjustPalette } from '../palette-options/model/options-managment';
import { ColorCodes } from './color-codes';
import {
  $palette,
  dragEnded,
  dragStarted,
  leftEdgeHovered,
  swatchCreated,
  swatchRemoved,
  swatchUpdated,
} from './model';

import styles from './styles.module.css';

type Props = {
  isShowColorCodes?: boolean;
};

export const Palette = ({ isShowColorCodes = true }: Props) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [hoveredSwatch, setHoveredSwatch] = useState<number | null>(null);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [isLeftEdge, setLeftEdge] = useState(false);
  const [isRightEdge, setRightEdge] = useState(false);
  const [isOpenColorPicker, setOpenColorPicker] = useState(false);

  const palette = useUnit($palette);
  const isAdjustPalette = useUnit($isAdjustPalette);

  const ref = useRef<HTMLInputElement>(null);

  useOnClickOutside(ref, () => {
    if (isOpenColorPicker) {
      setOpenColorPicker(false);
      setHoveredIndex(null);
    }
  });

  const onDragStart = (start: DragStart) => {
    setDraggingIndex(start.source.index);
    dragStarted(start.source.index);
  };

  const onDragEnd = (result: DropResult) => {
    setDraggingIndex(null);

    if (!result.destination) return;

    dragEnded({
      startIndex: result.source.index,
      endIndex: result.destination.index,
    });
  };

  const handleAddSwatch = (index: number) => {
    swatchCreated({ index });
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId="palette" direction="horizontal">
        {(provided) => (
          <div
            className={cn(styles.palette, {
              [styles.mobilePalette]: isMobile,
              [styles.creationDialog]: !isShowColorCodes,
            })}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {palette?.swatches.map((swatch, index) => {
              const isShowOnLeftEdgeLeftmostSwatch =
                hoveredSwatch === 0 &&
                hoveredSwatch === index &&
                isLeftEdge &&
                !draggingIndex &&
                !isOpenColorPicker &&
                palette.swatches.length < 5;

              const isShowOnRightEdgeRightmostSwatch =
                hoveredSwatch === palette.swatches.length - 1 &&
                hoveredSwatch === index &&
                isRightEdge &&
                !draggingIndex &&
                !isOpenColorPicker &&
                palette.swatches.length < 5;

              const isShowOnLeftEdgeSwatch =
                isLeftEdge &&
                hoveredSwatch - 1 === index &&
                index < palette.swatches.length - 1 &&
                !draggingIndex &&
                !isOpenColorPicker &&
                palette.swatches.length < 5;

              const isShowOnRightEdgeSwatch =
                isRightEdge &&
                hoveredSwatch === index &&
                index < palette.swatches.length - 1 &&
                !draggingIndex &&
                !isOpenColorPicker &&
                palette.swatches.length < 5;

              return (
                <Draggable
                  key={index}
                  draggableId={`swatch-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        className={styles.swatch}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        style={{
                          userSelect: 'none',
                          width: `${100 / palette.swatches.length}%`,
                          ...provided.draggableProps.style,
                        }}
                        onMouseEnter={() => {
                          if (!isOpenColorPicker) {
                            setHoveredIndex(index);
                            setHoveredSwatch(index);
                          }
                        }}
                        onMouseLeave={() => {
                          if (!isOpenColorPicker) {
                            setHoveredIndex(null);
                            setHoveredSwatch(null);
                          }
                        }}
                      >
                        <div
                          style={{
                            background: swatch.hex_color,
                          }}
                          className={styles.color}
                        >
                          {isAdjustPalette && (
                            <>
                              <div
                                className={styles.addSwatches}
                                onClick={() => {
                                  setHoveredIndex(index);
                                  setHoveredSwatch(index);
                                }}
                              >
                                <div
                                  className={styles.leftEdge}
                                  onMouseEnter={() => {
                                    setLeftEdge(true);
                                    leftEdgeHovered(true);
                                  }}
                                  onMouseLeave={() => {
                                    setLeftEdge(false);
                                    leftEdgeHovered(false);
                                  }}
                                >
                                  {isShowOnLeftEdgeLeftmostSwatch &&
                                    !snapshot.isDragging && (
                                      <IconButton
                                        className={styles.addLeft}
                                        type="surface round small"
                                        icon={
                                          <Icon name="sprite/plus" size={12} />
                                        }
                                        onClick={() => handleAddSwatch(index)}
                                        onMouseEnter={() => {
                                          if (!isMobile) {
                                            setHoveredIndex(null);
                                          }
                                        }}
                                        onMouseLeave={() => {
                                          if (!isMobile) {
                                            setHoveredIndex(index);
                                          }
                                        }}
                                      />
                                    )}
                                </div>

                                <div
                                  className={styles.rightEdge}
                                  onMouseEnter={() => {
                                    setRightEdge(true);
                                  }}
                                  onMouseLeave={() => {
                                    setRightEdge(false);
                                  }}
                                >
                                  {isShowOnRightEdgeRightmostSwatch &&
                                    !snapshot.isDragging && (
                                      <IconButton
                                        className={styles.addRight}
                                        type="surface round small"
                                        icon={
                                          <Icon name="sprite/plus" size={12} />
                                        }
                                        onClick={() => handleAddSwatch(index)}
                                        onMouseEnter={() => {
                                          if (!isMobile) {
                                            setHoveredIndex(null);
                                          }
                                        }}
                                        onMouseLeave={() => {
                                          if (!isMobile) {
                                            setHoveredIndex(index);
                                          }
                                        }}
                                      />
                                    )}
                                </div>

                                {isShowOnLeftEdgeSwatch &&
                                  !snapshot.isDragging && (
                                    <div
                                      className={styles.addBorder}
                                      onMouseEnter={() => {
                                        setRightEdge(true);
                                      }}
                                      onMouseLeave={() => {
                                        setRightEdge(false);
                                      }}
                                    >
                                      <IconButton
                                        className={styles.addCenter}
                                        type="surface round small"
                                        icon={
                                          <Icon name="sprite/plus" size={12} />
                                        }
                                        onClick={() => handleAddSwatch(index)}
                                        onMouseEnter={() => {
                                          if (!isMobile) {
                                            setHoveredIndex(null);
                                          }
                                        }}
                                        onMouseLeave={() => {
                                          if (!isMobile) {
                                            setHoveredIndex(index);
                                          }
                                        }}
                                      />
                                    </div>
                                  )}

                                {isShowOnRightEdgeSwatch &&
                                  !snapshot.isDragging && (
                                    <div
                                      className={styles.addBorder}
                                      onMouseEnter={() => {
                                        setRightEdge(true);
                                      }}
                                      onMouseLeave={() => {
                                        setRightEdge(false);
                                      }}
                                    >
                                      <IconButton
                                        className={styles.addCenter}
                                        type="surface round small"
                                        icon={
                                          <Icon name="sprite/plus" size={12} />
                                        }
                                        onClick={() => handleAddSwatch(index)}
                                        onMouseEnter={() => {
                                          if (!isMobile) {
                                            setHoveredIndex(null);
                                          }
                                        }}
                                        onMouseLeave={() => {
                                          if (!isMobile) {
                                            setHoveredIndex(index);
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>

                              <div style={{ position: 'relative' }}>
                                {(isMobile ||
                                  (hoveredIndex === index &&
                                    !draggingIndex &&
                                    !snapshot.draggingOver &&
                                    !snapshot.isDragging &&
                                    !isOpenColorPicker)) && (
                                  <div
                                    className={cn(styles.actions, {
                                      [styles.fadeIn]:
                                        hoveredIndex === index && !isMobile,
                                      [styles.fadeOut]:
                                        hoveredIndex !== index && !isMobile,
                                      [styles.mobileActions]: isMobile,
                                    })}
                                  >
                                    <IconButton
                                      type="unfilled"
                                      icon={
                                        <Icon
                                          name="sprite/x"
                                          className={cn(styles.dark, {
                                            [styles.light]: isLightColor(
                                              swatch.rgb_color,
                                            ),
                                          })}
                                        />
                                      }
                                      onClick={() => {
                                        swatchRemoved(swatch.order);
                                      }}
                                      isDisabled={palette.swatches.length <= 2}
                                    />
                                    <IconButton
                                      type="unfilled"
                                      icon={
                                        <Icon
                                          name="sprite/paintbrush"
                                          className={cn(styles.dark, {
                                            [styles.light]: isLightColor(
                                              swatch.rgb_color,
                                            ),
                                          })}
                                        />
                                      }
                                      onClick={() => {
                                        setOpenColorPicker(true);
                                        ref.current?.click();
                                      }}
                                    />

                                    {isMobile &&
                                      palette.swatches.length < 5 && (
                                        <IconButton
                                          type="unfilled"
                                          icon={
                                            <Icon
                                              name="sprite/plus"
                                              className={cn(styles.dark, {
                                                [styles.light]: isLightColor(
                                                  swatch.rgb_color,
                                                ),
                                              })}
                                            />
                                          }
                                          onClick={() => handleAddSwatch(index)}
                                        />
                                      )}
                                    <div
                                      className={styles.dragHandle}
                                      {...provided.dragHandleProps}
                                    >
                                      <Icon
                                        name="sprite/arrow-width"
                                        className={cn(styles.dark, {
                                          [styles.light]: isLightColor(
                                            swatch.rgb_color,
                                          ),
                                        })}
                                      />
                                    </div>
                                  </div>
                                )}

                                {hoveredIndex === index && (
                                  <input
                                    type="color"
                                    color={swatch.hex_color}
                                    ref={ref}
                                    className={styles.colorPicker}
                                    value={swatch.hex_color}
                                    onChange={(e) => {
                                      const rgb_color = hexToRgb(
                                        e.target.value,
                                      );

                                      swatchUpdated({
                                        order: swatch.order,
                                        hex_color: e.target.value,
                                        rgb_color,
                                      });
                                    }}
                                  />
                                )}
                              </div>

                              {isMobile && (
                                <input
                                  type="color"
                                  color={swatch.hex_color}
                                  className={styles.mobileColorPicker}
                                  value={swatch.hex_color}
                                  onChange={(e) => {
                                    const rgb_color = hexToRgb(e.target.value);

                                    swatchUpdated({
                                      order: swatch.order,
                                      hex_color: e.target.value,
                                      rgb_color,
                                    });
                                  }}
                                />
                              )}

                              {snapshot.draggingOver && !isMobile && (
                                <div>
                                  <div
                                    style={{ height: '48px', width: '48px' }}
                                  />
                                  <div
                                    style={{ height: '48px', width: '48px' }}
                                  />
                                  <div
                                    className={styles.dragHandle}
                                    {...provided.dragHandleProps}
                                    data-is-dragging={snapshot.isDragging}
                                  >
                                    <Icon
                                      name="sprite/arrow-width"
                                      className={cn(styles.dark, {
                                        [styles.light]: isLightColor(
                                          swatch.rgb_color,
                                        ),
                                      })}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        {isShowColorCodes && (
                          <ColorCodes
                            isOpenColorPicker={isOpenColorPicker}
                            index={index}
                            setHoveredIndex={setHoveredIndex}
                            swatch={swatch}
                            setHoveredSwatch={setHoveredSwatch}
                          />
                        )}
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
