import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

const key = new PluginKey('eventHandler');

export const DropHandler = Extension.create({
  name: 'eventHandler',

  // addOptions() {
  //   return {
  //     docId: '',
  //   };
  // },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key,
        props: {
          // handleClick(view, pos, event) { /* … */ },
          // handleDoubleClick(view, pos, event) { /* … */ },
          // handlePaste(view, event, slice) { /* … */ },
          // // … and many, many more.
          // // Here is the full list: https://prosemirror.net/docs/ref/#view.EditorProps
          // handleDrop(view, event, slice, moved) {
          handleDrop(view, event) {
            const imageData = event.dataTransfer?.getData('imageData');

            if (!imageData) return;

            // url, height, width,
            const parsedJson = JSON.parse(imageData) as unknown;

            if (
              !(
                typeof parsedJson === 'object' &&
                parsedJson !== null &&
                'fileId' in parsedJson &&
                'url' in parsedJson &&
                'width' in parsedJson &&
                'height' in parsedJson
              )
            )
              return;

            const { fileId, url, width, height } = parsedJson as {
              fileId: string;
              url: string;
              width: number;
              height: number;
            };

            const { schema } = view.state;
            const pos = view.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            });

            if (!pos) return;

            const $pos = view.state.doc.resolve(pos.pos);
            let adjustedWidth = width;
            let adjustedHeight = height;
            const aspectRatio = parseFloat((height / width).toFixed(2));
            let cellWidth;

            // Traverse up the node hierarchy to check if we're in a table cell
            for (let depth = $pos.depth; depth > 0; depth--) {
              const node = $pos.node(depth);
              if (node.type.name === 'tableCell') {
                const cellDOM = view.nodeDOM($pos.start(depth)) as HTMLElement;
                if (cellDOM) {
                  cellWidth = cellDOM.getBoundingClientRect().width;
                  if (cellWidth) {
                    adjustedWidth = cellWidth;
                    adjustedHeight = parseFloat(
                      (aspectRatio * adjustedWidth).toFixed(2),
                    );
                  }
                }
                break; // Stop traversal once we find a table cell
              }
            }

            const imageNode = schema.nodes.FileComponent.create({
              src: url,
              width: adjustedWidth,
              height: adjustedHeight,
              aspectRatio: aspectRatio,
              actualHeight: adjustedHeight,
              actualWidth: adjustedWidth,
              alt: fileId,
              fileId: fileId,
              fileType: 'image',
              textWrap: 'inline',
            });

            const tr = view.state.tr.insert(pos.pos, imageNode);
            view.dispatch(tr);
            view.focus();
          },
        },
      }),
    ];
  },
});
