export const formatDateString = ({
  dateString,
  dateObject,
  today = false,
  tomorrow = false,
  yesterday = false,
  displayCurrentYear = true,
}: {
  dateString?: string;
  dateObject?: Date;
  today?: boolean;
  tomorrow?: boolean;
  yesterday?: boolean;
  displayCurrentYear?: boolean;
}) => {
  const actualDate = dateObject ?? new Date(dateString as string);
  const compareDate = dateObject ?? new Date(dateString as string);
  compareDate.setHours(0, 0, 0, 0);
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const diff = compareDate.getTime() - now.getTime();
  const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (diffInDays === 0 && today) {
    return `Today`;
  }

  if (diffInDays === 1 && tomorrow) {
    return `Tomorrow`;
  }

  if (diffInDays === -1 && yesterday) {
    return `Yesterday`;
  }

  return `${actualDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    ...(displayCurrentYear || compareDate.getFullYear() !== now.getFullYear()
      ? { year: 'numeric' }
      : {}),
  })}`;
};
