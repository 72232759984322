import { ReactNode, useState } from 'react';

import cn from 'classnames';

import { Icon } from '@visualist/icons';

import { Button } from '../Buttons';
import { Divider } from '../Divider';
import { Dropdown, Item } from '../Dropdown';
import TypographyPoppins from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

export type List = {
  name: string;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
};

interface ListProps {
  list: List[];
}

export interface DialogProps {
  icon?: ReactNode;
  headline: string;
  description: string;
  supportingText?: string;
  thirdLine?: string;
  list?: List[];
  action: () => void;
  actionLabel: string;
  closeAction: () => void;
  className?: string;
  menuItems?: Item[];
  cancelLabel?: string;
}

export const Dialog = ({
  icon,
  headline,
  description,
  supportingText,
  thirdLine,
  list,
  action,
  actionLabel,
  closeAction,
  className,
  menuItems,
  cancelLabel,
}: DialogProps) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  return (
    <div className={cn(styles.dialog, className)}>
      <div className={styles.content}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <TypographyPoppins
          type="headline"
          headlineSize="S"
          className={styles.headline}
        >
          {headline}
        </TypographyPoppins>
        <TypographyPoppins
          type="body"
          bodySize="M"
          className={styles.description}
        >
          {description}
        </TypographyPoppins>
        {supportingText && (
          <TypographyPoppins
            type="body"
            bodySize="M"
            className={styles.supportingText}
          >
            {supportingText}
          </TypographyPoppins>
        )}
        {thirdLine && (
          <TypographyPoppins
            type="body"
            bodySize="M"
            className={styles.supportingText}
          >
            {thirdLine}
          </TypographyPoppins>
        )}
      </div>
      {list && list.length > 0 && <List list={list} />}
      <div className={styles.actions}>
        <Button
          type="outlined"
          label={cancelLabel ? cancelLabel : 'Cancel'}
          onClick={closeAction}
        />
        {menuItems && menuItems.length > 0 ? (
          <div style={{ display: 'flex' }}>
            <Button
              className={styles.withDropdown}
              autoFocus
              type="filled"
              label={actionLabel}
              trailingIcon={
                <Icon
                  name="sprite/chevron-down"
                  size={12}
                  className={styles.icon}
                />
              }
              action={() => setOpenDropdown(true)}
              onClick={action}
            />

            <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
              <Dropdown.Menu
                side="bottom"
                align="end"
                density="-2"
                sideOffset={4}
              >
                {menuItems.map((item, index) => (
                  <Dropdown.MenuItem key={index} item={item} />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <Button
            autoFocus
            type="filled"
            label={actionLabel}
            onClick={action}
          />
        )}
      </div>
    </div>
  );
};

const List = ({ list }: ListProps) => (
  <ul className={styles.list}>
    {list.map((item, index, list) => (
      <li key={index} className={styles.listItem}>
        <div className={styles.itemContent}>
          <div className={styles.itemIcon}>{item.leadingIcon}</div>
          <TypographyPoppins
            type="body"
            bodySize="L"
            className={styles.itemName}
          >
            {item.name}
          </TypographyPoppins>
          <div className={styles.itemIcon}>{item.trailingIcon}</div>
        </div>
        {index !== list.length - 1 && (
          <Divider className={styles.divider} type="long-line" />
        )}
      </li>
    ))}
  </ul>
);

/**
 * Redone Dialog component to be more flexible and customizable
 */

Dialog.Container = ({ children }: { children: ReactNode }) => {
  return <div className={styles.dialogNew}>{children}</div>;
};

Dialog.Content = ({ children }: { children: ReactNode }) => {
  return <div className={styles.contentNew}>{children}</div>;
};

Dialog.Actions = ({ children }: { children: ReactNode }) => {
  return <div className={styles.actions}>{children}</div>;
};

Dialog.Headline = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <TypographyPoppins
      type="headline"
      headlineSize="S"
      className={cn(styles.headline, className)}
    >
      {children}
    </TypographyPoppins>
  );
};

Dialog.Description = ({ children }: { children: ReactNode }) => {
  return (
    <TypographyPoppins type="body" bodySize="M" className={styles.description}>
      {children}
    </TypographyPoppins>
  );
};

Dialog.SupportingText = ({ children }: { children: ReactNode }) => {
  return (
    <TypographyPoppins
      type="body"
      bodySize="M"
      className={styles.supportingText}
    >
      {children}
    </TypographyPoppins>
  );
};

Dialog.Centered = ({ children }: { children: ReactNode }) => {
  return <div className={styles.centered}>{children}</div>;
};

Dialog.ActionTray = ({
  onClose,
  onAction,
  cancelLabel = 'Cancel',
  actionLabel,
}: {
  onClose: () => void;
  onAction: () => void;
  cancelLabel?: string;
  actionLabel: string;
}) => {
  return (
    <div className={styles.actions}>
      <Button type="outlined" label={cancelLabel} onClick={onClose} />
      <Button autoFocus type="filled" label={actionLabel} onClick={onAction} />
    </div>
  );
};
