import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import styles from './styles.module.css';

export const VaiPopup = ({
  showPopup,
  children,
  top,
  right,
  width,
}: {
  showPopup: boolean;
  children?: React.ReactNode;
  top?: number;
  right?: number;
  width?: number;
}) => {
  return (
    <AnimatePresence>
      {showPopup ? (
        <motion.div
          className={styles.container}
          initial={{
            opacity: 0,
            scale: 0,
            translateX: 150,
            translateY: -100,
          }}
          animate={{
            opacity: 1,
            scale: 1,
            scaleX: 1,
            translateX: 0,
            translateY: 0,
          }}
          exit={{
            opacity: 0,
            scale: 0,
            translateX: 150,
            translateY: -100,
          }}
          transition={{
            type: 'spring',
            // stiffness: 50,
            // mass: 0.2,
            damping: 15,
          }}
          style={
            {
              '--top': top ? `${top}px` : undefined,
              '--right': right ? `${right}px` : undefined,
              '--width': width ? `${width}px` : undefined,
            } as React.CSSProperties
          }
        >
          {children}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
