import { useState } from 'react';

import cn from 'classnames';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import { Editor } from '@tiptap/react';

import styles from './styles.module.css';

const TABLE_ROWS = 5;
const TABLE_COLUMNS = 8;

export const TableCreation = (props: { editor: Editor }) => {
  const { editor } = props;
  const [tableDimension, setTableDimension] = useState({
    x: 1,
    y: 1,
  });

  return (
    <div className={styles.container}>
      <div className={styles.col}>
        {Array.from({ length: TABLE_ROWS }).map((_, i) => {
          return (
            <div className={styles.row} key={i}>
              {Array.from({ length: TABLE_COLUMNS }).map((_, j) => {
                return (
                  <div
                    role="button"
                    onMouseEnter={() => {
                      setTableDimension({
                        x: i + 1,
                        y: j + 1,
                      });
                    }}
                    onClick={() => {
                      // Create table
                      editor
                        .chain()
                        .focus()
                        .insertTable({
                          rows: tableDimension.x,
                          cols: tableDimension.y,
                          withHeaderRow: false,
                        })
                        .run();
                    }}
                    className={cn(styles.cell, {
                      [styles.highlighted]:
                        i < tableDimension.x && j < tableDimension.y,
                    })}
                    key={j}
                  >
                    {''}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <TypographyPoppins type="label" labelSize="S">
        {tableDimension.y} x {tableDimension.x}
      </TypographyPoppins>
    </div>
  );
};
