import React from 'react';

import { useUnit } from 'effector-react';
import { KonvaEventObject } from 'konva/lib/Node';
import { Text as TText } from 'konva/lib/shapes/Text';
import { isMobile } from 'react-device-detect';
import { Group, Text, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';

import { useKeyPress } from '@visualist/hooks';

import { TRANSFORMER } from '@pages/StudioPage/constants';
import { useTextBoxes } from '@pages/StudioPage/hooks/useTextBoxes';
// import { useOnClickOutside } from '@visualist/hooks';
import {
  $currentTool,
  $dragSelection,
  $isEditingText,
  $selectedImageBlocks,
  $selectedTextBlocks,
  changedEditingState,
  selectedImageBlockIdsAction,
  selectedTextBlockIdsAction,
} from '@pages/StudioPage/model';
import { WOLFE_LIGHT } from '@src/shared/constants/colours';
import { usePrevious } from '@src/shared/hooks/usePrevious';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';

import { TextToolbar } from '../TextToolbar';
import { textReducer } from './reducer';

import styles from './styles.module.css';

// import { Stage } from 'konva/lib/Stage';

type Props = {
  id: string;
  defaultText: string;
  defaultX: number;
  defaultY: number;
  defaultFontSize: number;
  defaultWidth: number;
  // Optional as the data base will not always have this for older textboxes
  defaultRotation?: number;
  defaultAlignment?: 'left' | 'center' | 'right';
  defaultBold?: boolean;
  defaultItalic?: boolean;
  defaultUnderline?: boolean;
  defaultColour?: string;
  designId: string;
  hoveredSelection: string;
  setHoveredSelection: (id: string) => void;
  // stageRef: React.MutableRefObject<Stage | null>;
  updateThumbnail: () => void;
};

const PADDING = 20;

export const Textbox = (props: Props) => {
  const queryClient = useQueryClient();
  // State
  const [mount, setMount] = React.useState(false);
  const [
    currentTool,
    selectedTextBlocks,
    dragSelection,
    selectedImageBlocks,
    isEditingText,
  ] = useUnit([
    $currentTool,
    $selectedTextBlocks,
    $dragSelection,
    $selectedImageBlocks,
    $isEditingText,
  ]);
  const [state, dispatch] = React.useReducer(textReducer, {
    text: props.defaultText,
    width: props.defaultWidth,
    height: undefined,
    x: props.defaultX,
    y: props.defaultY,
    fontSize: props.defaultFontSize,
    bold: props.defaultBold ?? false,
    italic: props.defaultItalic ?? false,
    underline: props.defaultUnderline ?? false,
    rotation: props.defaultRotation ?? 0,
    colour: props.defaultColour ?? '#000000',
    alignment: props.defaultAlignment ?? 'left',
  });
  const [isResizing, setIsResizing] = React.useState(false);

  // Mutation
  const { textboxMutation, createTextboxMutation, deleteTextboxMutation } =
    useTextBoxes({ designId: props.designId });

  // Refs
  const konvaTextRef = React.useRef<TText>(null);
  const transformerRef = React.useRef<Transformer>(null);
  const hoverTransformerRef = React.useRef<Transformer>(null);
  const textareaInputRef = React.useRef<HTMLTextAreaElement>(null);
  const growingElementRef = React.useRef<HTMLDivElement>(null);
  const toolbarRef = React.useRef<HTMLDivElement>(null);

  // Derived state
  const selectedBlocks = [...selectedImageBlocks, ...selectedTextBlocks];
  const isSelected =
    selectedBlocks.length === 1 && selectedTextBlocks.has(props.id);
  const isInMassSelection =
    selectedTextBlocks.has(props.id) && selectedBlocks.length > 1;
  const canDrag = 'select' === currentTool;
  const isHovered =
    props.id === props.hoveredSelection && currentTool !== 'move';
  const isEditingThisText = isEditingText && isSelected;
  const wasSelection = usePrevious(isSelected);

  // Handlers
  useKeyPress({
    key: 'Escape',
    onKeyDown: () => {
      changedEditingState(false);
      selectedTextBlockIdsAction(new Set());
    },
  });

  const enableEditing = () => {
    if (currentTool === 'move') return;

    changedEditingState(true);

    matchTextboxSize();

    setTimeout(() => {
      textareaInputRef.current?.focus();
      textareaInputRef.current?.setSelectionRange(0, state.text.length);
    }, 50);
  };

  const onEditText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!growingElementRef.current) return;

    matchTextboxSize();

    dispatch({
      type: 'SET_TEXT',
      payload: {
        text: e.target.value,
      },
    });
  };

  const commitDragPosition = (e: KonvaEventObject<DragEvent>) => {
    const newX = e.currentTarget.x();
    const newY = e.currentTarget.y();

    dispatch({
      type: 'SET_X',
      payload: {
        x: newX,
      },
    });
    dispatch({
      type: 'SET_Y',
      payload: {
        y: newY,
      },
    });

    textboxMutation.mutate({
      textboxId: props.id,
      positionX: newX,
      positionY: newY,
      fontSize: state.fontSize,
      text: state.text,
      width: state.width || 64,
      alignment: state.alignment,
      bold: state.bold,
      italic: state.italic,
      underline: state.underline,
      colour: state.colour,
      rotation: state.rotation,
    });

    props.updateThumbnail();
  };

  const updateDragPosition = (e: KonvaEventObject<DragEvent>) => {
    const node = konvaTextRef.current;

    if (!node || isInMassSelection) return;
    const newX = e.currentTarget.x();
    const newY = e.currentTarget.y();

    dispatch({
      type: 'SET_X',
      payload: {
        x: newX,
      },
    });
    dispatch({
      type: 'SET_Y',
      payload: {
        y: newY,
      },
    });
  };

  const onTransform = (e: KonvaEventObject<Event>) => {
    if (
      transformerRef.current &&
      // @ts-ignore TODO fix type issue
      (transformerRef.current.getActiveAnchor() === 'middle-right' ||
        // @ts-ignore TODO fix type issue
        transformerRef.current.getActiveAnchor() === 'middle-left')
    ) {
      // Scale width
      const scaleX = e.currentTarget.scaleX();
      const newWidth = e.currentTarget.width() * scaleX;
      e.currentTarget.scaleX(1);

      dispatch({
        type: 'SET_WIDTH',
        payload: {
          width: newWidth,
        },
      });
      dispatch({
        type: 'SET_X',
        payload: {
          x: e.currentTarget.x(),
        },
      });

      // setY(e.currentTarget.y());
    } else {
      const scaleX = e.currentTarget.scaleX();
      const scaleY = e.currentTarget.scaleY();

      const newWidth = e.currentTarget.width() * scaleX;
      const newHeight = e.currentTarget.height() * scaleY;
      const newRotation = e.currentTarget.rotation();

      dispatch({
        type: 'SET_FONTSIZE',
        payload: {
          fontSize: Math.fround(state.fontSize * scaleX),
        },
      });

      e.currentTarget.scaleX(1);
      e.currentTarget.scaleY(1);

      dispatch({
        type: 'SET_WIDTH',
        payload: {
          width: newWidth,
        },
      });
      dispatch({
        type: 'SET_HEIGHT',
        payload: {
          height: newHeight,
        },
      });
      dispatch({
        type: 'SET_ROTATION',
        payload: {
          rotation: newRotation,
        },
      });
      dispatch({
        type: 'SET_X',
        payload: {
          x: e.currentTarget.x(),
        },
      });
      dispatch({
        type: 'SET_Y',
        payload: {
          y: e.currentTarget.y(),
        },
      });
    }
  };

  const commitTransformChange = (e: KonvaEventObject<Event>) => {
    const node = konvaTextRef.current;

    if (!node) return;

    const newWidth = node.width() * node.scaleX();
    const newHeight = node.height() * node.scaleY();
    const newRotation = node.rotation();
    node.width(newWidth);
    node.height(newHeight);
    node.scaleX(1);
    node.scaleY(1);

    dispatch({
      type: 'SET_WIDTH',
      payload: {
        width: newWidth,
      },
    });
    dispatch({
      type: 'SET_HEIGHT',
      payload: {
        height: newHeight,
      },
    });
    dispatch({
      type: 'SET_ROTATION',
      payload: {
        rotation: newRotation,
      },
    });

    textboxMutation.mutate({
      textboxId: props.id,
      positionX: e.currentTarget.x(),
      positionY: e.currentTarget.y(),
      fontSize: state.fontSize,
      text: state.text,
      width: e.currentTarget.width() * e.currentTarget.scaleX(),
      rotation: newRotation,
      alignment: state.alignment,
      bold: state.bold,
      italic: state.italic,
      underline: state.underline,
      colour: state.colour,
    });

    setIsResizing(false);
    matchTextboxSize();

    props.updateThumbnail();
  };

  // Effects
  React.useEffect(() => {
    if (!mount) {
      setMount(true);
    }
  }, []);

  React.useEffect(() => {
    if (wasSelection && !isSelected) {
      // Selection has just been lost, compare previous state to current state to see if we should save
      // TODO only update if something has changed
      commitEditTextChange({
        id: props.id,
        x: state.x,
        y: state.y,
        text: state.text,
        width: state.width,
        designId: props.designId,
        fontSize: state.fontSize,
        alignment: state.alignment,
        bold: state.bold,
        italic: state.italic,
        underline: state.underline,
        colour: state.colour,
        rotation: state.rotation,
      });

      matchTextboxSize();

      props.updateThumbnail();
    }
  }, [isSelected]);

  React.useEffect(() => {
    changedEditingState(false);
  }, [isSelected]);

  React.useEffect(() => {
    // For when a new text box is created
    if (props.id.includes('temp-text')) {
      enableEditing();
    }
  }, []);

  React.useEffect(() => {
    // On mount set height and width
    if (!growingElementRef.current || !mount) return;

    dispatch({
      type: 'SET_HEIGHT',
      payload: {
        height: growingElementRef.current.clientHeight,
      },
    });
    dispatch({
      type: 'SET_WIDTH',
      payload: {
        width: growingElementRef.current.clientWidth,
      },
    });
  }, [mount]);

  React.useEffect(() => {
    // Delete textbox if no text and not selected
    if (!state.text && !isSelected) {
      deleteTextboxMutation.mutate({ ids: [props.id] });
    }
  }, [isSelected]);

  React.useEffect(() => {
    // When edit is started set correct height
    if (!growingElementRef.current || !textareaInputRef.current) return;
    matchTextboxSize();
  }, [isEditingThisText]);

  React.useEffect(() => {
    // When any text state changes
    if (!isResizing) {
      matchTextboxSize();
    }
  }, [state.alignment, state.bold, state.fontSize, state.italic, state.text]);

  // Set Transformers
  React.useEffect(() => {
    if (isSelected && transformerRef.current) {
      // we need to attach transformer manually
      // @ts-ignore
      transformerRef.current.nodes([konvaTextRef.current]);
      // @ts-ignore
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  React.useEffect(() => {
    if (
      (isHovered || (isEditingText && isSelected) || isInMassSelection) &&
      hoverTransformerRef.current
    ) {
      // we need to attach hover transformer manually
      // @ts-ignore
      hoverTransformerRef.current.nodes([konvaTextRef.current]);
      // @ts-ignore
      hoverTransformerRef.current.getLayer().batchDraw();
    }
  }, [isHovered, isEditingThisText, isInMassSelection]);

  // Selection handlers
  const selectTextBox = () => {
    selectedImageBlockIdsAction(new Set());

    if (selectedTextBlocks.has(props.id) && isMobile) {
      // Allow double select on mobile
      enableEditing();
      return;
    }

    if (currentTool === 'add-text') {
      selectedTextBlockIdsAction(new Set([props.id]));
      enableEditing();
      return;
    }

    if (!canDrag) return;

    selectedTextBlockIdsAction(new Set([props.id]));
  };

  const dragStarted = () => {
    if (isInMassSelection) return;

    selectTextBox();
  };

  const mouseEntersText = () => props.setHoveredSelection(props.id);

  const mouseLeavesText = () => props.setHoveredSelection('');

  const matchTextboxSize = () => {
    if (!growingElementRef.current) return;

    const w = growingElementRef.current.clientWidth;
    const h = growingElementRef.current.clientHeight;
    dispatch({
      type: 'SET_WIDTH',
      payload: {
        width: w + PADDING * 2,
      },
    });
    dispatch({
      type: 'SET_HEIGHT',
      payload: {
        height: h,
      },
    });

    if (!textareaInputRef.current) return;

    konvaTextRef.current?.width(w + PADDING * 2);
    konvaTextRef.current?.height(h);

    textareaInputRef.current.style.width = `${w}px`;
    textareaInputRef.current.style.height = `${h}px`;
  };

  const commitEditTextChange = async ({
    id,
    x,
    y,
    text,
    width,
    designId,
    alignment,
    fontSize,
    bold,
    italic,
    underline,
    colour,
    rotation,
  }: {
    id: string;
    x: number;
    y: number;
    text: string;
    width: number;
    designId: string;
    fontSize: number;
    alignment: 'left' | 'center' | 'right';
    bold: boolean;
    italic: boolean;
    underline: boolean;
    colour: string;
    rotation: number;
  }) => {
    let idToUse = id;
    if (id.includes('temp-text')) {
      const result = await createTextboxMutation.mutateAsync({
        id,
        positionX: x,
        positionY: y,
        fontSize,
        text,
        width,
        layer: 0,
        designId,
        alignment,
        bold,
        italic,
        underline,
        colour,
        rotation,
      });

      idToUse = result.data.id;
    }

    textboxMutation.mutate({
      positionX: x,
      positionY: y,
      fontSize,
      text,
      width,
      textboxId: idToUse,
      alignment,
      bold,
      italic,
      underline,
      colour,
      rotation,
    });
  };

  const calculateFontFamily = () => {
    if (state.bold && state.italic) {
      return 'bold italic';
    }

    if (state.bold) {
      return 'bold';
    }

    if (state.italic) {
      return 'italic';
    }

    return 'normal';
  };

  const textareaStyle = {
    whiteSpace: 'pre',
    width: state.width,
    letterSpacing: konvaTextRef.current?.letterSpacing(),
    fontSize: state.fontSize,
    resize: 'none',
    textAlign: state.alignment,
    color: state.colour,
    fontWeight: state.bold ? 'bold' : 'normal',
    fontStyle: state.italic ? 'italic' : 'normal',
    textDecoration: state.underline ? 'underline' : 'none',
    lineHeight: konvaTextRef.current?.lineHeight(),
    fontFamily: konvaTextRef.current?.fontFamily(),
    border: 'none',
    padding: PADDING,
    outline: 'none',
    backgroundColor: 'transparent',
  } as React.CSSProperties;

  return (
    <Group>
      <Text
        name="Text"
        padding={PADDING}
        id={props.id}
        ref={konvaTextRef}
        text={state.text}
        onDblClick={enableEditing}
        onClick={selectTextBox}
        onTap={selectTextBox}
        onDragStart={dragStarted}
        onMouseEnter={mouseEntersText}
        onMouseLeave={mouseLeavesText}
        onDragEnd={commitDragPosition}
        onDragMove={updateDragPosition}
        onTransform={onTransform}
        onTransformStart={() => setIsResizing(true)}
        onTransformEnd={commitTransformChange}
        x={state.x}
        y={state.y}
        rotation={state.rotation}
        draggable={canDrag}
        fontSize={state.fontSize}
        width={state.width}
        height={state.height}
        align={state.alignment}
        fill={state.colour}
        fontStyle={calculateFontFamily()}
        textDecoration={state.underline ? 'underline' : undefined}
        ellipsis={true}
        visible={!isEditingThisText}
      />
      {isSelected && !isEditingThisText ? (
        <Transformer
          // @ts-ignore TODO fix type issue
          ref={transformerRef}
          name={TRANSFORMER}
          keepRatio={true}
          borderStroke={WOLFE_LIGHT}
          anchorStroke={WOLFE_LIGHT}
          anchorCornerRadius={100}
          enabledAnchors={[
            'top-right',
            'top-left',
            'bottom-left',
            'bottom-right',
            'middle-right',
            'middle-left',
          ]}
        />
      ) : null}
      {(!isSelected && isHovered && !dragSelection) ||
      isEditingThisText ||
      isInMassSelection ? (
        // Transformer for hovering selection
        <Transformer
          // @ts-ignore TODO fix type issue
          ref={hoverTransformerRef}
          flipEnabled={false}
          keepRatio={false}
          enabledAnchors={[]}
          rotateEnabled={false}
          resizeEnabled={false}
          borderStroke={WOLFE_LIGHT}
          anchorStroke={WOLFE_LIGHT}
          name={TRANSFORMER}
        />
      ) : null}
      <Group x={state.x} y={state.y}>
        <Html
          groupProps={{
            y: -80,
            // Fixed number here is the width of each icon in the toolbar. Each is 50px wide, and each divider is 1px
            x: state.width - 50 * 9 + 3,
          }}
          divProps={{
            style: {
              transform: 'unset',
              zIndex: 'unset',
              position: 'unset',
              display: 'flex',
              justifyContent: 'center',
            },
          }}
        >
          {isSelected ? (
            // TODO fix type error
            // @ts-ignore Some query client global issue
            <QueryClientProvider client={queryClient}>
              <TextToolbar
                key={state.fontSize}
                designId={props.designId}
                textId={props.id}
                x={state.x}
                y={state.y}
                width={state.width}
                height={0}
                fontSize={state.fontSize}
                text={state.text}
                formatState={state}
                dispatch={dispatch}
                toolbarRef={toolbarRef}
                matchTextboxSize={matchTextboxSize}
              />
            </QueryClientProvider>
          ) : null}
        </Html>
      </Group>
      <Group x={state.x} y={state.y} rotation={state.rotation}>
        <Html
          divProps={{
            style: {
              visibility: isEditingThisText ? 'visible' : 'hidden',
            },
            className: styles.textAreaContainer,
          }}
        >
          {/* Main editing text component */}
          <textarea
            name="textarea"
            ref={textareaInputRef}
            defaultValue={state.text}
            style={{
              ...textareaStyle,
              width: `calc(100% - ${PADDING * 2}px)`,
              height: `calc(100% - ${PADDING * 2}px)`,
            }}
            className={styles.textArea}
            onChange={onEditText}
            onClick={(e) => e.stopPropagation()}
            // onBlur={saveText}
            spellCheck={false}
            autoCorrect="off"
            autoCapitalize="off"
            autoComplete="off"
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false"
          />
        </Html>
      </Group>
      <Group x={state.x} y={state.y} rotation={state.rotation} offsetX={-500}>
        <Html
          divProps={{
            style: {
              visibility: 'hidden',
            },
            className: styles.textAreaContainer,
          }}
        >
          <div
            ref={growingElementRef}
            style={{
              ...textareaStyle,
              width: `calc(100% - ${PADDING * 2}px)`,
              height: `calc(100% - ${PADDING * 2}px)`,
              backgroundColor: 'red',
            }}
            className={styles.textAreaInvisible}
          >
            {state.text || 'A'}
          </div>
        </Html>
      </Group>
    </Group>
  );
};
