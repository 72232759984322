import { Icon, IconProps } from './Icon';

export const Check = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 10 8">
      <defs />
      <path
        fill="currentColor"
        d="M.765 3.735a.375.375 0 00-.53.53l.53-.53zM3.5 7l-.265.265.265.265.265-.265L3.5 7zm6.265-5.735a.375.375 0 00-.53-.53l.53.53zm-9.53 3l3 3 .53-.53-3-3-.53.53zm3.53 3l6-6-.53-.53-6 6 .53.53z"
      />
    </Icon>
  );
};
