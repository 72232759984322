import { api } from '../services';

/**
 *
 * @param {*} accesstoken This is the accesstoken of the user obtained from Google
 */

type Props = {
  access_token: string;
  invitation_code?: string | string[] | null;
  promo_code?: string | string[] | null;
};
const googleLogin = async ({
  access_token,
  invitation_code,
  promo_code,
}: Props) => {
  const { data } = await api.post('/auth/google/connect/', {
    access_token,
    invitation_code,
    promo_code,
  });
  return data;
};

export default googleLogin;
