import React, { PropsWithChildren } from 'react';

import {
  Centrifuge,
  PublicationContext,
  SubscriptionState,
  SubscriptionStateContext,
  // SubscriptionStateContext,
  // SubscriptionState,
} from 'centrifuge';

import { api } from '../../api';
import { User } from '../../AppContext';
import { CentrifugeContext } from './context';
import { handleEvent } from './events';

const WS_ENDPOINT = import.meta.env.VITE_WS_URL;

export const CentrifugeConnect = ({
  children,
  user,
}: PropsWithChildren<{ user: User }>) => {
  const personalChannel = 'vai-personal:' + user.id;
  const [centrifuge, setCentrifuge] = React.useState<Centrifuge>(
    new Centrifuge(WS_ENDPOINT, {
      getToken: getConnectionToken,
      debug: import.meta.env.DEV,
    }),
  );

  const getPersonalChannelSubscriptionToken = () => {
    return getSubscriptionToken(personalChannel);
  };

  React.useEffect(() => {
    if (!WS_ENDPOINT) {
      console.error('Need WS endpoint');
      return;
    }

    const init = async () => {
      centrifuge.connect();
      setCentrifuge(centrifuge);
    };

    init();

    return () => {
      if (centrifuge) {
        centrifuge.disconnect();
      }
    };
  }, [user.id]);

  React.useEffect(() => {
    if (!centrifuge) return;

    if (centrifuge.getSubscription(personalChannel)) return;

    const sub = centrifuge.newSubscription(personalChannel, {
      getToken: getPersonalChannelSubscriptionToken,
    });

    sub.on('publication', (ctx: PublicationContext) => {
      handleEvent(ctx.data);
    });

    // Used for cehcking connection status
    sub.on('state', (ctx: SubscriptionStateContext) => {
      if (ctx.newState == SubscriptionState.Subscribed) {
        console.log('Subscribed');
      } else {
        console.log('Unsubscribed');
      }
    });

    sub.subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [centrifuge]);

  return (
    <CentrifugeContext.Provider value={centrifuge}>
      {children}
    </CentrifugeContext.Provider>
  );
};

const getSubscriptionToken = async (channel: string): Promise<string> => {
  const response = await api.get(
    `${import.meta.env.VITE_API_BASE_URL}/connections/ws/subscription_token`,
    {
      params: {
        channel,
      },
    },
  );
  return response.data.token;
};

const getConnectionToken = async () => {
  const response = await api.get(
    `${import.meta.env.VITE_API_BASE_URL}/connections/ws/connection_token`,
    {},
  );
  return response.data.token;
};
