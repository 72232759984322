import React from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { motion, MotionProps } from 'framer-motion';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';
import { useOnClickOutside } from '@visualist/hooks';

import {
  $editingStickyId,
  $hoveredStickyId,
  editedSticky,
  selectedSticky,
} from '@src/entities/Stickies/model';
import { StickyDropDown } from '@src/entities/Stickies/StickyDropDown';
import { StickyFooter } from '@src/entities/Stickies/StickyFooter';
import { StickyHeader } from '@src/entities/Stickies/StickyHeader';
import { EmptySticky } from '@src/entities/Stickies/useStickies';

import { StickyType } from '../types';
import { useStickyAction } from '../useStickyAction';

import styles from './styles.module.css';

interface BaseStickyProps
  extends Omit<
      React.HTMLAttributes<HTMLDivElement>,
      | 'children'
      | 'onAnimationStart'
      | 'onDrag'
      | 'onDragEnd'
      | 'onDragStart'
      | 'style'
    >,
    MotionProps {
  sticky: StickyType | EmptySticky;
  enableDragControls: boolean;
  blockId: string;
  removeNewStickies: () => void;
  saveStickyText: (text: string) => void;
  text: string;
  setText: (text: string) => void;
  showDropdown: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  type?: 'mini' | 'normal' | 'medium';
  textSize: React.ComponentProps<typeof TypographyPoppins>['labelSize'];
  showActionableBadge?: boolean;
}

/**
 * BaseSticky
 * @description
 * Base sticky component that is used to render a sticky note, needs to be encapsulated by one that makes it more specific and passes in methods that allow it to handle positioning
 *
 * @param {BaseStickyProps}
 * {sticky} - StickyType | EmptySticky - The sticky note object
 * {index} - number - The index of the sticky note
 * {enableDragControls} - boolean - Whether or not to enable drag controls
 * {blockId} - string - The block id
 * {isDragging} - boolean - Whether or not the sticky is being dragged
 * {removeNewStickies} - () => void - Method to remove new stickies
 * {saveStickyText} - (text: string) => void - Method to save the sticky text
 * {text} - string - The text of the sticky
 * {setText} - (text: string) => void - Method to set the text of the sticky
 */

export const BaseSticky = React.forwardRef<
  React.ElementRef<'div'>,
  BaseStickyProps
>(
  (
    {
      sticky,
      blockId,
      // enableDragControls = false,
      removeNewStickies,
      saveStickyText,
      text,
      setText,
      showDropdown,
      setShowDropdown,
      type = 'mini',
      textSize,
      showActionableBadge = false,
      ...props
    },
    ref,
  ) => {
    const { createStickyAction, deleteStickyAction, undoDeleteStickyAction } =
      useStickyAction({ imageId: blockId });
    // Effector
    const [hoveredStickyId, editingStickyId] = useUnit([
      $hoveredStickyId,
      $editingStickyId,
    ]);

    // Derived properties
    const isEditing = editingStickyId === sticky.id;
    const { action_sticky } = sticky;
    const isActionable = !!action_sticky && !action_sticky.is_removed;

    // Refs
    const cardRef = React.useRef<HTMLDivElement>(null);
    const textRef = React.useRef(text);
    const textareaRef = React.useRef<React.ElementRef<'textarea'>>(null);

    React.useImperativeHandle(
      ref,
      () => {
        return cardRef.current as React.ElementRef<'div'>;
      },
      [],
    );

    // Effects
    React.useEffect(() => {
      textRef.current = text;
    }, [text]);

    React.useEffect(() => {
      if (isEditing) {
        // Set input to have cursor selected
        setTimeout(() => {
          if (!textareaRef.current) return;
          textareaRef.current.setSelectionRange(text.length, text.length);
          textareaRef.current.focus();
        }, 50);
      } else {
        // When closing sticky set text selection to start
        if (!textareaRef.current) return;
        textareaRef.current.scrollTo({ top: 0 });
      }
    }, [isEditing]);

    React.useEffect(() => {
      // If new sticky, wait 1200ms before entering edit mode
      let timeout: NodeJS.Timeout | null = null;
      if (sticky.id === 'new-sticky') {
        timeout = setTimeout(() => {
          editedSticky(sticky.id);
        }, 500);
      }

      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }, []);

    useOnClickOutside(cardRef, (e) => {
      // Checking if we are editing and there is text
      if (isEditing && text) {
        editedSticky(null);
        saveStickyText(text);
      }

      const target = e.target as Node;
      if (!text && !target.contains(cardRef.current)) {
        // clicked outside of placeholder sticky no need to save
        removeNewStickies();
      }
    });

    const updateText = (e: React.ChangeEvent<React.ElementRef<'textarea'>>) => {
      setText(e.target.value);

      // Do not save if sticky is new (placeholder)
      if (sticky.id === 'new-sticky') return;
      saveStickyText(e.target.value);
    };

    const toggleActionable = () => {
      if (isActionable) {
        deleteStickyAction.mutate(action_sticky.id);
      } else {
        selectedSticky(sticky.id);
        // If sticky is new, we need to create it first else undo the previous delete
        if (action_sticky) {
          undoDeleteStickyAction.mutate(action_sticky.id);
          return;
        }

        createStickyAction.mutate({
          stickyId: sticky.id,
          status: 'open',
        });
      }
    };

    return (
      <motion.div
        ref={cardRef}
        onContextMenu={(e) => {
          e.preventDefault();
          setShowDropdown((v) => !v);
        }}
        tabIndex={-1}
        className={cn(styles.container, styles.placeholderContainer, {
          [styles.isEditingContainer]: isEditing,
        })}
        style={{
          zIndex: isEditing || hoveredStickyId === sticky.id ? 100 : 50,
        }}
        {...props}
      >
        <div
          className={styles.bg}
          style={{
            backgroundColor:
              text.length === 0
                ? 'var(--color-brew-soft)'
                : sticky.background_hex,
          }}
        >
          <div className={styles.innerContainer}>
            <StickyHeader
              createdBy={sticky.created_by}
              createdAt={sticky.created_at}
              size={textSize}
              expanded={isEditing}
              isActionable={isActionable}
              showActionableBadge={showActionableBadge}
            />
            <div className={styles.content}>
              <div
                className={cn(styles.baseText, styles.textContainer, {
                  [styles.miniText]: !isEditing && type === 'mini',
                  [styles.normalText]: !isEditing && type === 'normal',
                  [styles.mediumText]: !isEditing && type === 'medium',
                })}
                data-replicated-value={text}
              >
                <textarea
                  rows={isEditing ? 1 : 2}
                  ref={textareaRef}
                  onClick={(e) => e.stopPropagation()}
                  onDoubleClick={(e) => e.stopPropagation()}
                  onPointerDownCapture={(e) => e.stopPropagation()}
                  onTouchStart={(e) => e.stopPropagation()}
                  className={cn(styles.baseText, {
                    [styles.text]: !isEditing,
                    [styles.textarea]: isEditing,
                    [styles.placeholderText]: !isEditing && text.length === 0,
                    [styles.italics]: !isEditing && text.length === 0,
                  })}
                  value={text}
                  onChange={updateText}
                  placeholder="Add a sticky note"
                  disabled={!isEditing}
                  onBlur={(e) => {
                    // Do nothing if sticky is new and has no text
                    if (!e.target.value && sticky.id === 'new-sticky') return;
                    saveStickyText(e.target.value);
                    editedSticky(null);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      saveStickyText(e.target.value);
                      editedSticky(null);
                    }
                  }}
                />
              </div>
            </div>
            {!isEditing ? (
              <StickyFooter
                isSelected={false}
                numberOfReplies={sticky.comment_count}
                isEditing={isEditing}
                setShowDropdown={setShowDropdown}
                size={textSize}
              />
            ) : null}
          </div>
          <StickyDropDown
            sticky={sticky}
            blockId={blockId}
            showDropdown={
              showDropdown && !isEditing && sticky.id !== 'new-sticky'
            }
            isActionable={isActionable}
            toggleActionable={toggleActionable}
            setShowDropdown={setShowDropdown}
          />
        </div>
      </motion.div>
    );
  },
);
