import { Plugin, PluginKey } from 'prosemirror-state';
import { ReplaceStep } from 'prosemirror-transform';

import { Extension } from '@tiptap/core';

// This filter is created to prevent images to be accidentally deleted when their text wrap is front or behind
const filterDeletePlugin = new Plugin({
  key: new PluginKey('filterDeletePlugin'),
  filterTransaction: (transaction, state) => {
    const allowTransaction = true; // Default to allowing the transaction
    const replaceSteps: ReplaceStep[] = [];
    transaction.steps.forEach((step) => {
      if (step instanceof ReplaceStep) {
        replaceSteps.push(step);
      }
    });

    replaceSteps.forEach((step, index) => {
      const isDelete = step.slice.size === 0 && step.from !== step.to;
      if (isDelete) {
        const map = transaction.mapping.maps[index] as any;
        const oldStart = map.ranges[0];
        const oldEnd = map.ranges[0] + map.ranges[1];
        const imagePositions: { from: number; to: number }[] = [];
        let imageInSelection = false;
        let nonImageContentInSelection = false;

        state.doc.nodesBetween(oldStart, oldEnd, (node, pos) => {
          if (node.type.name === 'FileComponent') {
            const textWrap = node.attrs.textWrap;
            if (textWrap === 'front' || textWrap === 'behind') {
              imageInSelection = true;
              imagePositions.push({ from: pos, to: pos + node.nodeSize });
            }
          } else if (node.type.name !== 'paragraph') {
            // Consider other content that is not a paragraph
            nonImageContentInSelection = true;
          }
          return true; // Continue iterating
        });
        // if it tries to delete both an image and other content
        if (imageInSelection && nonImageContentInSelection) {
          const tr = state.tr;
          imagePositions.forEach(({ from }) => {
            const node = state.doc.nodeAt(from);
            if (node) {
              // Adjust the transaction to exclude the image from deletion
              tr.insert(0, node);
              transaction.step(tr.steps[tr.steps.length - 1]); // Add the modified step back to the transaction
            }
          });
        }
      }
    });

    return allowTransaction;
  },
});

export const FilterDeleteExtension = Extension.create({
  name: 'filterDelete',

  addProseMirrorPlugins() {
    return [filterDeletePlugin];
  },
});
