import { uploadImage as uploadFile } from '@api/blocks';
import { EditorState } from '@tiptap/pm/state';
import { DecorationSet, EditorView } from '@tiptap/pm/view';

import { placeholderKey } from '../plugins/placeholder';

// For prosemirror specific reference check out https://prosemirror.net/examples/upload/

export const uploadImage = async ({
  files,
  view,
}: {
  files: FileList;
  view: EditorView;
}) => {
  const uploadPromises = Array.from(files).map((file) => {
    return new Promise<void>((resolve, reject) => {
      const fileSize = parseFloat((file.size / 1024 / 1024).toFixed(4));
      if (
        (file.type === 'image/png' || file.type === 'image/jpeg') &&
        fileSize < 10
      ) {
        const formData = new FormData();
        formData.append('file', file);

        const id = {};
        // Create tr to replace selection for placeholder to be added as a decoration
        const tr = view.state.tr;
        if (!tr.selection.empty) tr.deleteSelection();

        let adjustedWidth = 0;
        let adjustedHeight = 0;
        let aspectRatio = 0;
        const pos = view.state.selection.from;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const $pos = view.state.doc.resolve(pos);
          const parent = $pos.parent;
          const img = new Image();
          img.src = reader.result as string;

          img.onload = () => {
            aspectRatio = parseFloat((img.height / img.width).toFixed(2));
            adjustedWidth = img.width;
            adjustedHeight = img.height;

            let isInTableCell = false;

            if (parent.type.name === 'tableCell') {
              isInTableCell = true;
            } else {
              let node = $pos.node($pos.depth);
              while (node && node.type.name !== 'tableCell' && $pos.depth > 0) {
                $pos.depth--;
                node = $pos.node($pos.depth);
              }
              if (node && node.type.name === 'tableCell') {
                isInTableCell = true;
              }
            }

            if (isInTableCell) {
              const cellDOM = view.nodeDOM($pos.start()) as HTMLElement;
              const cellWidth = cellDOM?.getBoundingClientRect().width;
              if (cellWidth) {
                adjustedWidth = cellWidth;
                adjustedHeight = parseFloat(
                  (aspectRatio * adjustedWidth).toFixed(2),
                );
              }
            } else {
              if (img.width > 330) {
                adjustedWidth = 330;
                adjustedHeight = parseFloat((330 * aspectRatio).toFixed(2));
              }
            }

            tr.setMeta(placeholderKey, {
              add: {
                id,
                pos,
                src: reader.result,
                width: adjustedWidth,
                height: adjustedHeight,
              },
            });
            view.dispatch(tr);
          };
        };

        uploadFile(formData)
          .then((response) => {
            // On success find placeholder and replace it with the image
            const decoPos = findPlaceholder(view.state, id);

            if (!decoPos) return;

            const { schema } = view.state;
            const fileId = response.data.id;
            const imageNode = schema.nodes.FileComponent.create({
              src: response.data.file.full_size,
              width: adjustedWidth,
              height: adjustedHeight,
              aspectRatio: aspectRatio,
              actualHeight: adjustedHeight,
              actualWidth: adjustedWidth,
              alt: fileId,
              fileId: fileId,
              fileType: 'image',
              textWrap: 'inline',
            });

            const transaction = view.state.tr
              .replaceWith(decoPos, decoPos, imageNode)
              .setMeta(placeholderKey, { remove: { id } });

            resolve(view.dispatch(transaction));
          })
          .catch(() => {
            const transaction = view.state.tr
              .delete(pos, pos)
              .setMeta(placeholderKey, { remove: { id } });
            view.dispatch(transaction);
            reject();
          });
      }
    });
  });

  return Promise.all(uploadPromises);
};

function findPlaceholder(state: EditorState, id: object) {
  const decos = placeholderKey.getState(state) as DecorationSet;
  const found = decos.find(undefined, undefined, (spec) => spec.id == id);
  return found.length ? found[0].from : null;
}
