import { ReactNode } from 'react';

import { TextStyleButton } from '../../../text-style-segmented-button';

import styles from './styles.module.css';

export const OptionsMenu = (props: {
  options: Array<{
    icon: ReactNode;
    onClick: () => void;
    tooltipText: string;
  }>;
}) => {
  const { options } = props;
  return (
    <div className={styles.container}>
      {options.map((o, idx) => (
        <>
          {idx === options.length - 1 ? (
            <div
              key={`${o.tooltipText}-divider`}
              className={styles.divider}
            ></div>
          ) : null}
          <TextStyleButton
            key={`${o.tooltipText}`}
            isActive={false}
            toggle={o.onClick}
            textIcon={o.icon}
            textIconStyle={{}}
            tooltipText={o.tooltipText}
          />
        </>
      ))}
    </div>
  );
};
