import { combine, createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';

export const moveBoardDialogOpened = createEvent();
export const moveBoardDialogClosed = createEvent();

export const warningDialogOpened = createEvent();
export const warningDialogClosed = createEvent();

export const searchModalOpened = createEvent<boolean>();
export const searchModalClosed = createEvent();

export const hubIdForAddingSelected = createEvent<string>();
export const hubIdForAddingCleared = createEvent();

export const hubIdForDeletingSelected = createEvent<string>();
export const hubIdForDeletingCleared = createEvent();

export const hubNameEntered = createEvent<string>();
export const hubNameCleared = createEvent();

export const boardIdForHubSelected = createEvent<string>();
export const boardIdForHubCleared = createEvent();

export const boardWarningEntered = createEvent<string>();
export const boardWarningCleared = createEvent();

// Move board dialog
export const $isShowMoveBoardDialog = createStore(false);

$isShowMoveBoardDialog
  .on(moveBoardDialogOpened, () => true)
  .reset(moveBoardDialogClosed);

sample({
  clock: moveBoardDialogClosed,
  target: [
    hubIdForAddingCleared,
    hubNameCleared,
    boardIdForHubCleared,
    hubIdForDeletingCleared,
    boardWarningCleared,
  ],
});

// Warning dialog
export const $isShowWarningDialog = createStore(false);

$isShowWarningDialog
  .on(warningDialogOpened, () => true)
  .reset(warningDialogClosed);

// Search modal
export const $isSearchModalOpen = createStore(false);

$isSearchModalOpen
  .on(searchModalOpened, (_, state) => state)
  .reset(searchModalClosed);

// The hub id for adding a board
export const $hubdIdForAdding = createStore<string | null>(null);

$hubdIdForAdding
  .on(hubIdForAddingSelected, (_, id) => id)
  .reset(hubIdForAddingCleared);

// The hub id for deleting a board
export const $hubdIdForDeleting = createStore<string | null>(null);

$hubdIdForDeleting
  .on(hubIdForDeletingSelected, (_, id) => id)
  .reset(hubIdForDeletingCleared);

// Hub name
export const $hubName = createStore('');

$hubName.on(hubNameEntered, (_, name) => name).reset(hubNameCleared);

// The board id for adding/deleting a board to hub
export const $boardIdForHub = createStore<string | null>(null);

$boardIdForHub
  .on(boardIdForHubSelected, (_, id) => id)
  .reset(boardIdForHubCleared);

// Board name for the warning dialog
export const $boardWarningName = createStore('');

$boardWarningName
  .on(boardWarningEntered, (_, name) => name)
  .reset(boardWarningCleared);

/* --------- Shortcuts --------- */

const $isClose = combine(
  $isSearchModalOpen,
  $isShowWarningDialog,
  (isSearchModalOpen, isShowWarningDialog) =>
    !isSearchModalOpen && !isShowWarningDialog,
);

// Close the move board dialog if the hub search modal is closed
hotkey({
  key: 'Escape',
  filter: $isClose,
  target: moveBoardDialogClosed,
});

// Close the hub search modal
hotkey({
  key: 'Escape',
  filter: $isSearchModalOpen,
  target: searchModalClosed,
});

// Close the warning dialog
hotkey({
  key: 'Escape',
  filter: $isShowWarningDialog,
  target: warningDialogClosed,
});
