import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

const useQueryParameters = () => {
  const history = useHistory();
  const location = useLocation();

  /**
   * add new query parameter to history
   * @param {object} queryParameters your parameter want to be added
   */
  const addToQueryParams = (queryParameters: Record<string, unknown> = {}) => {
    const currentQuery =
      qs.parse(location.search, {
        arrayFormat: 'comma',
      }) || {};

    const mergedQuery = { ...currentQuery, ...queryParameters } as Record<
      string,
      unknown
    >;

    Object.keys(mergedQuery).forEach(
      (key) => mergedQuery[key] === null && delete mergedQuery[key],
    );

    const newQuery = qs.stringify(
      { ...mergedQuery },
      {
        arrayFormat: 'comma',
        skipNull: true,
        skipEmptyString: true,
      },
    );

    if (location.search === `?${newQuery}`) {
      return;
    }

    history.push({
      search: newQuery,
    });
  };

  return { addToQueryParams };
};

export default useQueryParameters;
