import { useEffect, useState } from 'react';

import { getHubs, HubResponse } from '@api/hubs';
import { Hub } from '@pages/action-items/modal';
import { HUBS_SUGGESTIONS_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

import { SearchModal } from '../ui/modal';

import styles from './styles.module.css';

export const HubSearch = ({
  children,
  onResultClick,
  selectedHub,
  isOpen,
  setIsOpen,
}: {
  children: React.ReactElement<{ onClick?: (e: React.MouseEvent) => void }>;
  onResultClick: (hub: HubResponse) => void;
  selectedHub?: Hub;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
}) => {
  const [hubName, setHubName] = useState('');

  const { data: hubsSuggestions = [], isLoading } = useQuery({
    queryKey: [HUBS_SUGGESTIONS_QUERY, hubName.toLowerCase()],
    queryFn: () => getHubs({ name: hubName.toLowerCase() }),
  });

  useEffect(() => {
    if (!isOpen) {
      setHubName('');
    }
  }, [isOpen]);

  return (
    <SearchModal
      placeholder="Search for a hub"
      searchTerm={hubName}
      updateSearchTerm={setHubName}
      onClose={() => setHubName('')}
      isLoading={isLoading}
      results={hubsSuggestions.map((h) => ({
        id: h.id,
        name: h.name,
        onClick: () => onResultClick(h),
        isSelected: h.id === selectedHub?.id,
      }))}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      renderItem={(row) => (
        <SearchModal.Row
          key={row.id}
          data={row}
          displaySelection={true}
          className={styles.hubListItem}
          onClose={close}
        />
      )}
    >
      {children}
    </SearchModal>
  );
};
