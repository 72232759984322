import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { actions, NOTES_QUERY } from '@src/shared/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  createStickyAction as createStickyActionApi,
  deleteStickyAction as deleteStickyActionApi,
  undoDeleteStickyAction as undoDeleteStickyActionApi,
  updateStickyAction as updateStickyActionApi,
} from './api';

type Props = {
  imageId: string;
  actionId?: string;
  clearActionId?: (id: string) => void;
};

export const useStickyAction = ({
  imageId,
  actionId,
  clearActionId,
}: Props) => {
  const queryClient = useQueryClient();

  const createStickyAction = useMutation({
    mutationFn: createStickyActionApi,
    onMutate: async () => {
      // Magically update the sticky to be the actionable one
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY, { imageId }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
    },
    onError: () => {
      startedSnack({
        label: "Couldn't create sticky action",
        close: true,
      });
    },
  });

  const updateStickyAction = useMutation({
    mutationFn: updateStickyActionApi,
    onMutate: async () => {
      // Magically update the sticky to be the actionable one
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY, { imageId }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
      if (clearActionId) {
        clearActionId('');
      }
    },
  });

  const deleteStickyAction = useMutation({
    mutationFn: deleteStickyActionApi,
    onMutate: async () => {
      // Magically update the sticky to be the actionable one
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY, { imageId }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
      if (actionId) {
        startedSnack({
          label: 'Unmarked action',
          action: {
            label: 'Undo',
            action: () => {
              undoDeleteStickyAction.mutate(actionId);
            },
          },
          close: true,
        });
      }
    },
  });

  const undoDeleteStickyAction = useMutation({
    mutationFn: undoDeleteStickyActionApi,
    onMutate: async () => {
      // Magically update the sticky to be the actionable one
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY, { imageId }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
    },
  });

  return {
    createStickyAction,
    updateStickyAction,
    deleteStickyAction,
    undoDeleteStickyAction,
  };
};
