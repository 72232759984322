import React, {
  ComponentProps,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
} from 'react';

import cn from 'classnames';

import TypographyPoppins from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

type Style = 'small' | 'extended';

interface FabProps {
  label?: string;
  style?: Style;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: ReactNode;
  className?: string;
}

export const Fab = ({
  label,
  disabled,
  style,
  onClick,
  icon,
  className,
  ...rest
}: FabProps & Omit<ComponentProps<'button'>, 'style'>) => {
  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onClick) onClick(e);
  };

  return (
    <button
      className={cn(styles.enabled, className, {
        [styles.disabled]: disabled,
        [styles.enabledExtended]: style === 'extended',
      })}
      onClick={handleOnClick}
      {...rest}
    >
      <span
        className={cn(styles.plusIcon, {
          [styles.disabledPlusIcon]: disabled,
          [styles.plusIconOutlined]: style === 'small' && disabled,
          [styles.plusIconElevated]: style === 'extended' && disabled,
        })}
      >
        {icon}
      </span>
      {label && style === 'extended' && (
        <TypographyPoppins type="label" size="L">
          <span
            className={cn(styles.label, {
              [styles.disabledLabel]: disabled,
            })}
          >
            {label}
          </span>
        </TypographyPoppins>
      )}
    </button>
  );
};
