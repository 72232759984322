import { useEffect } from 'react';

export const useKeyPress = ({
  key,
  onKeyDown,
}: {
  key: string;
  onKeyDown?: (event: KeyboardEvent) => void;
}): void => {
  useEffect(() => {
    const handleDown = (event: KeyboardEvent) => {
      const element = event.target as Element;
      if (element && element.matches('input')) return;
      if (event.repeat) return;
      const { key: pressedKey } = event;
      if (key === pressedKey && onKeyDown) {
        onKeyDown(event);
      }
    };

    window.addEventListener('keydown', handleDown);

    return () => {
      window.removeEventListener('keydown', handleDown);
    };
  }, [key, onKeyDown]);
};
