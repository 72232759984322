import { AxiosResponse } from 'axios';

import {
  ImageBlock,
  ImageBlockResponse,
  setLayer,
} from '@pages/StudioPage/api';
import { GET_IMAGES_QUERY } from '@src/shared/constants/query-names';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useLayer = () => {
  const queryClient = useQueryClient();

  const mutateLayer = useMutation({
    mutationFn: ({
      designId,
      imageId,
      typeOfLayerAction,
    }: {
      designId: string;
      imageId: string;
      typeOfLayerAction: 'forward' | 'backward';
    }) => {
      const allBlocksResponse = queryClient.getQueryData<
        AxiosResponse<ImageBlockResponse>
      >([GET_IMAGES_QUERY, designId]);

      if (!allBlocksResponse) throw Error('No image data in query cache');

      const allBlocks = allBlocksResponse.data.results.sort((a, b) => {
        return a.studio.layer - b.studio.layer;
      });

      const newBlocks = [...allBlocks];

      const index = newBlocks.findIndex((v) => v.id === imageId);

      // TODO FIX THIS
      if (index === -1) throw Error('Image id not found in block');

      const blockToMove = newBlocks.splice(index, 1)[0];

      if (typeOfLayerAction === 'forward') {
        newBlocks.push(blockToMove);
      } else {
        newBlocks.unshift(blockToMove);
      }

      // Optimistic update of query data
      // TODO add optimistic updates here
      // queryClient.setQueryData([GET_IMAGES_QUERY, designId], )

      const mappedLayers = newBlocks.map((b, index) => ({
        block: b.id,
        layer: index,
      }));

      return setLayer({ setId: designId, layers: mappedLayers });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [GET_IMAGES_QUERY, variables.designId],
      });
    },
  });

  const mutateLayers = useMutation({
    mutationFn: ({
      designId,
      imageBlocks,
    }: {
      designId: string;
      imageBlocks: ImageBlock[];
    }) => {
      // const imageBlockResponse = queryClient.getQueryData<
      //   AxiosResponse<ImageBlockResponse>
      // >([GET_IMAGES_QUERY, designId]);

      // if (!imageBlockResponse) throw Error('No image data in query cache');

      // const allBlocks = imageBlockResponse.data.results;

      // const newBlocks = [...allBlocks];

      const mappedLayers = imageBlocks.map((b, index) => ({
        block: b.id,
        layer: index,
      }));

      return setLayer({ setId: designId, layers: mappedLayers });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [GET_IMAGES_QUERY, variables.designId],
      });
    },
  });

  return {
    mutateLayer,
    mutateLayers,
  };
};
