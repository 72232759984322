import { createEvent, createStore } from 'effector';

export const settingsSheetOpened = createEvent();
export const settingsSheetClosed = createEvent();

export const $isSettingsSheetOpen = createStore(false);

$isSettingsSheetOpen
  .on(settingsSheetOpened, (open) => !open)
  .reset(settingsSheetClosed);
