import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { changeInviteePermissions, ChangingPermissions } from '@api/boards';
import { queryClient } from '@src/queryClient';
import { MEMBERS_INVITATION_QUERY } from '@src/shared/constants/query-names';
import { MutateOptions, useMutation } from '@tanstack/react-query';

interface Props {
  options?: MutateOptions<ChangingPermissions, any, ChangingPermissions>;
}

export const useChangePermissions = ({ options }: Props) => {
  const { mutate: chahgePermissions } = useMutation({
    mutationFn: ({
      boardId,
      member,
      email,
      permissions,
    }: ChangingPermissions) =>
      changeInviteePermissions({ boardId, member, email, permissions }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_INVITATION_QUERY],
      });
      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      startedSnack({
        label: `Couldn't update ${variables.email}'s} permissions.`,
        action: {
          label: 'Try again',
          action: () => {
            chahgePermissions({
              boardId: variables.boardId,
              member: variables.member,
              email: variables.email,
              permissions: variables.permissions,
            });
          },
        },
        close: true,
      });

      if (options?.onError) options?.onError(error, variables, context);
    },
  });

  return { chahgePermissions };
};
