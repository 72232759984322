import { createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';

export const boardDialogOpened = createEvent();
export const boardDialogClosed = createEvent();

export const stepContinue = createEvent();
export const stepBack = createEvent();

export const boardIdSelected = createEvent<string>();
export const boardIdCleared = createEvent();

export const boardNameEntered = createEvent<string>();
export const boardNameCleared = createEvent();

export const boardDescriptionEntered = createEvent<string>();
export const boardDescriptionCleared = createEvent();

export const widthSetted = createEvent<number>();

export const boardRenamingDialogOpened = createEvent();
export const boardRenamingDialogClosed = createEvent();

// Dialog
export const $isShowBoardDialog = createStore(false);

$isShowBoardDialog.on(boardDialogOpened, () => true).reset(boardDialogClosed);

sample({
  clock: [boardDialogClosed, boardRenamingDialogClosed],
  target: [boardIdCleared, boardNameCleared, boardDescriptionCleared],
});

// A store for tracking the current step
export const $step = createStore<1 | 2>(1);

$step.reset(boardDialogClosed);

sample({
  clock: stepContinue,
  source: {
    isShowBoardDialog: $isShowBoardDialog,
    step: $step,
  },
  filter: ({ isShowBoardDialog }) => isShowBoardDialog,
  fn: ({ step }) => {
    if (step === 1) {
      return 2;
    } else {
      return 1;
    }
  },
  target: $step,
});

sample({
  clock: stepBack,
  source: {
    isShowBoardDialog: $isShowBoardDialog,
    step: $step,
  },
  filter: ({ isShowBoardDialog }) => isShowBoardDialog,
  fn: ({ step }) => {
    if (step === 2) {
      return 1;
    } else {
      return 2;
    }
  },
  target: $step,
});

// Id
export const $boardId = createStore<string | null>(null);

$boardId.on(boardIdSelected, (_, id) => id).reset(boardIdCleared);

// Name
export const $boardName = createStore('');

$boardName
  .on(boardNameEntered, (_, boardName) => boardName)
  .reset(boardNameCleared);

// Description
export const $boardDescription = createStore('');

$boardDescription
  .on(boardDescriptionEntered, (_, boardDescription) => boardDescription)
  .reset(boardDescriptionCleared);

// Renaming dialog
export const $isShowBoardRenamingDialog = createStore(false);

$isShowBoardRenamingDialog
  .on(boardRenamingDialogOpened, () => true)
  .reset(boardRenamingDialogClosed);

hotkey({
  key: 'Escape',
  filter: $isShowBoardRenamingDialog,
  target: boardRenamingDialogClosed,
});
