import { getPinnedMessages } from '@api/vai';
import { vaiPinnedMessageKeys } from '@src/shared/constants/query-keys';
import { useQuery } from '@tanstack/react-query';

export const usePinnedMessages = (hubId: string) => {
  const pinnedMessageQuery = useQuery({
    queryKey: vaiPinnedMessageKeys.all,
    queryFn: () => getPinnedMessages(hubId),
  });

  return pinnedMessageQuery;
};
