import { ReactNode } from 'react';

import cn from 'classnames';

import { TabsStyle } from '..';

import styles from './styles.module.css';

interface LeadingIconProps {
  type: TabsStyle;
  icon: ReactNode;
  isDisabled?: boolean;
}

export const LeadingIcon = ({ icon, isDisabled, type }: LeadingIconProps) => (
  <span
    className={cn(styles.icon, {
      [styles.iconOnTop]: type === 'icon on top + label',
      [styles.disabled]: isDisabled,
    })}
  >
    {icon}
  </span>
);
