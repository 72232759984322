import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { removeBoardFromHub } from '../../../../../api/boards';
import {
  ALL_BOARDS_QUERY,
  ALL_HUBS_QUERY,
  BOARD_QUERY,
  BOARDS_SUGGESTIONS_QUERY,
  BOARDS_TREE_QUERY,
  MEMBERS_INVITATION_QUERY,
} from '../../../../../shared/constants';

export interface RemoveBoardToHub {
  hub?: string;
  boards: string[];
}

export const useRemoveBoardFromHub = () => {
  const queryClient = useQueryClient();
  const { mutate: deleteBoardFromHub } = useMutation({
    mutationFn: ({ hub, boards }: RemoveBoardToHub) =>
      removeBoardFromHub({ hub, boards }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARDS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_HUBS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_SUGGESTIONS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: variables.boards[0] }],
      });
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_INVITATION_QUERY, { board_id: variables.boards[0] }],
      });

      startedSnack({
        label: 'Removed board from hub',
        close: true,
      });
    },

    onError: (err, { hub, boards }) => {
      startedSnack({
        label: "Couldn't remove board from hub",
        action: {
          label: 'Try again',
          action: () => {
            deleteBoardFromHub({
              hub,
              boards,
            });
          },
        },
        close: true,
      });
    },
  });

  // const { mutate: undoRemoveBoardFromHub } = useMutation({
  //   mutationFn: ({ hub, boards }: AddBoardToHub) =>
  //     addOrMoveBoardToHub({ hub, boards }),
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({
  //       queryKey: [BOARDS_TREE_QUERY],
  //     });
  //     queryClient.invalidateQueries({
  //       queryKey: [ALL_BOARDS_QUERY],
  //     });
  //     queryClient.invalidateQueries({
  //       queryKey: [ALL_HUBS_QUERY],
  //     });
  //     queryClient.invalidateQueries({
  //       queryKey: [BOARDS_SUGGESTIONS_QUERY],
  //     });
  //   },
  // });

  return { deleteBoardFromHub };
};
