import React from 'react';

import { useUnit } from 'effector-react';
import { debounce } from 'lodash';

import {
  $editingStickyId,
  $hoveredStickyId,
  editedSticky,
  selectedSticky,
} from '@src/entities/Stickies/model';
import { StickyType } from '@src/entities/Stickies/types';
import useStickies, { EmptySticky } from '@src/entities/Stickies/useStickies';
import { Timeout } from '@src/shared/types';

import { BaseSticky } from '../../../../entities/Stickies/BaseSticky';
import {
  calculateTextHeight,
  chooseColour,
} from '../../../../entities/Stickies/utils';
import { CARD_SIZE, EXPANDED_CARD_SIZE, PADDING_SIZE } from '../constants';
import { useStickyPosition } from './useStickyPosition';

type Props =
  | {
      sticky: StickyType;
      imageHeight: number;
      imageWidth: number;
      containerRef: React.RefObject<React.ElementRef<'div'>>;
      index: number;
      blockId: string;
    }
  | {
      sticky: EmptySticky;
      imageHeight: number;
      imageWidth: number;
      containerRef: React.RefObject<React.ElementRef<'div'>>;
      index: number;
      blockId: string;
    };

export const FileCardSticky = ({
  sticky,
  imageHeight,
  imageWidth,
  containerRef,
  index,
  blockId,
}: Props) => {
  const [hoveredStickyId, editingStickyId] = useUnit([
    $hoveredStickyId,
    $editingStickyId,
  ]);

  const containerWidth = containerRef.current?.clientWidth ?? 0;
  const containerHeight = containerRef.current?.clientHeight ?? 0;

  const [text, setText] = React.useState(sticky.text);

  const [isDragging, setIsDragging] = React.useState(false);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [initialAnimationComplete, setInitialAnimationComplete] =
    React.useState(false);

  const shouldAnimate =
    new Date(sticky.created_at).getTime() < new Date().getTime() - 10000 ||
    sticky.id === 'new-sticky';

  const cardRef = React.useRef<React.ElementRef<'div'>>(null);
  const stickySaveTimeout = React.useRef<Timeout | null>(null);
  const clickTimeout = React.useRef<Timeout | null>(null);

  const isEditing = editingStickyId === sticky.id;

  const { position, setPosition, x, y, height, width } = useStickyPosition({
    sticky: sticky,
    position: {
      x: sticky.left_pixel > 1 ? 0 : sticky.left_pixel,
      y: sticky.top_pixel > 1 ? 0 : sticky.top_pixel,
    },
    isEditing,
    containerWidth,
    containerHeight,
    imageHeight,
    imageWidth,
    saveSticky: () => saveSticky({ text: text }),
    text,
  });

  const {
    mutateSticky,
    addStickyMutation,
    optimisticallyUpdateStickyUpdateAt,
    removeNewStickies,
  } = useStickies(blockId, null);

  React.useEffect(() => {
    // Cleanup timeout form saving on unmounting
    return () => {
      if (stickySaveTimeout.current) clearTimeout(stickySaveTimeout.current);
    };
  }, []);

  const saveSticky = ({
    x,
    y,
    text,
  }: {
    x?: number;
    y?: number;
    text: string;
  }) => {
    const xRatio = typeof x === 'number' ? x : position.x;
    const yRatio = typeof y === 'number' ? y : position.y;

    if (sticky.id === 'new-sticky') {
      // Create new sticky with data
      // Set sticky to go back to normal mode
      editedSticky(null);
      if (stickySaveTimeout.current) clearTimeout(stickySaveTimeout.current);
      stickySaveTimeout.current = setTimeout(() => {
        // Set timeout to have stickies start saving after animation ends
        addStickyMutation.mutate({
          data: {
            left_pixel: xRatio,
            top_pixel: yRatio,
            text,
            background_hex: chooseColour(index),
            updated_at: new Date().toISOString(),
            attached_to_id: undefined,
            attached_to_type: undefined,
            action_sticky: null,
          },
        });
      }, 800);
    } else {
      // Save sticky data
      mutateSticky.mutate({
        stickyId: sticky.id,
        data: {
          left_pixel: xRatio,
          top_pixel: yRatio,
          text,
          background_hex: sticky.background_hex,
          updated_at: new Date().toISOString(),
          attached_to_id: undefined,
          attached_to_type: undefined,
        },
      });
    }
  };

  const saveStickyDebounced = React.useCallback(
    debounce((text: string) => saveSticky({ text }), 1000),
    [],
  );

  const onDragStart = () => {
    optimisticallyUpdateStickyUpdateAt(sticky.id);
    setIsDragging(true);
  };

  const onDragEnd = () => {
    setIsDragging(false);

    const x = Math.abs(
      (cardRef.current?.getBoundingClientRect().x ?? 0) -
        (containerRef.current?.getBoundingClientRect().left ?? 0),
    );
    const y = Math.abs(
      (cardRef.current?.getBoundingClientRect().y ?? 0) -
        (containerRef.current?.getBoundingClientRect().top ?? 0),
    );

    // Calculate ratio
    const xRatio = x / containerWidth;
    const yRatio = y / containerHeight;

    setPosition({
      x: xRatio,
      y: yRatio,
    });

    if (sticky.id !== 'new-sticky') saveSticky({ x: xRatio, y: yRatio, text });
  };

  const selectSticky = () => {
    if (isDragging) return;
    selectedSticky(sticky.id);
  };

  const enterEditMode = () => {
    if (isDragging) return;

    editedSticky(isEditing ? null : sticky.id);
  };

  const handleClick = () => {
    // Do nothing if dropdown is open
    if (showDropdown) return;

    // If double click in progress, cancel single click
    if (clickTimeout.current) return;
    clickTimeout.current = setTimeout(() => {
      if (sticky.id === 'new-sticky') {
        enterEditMode();
      } else {
        // Only open sticky side if not in editing mode
        if (!isEditing) selectSticky();
      }

      clickTimeout.current = null;
    }, 200);
  };

  const handleDoubleClick = () => {
    if (showDropdown) return;

    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }
    enterEditMode();
  };

  const computedHeight = React.useMemo(() => calculateTextHeight(text), [text]);

  return (
    <BaseSticky
      textSize="XS"
      ref={cardRef}
      text={text}
      setText={setText}
      showDropdown={showDropdown}
      setShowDropdown={setShowDropdown}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onMouseEnter={() => optimisticallyUpdateStickyUpdateAt(sticky.id)}
      initial={{
        x: x,
        y: y,
        scale: shouldAnimate ? 0.8 : 1,
        opacity: shouldAnimate ? 0 : 1,
        height: CARD_SIZE,
        width: CARD_SIZE,
      }}
      animate={{
        x,
        y,
        width,
        height: isEditing ? computedHeight : height,
        scale: hoveredStickyId === sticky.id ? 1.1 : 1,
        opacity: 1,
      }}
      onAnimationComplete={() => setInitialAnimationComplete(true)}
      exit={shouldAnimate ? { scale: 0.8, opacity: 0 } : undefined}
      transition={
        isDragging
          ? {
              type: 'tween',
              duration: 0.5,
              ease: [0.2, 0, 0, 1],
            }
          : {
              type: 'tween',
              duration: 0.5,
              ease: [0.2, 0, 0, 1],
              // Delay causes lag with lots of items
              delay: initialAnimationComplete ? 0 : index * 0.05,
            }
      }
      whileHover={isEditing ? undefined : { scale: 1.1 }}
      whileTap={isEditing ? undefined : { scale: 0.95 }}
      // onTap={enterEditMode}
      showActionableBadge
      dragConstraints={{
        top: 0,
        left: 0,
        right:
          containerWidth -
          PADDING_SIZE -
          (isEditing ? EXPANDED_CARD_SIZE : CARD_SIZE),
        bottom: containerHeight - PADDING_SIZE - CARD_SIZE,
      }}
      dragElastic={0.05}
      dragMomentum={false}
      // Do not do a layout animation if there is text meaning we are saving the sticky
      // TODO Fix jank layout animation
      // layoutId="new-sticky"
      drag
      blockId={blockId}
      sticky={sticky}
      enableDragControls
      removeNewStickies={removeNewStickies}
      saveStickyText={saveStickyDebounced}
    />
  );
};
