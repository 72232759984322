import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { useHistory, useLocation, useParams } from 'react-router';

import {
  Avatar,
  Button,
  Checkbox,
  CheckboxRound,
  Dialog,
  Dropdown,
  Modal,
  SideSheet,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import DatePicker from '@visualist/design-system/src/components/v2/DatePicker';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { Action, SimpleAction } from '@api/actions';
import {
  useAction,
  useCreateAction,
  useDeleteAction,
  useMutateAction,
} from '@pages/action-items/hooks/useAction';
import { $actionFilters } from '@pages/action-items/modal';
import { AssigneeSearch } from '@pages/action-items/ui/Assignee';
import {
  ActionItemPriority,
  PriorityType,
} from '@pages/action-items/ui/priority';
import { isDateDue } from '@pages/action-items/utils';
import { FileCard } from '@pages/FileCard';
import { useAppData, User } from '@src/AppContext';
import { StickyType } from '@src/entities/Stickies/types';
import { formatDateString } from '@src/shared/utils/format-date';

import styles from './styles.module.css';

type Props = {
  isOpen: boolean;
  closeAction: () => void;
};

export const ActionItemDetails = ({ isOpen, closeAction }: Props) => {
  const { actionId: pathActionId } = useParams<{
    actionId?: string;
  }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryActionId = queryParams.get('actionId');
  const actionId = pathActionId || queryActionId || undefined;
  const { data: action } = useAction(actionId);
  const [filters] = useUnit([$actionFilters]);
  const { user } = useAppData();
  const defaultNewActionItem: SimpleAction = {
    id: 'new',
    assigned_to: [],
    completed_at: '',
    name: '',
    description: null as unknown as string,
    status: 'open',
    priority: 0,
    due_date: null as unknown as string,
    created_at: '',
    created_by: user,
    is_removed: false,
    marked_as_done_by: null,
    updated_at: '',
  };
  const [newAction, setNewAction] =
    useState<SimpleAction>(defaultNewActionItem);

  const createAction = useCreateAction(() => {
    setNewAction(defaultNewActionItem);
    startedSnack({
      label: `Created action item`,
      closeAction: true,
    });
  });

  const createActionWithName = () => {
    const { name, status, description, priority, due_date, assigned_to } =
      newAction;
    if (name) {
      createAction.mutate({
        name: name,
        hub: filters.hub?.id,
        status: status,
        description: description,
        priority: priority ?? 0,
        due_date: due_date ?? null,
        assigned_to: assigned_to,
      });
    } else if (
      priority ||
      description ||
      due_date ||
      (assigned_to && assigned_to?.length > 0)
    ) {
      startedSnack({
        label: `Oops, the name can’t be empty`,
        closeAction: true,
      });
    }
  };

  const backdropAction = () => {
    if (!action) {
      createActionWithName();
    }
    closeAction();
  };

  return (
    <SideSheet
      type="large"
      className={styles.sideSheetContainer}
      showSheet={isOpen}
      closeSideSheet={closeAction}
      handleBackdropClick={backdropAction}
      backdropVisible={false}
      displayMobileVariant={false}
    >
      {actionId && action ? (
        <ActionWithId action={action} closeAction={closeAction} />
      ) : (
        <ActionWithoutId
          actionId={actionId ?? 'new'}
          isOpen={isOpen}
          closeAction={closeAction}
          createActionWithName={createActionWithName}
          setNewAction={setNewAction}
          newAction={newAction}
        />
      )}
    </SideSheet>
  );
};

const ActionWithoutId = ({
  isOpen,
  actionId,
  closeAction,
  createActionWithName,
  setNewAction,
  newAction,
}: {
  isOpen: boolean;
  actionId: string;
  closeAction: () => void;
  createActionWithName: () => void;
  setNewAction: Dispatch<SetStateAction<SimpleAction>>;
  newAction: SimpleAction;
}) => {
  const nameRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && actionId === 'new' && nameRef.current) {
      nameRef.current.focus();
    }
  }, [actionId, isOpen]);

  const updateStatus = (status: SimpleAction['status']) => {
    setNewAction((prev) => ({
      ...prev,
      status,
    }));
  };

  const updatePriority = (priority: PriorityType) => {
    setNewAction((prev) => ({
      ...prev,
      priority,
    }));
  };

  const updateDueDate = (due_date: string | null) => {
    setNewAction((prev) => ({
      ...prev,
      due_date: due_date ?? '',
    }));
  };

  const updateAssignees = (assigned_to: User[]) => {
    setNewAction((prev) => ({
      ...prev,
      assigned_to,
    }));
  };

  const updateDescription = (description: string) => {
    setNewAction((prev) => ({
      ...prev,
      description,
    }));
  };

  const updateName = () => {
    const name = nameRef.current?.textContent?.trim();
    if (name) {
      setNewAction((prev) => ({
        ...prev,
        name,
      }));
    }
  };

  const backAction = () => {
    createActionWithName();
    closeAction();
  };

  return (
    <div className={styles.sideSheetWrapper}>
      <div className={styles.sideSheetHeadingWrapper}>
        <SideSheet.Heading
          className={styles.sideSheetHeadingContainer}
          backAction={backAction}
        >
          <Dropdown>
            <Dropdown.Menu
              side="bottom"
              density="-2"
              align="start"
              sideOffset={8}
              alignOffset={-50}
              trigger={
                <Button type="text" label="More options" onClick={() => {}} />
              }
            >
              <Dropdown.MenuItem
                item={{
                  content: 'Delete',
                  isDisabled: true,
                  leadingIcon: (
                    <Icon name="sprite/bin" color="var(--color-error-40)" />
                  ),
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </SideSheet.Heading>
      </div>
      <SideSheet.Body className={styles.sideSheetBodyContainer}>
        <SideSheet.ContentContainer>
          <TypographyPoppins
            type="title"
            size="L"
            className={styles.actionItemName}
            contentEditable={true}
            suppressContentEditableWarning
            ref={nameRef}
            onBlur={updateName}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                nameRef.current?.blur();
              }
            }}
          >
            {newAction.name}
          </TypographyPoppins>
        </SideSheet.ContentContainer>
        <ActionContents
          action={newAction}
          updateStatus={updateStatus}
          updatePriority={updatePriority}
          updateDueDate={updateDueDate}
          updateAssignees={updateAssignees}
          updateDescription={updateDescription}
        />
      </SideSheet.Body>
    </div>
  );
};

const ActionWithId = ({
  action,
  closeAction,
}: {
  action: Action;
  closeAction: () => void;
}) => {
  const [isOpenActionItemDeleteModal, setIsOpenActionItemDeleteModal] =
    useState(false);

  const nameRef = useRef<HTMLDivElement>(null);
  const isUpdating = useRef(false);

  const { mutateAction } = useMutateAction(action.id);

  const { removeAction } = useDeleteAction(() => {
    setIsOpenActionItemDeleteModal(false);
    startedSnack({
      label: 'Deleted action item',
      closeAction: true,
    });
    closeAction();
  });

  const closeDeleteActionItemModal = () => {
    setIsOpenActionItemDeleteModal(false);
  };

  const updateName = () => {
    if (isUpdating.current) return;
    isUpdating.current = true;

    const name = nameRef.current?.textContent?.trim();
    if (name) {
      if (name !== action.name) {
        mutateAction.mutate({
          name: name,
        });
      }
      nameRef.current?.blur();
    } else {
      nameRef.current?.focus();
      startedSnack({
        label: `Oops, the name can’t be empty`,
        closeAction: true,
      });
    }

    isUpdating.current = false;
  };

  const updateStatus = (status: SimpleAction['status']) => {
    mutateAction.mutate({
      status,
    });
  };

  const updatePriority = (priority: PriorityType) => {
    mutateAction.mutate({
      priority,
    });
  };

  const updateDueDate = (due_date: string | undefined | null) => {
    mutateAction.mutate({
      due_date: due_date === undefined ? null : due_date,
    });
  };

  const updateDescription = (description: string) => {
    mutateAction.mutate({
      description,
    });
  };

  const deleteActionItem = () => {
    if (action && action.id) removeAction(action.id);
  };

  return (
    <>
      <div className={styles.sideSheetWrapper}>
        <div className={styles.sideSheetHeadingWrapper}>
          <SideSheet.Heading
            className={styles.sideSheetHeadingContainer}
            backAction={closeAction}
          >
            <Dropdown>
              <Dropdown.Menu
                side="bottom"
                density="-2"
                align="start"
                sideOffset={8}
                alignOffset={-50}
                trigger={
                  <Button type="text" label="More options" onClick={() => {}} />
                }
              >
                <Dropdown.MenuItem
                  item={{
                    content: 'Delete',
                    leadingIcon: (
                      <Icon name="sprite/bin" color="var(--color-error-40)" />
                    ),
                    onClick: () => {
                      if (action?.id) setIsOpenActionItemDeleteModal(true);
                    },
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </SideSheet.Heading>
        </div>
        <SideSheet.Body className={styles.sideSheetBodyContainer}>
          <SideSheet.ContentContainer>
            <TypographyPoppins
              type="title"
              size="L"
              className={styles.actionItemName}
              contentEditable={true}
              suppressContentEditableWarning
              ref={nameRef}
              onBlur={() => {
                if (!isUpdating.current) updateName();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  updateName();
                }
              }}
            >
              {action?.name ?? ''}
            </TypographyPoppins>
          </SideSheet.ContentContainer>
          {(action?.sticky || action?.message) && (
            <ActionCreatedIn
              hub_id={action.hub}
              sticky={action.sticky}
              message={action.message}
            />
          )}
          <ActionContents
            action={action}
            updateStatus={updateStatus}
            updatePriority={updatePriority}
            updateDueDate={updateDueDate}
            updateDescription={updateDescription}
          />
        </SideSheet.Body>
      </div>
      <Modal
        handleClose={closeDeleteActionItemModal}
        showModal={isOpenActionItemDeleteModal}
      >
        <Dialog
          headline="Are you sure?"
          description="You’re about to delete this action item and all its information."
          action={deleteActionItem}
          actionLabel="Confirm"
          closeAction={closeDeleteActionItemModal}
        />
      </Modal>
    </>
  );
};

const ActionCreatedIn = ({
  hub_id,
  sticky,
  message,
}: {
  hub_id: string | null | undefined;
  sticky?: (StickyType & { block_file: string }) | null;
  message?: string | null;
}) => {
  const history = useHistory();

  if (sticky) {
    const openFileCard = () => {
      history.push(`#/block/${sticky.block}`);
    };

    return (
      <SideSheet.ContentContainer className={styles.createdInContainer}>
        <FileCard />
        <div className={styles.propertiesTitleWrapper}>
          <Icon
            name="sprite/sticky-action"
            color="var(--color-secondary-40)"
            size={24}
          />
          <TypographyPoppins
            type="body"
            bodySize="M"
            className={styles.propertyColor}
          >
            Created in
          </TypographyPoppins>
        </div>
        <div className={styles.createdInFileWrapper}>
          <img
            className={styles.createdInFile}
            src={sticky.block_file}
            onClick={openFileCard}
          />
        </div>
      </SideSheet.ContentContainer>
    );
  } else if (hub_id && message) {
    const openMessage = () => {
      history.push(`/messages/${hub_id}/${message}`);
    };

    return (
      <SideSheet.ContentContainer className={styles.createdInContainer}>
        <FileCard />
        <div className={styles.propertiesTitleWrapper}>
          <Icon
            name="sprite/sticky-action"
            color="var(--color-secondary-40)"
            size={24}
          />
          <TypographyPoppins
            type="body"
            bodySize="M"
            className={styles.propertyColor}
          >
            Created in
          </TypographyPoppins>
        </div>
        <div className={styles.createdInFileWrapper}>
          <div className={styles.createdInMessageWrapper} onClick={openMessage}>
            <Icon
              name="sprite/paper-plane"
              color="var(--color-secondary-40)"
              size={24}
            />
            <TypographyPoppins
              type="body"
              bodySize="M"
              className={styles.propertyColor}
              style={{}}
            >
              Created from message
            </TypographyPoppins>
          </div>
        </div>
      </SideSheet.ContentContainer>
    );
  } else {
    return null;
  }
};

const ActionContents = ({
  action,
  updateStatus,
  updatePriority,
  updateDueDate,
  updateAssignees,
  updateDescription,
}: {
  action: Action;
  updateStatus: (status: SimpleAction['status']) => void;
  updatePriority: (priority: PriorityType) => void;
  updateDueDate: (due_date: string | null) => void;
  updateAssignees?: (assigned_to: User[]) => void;
  updateDescription: (description: string) => void;
}) => {
  return (
    <SideSheet.ContentContainer className={styles.actionItemDetailsContainer}>
      <ActionItemStatus
        status={action.status}
        updateStatus={updateStatus}
        action={action}
      />
      <ActionItemDetailsPriority
        priority={action.priority}
        updatePriority={updatePriority}
      />
      <ActionItemDueDate date={action.due_date} updateDueDate={updateDueDate} />
      <ActionItemAssignee action={action} updateAssignees={updateAssignees} />
      <ActionItemDescription
        actionItemDescription={action.description}
        updateDescription={updateDescription}
      />
    </SideSheet.ContentContainer>
  );
};

const ActionItemStatus = ({
  action,
  status,
  updateStatus,
}: {
  action: Action;
  status: SimpleAction['status'];
  updateStatus: (status: SimpleAction['status']) => void;
}) => {
  const handleUpdateStatus = () => {
    const updatedStatus = status === 'open' ? 'done' : 'open';
    updateStatus(updatedStatus);
  };
  const closedBy =
    (action.marked_as_done_by?.first_name ?? '') +
    ' ' +
    (action.marked_as_done_by?.last_name ?? '');
  const closedDate = action.completed_at
    ? formatDateString({
        dateString: action.completed_at,
        displayCurrentYear: false,
      })
    : '';
  return (
    <SideSheet.ContentContainer
      className={cn(styles.actionItemPropertiesWrapper, styles.statusContainer)}
    >
      <div className={styles.propertiesTitleWrapper}>
        <CheckboxRound stroke="var(--color-secondary-40)" fill="none" />
        <TypographyPoppins
          type="body"
          bodySize="M"
          className={styles.propertyColor}
        >
          Status
        </TypographyPoppins>
      </div>
      <div className={styles.propertiesContentWrapper}>
        <Tooltip
          parameter={{
            description: `Closed by ${closedBy} on ${closedDate}`,
            type: 'plain',
            position: 'bottom',
          }}
          style={{
            position: 'fixed',
            minWidth: 'fit-content',
            display: `${
              status === 'open'
                ? 'none'
                : closedBy && closedDate
                ? 'block'
                : 'none'
            }`,
          }}
        >
          <div className={styles.statusCheckBox}>
            <Checkbox
              icon={<Icon name="sprite/checkbox-filled" size={24} />}
              onClick={handleUpdateStatus}
              isSelected={status === 'done'}
              classNameOuterRadio={styles.checkboxOuterRadio}
            />
          </div>
          <TypographyPoppins
            type="body"
            bodySize="M"
            className={styles.propertyColor}
          >
            {status === 'done' ? 'Closed' : 'Open'}
          </TypographyPoppins>
        </Tooltip>
      </div>
    </SideSheet.ContentContainer>
  );
};

const ActionItemDetailsPriority = ({
  priority,
  updatePriority,
}: {
  priority: PriorityType;
  updatePriority: (priority: PriorityType) => void;
}) => {
  return (
    <SideSheet.ContentContainer className={styles.actionItemPropertiesWrapper}>
      <div className={styles.propertiesTitleWrapper}>
        <Icon name="sprite/flag" color="var(--color-secondary-40)" size={24} />
        <TypographyPoppins
          type="body"
          bodySize="M"
          className={styles.propertyColor}
        >
          Priority
        </TypographyPoppins>
      </div>
      <div className={styles.propertiesContentWrapper}>
        <ActionItemPriority
          priority={priority}
          updatePriority={updatePriority}
          displayLabel={true}
          displaySetPriorityBtn={true}
          className={styles.actionItemPriority}
        />
      </div>
    </SideSheet.ContentContainer>
  );
};

const ActionItemDueDate = ({
  date,
  updateDueDate,
}: {
  date: string | null;
  updateDueDate: (due_date: string | null) => void;
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    date ? new Date(date) : null,
  );
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleOpenDatePicker = () => {
    setIsDatePickerOpen(true);
  };

  const handleCloseDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  const handleSelect = (date: Date | undefined) => {
    setSelectedDate(date === undefined ? null : date);
    handleCloseDatePicker();
    updateDueDate(date?.toISOString() || null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        handleCloseDatePicker();
      }
    };

    if (isDatePickerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDatePickerOpen]);

  return (
    <SideSheet.ContentContainer className={styles.actionItemPropertiesWrapper}>
      <div className={styles.propertiesTitleWrapper}>
        <Icon
          name="sprite/calendar-blank"
          color="var(--color-secondary-40)"
          size={24}
        />
        <TypographyPoppins
          type="body"
          bodySize="M"
          className={styles.propertyColor}
        >
          Due
        </TypographyPoppins>
      </div>
      <div
        className={styles.propertiesContentWrapper}
        onClick={handleOpenDatePicker}
      >
        {selectedDate ? (
          <TypographyPoppins
            type="body"
            bodySize="M"
            className={cn(styles.propertyColor, {
              [styles.dueDate]: isDateDue(selectedDate),
            })}
            style={{ cursor: 'pointer' }}
          >
            {formatDateString({
              dateObject: selectedDate,
              today: true,
              tomorrow: true,
              yesterday: true,
              displayCurrentYear: false,
            })}
          </TypographyPoppins>
        ) : (
          <Button
            type="text"
            style={{ color: 'var(--color-secondary-80)' }}
            label="Set due date"
            onClick={() => {}}
          />
        )}
      </div>
      {isDatePickerOpen && (
        <div className={styles.datepickerWrapper} ref={datePickerRef}>
          <DatePicker
            selectedDate={selectedDate ? selectedDate : undefined}
            onDateSelect={handleSelect}
            captionLayout="dropdown"
            fromYear={2020}
          />
        </div>
      )}
    </SideSheet.ContentContainer>
  );
};

const ActionItemAssignee = ({
  action,
  updateAssignees,
}: {
  action: Action;
  updateAssignees?: (assigned_to: User[]) => void;
}) => {
  const assignee = action.assigned_to;

  const { mutateAction } = useMutateAction(action.id);

  return (
    <div
      className={cn(
        styles.actionItemPropertiesWrapper,
        styles.assigneesContainer,
      )}
    >
      <div
        className={cn(
          styles.propertiesTitleWrapper,
          styles.assigneeTitleWrapper,
        )}
      >
        <Icon
          name="sprite/person"
          color="var(--color-secondary-40)"
          size={24}
        />
        <TypographyPoppins
          type="body"
          bodySize="M"
          className={styles.propertyColor}
        >
          Assignee
        </TypographyPoppins>
      </div>
      <div
        className={cn(
          styles.propertiesContentWrapper,
          styles.assigneeAvatarWrapper,
        )}
      >
        <AssigneeSearch action={action} updateAssignees={updateAssignees}>
          {assignee && assignee.length > 0 ? (
            assignee.map((user: User) => (
              <button
                onClick={() => {}}
                className={styles.avatarButton}
                key={user.id}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      action.assigned_to !== null &&
                      action.assigned_to.some((u) => u.id === user.id)
                    ) {
                      // Remove assignee
                      if (updateAssignees) {
                        updateAssignees(
                          action.assigned_to.filter((u) => u.id !== user.id),
                        );
                      } else {
                        mutateAction.mutate({
                          assigned_to: action.assigned_to
                            .filter((u) => u.id !== user.id)
                            .map((u) => u.id),
                        });
                      }
                    } else {
                      // Add assignee
                      const assignedTo = action.assigned_to ?? [];
                      if (updateAssignees) {
                        updateAssignees([...assignedTo, user]);
                      } else {
                        mutateAction.mutate({
                          assigned_to: [
                            ...assignedTo.map((u) => u.id),
                            user.id,
                          ],
                        });
                      }
                    }
                  }}
                  className={styles.avatarCancel}
                >
                  <Icon name="sprite/x" size={12} />
                </button>
                <Avatar
                  size={40}
                  image={user.photo?.full_size}
                  initials={`
                    ${user.first_name ?? undefined} 
                    ${user.last_name ?? undefined}
                  `}
                />
              </button>
            ))
          ) : (
            <Button
              type="text"
              style={{ color: 'var(--color-secondary-80)' }}
              label="Assign to someone"
              onClick={() => {}}
              isDisabled={action.id ? false : true}
            />
          )}
        </AssigneeSearch>
      </div>
    </div>
  );
};

const ActionItemDescription = ({
  actionItemDescription,
  updateDescription,
}: {
  actionItemDescription: string;
  updateDescription: (description: string) => void;
}) => {
  const [description, setDescription] = useState<string | null>(
    actionItemDescription,
  );
  const [isEditing, setIsEditing] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);

  const handleAddDetailsClick = () => {
    setDescription('');
    setIsEditing(true);
  };

  const handleUpdateDescription = () => {
    const updatedDescription =
      descriptionRef.current?.textContent?.trim() || '';
    if (updatedDescription === '') {
      setDescription(null);
    }
    updateDescription(updatedDescription);
  };

  useEffect(() => {
    if (isEditing && descriptionRef.current) {
      descriptionRef.current.focus();
      setIsEditing(false);
    }
  }, [description]);

  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    const element = e.currentTarget;
    const currentText = element.textContent || '';
    if (currentText.length > 500) {
      e.preventDefault();
      element.textContent = currentText.substring(0, 500);
      const selection = window.getSelection();
      const range = document.createRange();
      range.setStart(element.childNodes[0], 500);
      range.collapse(true);
      selection?.removeAllRanges();
      selection?.addRange(range);
      startedSnack({
        label: `Try writing less. There’s a limit of 500 characters`,
        closeAction: true,
      });
    }
  };

  return (
    <SideSheet.ContentContainer
      className={cn({
        [styles.actionItemPropertiesWrapper]: description === null,
        [styles.descriptionContainer]: description !== null,
      })}
    >
      <div className={styles.propertiesTitleWrapper}>
        <Icon
          name="sprite/information"
          color="var(--color-secondary-40)"
          size={24}
        />
        <TypographyPoppins
          type="body"
          bodySize="M"
          className={styles.propertyColor}
        >
          Description
        </TypographyPoppins>
      </div>
      <div
        className={cn({
          [styles.propertiesContentWrapper]: description === null,
          [styles.descriptionWrapper]: description !== null,
        })}
      >
        {description !== null ? (
          <TypographyPoppins
            type="body"
            bodySize="S"
            className={styles.propertyColor}
            contentEditable={true}
            suppressContentEditableWarning
            ref={descriptionRef}
            onInput={handleInput}
            onBlur={handleUpdateDescription}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleUpdateDescription();
              }
            }}
          >
            {description}
          </TypographyPoppins>
        ) : (
          <Button
            type="text"
            style={{ color: 'var(--color-secondary-80)' }}
            label="Add details"
            onClick={handleAddDetailsClick}
          />
        )}
      </div>
    </SideSheet.ContentContainer>
  );
};
