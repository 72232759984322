import {
  ComponentProps,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Dropdown } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { FONT_FAMILIES, FontFamily } from '@src/shared/types';
import { Editor } from '@tiptap/react';

import { useMouseEnterLeave } from './useMouseEnterLeave';

import styles from './styles.module.css';

const leadIcon = (isSelected: boolean) => (
  <Icon name="sprite/tick" opacity={isSelected ? 1 : 0} />
);

export const FontDropdown = (props: {
  selectedFontFamily: FontFamily;
  showDropdown: boolean;
  setShowDropdown: Dispatch<SetStateAction<boolean>>;
  editor: Editor;
  hideTick: boolean;
}) => {
  const {
    showDropdown,
    setShowDropdown,
    // Selected font family is always the current font applied to the selected node. so if a new font is applied i.e. when previewing this changes to that font. So it always reflects the current font on the selection
    selectedFontFamily,
    editor,
    hideTick,
  } = props;

  const [currentSelectedFont, setCurrentSelectedFont] =
    useState(selectedFontFamily);
  const selectionHasMultipleFontsRef = useRef(false);

  const shouldShowPreview =
    editor.state.selection.from !== editor.state.selection.to;

  const menuItems = FONT_FAMILIES.map((fontFamily) => ({
    leadingIcon: leadIcon(fontFamily === currentSelectedFont && !hideTick),
    content: fontFamily,
    onClick: () => {
      setCurrentSelectedFont(fontFamily);
      editor.chain().setFontFamily(fontFamily).focus().run();
      editor.commands.focus();
    },
  }));

  const selectionHasMultipleFonts = useMemo(() => {
    const fonts: Set<FontFamily> = new Set();
    editor.state.doc.nodesBetween(
      editor.state.selection.from,
      editor.state.selection.to,
      (node) => {
        node.marks.forEach((mark) => {
          if (mark.attrs.fontFamily) fonts.add(mark.attrs.fontFamily);
        });
        return true;
      },
    );

    return fonts.size > 1;
  }, [editor.state.selection]);

  useEffect(() => {
    if (showDropdown) {
      // When it opens
      setCurrentSelectedFont(selectedFontFamily);
      selectionHasMultipleFontsRef.current = selectionHasMultipleFonts;
    }
  }, [showDropdown]);

  return (
    <Dropdown open={showDropdown} onOpenChange={setShowDropdown}>
      <Dropdown.Menu
        side="bottom"
        sideOffset={24}
        align="center"
        density="-2"
        className={styles.dropdownContainer}
      >
        {menuItems.map((item, index) => (
          <DropdownItem
            key={item.content}
            item={item}
            textStyle={{
              fontFamily: item.content,
            }}
            onMouseEnter={() => {
              if (!shouldShowPreview) return;

              if (
                selectionHasMultipleFontsRef.current ||
                item.content !== selectedFontFamily
              ) {
                editor.chain().setFontFamily(item.content).focus().run();
              }
            }}
            onMouseLeave={() => {
              if (!shouldShowPreview) return;

              if (
                selectionHasMultipleFontsRef.current ||
                currentSelectedFont !== selectedFontFamily
              ) {
                editor.commands.undo();
              }
            }}
            index={index}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const DropdownItem = (props: {
  item: ComponentProps<typeof Dropdown.MenuItem>['item'];
  textStyle: React.CSSProperties;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  index: number;
}) => {
  const { item, textStyle, onMouseEnter, onMouseLeave, index } = props;

  const {
    elementRef,
    onMouseEnter: onMouseEntered,
    onMouseLeave: onMouseLeft,
  } = useMouseEnterLeave(onMouseEnter, onMouseLeave);

  return (
    <Dropdown.MenuItem
      ref={elementRef}
      key={index}
      item={item}
      textStyle={textStyle}
      onMouseEnter={onMouseEntered}
      onMouseLeave={onMouseLeft}
    />
  );
};
