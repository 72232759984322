import { createEvent, createStore } from 'effector';

export const viewThumbnailsSheetOpened = createEvent();
export const viewThumbnailsSheetClosed = createEvent();

export const $viewThumbnailsSheetOpen = createStore(false);

$viewThumbnailsSheetOpen
  .on(viewThumbnailsSheetOpened, (open) => !open)
  .reset(viewThumbnailsSheetClosed);
