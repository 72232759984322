import { createEvent, createStore } from 'effector';

import { deleteBoardModalClosed } from '@src/entities/delete-modals/modal';

export const shareSideSheetOpened = createEvent();
export const boardIdCleaned = createEvent();

export const $isShareSheetOpen = createStore(false);

export const boardIdPassed = createEvent<string>();
export const shareSideSheetClosed = createEvent();

// TODO:
export const $boardId = createStore('');

$boardId
  .on(boardIdPassed, (_, id) => id)
  .reset([boardIdCleaned, deleteBoardModalClosed]);

$isShareSheetOpen
  .on(shareSideSheetOpened, (state) => !state)
  .reset(shareSideSheetClosed);
