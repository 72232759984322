import cn from 'classnames';
import { useUnit } from 'effector-react';
import { AnimatePresence, motion, Variants } from 'framer-motion';

import { Icon } from '@visualist/icons';

import { Button } from '../Buttons';
import { IconButton } from '../IconButtons';
import TypographyPoppins from '../Styles/Typography/TypographyPoppins';
import { useSnack } from './hook';
import { $snack, closedSnack, SnackState } from './model';
import { SnackError } from './types';

import styles from './styles.module.css';

const variants: Variants = {
  hidden: {
    y: 100,
    transition: {
      type: 'tween',
      duration: 0.4,
      ease: [0.05, 0.7, 0.1, 1],
    },
  },
  visible: {
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.2,
      ease: [0.3, 0, 0.8, 0.15],
    },
  },
};

const largeSnackbar: Variants = {
  hidden: {
    y: 164,
    transition: {
      type: 'tween',
      duration: 0.4,
      ease: [0.05, 0.7, 0.1, 1],
    },
  },
  visible: {
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.2,
      ease: [0.3, 0, 0.8, 0.15],
    },
  },
};

export const SnackBar = ({
  handleSnackError,
  isSharePage,
}: {
  handleSnackError?: (e: SnackError, s?: string) => void;
  isSharePage?: boolean;
}) => {
  useSnack({ handleSnackError, isSharePage });

  const snackState = useUnit($snack);

  return (
    <div className={styles.fixedContainer}>
      <div className={styles.relativeContainer}>
        <AnimatePresence>
          {snackState !== null ? (
            <div
              key="snack"
              className={cn(styles.container, {
                [styles.largeSnackbar]: snackState.type === 'longer action',
              })}
            >
              <motion.div
                key="snack"
                variants={
                  snackState.type === 'longer action' ? largeSnackbar : variants
                }
                initial="hidden"
                animate="visible"
                exit="hidden"
              >
                <Snack snackState={snackState} />
              </motion.div>
            </div>
          ) : null}
        </AnimatePresence>
      </div>
    </div>
  );
};

const Snack = ({ snackState }: { snackState: SnackState }) => {
  const action = () => {
    closedSnack();
    snackState.action?.action();
  };

  const close = () => {
    closedSnack();
  };

  return (
    <div
      className={cn(styles.snackBar, {
        [styles.longerAction]: snackState.type === 'longer action',
      })}
    >
      <TypographyPoppins
        className={cn(styles.label, {
          [styles.labelwithlongerAction]: snackState.type === 'longer action',
        })}
        type="label"
        labelSize="M"
      >
        {snackState.label}
      </TypographyPoppins>
      <div
        className={cn(styles.actions, {
          [styles.longerActions]: snackState.type === 'longer action',
        })}
      >
        {snackState.action && (
          <Button
            className={cn(styles.action, {
              [styles.longer]: snackState.type === 'longer action',
            })}
            labelSize="M"
            type="inverse-primary"
            label={snackState.action.label}
            onClick={action}
          />
        )}
        {snackState.close && (
          <IconButton
            className={cn(styles.actionClose, {
              [styles.longer]: snackState.type === 'longer action',
            })}
            iconStyles={styles.close}
            type="unfilled"
            icon={<Icon name="sprite/x" />}
            onClick={close}
          />
        )}
      </div>
    </div>
  );
};
