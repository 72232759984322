import { useHistory, useParams } from 'react-router-dom';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { createColorPaletteAsCopy } from '@api/blocks';
import { ColorSwatch } from '@api/index';
import { fileCardIdSet } from '@pages/FileCard/model';
import {
  allBoardImagesKeys,
  libraryKeys,
} from '@src/shared/constants/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useCreatePaletteCopy = () => {
  const history = useHistory();
  const { board_id } = useParams<{ board_id: string }>();
  const queryClient = useQueryClient();

  const { mutate: createPaletteCopy } = useMutation({
    mutationFn: (payload: {
      id: string;
      swatches: ColorSwatch[];
      board?: string;
    }) => {
      if (board_id) {
        payload.board = board_id;
      }

      return createColorPaletteAsCopy(payload);
    },
    onMutate: () => {
      startedSnack({
        label: 'Creating palette...',
        close: true,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: libraryKeys.all,
      });

      if (board_id) {
        queryClient.invalidateQueries({
          queryKey: allBoardImagesKeys.all,
        });
      }

      startedSnack({
        label: 'Created new palette',
        action: {
          label: 'Go to file',
          action: () => {
            history.push(`#/block/${data.data.id}`);
            // TODO
            fileCardIdSet(data.data.id);
          },
        },
        close: true,
      });
    },
    onError: (_, payload) => {
      if (board_id) {
        payload.board = board_id;
      }

      startedSnack({
        label: "Couldn't create palette",
        action: {
          label: 'Try again',
          action: () => {
            createColorPaletteAsCopy(payload);
          },
        },
        close: true,
      });
    },
  });

  return { createPaletteCopy };
};
