import { Stage } from 'konva/lib/Stage';

import {
  CARD_SIZE,
  EDITING_CARD_SIZE,
  PADDING_SIZE,
} from '../../pages/FileCard/Stickies/constants';
import { StickyType } from './types';

export const sortByCreatedAt = (s1: StickyType, s2: StickyType) => {
  const s1Date = new Date(s1.created_at);
  const s2Date = new Date(s2.created_at);
  if (s1Date < s2Date) return -1;
  if (s1Date > s2Date) return 1;
  return 0;
};

export const sortByUpdatedAt = (s1: StickyType, s2: StickyType) => {
  const s1Date = new Date(s1.updated_at);
  const s2Date = new Date(s2.updated_at);
  if (s1Date < s2Date) return -1;
  if (s1Date > s2Date) return 1;
  return 0;
};

export const chooseColour = (index: number) => {
  switch (index % 3) {
    case 0:
      return '#FFE2B5';
    case 1:
      return '#E2C9EB';
    case 2:
      return '#B3D4C1';
  }
  return '#FFE2B5';
};

/**
 * @name computeStickyPositionToRatio
 * @description Takes a position of a sticky and returns a ratio of the position given the max and min bounds of elements on the canvas.
 * @param minX
 * @param minY
 * @param maxX
 * @param maxY
 * @param x
 * @param y
 */

export const computeStickyPositionToRatio = ({
  minX,
  minY,
  maxX,
  maxY,
  x,
  y,
}: {
  x: number;
  y: number;
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}) => {
  // Get a value between 0 and 1 for the relative position of the sticky given the max and min bounds of elements on the canvas.
  // Note if sticky is not in bounds the values can be less than 0 and greater than 1
  const height = maxY - minY;
  const width = maxX - minX;

  const ratioX = (x - minX) / width;
  const ratioY = (y - minY) / height;

  return {
    ratioX,
    ratioY,
  };
};

/**
 * @name computeStickPositionFromRatio
 * @description Take a ratio and return the position of the sticky given the max and min bounds of elements on the canvas.
 * @param ratioX
 * @param ratioY
 * @param minX
 * @param maxX
 * @param minY
 * @param maxY
 */

export const computeStickPositionFromRatio = ({
  ratioX,
  ratioY,
  minX,
  maxX,
  minY,
  maxY,
}: {
  ratioX: number;
  ratioY: number;
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}) => {
  // Take a ratio and return the position of the sticky given the max and min bounds of elements on the canvas.
  const height = maxY - minY;
  const width = maxX - minX;

  const x = ratioX * width + minX;
  const y = ratioY * height + minY;

  return {
    x,
    y,
  };
};

/**
 * @name isStickyOutOfBounds
 * @description Check if the sticky is out of bounds of the container
 * @param {StickyType} sticky
 */

export const isStickyOutOfBounds = (sticky: StickyType) => {
  if (sticky.left_pixel < -0.1 || sticky.top_pixel < -0.1) {
    return false;
  }

  if (sticky.left_pixel > 1 || sticky.top_pixel > 1) {
    // TODO this instead of a fixed value of 0.9 should be calculated based on the size of the image. Basically the ratio of the image size to the sticky size.
    return false;
  }

  return true;
};

/**
 * @name checkCollisions
 * @description Check if the sticky is colliding with the edges of the container and return the new position of the sticky to be within this container.
 * @param x
 * @param y
 * @param top
 * @param left
 * @param right
 * @param bottom
 */

export const checkCollisions = ({
  x,
  y,
  top,
  left,
  right,
  bottom,
}: {
  x: number;
  y: number;
  top: number;
  right: number;
  left: number;
  bottom: number;
}) => {
  let newX = x;
  let newY = y;
  // Check each side to move sticky to correct position
  if (x < left) newX = left;
  // Check if colliding with right
  if (x + CARD_SIZE > right) newX = right - CARD_SIZE - PADDING_SIZE / 2;
  // Check if colliding with top
  if (y < top) newY = top;
  // Check if colliding with bottom
  if (y + CARD_SIZE > bottom) newY = bottom - CARD_SIZE - PADDING_SIZE / 2;

  return { cleanedX: newX, cleanedY: newY };
};

/**
 * @description
 * This function is used to check if a sticky should be attached to an image or a textbox
 */

export const checkIfAttached = (props: {
  point: { x: number; y: number };
  stageRef: React.RefObject<Stage>;
}) => {
  const stage = props.stageRef.current;
  if (!stage) return undefined;

  const shapes = stage.getAllIntersections(props.point);

  const filteredShapes = shapes.filter((s) => {
    return s.attrs.name === 'Image' || s.attrs.name === 'Text';
  });

  const topmostShape = filteredShapes[filteredShapes.length - 1];

  const id = topmostShape?.id();
  let type = undefined;
  switch (topmostShape?.attrs.name) {
    case 'Image':
      type = 'file';
      break;
    case 'Text':
      type = 'text-box';
      break;
    default:
      type = undefined;
  }

  if (!id || !type) return undefined;

  return {
    id,
    type,
  };
};

export const calculateTextHeight = (content: string) => {
  // Create a hidden textarea to calculate the height of the text
  // Not the most performant way to calculate text height
  const parentDiv = document.createElement('div');
  const paragraph = document.createElement('p');
  parentDiv.appendChild(paragraph);
  parentDiv.style.visibility = 'hidden';
  parentDiv.style.position = 'absolute';
  parentDiv.style.top = '0px';
  parentDiv.style.left = '0px';
  parentDiv.style.width = '308px';
  parentDiv.style.height = '142px';
  parentDiv.style.left = '0px';
  parentDiv.style.overflow = 'hidden';

  document.body.appendChild(parentDiv);
  paragraph.id = 'sticky-textarea-sizing';
  paragraph.textContent = content + '  ';
  paragraph.style.fontFamily = 'Poppins';
  paragraph.style.fontSize = '12px';
  paragraph.style.letterSpacing = '0.048px';
  paragraph.style.width = '100%';
  paragraph.style.height = 'auto';
  paragraph.style.resize = 'none';
  paragraph.style.padding = '4px';
  paragraph.style.border = 'none';
  paragraph.style.outline = 'none';
  paragraph.style.maxHeight = '100%';
  paragraph.style.whiteSpace = 'pre-wrap';

  const height = paragraph.getBoundingClientRect().height;
  document.body.removeChild(parentDiv);
  // 42px is the height of the header and footer to add
  return Math.max(height + 20 + 8 + 4 + 4, EDITING_CARD_SIZE);
};
