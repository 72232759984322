import { forwardRef, PropsWithChildren } from 'react';

import cn from 'classnames';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import styles from './styles.module.css';

const Card = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn(styles.card, className)} {...props} />
  ),
);
Card.displayName = 'Card';

const CardHeader = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    onClose?: () => void;
  }
>(({ className, onClose, ...props }, ref) => (
  <div ref={ref} className={cn(styles.cardHeader, className)} {...props}>
    {onClose ? (
      <button onClick={onClose} className={styles.x}>
        <Icon name="sprite/x" />
      </button>
    ) : null}
    {props.children}
  </div>
));
CardHeader.displayName = 'CardHeader';

const CardContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn(styles.cardContent, className)} {...props} />
));
CardHeader.displayName = 'cardContent';

const CardButtons = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn(styles.cardButtons, className)} {...props} />
));
CardHeader.displayName = 'CardButtons';

const CardHeaderText = ({ children }: PropsWithChildren) => {
  return (
    <TypographyPoppins type="title" titleSize="S" className={styles.textHeader}>
      {children}
    </TypographyPoppins>
  );
};
CardHeaderText.displayName = 'CardHeaderText';

const CardText = ({ children }: PropsWithChildren) => {
  return (
    <TypographyPoppins type="body" bodySize="M" className={styles.text}>
      {children}
    </TypographyPoppins>
  );
};
CardText.displayName = 'CardText';

export { Card, CardHeader, CardContent, CardButtons, CardHeaderText, CardText };
