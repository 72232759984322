import { ChangeEvent, RefObject } from 'react';

import { Icon } from '@visualist/icons';

import {
  searchBoardsModalWindowClosed,
  searchQueryFinished,
} from '@pages/FileCard/model';

import styles from './styles.module.css';

interface SearhcFieldProps {
  inputRef: RefObject<HTMLInputElement>;
  query: string;
  emptyList: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchField = ({
  inputRef,
  onChange,
  query,
  emptyList,
}: SearhcFieldProps) => (
  <>
    <div className={styles.searchField}>
      <button
        className={styles.closeModal}
        onClick={() => searchBoardsModalWindowClosed()}
      >
        <Icon name="sprite/chevron-left" className={styles.caretIcon} />
      </button>
      <input
        className={styles.input}
        ref={inputRef}
        placeholder="Search for a board"
        value={query}
        onChange={onChange}
      />
      <button
        className={styles.searchReset}
        onClick={() => searchQueryFinished()}
      >
        {query && <Icon name="sprite/x" className={styles.closeIcon} />}
      </button>
    </div>
    {emptyList && <div className={styles.emptyList} />}
  </>
);
