import { api } from './services';

export const createBoardShare = async (
  boardId: string,
  allow_comments: boolean,
  nested_board: string[] = [],
) => {
  const { data } = await api.post('/share/', {
    board: boardId,
    nested_board,
    allow_comments,
  });

  return data;
};

export const deleteShare = async (shareId: string) =>
  await api.delete(`/share/${shareId}/`);

export const getShare = async (shareId: string) => {
  const { data } = await api.get(`/share/${shareId}/`);
  return data;
};
