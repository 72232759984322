import { addTagToBlock } from '@api/blocks';
import { BLOCKS_TAGS_QUERY } from '@src/shared/constants';
import { useMutation, useQuery } from '@tanstack/react-query';

import { fetchTags } from './api';

export const useTags = () => {
  const query = useQuery({
    queryKey: [BLOCKS_TAGS_QUERY],
    queryFn: () => fetchTags(),
  });

  const mutation = useMutation({
    mutationFn: ({
      blockId,
      tag,
      isTag,
    }: {
      blockId: string;
      tag: string;
      isTag: boolean;
    }) => addTagToBlock(blockId, { name: tag, label: isTag }),
    // onSuccess: () => query.refetch(),
  });

  return {
    query,
    mutation,
  };
};
