import { AccountNavigation } from './components/navigation';
import { AccountRouter } from './components/router';

import styles from './style.module.css';

export const AccountPage = () => {
  return (
    <div className={styles.container}>
      <AccountNavigation />
      <div className={styles.routerContainer}>
        <AccountRouter />
      </div>
    </div>
  );
};
