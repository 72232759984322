import { useUnit } from 'effector-react';

import { saveOnboarding } from '@api/account';
import {
  closedVaiTidyupModal,
  openedVaiTidyupModal,
} from '@pages/StudioPage/model';
import { useAppData } from '@src/AppContext';
import {
  $showVaiTagPopup,
  closedVaiTagPopup,
  openedVaiTagPopup,
} from '@src/entities/popups/ui/VaiTagPopup/model';
import { InitalOnboardingState } from '@src/widgets/onboarding/useUserOnboarding';
import { useMutation } from '@tanstack/react-query';

import styles from './styles.module.css';

export const Debug = () => {
  const params = new URLSearchParams(window.location.search);
  const { user } = useAppData();

  const showDebug = params.get('debug') === 'true';

  const [showVaiTagPopup] = useUnit([$showVaiTagPopup]);

  const mutateOnboarding = useMutation({
    mutationFn: saveOnboarding,
  });

  if (!showDebug) return null;

  return (
    <div className={styles.container}>
      <div className={styles.option}>
        <label>
          <input
            id="tag"
            type="checkbox"
            checked={showVaiTagPopup}
            onChange={(e) => {
              if (e.target.checked) {
                openedVaiTagPopup();
              } else {
                closedVaiTagPopup();
              }
            }}
          />
          Vai Tag Popup
        </label>
      </div>
      <div className={styles.option}>
        <label>
          <input
            id="tag"
            type="checkbox"
            checked={showVaiTagPopup}
            onChange={(e) => {
              if (e.target.checked) {
                openedVaiTidyupModal();
              } else {
                closedVaiTidyupModal();
              }
            }}
          />
          Vai Tidy up Popup
        </label>
      </div>
      <div className={styles.option}>
        <button
          onClick={() =>
            mutateOnboarding.mutate({
              meta: {
                ...user.meta,
                onboarding: InitalOnboardingState,
              },
            })
          }
        >
          Reset onboarding state
        </button>
      </div>
    </div>
  );
};
