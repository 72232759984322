import { getActionAssignees } from '@api/actions';
import { User } from '@src/AppContext';
import { actionAssignees } from '@src/shared/constants/query-keys';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export const useAssignee = (
  actionId: string,
  queryOptions?: Omit<UseQueryOptions<User[]>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery({
    queryKey: actionAssignees.action(actionId),
    queryFn: () => getActionAssignees(actionId),
    ...queryOptions,
  });
};
