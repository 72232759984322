import cn from 'classnames';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

type Props = {
  title: string;
  description?: string;
  className?: string;
};

export const Title = ({ title, description, className }: Props) => {
  return (
    <div className={cn(styles.header, className)}>
      <TypographyPoppins
        type="headline"
        headlineSize="S"
        className={styles.title}
      >
        {title}
      </TypographyPoppins>
      <TypographyPoppins
        type="body"
        bodySize="S"
        className={styles.description}
      >
        {description}
      </TypographyPoppins>
    </div>
  );
};
