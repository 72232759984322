import { useQuery } from '@tanstack/react-query';

import {
  BlockResponse,
  getBlock,
  RawBlockResponse,
} from '../../../../api/services';
import { IMAGE_QUERY } from '../../../../shared/constants';

export const useBlock = (id: string) => {
  return useQuery({
    queryKey: [IMAGE_QUERY, { id }],
    queryFn: () => getBlock(id),
    select: transformData,
    enabled: !!id,
  });
};

const transformData = (data: RawBlockResponse) => {
  const transformedData = {
    ...data,
    custom_palettes: data.custom_palettes.map((palette) => ({
      ...palette,
      type: 'custom_palette',
    })),
  } satisfies BlockResponse;

  return transformedData;
};
