import { getDoc, ResponseDoc } from '@api/docs';
import { DOC_QUERY } from '@src/shared/constants';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export function useGetDoc({
  doc_id,
  options,
}: {
  doc_id: string;
  options?: Omit<
    UseQueryOptions<ResponseDoc, Error, ResponseDoc>,
    'queryKey' | 'queryFn' | 'select'
  >;
}) {
  const query = useQuery({
    queryKey: [DOC_QUERY, { docId: doc_id }],
    queryFn: () => getDoc({ docId: doc_id }),
    select: (data: ResponseDoc) => ({
      ...data,
      height: data.height || 11,
      width: data.width || 8.5,
    }),
    ...options,
  });

  return query;
}
