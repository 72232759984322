import { searchExactMatch, SearchType } from '@api/search';
import { searchKeys } from '@src/shared/constants/query-keys';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

type SearchProps = {
  query: string;
  type?: SearchType[];
  mutationOptions?: Omit<
    UseQueryOptions<Awaited<ReturnType<typeof searchExactMatch>>>,
    'queryKey' | 'queryFn' | 'enabled'
  >;
};

export const useSearch = (props: SearchProps) => {
  const { query, type = [] } = props;

  const searchQuery = useQuery({
    queryFn: () => searchExactMatch({ query, type }),
    queryKey: searchKeys.exactMatch(query, type),
    enabled: !!query,
    ...props.mutationOptions,
  });

  return {
    searchQuery,
  };
};
