import { ImageBlock } from '@pages/StudioPage/api';

import { ImageFile } from '../imageFile';

export const centralLayout = ({
  heroImage,
  images,
  center,
}: {
  heroImage: ImageBlock;
  images: ImageBlock[];
  center: {
    x: number;
    y: number;
  };
  transparentImages: Set<string>;
  paletteImages: Set<string>;
}) => {
  // TODO
  const hero = new ImageFile(heroImage);

  hero.resetRotation().resizeImageAspect(4000).centerImageFromPoint({
    x: center.x,
    y: center.y,
  });

  return images;
};
