import React, { ReactNode } from 'react';

import cn from 'classnames';
import { isTablet } from 'react-device-detect';

import styles from './styles.module.css';

interface ContainerProps {
  children: ReactNode;
}

export const Container = ({ children }: ContainerProps) => {
  return (
    <div
      className={cn(styles.container, {
        [styles.tablet]: isTablet,
      })}
    >
      {children}
    </div>
  );
};
