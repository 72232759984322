import { Icon, IconProps } from './Icon';

export const DesignColored = (props: IconProps) => {
  return (
    <Icon {...props}>
      <mask
        id="mask0_16097_1009"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="4"
        width="20"
        height="16"
      >
        <path
          d="M7.5 4H22L21.25 6H19.5L17.7857 12H19.2857L16.5 20H2L4.42 13.5H5.6L6.35294 11.5H4.85294L7.5 4Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_16097_1009)">
        <path
          d="M16.1263 19.5H2.70131L7.87372 4.5H14.7586H21.2987L16.1263 19.5Z"
          stroke="black"
        />
      </g>
      <path
        d="M21.6 7.5C21.6 7.16863 21.3314 6.9 21 6.9H14.4V10.5C14.4 10.8314 14.6686 11.1 15 11.1H21.6V7.5Z"
        fill="#FFE2B5"
        stroke="black"
        strokeWidth="0.8"
      />
      <path
        d="M8.6333 13.5L9.5 17L10.4757 13.5L14 12.5333L10.4757 11.5L9.5 8L8.6333 11.5L5 12.5L8.6333 13.5Z"
        fill="#E2C9EB"
        stroke="black"
        strokeWidth="0.8"
        strokeLinecap="square"
      />
    </Icon>
  );
};
