import { deleteFromDoc, RemoveInviteeDoc } from '@api/docs';
import { MutateOptions, useMutation } from '@tanstack/react-query';

import { queryClient } from '../../../../../queryClient';
import {
  MEMBERS_INVITATION_DOC_QUERY,
  MEMBERS_SUGGESTIONS_DOC_QUERY,
} from '../../../../../shared/constants/query-names';

export const useRemoveFromDoc = (
  options?: MutateOptions<RemoveInviteeDoc, any, RemoveInviteeDoc>,
) => {
  // const history = useHistory();

  const { mutate: removeInvitee } = useMutation({
    mutationFn: ({ id, members }: RemoveInviteeDoc) =>
      deleteFromDoc({ id, members }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_INVITATION_DOC_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_SUGGESTIONS_DOC_QUERY],
      });

      // if (variables.noBoardAccess) {
      //   history.push('/error');
      //   shareSideSheetClosed();
      // }

      // startedSnack({
      //   label: `Removed them from ${variables.boardName}`,
      //   close: true,
      // });

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    // onError: (error, variables, context) => {
    //   startedSnack({
    //     label: `Couldn't remove them from ${variables.boardName}`,
    //     action: {
    //       label: 'Try again',
    //       action: () => {
    //         removeInvitee({
    //           boardId: variables.boardId,
    //           members: variables.members,
    //           email: variables.email,
    //           boardName: variables.boardName,
    //         });
    //       },
    //     },
    //     close: true,
    //   });

    //   if (options?.onError) options?.onError(error, variables, context);
    // },
  });

  return { removeInvitee };
};
