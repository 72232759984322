import { Icon, IconProps } from './Icon';

export const StarPointyVaiDiagonal = (props: IconProps) => {
  return (
    <Icon fill="none" {...props}>
      <path
        d="M10.2222 10.4592L4 12L10.2222 13.7346L11.9407 20L13.7777 13.7346L20 12L13.7777 10.4592L11.9999 4L10.2222 10.4592Z"
        stroke="url(#paint0_linear_1954_2223)"
        strokeLinecap="square"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1954_2223"
          x1="20"
          y1="4"
          x2="1.43365"
          y2="15.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#630682" />
          <stop offset="0.5" stopColor="#8B39A8" />
          <stop offset="1" stopColor="#FAD7FF" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
