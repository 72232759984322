import { useRef } from 'react';

import {
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Plan } from '@pages/AccountPage/components/types';

import { Thumbnail } from '../Thumbnail';

import styles from './styles.module.css';

interface HubPros {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  thumbnail_hex: string;
  isLocked: boolean;
  location?: string;
  onboardingId?: string;
  index: number;
  isShowButton: any;
  plan: Plan;
}

export const Hub = ({
  id,
  name,
  description,
  thumbnail,
  thumbnail_hex,
  isLocked,
  location,
  onboardingId,
  index,
  isShowButton,
  plan,
}: HubPros) => {
  const hubRef = useRef<HTMLLIElement>(null);

  return (
    <li id={onboardingId} ref={hubRef} className={styles.hub}>
      <Thumbnail
        thumbnail={thumbnail}
        thumbnail_hex={thumbnail_hex}
        id={id}
        name={name}
        description={description}
        location={location}
        index={index}
        isShowButton={isShowButton}
        plan={plan}
      />
      <LockBanner isLocked={isLocked} />
    </li>
  );
};

const LockBanner = ({ isLocked }: { isLocked: boolean }) => {
  if (!isLocked) return null;

  return (
    <div className={styles.lockContainer}>
      <Tooltip
        parameter={{
          type: 'plain',
          description: 'Payment outstanding',
          hasVisualBoundary: true,
          position: 'top',
          centerText: true,
        }}
      >
        <div className={styles.ribbon}>
          <Icon name="sprite/dollar-sign" />
          <TypographyPoppins type="label" labelSize="M">
            Pending
          </TypographyPoppins>
        </div>
      </Tooltip>
    </div>
  );
};
