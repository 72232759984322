import { MouseEvent } from 'react';

import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import { Plan } from '@pages/AccountPage/components/types';

import { CreateHub } from '../CreateHub';
import { Details } from '../Details';

import styles from './styles.module.css';

interface ThumbnailProps {
  thumbnail: string;
  thumbnail_hex: string;
  id: string;
  name: string;
  description: string;
  location?: string;
  index: number;
  isShowButton: any;
  plan: Plan;
}

export const Thumbnail = ({
  thumbnail,
  thumbnail_hex,
  id,
  name,
  description,
  location,
  index,
  isShowButton,
  plan,
}: ThumbnailProps) => {
  const history = useHistory();

  const goToHub = (id: string) => history.push(`/h/${id}`);

  const onItemClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (e.ctrlKey || e.metaKey) {
      window.open(`${import.meta.env.VITE_BASE_URL}/h/${id}`, '_blank');
    } else {
      goToHub(id);
    }
  };
  return (
    <>
      {index === 0 && (
        <div
          className={cn(styles.header, {
            [styles.singleHub]: !isShowButton,
          })}
        >
          <TypographyPoppins
            type="headline"
            headlineSize="M"
            className={styles.title}
          >
            Hubs
          </TypographyPoppins>
          <CreateHub plan={plan} />
        </div>
      )}
      {thumbnail ? (
        <>
          <img
            className={styles.thumbnail}
            src={thumbnail}
            alt="thumbnail"
            onClick={onItemClick}
          />
          <Details
            name={name}
            description={description}
            id={id}
            location={location}
          />
        </>
      ) : (
        <>
          <div
            style={{ background: thumbnail_hex }}
            className={styles.background}
            onClick={onItemClick}
          />
          <Details
            name={name}
            description={description}
            id={id}
            location={location}
          />
        </>
      )}
    </>
  );
};
