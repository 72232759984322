import { GenericBanner } from '@components/Banner';
import { Plan } from '@pages/AccountPage/components/types';
import { useMembership } from '@src/shared/queries/useMembershipPermissions';

type Props = {
  plan: Plan;
};

export const HomeBanner = ({ plan }: Props) => {
  const { checkoutPro, isReady, billingQuery } = useMembership();

  if (!billingQuery.data || !isReady) return null;

  const numberOfHubs = billingQuery.data.hubs_billing.hubs_number;

  if (plan === 'free' || plan === 'starter')
    if (numberOfHubs === 0) {
      return (
        <GenericBanner
          label="Upgrade to Pro so you can create hubs for your projects."
          buttonText="Try Pro to create hubs"
          onClick={checkoutPro}
        />
      );
    } else {
      return (
        <GenericBanner
          label={`You have ${numberOfHubs} ${
            numberOfHubs > 1 ? 'hubs' : 'hub'
          }. Go back
				to Pro to unlock them.`}
          buttonText="Switch back to Pro"
          onClick={checkoutPro}
        />
      );
    }

  return null;
};
