import { useEffect } from 'react';

import { useLocalStorage } from './useLocalStorage';

export const useRecent = <T extends { id: string; name: string }>({
  key,
  item,
}: {
  key: string;
  item: T;
}) => {
  const [recentItems, setRecentItems] = useLocalStorage<Array<T>>(key, []);

  useEffect(() => {
    if (item.name === '') return;

    const newRecentItems = [...recentItems];

    const itemIndex = newRecentItems.findIndex(
      (newItem) => newItem.id === item.id,
    );

    if (itemIndex === -1) {
      // No item found, add it to the end
      setRecentItems([...newRecentItems.slice(0, 2), item]);
      return;
    }

    // Item found, move it to the top
    newRecentItems.splice(itemIndex, 1);
    setRecentItems([item, ...newRecentItems.slice(0, 2)]);
  }, [item.id, item.name]);

  return [recentItems, setRecentItems];
};
