import { createEvent, createStore } from 'effector';

export const enabledLoadingDemoMode = createEvent();
export const disabledLoadingDemoMode = createEvent();

export const $isTogglingDemoMode = createStore(false);

$isTogglingDemoMode
  .on(enabledLoadingDemoMode, () => true)
  .reset(disabledLoadingDemoMode);
