import { Icon, IconProps } from './Icon';

export const StudioIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path d="M4.5 15L5.4375 5H18.5625L19.5 15H4.5Z" stroke="currentColor" />
      <path d="M10.5 3.5H12H13.5" stroke="currentColor" strokeLinecap="round" />
      <path d="M10 15.5L8 21M14 15.5L16 21" stroke="currentColor" />
    </Icon>
  );
};
