import React from 'react';

import cx from 'classnames';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';

import styles from './styles.module.css';

export interface TextareaProps extends TextareaAutosizeProps {
  customClassName?: string;
  width: string | number;
}

type Ref = any;

const Textarea = React.forwardRef<Ref, TextareaProps>(
  ({ width, value, onChange, onKeyDown, onClick, customClassName }, ref) => {
    return (
      <div className={cx(styles.textareaWrapper)}>
        <TextareaAutosize
          ref={ref}
          style={{ width }}
          className={cx(customClassName, styles.text)}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onClick={onClick}
        />
      </div>
    );
  },
);

export default Textarea;
