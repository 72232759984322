import React from 'react';

import cn from 'classnames';
import { isMobile } from 'react-device-detect';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { capitalise } from '@pages/AccountPage/utils';
import { useMembership } from '@src/shared/queries/useMembershipPermissions';

import styles from './styles.module.css';

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// TODO refactor this since the logic is all over the place...

export const Banner = () => {
  const { checkoutPro, isReady, billingQuery } = useMembership();

  if (!billingQuery.data) return null;

  const numberOfHubs = billingQuery.data.hubs_billing.hubs_number;

  const currentPlan = billingQuery.data.membershipTierInfo.plan;
  const nextPlan = billingQuery.data.membershipTierInfo.nextPlan;
  const currentPlanCapitalised =
    capitalise(currentPlan) === 'Hobby' ? 'Starter' : capitalise(currentPlan);
  const nextPlanCapitalised =
    capitalise(nextPlan) === 'Hobby' ? 'Starter' : capitalise(nextPlan);
  const hasNextPlan = !!nextPlanCapitalised;

  const endDate = billingQuery.data.membershipTierInfo.planEndDate;

  if (
    billingQuery.data.membershipTierInfo.trialInfo.is_on_trial &&
    !hasNextPlan
  )
    return (
      <div className={cn(styles.container, { [styles.mobile]: isMobile })}>
        <TypographyPoppins type="body" bodySize="M">
          <span className={styles.text}>
            You&#39;re currently on a free trial of the Pro plan until{' '}
            {month[endDate.getMonth()]} {endDate.getDate()}. We&#39;ll start
            billing you for the Pro plan after.
          </span>
        </TypographyPoppins>
      </div>
    );

  if (billingQuery.data.membershipTierInfo.trialInfo.is_on_trial && hasNextPlan)
    return (
      <div className={cn(styles.container, { [styles.mobile]: isMobile })}>
        <TypographyPoppins type="body" bodySize="M">
          <span className={styles.text}>
            You&#39;re currently on a free trial of the Pro plan until{' '}
            {month[endDate.getMonth()]} {endDate.getDate()}. We&#39;ll switch
            you to the {nextPlanCapitalised} plan after.
          </span>
        </TypographyPoppins>
      </div>
    );

  if ((currentPlan === 'free' || currentPlan === 'starter') && !hasNextPlan)
    if (numberOfHubs === 0) {
      return (
        <div className={cn(styles.container, { [styles.mobile]: isMobile })}>
          <TypographyPoppins type="body" bodySize="M" className={styles.text}>
            Upgrade to Pro so you can create hubs for your projects.
          </TypographyPoppins>
          <Button
            type="ghost"
            onClick={checkoutPro}
            isDisabled={!isReady}
            label="Try Pro to create hubs"
          />
        </div>
      );
    } else {
      return (
        <div className={cn(styles.container, { [styles.mobile]: isMobile })}>
          <TypographyPoppins type="body" bodySize="M" className={styles.text}>
            You have {numberOfHubs} {numberOfHubs > 1 ? 'hubs' : 'hub'}. Go back
            to Pro to unlock them.
          </TypographyPoppins>
          <Button
            type="ghost"
            onClick={checkoutPro}
            isDisabled={!isReady}
            label="Switch back to Pro"
          />
        </div>
      );
    }

  if (!hasNextPlan) return null;

  return (
    <div className={cn(styles.container, { [styles.mobile]: isMobile })}>
      <TypographyPoppins type="body" size="M">
        <span className={styles.text}>
          You&#39;re currently on the {currentPlanCapitalised} plan until{' '}
          {month[endDate.getMonth()]} {endDate.getDate()}. We&#39;ll switch you
          to the {nextPlanCapitalised} plan after.
        </span>
      </TypographyPoppins>
    </div>
  );
};
