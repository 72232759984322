import { createEvent, createStore, sample } from 'effector';

export const elementsPanelToggled = createEvent();
export const elementPanelClosed = createEvent();
export const filesPanelToggled = createEvent();
export const filesPanelClosed = createEvent();

export const $isShowElementsPanel = createStore(false);
export const $isShowFilesPanel = createStore(false);

$isShowElementsPanel
  .on(elementsPanelToggled, (show) => !show)
  .reset(elementPanelClosed);

$isShowFilesPanel
  .on(filesPanelToggled, (show) => !show)
  .reset(filesPanelClosed);

sample({
  clock: $isShowElementsPanel,
  source: {
    isShowElementsPanel: $isShowElementsPanel,
  },
  filter: ({ isShowElementsPanel }) => isShowElementsPanel,
  target: filesPanelClosed,
});

sample({
  clock: $isShowFilesPanel,
  source: {
    isShowFilesPanel: $isShowFilesPanel,
  },
  filter: ({ isShowFilesPanel }) => isShowFilesPanel,
  target: elementPanelClosed,
});
