import { openedVaiTagPopup } from '@src/entities/popups/ui/VaiTagPopup/model';
import { openedVaiSearchHelpPopup } from '@src/entities/search/model';

type Thread =
  | {
      type: 'tagging_prompt';
      payload: null;
    }
  | {
      type: 'search_results_small';
      payload: null;
    }
  | {
      type: 'search_results_large';
      payload: null;
    };

export const handleEvent = (message: Thread) => {
  switch (message.type) {
    case 'tagging_prompt':
      openedVaiTagPopup();
      break;
    case 'search_results_small':
    case 'search_results_large':
      openedVaiSearchHelpPopup(message.type);
      break;
    default:
      return;
  }
};
