import React, { forwardRef, HTMLAttributes } from 'react';

import cn from 'classnames';

import style from './Button.module.css';

type ButtonVariant = 'round' | 'transparent' | 'square' | 'disabled';
type ButtonVersion = 'floating' | 'contained' | 'none';
export type ButtonSizeType = 'xs' | 's' | 'sm' | 'md' | 'lg' | 'xl';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  variant?: ButtonVariant;
  version?: ButtonVersion;
  size?: ButtonSizeType;
  active?: boolean;
  ref?: any;
  type?: any;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      active = false,
      disabled = false,
      children,
      variant = 'round',
      version = 'none',
      size = 'md',
      className,
      type,
      ...props
    },
    ref,
  ) => (
    <button
      {...props}
      ref={ref}
      type={type}
      disabled={disabled}
      className={cn(
        style.button,
        style[variant],
        style[version],
        style[`size-${size}`],
        { [style.active]: active },
        className,
      )}
    >
      {children}
    </button>
  ),
);

export default Button;
