// TODO: move this to future types package

// Generic types
export type Timeout = ReturnType<typeof setTimeout>;

// Font Types
export const FONT_FAMILIES = [
  'Alex Brush',
  'Arvo',
  'Caveat Variable',
  'Cormorant Garamond',
  'Lato',
  'Merriweather',
  'Monsieur La Doulaise',
  'Montserrat Variable',
  'Noto Serif Variable',
  'Nunito Variable',
  'Open Sans Variable',
  'Oswald Variable',
  'Playfair Display Variable',
  'Poppins',
  'Roboto Mono',
  'Roboto Serif',
  'Rouge Script',
  'Zeyada',
] as const;

export type FontFamily = (typeof FONT_FAMILIES)[number];
