import React, { useEffect } from 'react';

import { useUnit } from 'effector-react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Image as TImage } from 'konva/lib/shapes/Image';
import { Group, Image, Rect, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import useLoadedImage from 'use-image';

import { Studio } from '@api/services';
import { ImageBlock, ImageType } from '@pages/StudioPage/api';
import {
  GRID_SIZE,
  IMAGE_GROUP,
  TRANSFORMER,
} from '@pages/StudioPage/constants';
import { useImages } from '@pages/StudioPage/hooks/useImages';
import {
  $currentTool,
  $dragSelection,
  $selectedImageBlocks,
  $selectedTextBlocks,
  selectedImageBlockIdsAction,
  selectedTextBlockIdsAction,
} from '@pages/StudioPage/model';
import { calculateBoundsFromStage } from '@pages/StudioPage/utils';
import { useAppData } from '@src/AppContext';
import {
  computeStickPositionFromRatio,
  computeStickyPositionToRatio,
} from '@src/entities/Stickies/utils';
import { WOLFE_LIGHT } from '@src/shared/constants/colours';
import useStickies from '@src/shared/queries/useStickies';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';

import { ImageToolbar } from '../ImageToolbar';

import styles from './styles.module.css';

type Props = {
  designId: string;
  updateThumbnail: () => void;
  stageRef: React.RefObject<Konva.Stage>;
};

/**
 * Cropping in this component is very confusing. The crop values are a x, y, width, height related to the original height and width
 */

const LOADING_COLOURS = ['#DCD5CB', '#8A6F51', '#68402E', '#8A6F51', '#DCD5CB'];

export const Images = ({ designId, updateThumbnail, stageRef }: Props) => {
  const { imageQuery } = useImages({ designId });
  const [chosenImageForOnboarding, setChosenImageForOnboarding] =
    React.useState<ImageBlock | null>(null);

  React.useEffect(() => {
    // Refetch on unmount so data is fresh when navigating back
    return () => {
      imageQuery.refetch();
    };
  }, []);

  useEffect(() => {
    if (!imageQuery.data?.data.results || chosenImageForOnboarding) return;

    // Chose top left most image
    let image = imageQuery.data.data.results[0];

    imageQuery.data.data.results.forEach((img) => {
      if (
        img.studio.position_x < image.studio.position_x &&
        img.studio.position_y < image.studio.position_y
      ) {
        image = img;
      }
    });

    setChosenImageForOnboarding(image);
  }, []);

  if (imageQuery.isLoading) return null;

  if (!imageQuery.data) return null;

  return (
    <>
      {imageQuery.data.data.results.sort(imageOrdering).map((image) => (
        <LoadedImage
          id={image.id}
          designId={designId}
          key={image.id}
          url={image.file.full_size}
          locked={image.studio.position_lock}
          originalWidth={image.width}
          originalHeight={image.height}
          originalRotation={image.studio.position_omega}
          updateThumbnail={updateThumbnail}
          studio={image.studio}
          stageRef={stageRef}
          imageType={image.block_type}
          mostUpperRightImage={chosenImageForOnboarding?.id === image.id}
        />
      ))}
    </>
  );
};

const imageOrdering = (a: ImageBlock, b: ImageBlock) => {
  return a.studio.layer - b.studio.layer;
};

type LoadedImageProps = {
  url: string;
  originalHeight: number;
  originalWidth: number;
  locked: boolean;
  originalRotation: number;
  designId: string;
  id: string;
  updateThumbnail: () => void;
  studio: Studio;
  stageRef: React.RefObject<Konva.Stage>;
  imageType: ImageType;
  mostUpperRightImage: boolean;
};

const LoadedImage = ({
  url,
  originalHeight,
  originalWidth,
  designId,
  id,
  locked,
  originalRotation,
  studio,
  updateThumbnail,
  stageRef,
  imageType,
  mostUpperRightImage,
}: LoadedImageProps) => {
  const queryClient = useQueryClient();

  const [selectedImages, currentTool, dragSelection, selectedTextBlocks] =
    useUnit([
      $selectedImageBlocks,
      $currentTool,
      $dragSelection,
      $selectedTextBlocks,
    ]);

  // Hooks
  const { user } = useAppData();
  const [image, status] = useLoadedImage(url, 'anonymous');
  const { imageUpdateMutation } = useImages({ designId });
  const { updateAllStickiesToNewPositions, bulkMutateStickies } = useStickies(
    designId,
    null,
  );

  // Local State
  const [hasMounted, setHasMounted] = React.useState(false);
  const [width, setWidth] = React.useState(
    studio.position_width || originalWidth,
  );
  const [height, setHeight] = React.useState(
    studio.position_height || originalHeight,
  );
  const [x, setX] = React.useState(studio.position_x);
  const [y, setY] = React.useState(studio.position_y);
  const [rotation, setRotation] = React.useState(originalRotation);
  const [isHovered, setIsHovered] = React.useState(false);
  // const [isCropping, setIsCropping] = React.useState(false);
  const [isCropping] = React.useState(false);
  const [draggedImagePos, setDraggedImagePos] = React.useState({
    x: 0,
    y: 0,
  });
  const [isDragging, setIsDragging] = React.useState(false);

  // Refs
  const imageRef = React.useRef<TImage | null>(null);
  const draggingImageRef = React.useRef<TImage | null>(null);
  // const cropImageOverlayRef = React.useRef<TImage | null>(null);
  const trRef = React.useRef<Konva.Transformer>(null);
  const draggingImageTrRef = React.useRef<Konva.Transformer>(null);
  const hoveredTrRef = React.useRef<Konva.Transformer>(null);
  const positionBeforeDrag = React.useRef({
    x,
    y,
  });
  // const cropFakeRect = React.useRef<Konva.Rect>(null);
  // const cropTrRef = React.useRef<Konva.Transformer>(null);

  // const [boxCrop, setBoxCrop] = React.useState({
  //   x: 0, //studio.crop.top,
  //   y: 0, //studio.crop.left,
  //   width: studio.position_width,
  //   height: studio.position_height,
  // });

  // On the fly calculations
  const isSelected =
    selectedImages.has(id) &&
    selectedImages.size === 1 &&
    selectedImages &&
    !locked;
  const selectedBlocks = [...selectedImages, ...selectedTextBlocks];
  const isInMassSelection = selectedImages.has(id) && selectedBlocks.length > 1;
  const canDrag = currentTool === 'select' && !locked && !isCropping;
  const cropLeft = studio.crop.left || 0;
  const cropTop = studio.crop.top || 0;
  const cropRight = studio.crop.right || 0;
  const cropBottom = studio.crop.bottom || 0;

  // Sync states

  // React.useEffect(() => {
  //   // Reset all local state if is deselected
  //   if (!isSelected) {
  //     setIsCropping(false);
  //     if (image && imageRef.current) {
  //       setBoxCrop({
  //         x: 0,
  //         y: 0,
  //         width: studio.position_width,
  //         height: studio.position_height,
  //       });
  //     }
  //   }
  // }, [isSelected, isCropping]);

  // Keyboard shortkeys
  // useKeyPress({
  //   key: 'Enter',
  //   onKeyDown: () => {
  //     // Trigger when isCropping
  //     if (!isCropping) return;

  //     const cropBox = cropFakeRect.current;
  //     const imageBox = cropImageOverlayRef.current;
  //     const scale = sceneState.scale;

  //     if (!cropBox || !imageBox) return;

  //     const croppedImageRealHeight =
  //       (studio.position_height * height) / (cropBottom || 0);

  //     const croppedImageRealWidth =
  //       (studio.position_width * width) / (cropRight || 0);

  //     const l =
  //       (cropBox.getAbsolutePosition().x - imageBox.getAbsolutePosition().x) /
  //       scale;
  //     const left = (width * l) / croppedImageRealWidth;

  //     const t =
  //       (cropBox.getAbsolutePosition().y - imageBox.getAbsolutePosition().y) /
  //       scale;
  //     const top = (height * t) / croppedImageRealHeight;

  //     const right =
  //       (width * croppedImageRealWidth) / (cropBox.width() * cropBox.scaleX());

  //     const bottom =
  //       (height * croppedImageRealHeight) /
  //       (cropBox.height() * cropBox.scaleY());

  //     console.log('left', left, 'right', right, 'top', top, 'bottom', bottom);

  //     setIsCropping(false);
  //     // imageCropMutation.mutate({
  //     //   id,
  //     //   payload: {
  //     //     top: 0, //Math.fround(top),
  //     //     left: 0, //Math.fround(left),
  //     //     right: 0, //Math.fround(right),
  //     //     bottom: 0, //Math.fround(bottom),
  //     //     set: designId,
  //     //   },
  //     // });
  //   },
  // });

  // OLD CODE
  // useKeyPress({
  //   key: 'Enter',
  //   onKeyDown: () => {
  //     // Trigger when isCropping
  //     if (!isCropping) return;

  //     cropFakeRect.current?.getAbsolutePosition();
  //     imageRef.current?.getAbsolutePosition();

  //     const cropBox = cropFakeRect.current;
  //     const imageBox = imageRef.current;

  //     if (!cropBox || !imageBox) return;

  //     const left = Math.abs(
  //       Math.fround(imageBox.getAbsolutePosition().x) -
  //         Math.fround(cropBox.getAbsolutePosition().x),
  //     );

  //     const top = Math.abs(
  //       Math.fround(imageBox.getAbsolutePosition().y) -
  //         Math.fround(cropBox.getAbsolutePosition().y),
  //     );

  //     const b1 =
  //       imageBox.height() * sceneState.scale + imageBox.getAbsolutePosition().y;
  //     const b2 =
  //       cropBox.height() * cropBox.scaleY() * sceneState.scale +
  //       cropBox.getAbsolutePosition().y;
  //     const bottom = b1 - b2;

  //     const r1 =
  //       imageBox.width() * sceneState.scale + imageBox.getAbsolutePosition().x;
  //     const r2 =
  //       cropBox.width() * cropBox.scaleX() * sceneState.scale +
  //       cropBox.getAbsolutePosition().x;
  //     const right = r1 - r2;

  //     // setIsCropping(false);
  //     console.log(left, right, top, bottom);
  //     // imageCropMutation.mutate({
  //     //   id,
  //     //   payload: {
  //     //     top,
  //     //     left,
  //     //     right,
  //     //     bottom,
  //     //     set: designId,
  //     //   },
  //     // });
  //   },
  // });

  const removeSelectedImageTransformer = () => {
    // @ts-ignore
    trRef.current.nodes([]);
    // @ts-ignore
    trRef.current.getLayer().batchDraw();
  };

  React.useEffect(() => {
    // Used to update the transformer when transforming the image
    if (isSelected && trRef.current) {
      // we need to attach transformer manually
      // @ts-ignore
      trRef.current.nodes([imageRef.current]);
      // @ts-ignore
      trRef.current.getLayer().batchDraw();
    } else if (trRef.current && !isSelected) removeSelectedImageTransformer();

    return () => {
      if (trRef?.current) removeSelectedImageTransformer();
    };
  }, [isSelected]);

  const removeDraggingImageTransformer = () => {
    // @ts-ignore
    draggingImageTrRef.current.nodes([]);
    // @ts-ignore
    draggingImageTrRef.current.getLayer().batchDraw();
  };

  React.useEffect(() => {
    // Used to update the transformer when dragging the image
    if (isDragging) {
      // we need to attach transformer manually
      // @ts-ignore
      draggingImageTrRef.current.nodes([draggingImageRef.current]);
      // @ts-ignore
      draggingImageTrRef.current.getLayer().batchDraw();
    } else if (!isDragging && draggingImageTrRef.current)
      removeDraggingImageTransformer();

    return () => {
      if (draggingImageTrRef?.current) removeDraggingImageTransformer();
    };
  }, [isDragging]);

  const removeHoveredImageTransformer = () => {
    // @ts-ignore
    hoveredTrRef.current.nodes([]);
    // @ts-ignore
    hoveredTrRef.current.getLayer().batchDraw();
  };

  React.useEffect(() => {
    // Used to update the transformer when transforming the image
    if ((isHovered || isInMassSelection) && hoveredTrRef.current) {
      // we need to attach transformer manually
      // @ts-ignore
      hoveredTrRef.current.nodes([imageRef.current]);
      // @ts-ignore
      hoveredTrRef.current.getLayer().batchDraw();
    } else if (!(isHovered || isInMassSelection) && hoveredTrRef.current)
      removeHoveredImageTransformer();

    return () => {
      if (hoveredTrRef.current) removeHoveredImageTransformer();
    };
  }, [isHovered, isInMassSelection]);

  // React.useEffect(() => {
  //   // Used to update the transformer when cropping image
  //   if (isCropping && cropTrRef.current) {
  //     // we need to attach transformer manually
  //     // @ts-ignore
  //     cropTrRef.current.nodes([cropFakeRect.current]);
  //     // @ts-ignore
  //     cropTrRef.current.getLayer().batchDraw();
  //   }

  //   return () => {
  //     if (cropTrRef.current) {
  //       // @ts-ignore
  //       cropTrRef.current.nodes([]);
  //       // @ts-ignore
  //       cropTrRef.current.getLayer().batchDraw();
  //     }
  //   };
  // }, [isCropping]);

  React.useEffect(() => {
    // Adds event listeners to the html div and it has no pointer events
    if (!imageRef.current) return;

    const handlePointerEnter = () => {
      setIsHovered(true);
    };
    const handlePointerLeave = () => {
      setIsHovered(false);
    };

    imageRef.current.addEventListener('pointerenter', handlePointerEnter);
    imageRef.current.addEventListener('pointerleave', handlePointerLeave);

    return () => {
      if (!imageRef.current) return;
      imageRef.current.removeEventListener('pointerenter');
      imageRef.current.removeEventListener('pointerleave');
    };
  }, [hasMounted]);

  const onSelectImage = () => {
    setIsDragging(false);
    selectedTextBlockIdsAction(new Set());
    if (!canDrag || locked) return;
    selectedImageBlockIdsAction(new Set([id]));
  };

  const syncLocalStateToServer = () => {
    // HACK this is a hack to sync the local state to the server
    // In future remove local state and mutate react queries directly to move and adjust images and be the source of truth
    setX(studio.position_x);
    setY(studio.position_y);
    setWidth(studio.position_width || originalWidth);
    setHeight(studio.position_height || originalHeight);
    setRotation(studio.position_omega);
  };

  const dragStarted = () => {
    if (isInMassSelection) return;
    syncLocalStateToServer();
    onSelectImage();
    setIsDragging(true);
    positionBeforeDrag.current = {
      x,
      y,
    };
  };

  const updateDragPosition = (e: KonvaEventObject<DragEvent>) => {
    // Drag has ended
    if (!imageRef.current) return;

    const positionXGrid =
      Math.round(e.currentTarget.x() / GRID_SIZE) * GRID_SIZE;
    const positionYGrid =
      Math.round(e.currentTarget.y() / GRID_SIZE) * GRID_SIZE;

    imageUpdateMutation.mutate({
      imageId: id,
      positionX: positionXGrid,
      positionY: positionYGrid,
      positionWidth: width,
      positionHeight: height,
      positionLock: locked,
      positionOmega: e.currentTarget.rotation() || 0,
    });

    imageRef.current.to({
      duration: 0.2,
      x: positionXGrid,
      y: positionYGrid,
      onFinish: () => {
        setX(positionXGrid);
        setY(positionYGrid);
      },
    });

    setIsDragging(false);

    updateThumbnail();

    if (!stageRef?.current) return;

    // Recalculate positions for all the stickies
    // This is because sticky positions use ratios and if an image near an edge of the stage is moved, the stickies ratio can be incorrect.
    const updatedStickyPositions = updateAllStickiesToNewPositions({
      stage: stageRef.current,
    });

    if (!updatedStickyPositions) return;

    // If any attached stickies move those as well
    const attachedStickies = updatedStickyPositions.filter(
      (sticky) =>
        sticky.attached_to_id === id && sticky.attached_to_type === 'file',
    );

    if (!attachedStickies) return;

    const stage = stageRef.current;
    if (!stage) return;

    const bulkEditStickies = attachedStickies.map((sticky) => {
      const { x: stickyX, y: stickyY } = computeStickPositionFromRatio({
        ratioX: sticky.left_pixel,
        ratioY: sticky.top_pixel,
        ...calculateBoundsFromStage(stage),
      });

      // Find the difference moved to apply to the sticky
      const imageMovedXAmount =
        e.currentTarget.x() - positionBeforeDrag.current.x;
      const imageMovedYAmount =
        e.currentTarget.y() - positionBeforeDrag.current.y;

      const newStickyPosX = stickyX + imageMovedXAmount;
      const newStickyPosY = stickyY + imageMovedYAmount;

      const { ratioX, ratioY } = computeStickyPositionToRatio({
        x: newStickyPosX,
        y: newStickyPosY,
        ...calculateBoundsFromStage(stage),
      });

      return {
        ...sticky,
        left_pixel: ratioX,
        top_pixel: ratioY,
      };
    });

    bulkMutateStickies.mutate({
      data: bulkEditStickies,
    });
  };

  const updatePositionState = (e: KonvaEventObject<DragEvent>) => {
    // Drag is happening
    if (!imageRef.current || isInMassSelection) return;

    const moveToX = e.currentTarget.x();
    const moveToY = e.currentTarget.y();

    const positionXGrid = Math.round(moveToX / GRID_SIZE) * GRID_SIZE;
    const positionYGrid = Math.round(moveToY / GRID_SIZE) * GRID_SIZE;

    const positionX = e.currentTarget.x();
    const positionY = e.currentTarget.y();

    setDraggedImagePos({
      x: positionXGrid,
      y: positionYGrid,
    });

    setX(positionX);
    setY(positionY);
  };

  const updateTransformState = (e: KonvaEventObject<Event>) => {
    if (!imageRef.current) return;

    setIsDragging(false);

    const positionWidth = e.currentTarget.width() * e.currentTarget.scaleX();
    const positionHeight = e.currentTarget.height() * e.currentTarget.scaleY();
    const rotation = e.currentTarget.rotation();
    const positionX = e.currentTarget.x();
    const positionY = e.currentTarget.y();

    e.currentTarget.scaleX(1);
    e.currentTarget.scaleY(1);

    setWidth(positionWidth);
    setHeight(positionHeight);
    setRotation(rotation);
    setX(positionX);
    setY(positionY);
  };

  const onTransform = (e: KonvaEventObject<Event>) => {
    if (!imageRef.current) return;

    setIsDragging(false);

    const positionWidth = e.currentTarget.width() * e.currentTarget.scaleX();
    const positionHeight = e.currentTarget.height() * e.currentTarget.scaleY();
    const positionX = e.currentTarget.x();
    const positionY = e.currentTarget.y();

    e.currentTarget.scaleX(1);
    e.currentTarget.scaleY(1);

    imageUpdateMutation.mutate({
      imageId: id,
      positionX,
      positionY,
      positionWidth,
      positionHeight,
      positionLock: locked,
      positionOmega: e.currentTarget.rotation(),
    });

    setWidth(positionWidth);
    setHeight(positionHeight);
    setX(positionX);
    setY(positionY);
    setDraggedImagePos({
      x: positionX,
      y: positionY,
    });

    updateThumbnail();
  };

  const loadingColour = React.useMemo(
    () => LOADING_COLOURS[getRandomIntInclusive(0, LOADING_COLOURS.length)],
    [],
  );

  if (status === 'loading')
    return (
      <Rect
        x={x}
        y={y}
        height={studio.position_height || height}
        width={studio.position_width || width}
        fill={loadingColour}
        rotation={rotation}
      />
    );

  const showOnboardingDot =
    user.meta?.onboarding?.workCreatively === 'moodboards-that-pop' &&
    mostUpperRightImage &&
    !selectedImages.has(id) &&
    currentTool !== 'move';

  const stageScaleX = stageRef.current?.scaleX() ?? 0;

  return (
    <Group>
      {showOnboardingDot ? (
        <Html
          divProps={{
            style: {
              transform: 'unset',
              zIndex: 'unset',
            },
          }}
        >
          <div
            className={styles.demoIndicator}
            style={{
              position: 'absolute',
              top:
                (imageRef.current?.getAbsolutePosition().y ?? 0) -
                12 +
                (imageRef.current?.getHeight() * stageScaleX) / 2,
              left:
                (imageRef.current?.getAbsolutePosition().x ?? 0) -
                12 +
                (imageRef.current?.getWidth() * stageScaleX) / 2,
            }}
          ></div>
        </Html>
      ) : null}
      <Image
        visible={isDragging}
        ref={draggingImageRef}
        x={draggedImagePos.x}
        y={draggedImagePos.y}
        draggable={false}
        onClick={onSelectImage}
        onMouseOver={onSelectImage}
        onTap={onSelectImage}
        rotation={rotation}
        flipEnabled={false}
        crop={{
          x: cropLeft || 0,
          y: cropTop || 0,
          height: cropBottom || 0,
          width: cropRight || 0,
        }}
        height={height}
        width={width}
        image={image}
        name="Image-Drag"
      />
      {isDragging ? (
        // Transformer for dragging image
        <Transformer
          ref={draggingImageTrRef}
          borderStroke={WOLFE_LIGHT}
          anchorStroke={WOLFE_LIGHT}
          anchorCornerRadius={100}
          rotateAnchorOffset={58}
          name={TRANSFORMER}
        />
      ) : null}
      <Image
        x={x}
        y={y}
        draggable={canDrag}
        onDragEnd={updateDragPosition}
        onDragMove={updatePositionState}
        onDragStart={dragStarted}
        rotation={rotation}
        flipEnabled={false}
        id={id}
        ref={(ref) => {
          imageRef.current = ref;
          setHasMounted(true);
        }}
        crop={{
          x: cropLeft || 0,
          y: cropTop || 0,
          height: cropBottom || 0,
          width: cropRight || 0,
        }}
        height={height}
        width={width}
        onClick={onSelectImage}
        onTap={onSelectImage}
        onTransform={updateTransformState}
        onTransformEnd={onTransform}
        image={image}
        name={IMAGE_GROUP}
        visible={!isDragging}
        // visible={!isDragging && !isCropping}
      />
      {isSelected && !isCropping && !isInMassSelection ? (
        // Transformer for rotating and resizing
        <Transformer
          rotationSnaps={[0, 90, 180, 270]}
          ref={trRef}
          borderStroke={WOLFE_LIGHT}
          anchorStroke={WOLFE_LIGHT}
          anchorCornerRadius={100}
          rotateAnchorOffset={58}
          visible={!isDragging}
          name={TRANSFORMER}
        />
      ) : null}
      {(!isSelected && isHovered && !dragSelection && currentTool !== 'move') ||
      isInMassSelection ? (
        // Transformer for hovering selection
        <Transformer
          rotateEnabled={false}
          // @ts-ignore TODO fix type issue
          ref={hoveredTrRef}
          keepRatio={false}
          enabledAnchors={[]}
          resizeEnabled={false}
          borderStroke={WOLFE_LIGHT}
          anchorStroke={WOLFE_LIGHT}
          name={TRANSFORMER}
        />
      ) : null}
      {/* {isCropping ? (
        <Group x={x} y={y}>
          <Image
            x={
              cropLeft === 0
                ? 0
                : -(cropLeft * studio.position_width) / cropRight
            }
            y={
              cropTop === 0
                ? 0
                : -(cropTop * studio.position_height) / cropBottom
            }
            height={
              cropBottom === 0
                ? studio.position_height
                : (studio.position_height * height) / cropBottom
            }
            width={
              cropRight === 0
                ? studio.position_width
                : (studio.position_width * width) / cropRight
            }
            image={image}
            opacity={0.2}
            ref={cropImageOverlayRef}
          /> */}
      {/* <Group
            clipFunc={(ctx) => {
              ctx.save();
              ctx.beginPath();
              ctx.rect(boxCrop.x, boxCrop.y, boxCrop.width, boxCrop.height);
              ctx.closePath();
              ctx.restore();
            }}
          >
            <Image
              x={0}
              y={0}
              height={originalHeight}
              width={originalWidth}
              image={image}
            />
          </Group> */}
      {/* <Rect
            // Fake Rect for cropping
            x={0}
            y={0}
            height={studio.position_height}
            width={studio.position_width}
            ref={cropFakeRect}
            draggable={isCropping}
            dragBoundFunc={(pos) => {
              if (!cropImageOverlayRef.current) return { ...pos };
              // Use getAbsolutePosition for constrain pos within the imageRef
              const canvasPosX =
                cropImageOverlayRef.current.getAbsolutePosition().x;
              const canvasPosY =
                cropImageOverlayRef.current.getAbsolutePosition().y;
              const imageWidth = cropImageOverlayRef.current.width();

              return {
                x: Math.max(
                  Math.min(
                    canvasPosX +
                      imageWidth * sceneState.scale -
                      boxCrop.width * sceneState.scale,
                    pos.x,
                  ),
                  canvasPosX,
                ),
                y: Math.max(
                  Math.min(
                    canvasPosY +
                      cropImageOverlayRef.current.height() * sceneState.scale -
                      boxCrop.height * sceneState.scale,
                    pos.y,
                  ),
                  canvasPosY,
                ),
              };
            }}
            onDragMove={(e) => {
              setBoxCrop({
                x: e.target.x(),
                y: e.target.y(),
                width: e.target.width() * e.target.scaleX(),
                height: e.target.height() * e.target.scaleY(),
              });
            }}
            onTransform={(e) => {
              setBoxCrop({
                x: e.target.x(),
                y: e.target.y(),
                width: e.target.width() * e.target.scaleX(),
                height: e.target.height() * e.target.scaleY(),
              });
            }}
          />
          <Transformer
            borderStroke="black"
            ref={cropTrRef}
            enabledAnchors={[
              'top-right',
              'top-left',
              'bottom-right',
              'bottom-left',
            ]}
            anchorSize={10}
            anchorStroke="black"
            anchorFill="black"
            rotateEnabled={false}
            flipEnabled={false}
            keepRatio={false}
            name={TRANSFORMER}
          />
        </Group>
      ) : null} */}
      <Html
        divProps={{
          style: {
            transform: 'unset',
            zIndex: 'unset',
          },
        }} // additional props for wrapped div elements, useful for styles
      >
        {isSelected && !isCropping && !isInMassSelection ? (
          <QueryClientProvider client={queryClient}>
            <ImageToolbar
              designId={designId}
              imageId={id}
              imageType={imageType}
              canShowOnboardingTip={mostUpperRightImage}
            />
          </QueryClientProvider>
        ) : null}
      </Html>
    </Group>
  );
};

// const throttle = <T extends (...args: any[]) => void>(
//   callback: T,
//   delay: number,
// ) => {
//   let lastCall = 0;
//   return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
//     const now = new Date().getTime();
//     if (now - lastCall >= delay) {
//       lastCall = now;
//       callback.apply(this, args);
//     }
//   };
// };

function getRandomIntInclusive(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}
