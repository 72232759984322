import cn from 'classnames';
import { useUnit } from 'effector-react';

import { Fab } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { useAllBlocks } from '@pages/LibraryPage/model/queries/useAllBlocks';
import { useAppData } from '@src/AppContext';
import { Editor } from '@tiptap/react';

import {
  allFilesFetched,
  designsFetched,
  imagesFetched,
  palettesFetched,
} from '../../pages/DocPage/model/files-filter';
import {
  $isUploadAvailable,
  $selectedImages,
  allImagesUnselected,
} from '../../pages/DocPage/model/files-selection';
import {
  $isShowElementsPanel,
  $isShowFilesPanel,
} from '../../pages/DocPage/model/sidebar-opening';
import { Elements } from './elements';
import { Files } from './files';

import styles from './styles.module.css';

export const DocSidebar = ({ editor }: { editor: Editor }) => {
  const isShowElementsPanel = useUnit($isShowElementsPanel);
  const isShowFilesPanel = useUnit($isShowFilesPanel);
  const isUploadAvailable = useUnit($isUploadAvailable);
  const selectedImages = useUnit($selectedImages);

  const { user } = useAppData();

  const imageBlockQuery = useAllBlocks({
    ordering: '-created_at',
    typeOfBlock: 'image',
  });

  const designBlockQuery = useAllBlocks({
    ordering: '-created_at',
    typeOfBlock: 'set',
  });

  const paletteBlockQuery = useAllBlocks({
    ordering: '-created_at',
    typeOfBlock: 'color_palette',
  });

  const allBlockQuery = useAllBlocks({
    ordering: '-created_at',
  });

  allFilesFetched(allBlockQuery.blocks);
  imagesFetched(imageBlockQuery.blocks);
  designsFetched(designBlockQuery.blocks);
  palettesFetched(paletteBlockQuery.blocks);

  const addImages = () => {
    if (selectedImages && editor) {
      for (const image of selectedImages) {
        const { schema } = editor.view.state;

        let adjustedWidth = image.width;
        let adjustedHeight = image.height;
        const aspectRatio = parseFloat((image.height / image.width).toFixed(2));

        const pos = editor.state.selection.anchor;
        const $pos = editor.state.doc.resolve(pos);
        let cellWidth;

        // Traverse up the node hierarchy to check if we're in a table cell
        for (let depth = $pos.depth; depth > 0; depth--) {
          const node = $pos.node(depth);
          if (node.type.name === 'tableCell') {
            const cellDOM = editor.view.nodeDOM(
              $pos.before(depth),
            ) as HTMLElement;
            if (cellDOM) {
              cellWidth = cellDOM.getBoundingClientRect().width;
              if (cellWidth) {
                adjustedWidth = cellWidth;
                adjustedHeight = parseFloat(
                  (aspectRatio * adjustedWidth).toFixed(2),
                );
              }
            }
            break; // Stop traversal once we find a table cell
          }
        }

        const imageNode = schema.nodes.FileComponent.create({
          src: image.url,
          width: adjustedWidth,
          height: adjustedHeight,
          aspectRatio: aspectRatio,
          actualHeight: adjustedHeight,
          actualWidth: adjustedWidth,
          alt: image.id,
          fileId: image.id,
          fileType: 'image',
        });

        const tr = editor.view.state.tr;

        tr.insert(pos, imageNode);

        editor.view.dispatch(tr);
      }
    }

    allImagesUnselected();
  };

  return (
    <>
      {/* A div for hard coding the sidebar tooltip position. Remove when this will be refactored */}
      <div
        style={{
          position: 'fixed',
          top: '188px',
          width: isShowFilesPanel || isShowElementsPanel ? '530px' : '171px',
          left: '0px',
          height: 10,
          zIndex: '1000',
          pointerEvents: 'none',
        }}
        id="onboarding-doc-file-panel"
      ></div>
      <div
        className={cn(styles.container, {
          [styles.expanded]: isShowFilesPanel || isShowElementsPanel,
        })}
      >
        <div
          className={cn(styles.sidebar, {
            [styles.showSidebar]: isShowFilesPanel || isShowElementsPanel,
            [styles.sidebarAlignment]: isUploadAvailable,
            [styles.demoMode]: user.meta?.onboarding?.demoMode,
          })}
        >
          {isShowElementsPanel && <Elements />}
          {isShowFilesPanel && (
            <Files
              allBlockQuery={allBlockQuery}
              imageBlockQuery={imageBlockQuery}
              designBlockQuery={designBlockQuery}
              paletteBlockQuery={paletteBlockQuery}
            />
          )}
          <div
            className={cn(styles.fab, {
              [styles.fabAlignment]: isUploadAvailable,
            })}
          >
            {isUploadAvailable && (
              <Fab
                style="extended"
                icon={<Icon name="sprite/plus" />}
                label="Add to this doc"
                onClick={addImages}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
