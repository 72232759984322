import {
  Button,
  Divider,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { openedCancelPlanModal } from '@pages/AccountPage/models';
import { useBilling } from '@src/shared/queries/useBilling';

import styles from './styles.module.css';

export const PaymentMethod = () => {
  const {
    manageBillingInfoMutation: { mutate, isPending },
  } = useBilling();

  const updatePaymentMethod = () => {
    mutate();
  };

  return (
    <div className={styles.container}>
      <Divider type="short-line" />
      <TypographyPoppins type="headline" size="L">
        <span className={styles.header}>Payment method</span>
      </TypographyPoppins>
      <div className={styles.innerContainer}>
        <Button
          onClick={updatePaymentMethod}
          label="Update my payment method"
          type="outlined"
          isDisabled={isPending}
        />
        <div className={styles.ghostButton}>
          <Button
            onClick={() => openedCancelPlanModal()}
            label="Cancel subscription"
            type="ghost"
            isDisabled={isPending}
            style={{
              color: 'var(--color-error-40)',
              fontSize: '12px',
            }}
          />
        </div>
      </div>
    </div>
  );
};
