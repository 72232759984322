import { differenceInDays } from 'date-fns';
import { createEffect, createEvent, createStore, sample } from 'effector';

export const getQuote = createEvent();

const selectQuoteFx = createEffect(() => {
  const startDate = new Date('2024-01-01T00:00:00Z');
  const today = new Date();
  const daysDifference = differenceInDays(today, startDate);
  const next = daysDifference % quotes.length;

  return quotes[next];
});

export const $quote = createStore<{
  message: string;
  author: string;
} | null>(null);

$quote.on(selectQuoteFx.doneData, (_, quote) => quote);

sample({ clock: getQuote, target: selectQuoteFx });

const quotes = [
  {
    message: '“Inspiration usually comes during work rather than before it.”',
    author: 'Madeleine L’Engle',
  },
  {
    message:
      '“You can’t use up creativity. The more you use, the more you have.”',
    author: 'Maya Angelou',
  },
  {
    message: '“The worst enemy to creativity is self-doubt.”',
    author: 'Sylvia Plath',
  },
  {
    message:
      '“Great things are done by a series of small things brought together.”',
    author: 'Vincent van Gogh',
  },
  {
    message:
      '“Art is not about thinking something up. It is the opposite—getting something down.”',
    author: 'Julia Cameron',
  },
  {
    message:
      '“Creativity is a habit, and the best creativity is the result of good work habits.”',
    author: 'Twyla Tharp',
  },
  {
    message:
      '“Make the most beautiful thing you can. Try to do that every day. That’s it.”',
    author: 'Laurie Anderson',
  },
  {
    message: "“To create one's own world takes courage.”",
    author: "Georgia O'Keeffe",
  },
  {
    message:
      "“Passion is one great force that unleashes creativity, because if you're passionate about something, then you're more willing to take risks.”",
    author: 'Yo-Yo Ma',
  },
  {
    message:
      "“Creativity is an energy. It's a precious energy, and it's something to be protected.”",
    author: 'Ava DuVernay',
  },
  {
    message:
      "“Creativity is always a leap of faith. You're faced with a blank page, blank easel, or an empty stage.”",
    author: 'Julia Cameron',
  },
  {
    message:
      '“An idea that is not dangerous is unworthy of being called an idea at all.”',
    author: 'Oscar Wilde',
  },
  {
    message: '“The music is not in the notes, but in the silence between.”',
    author: 'Wolfgang Amadeus Mozart',
  },
  {
    message: '“Let the beauty we love be what we do.”',
    author: 'Rumi',
  },
  {
    message: '“Always be on the lookout for the presence of wonder.”',
    author: 'E.B. White',
  },
  {
    message:
      '“If I cannot do great things, I can do small things in a great way.”',
    author: 'Martin Luther King Jr.',
  },
  {
    message:
      '“Before you can think out of the box, you have to start with a box.”',
    author: 'Twyla Tharp',
  },
  {
    message: '“Inspiration exists, but it has to find you working.”',
    author: 'Pablo Picasso',
  },
  {
    message:
      '“It’s not where you take things from. It’s where you take them to.”',
    author: 'Jean-Luc Godard',
  },
  {
    message:
      '“Do not be satisfied with the stories that come before you. Unfold your own myth.”',
    author: 'Rumi',
  },
  {
    message:
      '“The world is full of magic things, patiently waiting for our senses to grow sharper.”',
    author: 'William Butler Yeats',
  },
  {
    message: '“The grass is greener where you water it.”',
    author: 'Neil Barringham',
  },
  {
    message: '“I can fix a bad page. I can’t fix a blank page.”',
    author: 'Nora Roberts',
  },
];
