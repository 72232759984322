import { useUnit } from 'effector-react';
import { Group, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';

import { $optimisticImages } from '@pages/StudioPage/model';

export const OptimisticImages = () => {
  const optimisticImages = useUnit($optimisticImages);

  return (
    <>
      {optimisticImages.map((image) => (
        <OptimisticImage
          key={image.id}
          imageURL={image.imageURL}
          x={image.position.x}
          y={image.position.y}
          height={image.height}
          width={image.width}
        />
      ))}
    </>
  );
};

type Props = {
  x: number;
  y: number;
  imageURL: string | HTMLImageElement;
  height: number;
  width: number;
};

const OptimisticImage = ({ imageURL, x, y, height, width }: Props) => {
  const getImage = () => {
    if (typeof imageURL === 'string') {
      const [image] = useImage(imageURL);
      return image;
    } else {
      return imageURL;
    }
  };

  return (
    <Group>
      <KonvaImage
        opacity={0.2}
        image={getImage()}
        x={x}
        y={y}
        height={height}
        width={width}
      />
    </Group>
  );
};
