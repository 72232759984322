import { useHistory } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  deleteInviteeFromHub,
  InvitationHub,
  RemoveInviteeFromHub,
} from '@api/hubs';
import {
  ALL_HUBS_QUERY,
  BOARDS_TREE_QUERY,
  MEMBERS_INVITATION_HUB_QUERY,
  MEMBERS_SUGGESTIONS_HUB_QUERY,
} from '@src/shared/constants/query-names';
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { shareSideSheetClosed } from '../..';

export const useRemoveInviteeFromHub = (
  options?: MutateOptions<
    RemoveInviteeFromHub | InvitationHub,
    any,
    RemoveInviteeFromHub | InvitationHub
  >,
) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { mutate: removeInviteeFromHub } = useMutation({
    mutationFn: ({ hubId, members, email, hubName }: RemoveInviteeFromHub) =>
      deleteInviteeFromHub({
        hubId,
        members,
        email,
        hubName,
      }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_INVITATION_HUB_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_SUGGESTIONS_HUB_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_HUBS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });

      if (variables.noHubAccess) {
        history.push('/error');
        shareSideSheetClosed();
      }

      startedSnack({
        label: `Removed them from ${variables.hubName}`,
        close: true,
      });

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      startedSnack({
        label: `Couldn't remove them from ${variables.hubName}`,
        action: {
          label: 'Try again',
          action: () => {
            removeInviteeFromHub({
              members: variables.members,
              hubId: variables.hubId,
              hubName: variables.hubName,
            });
          },
        },
        close: true,
      });

      if (options?.onError) options?.onError(error, variables, context);
    },
  });

  return { removeInviteeFromHub };
};
