import React from 'react';

import styles from './Slide.module.css';

export interface SlideProps {
  tipTitles: string;
  gif: string | undefined;
  downText: string;
  link: string;
  shouldDispalyTopTip: boolean;
}

export const Slide = ({
  tipTitles,
  gif,
  downText,
  link,
  shouldDispalyTopTip,
}: SlideProps) => {
  return (
    <div className={styles.colourSearchContainer}>
      <div className={styles.upText}>
        {shouldDispalyTopTip ? null : <span>Have you tried?</span>}
        <h2>{tipTitles}</h2>
      </div>
      <div className={styles.imageContainer}>
        <div className={styles.imageBox}>
          <div className={styles.imageBack}>
            <img src={gif} alt={tipTitles} />
          </div>
        </div>
      </div>
      <div className={styles.downText}>
        <span>{downText}</span>
        {tipTitles === 'Browser Extension' && (
          <h3>
            <a href={link} target={'_blank'} rel="noopener noreferrer">
              Download now
            </a>
          </h3>
        )}
      </div>
    </div>
  );
};
