import { combine, createEvent, createStore, sample } from 'effector';

import { $isShowElementsPanel, $isShowFilesPanel } from './sidebar-opening';

export type SelectedImage = {
  id: string | number;
  url: string;
  height: number;
  width: number;
  thumbnailUrl: string;
};

export const imageSelected = createEvent<SelectedImage>();
export const allImagesUnselected = createEvent();

export const $selectedImages = createStore<SelectedImage[] | null>(null);

// Select/unselect image
sample({
  clock: imageSelected,
  source: {
    selectedImages: $selectedImages,
  },
  fn: ({ selectedImages }, { height, url, width, id, thumbnailUrl }) => {
    if (!selectedImages) {
      return [{ height, url, width, id, thumbnailUrl }];
    }

    const isImagesExists =
      selectedImages.filter((image) => image.id === id).length > 0;

    if (isImagesExists) {
      const images = selectedImages.filter((image) => image.id !== id);
      return images.length > 0 ? images : null;
    } else {
      return [...selectedImages, { height, url, width, id, thumbnailUrl }];
    }
  },
  target: $selectedImages,
});

// Unselect all images after closing the side panel
sample({
  clock: [$isShowElementsPanel, $isShowFilesPanel],
  source: {
    isShowElementsPanel: $isShowElementsPanel,
    isShowFilesPanel: $isShowFilesPanel,
  },
  filter: ({ isShowElementsPanel, isShowFilesPanel }) =>
    !isShowElementsPanel && !isShowFilesPanel,
  target: allImagesUnselected,
});

// Unselect all images after adding a files
sample({
  clock: allImagesUnselected,
  fn: () => null,
  target: $selectedImages,
});

///Display FAB when at least 1 image is selected
export const $isUploadAvailable = combine(
  { selectedImages: $selectedImages },
  ({ selectedImages }) => Array.isArray(selectedImages),
);
