import type { ImageBlock } from '@pages/StudioPage/api';

type NewImageBlock = Omit<ImageBlock, 'studio'> & {
  studio: {
    [key in keyof ImageBlock['studio']]: ImageBlock['studio'][key] extends
      | number
      | null
      ? number
      : ImageBlock['studio'][key];
  };
};

export class ImageFile {
  private image: NewImageBlock;

  constructor(image: ImageBlock) {
    this.image = {
      ...image,
      studio: {
        ...image.studio,
        position_width: image.studio.position_width ?? image.width,
        position_height: image.studio.position_height ?? image.height,
      },
    };
  }

  resizeImage({ width, height }: { width: number; height: number }) {
    this.image.studio.position_width = width;
    this.image.studio.position_height = height;

    return this;
  }

  resizeWidth(width: number) {
    this.image.studio.position_width = width; //(maxX - minX) * 1.5;
    this.image.studio.position_height =
      this.image.studio.position_width / (this.image.width / this.image.height);

    return this;
  }

  resizeImageAspect(size: number) {
    const image = this.image;
    const totalHeight = size;
    const totalWidth = size;

    const aspectRatio =
      image.studio.position_width / image.studio.position_height;

    // Get 90& of the height or width of the max bounds of all images
    if (image.studio.position_height > image.studio.position_width) {
      // Vertical image priority
      image.studio.position_height = totalHeight; //(maxY - minY) * 1.5;
      image.studio.position_width = image.studio.position_height * aspectRatio;
    } else {
      // Horizontal image priority
      image.studio.position_width = totalWidth; //(maxX - minX) * 1.5;
      image.studio.position_height = image.studio.position_width / aspectRatio;
    }

    return this;
  }

  resetRotation() {
    this.image.studio.position_omega = 0;
    return this;
  }

  centerImageFromPoint({ x, y }: { x: number; y: number }) {
    this.image.studio.position_x = x - this.image.studio.position_width / 2;
    this.image.studio.position_y = y - this.image.studio.position_height / 2;
    return this;
  }

  getId() {
    return this.image.id;
  }

  getRawImage() {
    return this.image;
  }

  getImageDimensions() {
    return {
      x: this.image.studio.position_x,
      y: this.image.studio.position_y,
      width: this.image.studio.position_width,
      height: this.image.studio.position_height,
    };
  }
}
