import React from 'react';

import styles from './styles.module.css';

interface PlaceholderMediaProps {
  src: string;
}

export const PlaceholderMedia = ({ src }: PlaceholderMediaProps) => {
  return <img src={src} className={styles.placeholderMedia} />;
};
