import { createEvent, createStore } from 'effector';

export const shareSideSheetOpened = createEvent();
export const shareSideSheetClosed = createEvent();

export const $isShareSheetOpen = createStore(false);

$isShareSheetOpen
  .on(shareSideSheetOpened, (state) => !state)
  .reset(shareSideSheetClosed);
