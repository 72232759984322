import {
  Button,
  Spinner,
  TypographyPoppins,
  VaiColouredPathIcon,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { ErrorBoundary } from '@sentry/react';
import { useAppData } from '@src/AppContext';
import {
  Card,
  CardContent,
  CardEmptyContent,
  CardHeader,
} from '@src/entities/cards';
import { ProgressBar } from '@src/widgets/profile/components/progress-bar';
import { getFormCompletionPercentage } from '@src/widgets/profile/util';
import { useConversations } from '@src/widgets/vai-main/hooks/useConversations';
import { openedVaiModal } from '@src/widgets/vai-main/model';
import { LatestConversation } from '@src/widgets/vai-main/ui/page-cards';

import styles from './styles.module.css';

export const VaiCard = () => {
  const { user } = useAppData();
  const { conversationQuery: conversationQueryVaiMessage } = useConversations({
    type: 0,
  });

  const { conversationQuery: conversationsQueryWithAction } = useConversations({
    type: 0,
    vai_output_type: 2,
  });

  if (
    conversationQueryVaiMessage.isLoading ||
    conversationsQueryWithAction.isLoading
  ) {
    return (
      <Card>
        <CardHeader
          title="Vai"
          icon={<VaiColouredPathIcon isPurple height={22} width={22} />}
        />
        <CardContent>
          <div className={styles.loader}>
            <Spinner />
          </div>
        </CardContent>
      </Card>
    );
  }

  const progressPercentage = getFormCompletionPercentage(user);

  return (
    <Card>
      <CardHeader
        title="Vai"
        icon={<VaiColouredPathIcon isPurple height={22} width={22} />}
      />
      {((conversationQueryVaiMessage.data &&
        conversationQueryVaiMessage.data.conversations.length === 0) ||
        !conversationQueryVaiMessage.data) &&
      ((conversationsQueryWithAction.data &&
        conversationsQueryWithAction.data.conversations.length === 0) ||
        !conversationsQueryWithAction.data) ? (
        <CardEmptyContent
          icon={<Icon name="sprite/sparkles-vai-colored" size={80} />}
          description={`Vai is your copilot—get
											automated assistance to
											take back your time`}
          button={
            <Button
              label="Talk to Vai"
              onClick={() => {
                openedVaiModal();
              }}
              type="outlined"
            />
          }
        />
      ) : (
        <CardContent>
          {progressPercentage !== 100 ? (
            <div className={styles.row}>
              <div>
                <Icon name="sprite/personalize" />
              </div>
              <div className={styles.rowLine}>
                <TypographyPoppins
                  type="body"
                  bodySize="M"
                  className={styles.text}
                >
                  Complete your profile to allow Vai to personalize suggestions{' '}
                </TypographyPoppins>
                <ProgressBar progressPercentage={progressPercentage} />
              </div>
            </div>
          ) : null}
          {/* Error boundary to prevent the Conversations from crashing since unsafe parse */}
          <ErrorBoundary
            fallback={<p>Sorry, it looks like something went wrong</p>}
          >
            <LatestConversation
              basePath="/home"
              messages={conversationQueryVaiMessage.data?.conversations ?? []}
              actionItems={
                conversationsQueryWithAction.data?.conversations ?? []
              }
            />
          </ErrorBoundary>
        </CardContent>
      )}
    </Card>
  );
};
