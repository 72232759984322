import React from 'react';

import cn from 'classnames';

import { UserType } from '../../types';
import UserIconBase from './UserIconBase';

import styles from './UserIcon.module.css';

export interface UserIconProps {
  as?: React.ElementType;
  disabled?: boolean;
  size?: number;
  user: UserType;
  to?: string;
  fontSize?: number;
}

const UserIcon = ({
  as = 'button',
  size = 44,
  user,
  disabled = false,
  fontSize,
  ...props
}: UserIconProps): JSX.Element => {
  const Component = as || 'button';
  const { photo, first_name, last_name } = user;

  return (
    <Component
      {...props}
      className={cn(styles.roundFlex, styles.userIcon, {
        [styles.disabled]: disabled,
      })}
      style={{ width: size, height: size }}
      disabled={disabled}
    >
      <UserIconBase
        photo={photo}
        firstName={first_name}
        lastName={last_name}
        size={size - 4}
        fontSize={fontSize}
      />
    </Component>
  );
};

export default UserIcon;
