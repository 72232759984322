import { useUnit } from 'effector-react';

import {
  SideSheet,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { useWindowSize } from '@visualist/hooks';

import {
  $isCancelPlanModalOpen,
  closedCancelPlanModal,
} from '@pages/AccountPage/models';
import { useBilling } from '@src/shared/queries/useBilling';

import styles from './styles.module.css';

export const CancelSheet = () => {
  const { isMobile } = useWindowSize();
  const isModalOpen = useUnit($isCancelPlanModalOpen);
  const { cancelMutation, query } = useBilling(closedCancelPlanModal);

  const closeSheet = () => closedCancelPlanModal();

  if (query.isLoading) return null;

  const switchToFreePlan = () => {
    const currentPlan = query.data?.membershipTierInfo.plan;

    if (!currentPlan) {
      startedSnack({
        label: 'Oops, something went wrong.',
        close: true,
      });
      return;
    }

    cancelMutation.mutate();
  };

  return (
    <SideSheet
      className={styles.sideSheetContainer}
      handleBackdropClick={closeSheet}
      showSheet={isModalOpen}
      closeSideSheet={closeSheet}
    >
      <SideSheet.Heading closeSideSheet={closeSheet}>
        Sorry to see you go
      </SideSheet.Heading>
      <SideSheet.Body>
        <SideSheet.ContentContainer>
          {isMobile ? (
            <TypographyPoppins type="title" size="L">
              <span className={styles.headingLabel}>Sorry to see you go</span>
            </TypographyPoppins>
          ) : null}
          <SideSheet.Content>
            You can cancel at anytime and {'\n'} continue to enjoy your current
            plan {'\n'}
            until the last day of your billing cycle.
          </SideSheet.Content>
        </SideSheet.ContentContainer>
      </SideSheet.Body>
      <SideSheet.BottomControls
        primaryLabel="Continue to cancel"
        mainType="outlined"
        closeSideSheet={closeSheet}
        mainClick={switchToFreePlan}
        hideCancel
        isDisabled={cancelMutation.isPending}
      />
    </SideSheet>
  );
};
