import { api } from './services';

export type RGB = {
  r: number;
  g: number;
  b: number;
};

export const bulkClipToBoardImages = (data: any) =>
  api.post(`/boards/add_blocks/`, data);

export const deleteColorFromImage = (imageId: string, colorId: string) => {
  const data = { color_list: [colorId] };
  api.post(`/blocks/${imageId}/colors/bulk_delete/`, data);
};

export const reorderImageBlocks = (imageId: string, colors: string[]) => {
  return api.post(`/blocks/${imageId}/colors/update_order/`, { colors });
};
