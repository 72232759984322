import React, { useState } from 'react';

import { debounce } from 'lodash';

import { SegmentedButton } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { useTextBoxes } from '@pages/StudioPage/hooks/useTextBoxes';

import { TextAction, TextState } from '../Textbox/reducer';

import styles from './styles.module.css';

type Props = {
  designId: string;
  textId: string;
  x: number;
  y: number;
  height: number;
  width: number;
  fontSize: number;
  text: string;
  formatState: TextState;
  dispatch: React.Dispatch<TextAction>;
  toolbarRef: React.MutableRefObject<HTMLDivElement | null>;
  matchTextboxSize: () => void;
};

export const TextToolbar = ({
  designId,
  textId,
  x,
  y,
  width,
  fontSize,
  text,
  formatState,
  dispatch,
  toolbarRef,
}: Props) => {
  const [showColorWheel, setShowColorWheel] = React.useState(false);
  const [localFontSize, setLocalFontSize] = React.useState(
    formatState.fontSize,
  );
  const [isFocus, setFocus] = useState(false);

  const colorInputRef = React.useRef<HTMLInputElement>(null);

  const { textboxMutation, deleteTextboxMutation, removeTextbox } =
    useTextBoxes({ designId });

  const toggleColorWheel = () => setShowColorWheel((s) => !s);

  React.useEffect(() => {
    if (showColorWheel) {
      colorInputRef.current?.click();
    }
  }, [showColorWheel]);

  const mutateAlignment = (alignment: 'left' | 'center' | 'right') => {
    textboxMutation.mutate({
      positionX: x,
      positionY: y,
      textboxId: textId,
      text,
      width,
      fontSize,
      alignment: alignment,
      bold: formatState.bold,
      italic: formatState.italic,
      underline: formatState.underline,
      colour: formatState.colour,
    });
  };

  const alignCenter = () => {
    dispatch({
      type: 'SET_ALIGNMENT',
      payload: {
        alignment: 'center',
      },
    });
    mutateAlignment('center');
  };

  const alignLeft = () => {
    dispatch({
      type: 'SET_ALIGNMENT',
      payload: {
        alignment: 'left',
      },
    });
    mutateAlignment('left');
  };

  const alignRight = () => {
    dispatch({
      type: 'SET_ALIGNMENT',
      payload: {
        alignment: 'right',
      },
    });
    mutateAlignment('right');
  };

  const toggleBold = () => {
    dispatch({
      type: 'SET_BOLD',
      payload: {
        bold: !formatState.bold,
      },
    });
    textboxMutation.mutate({
      positionX: x,
      positionY: y,
      textboxId: textId,
      text,
      width,
      fontSize,
      alignment: formatState.alignment,
      bold: !formatState.bold,
      italic: formatState.italic,
      underline: formatState.underline,
      colour: formatState.colour,
    });
  };

  const toggleItalic = () => {
    dispatch({
      type: 'SET_ITALIC',
      payload: {
        italic: !formatState.italic,
      },
    });
    textboxMutation.mutate({
      positionX: x,
      positionY: y,
      textboxId: textId,
      text,
      width,
      fontSize,
      alignment: formatState.alignment,
      bold: formatState.bold,
      italic: !formatState.italic,
      underline: formatState.underline,
      colour: formatState.colour,
    });
  };

  const toggleUnderline = () => {
    dispatch({
      type: 'SET_UNDERLINE',
      payload: {
        underline: !formatState.underline,
      },
    });
    textboxMutation.mutate({
      positionX: x,
      positionY: y,
      textboxId: textId,
      text,
      width,
      fontSize,
      alignment: formatState.alignment,
      bold: formatState.bold,
      italic: formatState.italic,
      underline: !formatState.underline,
      colour: formatState.colour,
    });
  };

  const updateFontSize = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFontSize = Number(e.target.value);

    if (newFontSize && newFontSize <= 0) return;

    setLocalFontSize(newFontSize);
  };

  const commitFontSize = React.useMemo(() => {
    return debounce((fontSize: number) => {
      textboxMutation.mutate({
        positionX: x,
        positionY: y,
        textboxId: textId,
        text,
        width,
        fontSize,
        alignment: formatState.alignment,
        bold: formatState.bold,
        italic: formatState.italic,
        underline: formatState.underline,
        colour: formatState.colour,
      });
    }, 1000);
  }, []);

  const updateColour = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColour = e.target.value;

    if (!newColour) return;

    dispatch({
      type: 'SET_COLOUR',
      payload: {
        colour: newColour,
      },
    });

    commitColour(newColour);
  };

  const commitColour = React.useMemo(() => {
    return debounce((newColour: string) => {
      textboxMutation.mutate({
        positionX: x,
        positionY: y,
        textboxId: textId,
        text,
        width,
        fontSize,
        alignment: formatState.alignment,
        bold: formatState.bold,
        italic: formatState.italic,
        underline: formatState.underline,
        colour: newColour,
      });
    }, 1000);
  }, []);

  const removeText = () => {
    if (textId.includes('temp-text')) {
      removeTextbox(textId);
    } else {
      deleteTextboxMutation.mutate({ ids: [textId] });
    }
  };

  return (
    <div ref={toolbarRef} id="text-toolbar" className={styles.container}>
      <div className={styles.textToolbar}>
        <SegmentedButton
          start
          buttonStyle={styles.button}
          icon={
            <input
              value={Math.round(localFontSize) ?? undefined}
              className={styles.input}
              onChange={updateFontSize}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  dispatch({
                    type: 'SET_FONTSIZE',
                    payload: {
                      fontSize: localFontSize,
                    },
                  });
                  commitFontSize(localFontSize);
                }
              }}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              type="number"
            />
          }
          onClick={() => {}}
          isSelected={isFocus}
        />
        <SegmentedButton
          buttonStyle={styles.button}
          icon={
            <>
              <Icon name="sprite/text-color-colored" />
              <div className={styles.colorContainer}>
                <input
                  style={{
                    visibility: showColorWheel ? 'visible' : 'hidden',
                  }}
                  value={formatState.colour}
                  ref={colorInputRef}
                  type="color"
                  className={styles.colorWheelInput}
                  onChange={updateColour}
                />
              </div>
            </>
          }
          onClick={toggleColorWheel}
        />
        <div className={styles.divider}></div>
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-bold" />}
          onClick={toggleBold}
          isSelected={formatState.bold}
        />
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-italic" />}
          onClick={toggleItalic}
          isSelected={formatState.italic}
        />
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-underline" />}
          onClick={toggleUnderline}
          isSelected={formatState.underline}
        />
        <div className={styles.divider}></div>
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-align-left" />}
          onClick={alignLeft}
          isSelected={formatState.alignment === 'left'}
        />
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-align-centre" />}
          onClick={alignCenter}
          isSelected={formatState.alignment === 'center'}
        />
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-align-right" />}
          onClick={alignRight}
          isSelected={formatState.alignment === 'right'}
        />
        <div className={styles.divider}></div>
        <SegmentedButton
          end
          buttonStyle={styles.button}
          icon={<Icon name="sprite/bin" size={24} />}
          onClick={removeText}
        />
      </div>
    </div>
  );
};
