import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addOrMoveBoardToHub } from '../../../../../api/boards';
import {
  ALL_BOARDS_QUERY,
  ALL_HUBS_QUERY,
  BOARD_QUERY,
  BOARDS_TREE_QUERY,
  MEMBERS_INVITATION_QUERY,
} from '../../../../../shared/constants';

export interface AddBoardToHub {
  hub?: string;
  boards: string[];
  boardName?: string;
}

interface UseAddBoardToHubProps {
  handleSuccessAddBoardToHub: () => void;
}

export const useAddBoardToHub = ({
  handleSuccessAddBoardToHub,
}: UseAddBoardToHubProps) => {
  const queryClient = useQueryClient();

  const { mutate: addBoardToHub } = useMutation({
    mutationFn: ({ hub, boards }: AddBoardToHub) =>
      addOrMoveBoardToHub({ hub, boards }),
    onSuccess: (data, variables) => {
      if (handleSuccessAddBoardToHub) handleSuccessAddBoardToHub();
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARDS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_HUBS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: variables.boards[0] }],
      });
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_INVITATION_QUERY, { board_id: variables.boards[0] }],
      });

      startedSnack({
        label: 'Added board to hub',
        close: true,
      });
    },
    onError: (error, variables) => {
      startedSnack({
        label: "Couldn't add board to hub",
        action: {
          label: 'Try again',
          action: () => {
            addBoardToHub({
              hub: variables.hub,
              boards: variables.boards,
            });
          },
        },
        close: true,
      });
    },
  });

  // const { mutate: undoBoardToHub } = useMutation({
  //   mutationFn: ({ hub, boards }: RemoveBoardToHub) =>
  //     removeBoardFromHub({ hub, boards }),
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({
  //       queryKey: [BOARDS_TREE_QUERY],
  //     });
  //     queryClient.invalidateQueries({
  //       queryKey: [ALL_BOARDS_QUERY],
  //     });
  //     queryClient.invalidateQueries({
  //       queryKey: [ALL_HUBS_QUERY],
  //     });
  //   },
  // });

  return { addBoardToHub };
};
