import { useUnit } from 'effector-react';
import { useInView } from 'react-intersection-observer';

import { SegmentedButton } from '@visualist/design-system/src/components/v2';

import { Spinner } from '@components/Spinner';

import {
  $isAllSelected,
  $isDesignSelected,
  $isImagesSelected,
  $isPaletteSelected,
  allFilesSelected,
  designsSelected,
  imagesSelected,
  palettesSelected,
} from '../../../pages/DocPage/model/files-filter';
import { All } from '../all';
import { Designs } from '../designs';
import { Images } from '../images';
import { Palettes } from '../palettes';

import styles from './styles.module.css';

type Props = {
  allBlockQuery: any;
  imageBlockQuery: any;
  designBlockQuery: any;
  paletteBlockQuery: any;
};

export const Files = ({
  allBlockQuery,
  imageBlockQuery,
  designBlockQuery,
  paletteBlockQuery,
}: Props) => {
  const isAllSelected = useUnit($isAllSelected);
  const isImagesSelected = useUnit($isImagesSelected);
  const isDesignSelected = useUnit($isDesignSelected);
  const isPaletteSelected = useUnit($isPaletteSelected);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const isFetching =
    allBlockQuery.query.isFetching ||
    imageBlockQuery.query.isFetching ||
    designBlockQuery.query.isFetching ||
    paletteBlockQuery.query.isFetching;

  return (
    <>
      <div className={styles.sheetHeader}>
        <SegmentedButton
          buttonStyle={styles.segmentedButton}
          onClick={() => allFilesSelected()}
          start
          label="All"
          isSelected={isAllSelected}
        />
        <SegmentedButton
          buttonStyle={styles.segmentedButton}
          onClick={() => imagesSelected()}
          label="Images"
          isSelected={isImagesSelected}
        />
        <SegmentedButton
          buttonStyle={styles.segmentedButton}
          onClick={() => designsSelected()}
          label="Designs"
          isSelected={isDesignSelected}
        />
        <SegmentedButton
          buttonStyle={styles.segmentedButton}
          onClick={() => palettesSelected()}
          end
          label="Palettes"
          isSelected={isPaletteSelected}
        />
      </div>
      <div className={styles.files}>
        {isAllSelected && <All inView={inView} allBlockQuery={allBlockQuery} />}
        {isImagesSelected && (
          <Images inView={inView} imageBlockQuery={imageBlockQuery} />
        )}
        {isDesignSelected && (
          <Designs inView={inView} designBlockQuery={designBlockQuery} />
        )}
        {isPaletteSelected && (
          <Palettes inView={inView} paletteBlockQuery={paletteBlockQuery} />
        )}
        <div />
        <div ref={ref} className={styles.spinnerContainer}>
          {isFetching && <Spinner />}
        </div>
      </div>
    </>
  );
};
