import { useState } from 'react';

import { DayPicker, getDefaultClassNames } from 'react-day-picker';

import 'react-day-picker/dist/style.css';
import styles from './styles.module.css';

type DatePickerProps = {
  selectedDate?: Date;
  onDateSelect?: (date: Date | undefined) => void;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  captionLayout?: 'dropdown' | 'label';
  fromYear?: number;
  toYear?: number;
};

export default function DatePicker({
  selectedDate,
  onDateSelect,
  weekStartsOn = 1,
  captionLayout = 'dropdown',
  fromYear = 1980,
  toYear = new Date().getFullYear() + 10,
}: DatePickerProps) {
  const [selected, setSelected] = useState<Date | undefined>(selectedDate);
  const [month, setMonth] = useState(selectedDate ?? new Date());
  const handleSelect = (date: Date | undefined) => {
    setSelected(date);
    onDateSelect?.(date);
  };
  const defaultClassNames = getDefaultClassNames();
  return (
    <div className={styles.datePickerContainer}>
      <DayPicker
        mode="single"
        selected={selected}
        onSelect={handleSelect}
        captionLayout={captionLayout}
        weekStartsOn={weekStartsOn}
        month={month}
        onMonthChange={setMonth}
        fromYear={fromYear}
        toYear={toYear}
        fixedWeeks
        classNames={{
          month_caption: `${defaultClassNames.month_caption} ${styles.month_caption}`,
          chevron: `${defaultClassNames.chevron} ${styles.chevron}`,
          weekday: `${defaultClassNames.weekday} ${styles.weekday}`,
          day: `${defaultClassNames.day} ${styles.day}`,
          today: `${defaultClassNames.today} ${styles.today}`,
          selected: `${defaultClassNames.selected} ${styles.selected}`,
          day_button: `${defaultClassNames.day_button} ${styles.day_button}`,
        }}
      />
    </div>
  );
}
