import cn from 'classnames';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { LookupKeyHubCredit } from '@api/billing';
import { openedHubCreditSheet } from '@pages/AccountPage/models';

import styles from './styles.module.css';

type Props = {
  bundleId: LookupKeyHubCredit;
  amount: number;
  price: number;
  savings: number;
  isDisabled: boolean;
  isHighlighted: boolean;
};

export const HubCredit = ({
  bundleId,
  amount,
  price,
  savings,
  isDisabled,
  isHighlighted,
}: Props) => {
  const openHubBundle = () =>
    openedHubCreditSheet({
      bundleId: bundleId,
    });

  return (
    <div
      className={cn(styles.container, {
        [styles.containerHighlighted]: isHighlighted,
      })}
    >
      <div className={styles.textContainer}>
        <TypographyPoppins type="display" size="M">
          <span
            className={cn(
              isHighlighted
                ? styles.creditAmountHighlighted
                : styles.creditAmount,
            )}
          >
            {amount}
          </span>
        </TypographyPoppins>
        <TypographyPoppins type="body" size="M">
          <span
            className={cn(
              isHighlighted ? styles.dealInfoHighlighted : styles.dealInfo,
            )}
          >
            credits for ${price}
          </span>
        </TypographyPoppins>
      </div>
      <Button
        type="outlined"
        label={`Save $${savings}`}
        isDisabled={isDisabled}
        onClick={openHubBundle}
      />
    </div>
  );
};
