import { useState } from 'react';

import cn from 'classnames';

import {
  Avatar,
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { SimpleAction } from '@api/actions';
import { useMutateAction } from '@pages/action-items/hooks/useAction';
import { useAssignee as usePossibleAssignee } from '@pages/action-items/hooks/useAssignee';
import { User } from '@src/AppContext';
import { UserCheckSelectSearch } from '@src/entities/search-modal/user-search/index';

import styles from './styles.module.css';

export const ActionItemAssignee = ({
  assignees,
  action,
}: {
  assignees: Array<User> | null;
  action: SimpleAction;
}) => {
  const [loadAssigneeData, setLoadAssigneeData] = useState(false);
  const others = assignees === null ? false : assignees.length > 2;

  const { mutateAction } = useMutateAction(action.id);

  const loadAssignees = () => setLoadAssigneeData(true);

  if (!assignees || assignees.length === 0) {
    return (
      <div
        className={styles.actionItemAssignee}
        onMouseEnter={loadAssignees}
        onTouchStart={loadAssignees}
        onClick={loadAssignees}
      >
        <AssigneeSearch
          action={action}
          enableFetchingAssignees={loadAssigneeData}
        >
          <IconButton
            type="unfilled"
            icon={<Icon name="sprite/plus" color="var(--color-secondary-70)" />}
            className={styles.addAssigneeBtn}
            onClick={(e) => e.stopPropagation()}
          />
        </AssigneeSearch>
      </div>
    );
  }

  return (
    <AssigneeSearch action={action} enableFetchingAssignees={loadAssigneeData}>
      <div
        className={styles.actionItemAssignee}
        onMouseEnter={loadAssignees}
        onTouchStart={loadAssignees}
        onClick={loadAssignees}
      >
        {assignees.slice(0, 2).map((assignee, idx) => {
          return (
            <div
              key={assignee.id}
              className={cn(styles.avatarAssignee, {
                [styles.assigneeAvatarPrimary]: idx === 0,
                [styles.assigneeAvatarSecondary]: idx === 1,
              })}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    action.assigned_to !== null &&
                    action.assigned_to.some((u) => u.id === assignee.id)
                  ) {
                    // Remove assignee
                    mutateAction.mutate({
                      assigned_to: action.assigned_to
                        .filter((u) => u.id !== assignee.id)
                        .map((u) => u.id),
                    });
                  } else {
                    // Add assignee
                    const assignedTo = action.assigned_to ?? [];
                    mutateAction.mutate({
                      assigned_to: [
                        ...assignedTo.map((u) => u.id),
                        assignee.id,
                      ],
                    });
                  }
                }}
                className={cn(styles.cancelAssignee, {
                  [styles.cancelAssigneePrimary]: idx === 0,
                  [styles.cancelAssigneeSecondary]: idx === 1,
                })}
              >
                <Icon name="sprite/x" size={12} />
              </button>
              <Avatar
                size={40}
                image={assignee.photo?.full_size}
                initials={`
                  ${assignee.first_name ?? undefined}
                  ${assignee.last_name ?? undefined}
                `}
              />
            </div>
          );
        })}
        {others && (
          <TypographyPoppins
            type="label"
            labelSize="XS"
            className={styles.otherAssigneesLabel}
          >
            + others
          </TypographyPoppins>
        )}
      </div>
    </AssigneeSearch>
  );
};

export const AssigneeSearch = ({
  action,
  enableFetchingAssignees = true,
  children,
  updateAssignees,
}: {
  action: SimpleAction;
  enableFetchingAssignees?: boolean;
  updateAssignees?: (assigned_to: User[]) => void;
  children:
    | React.ReactElement<{ onClick?: (e: React.MouseEvent) => void }>
    | Array<React.ReactElement<{ onClick?: (e: React.MouseEvent) => void }>>;
}) => {
  const { mutateAction } = useMutateAction(action.id);

  const { data, isLoading } = usePossibleAssignee(action.id, {
    enabled: enableFetchingAssignees,
  });

  return (
    <UserCheckSelectSearch
      onResultClick={(user) => {
        if (
          action.assigned_to !== null &&
          action.assigned_to.some((u) => u.id === user.id)
        ) {
          // Remove assignee
          if (updateAssignees) {
            updateAssignees(action.assigned_to.filter((u) => u.id !== user.id));
          } else {
            mutateAction.mutate({
              assigned_to: action.assigned_to
                .filter((u) => u.id !== user.id)
                .map((u) => u.id),
            });
          }
        } else {
          // Add assignee
          const assignedTo = action.assigned_to ?? [];
          if (updateAssignees) {
            updateAssignees([...assignedTo, user]);
          } else {
            mutateAction.mutate({
              assigned_to: [...assignedTo.map((u) => u.id), user.id],
            });
          }
        }
      }}
      isLoading={isLoading}
      selectedAssignees={action.assigned_to}
      users={data}
    >
      {children}
    </UserCheckSelectSearch>
  );
};
