import cn from 'classnames';

import TypographyPoppins from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

export interface AvatarProps {
  image?: string;
  initials?: string;
  email?: string;
  size: number | string;
  isDisabled?: boolean;
}

export const Avatar = ({
  image,
  initials,
  size,
  isDisabled,
  email,
}: AvatarProps) => {
  const parseString = (string?: string, email?: string) => {
    if (!string && !email) return '';

    if (email && (!string || (string.length === 1 && string.includes(' '))))
      return email.slice(0, 2).toUpperCase();

    if (!string) return '';

    const words = string.trim().split(' ');

    let firstInitial = '';
    let lastInitial = '';

    if (words.length === 1) {
      firstInitial = words[0].charAt(0).toUpperCase();

      return firstInitial;
    } else {
      firstInitial = words[0].charAt(0).toUpperCase();
      const lastIndex = words.length - 1;
      lastInitial = words[lastIndex].charAt(0).toUpperCase();

      return `${firstInitial}${lastInitial}`;
    }
  };

  const displayInitials = parseString(initials, email);

  return (
    <>
      {image ? (
        <img
          src={image}
          className={cn(styles.image, {
            [styles.disabledImage]: isDisabled,
          })}
          style={{
            height: size,
            width: size,
            minWidth: size,
          }}
        />
      ) : (
        <div
          className={cn(styles.initials, {
            [styles.disabledInitials]: isDisabled,
          })}
          style={{
            height: size,
            width: size,
            minWidth: size,
          }}
        >
          <TypographyPoppins
            type="label"
            labelSize={size === '20px' || size === 20 ? 'XS' : 'M'}
            className={cn({
              [styles.disabledLabel]: isDisabled,
            })}
          >
            {displayInitials}
          </TypographyPoppins>
        </div>
      )}
    </>
  );
};
