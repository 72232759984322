import React, { useRef, useState } from 'react';

import cx from 'classnames';
import { usePopper } from 'react-popper';

import * as PopperJS from '@popperjs/core';

import { ShiftIcon } from '../Icons';

import styles from './Shortcut.module.css';

export interface ShortcutProps {
  description: string;
  shortcut?: string[];
  placement?: PopperJS.Placement;
  variant?: 'light' | 'dark';
  children: React.ReactNode;
}

const shortcutIcons: { [key: string]: JSX.Element } = {
  Shift: <ShiftIcon size={16} className={cx(styles.icon)} />,
};

const Shortcut: React.FC<ShortcutProps> = ({
  description,
  shortcut,
  placement = 'top',
  variant = 'light',
  children,
}) => {
  const [popperTimeout, setPopperTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );
  const referenceElement = useRef<HTMLDivElement | null>(null);
  const [showPopper, setShowPopper] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles: popperStyles, attributes } = usePopper(
    referenceElement.current,
    popperElement,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    },
  );

  const replaceShortcut = (keys: string[]) => {
    return keys
      .map<JSX.Element | string>((key) => shortcutIcons[key] ?? key)
      .reduce(
        (accumulator, key) =>
          accumulator === '' ? (
            <>{key}</>
          ) : (
            <>
              {accumulator}
              <span className={cx(styles.plus)}>+</span>
              {key}
            </>
          ),
        '',
      );
  };

  const displayShortcut = shortcut ? replaceShortcut(shortcut) : undefined;

  const triggerShowPopper = () => {
    if (description) {
      setPopperTimeout(
        setTimeout(() => {
          setShowPopper(true);
        }, 1000),
      );
    }
  };

  const triggerHidePopper = () => {
    if (description && showPopper) {
      setShowPopper(false);
    }

    if (popperTimeout !== null) {
      clearInterval(popperTimeout);
    }
  };

  if (showPopper && referenceElement?.current?.matches(':hover') === false) {
    triggerHidePopper();
  }

  return (
    <>
      <div
        ref={referenceElement}
        onPointerEnter={triggerShowPopper}
        onPointerLeave={triggerHidePopper}
      >
        {children}
      </div>
      {showPopper && (
        <div
          ref={setPopperElement}
          style={popperStyles.popper}
          className={cx(styles.tooltip, styles[variant])}
          {...attributes.popper}
        >
          <span>{description}</span>
          {displayShortcut && (
            <span className={cx(styles.shortcut)}>{displayShortcut}</span>
          )}
        </div>
      )}
    </>
  );
};

export default Shortcut;
