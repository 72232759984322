import React, { ReactNode } from 'react';

import cn from 'classnames';

import TypographyPoppins, {
  LabelSize,
} from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

export type ButtonType =
  | 'filled'
  | 'outlined'
  | 'ghost'
  | 'text'
  | 'inverse-primary';

type Props = {
  type: ButtonType;
  label: string;
  isDisabled?: boolean;
  buttonStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  stopPropagation?: boolean;
  icon?: ReactNode;
  className?: string;
  labelSize?: LabelSize;
  autoFocus?: boolean;
  trailingIcon?: ReactNode;
  action?: () => void;
  tabIndex?: number;
};

export const Button = ({
  type,
  label,
  isDisabled,
  style,
  buttonStyle,
  onClick,
  stopPropagation,
  icon,
  className,
  labelSize,
  autoFocus,
  trailingIcon,
  action,
  tabIndex,
}: Props) => {
  const onClickHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (stopPropagation) {
      e.stopPropagation();
    }

    onClick(e);
  };

  return (
    <button
      autoFocus={autoFocus}
      disabled={isDisabled}
      onClick={onClickHandler}
      className={cn(styles.container, className, {
        [styles.solid]: type === 'filled',
        [styles.solidDisabled]: isDisabled && type === 'filled',
        [styles.outlined]: type === 'outlined',
        [styles.outlineDisabled]: isDisabled && type === 'outlined',
        [styles.ghost]: type === 'ghost',
        [styles.ghostDisabled]: isDisabled && type === 'ghost',
        [styles.text]: type === 'text',
        [styles.inversePrimary]: type === 'inverse-primary',
        [styles.inversePrimaryDisabled]:
          isDisabled && type === 'inverse-primary',
        [styles.containerWithIcon]: icon,
      })}
      style={buttonStyle}
      tabIndex={tabIndex}
    >
      {icon && (
        <span
          className={cn(styles.icon, {
            [styles.iconFilled]: type === 'filled',
            [styles.iconOutline]: type === 'outlined',
            [styles.iconGhost]: type === 'ghost',
          })}
        >
          {icon}
        </span>
      )}
      {label ? (
        <TypographyPoppins type="label" labelSize={labelSize}>
          <span style={style} className={styles.label}>
            {label}
          </span>
        </TypographyPoppins>
      ) : null}
      {trailingIcon &&
        (isDisabled ? (
          <span className={styles.trailingIconDisabled}>{trailingIcon}</span>
        ) : (
          <button
            className={styles.trailingIcon}
            onClick={(e) => {
              e.stopPropagation();
              if (action) {
                action();
              }
            }}
          >
            {trailingIcon}
          </button>
        ))}
    </button>
  );
};
