import { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { parseFailureReason, SnackError } from './types';
import {
  CHECKOUT_FAILURE,
  generateCurrentPathURL,
  // readHubCreditCheckoutBundleId,
  readValueFromLocalStorageAndDelete,
  removeStatusFromURL,
} from './utils';
import { CHECKOUT_SUCCESS } from './utils';

type Props = {
  handleSnackError?: (e: SnackError, s?: string) => void;
  isSharePage?: boolean;
};

/**
 * This hook is used to show a snackbar on successful checkout by reading from local storage.
 * The snack bar component works like any other imported at the root. The particular part of this that can get a bit confusing is error states
  for billing failure. When we are redirected to stripe for anything we need a failure snack if it fails. Since the failure snack has a try again button
  we store the type of failure in local storage, and on mount we check if we are on /failed, if so load the value and send a snack based on that failure.
 * 
 */

export const useSnack = ({ handleSnackError, isSharePage }: Props) => {
  if (isSharePage) return;

  const history = useHistory();
  const { pathname } = useLocation();

  // Check if current url has a success or failure path
  const hasSuccessOrFailure =
    pathname.includes('success') || pathname.includes('failed');
  const isSuccess = hasSuccessOrFailure
    ? pathname.includes('success')
    : undefined;

  // const { syncHubCreditSuccessMutation, syncHubCreditFailureMutation } =
  //   useHubCredits();

  useEffect(() => {
    if (typeof isSuccess === 'undefined') return;
    try {
      if (isSuccess) {
        // show success snackbar on successful checkout
        const snackText = readValueFromLocalStorageAndDelete(CHECKOUT_SUCCESS);
        // Read value from failure store to empty it.
        readValueFromLocalStorageAndDelete(CHECKOUT_FAILURE);
        if (!snackText) return;
        startedSnack({
          label: snackText.replaceAll('"', ''),
          close: true,
        });
      } else {
        // show error snackbar on failed checkout
        if (!handleSnackError) return;

        const unsafe_failureReason =
          readValueFromLocalStorageAndDelete(CHECKOUT_FAILURE) || '';
        const failureReason = parseFailureReason(
          unsafe_failureReason.replaceAll('"', ''),
        );

        // const hubBundleId = readHubCreditCheckoutBundleId();

        if (!failureReason) return;

        // startedSnack({
        //   label: 'Oops, something went wrong',
        //   action: {
        //     label: 'Try again',
        //     action: () =>
        //       handleSnackError(failureReason, hubBundleId || undefined),
        //   },
        //   close: true,
        // });
      }
    } finally {
      history.replace(removeStatusFromURL(generateCurrentPathURL()));
    }
  }, [isSuccess]);
};
