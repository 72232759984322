import { getColourPaletteFromBlock } from '@api/blocks';
import { COLOURS_OF_BLOCK_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

/**
 * @description Hook for fetching the colour palette of a block
 * @param blockId - The ID of the block to fetch the colour palette of
 * @returns The colour palette of the block
 */

export const useColourQuery = (blockId: string) => {
  return useQuery({
    queryKey: [COLOURS_OF_BLOCK_QUERY, blockId],
    queryFn: () => getColourPaletteFromBlock(blockId),
    enabled: !!blockId,
  });
};
