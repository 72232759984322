import { ComponentProps, forwardRef, PropsWithChildren } from 'react';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { OnboardingContent } from '@src/AppContext';

import styles from './styles.module.css';

export const OnboardingTooltip = forwardRef<
  HTMLDivElement,
  PropsWithChildren<
    ComponentProps<'div'> & {
      actionForward: {
        onClick: () => void;
        label: string;
      };
      onClose: () => void;
      title: string;
    }
  >
>(({ actionForward, title, children, onClose, ...rest }, ref) => {
  return (
    <div ref={ref} className={styles.container} {...rest}>
      <div className={styles.header}>
        <TypographyPoppins type="title" titleSize="S" className={styles.title}>
          {title}
        </TypographyPoppins>
        <button className={styles.closeButton} onClick={onClose}>
          <Icon name="sprite/x" size={24} />
        </button>
      </div>
      <div className={styles.body}>{children}</div>
      <div className={styles.footer}>
        <Button
          type="ghost"
          label={actionForward.label}
          onClick={actionForward.onClick}
        />
      </div>
    </div>
  );
});

export const onboardingTooltipContentMapper = (
  content: OnboardingContent,
  hideLast: boolean,
) => {
  return content.map((item, idx, arr) => {
    if (item.type === 'text') {
      if (hideLast && idx === arr.length - 1) return null;
      return (
        <TypographyPoppins
          key={`text-${item.content}`}
          type="body"
          bodySize="M"
          className={styles.text}
        >
          {item.content}
        </TypographyPoppins>
      );
    } else if (item.type === 'image') {
      return <img key={`image-${item.href}`} src={item.href} alt="image" />;
    } else if (item.type === 'video') {
      return (
        <video
          key={`video-${item.href}`}
          className={styles.video}
          src={item.href}
          width="240px"
          // height="160px"
          controls={false}
          loop
          autoPlay
        ></video>
      );
    }
  });
};
