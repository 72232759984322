// New stickies api file. Migrate other sticky api's here

import { api } from '@api/index';

import { StickyAction } from './types';

export const createStickyAction = async ({
  stickyId,
  status,
  isReply = false,
}: {
  stickyId: string;
  status: 'open' | 'closed';
  isReply?: boolean;
}) => {
  const { data } = await api.post('/actions/sticky/', {
    sticky: stickyId,
    status,
    sticky_type: isReply ? 'sticky_reply' : 'sticky',
  });

  return data;
};

export const updateStickyAction = async ({
  actionId,
  payload,
}: {
  actionId: string;
  payload: Partial<Pick<StickyAction, 'status' | 'assigned_to'>>;
}) => {
  const { data } = await api.patch(`/actions/sticky/${actionId}/`, payload);
  return data;
};

export const deleteStickyAction = async (actionId: string) => {
  await api.delete(`/actions/sticky/${actionId}/`);
};

export const undoDeleteStickyAction = async (actionId: string) => {
  await api.post(`/actions/sticky/${actionId}/undo_delete/`);
};
