import { fetchMessage, fetchMessageThread, Thread } from '@api/messaging';
import { messagesKeys } from '@src/shared/constants/query-keys';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

export const useMessage = ({
  hubId,
  threadId,
}: {
  hubId: string;
  threadId: string;
}) => {
  const messageQuery = useQuery({
    queryKey: messagesKeys.message(hubId, threadId),
    queryFn: () => fetchMessage(hubId, threadId),
  });

  // Fetch messages
  const threadQuery = useInfiniteQuery({
    queryKey: messagesKeys.thread(hubId, threadId),
    queryFn: ({ pageParam = '0' }) =>
      fetchMessageThread(hubId, threadId, pageParam, '40'),
    initialPageParam: '0',
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const threads = threadQuery.data?.pages.reduce(
    (acc, item) => [...acc, ...item.results],
    [] as Thread[],
  );

  return {
    messageQuery,
    threadQuery,
    threads,
  };
};
