import { Dispatch } from 'react';

import { useUnit } from 'effector-react';

import {
  AssistChip,
  SideSheet,
} from '@visualist/design-system/src/components/v2';

import { createHubShare, deleteHubShare } from '@api/hubs';
import { useHubCredits } from '@pages/AccountPage/hooks/useHubCredits';
import { openedHubCreditSheet } from '@pages/AccountPage/models';
import { HubShare } from '@pages/HubPage';
import { useAppData } from '@src/AppContext';
import { deleteHubModalOpened } from '@src/entities/delete-modals/modal';
import { showingDemoWarning } from '@src/widgets/onboarding/model';

import { DateOfBilling } from '../Settings';
import { Links } from '../ShareSheet/Links';
import { $isSettingsSheetOpen, settingsSheetClosed } from './model';

import styles from './styles.module.css';

type SettingSheetProps = {
  hubShare: HubShare | null;
  setHubShare: Dispatch<HubShare | null>;
  hubId: string;
  hubName: string;
  isWithinThreeDays: boolean;
  getDateOfBilling: (isSettingsSheet?: boolean) => string | null;
  isOwner?: boolean;
};

export const SettingSheet = ({
  hubShare,
  setHubShare,
  hubId,
  isWithinThreeDays,
  getDateOfBilling,
  isOwner,
  hubName,
}: SettingSheetProps) => {
  const isSettingsSheetOpen = useUnit($isSettingsSheetOpen);

  const { hubProductsQuery } = useHubCredits();
  const { user } = useAppData();

  const handlePublishChange = async (
    published: boolean,
    allow_comments = true,
  ) => {
    if (user.meta?.onboarding?.demoMode) {
      showingDemoWarning();
      return;
    }
    if (published) {
      const share = await createHubShare(hubId, allow_comments);
      setHubShare(share);
    } else if (hubShare) {
      await deleteHubShare(hubShare.id);
      setHubShare(null);
    }
  };

  return (
    <SideSheet
      type="large"
      showSheet={isSettingsSheetOpen}
      handleBackdropClick={settingsSheetClosed}
      closeSideSheet={settingsSheetClosed}
    >
      <SideSheet.Heading closeSideSheet={settingsSheetClosed}>
        Settings
      </SideSheet.Heading>
      <SideSheet.Body>
        <SideSheet.ContentContainer>
          {isOwner && (
            <>
              <DateOfBilling
                className={styles.settings}
                getDateOfBilling={() => getDateOfBilling(true)}
                isSettingsSheet
              />
              {hubProductsQuery.data
                ?.filter((product) => product.lookup_key === 'credit_pack_500')
                .map((product) => {
                  return (
                    <AssistChip
                      key={product.id}
                      className={styles.credits}
                      style="outlined"
                      onClick={() => {
                        settingsSheetClosed();
                        openedHubCreditSheet({
                          bundleId: product.lookup_key,
                        });
                      }}
                    >
                      Buy credits to save up to 33%
                    </AssistChip>
                  );
                })}
            </>
          )}
          <Links
            hubShare={hubShare}
            handlePublishChange={handlePublishChange}
          />
        </SideSheet.ContentContainer>
      </SideSheet.Body>
      <SideSheet.BottomControls
        hideCancel
        mainType="outlined"
        primaryLabel="Delete hub"
        mainClick={() => {
          deleteHubModalOpened({
            hubId: hubId,
            name: hubName,
          });
        }}
        isDisabled={isWithinThreeDays}
      />
    </SideSheet>
  );
};
