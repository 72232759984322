import React from 'react';

import cn from 'classnames';

import { PhotoType } from '../../types';
import PhotoAvatar from './Photo';

import styles from './UserIcon.module.css';

interface Props {
  firstName: string | null;
  lastName: string | null;
  photo?: PhotoType;
  size: number;
  fontSize?: number;
}

const UserIconBase = ({
  firstName = ' ',
  lastName = ' ',
  photo,
  size,
  fontSize,
}: Props): JSX.Element => {
  if (photo?.full_size) {
    return <PhotoAvatar photo={photo.full_size} size={size} />;
  }

  if (firstName || lastName) {
    return (
      <div
        className={cn(styles.roundFlex, styles.inner)}
        style={{ width: size, height: size, fontSize }}
      >
        {firstName !== null ? firstName.substring(0, 1) : null}
        {lastName !== null ? lastName?.substring(0, 1) : null}
      </div>
    );
  }

  return (
    <div
      className={cn(styles.roundFlex, styles.inner, styles.blank)}
      style={{ width: size, height: size, fontSize }}
    ></div>
  );
};

export default UserIconBase;
