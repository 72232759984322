import { useHistory } from 'react-router';

import { REDIRECT_TO_HUB_OR_BOARD_NEW_USER } from '@src/shared/constants/variables-local-storage';
import { setCookie } from '@src/shared/utils';
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  fetchSignUp,
  FetchSignUpResponse,
  FormSignUp,
} from '../../../../api/account';

export const useCheckRegistrationCode = ({
  onSuccess,
  ...options
}: MutateOptions<FetchSignUpResponse, any, FormSignUp>) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate: registerUser, ...mutateStates } = useMutation({
    mutationFn: (values: FormSignUp) => fetchSignUp(values),
    ...options,
    onSuccess: (...args) => {
      const [response] = args;
      setCookie('access_token', response.data.access_token);
      setCookie('refresh_token', response.data.refresh_token);
      queryClient.invalidateQueries({
        queryKey: ['my-user'],
      });

      const redirect = localStorage.getItem(REDIRECT_TO_HUB_OR_BOARD_NEW_USER);

      if (redirect) {
        window.location.href = redirect;
        localStorage.removeItem(REDIRECT_TO_HUB_OR_BOARD_NEW_USER);
      } else {
        history.push('/home?first=true');
      }
      if (onSuccess) onSuccess(...args);
    },
  });
  return { registerUser, ...mutateStates };
};
