import { SyntheticEvent, useEffect, useState } from 'react';

import { shuffle } from 'lodash';
import Masonry from 'react-masonry-css';

import {
  Checkbox,
  SideSheet,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { SelectedImageDetails } from '@components/LayoutGrid';
import { SelectionToolbar } from '@src/entities/selection-toolbar';

import styles from './styles.module.css';

const thumbnailsBreakpoints = {
  default: 3,
};

type Props = {
  isOpen: boolean;
  items: SelectedImageDetails[];
  close: () => void;
  download: (e: SyntheticEvent) => void;
  idDeleted: (id: string) => void;
  createDesign: () => void;
};

export const ViewThumbnailsSheet = ({
  isOpen,
  close,
  items,
  download,
  idDeleted,
  createDesign,
}: Props) => {
  const [list, setList] = useState<SelectedImageDetails[]>(() => items);

  useEffect(() => {
    setList(items);
  }, [items]);
  //TODO: I use the delete function with old code, which requires creating a local copy of the array (list), a separate function (idDeleted) to delete the selected file, all this logic requires refactoring and significant simplification
  const handleDelete = (id: string) => {
    const item = list.filter((item) => item.id !== id);
    setList(item);
    idDeleted(id);
  };

  const shuffleList = () => {
    const shuffledImages = shuffle(list);
    setList(shuffledImages);
  };

  return (
    <SideSheet
      className={styles.sideSheetContainer}
      showSheet={isOpen}
      closeSideSheet={close}
      handleBackdropClick={close}
    >
      <SideSheet.Heading
        className={styles.headingContainer}
        closeSideSheet={close}
      >
        {list.length} selected
      </SideSheet.Heading>
      <SelectionToolbar
        items={list}
        download={download}
        shuffleList={shuffleList}
        createDesign={createDesign}
        isSideSheet={true}
      />
      <SideSheet.Body className={styles.bodyContainer}>
        <SideSheet.ContentContainer className={styles.contentContainer}>
          <Masonry
            breakpointCols={thumbnailsBreakpoints}
            className={styles.thumbnails}
            columnClassName={styles.thumbnailsColumn}
          >
            {list.map((file) => (
              <li
                key={file.imageInfo.id}
                style={{ listStyleType: 'none', position: 'relative' }}
              >
                <Checkbox
                  style={{
                    position: 'absolute',
                    width: '16px',
                    height: '16px',
                  }}
                  classNameOuterRadio={styles.checkboxOuterRadio}
                  icon={
                    <Icon
                      name="sprite/checkbox-filled"
                      color="#99461C"
                      className={styles.checkbox}
                    />
                  }
                  onClick={() => handleDelete(file.imageInfo.id)}
                  isSelected={true}
                />
                <img
                  className={styles.thumbnail}
                  src={file.imageInfo.file.full_size}
                  alt="thumbnail"
                />
              </li>
            ))}
          </Masonry>
        </SideSheet.ContentContainer>
      </SideSheet.Body>
    </SideSheet>
  );
};
