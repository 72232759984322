import { AnimatePresence, motion } from 'framer-motion';

import {
  AssistChip,
  Switch,
  Team,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { HubShare } from '@pages/HubPage';

import styles from './styles.module.css';

interface PublicLinkProps {
  handlePublishChange: (
    published: boolean,
    allow_comments?: boolean,
  ) => Promise<void>;
  hubShare: HubShare | null;
}

export const Links = ({ handlePublishChange, hubShare }: PublicLinkProps) => {
  const copyPublicLink = async () => {
    try {
      if (hubShare) {
        await navigator.clipboard.writeText(hubShare.link);
        startedSnack({
          label: 'Copied link',
          close: true,
        });
      }
    } catch (error) {
      startedSnack({
        label: "Oops, could'nt copy this",
        action: {
          label: 'Try again',
          action: () => {
            copyPublicLink();
          },
        },
        close: true,
      });
    }
  };

  const copyPrivateLink = async () => {
    const url = new URL(document.location.href);

    try {
      await navigator.clipboard.writeText(url.href);
      startedSnack({
        label: 'Copied link',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link",
        action: {
          label: 'Try again',
          action: () => {
            copyPrivateLink();
          },
        },
        close: true,
      });
    }
  };

  return (
    <div className={styles.copyPublicLinkContainer}>
      <div className={styles.row}>
        <div className={styles.publicTitleContainer}>
          <Icon className={styles.icon} name="sprite/public" />
          <TypographyPoppins type="body" bodySize="M">
            <span className={styles.publicLabel}>Share link with anyone</span>
          </TypographyPoppins>
          <Tooltip
            style={{ minWidth: '222px', left: '-38px' }}
            parameter={{
              type: 'plain',
              description: 'Anyone on the internet with this link can view',
              hasVisualBoundary: false,
              position: 'bottom',
            }}
          >
            <Icon className={styles.icon} name="sprite/information" />
          </Tooltip>
        </div>
        <Switch
          onValueChange={handlePublishChange}
          value={hubShare?.link ? true : false}
        />
      </div>
      <motion.div
        className={styles.row}
        animate={{ height: hubShare?.link ? 'auto' : 0 }}
        exit={{ height: 0 }}
      >
        <AnimatePresence>
          {hubShare?.link ? (
            <AssistChip onClick={copyPublicLink}>Copy public link</AssistChip>
          ) : null}
        </AnimatePresence>
      </motion.div>

      <div className={styles.row}>
        <div className={styles.publicTitleContainer}>
          <Team className={styles.icon} fill="none" />
          <TypographyPoppins type="body" bodySize="M">
            <span className={styles.publicLabel}>
              Share link with invited guests
            </span>
          </TypographyPoppins>
          <Tooltip
            style={{ minWidth: '246px', left: '-102px' }}
            parameter={{
              type: 'plain',
              description:
                'Only those invited can see this page when they log in',
              hasVisualBoundary: false,
              position: 'bottom',
            }}
          >
            <Icon className={styles.icon} name="sprite/information" />
          </Tooltip>
        </div>
      </div>
      <div className={styles.row}>
        <AssistChip onClick={copyPrivateLink}>Copy private link</AssistChip>
      </div>
    </div>
  );
};
