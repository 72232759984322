import React, { MouseEvent, useMemo } from 'react';

import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router';

import { FileSize, FileType, ImageCard } from '@visualist/design-system';
import {
  AssistChip,
  Button,
  DesignColored,
  EmptyState,
  ImageColored,
  PaletteColored,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { createNewSet, EditFile, editFile } from '@api/blocks';
import { Block } from '@api/services';
import { SkeletonCards } from '@components/SkeletonCards';
import { Spinner } from '@components/Spinner';
import { Plan } from '@pages/AccountPage/components/types';
import { fileCardIdSet } from '@pages/FileCard/model';
import {
  fileDeleteDialogOpened,
  fileIdSelected,
  fileTypeSelected,
} from '@pages/LibraryPage/model';
import { openedLibrary } from '@pages/StudioPage/model';
import {
  deleteDocModalOpened,
  docIdSelected,
} from '@src/entities/delete-modals/modal';
import { paletteDialogOpened } from '@src/entities/dialogs/palette/model';
import { useCreateDoc } from '@src/entities/doc/queries/useCreateDoc';
import { useDownloadDoc } from '@src/entities/doc/queries/useDownloadDoc';
import { useUpdateDoc } from '@src/entities/doc/queries/useUpdateDoc';
import {
  docShareSheetOpened,
  hubVerified,
  idSelected,
} from '@src/entities/share-sheet/doc/model/open-share-sheet';
import { IMAGE_QUERY, LIBRARY_QUERY } from '@src/shared/constants';
import { MEDIUM, SMALL } from '@src/shared/constants/breakpoints';
import { useMembership } from '@src/shared/queries/useMembershipPermissions';
import { copyFile } from '@src/shared/utils';
import { openChromeExtension } from '@src/shared/utils/files/open-chrome-extension';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Banner } from '..';

import styles from './styles.module.css';

type Title = 'Documents' | 'Images' | 'Designs' | 'Palettes';

interface FilesSectionProps {
  // listInfo: any;
  blocks: Array<Block> | undefined;
  isLoading: boolean;
  actions: any;
  history: any;
  selectedImages: any;
  onImageClick: (
    e: React.MouseEvent<HTMLElement>,
    id: string,
    imageRef: React.RefObject<HTMLElement>,
  ) => void;
  createBoard: any;
  anyImageActionOpen: any;
  setAnyImageActionOpen: any;
  hasPermissionEditing: any;
  isOwner: any;
  handleUpload: any;
  uploadButtonRef: any;
  uploadAction: any;
  size: FileSize;
  type: FileType;
  title: Title;
  plan?: Plan;
  selectAll?: boolean;
  hasNextPage: boolean;
  fetchNextPage: (() => void) | undefined;
  page?: 'board' | 'library';
  boardId?: string;
  downloadSelectedBlocksSingle?: (id: string) => Promise<any>;
}

export const FilesSection = ({
  // listInfo,
  blocks,
  isLoading,
  actions,
  history,
  selectedImages,
  onImageClick,
  createBoard,
  anyImageActionOpen,
  setAnyImageActionOpen,
  hasPermissionEditing,
  isOwner,
  handleUpload,
  uploadButtonRef,
  uploadAction,
  size,
  type,
  title,
  plan,
  selectAll,
  hasNextPage,
  fetchNextPage,
  page,
  boardId,
  downloadSelectedBlocksSingle,
}: FilesSectionProps) => {
  const [isCollapse, setIsCollapse] = React.useState(false);
  const [cardsRow, setCardsRow] = React.useState<number>(0);

  const { makeDoc } = useCreateDoc();

  const { isReady } = useMembership();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const isShowButton = blocks && cardsRow && blocks.length > cardsRow;

  React.useEffect(() => {
    if (hasNextPage && inView && fetchNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const { mutate: createNewSetMutation } = useMutation({
    mutationFn: () => {
      if (page === 'board' && boardId) {
        return createNewSet({ name: 'Untitled', boardId });
      } else {
        return createNewSet({ name: 'Untitled' });
      }
    },
    onSuccess: async ({ data }) => {
      history.push(`/studio/${data.id}`);
      setTimeout(() => {
        openedLibrary();
      }, 500);
    },
    onError: () => {
      startedSnack({
        label: "Couldn't create design",
        action: {
          label: 'Try again',
          action: () => {
            if (page === 'board' && boardId) {
              return createNewSet({ name: 'Untitled', boardId });
            } else {
              return createNewSet({ name: 'Untitled' });
            }
          },
        },
        close: true,
      });
    },
  });

  React.useEffect(() => {
    if (window.innerWidth >= MEDIUM) {
      setCardsRow(6);
    } else if (window.innerWidth >= SMALL && window.innerWidth <= MEDIUM) {
      setCardsRow(4);
    } else {
      setCardsRow(2);
    }
  }, [window.innerWidth]);

  const getTitle = (title: Title) => {
    switch (title) {
      case 'Documents':
        return 'New doc';

      case 'Designs':
        return 'New design';

      case 'Palettes':
        return 'Create palette';

      default:
        return 'Add image';
    }
  };

  const getIcon = (title: Title) => {
    switch (title) {
      case 'Documents':
        return <Icon name="sprite/doc-colored" className={styles.icon} />;

      case 'Designs':
        return <DesignColored className={styles.icon} fill="none" />;

      case 'Palettes':
        return <PaletteColored className={styles.icon} fill="none" />;

      default:
        return <ImageColored fill="none" className={styles.icon} />;
    }
  };

  const getAction = (title: Title) => {
    switch (title) {
      case 'Documents':
        return createNewDoc;

      case 'Designs':
        return addNewDesign;

      case 'Palettes':
        return paletteDialogOpened;

      default:
        return uploadAction;
    }
  };

  const addNewDesign = () => createNewSetMutation();

  const createNewDoc = () => {
    if (page === 'board') {
      makeDoc({ board: boardId });
    } else {
      makeDoc({});
    }
  };

  const skeletonCards = useMemo(() => {
    const number =
      (cardsRow && type === 'Image' && blocks?.length === 0) ||
      (cardsRow && type === 'Doc' && blocks?.length === 0) ||
      (cardsRow && type === 'Design' && blocks?.length === 0) ||
      (cardsRow && type === 'Palette' && blocks?.length === 0)
        ? cardsRow - 1
        : cardsRow;
    const skeletons = Array.from({ length: number }, (_, index) => index + 1);

    return skeletons.map((_, i) => <SkeletonCards key={i} type={type} />);
  }, [cardsRow, type, blocks?.length]);

  return (
    <>
      <header className={cn(styles.header)}>
        <div className={styles.sectionTitle}>
          {getIcon(title)}
          <TypographyPoppins type="title" size="L" className={styles.title}>
            {title}
          </TypographyPoppins>
        </div>
        <AssistChip
          leadingIcon
          icon={<Icon name="sprite/plus" />}
          onClick={getAction(title)}
          style="outlined"
        >
          {getTitle(title)}
        </AssistChip>
      </header>

      {type === 'Image' ? (
        <div className={styles.extensionDownloadBanner}>
          <TypographyPoppins type="body" size="M">
            <span className={styles.extensionText}>
              Download the Visualist browser extension to save images from the
              web.
            </span>
          </TypographyPoppins>
          <Button
            label="Download the extension"
            onClick={openChromeExtension}
            type="ghost"
          />
        </div>
      ) : null}

      {plan === 'starter' &&
        type === 'Doc' &&
        blocks &&
        blocks.length >= 7 &&
        blocks.length <= 10 && <Banner numberDocs={blocks?.length} />}

      {isShowButton && (
        <div className={styles.sectionViewControl}>
          <Button
            style={{ marginLeft: '8px' }}
            type="text"
            label={isCollapse ? 'Collapse' : 'See all'}
            icon={
              <Icon
                name="sprite/chevron-down"
                className={cn({ [styles.caretDownIcon]: isCollapse })}
              />
            }
            onClick={() => setIsCollapse(!isCollapse)}
          />
        </div>
      )}

      {type === 'Doc' && blocks?.length === 0 && !isLoading && plan && (
        <EmptyState
          icon={<Icon name="sprite/contract-soft-colored" size={80} />}
          content={`Create docs that make 
                    your ideas stand out`}
          label="Create a doc"
          action={createNewDoc}
        />
      )}

      {type === 'Image' && blocks?.length === 0 && !isLoading && (
        <EmptyState
          icon={<Icon name="sprite/image-cards-soft-colored" size={80} />}
          content={`This is looking rather empty. 
                     How about adding some 
                           images?`}
          label="Upload from device"
          action={uploadAction}
          secondaryLabel="Install browser extension"
          secondaryAction={openChromeExtension}
        />
      )}

      {type === 'Design' && blocks?.length === 0 && !isLoading && (
        <EmptyState
          icon={<Icon name="sprite/moodboard-soft-colored" size={80} />}
          content={`Craft moodboards and 
                    lookbooks that impress`}
          label="Create in Studio"
          action={addNewDesign}
        />
      )}

      {type === 'Palette' && blocks?.length === 0 && !isLoading && (
        <EmptyState
          icon={<Icon name="sprite/palette-soft-colored" size={80} />}
          content={`Time to add some color 
                    to this space`}
          label="Create palette"
          action={paletteDialogOpened}
        />
      )}

      <div
        className={cn(styles.filesList, {
          [styles.docsListPlaceholder]:
            title === 'Documents' && blocks && blocks.length === 0,
          [styles.imageListPlaceholder]:
            title === 'Images' && blocks && blocks.length === 0,
          [styles.designsListPlaceholder]:
            title === 'Designs' && blocks && blocks.length === 0,
          [styles.paletteListPlaceholder]:
            title === 'Palettes' && blocks && blocks.length === 0,
          [styles.filesView]: isCollapse,
        })}
      >
        {isLoading || !isReady ? (
          skeletonCards
        ) : (
          <ImageCards
            isLoading={isLoading}
            blocks={blocks}
            history={history}
            actions={actions}
            selectedImages={selectedImages}
            onImageClick={onImageClick}
            createBoard={createBoard}
            anyImageActionOpen={anyImageActionOpen}
            setAnyImageActionOpen={setAnyImageActionOpen}
            hasPermissionEditing={hasPermissionEditing}
            size={size}
            type={type}
            selectAll={selectAll}
            isOwner={isOwner}
            cardsRow={cardsRow}
            isCollapse={isCollapse}
            page={page}
            boardId={boardId}
            downloadSelectedBlocksSingle={downloadSelectedBlocksSingle}
          />
        )}
      </div>

      {hasNextPage && isCollapse ? (
        <div ref={ref} className={styles.spinnerContainer}>
          <Spinner />
        </div>
      ) : null}

      <input
        type="file"
        multiple
        ref={uploadButtonRef}
        className={styles.uploadInput}
        accept="image/png, image/jpeg"
        onChange={handleUpload}
      />
    </>
  );
};

type ImageProps = {
  history: any;
  blocks: Block[] | undefined;
  isLoading: boolean;
  selectedImages: any;
  onImageClick: (
    e: React.MouseEvent<HTMLElement>,
    id: string,
    imageRef: React.RefObject<HTMLElement>,
  ) => void;
  isOwner: any;
  isCollapse: boolean;
  cardsRow: number | undefined;
  actions: boolean;
  createBoard?: boolean;
  anyImageActionOpen: boolean;
  setAnyImageActionOpen: React.Dispatch<boolean>;
  hasPermissionEditing?: boolean;
  size: FileSize;
  type: FileType;
  selectAll?: boolean;
  page?: 'board' | 'library';
  boardId?: string;
  downloadSelectedBlocksSingle?: (id: string) => Promise<any>;
};

const ImageCards = ({
  history,
  blocks,
  isLoading,
  actions,
  selectedImages,
  onImageClick,
  createBoard,
  anyImageActionOpen,
  setAnyImageActionOpen,
  hasPermissionEditing,
  size,
  type,
  selectAll,
  // isOwner,
  isCollapse,
  cardsRow,
  page,
  boardId,
  downloadSelectedBlocksSingle,
}: ImageProps) => {
  const { search } = useLocation();
  const queryClient = useQueryClient();

  const { editDoc } = useUpdateDoc();
  const { downloadMutation } = useDownloadDoc();

  const { mutate: renameFile } = useMutation({
    mutationFn: ({ id, name }: EditFile) => editFile({ id, name }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [IMAGE_QUERY, { imageId: data.data.id }],
      });
    },
  });

  const openNewTab = (id: string, shareOnly?: boolean) => {
    if (!shareOnly && type !== 'Doc' && page !== 'board') {
      window.open(
        `${import.meta.env.VITE_BASE_URL}/library#/block/${id}`,
        '_blank',
      );
    } else if (type === 'Doc') {
      window.open(`${import.meta.env.VITE_BASE_URL}/d/${id}`, '_blank');
    } else if (!shareOnly && page === 'board') {
      window.open(
        `${import.meta.env.VITE_BASE_URL}/board/${boardId}#/block/${id}`,
        '_blank',
      );
    }
  };

  const renameDoc = ({ id, name }: { id: string; name: string }) =>
    editDoc({ docId: id, title: name });

  const downloadAsPDF = ({ id, name }: { id: string; name: string }) =>
    downloadMutation.mutate({ id, name });

  const removeDoc = (name: string, id: string) => {
    deleteDocModalOpened();
    docIdSelected(id);
  };

  const removeFile = (id: string[]) => {
    fileDeleteDialogOpened();
    fileIdSelected(id);
  };

  if (isLoading) return <p> Loading...</p>;

  if (!blocks) return null;

  const visibleCards = isCollapse ? blocks : blocks.slice(0, cardsRow);

  return (
    <>
      {visibleCards && visibleCards.length
        ? visibleCards.map((card, i) => (
            <ImageCard
              key={card.id}
              item={card}
              imageAction={() => {
                if (actions) {
                  history.push({
                    search,
                    hash: `#/block/${card.id}`,
                  });
                  fileCardIdSet(card.id);
                }
              }}
              selected={selectedImages[card.id]?.selected || false} //?
              currentCardId={selectedImages[card.id]?.id} //?
              selectImage={onImageClick} //?
              actions={actions} //?
              createBoard={createBoard} //?
              anyImageActionOpen={anyImageActionOpen} //?
              setAnyImageActionOpen={setAnyImageActionOpen} //?
              copyFile={copyFile}
              hasPermissionEditing={hasPermissionEditing}
              size={size}
              type={type}
              history={history}
              selectAll={selectAll}
              animationDelay={i * 0.01}
              openNewTab={openNewTab}
              downloadSelectedBlocksSingle={downloadSelectedBlocksSingle}
              removeDoc={removeDoc}
              removeFile={removeFile}
              renameFile={renameFile}
              renameDoc={renameDoc}
              fileTypeSelected={fileTypeSelected}
              downloadAsPDF={downloadAsPDF}
              docShareSheetOpened={docShareSheetOpened}
              idSelected={idSelected}
              hubVerified={hubVerified}
              baseURL={import.meta.env.VITE_BASE_URL}
            />
          ))
        : null}
    </>
  );
};
