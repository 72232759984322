import { SnackError } from './types';

export const CHECKOUT_SUCCESS = 'checkout_success';
export const CHECKOUT_FAILURE = 'checkout_failure';
export const HUB_CREDIT_CHECKOUT_FAILURE = 'hub_credit_checkout_failure';

export const saveCheckoutFailureToLocalStorage = (value: SnackError) => {
  saveValueTolocalStorage(CHECKOUT_FAILURE, value);
};

export const saveHubCreditCheckoutBundleId = (value: string) => {
  saveValueTolocalStorage(HUB_CREDIT_CHECKOUT_FAILURE, value);
};

export const readHubCreditCheckoutBundleId = () => {
  const s = readValueFromLocalStorageAndDelete(HUB_CREDIT_CHECKOUT_FAILURE);

  if (s) return s.replaceAll('"', '');
  return null;
};

export const generateCurrentPathURL = () => {
  const url = new URL(window.location.href);

  return removeTrailingSlash(`${url.pathname}`);
};

export const removeStatusFromURL = (url: string) => {
  const urlParts = removeTrailingSlash(url).split('/');

  // Check if the last part is "success" or "failed"
  const lastPart = urlParts[urlParts.length - 1];
  if (lastPart === 'success' || lastPart === 'failed') {
    // Remove the last part ("success" or "failed")
    urlParts.pop();
  }

  const modifiedURL = urlParts.join('/');

  return removeTrailingSlash(`${modifiedURL}`);
};

const removeTrailingSlash = (url: string) =>
  url.endsWith('/') ? url.slice(0, -1) : url;

export const formatAnnualPrice = (n: number, isAnnual: boolean) => {
  return isAnnual ? n / 100 / 12 : n / 100;
};

export const saveValueTolocalStorage = (key: string, value: string) => {
  // TODO error handling for if local storage cannot be set.
  localStorage.setItem(key, JSON.stringify(value));
};

export const readValueFromLocalStorageAndDelete = (key: string) => {
  const value = localStorage.getItem(key);
  localStorage.removeItem(key);
  return value ? value : null;
};
