import React from 'react';

import styles from './Photo.module.css';

export interface PhotoAvatarProps {
  size?: number;
  photo: string;
  name?: string;
}

const Photo = ({ size = 32, photo, name }: PhotoAvatarProps): JSX.Element => {
  return (
    <div className={styles.photoWrapper}>
      {photo ? (
        <img
          src={photo}
          className={styles.photo}
          style={{
            height: size,
            width: size,
          }}
          alt="user avatar"
        />
      ) : (
        <div
          className={styles.emptyPhoto}
          style={{
            height: size,
            width: size,
          }}
        >
          {name}
        </div>
      )}
    </div>
  );
};

export default Photo;
