import { fetchMessages } from '@api/messaging';
import { messagesKeys } from '@src/shared/constants/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useHubMessages = ({ id }: { id: string }) => {
  const messagesQuery = useQuery({
    queryKey: messagesKeys.hubMessages(id),
    queryFn: () => fetchMessages(id, '0', '3'),
  });

  return {
    messagesQuery,
  };
};
