import { useEffect } from 'react';

import { useUnit } from 'effector-react';

import { $palettes } from '@pages/DocPage/model/files-filter';

import { File } from '../file';

type Props = {
  inView: boolean;
  paletteBlockQuery: any;
};

export const Palettes = ({ inView, paletteBlockQuery }: Props) => {
  const palettes = useUnit($palettes);

  useEffect(() => {
    if (inView && paletteBlockQuery.query.hasNextPage) {
      paletteBlockQuery.query.fetchNextPage();
    }
  }, [inView, paletteBlockQuery.query.hasNextPage]);

  return (
    <>
      {palettes.map((b) => (
        <File
          key={b.id}
          url={b.file.full_size}
          thumbnailURL={b.file.thumbnail_330}
          fileId={b.id}
          fileType={b.block_type}
        />
      ))}
    </>
  );
};
