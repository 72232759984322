import { createEvent, createStore, sample } from 'effector';

import {
  $isStickyModeOn,
  $openPanel,
  changedStickyMode,
} from '@pages/FileCard/model';

import {
  themeCreationCompleted,
  themeNameCleared,
  themePlaceholderCleared,
} from './create-theme';
import { existingThemeUpdatingCompleted } from './edit-theme';

// Active dropdown
export const dropdownOpened = createEvent<number>();
export const dropdownClosed = createEvent();

export const $openDropdown = createStore<number | null>(null);

$openDropdown.on(dropdownOpened, (_, index) => index).reset(dropdownClosed);

// Active theme
export const themeSelected = createEvent<number>();
export const themeUnselected = createEvent();

export const $activeTheme = createStore<number | null>(null);

$activeTheme.on(themeSelected, (_, index) => index).reset(themeUnselected);

// Exit colors adjusting mode when the themes are collapsed
sample({
  clock: $openPanel,
  source: { openPanel: $openPanel, activeTheme: $activeTheme },
  filter: ({ openPanel, activeTheme }) =>
    activeTheme !== 0 && openPanel !== 'colour',
  target: themeUnselected,
});

//@ts-ignore
sample({
  clock: $activeTheme,
  source: { isStickyModeOn: $isStickyModeOn },
  filter: ({ isStickyModeOn }, activeTheme) =>
    isStickyModeOn && (activeTheme || activeTheme === 0),
  fn: () => false,
  target: changedStickyMode,
});

sample({
  clock: $isStickyModeOn,
  filter: (isStickyModeOn) => isStickyModeOn,
  target: [
    themeUnselected,
    themeCreationCompleted,
    themePlaceholderCleared,
    themeNameCleared,
    existingThemeUpdatingCompleted,
  ],
});
