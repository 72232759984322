import { useEffect } from 'react';

import cn from 'classnames';
import { motion, MotionValue, useSpring, useTransform } from 'framer-motion';

import styles from './styles.module.css';

const FONT_SIZE = 45;
const PADDING = 4;
const HEIGHT = FONT_SIZE + PADDING;

export const Credit = ({
  credit,
  isCurrentPlan,
  place,
}: {
  credit: number;
  isCurrentPlan: boolean;
  place: number;
}) => {
  const creditRoundedToPlace = Math.floor(credit / place);
  const animatedCredit = useSpring(creditRoundedToPlace);

  useEffect(() => {
    animatedCredit.set(creditRoundedToPlace);
  }, [animatedCredit, creditRoundedToPlace]);

  return (
    <span
      style={{ height: HEIGHT, fontSize: FONT_SIZE }}
      className={cn(styles.cost, {
        [styles.costCurrentPlan]: !isCurrentPlan,
      })}
    >
      {[...Array(10).keys()].map((i) => (
        <Number key={i} mv={animatedCredit} number={i} />
      ))}
    </span>
  );
};

const Number = ({ mv, number }: { mv: MotionValue; number: number }) => {
  const y = useTransform(mv, (latest: number) => {
    const placeValue = latest % 10;
    const offset = (10 + number - placeValue) % 10;

    let memo = offset * HEIGHT;

    if (offset > 5) {
      memo -= 10 * HEIGHT;
    }

    return memo;
  });

  return (
    <motion.span style={{ y }} className={styles.counterNumber}>
      {number}
    </motion.span>
  );
};
