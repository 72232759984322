import {
  openedVaiModal,
  openedVaiModalWithHub,
} from '@src/widgets/vai-main/model';

export const openVaiModal = ({
  pathname,
  hubs,
}: {
  pathname: string;
  hubs: Array<{ id: string; name: string }>;
}) => {
  try {
    const splitPath = pathname.split('/');

    if (pathname.includes('/messages/')) {
      // /messages/hubId/
      const hubId = splitPath[2];

      openedVaiModalWithHub({
        id: hubId,
        name: hubs.find((h) => h.id === hubId)?.name ?? '',
      });
    } else if (pathname.includes('/h/')) {
      // /h/hubId/
      const hubId = splitPath[2];

      openedVaiModalWithHub({
        id: hubId,
        name: hubs.find((h) => h.id === hubId)?.name ?? '',
      });
    } else {
      openedVaiModal();
    }
  } catch (e) {
    console.error(e);
    openedVaiModal();
  }
};
