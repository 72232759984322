import { SpinnerIcon } from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const Spinner = ({ colour }: { colour?: string }) => {
  return (
    <SpinnerIcon
      style={{
        color: colour,
      }}
      className={styles.spinner}
      width={16}
      height={16}
    />
  );
};
