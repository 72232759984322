import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { labelValueListItem, searchItemType } from './types';
import useQueryParameters from './useQueryParameters';

export const colorCategoriesList: labelValueListItem[] = [
  { label: 'black', value: '#000000' },
  { label: 'blue', value: '#0000FF' },
  { label: 'brown', value: '#825109' },
  { label: 'cyan', value: '#00FFFF' },
  { label: 'dark blue', value: '#1101CD' },
  { label: 'dark brown', value: '#3F2A0C' },
  { label: 'dark green', value: '#346A00' },
  { label: 'dark purple', value: '#800080' },
  { label: 'dark teal', value: '#006472' },
  { label: 'gold', value: '#FFE03D' },
  { label: 'green', value: '#008000' },
  { label: 'light blue', value: '#6FC2FF' },
  { label: 'lime green', value: '#00FF00' },
  { label: 'magenta', value: '#FF00FF' },
  { label: 'maroon', value: '#881818' },
  { label: 'mustard', value: '#E5E062' },
  { label: 'navy blue', value: '#000080' },
  { label: 'olive', value: '#808000' },
  { label: 'orange', value: '#FFAE10' },
  { label: 'pink', value: '#FFC0CB' },
  { label: 'purple', value: '#9370DB' },
  { label: 'red', value: '#C70000' },
  { label: 'sky blue', value: '#BBF3FF' },
  { label: 'teal', value: '#40E0D0' },
  { label: 'white', value: '#FFFFFF' },
  { label: 'yellow', value: '#FAFF00' },
];

const colorCategoriesMap: Record<string, string> = colorCategoriesList.reduce(
  (acc, { label, value }) => ({ ...acc, [value.toLowerCase()]: label }),
  {},
);

const useSearchItem = () => {
  const { addToQueryParams } = useQueryParameters();
  const location = useLocation();

  const getFlattenColorArray = (value?: string | string[] | null) => {
    return [value || []].flat();
  };
  const getUniqueArray = (arr: string[]) => {
    return Array.from(new Set(arr));
  };

  const getReadyHexColors = () => {
    const { hex_colors } = qs.parse(location.search.toLowerCase(), {
      arrayFormat: 'comma',
    });

    const cleanHexColors = getFlattenColorArray(hex_colors)
      .map((color) => {
        // extract hex part from string
        return color.replace(/.*#/, '').toLowerCase();
      })
      .filter((color) => {
        // filter invalid hex colors
        return /^[0-9A-F]{6}$/i.test(color);
      });

    return getUniqueArray(cleanHexColors).join(',');
  };

  const getSearchItems = () => {
    const { hex_colors, rgb_color } = qs.parse(location.search.toLowerCase(), {
      arrayFormat: 'comma',
    });

    const filteredCatColors: searchItemType[] = [];
    const filteredHexColors: searchItemType[] = [];

    getFlattenColorArray(hex_colors).forEach((color) => {
      const lowerCasedColor = color.toLowerCase();
      const colorHash = lowerCasedColor.replace(/.*#/, '#');
      // extract hex part from string
      if (lowerCasedColor.startsWith('c#')) {
        filteredCatColors.push({
          type: 'category',
          item: {
            label: colorCategoriesMap[colorHash],
            value: colorHash,
          },
        });
      } else {
        filteredHexColors.push({
          type: 'hex',
          item: {
            label: colorHash,
            value: colorHash,
          },
        });
      }
    });

    return {
      isEmpty: !hex_colors?.length,
      rgb_color,
      colorItems: [...filteredCatColors, ...filteredHexColors],
    };
  };

  const updateSearchItems = (
    action: 'add' | 'remove',
    { type, item }: searchItemType,
  ) => {
    const { hex_colors } = qs.parse(location.search.toLowerCase(), {
      arrayFormat: 'comma',
    });
    switch (type) {
      case 'hex':
      case 'category': {
        const signedValue = `${type === 'hex' ? 'h' : 'c'}${
          item.value
        }`.toLowerCase();
        const currentValue = getFlattenColorArray(hex_colors);

        const flattenArray =
          action === 'add'
            ? [currentValue, signedValue].flat()
            : currentValue.filter((item) => item !== signedValue);

        addToQueryParams({
          hex_colors: getUniqueArray(flattenArray),
        });

        break;
      }
      case 'tag': {
        break;
      }
      case 'swatch': {
        addToQueryParams({ rgb_color: item.value });
        break;
      }
      default: {
        addToQueryParams({ [item.label]: item.value });
        break;
      }
    }
  };

  return { updateSearchItems, getReadyHexColors, getSearchItems };
};

export default useSearchItem;
