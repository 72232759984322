import { useUnit } from 'effector-react';

import { renameColorTheme } from '@api/blocks';
import { IMAGE_QUERY } from '@src/shared/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { $currentSwatches } from '../../edit-theme';
import { useEditColor } from '../use-edit-color';

export const useRenameColorTheme = (fileId: string) => {
  const queryClient = useQueryClient();
  const { editColor } = useEditColor(fileId);
  const currentSwatches = useUnit($currentSwatches);

  const { mutate: renameTheme } = useMutation({
    mutationFn: (theme: any) => renameColorTheme(theme),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [IMAGE_QUERY, { id: fileId }],
      });

      if (
        data?.data.state.status === 'rename_success' &&
        variables.isEditColor
      ) {
        editColor({
          id: fileId,
          swatches: currentSwatches[0].swatches,
          theme: data.data.data.new_name,
        });
      }
    },
  });

  return { renameTheme };
};
