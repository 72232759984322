import {
  Button,
  Divider,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { GenericBanner } from '@components/Banner';
import { useHubCredits } from '@pages/AccountPage/hooks/useHubCredits';
import {
  openedHubCreditSheet,
  openedHubInfoSheet,
} from '@pages/AccountPage/models';
import { filterOneCreditPack, sortHubBundles } from '@pages/AccountPage/utils';
import { useBilling } from '@src/shared/queries/useBilling';

import { HubCredit } from '../HubCredit';
import { LargeHubCredit } from '../LargeHubCredit';

import styles from './styles.module.css';

export const HubCreditsSection = () => {
  const {
    query: { data },
  } = useBilling();
  const { hubCreditQuery, hubProductsQuery } = useHubCredits();
  const openHubCreditInfo = () => openedHubInfoSheet();

  const openHubCreditSheet = () => {
    openedHubCreditSheet({
      bundleId: 'credit_pack_250',
      purchaseIdentifier: 'hub-credits',
      purchasePurpose: 'hub_unlock',
      redirectURL: window.location.href,
    });
  };

  const showLowCreditBanner =
    hubCreditQuery.data &&
    hubCreditQuery.data.hub_credits_available <= 10 &&
    data &&
    data.membershipTierInfo.plan === 'pro' &&
    data.membershipTierInfo.nextPlan === null;

  const hubCredits = hubCreditQuery.data?.hub_credits_available;

  const isLoading = hubCreditQuery.isLoading || hubProductsQuery.isLoading;
  const isError = hubCreditQuery.isError || hubProductsQuery.isError;

  if (isLoading) return <p>Loading</p>;

  if (isError) return <p>Error</p>;

  if (!data || !hubCreditQuery.data || !hubProductsQuery.data) return null;

  const plan = data.membershipTierInfo.plan;
  const disableHubCreditButtons = plan === 'free' || plan === 'starter';

  return (
    <div className={styles.mainContainer}>
      <Divider type="short-line" />
      {showLowCreditBanner ? (
        <div className={styles.bannerContainer}>
          <GenericBanner
            label={
              hubCredits === 0
                ? 'You have no credits. Top up your balance to keep saving on hubs.'
                : 'You’re almost out of credits. Top up your balance to keep saving on hubs.'
            }
            buttonText="Top up your credits"
            onClick={openHubCreditSheet}
          />
        </div>
      ) : null}
      <div className={styles.container}>
        <div className={styles.largeHubContainer}>
          <TypographyPoppins type="body" size="M">
            <span className={styles.upperLabel}>Credits never expire.</span>
          </TypographyPoppins>
          <div className={styles.hubBalanceContainer}>
            <LargeHubCredit
              credits={hubCreditQuery.data.hub_credits_available}
              plan={plan}
            />
          </div>
          <Button
            label="Learn more about hub billing"
            type="ghost"
            onClick={openHubCreditInfo}
            style={{
              color: 'var(--color-primary-40)',
            }}
          />
        </div>

        <div className={styles.hubCreditAndLabelContainer}>
          <div className={styles.createTagLine}>
            <TypographyPoppins type="body" size="M">
              <span className={styles.upperLabel}>
                Buy a credit bundle to save up to $200
              </span>
            </TypographyPoppins>
          </div>
          <div className={styles.hubCreditContainer}>
            {hubProductsQuery.data
              .filter(filterOneCreditPack)
              .sort(sortHubBundles)
              .map((product) => {
                const credit = CREDITS_PRICING[product.lookup_key];
                return (
                  <HubCredit
                    bundleId={product.lookup_key}
                    key={product.stripe_price_id}
                    amount={credit.amount}
                    price={product.unit_price / 100}
                    savings={credit.savings}
                    isDisabled={disableHubCreditButtons}
                    isHighlighted={credit.highlighted}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const CREDITS_PRICING = {
  credit_pack_1: {
    amount: 1,
    price: 1.2,
    savings: 10,
    highlighted: false,
  },
  credit_pack_10: {
    amount: 10,
    price: 10,
    savings: 10,
    highlighted: false,
  },
  credit_pack_100: {
    amount: 100,
    price: 110,
    savings: 10,
    highlighted: false,
  },
  credit_pack_250: {
    amount: 250,
    price: 250,
    savings: 50,
    highlighted: true,
  },
  credit_pack_500: {
    amount: 500,
    price: 400,
    savings: 200,
    highlighted: false,
  },
};
