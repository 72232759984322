import { Conversation, getHubConversations } from '@api/vai';
import { vaiConversationsKeys } from '@src/shared/constants/query-keys';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useHubConversations = (hubId: string) => {
  const conversationQuery = useInfiniteQuery({
    queryKey: vaiConversationsKeys.hubConversation(hubId),
    queryFn: ({ pageParam }) => {
      const offset = parseInt(pageParam) ?? 0;
      return getHubConversations({
        hubId,
        offset,
        // TEMP limit
        limit: 500,
      });
    },
    initialPageParam: '0',
    getNextPageParam: (lastGroup) => {
      return (
        lastGroup.next && new URL(lastGroup.next).searchParams.get('offset')
      );
    },
    select: (data) => {
      return {
        ...data,
        conversations: data.pages.reduce(
          (acc, item) => [...acc, ...item.results].reverse(),
          [] as Array<Conversation>,
        ),
      };
    },
  });

  return { conversationQuery };
};
