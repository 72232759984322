import React from 'react';

import { motion } from 'framer-motion';

import { Icon } from '@visualist/icons';

import styles from './styles.module.css';

type Props = {
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ReplyFooter = ({ setIsDropdownOpen }: Props) => {
  return (
    <motion.div className={styles.selectedFooter}>
      <div></div>
      <div className={styles.buttonContainer}>
        <button
          className={styles.dotsButton}
          onClick={() => {
            setIsDropdownOpen((p) => !p);
          }}
        >
          <Icon name="sprite/3-dot-menu" />
        </button>
      </div>
    </motion.div>
  );
};
