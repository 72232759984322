/**
 * @name isTransparent
 * @description Check if an image is transparent by checking each corner and center pixel of the image
 * @param image
 * @returns boolean
 */

export const isTransparent = (image: { url: string }) =>
  new Promise((res, rej) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        rej('Could not get context');
        return;
      }

      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const data = imageData.data;

      // Pixel values are stored as RGBA (Red, Green, Blue, Alpha) in a single array
      const topLeftAlpha = data[3];
      const bottomRightAlpha = data[data.length - 1];
      const bottomLeftAlpha = data[data.length - img.width * 4 - 1];
      const topRightAlpha = data[img.width * 4 - 1];
      // TODO Figure out centre alpha pixel
      // const centerAlpha = data[Math.floor(data.length / 2) + 4];

      // Checks if any of the corners or center pixel is transparent. If alpha is not 255 (solid) we have some transparency
      const isTransparent =
        topLeftAlpha !== 255 ||
        bottomRightAlpha !== 255 ||
        bottomLeftAlpha !== 255 ||
        topRightAlpha !== 255;
      // ||
      // centerAlpha !== 255;

      res(isTransparent);
    };

    img.onerror = (e) => {
      console.error(e);
      rej('Could not load image');
    };
    img.src = image.url;
  });
