import { useHistory } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { softDeleteDoc, undoSoftDeleteDoc, UpdateDoc } from '@api/docs';
import {
  ALL_DOCS_QUERY,
  DOC_QUERY,
  DOCS_OF_BOARD_QUERY,
  DOCS_OF_HUB_QUERY,
} from '@src/shared/constants/query-names';
import { DOC_TABS } from '@src/shared/constants/variables-local-storage';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { Tab } from '../../ui/tabs';

export const useDeleteDoc = (options?: MutateOptions<any, any, any>) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const [, setTabs] = useLocalStorage<Tab[]>(DOC_TABS, []);

  const removeDocMutation = useMutation({
    mutationFn: ({ docId }: UpdateDoc) => softDeleteDoc(docId),
    onSuccess: (data, { docId, redirect }, context) => {
      queryClient.invalidateQueries({
        queryKey: [ALL_DOCS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [DOC_QUERY, { docId }],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_HUB_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_BOARD_QUERY],
      });

      setTabs((tabs) => tabs.filter((t) => t.id !== data.id));

      if (redirect) {
        history.push(`/library`);
      }

      startedSnack({
        label: 'Deleted doc',
        action: {
          label: 'Undo',
          action: async () => {
            await putDocBackMutation.mutateAsync({ docId });
            setTabs((tabs) => [
              ...tabs.filter((t) => t.id !== docId),
              {
                id: docId,
                label: data.title ?? '',
              },
            ]);
          },
        },
        close: true,
      });

      if (options?.onSuccess) options?.onSuccess(data, { docId }, context);
    },
    onError: (_, { docId }) => {
      startedSnack({
        label: "Couldn't delete doc",
        action: {
          label: 'Try again',
          action: async () => {
            await removeDocMutation.mutateAsync({ docId });
          },
        },
        close: true,
      });
    },
  });

  const putDocBackMutation = useMutation({
    mutationFn: ({ docId }: UpdateDoc) => undoSoftDeleteDoc(docId),
    ...(options ?? {}),
    onSuccess: (_data, { docId }) => {
      queryClient.invalidateQueries({
        queryKey: [ALL_DOCS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [DOC_QUERY, { docId }],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_HUB_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_BOARD_QUERY],
      });
    },
  });

  return { removeDocMutation };
};
