import { Icon, IconProps } from './Icon';

interface ThinArrowIconProps extends IconProps {
  direction: 'left' | 'right';
}

export const ThinArrowIcon = (props: ThinArrowIconProps) => {
  return (
    <Icon
      {...props}
      style={{
        ...props.style,
        rotate: props.direction === 'left' ? '0deg' : '180deg',
      }}
    >
      <path
        d="M19 12L4.99998 12M7.63985 9.36015L4.99998 12M4.99998 12L7.63985 14.6399"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </Icon>
  );
};
