import React from 'react';

import cn from 'classnames';

import styles from './styles.module.css';

export type State = 'enabled' | 'hovered' | 'focused' | 'pressed' | 'disabled';

export interface RadioButtonProps {
  state: State;
  selected?: boolean;
  className?: string;
  classNameOuterRadioSelected?: string;
  classNameInnerRadioSelected?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const RadioButton = ({
  state,
  selected,
  className,
  classNameOuterRadioSelected,
  classNameInnerRadioSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: RadioButtonProps) => (
  <div
    className={cn(styles.enabled, className, {
      [styles.hovered]: state === 'hovered',
      [styles.focused]: state === 'focused',
      [styles.pressed]: state === 'pressed',
      [styles.disabled]: state === 'disabled' && !selected,
    })}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div
      className={cn(
        styles.outerRadio,
        {
          [styles.outerRadioSelected]: selected,
          [styles.outerRadioSelectedDisabled]: selected && state === 'disabled',
        },
        selected && classNameOuterRadioSelected,
      )}
    >
      <div
        className={cn(
          styles.innerRadio,
          {
            [styles.innerRadioSelected]: selected,
            [styles.innerRadioSelectedDisabled]:
              selected && state === 'disabled',
          },
          selected && classNameInnerRadioSelected,
        )}
      ></div>
    </div>
  </div>
);
