import cn from 'classnames';

import { FileType } from '@visualist/design-system';

import styles from './styles.module.css';

type SkeletonLoaderProps = {
  type: FileType;
};

export const SkeletonCards = ({ type }: SkeletonLoaderProps) => {
  return (
    <div
      className={cn(styles.skeletonCard, styles.skeletonAnimation, {
        [styles.skeletonDocs]: type === 'Doc',
        [styles.skeletonImage]: type === 'Image',
        [styles.skeletonDesign]: type === 'Design',
        [styles.skeletonPalette]: type === 'Palette',
      })}
    >
      <div className={styles.skeletonCardInner}>
        <div className={styles.skeletonCardImage} data-delay={0} />
        <div className={styles.skeletonCardText} data-delay={0.2} />
      </div>
    </div>
  );
};
