import { forwardRef, PropsWithChildren } from 'react';

import * as PopoverPrimitive from '@radix-ui/react-popover';

import styles from './styles.module.css';

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

export const PopoverContent = forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children, ...props }, forwardedRef) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        className={styles.PopoverContent}
        sideOffset={4}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  ),
);
