import { api } from '.';
import { HubResponse } from './hubs';

/*
 * (0, 'vai_generated_output', 'Generated output from VAI')
 * (1, 'vai_questions', 'VAI predefined message')
 * (2, 'user_message', 'User message')
 * (3, 'undefined', 'Undefined')
 */

type ConversationTypeWithText =
  | {
      id: '0';
      text: 'Generated output from VAI';
    }
  | {
      id: '1';
      text: 'VAI predefined message';
    }
  | {
      id: '2';
      text: 'User message';
    };

type ConversationTypeWithoutText = {
  id: '3';
  text: 'Undefined';
};

export type RelatedObject = {
  type_model: 'hub' | 'message';
  type_id: string;
  id: string;
};

export type VaiPossibleQuestion =
  | 'DRAFT_RESPONSE_PROMPT'
  | 'ADD_TO_LIST_PROMPT'
  | 'EXTRACT_ACTION_ITEMS_PROMPT'
  | 'RATING_PROMPT'
  | 'FURTHER_ACTIONS_PROMPT'
  | 'ACTION_FOUND_ADD_PROMPT';
export type UserAction =
  | 'SUMMARIZE'
  | 'DRAFT_RESPONSE'
  | 'CREATE_ACTION_ITEMS'
  | 'HUB_STATUS_CHECK'
  | 'THREAD_STATUS_CHECK'
  | 'DONE'
  | 'YES'
  | 'NO'
  | 'CHANGE_TONE_FORMAL'
  | 'CHANGE_TONE_FRIENDLY';

type ConversationPartial = {
  created_at: string;
  hub: Pick<HubResponse, 'id' | 'name' | 'thumbnail' | 'created_by'>;
  id: string;
  is_pinned: boolean;
  possible_responses: Array<{
    response_text: string;
    action: UserAction;
  }>;
  related_object: RelatedObject;
  vai_object_id: {
    id: string;
    output_type: string;
  };
};

export type VaiUserConversation = ConversationPartial & {
  text: {
    text: string;
    action: VaiPossibleQuestion;
  };
  convo_type: ConversationTypeWithText;
};

export type UndefinedConversation = ConversationPartial & {
  convo_type: ConversationTypeWithoutText;
};

export type Conversation = VaiUserConversation | UndefinedConversation;

export type InfiniteConversationResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<Conversation>;
};

// Only some action posts return a vai_pid. Ones that go to open ai.

type ActionMutationResponse =
  | {
      vai_pid: string;
    }
  | undefined;

type PollStatus = {
  data: {
    vai_pid: string;
    is_removed: boolean;
    status: 'failed' | 'in_progress' | 'succeeded';
  };
  state: {
    message: string;
    status: string;
  };
};

/**
 * Checks status of an ongoing mutation request. Backend has the event in the queue so we need to check status periodically to know when to refetch the conversation data.
 * Ths lets us know when it has the latest conversation
 */

// PLACEHOLDER
export const getMutationStatus = async (id: string): Promise<PollStatus> => {
  const { data } = await api.get(`/vai/${id}/poll/`);
  return data;
};

/**
 * To trigger VAI to create a status check of the hub (it is a summary of all the available threads in the hub). It will return with a 201 HTTP Response
 * TODO Update for standard reponse
 * @param hubId
 * @returns
 */

export const triggerVaiHubStatusCheck = async (
  hubId: string,
): Promise<ActionMutationResponse> => {
  const { data } = await api.post(`/vai/hub/${hubId}/status-check/`);
  return data;
};

/**
 * To trigger VAI to create a status check of the thread (it is a summary of all the emails in the thread). It will return with a 201 HTTP Response
 * TODO Update for standard reponse
 * @param threadId
 * @returns
 */

export const triggerVaiThreadStatusCheck = async (
  threadId: string,
): Promise<ActionMutationResponse> => {
  const { data } = await api.post(`/vai/thread/${threadId}/status-check/`);
  return data;
};

/**
 * To trigger VAI to generate summary of an email. It will return with a 201 HTTP Response
 * TODO Update for standard reponse
 * @param messageId
 * @returns
 */

export const generateVaiEmailSummary = async (
  messageId: string,
): Promise<ActionMutationResponse> => {
  const { data } = await api.post(`/vai/message/${messageId}/summary/`);
  return data;
};

/**
 * To trigger VAI to generate action items of an email. It will return with a 201 HTTP Response
 * TODO Update for standard reponse
 * @param messageId
 * @returns
 */

export const generateVaiEmailActionItems = async (
  messageId: string,
): Promise<ActionMutationResponse> => {
  const { data } = await api.post(`/vai/message/${messageId}/action-item/`);
  return data;
};

/**
 * To trigger VAI to generate a draft response to an email. It will return with a 201 HTTP Response
 * TODO Update for standard reponse
 * @param messageId
 * @returns
 */

export const generateVaiEmailResponse = async (
  messageId: string,
  tone?: 'CHANGE_TONE_FORMAL' | 'CHANGE_TONE_FRIENDLY',
): Promise<ActionMutationResponse> => {
  const { data } = await api.post(`/vai/message/${messageId}/response/`, {
    tone,
  });
  return data;
};

/**
 * Fetches conversations with filters, pagination and search support.
 * Returns paginated conversations ordered by latest first.
 *
 * @param params {ConversationFilters} - Filters for conversations (hub, type, search)
 * @param offset {number} - Pagination offset
 * @param limit {number} - Number of results per page (default 40)
 * @returns InfiniteConversationResponse with paginated results
 *
 * Message Types:
 * - 0: 'vai_generated_output' - Generated output from VAI
 * - 1: 'vai_questions' - VAI predefined message with suggested responses
 * - 2: 'user_message' - User message
 * - 3: 'undefined' - Undefined message type
 *
 * Note: Types 0 and 1 are VAI messages, type 2 is user messages
 */

export const getConversations = async ({
  limit = 40,
  offset,
  params,
}: {
  params?: ConversationFilters;
  offset?: number;
  limit?: number;
}): Promise<InfiniteConversationResponse> => {
  const { data } = await api.get(`/vai/conversations/`, {
    params: {
      limit,
      offset,
      ...(params || {}),
    },
  });
  return data;
};

export type ConversationFilters = {
  hub?: string;
  type?: 0 | 1 | 2 | 3;
  search?: string;
  // Important note: Status check can be for both (hub or thread). If the vai object value is a message its a thread status check if it is a hub its a hub status check.
  // 0: status check, 1: summary, 2: action items, 3: response, 4: undefined
  vai_output_type?: 0 | 1 | 2 | 3 | 4;
};

/**
 * To fetch conversations in a hub. It will return with a 201 HTTP Response. It returns the "chat" ordered by latest first.
 * TODO Update for standard reponse
 * @param hubId
 * @returns
 * (0, 'vai_generated_output', 'Generated output from VAI')
 * (1, 'vai_questions', 'VAI predefined message')
 * (2, 'user_message', 'User message')
 * (3, 'undefined', 'Undefined')
 *
 * 0 and 1 are VAI speaking messages
 * 3 are user speaking messages
 * Attached to type 1 vai_questions  I provide also possible user responses for the user to choose from.
 */

export const getHubConversations = async ({
  hubId,
  limit = 40,
  offset,
}: {
  hubId: string;
  offset?: number;
  limit?: number;
}): Promise<InfiniteConversationResponse> => {
  const { data } = await api.get(`/vai/conversation/hub/${hubId}/`, {
    params: {
      limit,
      offset,
    },
  });
  return data;
};

/**
 * Sends a user's selected response to a conversation in a hub
 * @param hubId - The ID of the hub containing the conversation
 * @param vai_action - The VAI question/prompt being responded to
 * @param user_action - The user's selected response action
 * @param relatedObjectId - ID of the related object (e.g. message, task)
 * @param relatedObjectTypeId - Type of the related object
 * @returns Promise that resolves when the message is sent
 */

export const sendMessageToConversation = async ({
  hubId,
  ...rest
}: {
  hubId: string;
  vai_action: VaiPossibleQuestion;
  user_action: UserAction;
  related_object_id?: string;
  related_object_type_id?: string;
}): Promise<ActionMutationResponse> => {
  const { data } = await api.post(`/vai/conversation/hub/${hubId}/message/`, {
    ...rest,
  });
  return data;
};

/**
 * Get Pinned messages
 */

type PinnedMessage = {
  id: string;
  vai_object_id: {
    id: string;
    output_type: string;
  };
  text: {
    text: string;
  };
};

export const getPinnedMessages = async (
  hubId: string,
): Promise<PinnedMessage[]> => {
  const { data } = await api.get(`/vai/conversation/hub/${hubId}/pinned/`);
  return data;
};

/**
 * Pin or unpin a message
 */

export const toggleMessagePin = async (
  hubId: string,
  conversationId: string,
): Promise<unknown> => {
  return api.post(`/vai/conversation/hub/${hubId}/${conversationId}/pin/`);
};

// Popup dismissalls
/**
 * @description Sends a request to the API to never ask for the tagging modal WS ping again
 */

export const doNotAskAgainTaggingModal = async (
  type: 'tagging_prompt' | (string & {}),
) => {
  const { data } = await api.post('/connections/vai/do_not_ask_again/', {
    type,
  });
  return data;
};
