export const ROTATION_ANGLE_LIMIT = 360;
export const MIN_WIDTH = 10;
export const ANGLE_ZERO = 0;
export const ANGLE_TWENTY_TWO_DOT_FIVE = 22.5;
export const ANGLE_FOURTY_FOUR = 44;
export const ANGLE_FOURTY_FIVE = 45;
export const ANGLE_SIXTY_SEVEN_DOT_SIX = 67.6;
export const ANGLE_NINETY = 90;
export const ANGLE_ONE_ONE_TWO_DOT_FIVE = 112.5;
export const ANGLE_ONE_THIRTY_FOUR = 134;
export const ANGLE_ONE_THIRTY_FIVE = 135;
export const ANGLE_ONE_FIFTY_SEVEN_DOT_SIX = 157.6;
export const ANGLE_ONE_EIGHTY = 180;
export const ANGLE_TWO_ZERO_TWO_DOT_FIVE = 202.5;
export const ANGLE_TWO_TWENTY_FIVE = 225;
export const ANGLE_TWO_FOURTY_SEVEN_DOT_SIX = 247.6;
export const ANGLE_TWO_SEVENTY = 270;
export const ANGLE_TWO_NINETY_TWO_DOT_FIVE = 292.5;
export const ANGLE_THREE_ONE_FOUR = 314;
export const ANGLE_THREE_ONE_FIVE = 315;
export const ANGLE_THREE_THIRTY_SEVEN_DOT_SIX = 337.6;
export const HANDLE_TOP = 'Top';
export const HANDLE_BOTTOM = 'Bottom';
export const HANDLE_RIGHT = 'Right';
export const HANDLE_LEFT = 'Left';
export const HANDLE_TOP_LEFT = 'TopLeft';
export const HANDLE_TOP_RIGHT = 'TopRight';
export const HANDLE_BOTTOM_LEFT = 'BottomLeft';
export const HANDLE_BOTTOM_RIGHT = 'BottomRight';
