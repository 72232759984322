import { ReactNode } from 'react';

import {
  Avatar,
  Badge,
  FilterChip,
  Switch,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Member } from '@api/boards';
import { PermissionsHub } from '@api/hubs';
import {
  renderUserInitials,
  renderUserNameOrEmail,
} from '@src/shared/utils/names';

import { PermissionsMenu } from '../PermissionsMenu';

import styles from './styles.module.css';

type FilterChipsProps = {
  user: Member;
  isShowMenu: boolean;
  setShowMenu: React.Dispatch<boolean>;
  getPermissions?: (permission: any) => ReactNode;
};

type SwitchProps = {
  user: Member;
  on: boolean;
  invite?: () => void;
  remove?: () => void;
};

type UserProps = {
  user: Member;
  isShowMenu: boolean;
  setShowMenu: React.Dispatch<boolean>;
  changePermissions: (permissions: PermissionsHub[]) => void;
  invite?: () => void;
  remove?: () => void;
  on: boolean;
  permissions: PermissionsHub[];
  permissionsRef: React.RefObject<HTMLDivElement>;
  getPermissions?: (permission: any) => ReactNode;
};

export const User = ({
  user,
  isShowMenu,
  setShowMenu,
  changePermissions,
  invite,
  remove,
  on,
  permissions,
  permissionsRef,
  getPermissions,
}: UserProps) => {
  return (
    <li key={user.id} className={styles.user}>
      <div className={styles.nameOrEmail}>
        <Avatar
          image={user.user?.photo.full_size}
          initials={renderUserInitials({
            first_name: user.user ? user.user.first_name : null,
            last_name: user.user ? user.user.last_name : null,
            email: user.email,
          })}
          size={20}
        />
        <TypographyPoppins
          className={styles.nameTitle}
          type="body"
          bodySize="S"
        >
          {renderUserNameOrEmail({
            first_name: user.user ? user.user.first_name : null,
            last_name: user.user ? user.user.last_name : null,
            email: user.email,
          })}
        </TypographyPoppins>
        {user.is_owner && <Badge style="text" text="Owner" />}
        {!user.is_owner && !user.user && user.permissions ? (
          <Badge style="text" text="Pending" />
        ) : null}
      </div>
      <div className={styles.actions}>
        <div ref={permissionsRef} className={styles.permissions}>
          <FilterChips
            user={user}
            isShowMenu={isShowMenu}
            setShowMenu={setShowMenu}
            getPermissions={getPermissions}
          />
          <PermissionsMenu
            isShowMenu={isShowMenu}
            setShowMenu={setShowMenu}
            permissions={permissions}
            changePermissions={changePermissions}
          />
        </div>
        <Switchs user={user} on={on} invite={invite} remove={remove} />
      </div>
    </li>
  );
};

const FilterChips = ({
  user,
  isShowMenu,
  setShowMenu,
  getPermissions,
}: FilterChipsProps) => {
  return (
    <>
      {/* Owner */}
      {user.is_owner && (
        <FilterChip
          className={styles.permission}
          leadingIcon={
            isShowMenu && (
              <Icon
                name="sprite/tick"
                size={16}
                color="var(--color-neutral-variant-30)"
              />
            )
          }
          trailingIcon={
            isShowMenu ? (
              <Icon name="sprite/caret-down" size={16} />
            ) : (
              <Icon name="sprite/caret-up" size={16} />
            )
          }
          type="label"
          labelSize="S"
          isDisabled
          onClick={() => setShowMenu(!isShowMenu)}
        >
          {getPermissions && getPermissions(user)}
        </FilterChip>
      )}

      {/* Invited users */}
      {!user.is_owner && user.permissions && (
        <FilterChip
          className={styles.permission}
          leadingIcon={
            isShowMenu && (
              <Icon
                name="sprite/tick"
                size={16}
                color="var(--color-neutral-variant-30)"
              />
            )
          }
          trailingIcon={
            isShowMenu ? (
              <Icon name="sprite/caret-down" size={16} />
            ) : (
              <Icon name="sprite/caret-up" size={16} />
            )
          }
          type="label"
          labelSize="S"
          isSelected={isShowMenu}
          onClick={() => setShowMenu(!isShowMenu)}
        >
          {getPermissions && getPermissions(user)}
        </FilterChip>
      )}

      {/* Existing users and guest seats */}
      {!user.permissions && (
        <FilterChip
          className={styles.permission}
          leadingIcon={
            isShowMenu && (
              <Icon
                name="sprite/tick"
                size={16}
                color="var(--color-neutral-variant-30)"
              />
            )
          }
          trailingIcon={
            isShowMenu ? (
              <Icon name="sprite/caret-down" size={16} />
            ) : (
              <Icon name="sprite/caret-up" size={16} />
            )
          }
          type="label"
          labelSize="S"
          isSelected={isShowMenu}
          onClick={() => setShowMenu(!isShowMenu)}
        >
          Editor
        </FilterChip>
      )}
    </>
  );
};

const Switchs = ({ user, on, invite, remove }: SwitchProps) => {
  return (
    <>
      {/* Owner */}
      {user.is_owner && (
        <Switch onValueChange={() => {}} value={true} disable />
      )}

      {/* Invited users */}
      {!user.is_owner && user.permissions && (
        <Tooltip
          style={{
            minWidth: 'max-content',
            left: '-5px',
          }}
          parameter={{
            type: 'plain',
            description: 'Remove',
            position: 'top',
          }}
        >
          <Switch
            onValueChange={() => {
              if (remove) {
                remove();
              }
            }}
            value={on}
          />
        </Tooltip>
      )}

      {/* Existing users and guest seats */}
      {!user.permissions && (
        <Tooltip
          style={{
            minWidth: 'max-content',
          }}
          parameter={{
            type: 'plain',
            description: 'Invite',
            position: 'top',
          }}
        >
          <Switch
            onValueChange={() => {
              if (invite) {
                invite();
              }
            }}
            value={on}
          />
        </Tooltip>
      )}
    </>
  );
};
