// Import necessary functions and types from external libraries and modules
import { createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';
import { delay } from 'patronum';

import { SearchType } from '@api/search';
import { $isFileCardOpen } from '@pages/FileCard/model';
import { isInteractableElement } from '@src/shared/utils/isInteractable';

// Define the structure for a search colour
export type SearchColour = {
  hex: string;
  x?: number;
  y?: number;
  id: string;
};

// Create events for various search-related actions
export const revealedSearch = createEvent();
export const hideSearch = createEvent();
export const toggleSearch = createEvent();
export const toggleFilterType = createEvent<FilterFileType>();
export const resetFilters = createEvent();

export const selectedColour = createEvent<SearchColour>();
export const removedColour = createEvent<string>();
export const movedColour = createEvent<SearchColour>();
export const updatedColours = createEvent<Array<SearchColour>>();
export const revealColoursOnPicker = createEvent();

export const searchedColours = createEvent();

export const setSearchFullScreen = createEvent<boolean>();

// Define types for search help options
type SearchHelpOptions = 'search_results_large' | '' | 'search_results_small';

// Create events for VAI search help popup
export const openedVaiSearchHelpPopup = createEvent<SearchHelpOptions>();
export const closedVaiSearchHelpPopup = createEvent();

// Create events for colour palette search
export const openedColourPaletteSearch = createEvent();
export const closedColourPaletteSearch = createEvent();

// Create stores for managing search-related state
export const $showSearch = createStore(false)
  .on(revealedSearch, () => true)
  .on(toggleSearch, (s) => !s)
  .reset(hideSearch);

export const $showVaiSearchHelpPopup = createStore<SearchHelpOptions>('')
  .on(openedVaiSearchHelpPopup, (_, s) => s)
  .on(closedVaiSearchHelpPopup, () => '');

export const $showColourPaletteSearch = createStore(false)
  .on(openedColourPaletteSearch, () => true)
  .on(closedColourPaletteSearch, () => false);

/**
 * Store that is mainly for selecting colours on the palette before a search is made
 */
export const $selectedColours = createStore<Array<SearchColour>>([])
  .on(selectedColour, (state, colour) => [...state, colour])
  .on(removedColour, (state, id) => state.filter((c) => c.id !== id))
  .on(updatedColours, (_, colours) => colours)
  .on(movedColour, (state, colour) => {
    const index = state.findIndex((c) => c.id === colour.id);
    if (index === -1) return state;
    const newState = [...state];
    newState[index].x = colour.x;
    newState[index].y = colour.y;
    newState[index].hex = colour.hex;
    return newState;
  })
  .reset(closedColourPaletteSearch);

/**
 * Store that is actually used for searching colours
 */
export const $selectedSearchedColours = createStore<Array<SearchColour>>(
  [],
).reset(closedColourPaletteSearch);

// Create stores for managing search full screen and colour picker visibility
export const $searchFullScreen = createStore(false).on(
  setSearchFullScreen,
  (_, s) => s,
);

export const $hideColoursOnPicker = createStore(false)
  .on(setSearchFullScreen, () => true)
  .on(revealColoursOnPicker, () => false);

// Define valid filter file types
const FILTER_FILE_TYPE_VALUES = [
  'hub',
  'board',
  'doc',
  'image',
  'design',
  'palette',
  // Stickies is here as BE needs it but it has no ui filter set.
  'stickies',
] satisfies Readonly<Array<SearchType>>;

export type FilterFileType = (typeof FILTER_FILE_TYPE_VALUES)[number];

// Define the structure for filter state
export type FilterState = {
  files: Set<FilterFileType>;
  allSelected: () => boolean;
  allPossible: () => Readonly<Array<FilterFileType>>;
};

// Define initial filter state
const initialFilterState = {
  files: new Set([] as Array<FilterFileType>),
  allSelected: function () {
    return this.files.size === 0;
  },
  allPossible: function () {
    return FILTER_FILE_TYPE_VALUES;
  },
} satisfies FilterState;

// Create a store for managing filters
export const $filters = createStore(initialFilterState)
  .on(toggleFilterType, (state, filterType) => {
    if (state.files.has(filterType)) {
      if (state.files.size === 1) {
        return initialFilterState;
      }
      const files = new Set(state.files);
      files.delete(filterType);
      return {
        ...state,
        files,
      };
    } else {
      const files = new Set(state.files);
      files.add(filterType);
      return {
        ...state,
        files,
      };
    }
  })
  .reset(resetFilters);

// Sample to reset filters when all file types are selected
sample({
  clock: $filters,
  source: {
    filters: $filters,
  },
  filter: ({ filters }) => filters.files.size === 6,
  target: resetFilters,
});

// Create a hotkey for revealing search
const sKeyPressed = hotkey({
  key: 's',
});

// Sample to reveal search when 's' key is pressed
sample({
  clock: sKeyPressed,
  source: $showSearch,
  filter: (s, e) => {
    if (isInteractableElement(e.target as Element)) return false;

    return true;
  },
  target: revealedSearch,
});

// Create a hotkey for hiding search
hotkey({
  key: 'Escape',
  target: hideSearch,
});

// Create a delayed hide search event
const hideSearchWithDelay = delay({
  source: hideSearch,
  timeout: 200,
});

// Sample to close colour palette search and reset full screen when search is hidden
sample({
  clock: hideSearchWithDelay,
  fn: () => false,
  target: [
    closedColourPaletteSearch,
    setSearchFullScreen,
    revealColoursOnPicker,
  ],
});

// When search is hidden, reset the colour palette search
sample({
  clock: hideSearch,
  target: closedColourPaletteSearch,
});

// Sample to reset full screen when colour palette search is closed
sample({
  clock: closedColourPaletteSearch,
  fn: () => false,
  target: [setSearchFullScreen, revealColoursOnPicker],
});

// Sample to update selectedSearchColours when searchedColours is triggered
sample({
  clock: searchedColours,
  source: $selectedColours,
  target: $selectedSearchedColours,
});

// Close the search when closing the card
sample({
  clock: $isFileCardOpen,
  filter: (isFileCardOpen) => !isFileCardOpen,
  target: hideSearch,
});
