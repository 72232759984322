import { format } from 'date-fns';
import { useHistory } from 'react-router';

import {
  Button,
  IconButton,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { BlockResponse } from '@api/index';
import { SMALL } from '@src/shared/constants/breakpoints';

import styles from './styles.module.css';

type Props = {
  block?: BlockResponse;
};

export const Source = ({ block }: Props) => {
  const { width } = useWindowSize();
  const history = useHistory();

  const getSource = () => {
    switch (block?.block_source) {
      case 'upload':
        return (
          <TypographyPoppins
            type="body"
            bodySize="M"
            className={styles.content}
          >
            Uploaded from device
          </TypographyPoppins>
        );
      case 'hand':
        return (
          <div className={styles.goToSource}>
            <span className={styles.hyperlink}>
              {block?.source_data.page_url}
            </span>
            <Button
              type="text"
              label="Go to source"
              onClick={() => {
                window.open(`${block?.source_data.page_url}`, '_blank');
              }}
            />
          </div>
        );
      case 'set':
      case 'user':
        return (
          <TypographyPoppins
            type="body"
            bodySize="M"
            className={styles.content}
          >
            Created by user
          </TypographyPoppins>
        );
      case 'block':
        return (
          <div className={styles.goToSource}>
            <TypographyPoppins
              type="body"
              bodySize="M"
              className={styles.content}
            >
              Extracted from image file
            </TypographyPoppins>
            <Button
              type="text"
              label="Go to source"
              onClick={() => {
                history.push(`#/block/${block?.source_data.source_id}`);
              }}
            />
          </div>
        );
      case 'palette':
        return (
          <div className={styles.goToSource}>
            <TypographyPoppins
              type="body"
              bodySize="M"
              className={styles.content}
            >
              Edited from original
            </TypographyPoppins>
            <Button
              type="text"
              label="Go to source"
              onClick={() => {
                history.push(`#/block/${block?.source_data.source_id}`);
              }}
            />
          </div>
        );
    }
  };

  const description = (
    <div className={styles.description}>
      <TypographyPoppins type="title" titleSize="S" className={styles.content}>
        {block && format(new Date(block.created_at), `MMM d, yyyy`)}
      </TypographyPoppins>
      {getSource()}
    </div>
  );

  if (!block) return null;

  return (
    <Tooltip
      parameter={{
        type: 'rich',
        description,
        position: width <= SMALL ? 'bottom' : 'top right',
      }}
    >
      <div className={styles.copy}>
        <IconButton
          type="unfilled"
          className={styles.iconButton}
          icon={<Icon name="sprite/source" className={styles.icon} />}
          onClick={() => {}}
        />
      </div>
    </Tooltip>
  );
};
