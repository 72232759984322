import React from 'react';

// https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
export function useCombinedRefs<T>(
  innerRef: React.MutableRefObject<T>,
  fwdRef: React.ForwardedRef<T>,
): React.MutableRefObject<T> {
  React.useEffect(() => {
    [innerRef, fwdRef].forEach((ref) => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(innerRef.current || null);
        } else {
          ref.current = innerRef.current || null;
        }
      }
    });
  }, [innerRef, fwdRef]);
  return innerRef;
}
