export type Plan = 'free' | 'starter' | 'pro' | 'trial';

export type PlanCardDetails = {
  title: Plan;
  subTitle: string;
  cost: {
    monthly: number;
    annual: number;
  };
  costDuration: string;
  features: string[];
  buttonAction?: {
    title: string;
  };
};

export type TabName = 'Profile' | 'My plan' | 'Previous bills' | 'Rewards';

type PLAN_SHEET = {
  heading: string;
  subHeading: string;
  buttonLabel: string;
};

const LIST_OF_PLAN_SHEET_ACTIONS = [
  'free-starter',
  'free-pro',
  'starter-pro',
  'starter-free',
  'pro-free',
  'pro-starter',
  'free-free',
  'pro-pro',
  'starter-starter',
] as const;

export type PlanSheetActions = (typeof LIST_OF_PLAN_SHEET_ACTIONS)[number];

export type BillingPlanSheetActions = Extract<
  PlanSheetActions,
  'free-free' | 'pro-pro' | 'starter-starter'
>;

export type UpgradePlanSheetActions = Exclude<
  PlanSheetActions,
  'free-free' | 'pro-pro' | 'starter-starter'
>;

export const checkIfAction = (s: string) => {
  if (LIST_OF_PLAN_SHEET_ACTIONS.includes(s as any)) {
    return s as PlanSheetActions;
  }

  throw new Error('Invalid action');
};

export const checkIfUpgradeAction = (s: string) => {
  if (
    LIST_OF_PLAN_SHEET_ACTIONS.includes(s as any) &&
    !['free-free', 'pro-pro', 'starter-starter'].includes(s)
  ) {
    return s as UpgradePlanSheetActions;
  }

  throw new Error('Invalid action');
};

export const checkIfPlan = (s: string) => {
  if (['free', 'starter', 'pro'].includes(s)) {
    return s as Plan;
  }

  throw new Error('Invalid plan');
};

export type PLAN_SHEET_DATA = Record<UpgradePlanSheetActions, PLAN_SHEET>;
