import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { AnimatePresence, LayoutGroup, motion, Reorder } from 'framer-motion';

// import { flushSync } from 'react-dom';
import {
  Dropdown,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useOnClickOutside } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { BoardLvl1, BoardLvl2, BoardLvl3 } from '@api/designs';
import {
  $isShowElementsPanel,
  $isShowFilesPanel,
} from '@pages/DocPage/model/sidebar-opening';
import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { TabWithHandlers } from '@src/entities/doc/ui/tabs';

import styles from './styles.module.css';

export const Tabs = <T extends TabWithHandlers>(props: {
  tabs: Array<T>;
  isLoadingTabs: boolean;
  reorderTabs: (values: Array<string>) => void;
  closeTab: (id: string) => void;
  postCloseAction?: () => void;
  canDeleteTab: boolean;
  deleteTab: (id: string) => void;
  saveTabName: (id: string, name: string) => void;
  newTabAction: () => void;
  selectedId: string;
  board?: BoardLvl1 | BoardLvl2 | BoardLvl3 | null;
}) => {
  const {
    tabs,
    reorderTabs,
    isLoadingTabs,
    closeTab,
    postCloseAction,
    canDeleteTab,
    deleteTab,
    saveTabName,
    newTabAction,
    selectedId,
    board,
  } = props;

  return (
    <Tab.Container values={tabs.map((t) => t.id)} onReorder={reorderTabs}>
      <LayoutGroup>
        <AnimatePresence
          mode="popLayout"
          onExitComplete={() => {
            if (postCloseAction) postCloseAction();
          }}
        >
          {!isLoadingTabs || !tabs.length
            ? tabs.map((t) => {
                return (
                  <Tab
                    key={t.id}
                    tab={t}
                    closeTab={closeTab}
                    canDeleteTab={canDeleteTab}
                    deleteTab={deleteTab}
                    saveTabName={saveTabName}
                    isSelected={t.id === selectedId}
                    board={board}
                  />
                );
              })
            : null}
          <motion.button
            onClick={newTabAction}
            layout
            className={styles.addButton}
          >
            <Icon name="sprite/plus" />
          </motion.button>
        </AnimatePresence>
      </LayoutGroup>
    </Tab.Container>
  );
};

export const Tab = (props: {
  tab: TabWithHandlers;
  closeTab: (id: string) => void;
  deleteTab: (id: string) => void;
  canDeleteTab: boolean;
  saveTabName: (id: string, name: string) => void;
  isSelected: boolean;
  board?: BoardLvl1 | BoardLvl2 | BoardLvl3 | null;
}) => {
  const {
    tab: { id, onClick, label, menuItems },
    closeTab,
    deleteTab,
    canDeleteTab,
    saveTabName,
    isSelected,
    board,
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);
  const labelRef = useRef<HTMLSpanElement | null>(null);

  const [isHovered, setIsHovered] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isShowTooltip, setShowTooltip] = useState(false);

  const closeTabAction = () => closeTab(id);

  useOnClickOutside(ref, () => {
    if (
      isRenaming &&
      labelRef &&
      labelRef.current &&
      labelRef.current.innerText
    ) {
      saveTabName(id, labelRef.current.innerText);
    }
    setIsRenaming(false);
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isSelected) {
      timer = setTimeout(() => {
        setShowTooltip(true);
      }, 300);
    } else {
      setShowTooltip(false);
    }

    return () => clearTimeout(timer);
  }, [isSelected]);

  return isSelected && board ? (
    <Tooltip
      parameter={{
        description: <Breadcrumbs board={board} />,
        type: 'plain',
        position: 'bottom',
      }}
      style={{
        maxWidth: '230px',
        width: 'max-content',
        minWidth: 'min-content',
        display: isShowTooltip ? 'flex' : 'none',
      }}
    >
      <Reorder.Item
        value={id}
        draggable={!isRenaming}
        drag="x"
        onDragEnd={(e) => e.stopPropagation()}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, y: -48 }}
        layoutScroll
        layout="position"
        as="div"
        className={styles.tabMain}
        ref={ref}
      >
        <div
          className={cn(styles.tabContainer, {
            [styles.tabContainerSelected]: isSelected,
          })}
        >
          {isSelected && menuItems && menuItems.length > 0 ? (
            <Dropdown open={showDropdown} onOpenChange={setShowDropdown}>
              <Dropdown.Menu
                trigger={
                  isSelected && (
                    <button
                      className={styles.dropdownButton}
                      onClick={() => setShowDropdown((o) => !o)}
                    >
                      <Icon name="sprite/caret-down" />
                    </button>
                  )
                }
                side="bottom"
                sideOffset={20}
                align="start"
                alignOffset={-13}
                density="-2"
              >
                {/* <Dropdown.SubMenu
              icon={<Icon name="sprite/download" />}
              subTriggerByClick="asd"
            >
              {subMenuItems &&
                subMenuItems.map((item, index) => (
                  <Dropdown.SubMenuItem key={index} item={item} />
                ))}
            </Dropdown.SubMenu> */}
                {menuItems.map((item) => (
                  <Dropdown.MenuItem key={item.content} item={item} />
                ))}
                <Dropdown.MenuItem
                  key="rename"
                  item={{
                    leadingIcon: <Icon name="sprite/pen" />,
                    content: 'Rename',
                    isDivider: true,
                    onClick: () => {
                      setIsRenaming(true);
                      // flushSync(() => {
                      //   setTimeout(() => {
                      //     if (!labelRef.current) return;
                      //     const label = labelRef.current;
                      //     label.focus();
                      //     const range = document.createRange();
                      //     const selection = window.getSelection();
                      //     range.selectNodeContents(label);
                      //     range.collapse(false);
                      //     selection?.removeAllRanges();
                      //     selection?.addRange(range);
                      //   }, 0);
                      // });
                    },
                  }}
                />
                {canDeleteTab ? (
                  <Dropdown.MenuItem
                    key="delete"
                    item={{
                      leadingIcon: (
                        <Icon name="sprite/bin" color="var(--color-error)" />
                      ),
                      content: 'Delete',
                      onClick: () => deleteTab(id),
                      classNameContent: styles.delete,
                    }}
                  />
                ) : null}
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
          <TypographyPoppins type="label" labelSize="L">
            <span
              role="textbox"
              tabIndex={1}
              onClick={(e) => {
                if (isRenaming) e.stopPropagation();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();

                  if (!labelRef.current) return;
                  saveTabName(id, labelRef.current.innerText);
                  setIsRenaming(false);
                }
                if (e.key === 'Escape') {
                  e.preventDefault();
                  setIsRenaming(false);
                  if (!labelRef.current) return;
                  labelRef.current.innerText = label;
                }
              }}
              ref={labelRef}
              contentEditable={isRenaming}
              suppressContentEditableWarning
              className={cn(styles.textarea, {
                [styles.editing]: isRenaming,
              })}
              dangerouslySetInnerHTML={{ __html: label ?? 'Untitled' }}
            />
          </TypographyPoppins>
          {(isSelected && isHovered && !isRenaming) || showDropdown ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                closeTabAction();
              }}
              className={styles.closeButton}
            >
              <Icon name="sprite/x" />
            </button>
          ) : null}
          {isRenaming && isSelected ? (
            <button
              onClick={() => {
                if (!labelRef.current) return;
                saveTabName(id, labelRef.current.innerText);
                setIsRenaming(false);
              }}
              className={styles.closeButton}
            >
              <Icon name="sprite/tick" />
            </button>
          ) : null}
        </div>
      </Reorder.Item>
    </Tooltip>
  ) : (
    <Reorder.Item
      value={id}
      draggable={!isRenaming}
      drag="x"
      onDragEnd={(e) => e.stopPropagation()}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, y: -48 }}
      layoutScroll
      layout="position"
      as="div"
      className={styles.tabMain}
      ref={ref}
    >
      <div
        className={cn(styles.tabContainer, {
          [styles.tabContainerSelected]: isSelected,
        })}
      >
        {isSelected && menuItems && menuItems.length > 0 ? (
          <Dropdown open={showDropdown} onOpenChange={setShowDropdown}>
            <Dropdown.Menu
              trigger={
                isSelected && (
                  <button
                    className={styles.dropdownButton}
                    onClick={() => setShowDropdown((o) => !o)}
                  >
                    <Icon name="sprite/caret-down" />
                  </button>
                )
              }
              side="bottom"
              sideOffset={20}
              align="start"
              alignOffset={-13}
              density="-2"
            >
              {/* <Dropdown.SubMenu
              icon={<Icon name="sprite/download" />}
              subTriggerByClick="asd"
            >
              {subMenuItems &&
                subMenuItems.map((item, index) => (
                  <Dropdown.SubMenuItem key={index} item={item} />
                ))}
            </Dropdown.SubMenu> */}
              {menuItems.map((item) => (
                <Dropdown.MenuItem key={item.content} item={item} />
              ))}
              <Dropdown.MenuItem
                key="rename"
                item={{
                  leadingIcon: <Icon name="sprite/pen" />,
                  content: 'Rename',
                  isDivider: true,
                  onClick: () => {
                    setIsRenaming(true);
                    // flushSync(() => {
                    //   setTimeout(() => {
                    //     if (!labelRef.current) return;
                    //     const label = labelRef.current;
                    //     label.focus();
                    //     const range = document.createRange();
                    //     const selection = window.getSelection();
                    //     range.selectNodeContents(label);
                    //     range.collapse(false);
                    //     selection?.removeAllRanges();
                    //     selection?.addRange(range);
                    //   }, 0);
                    // });
                  },
                }}
              />
              {canDeleteTab ? (
                <Dropdown.MenuItem
                  key="delete"
                  item={{
                    leadingIcon: (
                      <Icon name="sprite/bin" color="var(--color-error)" />
                    ),
                    content: 'Delete',
                    onClick: () => deleteTab(id),
                    classNameContent: styles.delete,
                  }}
                />
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        <TypographyPoppins type="label" labelSize="L">
          <span
            role="textbox"
            tabIndex={1}
            onClick={(e) => {
              if (isRenaming) e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();

                if (!labelRef.current) return;
                saveTabName(id, labelRef.current.innerText);
                setIsRenaming(false);
              }
              if (e.key === 'Escape') {
                e.preventDefault();
                setIsRenaming(false);
                if (!labelRef.current) return;
                labelRef.current.innerText = label;
              }
            }}
            ref={labelRef}
            contentEditable={isRenaming}
            suppressContentEditableWarning
            className={cn(styles.textarea, {
              [styles.editing]: isRenaming,
            })}
            dangerouslySetInnerHTML={{ __html: label ?? 'Untitled' }}
          />
        </TypographyPoppins>
        {(isSelected && isHovered && !isRenaming) || showDropdown ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              closeTabAction();
            }}
            className={styles.closeButton}
          >
            <Icon name="sprite/x" />
          </button>
        ) : null}
        {isRenaming && isSelected ? (
          <button
            onClick={() => {
              if (!labelRef.current) return;
              saveTabName(id, labelRef.current.innerText);
              setIsRenaming(false);
            }}
            className={styles.closeButton}
          >
            <Icon name="sprite/tick" />
          </button>
        ) : null}
      </div>
    </Reorder.Item>
  );
};

type ContainerProps = {
  children: React.ReactNode;
  values: Array<string>;
  onReorder: (values: Array<string>) => void;
};

Tab.Container = ({ children, values, onReorder }: ContainerProps) => {
  const isShowElementsPanel = useUnit($isShowElementsPanel);
  const isShowFilesPanel = useUnit($isShowFilesPanel);
  return (
    <Reorder.Group
      axis="x"
      values={values}
      onReorder={onReorder}
      as="div"
      className={cn(styles.container, {
        [styles.showDocSidebar]: isShowElementsPanel || isShowFilesPanel,
      })}
    >
      {children}
    </Reorder.Group>
  );
};
