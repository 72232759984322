import { Helmet } from 'react-helmet-async';
import { Route, Switch, useRouteMatch } from 'react-router';

import { Billing } from '@pages/AccountPage/components/Billing';
import { ErrorBoundary } from '@sentry/react';
import { Profile } from '@widgets/profile';

export const AccountRouter = () => {
  const { path } = useRouteMatch();

  const fallback = <p>An Error Occurred</p>;

  return (
    <div>
      <AccountTitle />
      <Switch>
        <Route path={`${path}/profile`}>
          <Profile />
        </Route>
        <Route path={`${path}/billing/:result`}>
          <ErrorBoundary fallback={fallback}>
            <Billing />
          </ErrorBoundary>
        </Route>
        <Route path={`${path}/billing`}>
          <ErrorBoundary fallback={fallback}>
            <Billing />
          </ErrorBoundary>
        </Route>
        <Route>Not Found</Route>
      </Switch>
    </div>
  );
};

export const AccountTitle = () => {
  return (
    <Helmet>
      <title>Account | Visualist</title>
    </Helmet>
  );
};
