import { memo } from 'react';

import { BoardCreationDialog } from './board/creation';
import { MoveBoardDialog } from './board/move-board';
import { WarningDialog } from './board/move-board/warning';
import { BoardRenamingDialog } from './board/renaming';
import { ColorCodesDialog } from './color-codes';
import { HubCreationDialog } from './hub/creation';
import { HubRenamingDialog } from './hub/renaming';
import { PaletteCreationDialog } from './palette';

export const Dialogs = memo(() => (
  <>
    <HubCreationDialog />
    <HubRenamingDialog />
    <BoardCreationDialog />
    <BoardRenamingDialog />
    <MoveBoardDialog />
    <WarningDialog />
    <PaletteCreationDialog />
    <ColorCodesDialog />
  </>
));
