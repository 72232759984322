import React, { ReactNode } from 'react';

import cn from 'classnames';

import TypographyPoppins from '../Styles/Typography/TypographyPoppins';
import { LeadingIcon } from './LeadingIcon';
import { TrailingIcon } from './TrailingIcon';

import styles from './styles.module.css';

export type TabsStyle =
  | 'label only'
  | 'icon on top + label'
  | 'leading icon + label'
  | 'leading icon + trailing icon + label';

export interface TabProps {
  type?: TabsStyle;
  className?: string;
  icon?: ReactNode;
  iconTrailing?: ReactNode;
  onClickTab?: () => void;
  onClickIconTrailing?: () => void;
  children?: ReactNode;
  isDisabled?: boolean;
  isSelected?: boolean;
}

export const Tab = ({
  type,
  className,
  icon,
  iconTrailing,
  onClickTab,
  onClickIconTrailing,
  children,
  isDisabled,
  isSelected,
}: TabProps) => {
  const isShowLeadingIcon =
    (type === 'leading icon + label' ||
      type === 'icon on top + label' ||
      type === 'leading icon + trailing icon + label') &&
    icon;

  const isShowTrailingIcon =
    type === 'leading icon + trailing icon + label' && iconTrailing;

  const onClickTabHandler = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    if (onClickTab && !isDisabled) {
      onClickTab();
    }
  };

  const onClickIconTrailingHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    if (onClickIconTrailing) {
      onClickIconTrailing();
    }
  };

  return (
    <li
      className={cn(styles.tab, className, {
        [styles.tabIconOnTop]: type === 'icon on top + label',
        [styles.tabIconTrailing]:
          type === 'leading icon + trailing icon + label',
        [styles.selected]: isSelected && type !== 'icon on top + label',
        [styles.disabled]: isDisabled,
      })}
      onClick={onClickTabHandler}
    >
      <div
        className={cn(styles.content, {
          [styles.contentIconTop]: type === 'icon on top + label',
        })}
      >
        {isShowLeadingIcon && (
          <LeadingIcon icon={icon} isDisabled={isDisabled} type={type} />
        )}
        <TypographyPoppins
          type="label"
          size="L"
          className={cn(styles.label, {
            [styles.selectedLabel]: isSelected,
            [styles.disabledLabel]: isDisabled,
          })}
        >
          {children}
        </TypographyPoppins>
      </div>
      {isShowTrailingIcon && (
        <TrailingIcon
          iconTrailing={iconTrailing}
          isDisabled={isDisabled}
          isSelected={isSelected}
          onClickIconTrailingHandler={onClickIconTrailingHandler}
          type={type}
        />
      )}
      {type === 'icon on top + label' && isSelected && (
        <span className={styles.tabIconOnTopSelected} />
      )}
    </li>
  );
};
