import { useEffect, useRef } from 'react';

import { useUnit } from 'effector-react';
import { HelmetProvider } from 'react-helmet-async';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { Modal, SnackBar } from '@visualist/design-system/src/components/v2';
import { SnackError } from '@visualist/design-system/src/components/v2/SnackBar/types';

import { LookupKeyHubCredit } from '@api/billing';
import { AccountPage } from '@pages/account-page';
import { HubCreditSheet } from '@pages/AccountPage/components/HubCreditSheet';
import { PlanSheet } from '@pages/AccountPage/components/PlanSheet';
import { TrialSheet } from '@pages/AccountPage/components/TrialSheet';
import {
  openedCancelPlanModal,
  openedHubCreditSheet,
  openedPlanModal,
  openedTrialSheet,
} from '@pages/AccountPage/models';
import { DocPageV2 } from '@pages/DocPage';
import {
  $isFileCardOpen,
  $isOpenSearchBoardsModalWindow,
  searchBoardsModalWindowClosed,
} from '@pages/FileCard/model';
import { SearchBoards } from '@pages/FileCard/SearchBoards';
import { Login } from '@pages/new-auth';
import { Forgot } from '@pages/new-auth/forgot';
import { Reset } from '@pages/new-auth/reset';
import { Signup } from '@pages/new-auth/signup';
import * as Sentry from '@sentry/react';
import { REDIRECT_TO_HUB_OR_BOARD_NEW_USER } from '@src/shared/constants/variables-local-storage';
//@ts-ignore
import TawkMessenger from '@tawk.to/tawk-messenger-react';

import { CentrifugeConnect } from './app/Centrifuge/Centrifuge';
import { useAppData } from './AppContext';
import { Debug } from './entities/debug';
import { DeleteModals } from './entities/delete-modals';
import { Dialogs } from './entities/dialogs';
import { MainSidebar } from './entities/navbar/sidebars/common';
import { Popups } from './entities/popups';
import { SearchDialog } from './entities/search/ui/searchDialog';
import { DocShareSheet } from './entities/share-sheet/doc';
import {
  ActionItemsPage,
  BoardPage,
  HomePage,
  HubPage,
  LibraryPage,
  MessagesPage,
  StudioPage,
} from './pages';
import { NotFound } from './pages/NotFoundPage';
import { getRoute } from './routes';
import { getCookie } from './shared/utils';
import { Onboarding } from './widgets/onboarding';
import { DemoLoading } from './widgets/onboarding/onboarding-screens/demo-loading';
import { DemoCompletionScreen } from './widgets/onboarding/onboarding-screens/demoCompletion';
import { VaiMain } from './widgets/vai-main';

import '@visualist/design-system/src/components/v2/theme-v2.css';
import '@visualist/design-system/src/theme.css';
import 'animate.css/animate.min.css';
import 'react-notifications-component/dist/theme.css';
import './App.scss';
import styles from './App.module.css';

const SentryRoute = Sentry.withSentryRouting(Route);

export function App() {
  const { user } = useAppData();
  const { pathname } = useLocation();

  const tawkMessengerRef = useRef<any>();

  const [isOpenSearchBoardsModalWindow, isFileCardOpen] = useUnit([
    $isOpenSearchBoardsModalWindow,
    $isFileCardOpen,
  ]);

  useEffect(() => {
    const token = getCookie('access_token');

    window.top?.window.postMessage(
      { event_id: 'token_send', data: { token } },
      '*',
    );
  }, []);

  const toggleChatWidget = () => {
    if (tawkMessengerRef.current) {
      tawkMessengerRef.current.toggle();
    }
  };

  const redirectToSignup = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    // The description of the purpose of the logic for saving to local storage, see the file variables-local-storage.ts
    if (pathname === '/registration' && urlSearchParams.has('next')) {
      const nextValue = urlSearchParams.get('next');
      localStorage.setItem(REDIRECT_TO_HUB_OR_BOARD_NEW_USER, nextValue ?? '');
    }

    return getRoute('registration');
  };
  return (
    <>
      <main className="App">
        <HelmetProvider>
          {/* <Partytown forward={['dataLayer.push']} /> */}
          <div className="tipPopupGlobalBackdrop" />
          <>
            {user ? (
              <>
                <CentrifugeConnect user={user}>
                  <div className="d-flex" id="d-flex">
                    <Sentry.ErrorBoundary
                      onError={() => {
                        console.error('onboarding crashing');
                      }}
                    >
                      <Onboarding />
                    </Sentry.ErrorBoundary>
                    <DemoCompletionScreen />
                    <DemoLoading />
                    <MainSidebar>
                      <TawkMessenger
                        propertyId={import.meta.env.VITE_TAWK_PROPERTY_ID}
                        widgetId={import.meta.env.VITE_TAWK_WIDGET_ID}
                        ref={tawkMessengerRef}
                        toggleChatWidget={toggleChatWidget}
                      />
                      <Switch>
                        <SentryRoute exact path="/">
                          <Redirect to="/home" />
                        </SentryRoute>
                        <SentryRoute exact path="/signup">
                          <Redirect to="/home" />
                        </SentryRoute>
                        <SentryRoute
                          path={getRoute('actionItems')}
                          component={ActionItemsPage}
                        />
                        <SentryRoute
                          path="/home/:result"
                          component={HomePage}
                        />
                        <SentryRoute path="/home" component={HomePage} />
                        <SentryRoute
                          path={getRoute('library')}
                          component={LibraryPage}
                        />
                        <SentryRoute
                          path={getRoute('messages')}
                          component={MessagesPage}
                        />
                        <SentryRoute
                          path={getRoute('account')}
                          component={AccountPage}
                        />
                        <SentryRoute
                          path="/board/:board_id"
                          component={BoardPage}
                        />
                        <SentryRoute path="/h/:hub_id" component={HubPage} />
                        <SentryRoute path="/d/:doc_id" component={DocPageV2} />
                        <SentryRoute
                          path="/studio/:design_id"
                          component={StudioPage}
                        />
                        <SentryRoute path="/studio" component={StudioPage} />
                        <SentryRoute component={NotFound} />
                      </Switch>
                    </MainSidebar>
                  </div>
                  {/* </div> */}
                </CentrifugeConnect>
                {/* TODO temporary for the vai icon to show on any screen */}
                <SearchDialog />
                <VaiMain />
                <TrialSheet />
                <PlanSheet />
                <DocShareSheet />
                <DeleteModals />
                <Dialogs />
                <Popups />
                {/* <TryProPlanModal /> */}
                <HubCreditSheet />
                <Modal
                  className={styles.searchBoardsModal}
                  scrim="Surface container low, 32%"
                  handleClose={() => searchBoardsModalWindowClosed()}
                  showModal={isOpenSearchBoardsModalWindow && isFileCardOpen}
                >
                  <SearchBoards />
                </Modal>
              </>
            ) : (
              <>
                <Switch>
                  <SentryRoute exact path="/">
                    <Redirect to="/login" />
                  </SentryRoute>
                  <SentryRoute
                    path="/r/:referralCode"
                    render={({ match }) => (
                      <Redirect
                        to={`/signup/?referral=${match.params.referralCode}`}
                      />
                    )}
                  />
                  <SentryRoute
                    path={getRoute('signup')}
                    exact
                    component={Signup}
                  />
                  <Redirect path={redirectToSignup()} to={getRoute('signup')} />
                  <SentryRoute
                    path={getRoute('forgotPassword')}
                    exact
                    component={Forgot}
                  />
                  <SentryRoute
                    path={'/new-reset-password'}
                    exact
                    component={Reset}
                  />
                  <SentryRoute
                    path={getRoute('login')}
                    exact
                    component={Login}
                  />
                  <SentryRoute exact path="/r/:referralCode"></SentryRoute>
                </Switch>
              </>
            )}
          </>
        </HelmetProvider>
      </main>
      <SnackBar handleSnackError={handleSnackError} />
      <Debug />
    </>
  );
}

const handleSnackError = (error: SnackError, hubBundleId?: string) => {
  // Special snack handler for after checkout page reloads. Usses local storage to check error.
  const s = hubBundleId as LookupKeyHubCredit;

  switch (error) {
    case 'hub-checkout-failure':
      if (hubBundleId) openedHubCreditSheet({ bundleId: s });
      return;
    case 'pro-hobby-checkout-failure':
      openedPlanModal({
        isPlanModalOpen: true,
        mode: 'upgrade-downgrade',
        planModalMode: 'pro-starter',
        planToChange: 'pro',
      });
      return;
    case 'pro-free-checkout-failure':
      openedPlanModal({
        isPlanModalOpen: true,
        mode: 'upgrade-downgrade',
        planModalMode: 'pro-free',
        planToChange: 'pro',
      });
      return;
    case 'hobby-pro-checkout-failure':
      openedPlanModal({
        isPlanModalOpen: true,
        mode: 'upgrade-downgrade',
        planModalMode: 'starter-pro',
        planToChange: 'starter',
      });
      return;
    case 'hobby-free-checkout-failure':
      openedPlanModal({
        isPlanModalOpen: true,
        mode: 'upgrade-downgrade',
        planModalMode: 'starter-free',
        planToChange: 'starter',
      });
      return;
    case 'free-hobby-checkout-failure':
      openedPlanModal({
        isPlanModalOpen: true,
        mode: 'upgrade-downgrade',
        planModalMode: 'free-starter',
        planToChange: 'free',
      });
      return;
    case 'free-pro-checkout-failure':
      openedPlanModal({
        isPlanModalOpen: true,
        mode: 'upgrade-downgrade',
        planModalMode: 'free-pro',
        planToChange: 'free',
      });
      return;
    case 'trial-checkout-failure':
      openedTrialSheet();
      return;
    case 'cancel-failure':
      openedCancelPlanModal();
      return;
    default:
      return;
  }
};
