import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector';

import { SelectedImage } from './files-selection';

const SHAPES_NUM = 24;
const PATTERNS_NUM = 36;

export const getElements = createEvent();

const getShapesFx = createEffect(() => {
  const shapes: SelectedImage[] = [];

  for (let i = 1; i <= SHAPES_NUM; i++) {
    shapes.push({
      id: `shape${i}`,
      url: `https://my.visualistapp.com/public/media/elements/shapes/shape${i}.png`,
      thumbnailUrl: `https://my.visualistapp.com/public/media/elements/shapes/shape${i}-thumb.png`,
      height: 0,
      width: 0,
    });
  }

  return shapes;
});

const getPatternsFx = createEffect(() => {
  const patterns: SelectedImage[] = [];

  for (let i = 1; i <= PATTERNS_NUM; i++) {
    patterns.push({
      id: `pattern${i}`,
      url: `https://my.visualistapp.com/public/media/elements/patterns/pattern${i}.png`,
      height: 0,
      width: 0,
      thumbnailUrl: `https://my.visualistapp.com/public/media/elements/patterns/pattern${i}-thumb.png`,
    });
  }

  return patterns;
});

sample({ clock: getElements, target: [getShapesFx, getPatternsFx] });

export const $shapes = createStore<SelectedImage[]>([]);
export const $patterns = createStore<SelectedImage[]>([]);

sample({ clock: getShapesFx.doneData, target: $shapes });

sample({ clock: getPatternsFx.doneData, target: $patterns });

const $all = combine($shapes, $patterns, (shapes, patterns) => {
  return [...shapes, ...patterns];
});

export const allElementsSelected = createEvent();
export const allElementsUnselected = createEvent();
export const shapesSelected = createEvent();
export const patternsSelected = createEvent();

export const $isAllElementsSelected = createStore(false);
export const $isShapesSelected = createStore(true);
export const $isPatternsSelected = createStore(true);

$isAllElementsSelected
  .on(allElementsSelected, () => true)
  .on(allElementsUnselected, () => false);

$isShapesSelected
  .on(shapesSelected, (state) => !state)
  .reset(allElementsSelected);

$isPatternsSelected
  .on(patternsSelected, (state) => !state)
  .reset(allElementsSelected);

// If all options other than 'All' are selected or no option is selected then - automatically switch to 'All' being selected
// sample({
//   clock: [$isShapesSelected, $isPatternsSelected],
//   source: {
//     isShapesSelected: $isShapesSelected,
//     isPatternsSelected: $isPatternsSelected,
//   },
//   filter: ({ isShapesSelected, isPatternsSelected }) =>
//     (isShapesSelected && isPatternsSelected) ||
//     (!isShapesSelected && !isPatternsSelected),
//   target: allElementsSelected,
// });

// 'All' becomes unselected if another option is selected
// sample({
//   clock: [$isShapesSelected, $isPatternsSelected],
//   source: {
//     isShapesSelected: $isShapesSelected,
//     isPatternsSelected: $isPatternsSelected,
//   },
//   filter: ({ isShapesSelected, isPatternsSelected }) =>
//     isShapesSelected || isPatternsSelected,
//   target: allElementsUnselected,
// });

sample({
  clock: [$isShapesSelected, $isPatternsSelected],
  source: {
    isShapesSelected: $isShapesSelected,
    isPatternsSelected: $isPatternsSelected,
  },
  filter: ({ isShapesSelected, isPatternsSelected }) =>
    !isShapesSelected && !isPatternsSelected,
  target: [shapesSelected, patternsSelected],
});

export const $filteredElements = createStore<SelectedImage[]>([]);

// All varianst of displayed elements
sample({
  clock: [$all, $isShapesSelected, $isPatternsSelected],
  source: {
    all: $all,
    isShapesSelected: $isShapesSelected,
    isPatternsSelected: $isPatternsSelected,
    shapes: $shapes,
    patterns: $patterns,
  },
  fn: ({ all, isShapesSelected, isPatternsSelected, shapes, patterns }) => {
    if (isShapesSelected && !isPatternsSelected) {
      return shapes;
    } else if (!isShapesSelected && isPatternsSelected) {
      return patterns;
    } else {
      return all;
    }
  },
  target: $filteredElements,
});
