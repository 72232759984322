import React from 'react';

import { StickyType } from '@src/entities/Stickies/types';
import { EmptySticky } from '@src/entities/Stickies/useStickies';

import {
  CARD_SIZE,
  EDITING_CARD_SIZE,
  EXPANDED_CARD_SIZE,
  PADDING_SIZE,
} from '../constants';

type StickyPositionData = {
  x: number;
  y: number;
  width: number;
  height: number;
  position: {
    x: number;
    y: number;
  };
  setPosition: (position: { x: number; y: number }) => void;
};

export const useStickyPosition = (props: {
  sticky: StickyType | EmptySticky;
  position: {
    x: number;
    y: number;
  };
  isEditing: boolean;
  containerWidth: number;
  containerHeight: number;
  imageHeight: number;
  imageWidth: number;
  saveSticky: () => void;
  text: string;
}) => {
  const [position, setPosition] = React.useState({
    // HACK fix for old stickies
    x: props.position.x,
    y: props.position.y,
  });

  const { t, l } = translateRatioToStickyPosition({
    containerHeight: props.containerHeight,
    containerWidth: props.containerWidth,
    imageHeight: props.imageHeight,
    imageWidth: props.imageWidth,
    topPixel: position.y,
    leftPixel: position.x,
  });

  const { x, y, height, width } = calculateStickyPositions({
    isEditing: props.isEditing,
    left: l,
    top: t,
    containerWidth: props.containerWidth,
    containerHeight: props.containerHeight,
  });

  React.useEffect(() => {
    // Update position if sticky is updated i.e. if the query client is updated. Used for repositioning placeholder stickies position mainly
    if (props.sticky.id === 'new-sticky' && props.text) {
      // If new sticky and has text, do not update position, instead save it.
      props.saveSticky();
      return;
    }
    setPosition({
      // HACK fix for old stickies
      x: props.sticky.left_pixel > 1 ? 0 : props.sticky.left_pixel,
      y: props.sticky.top_pixel > 1 ? 0 : props.sticky.top_pixel,
    });
  }, [props.sticky.left_pixel, props.sticky.top_pixel]);

  return {
    position,
    setPosition,
    x,
    y,
    height,
    width,
  } satisfies StickyPositionData;
};

/**
 * @name calculateStickyPositions
 * @description Calculate the position of the sticky based on the container size and the sticky size. Push sticky into the frame. When sticky is being editied use the new positions when activating sticky mode
 * @returns
 */

const calculateStickyPositions = ({
  containerWidth,
  isEditing,
  left,
  top,
  containerHeight,
}: {
  isEditing: boolean;
  left: number;
  top: number;
  containerWidth: number;
  containerHeight: number;
}) => {
  // Set default values
  let x = left;
  let y = top;
  let width = CARD_SIZE;
  let height = CARD_SIZE;

  if (x < 0) {
    x = PADDING_SIZE / 2;
  }
  // Check right side
  if (x + CARD_SIZE + PADDING_SIZE > containerWidth) {
    x = containerWidth - CARD_SIZE - PADDING_SIZE;
  }
  // Check if touching the top of the image, push it down a bit
  if (y < -0.5) {
    y = PADDING_SIZE / 2;
  }

  // Check if touching the bottom of the image, push it up a bit
  if (y + CARD_SIZE + PADDING_SIZE > containerHeight) {
    y = containerHeight - CARD_SIZE - PADDING_SIZE;
  }

  if (isEditing) {
    // Edit Mode values
    // Calculation for the card to grow evenly instead of to one side
    x =
      x -
      Math.abs(EXPANDED_CARD_SIZE - (CARD_SIZE / 2 + EXPANDED_CARD_SIZE / 2));
    width = EXPANDED_CARD_SIZE;
    height = EDITING_CARD_SIZE;

    // Check if card is out of bounds
    // Checking left side
    if (x < 0) {
      x = PADDING_SIZE / 2;
    }
    // Check right side
    if (x + width + PADDING_SIZE > containerWidth) {
      x = containerWidth - width - PADDING_SIZE - PADDING_SIZE / 2;
    }

    // Check if image size is small, if so center the edit card to the middle
    if (containerWidth < 400) {
      x = Math.abs(containerWidth - 324) / 2 - PADDING_SIZE / 2;
    }

    // Check if touching the top of the image, push it down a bit
    if (y < 4) {
      y = PADDING_SIZE / 2;
    }
  }

  return { x, y, width, height };
};

const translateRatioToStickyPosition = ({
  containerHeight,
  containerWidth,
  imageHeight,
  imageWidth,
  leftPixel,
  topPixel,
}: {
  containerHeight: number;
  containerWidth: number;
  imageHeight: number;
  imageWidth: number;
  topPixel: number;
  leftPixel: number;
}) => {
  const yPositionOnImage = imageHeight * topPixel;
  const xPositionOnImage = imageWidth * leftPixel;

  const t = ((containerHeight - PADDING_SIZE) * yPositionOnImage) / imageHeight;
  const l = ((containerWidth - PADDING_SIZE) * xPositionOnImage) / imageWidth;

  return {
    t,
    l,
  };
};
