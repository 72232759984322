import { Icon, IconProps } from './Icon';

export const ImageColored = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M4.5 21.5V2.5H19C19.2761 2.5 19.5 2.72386 19.5 3V21.5H4.5Z"
        stroke="black"
        strokeLinecap="square"
      />
      <path d="M5 21V11.5L7.5 9.5L17 18L19 16V21H5Z" fill="#B3D4C1" />
      <mask
        id="mask0_16826_8080"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="2"
        width="16"
        height="19"
      >
        <path d="M4 21V2H20V21H4Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_16826_8080)">
        <path d="M4 12L7.55556 9L16.8889 17.5L20 14" stroke="black" />
      </g>
      <rect
        x="8.4"
        y="4.4"
        width="2.2"
        height="1.2"
        rx="0.6"
        fill="#FFE2B5"
        stroke="black"
        strokeWidth="0.8"
      />
      <path
        d="M11.4 8C11.4 7.66863 11.6686 7.4 12 7.4H17C17.3314 7.4 17.6 7.66863 17.6 8C17.6 8.33137 17.3314 8.6 17 8.6H12C11.6686 8.6 11.4 8.33137 11.4 8Z"
        fill="#FFE2B5"
        stroke="black"
        strokeWidth="0.8"
      />
    </Icon>
  );
};
