import { Icon, IconProps } from './Icon';

export const TeamIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 11.25C11.433 11.25 13 9.683 13 7.75C13 5.817 11.433 4.25 9.5 4.25C7.567 4.25 6 5.817 6 7.75C6 9.683 7.567 11.25 9.5 11.25ZM9.5 10.25C10.8807 10.25 12 9.13071 12 7.75C12 6.36929 10.8807 5.25 9.5 5.25C8.11929 5.25 7 6.36929 7 7.75C7 9.13071 8.11929 10.25 9.5 10.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.10313 12.7502C6.07289 12.7379 5.04873 13.2539 4.29276 14.0371C3.53111 14.8263 3 15.9279 3 17.152V20.25H16V17.152C16 14.7514 14.1097 12.7502 11.9 12.7502H7.10313ZM5.01229 14.7316C4.40559 15.3602 4 16.2208 4 17.152V19.25H15V17.152C15 15.2421 13.4978 13.7502 11.9 13.7502H7.09344C6.39824 13.7411 5.62383 14.098 5.01229 14.7316Z"
        fill="currentColor"
      />
      <path
        d="M16.4 13.2502C16.4 12.9741 16.6239 12.7502 16.9 12.7502C19.1097 12.7502 21 14.7514 21 17.152V20.25H16.9C16.6239 20.25 16.4 20.0261 16.4 19.75C16.4 19.4739 16.6239 19.25 16.9 19.25H20V17.152C20 15.2421 18.4978 13.7502 16.9 13.7502C16.6239 13.7502 16.4 13.5264 16.4 13.2502Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 9C19.5 10.5188 18.2688 11.75 16.75 11.75C15.2312 11.75 14 10.5188 14 9C14 7.48122 15.2312 6.25 16.75 6.25C18.2688 6.25 19.5 7.48122 19.5 9ZM18.65 9C18.65 10.0493 17.7993 10.9 16.75 10.9C15.7007 10.9 14.85 10.0493 14.85 9C14.85 7.95066 15.7007 7.1 16.75 7.1C17.7993 7.1 18.65 7.95066 18.65 9Z"
        fill="currentColor"
      />
    </Icon>
  );
};
