import React, { ReactNode } from 'react';

import cn from 'classnames';

import styles from './styles.module.css';

export type Variant = 'body' | 'label';
export type FormattingBody =
  | 'large italic'
  | 'large light italic'
  | 'medium italic'
  | 'medium light italic'
  | 'small italic'
  | 'small light italic';

export type FormattingLabel = 'large light italic' | 'small italic';

export interface TypographyItalicProps {
  type?: Variant;
  formattingBody?: FormattingBody;
  formattingLabel?: FormattingLabel;
  customStyle?: string;
  children: ReactNode;
}

const TypographyItalic = ({
  type,
  formattingBody,
  formattingLabel,
  customStyle,
  children,
}: TypographyItalicProps) => {
  if (type === 'label') {
    return (
      <div
        className={cn(styles.label, styles.poppins, customStyle, {
          [styles.labelLargeItalic]: formattingLabel === 'large light italic',
          [styles.labelSmallItalic]: formattingLabel === 'small italic',
        })}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={cn(styles.body, styles.poppins, customStyle, {
        [styles.bodyLargeItalic]: formattingBody === 'large italic',
        [styles.bodyLargeLightItalic]: formattingBody === 'large light italic',
        [styles.bodyMediumItalic]: formattingBody === 'medium italic',
        [styles.bodyMediumLightItalic]:
          formattingBody === 'medium light italic',
        [styles.bodySmallItalic]: formattingBody === 'small italic',
        [styles.bodySmallLightItalic]: formattingBody === 'small light italic',
      })}
    >
      {children}
    </div>
  );
};

export default TypographyItalic;
